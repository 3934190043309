import { IntConnectivityUnitStatusCountDto, IntStatus } from 'generated';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { fontSettings } from 'styles/themes/fontSettings';

export enum chartColors {
  '#000000' = 0,
  '#00cc67' = 1,
  '#a0a0a0' = 2,
  '#ff9902' = 3,
  '#e12265' = 4,
  '#029899' = 5,
}

interface GroupDetailsChartProps {
  connectivityUnitStatuses?: IntConnectivityUnitStatusCountDto[];
}

export const GroupDetailsChart: React.FC<GroupDetailsChartProps> = ({
  connectivityUnitStatuses,
}) => {
  const { t } = useTranslation();

  const data = returnChartData(connectivityUnitStatuses);

  const chartsOptions: Highcharts.Options = {
    title: {
      text: undefined,
    },
    legend: {
      enabled: true,
      align: 'right',
      verticalAlign: 'middle',
      symbolRadius: 0,
      itemMarginBottom: 4,
      itemMarginTop: 4,
    },
    credits: {
      enabled: false,
    },
    chart: {
      height: 220,
    },
    series: [
      {
        type: 'pie',
        tooltip: {
          pointFormatter(this) {
            return `<b>${this.percentage?.toFixed(2)}%</b><br/>${t(
              'count'
            )} <b>${this.y}</b>`;
          },
        },
        dataLabels: {
          enabled: true,
          distance: -28,
          formatter() {
            return this.percentage > 3
              ? `${this.percentage.toFixed(2) || 0}%`
              : '';
          },
          style: {
            textOutline: undefined,
            fontFamily: fontSettings.Pebble.fontStack,
            fontSize: '0.8rem',
            fontWeight: 'light',
          },
        },

        borderWidth: 1,
        borderColor: 'none',
        shadow: false,
        innerSize: '50%',
        showInLegend: true,
        data,
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={chartsOptions} />;
};

export default GroupDetailsChart;
function returnChartData(
  connectivityUnitStatuses?: IntConnectivityUnitStatusCountDto[]
) {
  return connectivityUnitStatuses?.map(item => {
    return {
      name: IntStatus[item.connectivityUnitStatus],
      y: item.count,
      color: chartColors[item.connectivityUnitStatus],
    };
  });
}
