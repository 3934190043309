import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const DocumentTitle: React.FC<{ title?: string; suffix?: string }> = ({
  title,
  suffix = 'common:telia_iot_portal',
}) => {
  const { t } = useTranslation('route');

  useEffect(() => {
    document.title = title ? `${t(title)} - ${t(suffix)}` : t(suffix);
  }, [title, suffix, t]);

  return null;
};

export default DocumentTitle;
