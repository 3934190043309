import { useEffect, useMemo, useState } from 'react';
import { IGroupedData } from 'services/service.service';
import { serviceDataUtils } from 'views/Dashboard/DataSources/ServiceData/serviceDataUtils';
import { dataProp } from '../../../DataSources/dataProp';
import { IAzureMapsSettings } from '../azureMapsConfig';
import { ILocationDataSource } from './useCurrentLocationDataSource';

export interface IMapLocationData {
  id: string;
  latitude: number;
  longitude: number;
  displayName: string;
  isConnected: boolean; // Todo: remove when adding settings for pins - now they are hard-coded to use this for deciding their colors
  group: string;
}

export function useCurrentLocationData(
  location: ILocationDataSource,
  settings: IAzureMapsSettings
): IMapLocationData[] {
  const { dataSource, latitudeId, longitudeId } = location;

  const locationProps = dataSource.getDataProps(getProp => ({
    latitude: getProp(latitudeId),
    longitude: getProp(longitudeId),
    displayName: getProp('AssetDetailsAssetName'),
    isConnected: getProp('AssetDetailsTerminalConnected'),
    pivotProperties: settings.groupProperties.map(getProp),
  }));

  const [rawServiceData, setRawServiceData] = useState<IGroupedData[]>([]);

  // Not fully efficient since it re-runs whenever locationProps changes, but a minor issue for now, consider improvements
  const parsedData = useMemo(
    (): IMapLocationData[] =>
      rawServiceData
        ?.map(group => {
          const flattenedData = serviceDataUtils.flattenLatestData(group);

          return {
            id: group.id,
            displayName: dataProp.getString(
              locationProps.displayName,
              group.owner
            ),
            isConnected: dataProp.getBool(
              locationProps.isConnected,
              group.owner
            ),
            latitude: dataProp.getNumber(locationProps.latitude, flattenedData),
            longitude: dataProp.getNumber(
              locationProps.longitude,
              flattenedData
            ),
            group: locationProps.pivotProperties
              .map(dp => dataProp.getString(dp, group.owner))
              .join(', '),
          };
        })
        .filter(
          point => point.latitude !== null && point.longitude !== null
        ) as IMapLocationData[], // Cast needed since filter doesn't infer our nullcheck
    [rawServiceData, locationProps]
  );

  useEffect(() => {
    let isMounted = true;
    const loadData = async () => {
      const response = await dataSource.getData();

      if (
        response.type === 'success' &&
        response.data.type === 'groupedData' &&
        isMounted
      ) {
        setRawServiceData(response.data.groups);
      } else {
        setRawServiceData([]);
      }
    };

    loadData();

    return () => {
      isMounted = false;
    };
  }, [dataSource, dataSource.depString]);

  return parsedData;
}
