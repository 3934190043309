import { AutoComplete, InfiniteScrollAutoComplete } from 'components/Inputs';
import {
  IntServiceDataFilterOptionDto,
  ServiceDataFilterType,
} from 'generated';
import i18n from 'i18n';
import { observer } from 'mobx-react-lite';
import React from 'react';
import FilterColumn from './FilterColumn';
import { FilterControlState } from './FilterControlState';

interface IProps {
  control: FilterControlState;
}

interface IHierarchyDropdownProps {
  label: string;
  options: IntServiceDataFilterOptionDto[];
  selectedOption?: IntServiceDataFilterOptionDto;
}

// Create an array of dropdowns for hierarchal asset groups
function getHierarchyDropdowns(control: FilterControlState) {
  const dropdowns: IHierarchyDropdownProps[] = [];

  const rootValue =
    control.selectedOptions.length > 0 ? control.selectedOptions[0] : undefined;

  // The first dropdown is always there
  dropdowns.push({
    label: i18n.t(
      `dashboard:filter.service_data_filter_type.${ServiceDataFilterType.ResourceGroupId}`
    ),
    options: control.options,
    selectedOption: rootValue,
  });

  const addNextDropdown = (i = 0) => {
    const { selectedOption } = dropdowns[i];
    if (selectedOption?.children.length) {
      const childSelectedOption =
        control.selectedOptions.length > i
          ? control.selectedOptions[i + 1]
          : undefined;

      dropdowns.push({
        label: selectedOption.hierarchyName || '',
        options: selectedOption.children,
        selectedOption: childSelectedOption,
      });

      addNextDropdown(i + 1);
    }
  };

  addNextDropdown();

  return dropdowns;
}

const AssetGroupFilterControl: React.FC<IProps> = ({ control }) => {
  const dropdowns = getHierarchyDropdowns(control);

  const handleChange = (index: number, selectedGroupId: string) => {
    const changedDropdown = dropdowns[index];
    const selectedOption = changedDropdown.options.find(
      o => o.id === selectedGroupId
    );
    const newValue = [...control.selectedOptions].slice(0, index); // Remove all values after and including this one

    if (selectedOption) {
      newValue[index] = selectedOption;
    }

    control.setValue(newValue);
  };

  // If we have more than one dropdown, make them slightly narrower than the other filter controls so they don't get too squeezed
  const columnFlex = dropdowns.length > 1 ? 0.75 : 1;

  return (
    <>
      {dropdowns.map((dropdown, i) => {
        // The first dropdown gets a proper paginated, backend-filtered, dropdown.
        if (i === 0) {
          return (
            <FilterColumn key={i} flex={columnFlex}>
              <InfiniteScrollAutoComplete
                id={control.key}
                options={dropdown.options}
                getOptionLabel={opt => opt.name || opt.id}
                getOptionValue={opt => opt.id}
                label={dropdown.label}
                clearable
                isLoading={control.isLoading}
                hasMore={control.hasMore}
                search={
                  control.search || control.selectedOptions[0]?.name || ''
                }
                onSearchChange={control.setSearch}
                fetchMore={control.fetchMore}
                value={dropdown.selectedOption || null}
                onChange={newVal => handleChange(i, newVal?.id || '')}
                multiple={false}
                disabled={control.isDisabled}
                dataCy="assetFilterResourceGroup"
              />
            </FilterColumn>
          );
        }
        return (
          <FilterColumn key={i} flex={columnFlex}>
            <AutoComplete
              value={dropdown.selectedOption?.id || ''}
              options={dropdown.options}
              getOptionLabel={o => o.name}
              getOptionValue={o => o.id}
              label={dropdown.label}
              onChange={selectedId => handleChange(i, selectedId || '')}
              disabled={control.isDisabled}
              clearable
            />
          </FilterColumn>
        );
      })}
    </>
  );
};

export default observer(AssetGroupFilterControl);
