import {
  IconButton,
  InputAdornment,
  TextField as MUITextField,
} from '@mui/material';
import AtlasIcon from 'components/AtlasIcon';
import LoadingSpinner from 'components/LoadingSpinner';
import { useField } from 'formik';
import React, { useRef } from 'react';
import { AtlasIconType } from 'shared/types/atlas-icon';

export type InputType = 'text' | 'email' | 'phone' | 'number';

interface IProps {
  name: string;
  label: string;
  type?: InputType;
  adornment?: {
    iconButton?: {
      icon: AtlasIconType;
      onClick: React.MouseEventHandler<HTMLButtonElement>;
    };
    loading?: boolean;
    position: 'end' | 'start';
    text?: string;
  };
  placeholder?: string;
  className?: string;
  multiline?: boolean;
  rows?: string | number;
  rowsMax?: string | number;
  required?: boolean;
  autoFocus?: boolean;
  minLength?: number;
  maxLength?: number;
  disabled?: boolean;
  onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  readOnly?: boolean;
  autoComplete?: string;
}

const TextField: React.FC<IProps> = ({
  name,
  label,
  placeholder,
  type,
  adornment,
  multiline,
  rows,
  rowsMax,
  required,
  autoFocus,
  onFocus,
  onBlur,
  minLength,
  maxLength,
  disabled = false,
  className,
  readOnly = false,
  autoComplete,
}) => {
  const [field, meta] = useField<string>(name);
  const textFieldInputRef = useRef<HTMLInputElement>();

  const getInputAdornment = () => {
    if (adornment) {
      return (
        <InputAdornment position={adornment.position}>
          {adornment.loading && <LoadingSpinner />}
          {adornment.iconButton ? (
            <IconButton onClick={adornment.iconButton.onClick} size="large">
              <AtlasIcon color="primary" type={adornment.iconButton.icon} />
            </IconButton>
          ) : (
            adornment.text
          )}
        </InputAdornment>
      );
    }
    return null;
  };

  const onScrollCapture = () => {
    const currentRef = textFieldInputRef.current;
    if (multiline && currentRef) {
      currentRef.style.marginTop = currentRef.scrollTop > 15 ? '25px' : '0px';
      currentRef.style.paddingBottom =
        currentRef.scrollTop > 15 ? '0px' : '25px';
    }
  };
  return (
    <MUITextField
      inputRef={textFieldInputRef}
      onScrollCapture={onScrollCapture}
      autoComplete={autoComplete}
      id={name}
      type={type}
      label={label}
      className={className}
      data-cy={`formControl-${name}`}
      fullWidth
      autoFocus={autoFocus}
      placeholder={placeholder}
      error={meta.touched && !!meta.error}
      helperText={meta.touched && meta.error}
      multiline={multiline}
      rows={rows}
      maxRows={rowsMax}
      required={required}
      disabled={disabled}
      InputProps={{ endAdornment: getInputAdornment(), readOnly: readOnly }}
      inputProps={{
        minLength,
        maxLength,
        'data-cy': name,
      }}
      variant="outlined"
      {...field}
      onBlur={e => {
        onBlur?.(e);
        field.onBlur(e);
      }}
      onFocus={onFocus}
      value={field.value || ''}
    />
  );
};

export default TextField;
