import { Box } from '@mui/material';
import { DropdownMenu, DropdownMenuItem } from 'components/DropdownMenu';
import i18n from 'i18n/i18n';
import { Column } from 'react-table';
import { UserAction } from 'store/authStore';
import { textRight } from '../Helpers/textRight';

export function userAction<T>(options: {
  id: string;
  accessor: (row: T) => string;
  onClick: (row: T, action: UserAction) => void;
  actions: { update: boolean; changePassword: boolean; delete: boolean };
}): Column<T> {
  const { id = 'modify', accessor, onClick, actions } = options;

  return {
    id,
    accessor,
    filterable: false,
    sortable: false,
    resizable: false,
    className: textRight,
    width: 300,
    Cell: row => {
      return (
        <Box color="primary.main">
          {Object.values(actions).some(action => action === true) && (
            <DropdownMenu
              onItemClick={(action: UserAction) =>
                onClick(row.original, action)
              }
              label={i18n.t('common:label.action')}
            >
              <DropdownMenuItem
                icon="Pencil"
                label={i18n.t('common:modify')}
                value={UserAction.UPDATE}
                disabled={!actions.update}
              />
              <DropdownMenuItem
                icon="Pencil"
                label={i18n.t('auth:action.password.change')}
                value={UserAction.CHANGEPASSWORD}
                disabled={!actions.changePassword}
              />
              <DropdownMenuItem
                icon="TrashEmpty"
                label={i18n.t('user:user.delete.base')}
                value={UserAction.DELETE}
                disabled={!actions.delete}
              />
            </DropdownMenu>
          )}
        </Box>
      );
    },
  };
}
