import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(4),
      textDecoration: 'none',
      color: theme.palette.text.primary,
      '&:hover': {
        backgroundColor: theme.settings.colors.lightPink,
      },
    },
    compact: {
      display: 'block',
    },
  })
);
