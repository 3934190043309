import i18n from 'i18n';
import { TableCellRenderer } from 'react-table';
import { formatNumber } from 'utils';
import { IColumnAggregationProps } from './IColumnAggregationProps';
import { TypedAggregator } from './TypedAggregator';

export interface INumberColumnPivotData {
  min: number;
  max: number;
  average: number;
}

const aggregateNumberColumn: TypedAggregator<
  number | null,
  INumberColumnPivotData | null
> = values => {
  const nonNullValues = values.filter(val => val !== null) as number[];

  if (nonNullValues.length === 0) {
    return null;
  }

  const result: INumberColumnPivotData = {
    min: nonNullValues.reduce(
      (min, val) => (val < min ? val : min),
      nonNullValues[0]
    ),
    max: nonNullValues.reduce(
      (max, val) => (val > max ? val : max),
      nonNullValues[0]
    ),
    average:
      nonNullValues.reduce((sum, val) => sum + val, 0) / nonNullValues.length,
  };

  return result;
};

type AggregatedNumberMinMaxValue = INumberColumnPivotData | number | null;

function sortAggregatedNumberColumn(
  a: AggregatedNumberMinMaxValue,
  b: AggregatedNumberMinMaxValue,
  desc: boolean
): number {
  // Nulls go last
  const fallback = desc ? Number.MIN_VALUE : Number.MAX_VALUE;

  // If decending, sort on max, if ascending on min.
  const getValueToSortBy = (rawValue: AggregatedNumberMinMaxValue): number => {
    if (typeof rawValue === 'number') {
      return rawValue;
    }

    return (desc ? rawValue?.max : rawValue?.min) ?? fallback;
  };

  return getValueToSortBy(a) - getValueToSortBy(b);
}

interface INumberMinMaxAggregationOptions {
  unit?: string;
  decimals?: number;
}

export function getNumberMinMaxAggregationProps({
  unit = '',
  decimals = 1,
}: INumberMinMaxAggregationOptions): IColumnAggregationProps {
  const Aggregated: TableCellRenderer = cellInfo => {
    const value = cellInfo.value as INumberColumnPivotData | null;

    if (!value) {
      return (
        <span className="text-muted">
          {i18n.t('not_applicable_abbreviation')}
        </span>
      );
    }

    const formattedUnit = unit && <span className="text-unit">{unit}</span>;

    if (value.min === value.max) {
      return (
        <>
          {formatNumber(value.min, { decimals })}
          {formattedUnit}
        </>
      );
    }

    return (
      <span
        title={`${i18n.t('dashboard:aggregation.avg')}: ${formatNumber(
          value.average,
          { decimals }
        )}${unit}`}
      >
        {formatNumber(value.min, { decimals })}
        &nbsp; &#8211; &nbsp;
        {formatNumber(value.max, { decimals })}
        {formattedUnit}
      </span>
    );
  };

  return {
    Aggregated,
    aggregate: aggregateNumberColumn,
    sortMethod: sortAggregatedNumberColumn,
  };
}
