import { DashboardComponentType } from 'generated';
import { runInAction } from 'mobx';
import {
  IBaseComponentSettings,
  IComponentTypeConfig,
} from 'views/Dashboard/dashboardTypes';
import { DataPropSetting } from 'views/Dashboard/DataSources/dataPropTypes';
import { IDataSourceSettings } from 'views/Dashboard/DataSources/dataSourceTypes';
import { IMapPopupSettings } from '../AzureMapsComponent/azureMapsConfig';
import PreviewStep from '../PreviewStep';
import ImageWidget, { IMapPosition } from './ImageWidget';
import AddAssetsStep from './SettingsSteps/AddAssetsStep';
import GeneralSettingsStep from './SettingsSteps/GeneralSettingsStep';

export interface IImageSettings extends IBaseComponentSettings {
  title: string;
  dataSource: IDataSourceSettings;

  imageUrl: string;
  imageWidth?: number;
  imageHeight?: number;

  dataPoints: IImagePointSettings[];

  policyId: string;
  markerProp?: DataPropSetting; // Optionally, select a single prop to color icons by

  popup: IMapPopupSettings;
  enableZoom: boolean;
}

export interface IImagePointSettings {
  id: string;
  position: IMapPosition;

  assetId: string;
  dataProp: string;
}

export const imageComponentConfig: IComponentTypeConfig<
  DashboardComponentType.Image,
  IImageSettings
> = {
  widgetType: DashboardComponentType.Image,
  icon: 'DefaultImage',
  Component: ImageWidget,
  showDatasourceSettings: false,
  settingsSteps: [
    {
      title: 'settings',
      Component: GeneralSettingsStep,
    },
    {
      title: 'image.configure_data_points',
      Component: AddAssetsStep,
    },
    {
      title: 'final_preview',
      Component: PreviewStep,
    },
  ],
  getDefaultSettings: ({ dataSource }) => {
    return {
      position: { x: 0, y: 0, w: 12, h: 8 },
      title: '',
      dataSource: dataSource.settings,
      imageUrl: '',
      enableZoom: true,
      dataPoints: [],
      policyId: '',
      markerProp: '',
      popup: {
        rows: [],
      },
    };
  },
  fixSettings: settings => {
    if (!settings.dataPoints) {
      settings.dataPoints = [];
    }

    if (!settings.popup) {
      settings.popup = { rows: [] };
    }

    if (settings.enableZoom === undefined) {
      settings.enableZoom = true;
    }
    // Attempt to fix widgets added before we added width/height to settings - so we don't have to do it manually or break existing dashboards
    // Consider removing this code after a while
    if (!settings.imageWidth || !settings.imageHeight) {
      const image = document.createElement('img');
      image.setAttribute('src', settings.imageUrl);
      image.style.display = 'none';
      document.body.appendChild(image);

      image.onload = e => {
        const { naturalHeight, naturalWidth } = e.target as HTMLImageElement;
        runInAction(() => {
          document.body.removeChild(image);
          settings.imageWidth = naturalWidth;
          settings.imageHeight = naturalHeight;
        });
      };
    }
  },
};
