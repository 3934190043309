import ErrorBoundary from 'components/ErrorBoundary';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { DashboardComponent } from 'store/domains/dashboardComponent';
import { componentTypeConfigs } from '../ComponentTypes/componentTypeConfigs';
import { ComponentContext } from '../contexts/componentContext';

interface IProps {
  component: DashboardComponent;
}

const ComponentContainer: React.FC<IProps> = ({ component }) => {
  const componentConfig = componentTypeConfigs[component.componentType];

  if (!componentConfig) {
    // This truly should never happen, but just in case
    return <div>Unknown component type.</div>;
  }

  const { Component } = componentConfig;

  return (
    <ErrorBoundary>
      <ComponentContext.Provider value={component}>
        <Component component={component} />
      </ComponentContext.Provider>
    </ErrorBoundary>
  );
};

export default observer(ComponentContainer);
