import { Box, Button, FormControl, FormLabel } from '@mui/material';
import LoadingOverlay from 'components/LoadingOverlay';
import { snackbar } from 'components/Snackbar';
import { useField } from 'formik';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { usePost } from 'shared/api/hooks';
import { PostEndpoint } from 'shared/interfaces/api';
import { useStyles } from './SingleFileUpload.styles';

interface IProps {
  name: string;
  label?: string;
  maxSize?: number;
  endpoint: PostEndpoint<string, FormData, string>; // The return string is expected to be the URL to the uploaded file
  params: string;
  type: 'image'; // Expand as needed
}

const SingleFileUpload: React.FC<IProps> = ({
  name,
  label,
  maxSize,
  endpoint,
  params,
  type,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [, meta, helpers] = useField<string>(name);
  const [post, isUploading] = usePost(endpoint);

  const { getRootProps, getInputProps, open } = useDropzone({
    multiple: false,
    maxSize,
    noClick: true,
    accept: type === 'image' ? 'image/*' : '*',
    onDropRejected: () => {
      snackbar(t('validation:file.too_large'), { variant: 'error' });
    },
    onDrop: async acceptedFiles => {
      if (acceptedFiles.length === 0) {
        return;
      }
      const formData = new FormData();
      acceptedFiles.forEach((file, i) => {
        formData.append(`file[${i}]`, file);
      });
      const uploadResponse = await post({ params, data: formData });
      if (uploadResponse.status === 200 && uploadResponse.data) {
        helpers.setValue(uploadResponse.data);
      } else {
        snackbar(t('common:error.file_upload'), { variant: 'error' });
      }
    },
  });

  return (
    <FormControl error={!!meta.error} fullWidth>
      {label && <FormLabel>{label}</FormLabel>}

      <section {...getRootProps()}>
        <LoadingOverlay isLoading={isUploading}>
          <input {...getInputProps()} />

          {meta.value && type === 'image' && (
            <img src={meta.value} alt="" className={classes.uploadedImage} />
          )}

          <Box pt={0.5}>
            <Button color="primary" variant="contained" onClick={open}>
              {t('select.files_to_upload')}
            </Button>
          </Box>
        </LoadingOverlay>
      </section>
    </FormControl>
  );
};

export default SingleFileUpload;
