import { Box, useTheme } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { useStyles } from './HeaderLogo.styles';

interface IProps {
  navDrawerOpen: boolean;
}

const HeaderLogo: React.FC<IProps> = ({ navDrawerOpen }) => {
  const classes = useStyles();
  const theme = useTheme();

  const {
    settings: {
      components: { topMenu },
    },
  } = theme;

  const isMinimized = !navDrawerOpen;

  const bigLogo =
    topMenu.logoSrc || `${process.env.PUBLIC_URL}/telia-company-logo.png`;

  // Right now, default to only supporting custom small logos for the default UI configuration. Not top priority.
  const smallLogo =
    topMenu.logoSrc || `${process.env.PUBLIC_URL}/telia-logo-mini.svg`;

  return (
    <Box
      className={classNames(classes.logoWrapper, {
        [classes.minimized]: isMinimized,
      })}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Link
        className={classNames(classes.brand, {
          [classes.minimized]: isMinimized,
        })}
        to="/"
      >
        <Box
          display="flex"
          justifyContent={`${navDrawerOpen ? 'flex-start' : 'center'}`}
          alignItems="center"
          pl={navDrawerOpen ? 3 : 0}
          pr={navDrawerOpen ? 3 : 0}
        >
          <img
            className={classNames(classes.icon)}
            src={navDrawerOpen ? bigLogo : smallLogo}
            alt="Logotype"
          />
        </Box>
      </Link>
    </Box>
  );
};

export default HeaderLogo;
