import worldMapData from 'components/Highcharts/worldMapData.json';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { observer } from 'mobx-react-lite';
import React from 'react';
import {
  convertByteToUnitNumber,
  formatNumber,
  getByteConversionUnit,
} from 'utils';
import { IWidgetData } from 'views/Dashboard/DataSources/dataSourceTypes';
import { dataProp } from '../../DataSources';
import { useMapStyles } from './HighchartsMap.styles';
import { IHighthchartsMapSettings } from './highchartsMapConfig';
import { IHighchartsMapViewModel } from './HighchartsMapContainer';
import { generateDataClasses } from './highchartsMapUtils';

interface IProps {
  viewModel: IHighchartsMapViewModel;
  data: IWidgetData;
  settings: IHighthchartsMapSettings;
}

interface CountryDataPoint {
  code: string;
  value: number;
  name: string;
}

const worldMapCountryISOCode = (countryName: string) => {
  return worldMapData.features.find(
    item => item.properties.name === countryName
  )?.properties['iso-a3'];
};

function widgetDataToMapData(
  data: IWidgetData,
  vm: IHighchartsMapViewModel
): CountryDataPoint[] {
  if (data.type === 'list') {
    return data.items
      .map(dataPoint => ({
        name: dataPoint.country,
        code:
          dataPoint.countryISO3166 || worldMapCountryISOCode(dataPoint.country), // It's fine to hardcode these for now
        value: dataProp.get(vm.value, dataPoint),
      }))
      .filter(x => x.value !== null) as CountryDataPoint[];
  }
  return [];
}

const getMapOptions = (props: IProps): Highcharts.Options => {
  const { viewModel } = props;
  const { unit = '', type } = viewModel.value;

  const data = widgetDataToMapData(props.data, props.viewModel);
  const min = Math.min(...data.map(item => item.value));
  const max = Math.max(...data.map(item => item.value));
  const dataUsageDataClasses = generateDataClasses(min, max);

  const dataClasses =
    type === 'dataUsage'
      ? dataUsageDataClasses
      : [
          {
            from: 0,
            to: 100,
            name: `< 100 ${unit}`,
          },
          {
            from: 100,
            to: 499,
            name: `100 - 499 ${unit}`,
          },
          {
            from: 500,
            to: 999,
            name: `500 - 999 ${unit}`,
          },
          {
            from: 1000,
            to: 1999,
            name: `1 000 - 1 999 ${unit}`,
          },
          {
            from: 2000,
            name: `> 2 000 ${unit}`,
          },
        ];

  const mapOptions: Highcharts.Options = {
    title: {
      text: undefined,
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        align: 'right',
        verticalAlign: 'bottom',
      },
    },
    credits: {
      enabled: false,
    },
    colorAxis: {
      minColor: '#ffedd0',
      maxColor: '#dd8804',
      dataClassColor: 'tween',
      dataClasses,
    },
    legend: {
      align: 'center',
      layout: 'horizontal',
      symbolRadius: 0,
      itemStyle: { verticalAlign: 'center' },
    },
    plotOptions: {
      map: {
        nullColor: '#fefefe',
        nullInteraction: true,
      },
    },
    series: [
      {
        type: 'map',
        mapData: worldMapData,
        showInLegend: true,
        states: {
          hover: {
            borderColor: '#000',
          },
        },
        data,
        joinBy: ['iso-a3', 'code'],
      },
    ],
    tooltip: {
      formatter() {
        if (type === 'dataUsage') {
          return `<b>${this.point.name}</b> ${formatNumber(
            convertByteToUnitNumber(this.point.value || 0),
            {
              decimals: 2,
            }
          )} ${getByteConversionUnit(this.point.value || 0)}`;
        }
        return `<b>${this.point.name}:</b> ${formatNumber(
          this.point.value || 0,
          { decimals: viewModel.value.decimals }
        )} ${unit}`;
      },
    },
  };

  return mapOptions;
};

const ColumnChart: React.FC<IProps> = props => {
  const chartOptions = getMapOptions(props);
  const classes = useMapStyles();

  return (
    <HighchartsReact
      options={chartOptions}
      highcharts={Highcharts}
      constructorType={'mapChart'}
      containerProps={{
        className: classes.root,
      }}
    />
  );
};

export default observer(ColumnChart);
