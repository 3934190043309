import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    icon: {
      borderRadius: '12px',
      padding: '1px 8px',
      display: 'flex',
      alignItems: 'baseline',
      justifyContent: 'center',
      '& > div': {
        alignSelf: 'center',
      },
    },
    high: {
      background: theme.settings.colors.lightgreen,
    },
    average: {
      background: theme.settings.colors.lightyellow,
    },
    low: {
      background: theme.settings.colors.pink,
    },
  })
);
