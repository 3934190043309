import { ComparisonType } from 'generated';
import { useTranslation } from 'react-i18next';

const useFormatStringValue = () => {
  const { t } = useTranslation('policy');

  return (
    comparer: ComparisonType | null | undefined,
    value: string | null | undefined
  ) => {
    let textComparer;
    if (comparer === ComparisonType.EqualTo) {
      textComparer = '=';
    } else if (comparer === ComparisonType.NotEqualTo) {
      textComparer = '≠';
    } else {
      textComparer = '';
    }
    const stringValue = value ?? t('no_values');

    return `${textComparer} ${stringValue}`;
  };
};

export default useFormatStringValue;
