import { ArrowDropDown } from '@mui/icons-material';
import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  TextField,
} from '@mui/material';
import AtlasIcon from 'components/AtlasIcon';
import { useField } from 'formik';
import gPhoneNumber from 'google-libphonenumber';
import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TeliaFlagIcons from 'shared/icons/telia-flag-icons';
import { AtlasIconType } from 'shared/types/atlas-icon';
import { useStores } from 'store';
import { useStyles } from './PhoneField.styles';
import { findCountryCode } from './PhoneFieldUtils';

export const phoneUtil = gPhoneNumber.PhoneNumberUtil.getInstance();
const PNF = gPhoneNumber.PhoneNumberFormat;
interface IProps {
  name: string;
  label: string;
  disabled?: boolean;
  required?: boolean;
  className?: string;
  onBlur?: (value: any) => void;
  onKeyPress?: (value: any) => void;
  adornment?: {
    iconButton?: {
      icon: AtlasIconType;
      onClick: React.MouseEventHandler<HTMLButtonElement>;
    };
    position: 'end' | 'start';
    text?: string;
  };
  input?: {
    endAdorment: React.ReactNode;
  };
  error?: string;
}

const PhoneField: React.FC<IProps> = ({ name, ...props }) => {
  const { authStore } = useStores();
  const defaultCountryCode = authStore.user?.countryISO3166 || 'ZZ';
  const [field, meta, helpers] = useField<string | string[]>(name);
  const [countryCode, setCountryCode] = useState(defaultCountryCode);
  const [selectedCountryCode, setSelectedCountryCode] = useState(null);
  const [focused, setFocus] = useState(false);
  const [multipleFieldValue, setMultipleFieldValue] = useState<string>();
  const inputRef = useRef<HTMLInputElement>();
  const { t } = useTranslation('countries');
  const classes = useStyles();
  const isMultiple = Array.isArray(field.value);

  const countryCodeFlagIcon = `Flag${countryCode}`;
  const defaultFlagIcon = 'FlagZZ';
  const countryFlag = Object.keys(TeliaFlagIcons).includes(countryCodeFlagIcon)
    ? (countryCodeFlagIcon as AtlasIconType)
    : defaultFlagIcon;

  const setTargetValue = (value?: string) => {
    if (inputRef.current) {
      inputRef.current.value = value || '';
    }
    setMultipleFieldValue(value || '');
  };

  useEffect(() => {
    //setting the default country code as Sweden for the user
    if (defaultCountryCode && (!field.value || isEmpty(field.value))) {
      const formattedCountryCode = `+${phoneUtil.getCountryCodeForRegion(
        defaultCountryCode
      )}`;
      !isMultiple
        ? helpers.setValue(formattedCountryCode)
        : setTargetValue(formattedCountryCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fieldValue = Array.isArray(field.value)
      ? inputRef.current?.value || ''
      : field.value;
    // Prefer selected country code
    if (selectedCountryCode) {
      setCountryCode(selectedCountryCode || 'ZZ');
      setSelectedCountryCode(null);
    } else {
      try {
        const phoneNumber = phoneUtil.parseAndKeepRawInput(
          fieldValue?.replace(/^00/, '+'),
          'ZZ'
        );
        const regionCode = phoneUtil.getRegionCodeForNumber(phoneNumber);
        setCountryCode(
          findCountryCode(countryCode, fieldValue, phoneUtil, regionCode) ||
            'ZZ'
        );
      } catch {
        setCountryCode(
          findCountryCode(countryCode, fieldValue, phoneUtil) || 'ZZ'
        );
      }
    }
  }, [field.value, selectedCountryCode, countryCode, multipleFieldValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const phoneNumber = phoneUtil.parseAndKeepRawInput(
        !isMultiple ? event.target.value : inputRef.current?.value || '',
        'ZZ'
      );
      const formatted = phoneUtil.formatInOriginalFormat(phoneNumber);
      !isMultiple ? helpers.setValue(formatted) : setTargetValue(formatted);
    } catch {
      !isMultiple
        ? helpers.setValue(event.target.value)
        : setTargetValue(inputRef.current?.value);
    }
  };

  const handleCountrySelect = (event: React.ChangeEvent<any>): void => {
    const fieldValue = Array.isArray(field.value)
      ? inputRef.current?.value || ''
      : field.value;
    if (inputRef.current) {
      inputRef.current.focus();
    }
    try {
      // Replace country code with selected
      const phoneNumber = phoneUtil.parseAndKeepRawInput(
        fieldValue?.replace(/^00/, '+'),
        event.target.value
      );
      const newPhoneNumber = phoneUtil.parseAndKeepRawInput(
        `+${phoneUtil.getCountryCodeForRegion(
          event.target.value
        )}${phoneNumber.getNationalNumber()}`
      );
      const formatted = phoneUtil.format(newPhoneNumber, PNF.INTERNATIONAL);
      const regionCode = phoneUtil.getRegionCodeForNumber(newPhoneNumber);

      if (!regionCode) {
        setSelectedCountryCode(event.target.value);
      }
      !isMultiple ? helpers.setValue(formatted) : setTargetValue(formatted);
    } catch {
      setSelectedCountryCode(event.target.value);
      !isMultiple
        ? helpers.setValue(
            `+${phoneUtil.getCountryCodeForRegion(event.target.value)}`
          )
        : setTargetValue(
            `+${phoneUtil.getCountryCodeForRegion(event.target.value)}`
          );
    }
  };

  const getInputAdornment = () => {
    if (props.adornment) {
      return (
        <InputAdornment position={props.adornment.position}>
          {props.adornment.iconButton ? (
            <IconButton
              onClick={props.adornment.iconButton.onClick}
              size="large"
            >
              <AtlasIcon
                color="primary"
                type={props.adornment.iconButton.icon}
              />
            </IconButton>
          ) : (
            props.adornment.text
          )}
        </InputAdornment>
      );
    }
    return null;
  };

  return (
    <TextField
      id={name}
      autoComplete="phone"
      type="phone"
      variant="outlined"
      InputProps={{
        classes: {
          root: classes.root,
          disabled: classes.disabled,
          input: classes.input,
        },
        endAdornment: props.input?.endAdorment || getInputAdornment(),
        startAdornment: (
          <FormControl
            className={classes.formControl}
            disabled={props.disabled}
          >
            <select
              autoComplete="off-chrome"
              tabIndex={-1}
              value={countryCode}
              onChange={handleCountrySelect}
              className={classes.select}
              disabled={props.disabled}
              style={{
                opacity: focused ? 1 : 0,
              }}
            >
              {phoneUtil
                .getSupportedRegions()
                .sort((a, b) => t(a).localeCompare(t(b)))
                .map(code => (
                  <option key={code} value={code}>
                    {t(`${code}`)}
                  </option>
                ))}
            </select>
            <Input
              autoComplete="off-chrome"
              inputProps={{
                tabIndex: -1,
              }}
              disableUnderline
              margin="dense"
              className={classes.input}
              style={{
                opacity: focused ? 0 : 1,
              }}
              onFocus={() => setFocus(true)}
              startAdornment={
                <AtlasIcon
                  className={classes.flag}
                  color="primary"
                  type={countryCode !== 'ZZ' ? countryFlag : 'Global'}
                />
              }
              endAdornment={<ArrowDropDown />}
            />
          </FormControl>
        ),
      }}
      inputRef={inputRef}
      inputProps={{
        'data-cy': name,
      }}
      {...field}
      {...props}
      fullWidth
      error={(meta.touched && !!meta.error) || !!props.error}
      helperText={(meta.touched && meta.error) || props.error}
      onChange={handleChange}
      value={
        Array.isArray(field.value)
          ? field.value[field.value.length]
          : field.value
      }
    />
  );
};

export default PhoneField;
