import { TableData } from 'components/Table';
import { subMinutes } from 'date-fns';
import {
  IntEnterpriseVpnDto,
  IntEnterpriseVpnListDto,
  IntSearchDto,
  VpnStatus,
} from 'generated';
import { TempFailedSessionAttemptsDto } from 'services/ipAccess.service';
import { IResponse } from 'shared/interfaces/api';
import { paginate, rnd, rndItem, tableDemoData } from 'utils';
import { makeRandomFirewallRules } from './apnHubDemoData';

type IDemoVPNSettings = IntEnterpriseVpnListDto & {
  details?: IntEnterpriseVpnDto;
};
class IPAccessDemoData {
  private _vpnSettings: IDemoVPNSettings[] | undefined;
  get vpns() {
    if (!this._vpnSettings) {
      this._vpnSettings = rnd.array<IDemoVPNSettings>(7, i => {
        const vpn: IDemoVPNSettings = {
          id: rnd.guid(),
          customerId: rnd.guid(),
          uuid: rnd.guid(),
          status: i + 1, // Skip "Unknown", start at 1
          name: `VPN setting #${i}`,
          enterpriseUuid: rnd.guid(),
        };

        if (vpn.status === VpnStatus.Unconfigured) {
          vpn.uuid = undefined; // Doesnt exist in APN hub, only in our db.
        }
        if (
          vpn.status === VpnStatus.Unconfigured ||
          vpn.status === VpnStatus.NotFound
        ) {
          vpn.details = {
            id: vpn.id,
            customerId: vpn.customerId,
            name: vpn.name,
            enterpriseUuid: vpn.enterpriseUuid,
            status: vpn.status,
            networks: [],
            enterpriseGatewayIp: '',
            vpn: {
              gatewayAddress: '',
              psk: '',
              ikeVersions: [],
              phase1: {
                dhGroups: [],
                enc: [],
                auth: [],
              },
              phase2: {
                dhGroups: [],
                enc: [],
                auth: [],
              },
            },
            region: rnd.item(
              'eu-north-1',
              'eu-north-2',
              'eu-west-1',
              'eu-west-2'
            ),
            enterpriseGatewayUuid: rnd.guid(),
            firewallRules: {
              inbound: [],
              breakout: [],
              outbound: [],
            },
          };
        } else {
          vpn.details = {
            id: vpn.id,
            customerId: vpn.customerId,
            uuid: vpn.uuid,
            name: vpn.name,
            enterpriseUuid: vpn.enterpriseUuid,
            status: vpn.status,
            networks: ['192.0.0.1/11', '192.0.0.2/22'],
            enterpriseGatewayIp: rnd.ip(),
            vpn: {
              gatewayAddress: rndItem([
                '192.33.159.75',
                '70.90.217.228',
                '255.191.104.104',
                '43.111.53.232',
                '93.254.96.38',
              ]),
              psk: `preSharedKey${rnd.int(100, 999)}`,
              ikeVersions: ['ikev1'],
              phase1: {
                dhGroups: [1, 2],
                enc: ['3des'],
                auth: ['hmac-sha1'],
                lifetime: rnd.int(10000, 40000),
              },
              phase2: {
                dhGroups: [1, 2, 5],
                enc: ['aes-128'],
                auth: ['hmac-md5'],
                lifetime: rnd.int(1000, 5000),
              },
            },
            region: rnd.item(
              'eu-north-1',
              'eu-north-2',
              'eu-west-1',
              'eu-west-2'
            ),
            enterpriseGatewayUuid: rnd.guid(),
            firewallRules: {
              inbound: makeRandomFirewallRules(true),
              breakout: [],
              outbound: [],
            },
          };
        }

        return vpn;
      });
    }

    return this._vpnSettings;
  }

  getDefaultVpn = (): IntEnterpriseVpnListDto => {
    const rndInt = rnd.int(0, this.vpns.length);
    return this.vpns[rndInt];
  };

  getVpn = (uuid: string): IDemoVPNSettings => {
    const vpn = this.vpns.find(x => x.id === uuid);

    if (!vpn) {
      throw new Error('VPN missing');
    }

    return vpn;
  };

  getVpnTable = (
    params: IntSearchDto
  ): IResponse<TableData<IntEnterpriseVpnListDto>> => {
    const filteredOrders = this.vpns.filter(vpn => {
      if (params.search) {
        return vpn.name.toString().includes(params.search.toLowerCase());
      }
      return true;
    });

    return {
      status: 200,
      data: paginate(filteredOrders, params),
    };
  };

  getVpnDetails = (id: string): IResponse<IntEnterpriseVpnDto> => {
    const vpn = this.getVpn(id);

    return {
      status: 200,
      data: vpn.details,
    };
  };

  getEnterpriseVpns = (
    customerId: string
  ): IResponse<IntEnterpriseVpnDto[]> => {
    const vpns = this.vpns
      // .filter(vpn => vpn.customerId === customerId) // CustomerIds arent properly mapped in demo
      .map(vpn => vpn.details!);
    return { status: 200, data: vpns };
  };
  get getFailedSessionAttempts() {
    return tableDemoData<TempFailedSessionAttemptsDto>(i => ({
      timestamp: subMinutes(new Date(), rnd.int(10, 99999)),
      label: rnd.label(),
      simId: rnd.euiccId(),
      eventCause: rndItem([
        'Bad data',
        'Invalid date',
        'Not supported',
        'Invalid format',
      ]),
      imsi: rnd.imsi(),
      msisdn: rnd.msisdn(),
      tgppSgsnMccMnc: `${rnd.charSequence(5)}-${rnd.charSequence(
        7
      )}-${rnd.charSequence(3)}`,
    }));
  }
}

export const ipAccessDemoData = new IPAccessDemoData();
