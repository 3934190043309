import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      marginLeft: theme.spacing(1),
      color: theme.palette.primary.main,
      opacity: 0,
      transform: 'scale(0.4)',
      transformOrigin: 'top right',
      transition: 'all 260ms cubic-bezier(0.190, 1.000, 0.220, 1.000)',
      transitionDelay: '220ms',
      '.WidgetContainer:hover &': {
        opacity: 1,
        transform: 'none',
        transition: 'all 160ms cubic-bezier(0.190, 1.000, 0.220, 1.000)',
        transitionDelay: 'none',
      },
    },
  })
);
