import { IntPolicyDto } from 'generated';
import { IntCreateDashboardPolicyDto } from 'services/dashboardPolicy.service';
import { IResponse } from 'shared/interfaces/api';
import { rnd } from 'utils';

function fromLocalStorage<T>(key: string): T | null {
  const raw = localStorage.getItem(key);
  if (raw) {
    return JSON.parse(raw) as T;
  }
  return null;
}

function toLocalStorage<T>(key: string, value: T) {
  localStorage.setItem(key, JSON.stringify(value));
}

class PolicyDemoData {
  policies: IntPolicyDto[] = fromLocalStorage('policies') || [];

  save = () => toLocalStorage('policies', this.policies);

  getAll = (): IResponse<IntPolicyDto[]> => ({
    status: 200,
    data: this.policies,
  });

  createPolicy = (
    _: undefined,
    data?: IntCreateDashboardPolicyDto | undefined
  ): IResponse<IntPolicyDto> => {
    if (!data) {
      return { status: 500 };
    }

    const newPolicy: IntPolicyDto = {
      policyId: rnd.guid(),
      customerId: data.customerId,
      name: data.name,
      statuses: [],
    };

    this.policies.push(newPolicy);
    this.save();

    return {
      status: 201,
      data: newPolicy,
    };
  };

  updatePolicy = (
    policyId: string,
    data?: IntPolicyDto
  ): IResponse<undefined> => {
    if (!data) {
      return { status: 500 };
    }

    this.policies = this.policies.map(policy =>
      policy.policyId === policyId ? data : policy
    );

    this.save();

    return {
      status: 200,
    };
  };

  deletePolicy = (policyId: string): IResponse => {
    this.policies = this.policies.filter(
      policy => policy.policyId !== policyId
    );
    this.save();

    return {
      status: 200,
    };
  };
}

let _demoDataInstance: PolicyDemoData | undefined;
export function policyDemoData() {
  if (!_demoDataInstance) {
    _demoDataInstance = new PolicyDemoData();
  }
  return _demoDataInstance;
}
