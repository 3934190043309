import { createStyles, makeStyles } from '@mui/styles';
import { fontSettings } from 'styles/themes/fontSettings';
import { pxToRem } from 'styles/themes/makeAtlasTheme';

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      '& > div': {
        display: 'flex',
        alignItems: 'center',
        '& > div': {
          paddingLeft: pxToRem(8),
        },
      },
    },
    tooltip: {
      '& > div': {
        backgroundColor: theme.palette.background.default,
        fontFamily: fontSettings.Pebble,
        fontSize: pxToRem(14),
        padding: `${pxToRem(10)} ${pxToRem(12)}`,
        borderRadius: pxToRem(10),
        color: '#FFF',
        '& > span': {
          color: theme.palette.background.default,
        },
      },
    },
  })
);
