import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => {
  const { height } = theme.settings.components.navigation.menuItem;

  return createStyles({
    root: {
      display: 'flex',
      height,
      width: height,
      paddingLeft: 3,
      boxSizing: 'content-box',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
      },
    },
    menuClosed: {
      position: 'absolute',
      width: '30%',
      '&:last-child': {
        right: 0,
      },
      '&:first-of-type': {
        left: 0,
      },
    },
  });
});
