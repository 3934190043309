import { ButtonBase } from '@mui/material';
import classNames from 'classnames';
import AtlasIcon from 'components/AtlasIcon';
import React from 'react';
import { useStyles } from './SideNavCloser.styles';

export const closerWidth = 16;

interface IProps {
  onClick?: () => void;
  isOpen: boolean;
  alignLeft?: boolean;
}

const SideNavCloser: React.FC<IProps> = ({ alignLeft, isOpen, onClick }) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.root)}>
      <ButtonBase
        className={classNames(
          classes.button,
          { [classes.buttonOpen]: isOpen },
          { [classes.buttonAlignLeft]: alignLeft }
        )}
        onClick={onClick}
      >
        <AtlasIcon type="ChevronLeft" size={18} className={classes.icon} />
      </ButtonBase>
    </div>
  );
};

export default SideNavCloser;
