import { snackbar } from 'components/Snackbar';
import { IntDealerDto } from 'generated';
import i18n from 'i18n';
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { dealerAPI } from 'services/dealer.service';
import { RootStore } from './rootStore';
import { StoreBase } from './storeBase';

export class DealerStore extends StoreBase {
  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this);
  }

  @observable _dealers?: IntDealerDto[] = undefined;
  @observable _loading: boolean = false;

  getDealersInitiated = false;

  @computed get availableDealers(): IntDealerDto[] {
    if (!this.getDealersInitiated) {
      this.getDealers();
    }
    return this._dealers || [];
  }

  @action.bound async getDealers() {
    this.getDealersInitiated = true;

    const response = await this.httpGet(
      dealerAPI.getAll,
      {
        page: 0,
        pageSize: 99999,
        orderBy: null,
        orderDesc: null,
        search: null,
        filters: null,
      },
      loading => {
        this.setLoading(loading);
      }
    );

    runInAction(() => {
      if (response.status === 200 && response.data) {
        this._dealers = response.data.rows;
      } else if (![204, 401, 403].includes(response.status)) {
        snackbar(i18n.t('error.loading.dealers'), {
          variant: 'error',
        });
      }
    });
  }

  @action.bound setLoading(loading: boolean) {
    this._loading = loading;
  }
}
