import { Typography } from '@mui/material';
import MultiDialog from 'components/MultiDialog';
import { IntPolicyDto, IntPolicyUsageDto } from 'generated';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'store';
import PolicyUsage from './PolicyUsage';

interface IProps {
  isOpen: boolean;
  close: () => void;
  policy: IntPolicyDto;
  usages: IntPolicyUsageDto[];
  isUnauthorized: boolean;
  isLoading: boolean;
}

const ConfirmDeletePolicy: React.FC<IProps> = ({
  isOpen,
  close,
  policy,
  usages,
  isUnauthorized,
  isLoading,
}) => {
  const {
    policyStore: { deletePolicy },
  } = useStores();
  const { t } = useTranslation('policy');

  return (
    <MultiDialog
      title={t('action.delete_policy')}
      type={isUnauthorized ? 'simple' : 'confirm'}
      show={isOpen}
      isLoading={isLoading}
      onClose={() => {
        close();
      }}
      onConfirm={() => {
        deletePolicy(policy);
        close();
      }}
      acceptButtonText={t('common:action.yes')}
      cancelButtonText={t('common:action.cancel')}
    >
      {isUnauthorized ? (
        <Typography marginBottom={4}>{t('delete_not_allowed')}</Typography>
      ) : (
        <>
          <PolicyUsage usages={usages} />
          <Typography marginBottom={4}>
            {t('confirm_delete', { name: policy.name })}
          </Typography>
        </>
      )}
    </MultiDialog>
  );
};

export default observer(ConfirmDeletePolicy);
