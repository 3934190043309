import { AutoComplete } from 'components/Inputs';
import { useCallback, useEffect, useState } from 'react';
import { FilterOption } from './filterOption';
import { FilterProps } from './FilterProps';

interface IOptionFilterProps extends FilterProps<string | number | null> {
  options: FilterOption[];
}

type NewValueType = string | number | null | undefined;

export const OptionFilter: React.FC<IOptionFilterProps> = ({
  options,
  filter,
  onChange,
}) => {
  const getValue = useCallback(
    (newValue: NewValueType) => {
      if (newValue === 0) {
        return 0;
      } else if (newValue === null) {
        return null;
      } else {
        return newValue || options[0].value;
      }
    },
    [options]
  );
  const [value, setValue] = useState(getValue(filter?.value));

  useEffect(() => {
    setValue(getValue(filter?.value));
  }, [filter, getValue]);

  return (
    <AutoComplete
      blurOnSelect
      options={options}
      onChange={newValue => onChange(getValue(newValue))}
      label=""
      dense
      getOptionLabel={opt => opt.label}
      getOptionValue={opt => opt.value}
      value={value}
    />
  );
};
