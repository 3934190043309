import { emptyGuid } from 'components/Auth/utils';
import { TableData } from 'components/Table';
import { subDays, subHours } from 'date-fns';
import {
  AssetIdentifierType,
  AssetState,
  ConnectedStatus,
  IntAssetIdentifierDto,
  IntAssetPropertiesDto,
  IntAssetTableDto,
  IntAttributeDto,
  IntAttributeResourceType,
  IntFilterOptionsResponseDto,
  IntSearchDto,
  TerminalPowerSource,
  TerminalPowerVoltageStatus,
} from 'generated';
import { IResponse } from 'shared/interfaces/api';
import { paginate, rnd } from 'utils';
import {
  ITempAssetEventDto,
  ITempAssetEventsFeedDto,
  ITempAssetLast7DaysUnprocessedDto,
} from '../asset.service';

const attributeKeys = [
  'City',
  'ApartmentId',
  'VehicleRef',
  'MaintenanceUnit',
  'Building',
  'Floor',
  'SubSpace',
];

class AssetDemoData {
  private _attributesInitialized = false;
  private _activeAttributes: IntAttributeDto[] = [];

  private _initAttributes() {
    if (!this._attributesInitialized) {
      const unavailableKeys = Array.from(
        new Set(
          rnd.array(
            rnd.int(2, attributeKeys.length),
            () => attributeKeys[rnd.int(0, attributeKeys.length - 1)]
          )
        )
      );

      this._activeAttributes = unavailableKeys.map(key => ({
        key,
        value: rnd.int(10000, 99999).toString(),
        values: null,
        definitionId: rnd.guid(),
        resourceId: rnd.guid(),
        resourceType: IntAttributeResourceType.Asset,
      }));

      this._attributesInitialized = true;
    }
  }

  private _assets: IntAssetTableDto[] | undefined;
  get assets() {
    if (!this._assets) {
      this._assets = rnd.array<IntAssetTableDto>(70, i => ({
        assetId: `asset_${i}`,
        displayName: `${rnd.int(100, 999)} ${rnd.customerName()} ECR${rnd.int(
          100,
          999
        )}EL#${rnd.int(111111, 999999)}`,
        connected: rnd.enum(ConnectedStatus),
        customerId: rnd.guid(),
        customerName: rnd.customerName(),
        make: rnd.item('FM Mattsson', 'Volvo', 'Telia'),
        model: rnd.modelNumber(),
        type: 'Type',
        lastConnected: subDays(new Date(), i),
        powerInfo: {
          terminalId: rnd.guid(),
          powerVoltageStatus: rnd.enum(TerminalPowerVoltageStatus),
          powerSource: TerminalPowerSource.Battery,
        },
        address: rnd.address(),
        building: '',
        serialNumber: rnd.guid(),
        city: rnd.item(
          'Stockholm',
          'Uppsala',
          'Göteborg',
          'Malmö',
          'Örnsköldsvik'
        ),
        installationDate: rnd.date.past(),
        maintenanceUnit: '',
        referenceLabel: '',
        state: rnd.weighted(
          { value: AssetState.Active, weight: 10 },
          { value: AssetState.Terminated, weight: 1 },
          { value: AssetState.Provisioned, weight: 1 }
        ),
        subSpace: '',
        vehicleRef: '',
        serviceMode: false,
        apartmentId: `${rnd.int(10, 25)}${rnd.int(10, 29)}`,
        floor: rnd.int(1, 10).toString(),
        assetAttributes: {
          [emptyGuid]: [123456879.19098989, 1337, 9008898787],
        },
        assetTypeId: rnd.iccid(),
        dealerId: rnd.iccid(),
      }));
    }
    return this._assets;
  }

  singleAsset: IntAssetPropertiesDto = {
    assetId: rnd.guid(),
    displayName: `${rnd.item<string>(
      'Asset',
      'New_Asset',
      'Old_Asset'
    )} ${rnd.int(1, 100)}`,
    connected: rnd.enum(ConnectedStatus),
    customerId: rnd.guid(),
    customerName: rnd.customerName(),
    make: rnd.item<string>('Make 1', 'Make 2', 'Make 3'),
    model: rnd.item<string>('Model A', 'Model B', 'Model C'),
    type: rnd.item<string>('Type 1', 'Type 2', 'Type 3'),
  };

  getAllAssets = (
    data?: IntSearchDto
  ): IResponse<TableData<IntAssetTableDto>> => {
    return {
      status: 200,
      data: paginate(this.assets, data),
    };
  };

  getAttributeOptions = (
    params: IntSearchDto
  ): IResponse<TableData<IntFilterOptionsResponseDto>> => {
    const attributeOptions: IntFilterOptionsResponseDto[] = rnd.array(
      50,
      i => ({
        value: `attribute-${i}`,
        count: rnd.int(1, 100),
      })
    );
    return {
      status: 200,
      data: paginate(attributeOptions, params),
    };
  };

  getAsset = (): IResponse<IntAssetPropertiesDto> => ({
    status: 200,
    data: this.singleAsset,
  });

  getAssetsByTerminalId = (): IResponse<IntAssetPropertiesDto[]> => ({
    status: 200,
    data: [this.singleAsset],
  });

  getIdentifiers = (
    params: IntSearchDto
  ): IResponse<TableData<IntAssetIdentifierDto>> => {
    const identifiers: IntAssetIdentifierDto[] = rnd.array(3, () => ({
      assetId: rnd.guid(),
      assetIdentifierId: rnd.guid(),
      identifier: `Identifier ${rnd.int(10, 9999)}`,
      type: rnd.enum(AssetIdentifierType),
    }));

    return {
      status: 200,
      data: paginate(identifiers, params),
    };
  };

  getAttributes = (
    params: IntSearchDto
  ): IResponse<TableData<IntAttributeDto>> => {
    this._initAttributes();

    return {
      status: 200,
      data: paginate(this._activeAttributes, params),
    };
  };

  getLast7DaysUnprocessedData =
    (): IResponse<ITempAssetLast7DaysUnprocessedDto> => ({
      status: 200,
      data: {
        series: [
          {
            label: 'Temperature',
            values: rnd.array<number>(5, () => rnd.int(20, 40)),
            valueSuffix: '°C',
          },
          {
            label: 'Humidity',
            values: rnd.array<number>(5, () => rnd.int(30, 50)),
            valueSuffix: '%',
          },
        ],
      },
    });

  get eventsFeed() {
    let currentDate = new Date();

    const generateEvent = (): ITempAssetEventDto => {
      currentDate = subHours(currentDate, rnd.int(1, 24));

      return {
        date: currentDate,
        values: [rnd.countryCode(), rnd.operator(), rnd.ip()],
      };
    };

    return {
      eventTypes: ['Country', 'Operator', 'IP'],
      events: rnd.array<ITempAssetEventDto>(50, generateEvent),
    };
  }

  getEventsFeed = (): IResponse<ITempAssetEventsFeedDto> => ({
    status: 200,
    data: this.eventsFeed,
  });

  updateGeneralInformation = (
    data?: IntAssetPropertiesDto
  ): IResponse<IntAssetPropertiesDto> => ({
    status: 200,
    data,
  });

  createAsset = (): IResponse => ({ status: 201 });

  createIdentifier = (): IResponse => ({ status: 201 });

  deleteIdentifier = (): IResponse => ({ status: 200 });

  upsertAttribute = (newAttr: IntAttributeDto): IResponse => {
    const index = this._activeAttributes.findIndex(
      attr => attr.key === newAttr.key
    );
    if (index < 0) {
      // Create
      this._activeAttributes.push(newAttr);
      return {
        status: 201,
      };
    }

    // Update
    this._activeAttributes.splice(index, 1, newAttr);

    return {
      status: 200,
    };
  };

  deleteAttribute = (key: string): IResponse => {
    const index = this._activeAttributes.findIndex(attr => attr.key === key);

    this._activeAttributes.splice(index, 1);

    return { status: 200 };
  };

  deleteAsset = (): IResponse => {
    return { status: 200 };
  };

  getAllAttributeKeys = (): IResponse<string[]> => {
    this._initAttributes(); /* Sometimes this function returns before attributes have been initialized so call this here as well */

    return {
      status: 200,
      data: attributeKeys,
    };
  };
}

export const assetDemoData = new AssetDemoData();
