import { TableData } from 'components/Table';
import {
  IntCreateIntelligenceEventRequestDto,
  IntIntelligenceEventTableDto,
  IntIntelligenceEventTableRequestDto,
} from 'generated';
import { DeleteEndpoint, PostEndpoint } from 'shared/interfaces/api';
import { rnd } from 'utils';
import { intelligenceEventDemoData } from './demoData/intelligenceEventDemoData';

interface IIntelligenceEventAPI {
  getAll: PostEndpoint<
    undefined,
    IntIntelligenceEventTableRequestDto,
    TableData<IntIntelligenceEventTableDto>
  >;
  acknowledgeEvent: PostEndpoint<string>;
  deleteEvent: DeleteEndpoint<string>;
  createIntelligenceEvent: PostEndpoint<
    undefined,
    IntCreateIntelligenceEventRequestDto,
    string
  >;
}

const baseUrl = '/api/intelligenceevent';

export const IntelligenceEventAPI: IIntelligenceEventAPI = {
  getAll: {
    url: `${baseUrl}/table`,
    mockData: intelligenceEventDemoData.getAll,
  },
  acknowledgeEvent: {
    url: eventId => `${baseUrl}/${eventId}/ack`,
    mockData: intelligenceEventDemoData.acknowledgeEvent,
  },
  deleteEvent: {
    url: eventId => `${baseUrl}/${eventId}`,
    mockData: () => ({ status: 200 }),
  },
  createIntelligenceEvent: {
    url: baseUrl,
    mockData: () => ({ status: 200, data: rnd.guid() }),
  },
};
