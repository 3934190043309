import { Grid, TextField } from '@mui/material';
import { PickersDay, StaticDatePicker } from '@mui/x-date-pickers';
import {
  pickersDayClasses,
  PickersDayProps,
} from '@mui/x-date-pickers/PickersDay';
import classNames from 'classnames';
import OptionalTooltip from 'components/OptionalTooltip';
import {
  add,
  differenceInCalendarDays,
  endOfDay,
  isSameDay,
  max as maxDate,
  min as minDate,
  startOfDay,
  startOfMonth,
  sub,
} from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './DateRangePicker.styles';
import DateTextInput from './DateTextInput';
import TimeSelect from './TimeSelect';

interface IDateRange {
  startDate: Date;
  endDate: Date;
}

interface IProps {
  value: IDateRange;
  onChange: (value: IDateRange) => void;
  isValidDate?: (value: Date | null) => void;
  isDateRangeValid: boolean;
  enableTime?: boolean;
  limitFilterDays?: boolean;
  earliestDate?: Date;
  disableFuture?: boolean;
}

function getNonNullDates(dates: (Date | undefined)[]): Date[] {
  return dates.filter(d => d !== undefined) as Date[]; // Types not being inferred on .filter() ruins my day
}

// Allowing time selection when long intervals are selected won't work well according to backenders, due to aggregations
// So we only enable time-picking for suitably short intervals
function atLeastThreeDays(a: Date, b: Date) {
  return Math.abs(differenceInCalendarDays(a, b)) >= 3;
}

const DateRangePicker: React.FC<IProps> = ({
  value,
  onChange,
  isValidDate,
  isDateRangeValid,
  enableTime,
  limitFilterDays = false,
  earliestDate,
  disableFuture = true,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [begin, setBegin] = useState<Date>(value.startDate);
  const [end, setEnd] = useState<Date | undefined>(value.endDate);
  const [hover, setHover] = useState<Date>();

  const nonNullDates = getNonNullDates([begin, end || hover]); // Since begin isn't nullable, this always returns at least one
  const minHighlightDate = minDate(nonNullDates);
  const maxHighlightDate = maxDate(nonNullDates);

  const [rightMonth, setRightMonth] = useState(startOfMonth(value.endDate));
  const [leftMonth, setLeftMonth] = useState(sub(rightMonth, { months: 1 }));

  useEffect(() => {
    setBegin(value.startDate);
    setEnd(value.endDate);

    const newRightMonth = startOfMonth(value.endDate);
    setRightMonth(newRightMonth);
    setLeftMonth(sub(newRightMonth, { months: 1 }));
  }, [value.startDate, value.endDate]);

  const handleLeftMonthChange = (newMonth: Date | null) => {
    if (!newMonth) {
      return;
    }
    setLeftMonth(newMonth);
    setRightMonth(add(newMonth, { months: 1 }));
  };

  const handleRightMonthChange = (newMonth: Date | null) => {
    if (!newMonth) {
      return;
    }
    setRightMonth(newMonth);
    setLeftMonth(sub(newMonth, { months: 1 }));
  };

  const renderDay = (
    day: Date,
    pickersDayProps: PickersDayProps<Date> &
      React.RefAttributes<HTMLButtonElement>
  ) => {
    const picker = <PickersDay {...pickersDayProps} selected={false} />;
    if (!value) {
      return picker;
    }

    const isSelected = day >= minHighlightDate && day <= maxHighlightDate;

    return React.cloneElement(picker, {
      onClick: (e: React.MouseEvent) => {
        e.stopPropagation();
        if (!begin) {
          setBegin(day);
        } else if (!end) {
          setEnd(day);
          const sorted = [begin, day].sort((a, b) => b.getTime() - a.getTime());
          onChange({
            startDate: startOfDay(sorted[1]),
            endDate: endOfDay(sorted[0]),
          });
        } else {
          setBegin(day);
          setEnd(undefined);
        }
      },
      onMouseEnter: () => setHover(day),
      className: classNames(pickersDayClasses.dayWithMargin, classes.day, {
        [pickersDayClasses.hiddenDaySpacingFiller]:
          picker.props.outsideCurrentMonth,
        [pickersDayClasses.today]: picker.props.today,
        [pickersDayClasses.disabled]: picker.props.disabled,
        [classes.selected]: isSelected,
        [classes.beginCap]: isSameDay(day, minHighlightDate),
        [classes.endCap]: isSameDay(day, maxHighlightDate),
      }),
    });
  };

  const handleFromDateChange = (date: Date | null) => {
    date && onChange({ startDate: date, endDate: value.endDate });
    isValidDate?.(date);
  };

  const handleEndDateChange = (date: Date | null) => {
    date && onChange({ startDate: value.startDate, endDate: date });
    isValidDate?.(date);
  };

  const disableTime = limitFilterDays
    ? atLeastThreeDays(value.startDate, value.endDate)
    : false;

  return (
    <Grid container wrap="nowrap" spacing={2}>
      <Grid item sm={6}>
        <Grid container spacing={2}>
          <Grid item sm={enableTime ? 8 : 12}>
            <DateTextInput
              value={value.startDate}
              onChange={handleFromDateChange}
              label={t('date.range.from')}
              isDateRangeValid={isDateRangeValid}
              errorMessage={t('validation:invalid.from_date')}
            />
          </Grid>

          {enableTime && (
            <Grid item sm={4}>
              <OptionalTooltip
                enabled={disableTime}
                text={t('date.time_disabled_tooltip')}
                placement="top"
              >
                <TimeSelect
                  label={t('date.time')}
                  value={value.startDate}
                  disabled={disableTime}
                  onChange={handleFromDateChange}
                />
              </OptionalTooltip>
            </Grid>
          )}
        </Grid>
        <StaticDatePicker
          value={leftMonth}
          displayStaticWrapperAs="desktop"
          orientation="landscape"
          renderInput={params => <TextField {...params} />}
          openTo="day"
          renderDay={(day, _, props) => renderDay(day, props)}
          onChange={date => isValidDate?.(date)}
          onMonthChange={date => handleLeftMonthChange(date)}
          views={['day']}
          disableFuture={disableFuture}
          minDate={earliestDate}
        />
      </Grid>
      <Grid item sm={6}>
        <Grid container spacing={2}>
          <Grid item sm={enableTime ? 8 : 12}>
            <DateTextInput
              value={value.endDate}
              onChange={handleEndDateChange}
              label={t('date.range.to')}
              isDateRangeValid={isDateRangeValid}
              errorMessage={t('validation:invalid.to_date')}
            />
          </Grid>

          {enableTime && (
            <Grid item sm={4}>
              <OptionalTooltip
                enabled={disableTime}
                text={t('date.time_disabled_tooltip')}
                placement="top"
              >
                <TimeSelect
                  label={t('date.time')}
                  value={value.endDate}
                  disabled={disableTime}
                  onChange={handleEndDateChange}
                />
              </OptionalTooltip>
            </Grid>
          )}
        </Grid>
        <StaticDatePicker
          value={rightMonth}
          displayStaticWrapperAs="desktop"
          orientation="landscape"
          renderInput={params => <TextField {...params} />}
          openTo="day"
          renderDay={(day, _, props) => renderDay(day, props)}
          onChange={date => isValidDate?.(date)}
          onMonthChange={date => handleRightMonthChange(date)}
          views={['day']}
          disableFuture={disableFuture}
          minDate={earliestDate}
        />
      </Grid>
    </Grid>
  );
};

export default DateRangePicker;
