import { Field, FieldProps } from 'formik';
import React from 'react';
import { Column } from 'react-table';

export function formCheckbox<T>(
  name: string,
  valueKey: string & keyof T
): Column<T> {
  return {
    id: 'formCheckbox',
    className: 'p-0',
    accessor: () => '',
    resizable: false,
    sortable: false,
    Cell: row => (
      <Field name={name}>
        {({ field: { value }, form }: FieldProps) => {
          const rowValue = row.original[valueKey];
          const id = `${name}_${rowValue}`;
          const checked =
            value && Array.isArray(value) ? value.includes(rowValue) : false;

          const handleChange: React.EventHandler<
            React.FormEvent<HTMLInputElement>
          > = e => {
            if ((e.target as any).checked as boolean) {
              console.log('ADD A VALUE!');
            } else {
              console.log('remove a value!');
            }
          };

          return (
            <div className="custom-control custom-check">
              <input
                className="custom-control-input"
                type="check"
                name={id}
                id={id}
                checked={checked}
                onChange={handleChange}
              />
              <label className="custom-control-label" htmlFor={id} />
            </div>
          );
        }}
      </Field>
    ),
    filterable: false,
    Filter: undefined,
    width: 34,
  };
}
