import { DashboardComponentType } from 'generated';
import {
  IBaseComponentSettings,
  IComponentTypeConfig,
} from 'views/Dashboard/dashboardTypes';
import { DataPropSetting } from 'views/Dashboard/DataSources/dataPropTypes';
import { IDataSourceSettings } from 'views/Dashboard/DataSources/dataSourceTypes';
import { IMapPopupSettings } from '../AzureMapsComponent/azureMapsConfig';
import PreviewStep from '../PreviewStep';
import LineChartWidget from './LineChartWidget';
import { plotBandColors } from './plotBandColors';
import GeneralSettingsStep from './SettingsSteps/GeneralSettingsStep';

export interface ILineChartSettings extends IBaseComponentSettings {
  title: string;
  dataSource: IDataSourceSettings;

  nameProp: string;
  xAxis: {
    prop: DataPropSetting;
  };
  yAxis: {
    prop: string;
    min?: number;
    max?: number;
    plotBands?: IPlotBand[]; // Legacy, replace
    step?: boolean;
  };
  popup: IMapPopupSettings;
  policyId: string;
}

export interface IPlotBand {
  color: string & keyof typeof plotBandColors;
  from?: number;
  to?: number;
  // Todo: label, maybe?
}

export const lineChartComponentConfig: IComponentTypeConfig<
  DashboardComponentType.LineChart,
  ILineChartSettings
> = {
  widgetType: DashboardComponentType.LineChart,
  icon: 'Graph',
  Component: LineChartWidget,
  settingsSteps: [
    {
      title: 'settings',
      Component: GeneralSettingsStep,
    },
    {
      title: 'final_preview',
      Component: PreviewStep,
    },
  ],
  getDefaultSettings: ({ dataSource }) => {
    // Attempt to find sensible default properties
    const nameProp = dataSource.groupProperties.find(
      dp => dp.isDefaultDisplayName
    );
    const xProp =
      dataSource.dataProperties.find(dp => dp.id === 'registerDate') ||
      dataSource.dataProperties.find(dp => dp.type === 'dateTime');
    const yProp = dataSource.dataProperties.find(dp => dp.type === 'number');

    return {
      position: { x: 0, y: 0, w: 12, h: 6 },
      title: '',
      dataSource: dataSource.settings,
      nameProp: nameProp?.id || '',
      xAxis: {
        prop: xProp?.id || '',
      },
      yAxis: {
        prop: yProp?.id || '',
        plotBands: [{ color: 'red' }, { color: 'yellow' }, { color: 'green' }],
      },
      popup: {
        rows: [],
      },
      policyId: '',
    };
  },
  fixSettings: settings => {
    if (!settings.popup) {
      settings.popup = { rows: [] };
    }
    if (!settings.policyId) {
      settings.policyId = '';
    }
  },
};
