import i18n from 'i18n';
import { usageAPI } from 'services/usage.service';
import { parseDate } from 'utils';
import { formatLocalTimezoneToUTCString } from 'utils/formatting';
import { IDataPropCategory } from '../../dataPropTypes';
import { makeGenericDataSource } from '../../makeGenericDataSource';

const category: IDataPropCategory = {
  displayName: i18n.t('dashboard:data.properties'),
};

export const simActivityDataSource = makeGenericDataSource({
  endpoint: usageAPI.getMostActiveUnits,
  getParams: filterState => {
    const { from, to } = filterState.getDateFilters();
    const {
      dashboard: { rootStore },
    } = filterState;

    return {
      top: 10,
      startDate: from ? formatLocalTimezoneToUTCString(from) : undefined,
      endDate: to ? formatLocalTimezoneToUTCString(to) : undefined,
      connectivityType: rootStore.connectivityUnitStore.currentConnectivityType,
    };
  },
  getDataProps: () => [
    {
      id: 'label',
      type: 'string',
      category,
      _get: row => row.label,
      name: i18n.t('sim:activity.label'),
      isDefaultDisplayName: true,
    },
    {
      id: 'identifier',
      type: 'string',
      category,
      _get: row => row.identifier,
      name: i18n.t('sim:activity.identifier'),
      link: row => `/connectivity/sim/${row.connectivityUnitId}`,
    },
    {
      id: 'totalConsumption',
      type: 'dataUsage',
      category,
      _get: row => row.totalConsumption,
      name: i18n.t('sim:activity.data_usage'),
      width: 90,
    },
    {
      id: 'lastActive',
      type: 'dateTime',
      category,
      _get: row => (row.lastActive ? parseDate(row.lastActive, true) : null),
      name: i18n.t('sim:activity.last_active'),
      format: 'timeAgo',
    },
  ],
});
