import {
  IntCreateSubPortalRequestDto,
  IntEditSubPortalRequestDto,
  IntSubPortalDetailsDto,
  IntSubPortalTableDto,
} from 'generated';
import {
  DeleteEndpoint,
  GetEndpoint,
  PostEndpoint,
  PutEndpoint,
  TableEndpoint,
} from 'shared/interfaces/api';
import { subPortalDemoData } from './demoData/subPortalDemoData';

interface ISubportalAPI {
  getAll: TableEndpoint<IntSubPortalTableDto>;
  impersonate: PostEndpoint<{ subPortalAlias: string | null }>;
  createSubPortal: PostEndpoint<
    undefined,
    IntCreateSubPortalRequestDto,
    IntSubPortalDetailsDto
  >;
  editSubPortal: PutEndpoint<string, IntEditSubPortalRequestDto>;
  getSubPortalDetails: GetEndpoint<string, IntSubPortalDetailsDto>;
  deleteSubPortal: DeleteEndpoint<string>;
}

const baseUrl = '/api/subportal';

export const subportalAPI: ISubportalAPI = {
  getAll: {
    url: `${baseUrl}/table`,
    mockData: subPortalDemoData.getAll,
  },
  impersonate: {
    url: `${baseUrl}/impersonate`,
    mockData: () => {
      return {
        status: 200,
      };
    },
  },
  createSubPortal: {
    url: baseUrl,
    mockData: () => {
      return {
        status: 200,
      };
    },
  },
  deleteSubPortal: {
    url: subPortalId => `${baseUrl}/${subPortalId}`,
    mockData: () => {
      return {
        status: 200,
      };
    },
  },
  editSubPortal: {
    url: subPortalId => `${baseUrl}/${subPortalId}`,
  },
  getSubPortalDetails: {
    url: subPortalId => `${baseUrl}/${subPortalId}`,
  },
};
