import { Box, Tooltip, useTheme } from '@mui/material';
import classNames from 'classnames';
import AtlasIcon from 'components/AtlasIcon';
import React from 'react';
import { getColor } from 'utils';
import { ICustomCellProps } from './CustomCellProps';

export const CustomCell: React.FC<ICustomCellProps> = ({
  variant,
  text,
  title,
  iconType,
  index,
  iconColor,
}) => {
  const classes = classNames('custom-cell', {
    'text-danger': variant === 'error',
    'text-warning': variant === 'warning',
    'text-success': variant === 'success',
    'text-info': variant === 'default',
    'text-unconfigured': variant === 'unconfigured',
    'text-new': variant === 'new',
  });
  const theme = useTheme();
  const alignment = !text && iconType ? 'center' : 'flex-start';
  const margin = text ? '-5px 0' : '0 0';

  const mainContents = (
    <>
      {iconType && (
        <Box style={{ margin }}>
          <AtlasIcon
            type={iconType}
            style={{ color: iconColor || getColor(variant, theme) }}
            size="19px"
          />
        </Box>
      )}
      {text && <Box ml={iconType ? 2 : undefined}>{text}</Box>}
    </>
  );

  const textContent = (
    <Box
      className={classes}
      display="flex"
      justifyContent={alignment}
      alignItems="center"
    >
      {mainContents}
    </Box>
  );

  if (title && typeof index === 'number') {
    return (
      <Tooltip arrow title={title} placement="bottom">
        {textContent}
      </Tooltip>
    );
  }

  return textContent;
};
