import { Role } from 'components/Auth/Role';
import { snackbar } from 'components/Snackbar';
import {
  ConnectivityUnitLocalizedStatus,
  ConnectivityUnitProfileActivationStatus,
  ConnectivityUnitProfileDownloadStatus,
  IntConnectivityUnitAccessDetailsDto,
  IntConnectivityUnitDto,
  IntConnectivityUnitProfileDto,
  IntIpAllocationType,
  IntLocaleDto,
  IntSubscriptionStatusRequest,
  IntUnitConsumptionDataDto,
  IntUnitConsumptionSmsDto,
} from 'generated';
import i18n from 'i18n/i18n';
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { apnHubAPI } from 'services/apnHub.service';
import {
  connectivityUnitAPI,
  IGetLocalesParams,
} from 'services/connectivityUnit.service';
import { usageAPI } from 'services/usage.service';
import { ResponseError } from 'shared/interfaces/api';
import { RootStore } from 'store/rootStore';
import { StoreBase } from 'store/storeBase';
import { parseDate } from 'utils';
import { SubscriptionAssign } from 'views/Connectivity/Sim/Details/SIMSubscriptions/SubscriptionActionWizard/SubscriptionActionWizard';

type DateString = string | undefined | null;
export class ConnectivityUnit extends StoreBase {
  @observable id: string;
  @observable startDateString: DateString;
  @observable endDateString: DateString;

  @observable showDataTrafficModal = false;
  @observable postingDataTraffic = false;

  @observable detailsLoading = false;
  @observable details: IntConnectivityUnitDto | undefined;
  @observable detailsError = false;

  @observable lastActive: Date = new Date(0);
  @observable profiles: IntConnectivityUnitProfileDto[] = [];

  @observable apnHubAccessDetails:
    | IntConnectivityUnitAccessDetailsDto
    | undefined;
  @observable apnHubAccessDetailsLoading = false;
  @observable apnHubAccessDetailsError = '';

  @observable isTerminatingSession = false;
  @observable postingSubscriptionStatus = false;
  @observable configIpModalIsOpen = false;

  @observable isLoadingLocales = false;
  @observable localesError: ResponseError | undefined;
  @observable locales: IntLocaleDto[] = [];
  changeStateTimerId = 0;

  @observable blockDataTraffic: boolean | undefined | null = undefined;

  @observable loadingUsage = false;
  @action.bound setUsageLoading(loading: boolean) {
    this.loadingUsage = loading;
  }

  @observable dataUsage: IntUnitConsumptionDataDto | undefined;
  @observable dataUsageError: ResponseError = {
    error: false,
    message: '',
  };

  @observable smsUsage: IntUnitConsumptionSmsDto | undefined;
  @observable smsUsageError: ResponseError = {
    error: false,
    message: '',
  };

  genericErrorMessage = i18n.t('generic.error');

  constructor(
    unitId: string,
    startDateString: DateString,
    endDateString: DateString,
    rootStore: RootStore
  ) {
    super(rootStore);
    makeObservable(this);
    this.id = unitId;
    this.startDateString = startDateString;
    this.endDateString = endDateString;

    this.getDetails();
  }

  @action.bound
  dispose() {
    window.clearTimeout(this.changeStateTimerId);
  }

  @computed
  get detailsReady() {
    return this.detailsError || this.details !== undefined;
  }

  @computed
  get apnHubDetailsReady() {
    return (
      this.apnHubAccessDetailsError.length > 0 ||
      this.apnHubAccessDetails !== undefined
    );
  }

  @computed
  get subscriptionsDisabled() {
    return (
      this.profiles.some(
        p =>
          p.downloadStatus === ConnectivityUnitProfileDownloadStatus.InProgress
      ) ||
      this.detailsError ||
      this.isTerminated ||
      this.detailsLoading ||
      this.isUnitChangingStatus ||
      this.actionsDisabled ||
      this.isUnitLoading
    );
  }

  @computed
  get actionsDisabled() {
    return (
      !this.rootStore.authStore.hasRole(Role.RoleNameEditConnectivityUnit) ||
      this.details?.activationStatus !==
        ConnectivityUnitProfileActivationStatus.Active ||
      this.details?.localizedStatus === ConnectivityUnitLocalizedStatus.Updating
    );
  }

  @computed
  get isUnitChangingStatus() {
    return (
      this.details?.localizedStatus ===
        ConnectivityUnitLocalizedStatus.Updating ||
      this.details?.activationStatus ===
        ConnectivityUnitProfileActivationStatus.InProgress
    );
  }

  @computed
  get isTerminated() {
    return (
      this.details?.activationStatus ===
      ConnectivityUnitProfileActivationStatus.Terminated
    );
  }

  @computed
  get roamingText() {
    if (this.details && this.details.enabledProfile) {
      const { currentOperator, currentLocation } = this.details.enabledProfile;
      return i18n.t(`sim:roaming.${this.details.roaming}`, {
        dataRoamingText: `(${currentOperator} ${currentLocation})`,
      });
    }
    return undefined;
  }

  @computed
  get dataTrafficIcon() {
    if (this.apnHubDetailsReady && !this.apnHubAccessDetailsLoading) {
      switch (this.apnHubAccessDetails?.blockDataTraffic) {
        case true:
          return 'StatusError';
        case false:
          return 'StatusOk';
        case null:
        default:
          return 'StatusWarning';
      }
    } else {
      return 'StatusPending';
    }
  }

  @computed
  get isUnitLoading() {
    return this.detailsLoading || this.postingSubscriptionStatus;
  }

  @action.bound
  apnHubAccessDetailsLoadingFn(loading: boolean) {
    this.apnHubAccessDetailsLoading = loading;
  }

  @action.bound
  async getDetails() {
    this.detailsError = false;
    await this.httpGet(connectivityUnitAPI.getDetails, this.id, loading => {
      runInAction(() => {
        this.detailsLoading = loading;
      });
    }).then(response => {
      runInAction(() => {
        if (response.status === 200 && response.data) {
          this.details = response.data;
          this.profiles = response.data.profiles;
          this.lastActive = parseDate(new Date(response.data.lastActive), true);
          this.getDataUsage(this.startDateString, this.endDateString);
          this.getSmsUsage(this.startDateString, this.endDateString);
          this.getApnHubAccessDetails();
          if (this.isUnitChangingStatus) {
            this.changeStateTimerId = window.setTimeout(() => {
              this.getDetails();
            }, 10000);
          }
        } else {
          snackbar(i18n.t('sim:details_error'), {
            variant: 'error',
          });
          this.detailsError = true;
        }
      });
    });
  }

  @action.bound
  async postSetSubscriptionStatus(targetStatus: IntSubscriptionStatusRequest) {
    await this.httpPost(
      connectivityUnitAPI.setSubscriptionStatus,
      {
        params: {
          connectivityUnitId: this.id,
          targetStatus,
        },
      },
      loading => {
        runInAction(() => {
          this.postingSubscriptionStatus = loading;
        });
      }
    ).then(resp => {
      runInAction(() => {
        if (resp.status === 200) {
          this.getDetails();
        } else {
          snackbar(resp.statusText, { variant: 'error' });
        }
      });
    });
  }

  @action.bound
  async getLocales(params: IGetLocalesParams) {
    this.localesError = { error: false };
    return this.httpGet(connectivityUnitAPI.getLocales, params, loading => {
      runInAction(() => {
        this.isLoadingLocales = loading;
      });
    }).then(response => {
      runInAction(() => {
        if (response.status === 200 && response.data) {
          this.locales = response.data;
        } else {
          this.localesError = {
            error: true,
            message:
              response.status === 204
                ? i18n.t('sim:no_subscriptions')
                : response.statusText || this.genericErrorMessage,
          };
        }
      });
    });
  }

  @action.bound
  setShowDataTrafficModal(show: boolean) {
    this.showDataTrafficModal = show;
  }

  @action.bound
  async postSetDataTraffic() {
    await this.httpPost(
      apnHubAPI.setDataTrafficStatus,
      {
        params: {
          connectivityUnitId: this.id,
          blockDataTraffic: !this.apnHubAccessDetails?.blockDataTraffic,
        },
      },
      loading => {
        runInAction(() => {
          this.postingDataTraffic = loading;
        });
      }
    ).then(response => {
      snackbar(
        i18n.t([
          `sim:message.change_data_traffic.${response.status}`,
          'sim:message.change_data_traffic.error',
        ]),
        { variant: response.status === 200 ? 'success' : 'error' }
      );
      if (response.status === 200) {
        runInAction(() => {
          this.getApnHubAccessDetails();
        });
      }
    });
    this.setShowDataTrafficModal(false);
  }

  @action.bound
  async getApnHubAccessDetails() {
    this.apnHubAccessDetailsError = '';
    await this.httpGet(
      connectivityUnitAPI.getApnHubAccessDetails,
      this.id,
      this.apnHubAccessDetailsLoadingFn
    ).then(response => {
      runInAction(() => {
        if (response.status === 200 && response.data) {
          this.apnHubAccessDetails = response.data;
          this.blockDataTraffic = response.data.blockDataTraffic;
        } else {
          this.blockDataTraffic = null;
          this.apnHubAccessDetailsError =
            response.status === 204
              ? i18n.t('sim:message.apn.missing_device')
              : this.genericErrorMessage;
        }
      });
    });
  }

  @action.bound
  async activateLocale(values: SubscriptionAssign) {
    return this.httpPost(connectivityUnitAPI.activateLocale, {
      params: {
        connectivityUnitId: this.id,
        localeId: values.localeId,
      },
      data: values,
    });
  }

  @action.bound
  async activateBootstrap(values: SubscriptionAssign) {
    return this.httpPost(connectivityUnitAPI.activateBootstrap, {
      params: this.id,
      data: values,
    });
  }

  @action.bound
  async downloadLocale(localeId: string) {
    return this.httpPost(connectivityUnitAPI.downloadLocale, {
      params: {
        connectivityUnitId: this.id,
        localeId,
      },
    });
  }

  @action.bound
  async terminateSession() {
    if (this.apnHubAccessDetails) {
      const response = await this.httpDelete(
        connectivityUnitAPI.terminateSession,
        {
          params: this.apnHubAccessDetails.activeSession.uuid.apnHubSessionUUID,
        },
        this.apnHubAccessDetailsLoadingFn
      );
      if (response.status === 200) {
        snackbar(i18n.t('sim:terminate_session.success'), {
          variant: 'success',
        });
        this.getApnHubAccessDetails();
      } else {
        snackbar(this.genericErrorMessage, { variant: 'error' });
      }
    }
  }

  @action.bound
  async postLabel(newLabel: string) {
    const response = await this.httpPost(connectivityUnitAPI.setLabel, {
      params: this.id,
      data: { label: newLabel },
    });

    if (response.status === 200) {
      runInAction(() => {
        if (this.details) {
          this.details.label = newLabel;
        }
      });
    }

    return response;
  }

  @computed
  get label() {
    return this.details?.label || '';
  }

  @action.bound async getDataUsage(
    startDateString: DateString,
    endDateString: DateString
  ) {
    await this.httpPost(
      usageAPI.getDataConsumption,
      {
        params: {
          startDate: startDateString ?? new Date(0).toISOString(),
          endDate: endDateString ?? new Date().toISOString(),
        },
        data: [this.id],
      },
      loading => this.setUsageLoading(loading)
    ).then(resp => {
      runInAction(() => {
        if (resp.status === 200 && resp.data) {
          this.dataUsage = resp.data.find(
            u => u.connectivityUnitId === this.id
          );
        } else if (resp.status !== 204) {
          this.dataUsageError = {
            error: true,
            message:
              resp.statusText ||
              i18n.t('sim:message.usage.error', { type: 'data' }),
          };
        }
      });
    });
  }

  @action.bound
  async getSmsUsage(startDateString: DateString, endDateString: DateString) {
    await this.httpPost(
      usageAPI.getSmsConsumption,
      {
        params: {
          startDate: startDateString ?? new Date(1).toISOString(),
          endDate: endDateString ?? new Date().toISOString(),
        },
        data: [this.id],
      },
      loading => this.setUsageLoading(loading)
    ).then(resp => {
      runInAction(() => {
        if (resp.status === 200 && resp.data) {
          this.smsUsage = resp.data.find(u => u.connectivityUnitId === this.id);
        } else if (resp.status !== 204) {
          this.smsUsageError = {
            error: true,
            message:
              resp.statusText ||
              i18n.t('sim:message.usage.error', { type: 'SMS' }),
          };
        }
      });
    });
  }

  @action.bound setConfigIpModalIsOpen(isOpen: boolean) {
    this.configIpModalIsOpen = isOpen;
  }

  @action.bound async saveIpAllocation(
    connectivityUnitId: string,
    customerId: string,
    intIpAllocationType: IntIpAllocationType
  ) {
    const response = await this.httpPost(connectivityUnitAPI.setIpAllocation, {
      params: {
        connectivityUnitId,
        customerId,
        intIpAllocationType,
      },
    });

    runInAction(() => {
      if (response.status === 200) {
        snackbar(i18n.t('sim:ip_address.success'), { variant: 'success' });
        this.getApnHubAccessDetails();
      } else {
        snackbar(i18n.t('sim:ip_address.error'), {
          variant: 'error',
        });
      }

      this.setConfigIpModalIsOpen(false);
    });
  }
}
