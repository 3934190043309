import { Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'store';
import { isDevelopment, isDevOrLocal } from 'utils';
import DemoWarning from './DemoWarning';
import DeveloperButton from './DeveloperButton';
import DevelopmentChip from './DevelopmentChip';
import GlobalSearch from './GlobalSearch';
import ImpersonationWarning from './ImpersonationWarning';
import NotificationPopover from './NotificationPopover';
import ProfilePopover from './ProfilePopover';

export const iconSize = 30;

const SiteTopBarContent: React.FC = () => {
  const { authStore, demoMode, layoutStore, isStaticDemo } = useStores();
  const { enablePreviewFeatures, isImpersonating } = authStore;

  return (
    <>
      <Box
        justifyContent="flex-end"
        display="flex"
        alignItems="center"
        width={1}
      >
        {enablePreviewFeatures && <GlobalSearch />}

        {!demoMode && isDevelopment && <DevelopmentChip />}
        <Box mr={4} display="flex">
          {isImpersonating && <ImpersonationWarning />}
          {demoMode && !isStaticDemo && <DemoWarning />}
          {(enablePreviewFeatures ||
            layoutStore.settingsMenuOpen ||
            isDevOrLocal) && <DeveloperButton />}
          {enablePreviewFeatures && <NotificationPopover />}
          <ProfilePopover />
        </Box>
      </Box>
    </>
  );
};

export default observer(SiteTopBarContent);
