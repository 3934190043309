import { useCallback, useState } from 'react';
import {
  DeleteEndpoint,
  GetEndpoint,
  IResponse,
  PatchEndpoint,
  PostEndpoint,
  PostPatchPutOrDeleteEndpoint,
  PostRequest,
  PutEndpoint,
} from 'shared/interfaces/api';
import { useStores } from 'store';
import { delay } from 'utils';
import {
  getDataAndStatus,
  getHttpErrorException,
  RequestType,
  useGetEndpoint,
  usePostPatchPutOrDelete,
} from './shared';

export function useDeleteOrSaveMulti<TParams, TRequestBody, TResponseData>(
  endpointFn: PostPatchPutOrDeleteEndpoint<
    TParams,
    TRequestBody,
    TResponseData
  >,
  httpMethod: RequestType
): [
  (
    params: TParams[],
    requestBody: TRequestBody[]
  ) => Promise<IResponse<TResponseData>[]>,
  boolean,
  number,
  number,
] {
  const [isLoading, setIsLoading] = useState(false);
  const { demoMode, isStaticDemo, authStore } = useStores();
  const [successCount, setSuccessCount] = useState(0);
  const [failedCount, setFailedCount] = useState(0);

  const getRequests = useCallback(
    async (
      requests: {
        request: PostRequest<TParams, TRequestBody>;
        endpoint: PostPatchPutOrDeleteEndpoint<
          TParams,
          TRequestBody,
          TResponseData
        >;
      }[],
      mockdata: boolean,
      type: RequestType
    ) => {
      return requests.map(async p => {
        const { params, data } = p.request;

        if (demoMode || isStaticDemo || mockdata) {
          await delay(500, 1000);
          return p.endpoint.mockData
            ? p.endpoint.mockData?.(params, data)
            : { status: 200 };
        } else {
          return getDataAndStatus<TParams, TRequestBody, TResponseData>(
            p.endpoint,
            p.request,
            type
          );
        }
      });
    },
    [demoMode, isStaticDemo]
  );

  const send = useCallback(
    async (params: TParams[], requestBody: TRequestBody[]) => {
      setIsLoading(true);

      const paramsWithEndpoints = params.map((value, index) => {
        const request = {
          params: value,
          data: requestBody[index] ?? undefined,
        };
        return { request, endpoint: endpointFn };
      });

      const alwaysMockData = paramsWithEndpoints.some(
        p => p.endpoint.alwaysMockData
      );

      const requests = await getRequests(
        paramsWithEndpoints,
        alwaysMockData,
        httpMethod
      );

      const incrementSuccess = () =>
        setSuccessCount(prevState => prevState + 1);
      const incrementFailed = () => setFailedCount(prevState => prevState + 1);
      const results: IResponse<TResponseData>[] = [];

      for (const r of requests) {
        let response: IResponse<TResponseData>;
        try {
          response = await r;
          [200, 201, 204].includes(response.status)
            ? incrementSuccess()
            : incrementFailed();
        } catch (error) {
          response = getHttpErrorException(error, authStore);
          incrementFailed();
        }

        results.push(response);
      }

      setIsLoading(false);
      return results;
    },
    [authStore, endpointFn, getRequests, httpMethod]
  );

  return [send, isLoading, successCount, failedCount];
}

export function usePost<TParams, TPostData, TResponseData>(
  endpoint: PostEndpoint<TParams, TPostData, TResponseData>
): [
  (
    postRequest: PostRequest<TParams, TPostData>
  ) => Promise<IResponse<TResponseData>>,
  boolean,
] {
  return usePostPatchPutOrDelete(endpoint, 'post');
}

export function usePatch<TParams, TPostData, TResponseData>(
  endpoint: PatchEndpoint<TParams, TPostData, TResponseData>
): [
  (
    postResponse: PostRequest<TParams, TPostData>
  ) => Promise<IResponse<TResponseData>>,
  boolean,
] {
  return usePostPatchPutOrDelete(endpoint, 'patch');
}

export function usePut<TParams, TPutData, TResponseData>(
  endpoint: PutEndpoint<TParams, TPutData, TResponseData>
): [
  (
    putResponse: PostRequest<TParams, TPutData>
  ) => Promise<IResponse<TResponseData>>,
  boolean,
] {
  return usePostPatchPutOrDelete(endpoint, 'put');
}

export function useDelete<TParams, TPutData, TResponseData>(
  endpoint: DeleteEndpoint<TParams, TPutData, TResponseData>
): [
  (
    deleteResponse: PostRequest<TParams, TPutData>
  ) => Promise<IResponse<TResponseData>>,
  boolean,
] {
  return usePostPatchPutOrDelete(endpoint, 'delete');
}

export function useGet<TParams, TData>(
  endpoint: GetEndpoint<TParams, TData>,
  options: { defaultIsLoading?: boolean } = {}
): [(params: TParams) => Promise<IResponse<TData>>, boolean] {
  const { defaultIsLoading = true } = options;
  return useGetEndpoint(endpoint, 'get', defaultIsLoading);
}
