import { VirtualElement } from '@popperjs/core';
import * as azureMaps from 'azure-maps-control';
import { action, makeObservable, observable } from 'mobx';
import { AzureMapSetCameraOptions } from './hooks/useAutoZoom';

export class MapWidgetState {
  map: azureMaps.Map;

  constructor(map: azureMaps.Map) {
    makeObservable(this);

    this.map = map;
  }

  @observable selectedFeatureId = '';
  @action.bound selectFeature(id = '', moveCamera = false) {
    if (id === this.selectedFeatureId) {
      return;
    }
    this.selectedFeatureId = id;
    window.setTimeout(this.updateTooltip, moveCamera ? 310 : 100);

    const marker = this.markersById[id];
    if (marker && moveCamera) {
      this.map.setCamera({
        center: marker.getOptions().position,
        zoom: 16.4,
        duration: 280,
        type: 'fly',
      });
    }
  }

  @observable tooltipElement: VirtualElement | null = null;
  @action.bound onMapMoveStart() {
    this.tooltipElement = null;
  }

  @action.bound onMapMoveEnd() {
    this.updateTooltip();
  }

  @observable cameraOptions: AzureMapSetCameraOptions | undefined;
  @action.bound setDefaultCameraPosition(
    cameraOptions: AzureMapSetCameraOptions
  ) {
    this.cameraOptions = cameraOptions;
  }
  @action.bound resetCamera() {
    this.map.setCamera({ ...this.cameraOptions, type: 'ease', duration: 280 });
    this.selectFeature();
  }

  @action.bound updateTooltip() {
    if (this.selectedFeatureId) {
      const marker = this.markersById[this.selectedFeatureId];
      const position = marker?.getOptions().position;
      const camera = this.map.getCamera();

      if (marker && camera.bounds && position) {
        const isVisible = azureMaps.data.BoundingBox.containsPosition(
          camera.bounds,
          position
        );
        if (isVisible) {
          const markerBoundingRect = marker
            .getElement()
            .getBoundingClientRect();
          this.tooltipElement = {
            getBoundingClientRect: () => markerBoundingRect,
          };
        }
      }
    } else {
      this.tooltipElement = null;
    }
  }

  @action.bound closeTooltip() {
    this.tooltipElement = null;
    this.selectedFeatureId = '';
  }

  @observable markersById: Record<string, azureMaps.HtmlMarker> = {};
  @action.bound storeMarker(
    id: string | string[],
    feature: azureMaps.HtmlMarker
  ) {
    if (typeof id === 'string') {
      this.markersById[id] = feature;
    } else {
      id.forEach(singleId => (this.markersById[singleId] = feature));
    }
  }
}
