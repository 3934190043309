import {
  Autocomplete,
  AutocompleteClasses,
  AutocompleteInputChangeReason,
  Checkbox,
  Chip,
  ChipClasses,
  PopperProps,
  TextField,
  TextFieldClasses,
} from '@mui/material';
import LoadingSpinner from 'components/LoadingSpinner';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStyles } from './InfiniteScrollAutoComplete.styles';
import ScrollPaginationListBox, {
  IListBoxProps,
} from './ScrollPaginationListBox';

type IProps<TOption, TValue> = {
  label: string;
  id: string;
  placeholder?: string;
  clearable?: boolean;
  options: TOption[];
  hasMore: boolean;
  getOptionLabel: (option: TOption) => string;
  getOptionValue: (option: TOption) => TValue;
  isLoading?: boolean;
  disabled?: boolean;
  dataCy?: string;
  search: string;
  onSearchChange: (
    newSearch: string,
    reason: AutocompleteInputChangeReason
  ) => void;
  fetchMore: () => void;
  styles?: Partial<AutocompleteClasses>;
  textFieldStyles?: Partial<TextFieldClasses>;
  tagStyles?: Partial<ChipClasses>;
  onBlur?: React.FocusEventHandler;
  popperComponent?: React.JSXElementConstructor<PopperProps>;
} & ValueOptions<TOption>;

type ValueOptions<TOption> =
  | {
      multiple: true;
      value: TOption[] | undefined;
      onChange: (newValue: TOption[]) => void;
    }
  | {
      multiple: false | undefined;
      value: TOption | null;
      onChange: (newValue: TOption | null) => void;
    };

function InfiniteScrollAutoComplete<
  TOption extends object | string,
  TValue extends string | number,
>(props: IProps<TOption, TValue>) {
  const {
    label,
    options,
    placeholder,
    getOptionLabel,
    onChange,
    clearable = false,
    isLoading,
    disabled,
    dataCy,
    value,
    getOptionValue,
    hasMore,
    search,
    onSearchChange,
    fetchMore,
    id,
    multiple = false,
    onBlur,
    styles,
    textFieldStyles,
    tagStyles,
    popperComponent,
  } = props;
  const classes = useStyles();

  const listBoxProps: IListBoxProps = {
    id,
    dataLength: options.length,
    hasMore: isLoading || hasMore,
    fetchMore,
  };

  return (
    <Autocomplete
      PopperComponent={popperComponent}
      size="small"
      options={options}
      classes={{
        inputRoot: classes.inputRoot,
        input: classes.input,
        ...styles,
      }}
      disableClearable={!clearable}
      inputValue={search}
      onInputChange={(_, newValue, reason) => {
        onSearchChange(newValue, reason);
      }}
      loading={isLoading}
      autoComplete
      autoHighlight
      disabled={disabled}
      multiple={multiple}
      onChange={(_, newValue) => {
        onChange(newValue as any);
      }}
      isOptionEqualToValue={(option, selectedValue) =>
        getOptionValue(option) === getOptionValue(selectedValue)
      }
      getOptionLabel={getOptionLabel}
      value={value}
      ListboxComponent={ScrollPaginationListBox as any} // Cast required since we inject extra props using ListboxProps
      ListboxProps={listBoxProps}
      fullWidth
      limitTags={1}
      renderTags={(value: readonly TOption[], getTagProps) =>
        value.map((option: TOption, index: number) => (
          <Chip
            variant="filled"
            data-cy={`${label}-${getOptionLabel(option)}`}
            size="small"
            classes={tagStyles}
            label={getOptionLabel(option)}
            {...getTagProps({ index })}
          />
        ))
      }
      disableCloseOnSelect={multiple}
      renderOption={(listItemProps, option, { selected }) => (
        <li {...listItemProps} key={getOptionValue(option)}>
          {multiple && (
            <Checkbox checked={selected} size="small" color="primary" />
          )}
          {getOptionLabel(option)}
        </li>
      )}
      openOnFocus
      onBlur={onBlur}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            classes: textFieldStyles,
            endAdornment: (
              <>
                {isLoading ? (
                  <div className={classes.loadingSpinner}>
                    <LoadingSpinner color="inherit" size={18} />
                  </div>
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          placeholder={placeholder}
          data-cy={dataCy}
        />
      )}
    />
  );
}

export default observer(InfiniteScrollAutoComplete);
