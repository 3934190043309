import { Box, Grid } from '@mui/material';
import { Option } from 'components/FormFields';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import ComponentContainer from 'views/Dashboard/ComponentContainer';
import { IDashboardComponent } from 'views/Dashboard/dashboardTypes';
import { useDataSource } from 'views/Dashboard/DataSources';
import { getDataPropId } from 'views/Dashboard/DataSources/getDataProp';
import { Control } from 'views/Dashboard/SettingControls';
import PolicyControl from '../../NumberGridComponent/SettingsSteps/PolicyControl';
import PopupDetailsSettings from '../../PopupDetailsSettings';
import SelectDataPropButton from '../../TableComponent/SettingsSteps/SelectDataPropButton';
import { AzureMapsStyle, IAzureMapsSettings } from '../azureMapsConfig';

const GeneralSettingsStep: IDashboardComponent<IAzureMapsSettings> = ({
  component,
}) => {
  const { t } = useTranslation('dashboard');
  const { settings } = component;
  const dataSource = useDataSource(settings.dataSource);

  // Note: 'satellite' would be neat but does not work - presumably our licence is not enough?
  const mapStyleOptions: Option<AzureMapsStyle>[] = [
    {
      label: t('azure_maps.styles.roads'),
      value: 'roads',
    },
    {
      label: t('azure_maps.styles.night'),
      value: 'night',
    },
    {
      label: t('azure_maps.styles.grayscale.light'),
      value: 'grayscale_light',
    },
    {
      label: t('azure_maps.styles.grayscale.dark'),
      value: 'grayscale_dark',
    },
    {
      label: t('azure_maps.styles.high_contrast_dark'),
      value: 'high_contrast_dark',
    },
  ];

  const addPivot = (id: string | null) =>
    id &&
    runInAction(() => {
      settings.groupProperties.push(id);
    });

  return (
    <Grid container spacing={4}>
      <Grid item sm={4}>
        <Control.Group label={t('azure_maps.style_settings')}>
          <Control.AutoComplete
            label={t('azure_maps.map_style')}
            object={settings}
            name="style"
            options={mapStyleOptions}
          />
          <Control.Switch
            label={t('action.azure_maps.enable.style_picker')}
            name="stylePicker"
            object={settings.controls}
          />
          <Control.Switch
            label={t('action.azure_maps.enable.zoom_buttons')}
            name="zoomButtons"
            object={settings.controls}
          />
          <Control.Switch
            label={t('action.azure_maps.enable.geofence')}
            name="geofence"
            object={settings.controls}
          />
        </Control.Group>

        <Control.Group label={t('popup_settings.label.add_info')}>
          <PopupDetailsSettings
            rows={settings.popup.rows}
            dataSource={dataSource}
          />
        </Control.Group>
      </Grid>

      <Grid item sm={8}>
        <Control.Group label={t('preview')}>
          <Box height={460}>
            <ComponentContainer component={component} />
          </Box>
        </Control.Group>

        <Control.Group label={t('azure_maps.group_settings')}>
          <Control.SortableList items={settings.groupProperties}>
            {colId => (
              <div>
                {dataSource.groupProperties.find(dp => dp.id === colId)?.name ||
                  dataSource.dataProperties.find(dp => dp.id === colId)?.name ||
                  colId}
              </div>
            )}
          </Control.SortableList>

          <SelectDataPropButton
            onChange={addPivot}
            dataSource={dataSource}
            id="addPivot"
            label={t('action.azure_maps.add_group_property')}
            isOptionDisabled={dp =>
              !!settings.groupProperties.find(
                p => getDataPropId(p) === getDataPropId(dp)
              )
            }
            showDataProps={false}
          />
        </Control.Group>

        <Control.Group label={t('azure_maps.marker_settings')}>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <PolicyControl settings={settings} />
            </Grid>
          </Grid>
        </Control.Group>
      </Grid>
    </Grid>
  );
};

export default observer(GeneralSettingsStep);
