import { DashboardComponentType } from 'generated';
import {
  IBaseComponentSettings,
  IComponentTypeConfig,
} from 'views/Dashboard/dashboardTypes';
import { IDataSourceSettings } from 'views/Dashboard/DataSources/dataSourceTypes';
import GeneralSettingsStep from '../ColumnChartComponent/SettingsSteps/GeneralSettingsStep';
import PreviewStep from '../PreviewStep';
import GroupedBarChartWidget from './GroupedBarChartContainer';

export interface IGroupedBarChartSettings extends IBaseComponentSettings {
  title: string;
  dataSource: IDataSourceSettings;

  nameProp: string;
  xAxis: {
    prop: string;
  };
  yAxis: {
    prop: string;
    label?: string;
    min?: number;
    max?: number;
  };
}

export const groupedBarChartComponentConfig: IComponentTypeConfig<
  DashboardComponentType.GroupedBarChart,
  IGroupedBarChartSettings
> = {
  getDefaultSettings: ({ dataSource }) => {
    const yProp = dataSource.dataProperties.find(dp => dp.type === 'number');
    const xProp =
      dataSource.dataProperties.find(dp => dp.id === 'registerDate') ??
      dataSource.dataProperties.find(dp => dp.type === 'dateTime');

    const nameProp =
      dataSource.groupProperties?.find(dp => dp.isDefaultDisplayName) ??
      dataSource.dataProperties.find(dp => dp.isDefaultDisplayName);

    return {
      title: '',
      nameProp: nameProp?.id ?? '',
      position: { x: 0, y: 0, w: 6, h: 6 },
      dataSource: dataSource.settings,

      yAxis: {
        prop: yProp?.id ?? '',
      },
      xAxis: {
        prop: xProp?.id ?? '',
      },
    };
  },
  widgetType: DashboardComponentType.GroupedBarChart,
  icon: 'Statistics',
  Component: GroupedBarChartWidget,
  settingsSteps: [
    {
      title: 'settings',
      Component: GeneralSettingsStep,
    },
    {
      title: 'final_preview',
      Component: PreviewStep,
    },
  ],
};
