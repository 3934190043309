import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    green: {
      background: theme.palette.success.main,
      color: 'white',
    },
    yellow: {
      background: theme.palette.warning.main,
      color: '#402700',
    },
    red: {
      background: theme.palette.error.main,
      color: 'white',
    },
    overview: {
      textAlign: 'center',
      marginRight: 1,
    },
    ruleValueLabel: {
      fontSize: 16,
      fontWeight: 600,
    },
  })
);
