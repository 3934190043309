import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import classNames from 'classnames';
import AtlasIcon from 'components/AtlasIcon';
import { IntDashboardDataStatusDto } from 'generated';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AtlasIconType } from 'shared/types/atlas-icon';
import { formatNumber } from 'utils';
import { useActiveStatusRule } from '../useActiveStatusRule';
import { useStyles } from './NumberStatusValue.styles';

interface INumberValProps {
  value: number | undefined;
  decimals: number;
  status?: IntDashboardDataStatusDto;
  size: 'sm' | 'lg';
  defaultIcon?: AtlasIconType;
  label?: string;
  unit?: string;
}

const NumberStatusValue: React.FC<INumberValProps> = ({
  label,
  value = null,
  defaultIcon,
  decimals,
  unit,
  status,
  size,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const activeRule = useActiveStatusRule(status, value);
  const icon = activeRule?.icon || defaultIcon;

  return (
    <div
      style={{
        display: size === 'sm' ? 'inline-block' : 'block',
      }}
    >
      <div
        className={classNames(classes.valueRow, {
          [classes.valueRowLarge]: size === 'lg',
        })}
      >
        {(activeRule || icon) && (
          <div
            className={classNames(classes.statusCircle, {
              [classes.statusCircleSmall]: size === 'sm',
              [classes.statusCircleLarge]: size === 'lg',
              [classes.green]: activeRule?.color === 'green',
              [classes.yellow]: activeRule?.color === 'yellow',
              [classes.red]: activeRule?.color === 'red',
            })}
          >
            {size === 'lg' && icon && (
              <AtlasIcon
                type={icon as AtlasIconType}
                htmlColor="#FFF"
                size={26}
              />
            )}
          </div>
        )}

        <span
          className={classNames(classes.value, {
            [classes.valueSmall]: size === 'sm',
            [classes.valueLarge]: size === 'lg',
          })}
        >
          {value !== null ? (
            formatNumber(value, { decimals })
          ) : (
            <span className="text-muted">
              {t('not_applicable_abbreviation')}
            </span>
          )}
          {unit && <span className="text-unit">{unit}</span>}
        </span>
      </div>

      {label && (
        <Box fontSize={18} mb={2} fontWeight="bold">
          {label}
          {activeRule?.label && <>&nbsp;-&nbsp;{activeRule.label}</>}
          {activeRule?.description && (
            <Typography variant="body2" className="text-muted">
              {activeRule.description}
            </Typography>
          )}
        </Box>
      )}
    </div>
  );
};

export default observer(NumberStatusValue);
