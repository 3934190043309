import OptionalTooltip from 'components/OptionalTooltip';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { AtlasIconType } from 'shared/types/atlas-icon';
import { useStores } from 'store';
import MenuItemContainer from './MenuItem/MenuItemContainer';
import MenuItemIcon from './MenuItem/MenuItemIcon';
import MenuItemText from './MenuItem/MenuItemText';

interface IProps {
  to: string;
  icon: AtlasIconType;
  label: string;
  dataCy: string;
}

const BottomAdminMenuItem: React.FC<IProps> = ({ to, icon, label, dataCy }) => {
  const { layoutStore } = useStores();
  const { pathname } = useLocation();

  const isActive = !!matchPath(pathname, to);

  return (
    <OptionalTooltip text={label} enabled={!layoutStore.sidenavOpen}>
      <MenuItemContainer
        color={isActive ? 'active' : 'default'}
        url={to}
        dataCy={dataCy}
      >
        <MenuItemIcon iconType={icon} />
        {layoutStore.sidenavOpen && <MenuItemText>{label}</MenuItemText>}
      </MenuItemContainer>
    </OptionalTooltip>
  );
};

export default observer(BottomAdminMenuItem);
