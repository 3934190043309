import cidrRegex from 'cidr-regex';
import validator from 'validator';

const validDhGroups = [
  '1',
  '2',
  '5',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '27',
  '28',
  '29',
  '30',
  '31',
  '32',
];

export const isASCII = (value: string) => {
  return new RegExp(/^[\0-\x7F]*$/).test(value);
};

export const isIpAddress = (value: string, exp: RegExp) => {
  return cidrRegex.v4({ exact: true }).test(value) && exp.test(value);
};

export const areDhGroups = (groups: string) => {
  const values = groups ? groups.split('') : [''];
  return values.every(v => /[0-9]+/.test(v) && validDhGroups.includes(v));
};

export const isUniqueInArray = (value: string, values: string[] | number[]) => {
  const containsNumbers = values.some(v => typeof v === 'number');
  const stringValues = containsNumbers
    ? values.map(v => v.toString())
    : (values as string[]);

  return stringValues.indexOf(value) === stringValues.lastIndexOf(value);
};

export const arrayIsUnique = (myArray: string[]) => {
  return myArray.length === new Set(myArray).size;
};

export function isGuid(guid: string) {
  return /^([0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12})$/i.test(
    guid
  );
}

export const isMobilePhone = (phoneNumber: string) => {
  if (phoneNumber && phoneNumber.substring(0, 2) === '00') {
    phoneNumber = `+${phoneNumber.slice(2, phoneNumber.length)}​`;
  }

  return validator.isMobilePhone(
    phoneNumber || '',
    ['sv-SE', 'fi-FI', 'nn-NO', 'da-DK'],
    {
      strictMode: true,
    }
  );
};

export function isJSON(json = '') {
  try {
    JSON.parse(json);
    return true;
  } catch (_) {
    return false;
  }
}

export const isWorkloadImageName = (imageName: string) => {
  /*
  1. A tag name must be valid ASCII and may contain lowercase and uppercase letters, digits, underscores, periods and dashes.
  2. A tag name may not start with a period or a dash and may contain a maximum of 255 characters.
  3. Only one : is allowed.
  4. Any number of / is allowed, but no //
  */
  // make the regexp easier to read since it doesn't have to include negative lookahead
  const imageNameAsArray = imageName.split('');
  const onlyOneColon =
    imageNameAsArray.indexOf(':') === imageNameAsArray.lastIndexOf(':');
  const noDoubleSlash = !imageName.includes('//');

  return (
    onlyOneColon &&
    noDoubleSlash &&
    new RegExp(/^[a-zA-Z0-9_/:][a-zA-Z0-9_./:-]*$/).test(imageName)
  );
};

export const isPositiveValue = (value: number) => {
  return value >= 0 ? true : false;
};
