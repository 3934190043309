import { useEffect, useRef } from 'react';

const useDisableTabKey = (isLoading: boolean) => {
  const spinnerRef = useRef<HTMLElement | null>(null);
  useEffect(() => {
    const spinnerLoading = (event: any): void => {
      if (
        isLoading &&
        event.which === 9 &&
        (spinnerRef as any).current.className.includes('open')
      ) {
        event.preventDefault();
      }
    };
    document.addEventListener('keydown', spinnerLoading);
    return () => document.removeEventListener('keydown', spinnerLoading);
  }, [isLoading]);
  return spinnerRef;
};

export default useDisableTabKey;
