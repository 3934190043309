import { Button } from '@mui/material';
import { Box } from '@mui/system';
import AtlasIcon from 'components/AtlasIcon';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps<T> {
  array: T[];
  pageSize?: number;
  buttonTextAlign?: 'center' | 'right';
  children: (item: T) => JSX.Element;
}

function PaginatedList<T>({
  array,
  pageSize = 12,
  buttonTextAlign = 'right',
  children,
}: IProps<T>) {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const hasMore = !isExpanded && array.length > pageSize;

  const activeArray = isExpanded ? array : array.slice(0, pageSize);

  return (
    <>
      {activeArray.map(children)}
      {hasMore && (
        <Box textAlign={buttonTextAlign}>
          <Button onClick={() => setIsExpanded(true)}>
            {t('action.show_more')}&nbsp;
            <AtlasIcon type="ChevronDown" size={14} />
          </Button>
        </Box>
      )}
    </>
  );
}

export default observer(PaginatedList);
