import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'store';
import { Control } from 'views/Dashboard/SettingControls';
import { IAzureMapsSettings } from '../../AzureMapsComponent/azureMapsConfig';
import { IGaugeSettings } from '../../GaugeComponent/gaugeConfig';
import { IImageSettings } from '../../ImageComponent/imageConfig';
import { ILineChartSettings } from '../../LineChartComponent/lineChartConfig';
import { ITableSettings } from '../../TableComponent/tableConfig';
import { INumberGridSettings } from '../numberGridConfig';

interface IProps {
  settings:
    | INumberGridSettings
    | IImageSettings
    | ITableSettings
    | IAzureMapsSettings
    | ILineChartSettings
    | IGaugeSettings;
}

const PolicyControl: React.FC<IProps> = ({ settings }) => {
  const {
    policyStore: { policies = [] },
  } = useStores();
  const { t } = useTranslation('policy');

  return (
    <Control.AutoComplete
      object={settings}
      name="policyId"
      options={policies.map(policy => ({
        label: policy.name,
        value: policy.policyId,
      }))}
      label={t('select_policy')}
      clearable
    />
  );
};

export default observer(PolicyControl);
