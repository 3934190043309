import { Option } from 'components/FormFields';
import { DashboardComponentType } from 'generated';
import {
  IBaseComponentSettings,
  IComponentTypeConfig,
} from 'views/Dashboard/dashboardTypes';
import { DataPropSetting } from 'views/Dashboard/DataSources/dataPropTypes';
import { IDataSourceSettings } from 'views/Dashboard/DataSources/dataSourceTypes';
import PreviewStep from '../PreviewStep';
import NumberGridWidget from './NumberGridWidget';
import GeneralSettingsStep from './SettingsSteps/GeneralSettingsStep';

export interface INumberGridSettings extends IBaseComponentSettings {
  title: string;
  dataSource: IDataSourceSettings;

  policyId: string;

  cards: {
    prop: DataPropSetting;
    size: NumberGridCardSize;
  }[];
}

export type NumberGridCardSize = 'medium' | 'large';
export const MultipleNumberSizeOptions: Option<NumberGridCardSize>[] = [
  {
    label: 'Medium',
    value: 'medium',
  },
  {
    label: 'Large',
    value: 'large',
  },
];

export const numberGridComponentConfig: IComponentTypeConfig<
  DashboardComponentType.NumberGrid,
  INumberGridSettings
> = {
  widgetType: DashboardComponentType.NumberGrid,
  icon: 'Graph',
  Component: NumberGridWidget,
  settingsSteps: [
    {
      title: 'settings',
      Component: GeneralSettingsStep,
    },
    {
      title: 'final_preview',
      Component: PreviewStep,
    },
  ],
  getDefaultSettings: ({ dataSource }) => {
    return {
      position: { x: 0, y: 0, w: 12, h: 5 },
      title: '',
      dataSource: dataSource.settings,
      policyId: '',
      cards: [],
    };
  },
  fixSettings: settings => {
    if (!settings.cards) {
      settings.cards = [];
    }
    if (!settings.policyId) {
      settings.policyId = '';
    }
  },
};
