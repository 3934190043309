import { createStyles, makeStyles } from '@mui/styles';
import unauthorized from './access-denied.svg';

export const useStyles = makeStyles(theme =>
  createStyles({
    unauthorizedHeader: {
      padding: theme.spacing(5, 0),
      marginBottom: theme.spacing(4),
    },
    unauthorizedText: {
      paddingRight: theme.spacing(3),
      width: 460,
    },
    unauthorizedBackground: {
      marginTop: theme.spacing(8),
      backgroundImage: `url(${unauthorized})`,
      backgroundSize: 'contain',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      minHeight: 400,
      width: '100%',
    },
  })
);
