import { Box } from '@mui/material';
import i18n from 'i18n';
import { formatDate, formatNumber } from 'utils';
import { textOverflowEllipsis } from '../Columns.styles';

interface IColArrayValueProps {
  value: any[];
  type: 'string' | 'boolean' | 'number' | 'date';
  showTime?: boolean;
  showSeconds?: boolean;
  decimals?: number;
}

export const ColArrayValue: React.FC<IColArrayValueProps> = ({
  value,
  type,
  showTime = false,
  showSeconds = false,
  decimals,
}) => {
  const NA = (
    <span className="text-muted">{i18n.t('not_applicable_abbreviation')}</span>
  );
  let formattedArr: any = [];
  switch (type) {
    case 'date':
      formattedArr = value
        .map(d => formatDate(d, { time: showTime, seconds: showSeconds }))
        .join(', ');
      break;
    case 'boolean':
      formattedArr = value
        .map(b => (b ? i18n.t('yes') : i18n.t('no')))
        .join(', ');
      break;
    case 'number':
      formattedArr = value.map(n => formatNumber(n, { decimals })).join(', ');
      break;
    default:
      formattedArr = value.map(n => n).join(', ');
      break;
  }
  return (
    <Box title={formattedArr} style={textOverflowEllipsis}>
      {!formattedArr ? NA : formattedArr}
    </Box>
  );
};

export default ColArrayValue;
