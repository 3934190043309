import {
  IntCreateWorkloadRequestDto,
  IntCronJobStatusDto,
  IntDeploymentStatusDto,
  IntExtensionLogEntry,
  IntJobStatusDto,
  IntUpdateWorkloadRequestDto,
  IntValidateImageDto,
  IntWorkloadDto,
  IntWorkloadTableDto,
} from 'generated';
import {
  DeleteEndpoint,
  GetEndpoint,
  PostEndpoint,
  PutEndpoint,
  TableEndpoint,
} from 'shared/interfaces/api';
import { workloadDemoData } from './demoData/workloadDemoData';

export interface ITempConfiguration {
  name: string;
  value: string;
}

interface WorkloadAPI {
  getAllForExtension: (
    extensionId: string
  ) => TableEndpoint<IntWorkloadTableDto>;
  getGeneralProperties: GetEndpoint<string, IntWorkloadDto>;
  getConfigurations: (workloadId: string) => TableEndpoint<ITempConfiguration>;
  getLogs: (workloadId: string) => TableEndpoint<IntExtensionLogEntry>;
  create: PostEndpoint<undefined, IntCreateWorkloadRequestDto>;
  update: PutEndpoint<string, IntUpdateWorkloadRequestDto>;
  validateImageName: GetEndpoint<string, IntValidateImageDto>;
  delete: DeleteEndpoint<string>;
  getJobStatus: GetEndpoint<string, IntJobStatusDto>;
  getCronJobStatus: GetEndpoint<string, IntCronJobStatusDto>;
  getDeploymentStatus: GetEndpoint<string, IntDeploymentStatusDto>;
}

const base = '/api/workloads';

export const workloadAPI: WorkloadAPI = {
  getAllForExtension: extensionId => ({
    url: `${base}/table?extensionId=${extensionId}`,
    mockData: workloadDemoData.getWorkloads,
  }),
  getGeneralProperties: {
    url: workloadId => `${base}/${workloadId}`,
    mockData: workloadDemoData.getGeneralProperties,
  },
  getConfigurations: workloadId => ({
    url: `${base}/${workloadId}/configurations`,
    mockData: workloadDemoData.getConfigurations,
  }),
  getLogs: workloadId => ({
    url: `${base}/${workloadId}/logs`,
    mockData: workloadDemoData.getLogs(),
  }),
  create: {
    url: `${base}`,
    mockData: workloadDemoData.create,
  },
  update: {
    url: workloadId => `${base}/${workloadId}`,
    mockData: workloadDemoData.update,
  },
  validateImageName: {
    url: imageName => `${base}/validate/image?image=${imageName}`,
    mockData: imageName => workloadDemoData.validateImageName(imageName),
  },
  delete: {
    url: workloadId => `${base}/${workloadId}`,
    mockData: workloadDemoData.delete,
  },
  getJobStatus: {
    url: (workloadId: string) => `${base}/${workloadId}/status/job`,
    mockData: workloadDemoData.getJobStatus,
  },
  getCronJobStatus: {
    url: (workloadId: string) => `${base}/${workloadId}/status/cronjob`,
    mockData: workloadDemoData.getCronJobStatus,
  },
  getDeploymentStatus: {
    url: (workloadId: string) => `${base}/${workloadId}/status/deployment`,
    mockData: workloadDemoData.getDeploymentStatus,
  },
};
