import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    iconWrapper: {
      border: `1px solid ${theme.settings.colors.lightPink}`,
      borderRadius: 9999,
    },
  })
);
