import { createStyles, makeStyles } from '@mui/styles';

export const useMapStyles = makeStyles(theme =>
  createStyles({
    map: {
      height: '100%',

      '& .map-copyright': {
        opacity: 0.5,
      },

      '& .popup-container': {
        pointerEvents: 'none',
        '&.enable-pointer-events': {
          pointerEvents: 'all',
        },

        '& > .popup-content-container': {
          width: 240,
          boxShadow: theme.shadows[12],
          overflow: 'hidden',
          borderRadius: 8,
          padding: theme.spacing(2),

          '& > .popup-close': {
            pointerEvents: 'all',
          },

          '& h6': {
            maxWidth: '100%',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          },
        },
      },
      '& .popup-title': {
        margin: 0,
      },
      '& .popup-table': {
        lineHeight: 1.8,
        color: theme.palette.text.primary,
        marginTop: theme.spacing(1),
        width: '100%',
      },
      '& .popup-table__value': {
        textAlign: 'right',
        paddingLeft: theme.spacing(1),
        boxSizing: 'border-box',
        maxWidth: 110,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
      '& .popup-table__property': {
        fontWeight: 'bold',
      },
    },
    mapWithTable: {
      height: '50%',
    },
    toggle: {
      position: 'absolute',
      top: 0,
    },
  })
);
