import { Divider } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import PlaceHolder from 'styles/profile.svg';
import DropdownMessage from '../DropdownMessage/DropdownMessage';
import { useStyles } from './ProfilePreview.styles';

interface IProps {
  name: string;
  url: string;
  picturePath: string;
  headerText: string;
  dataCy?: string;
}

const ProfilePreview: React.FC<IProps> = ({
  url,
  name,
  picturePath,
  headerText,
  dataCy,
}) => {
  const classes = useStyles();

  const pictureRadius = classNames(classes.previewPicture, {
    [classes.pictureRound]: picturePath,
  });

  return (
    <>
      <DropdownMessage
        to={url}
        headerText={headerText}
        icon={
          <img
            src={picturePath || PlaceHolder}
            onError={e => (e.currentTarget.src = PlaceHolder)}
            className={pictureRadius}
            data-cy={dataCy}
            alt="Profile"
          />
        }
        label={name}
        compact
      />
      <Divider variant="middle" className={classes.divider} />
    </>
  );
};

export default ProfilePreview;
