import { TestModeStatus } from 'generated';
import i18n from 'i18next';
import { Column } from 'react-table';
import { getOptionFilter } from '../Helpers/getOptionFilter';

export function testModeStatus<T>(options: {
  id: string;
  accessor: (row: T) => TestModeStatus;
  defaultSortDesc?: boolean;
  header?: string;
  filterable?: boolean;
  sortable?: boolean;
  width?: number;
  defaultShow?: boolean;
}): Column<T> {
  const {
    id,
    header = 'Status',
    accessor,
    defaultSortDesc = false,
    filterable,
    sortable,
    width = 110,
    defaultShow: show = true,
  } = options;

  return {
    id,
    Header: header,
    accessor,
    defaultSortDesc,
    resizable: true,
    width,
    filterable,
    show,
    sortable,
    Cell: row => i18n.t(`sim:connectivity_unit.testModeStatus.${row.value}`),

    Filter: getOptionFilter([
      {
        label: i18n.t('sim:label.status_all'),
        value: '',
      },
      {
        label: i18n.t(
          `sim:connectivity_unit.testModeStatus.${TestModeStatus.NotApplicable}`
        ),
        value: TestModeStatus.NotApplicable,
      },
      {
        label: i18n.t(
          `sim:connectivity_unit.testModeStatus.${TestModeStatus.Active}`
        ),
        value: TestModeStatus.Active,
      },
      {
        label: i18n.t(
          `sim:connectivity_unit.testModeStatus.${TestModeStatus.Expired}`
        ),
        value: TestModeStatus.Expired,
      },
      {
        label: i18n.t(
          `sim:connectivity_unit.testModeStatus.${TestModeStatus.Consumed}`
        ),
        value: TestModeStatus.Consumed,
      },
    ]),
  };
}
