import { Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import ComponentContainer from 'views/Dashboard/ComponentContainer';
import { IDashboardComponent } from 'views/Dashboard/dashboardTypes';
import { Control } from 'views/Dashboard/SettingControls';
import { IGaugeSettings } from '../gaugeConfig';

const PreviewStep: IDashboardComponent<IGaugeSettings> = ({ component }) => {
  const { t } = useTranslation('dashboard');

  return (
    <Control.Group label={t('final_preview')}>
      <Box height={360}>
        <ComponentContainer component={component} />
      </Box>
    </Control.Group>
  );
};

export default observer(PreviewStep);
