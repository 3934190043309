import { Tooltip } from '@mui/material';
import React from 'react';

interface IProps {
  enabled: boolean;
  text: string;
  children: JSX.Element;
  placement?: 'right' | 'top' | 'bottom';
}

const OptionalTooltip: React.FC<IProps> = ({
  enabled,
  text,
  children,
  placement = 'right',
}) => {
  return (
    <Tooltip
      arrow
      title={text}
      placement={placement}
      disableHoverListener={!enabled}
      disableFocusListener={!enabled}
    >
      <div>{children}</div>
    </Tooltip>
  );
};

export default OptionalTooltip;
