import {
  DashboardComponentType,
  IntServiceDataSpecificationFilterDto,
} from 'generated';
import { IDataSourceConfig } from '../dataSourceTypes';
import { fixServiceDataSourceSettings } from './fixServiceDataSourceSettings';
import ServiceLatestSettingsMenu from './ServiceLatestSettingsMenu';

export interface IServiceLatestDataSourceSettings {
  type: 'serviceLatest';
  serviceDataFilters: IntServiceDataSpecificationFilterDto[];
}

export const serviceLatestDataSourceConfig: IDataSourceConfig<IServiceLatestDataSourceSettings> =
  {
    icon: 'Basketball',
    settingsSteps: [
      { title: 'service_settings', Component: ServiceLatestSettingsMenu },
    ],
    getDefaultSettings: async () => {
      return {
        type: 'serviceLatest',
        serviceDataFilters: [],
      };
    },
    fixSettings: fixServiceDataSourceSettings,
    enabledComponentTypes: {
      [DashboardComponentType.AzureMaps]: true,
      [DashboardComponentType.ColumnChart]: true,
      [DashboardComponentType.Gauge]: true,
      [DashboardComponentType.Image]: true,
      [DashboardComponentType.LineChart]: false,
      [DashboardComponentType.Number]: true,
      [DashboardComponentType.Table]: true,
      [DashboardComponentType.NumberGrid]: true,
      [DashboardComponentType.GroupedBarChart]: false,
    },
    shouldShowDateFilter: false,
  };
