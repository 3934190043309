import { createStyles, makeStyles } from '@mui/styles';
import { fontSettings } from 'styles/themes/fontSettings';

export const useStyles = makeStyles(theme =>
  createStyles({
    text: {
      fontFamily: fontSettings.TeliaSansMedium.fontStack,
    },
    infoBox: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      borderRadius: '2px',
    },
  })
);
