import i18n from 'i18n';
import { usageAPI } from 'services/usage.service';
import { formatLocalTimezoneToUTCString } from 'utils/formatting';
import { IDataPropCategory } from '../../dataPropTypes';
import { makeGenericDataSource } from '../../makeGenericDataSource';

const category: IDataPropCategory = {
  displayName: i18n.t('dashboard:data.properties'),
};

export const simGroupActivityDataSource = makeGenericDataSource({
  endpoint: usageAPI.getMostActiveGroups,
  getParams: filterState => {
    const { from, to } = filterState.getDateFilters();

    return {
      top: 10,
      startDate: from ? formatLocalTimezoneToUTCString(from) : undefined,
      endDate: to ? formatLocalTimezoneToUTCString(to) : undefined,
    };
  },
  getDataProps: () => [
    {
      id: 'label',
      type: 'string',
      category,
      _get: row => row.label,
      name: i18n.t('sim:activity.group_label'),
      isDefaultDisplayName: true,
      link: row => `/connectivity/groups/${row.id}`,
    },
    {
      id: 'identifier',
      type: 'number',
      category,
      _get: row => row.identifier,
      name: i18n.t('sim:activity.group_id'),
    },
    {
      id: 'totalData',
      type: 'dataUsage',
      category,
      _get: row => row.totalData || 0,
      name: i18n.t('sim:activity.total_data'),
    },
    {
      id: 'memberCount',
      type: 'number',
      category,
      _get: row => row.memberCount,
      name: i18n.t('sim:activity.sim_in_group'),
    },
  ],
  defaultSort: { id: 'totalData', desc: true },
});
