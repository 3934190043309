import { Box, Chip } from '@mui/material';
import { FieldArray, FieldArrayRenderProps, useField } from 'formik';
import gPhoneNumber from 'google-libphonenumber';
import React from 'react';
import { pxToRem } from 'styles/themes/makeAtlasTheme';
import { FormField } from '..';
import { useStyles } from './PhoneList.styles';

interface IProps {
  name: string;
  label: string;
  required?: boolean;
}

const PhoneList: React.FC<IProps> = ({ name, label, required = false }) => {
  const classes = useStyles();
  const [field, { error, value, touched }] = useField<string[]>(name);
  const phoneUtil = gPhoneNumber.PhoneNumberUtil.getInstance();

  const isErrorArray = Array.isArray(error);
  const fieldError = isErrorArray ? error[error.length - 1] : undefined;

  const getCountryCode = (rawInput: string) => {
    if (!rawInput) {
      rawInput = field.value ? field.value[field.value.length - 1] : '';
    }

    try {
      const phoneNumber = phoneUtil.parseAndKeepRawInput(
        rawInput?.replace(/^00/, '+'),
        rawInput
      );

      return phoneNumber.getCountryCode()?.toString();
    } catch {
      return '';
    }
  };

  const addPhoneNumberToList = (
    event: React.ChangeEvent<HTMLInputElement>,
    arrayHelpers: FieldArrayRenderProps
  ) => {
    event.preventDefault();
    const countryCode = getCountryCode(event.target.value);

    if (event.target.value !== '' && !fieldError && countryCode !== '') {
      arrayHelpers.push(event.target.value);
    }
    event.target.value = countryCode ? `+${countryCode}` : '';
  };

  return (
    <FieldArray
      name={name}
      render={arrayHelpers => (
        <>
          <FormField.PhoneField
            label={label}
            required={required}
            name={name}
            className={classes.listItem}
            onBlur={e => {
              addPhoneNumberToList(e, arrayHelpers);
            }}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                addPhoneNumberToList(e, arrayHelpers);
              }
            }}
            input={{
              endAdorment: (
                <Box className={classes.chipContainer}>
                  {value.map((chipLabel, index) => (
                    <Chip
                      key={index}
                      sx={{ m: pxToRem(3) }}
                      size="small"
                      label={chipLabel}
                      onDelete={() => arrayHelpers.remove(index)}
                      color={isErrorArray && error[index] ? 'error' : 'default'}
                    />
                  ))}
                </Box>
              ),
            }}
            error={touched && fieldError}
          />
        </>
      )}
    />
  );
};

export default PhoneList;
