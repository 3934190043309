import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useStores } from 'store';
import { IDataSourceSettingsMenuProps } from '../dataSourceTypes';
import AssetFilterSettingsControl from './AssetFilterSettingsControl/AssetFilterSettingsControl';
import { IServiceLatestDataSourceSettings } from './serviceLatestConfig';

const ServiceLatestSettingsMenu: React.FC<
  IDataSourceSettingsMenuProps<IServiceLatestDataSourceSettings>
> = ({ settings }) => {
  const { dashboardStore } = useStores();

  useEffect(() => {
    if (!dashboardStore.serviceOptions) {
      dashboardStore.loadServiceOptions();
    }
  }, [dashboardStore]);

  return <AssetFilterSettingsControl filters={settings.serviceDataFilters} />;
};

export default observer(ServiceLatestSettingsMenu);
