import { TableData } from 'components/Table';
import {
  EndpointAccessibility,
  IntAccessEndpointDto,
  IntSearchDto,
} from 'generated';
import { IResponse } from 'shared/interfaces/api';
import { paginate, rnd } from 'utils';

const getEndpoint = (num: number): IntAccessEndpointDto => {
  return {
    id: rnd.guid(),
    name: `Endpoint-${num}`,
    displayName: `Endpoint ${num}`,
    ipAddress: `${rnd.int(100, 199)}.${rnd.int(100, 199)}.${rnd.int(
      100,
      199
    )}.${rnd.int(1, 100)}`,
    accessibility: rnd.enum(EndpointAccessibility),
    ports: [],
    createdDate: rnd.date.past(),
  };
};

class AccessEndpointDemoData {
  get = (): IResponse<IntAccessEndpointDto> => {
    return { status: 200, data: getEndpoint(rnd.int(10, 9999)) };
  };

  getAll = (
    params?: IntSearchDto
  ): IResponse<TableData<IntAccessEndpointDto>> => {
    const endpoints: IntAccessEndpointDto[] = rnd.array(3, () => {
      return getEndpoint(rnd.int(10, 9999));
    });

    return {
      status: 200,
      data: paginate(endpoints, params),
    };
  };

  create = (): IResponse<IntAccessEndpointDto> => ({
    status: 201,
    data: getEndpoint(1),
  });

  delete = (): IResponse => ({ status: 200 });
}

export const accessEndpointDemoData = new AccessEndpointDemoData();
