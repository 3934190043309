import {
  AnimationOptions,
  CameraBoundsOptions,
  CameraOptions,
  data,
  Map as AzureMap,
} from 'azure-maps-control';
import { isEqual } from 'lodash';
import { useEffect, useRef } from 'react';
import { IMapFeature } from '../../ImageComponent/ImagePinLayer';
import { IMapPosition } from '../../ImageComponent/ImageWidget';

export type AzureMapSetCameraOptions = (CameraOptions | CameraBoundsOptions) &
  AnimationOptions;

const isPositionsSame = (features: IMapFeature[]): boolean => {
  const firstPosition: IMapPosition = features[0].position;

  for (let i = 1; i < features.length; i++) {
    if (!isEqual(firstPosition, features[i].position)) {
      return false;
    }
  }
  return true;
};

export function useAutoZoom(
  map: AzureMap,
  features: IMapFeature[],
  onNewAutoZoom?: (options: AzureMapSetCameraOptions) => void
) {
  // Save a reference to the last set position, so updates that don't change the features don't move the camera
  const cameraPositionRef = useRef('');

  useEffect(() => {
    if (features.length) {
      const { height, width } = map.getMapContainer().getBoundingClientRect();

      const yPadding = height * 0.25;
      const xPadding = width * 0.25;

      const bBox = data.BoundingBox.fromLatLngs(features.map(f => f.position));

      const newPositionString = JSON.stringify(bBox);
      if (newPositionString === cameraPositionRef.current) {
        return;
      }
      cameraPositionRef.current = newPositionString;
      const cameraOptions: AzureMapSetCameraOptions =
        features.length === 1 || isPositionsSame(features)
          ? {
              center: features[0].position,
              zoom: 16,
            }
          : {
              bounds: bBox,
              padding: {
                top: yPadding,
                bottom: yPadding,
                left: xPadding,
                right: xPadding,
              },
            };
      onNewAutoZoom?.(cameraOptions);

      try {
        map.setCamera(cameraOptions);
      } catch (error) {
        console.warn('Could not automatically set map camera', error);
      }
    }
  }, [map, features, onNewAutoZoom]);
}
