import { IntMenuItemDto } from 'generated';
import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { flattenedRoutes } from 'Routes';
import { useStores } from 'store';
import { IBreadcrumb } from './BreadcrumbList';

export function useNavigationBreadcrumbs() {
  const { layoutStore } = useStores();
  const { pathname } = useLocation();

  const activeRoute = useMemo(
    () =>
      flattenedRoutes.find(r =>
        matchPath(pathname, { path: r.path, exact: r.exact })
      ),
    [pathname]
  );

  const activeMenuItem = useMemo(() => {
    const primaryActive = layoutStore.flatMenuItems.find(
      menuItem => menuItem.route === pathname
    );

    if (primaryActive) {
      return {
        isDetailsPage: false,
        menuItem: primaryActive,
      };
    }

    // We're not on a route linked by a menuItem - but the parent of the route does have a menuItem
    // We assume we're on a details page
    const detailsPageActive = layoutStore.flatMenuItems.find(
      menuItem => menuItem.route === activeRoute?.parentItem?.path
    );
    const subDetailsPageActive = layoutStore.flatMenuItems.find(
      menuItem => menuItem.route === activeRoute?.parentItem?.parentItem?.path
    );

    if (detailsPageActive) {
      return {
        isDetailsPage: true,
        isNested: false,
        menuItem: detailsPageActive,
      };
    } else if (subDetailsPageActive) {
      // check if were on another details page one step further down in the tree
      return {
        isDetailsPage: true,
        isNested: true,
        menuItem: subDetailsPageActive,
      };
    }

    return null;
  }, [pathname, activeRoute, layoutStore.flatMenuItems]);

  const itemHierarchy = getMenuItemHierarchy(
    activeMenuItem?.menuItem,
    layoutStore.flatMenuItems
  );

  const breadcrumbs: IBreadcrumb[] = itemHierarchy.map(menuItem => ({
    text: menuItem.displayName,
    to: menuItem.route,
  }));

  // Detail pages don't nessecarily have menuItems, so need to insert a breadcrumb if we're on one
  if (activeMenuItem?.isDetailsPage) {
    if (activeMenuItem?.isNested) {
      breadcrumbs.push({
        text: layoutStore.detailsParentBreadcrumb.label,
        to: layoutStore.detailsParentBreadcrumb.path,
      });
    }
    breadcrumbs.push({
      text: layoutStore.customBreadcrumbText,
    });
  }

  if (breadcrumbs.length > 0) {
    breadcrumbs[breadcrumbs.length - 1].to = ''; // Disable links for the last breadcrumbs
    breadcrumbs[breadcrumbs.length - 1].isLast = true;
  }

  return breadcrumbs;
}

// Given an menuItem, return an array including all the parents of the item
// for example [connectivity, APN hub, active data sessions]
function getMenuItemHierarchy(
  currentActiveItem: IntMenuItemDto | undefined,
  allItems: IntMenuItemDto[]
) {
  const itemList: IntMenuItemDto[] = [];

  const addToItemArray = (item: IntMenuItemDto) => {
    itemList.unshift(item); // Insert at the the start to get the proper order

    if (!item.parentId) {
      return;
    }
    const parent = allItems.find(x => x.menuItemId === item.parentId);
    if (parent) {
      addToItemArray(parent);
    }
  };

  if (currentActiveItem) {
    addToItemArray(currentActiveItem);
  }

  return itemList;
}
