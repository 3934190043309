import { TableData } from 'components/Table';
import { subDays, subMinutes } from 'date-fns';
import {
  IntDocumentDto,
  IntPingResponseDto,
  IntPlaceDto,
  IntRealEstateItConnectedDeviceDto,
  IntRealEstateItConnectedDeviceTableDto,
  IntRealEstateItNetworkDto,
  IntRealEstateItProfileDto,
  IntRealEstateItServerDto,
  IntSearchDto,
  IntSiteDto,
  IntSupplierDto,
  IntSupplierTableDto,
  IntSwitchDto,
} from 'generated';
import { IResponse } from 'shared/interfaces/api';
import { paginate, rnd, rndInt } from 'utils';

class RealEstateItDemoData {
  private _connectedDevices:
    | IntRealEstateItConnectedDeviceTableDto[]
    | undefined;
  private _locations: IntPlaceDto[] | undefined;
  private _networks: IntRealEstateItNetworkDto[] | undefined;
  private _documents: IntDocumentDto[] | undefined;
  private _suppliers: IntSupplierTableDto[] | undefined;
  private _profiles: IntRealEstateItProfileDto[] | undefined;
  private _sites: IntSiteDto[] | undefined;
  private _switches: IntSwitchDto[] | undefined;
  get realEstateItLocations() {
    if (!this._locations) {
      this._locations = this.getDemoLocations(50);
    }
    return this._locations;
  }
  get realEstateItNetworks() {
    if (!this._networks) {
      this._networks = this.getDemoNetworks(50);
    }
    return this._networks;
  }
  get realEstateItDocuments() {
    if (!this._documents) {
      this._documents = this.getDemoDocuments(50);
    }
    return this._documents;
  }
  get realEstateItProfiles() {
    if (!this._profiles) {
      this._profiles = this.getDemoProfiles(50);
    }
    return this._profiles;
  }
  get realEstateItSites() {
    if (!this._sites) {
      this._sites = this.getDemoSites(50);
    }
    return this._sites;
  }
  get realEstateItSuppliers() {
    if (!this._suppliers) {
      this._suppliers = this.getDemoSuppliers(50);
    }
    return this._suppliers;
  }
  get realEstateItSwitches() {
    if (!this._switches) {
      this._switches = this.getDemoSwitches(50);
    }
    return this._switches;
  }
  get realEstateItAssets() {
    if (!this._connectedDevices) {
      this._connectedDevices = this.getDemoAssets(70); // Don't generate data until it's needed
    }
    return this._connectedDevices;
  }

  getDemoLocations = (n: number): IntPlaceDto[] => {
    const locations: IntPlaceDto[] = [];
    for (let i = 0; i < n; i++) {
      // Use static IDs so links and reloads work
      const placeId = i + rnd.int(999, 9999);

      locations.push(this.getDemoLocation(placeId));
    }
    return locations;
  };

  getDemoNetworks = (n: number): IntRealEstateItNetworkDto[] => {
    const networks: IntRealEstateItNetworkDto[] = [];
    for (let i = 0; i < n; i++) {
      // Use static IDs so links and reloads work
      const profileId = i + rnd.int(999, 9999);

      networks.push(this.getDemoNetwork(profileId));
    }
    return networks;
  };

  getDemoDocuments = (n: number): IntDocumentDto[] => {
    const documents: IntDocumentDto[] = [];
    for (let i = 0; i < n; i++) {
      // Use static IDs so links and reloads work
      const docId = i + rnd.int(999, 9999);

      documents.push(this.getDemoDocument(docId));
    }
    return documents;
  };

  getDemoSuppliers = (n: number): IntSupplierTableDto[] => {
    const entities: IntSupplierTableDto[] = [];
    for (let i = 0; i < n; i++) {
      // Use static IDs so links and reloads work
      const id = i + rnd.int(999, 9999);

      entities.push(this.getDemoSupplier(id));
    }
    return entities;
  };

  getDemoProfiles = (n: number): IntRealEstateItProfileDto[] => {
    const entities: IntRealEstateItProfileDto[] = [];
    for (let i = 0; i < n; i++) {
      // Use static IDs so links and reloads work
      const id = i + rnd.int(999, 9999);

      entities.push(this.getDemoProfile(id));
    }
    return entities;
  };

  getDemoSites = (n: number): IntSiteDto[] => {
    const entities: IntSiteDto[] = [];
    for (let i = 0; i < n; i++) {
      // Use static IDs so links and reloads work
      const id = i + rnd.int(999, 9999);

      entities.push(this.getDemoSite(id));
    }
    return entities;
  };

  getDemoServers = (n: number): IntRealEstateItServerDto[] => {
    const entities: IntRealEstateItServerDto[] = [];
    for (let i = 0; i < n; i++) {
      // Use static IDs so links and reloads work
      const id = i + rnd.int(999, 9999);

      entities.push(this.getDemoServer(id));
    }
    return entities;
  };

  getDemoSwitches = (n: number): IntSwitchDto[] => {
    const entities: IntSwitchDto[] = [];
    for (let i = 0; i < n; i++) {
      // Use static IDs so links and reloads work
      const id = i + rnd.int(999, 9999);

      entities.push(this.getDemoSwitch(id));
    }
    return entities;
  };

  getSupplierServers = (n: number): IntRealEstateItServerDto[] => {
    const servers: IntRealEstateItServerDto[] = [];
    for (let i = 0; i < n; i++) {
      const id = i + rnd.int(999, 9999);

      servers.push({
        id: id,
        createdAt: subDays(new Date(), rndInt(1, 5)),
        updatedAt: subDays(new Date(), rndInt(1, 5)),
        deletedAt: subDays(new Date(), rndInt(1, 5)),
        name: rnd.userName(),
        ipv4: rnd.ip(),
        supplierId: rnd.int(99, 999),
        profileId: rnd.int(99, 999),
        notes: rnd.words(20),
      });
    }
    return servers;
  };

  getDemoLocation = (placeId: number): IntPlaceDto => {
    const demoAsset: IntPlaceDto = {
      id: placeId,
      propertyNumber: `Property-${rnd.int(1, 20)}`,
      siteId: rnd.int(10, 20),
      name: rnd.userName(),
      area: rnd.country(),
      city: rnd.country(),
      longitude: rnd.lng(),
      latitude: rnd.lat(),
      network: `Network-${rnd.int(1, 20)}`,
      address: rnd.address(),
    };

    return demoAsset;
  };

  getDemoNetwork = (profileId: number): IntRealEstateItNetworkDto => {
    const demoNetwork: IntRealEstateItNetworkDto = {
      id: profileId,
      createdAt: subDays(new Date(), rndInt(1, 5)),
      updatedAt: subDays(new Date(), rndInt(1, 5)),
      deletedAt: subDays(new Date(), rndInt(1, 5)),
      name: rnd.userName(),
      type: rnd.ratType(),
      domain: rnd.domainName(),
      zone: rnd.item('Zone 1', 'Zone 2', 'Zone 3'),
      vlan: rnd.int(9, 999),
      ipSubnet: rnd.ip(),
      ipSubnetType: rnd.ip(),
      internetType: rnd.address(),
    };

    return demoNetwork;
  };

  getDemoDocument = (documentId: number): IntDocumentDto => {
    const demoNetwork: IntDocumentDto = {
      id: documentId,
      name: `File ${rnd.int(1, 9999)}`,
      fileName: `some.json`,
      createdAt: subDays(new Date(), rndInt(1, 5)),
      updatedAt: subDays(new Date(), rndInt(1, 5)),
      contentType: 'application/json',
      size: rnd.int(999, 99999),
      uploadedBy: rnd.fullName(),
    };

    return demoNetwork;
  };

  getDemoSupplier = (supplierId: number): IntSupplierTableDto => {
    const demoSupplier: IntSupplierTableDto = {
      id: supplierId,
      name: rnd.userName(),
      city: rnd.country(),
      contactInfo: rnd.fullName(),
    };

    return demoSupplier;
  };

  getDemoSupplierDetails = (supplierId: number): IntSupplierDto => {
    const demoSupplier = this.getDemoSupplier(supplierId);
    const demoSupplierDetails: IntSupplierDto = {
      ...demoSupplier,
      connectedDevices: [this.getDetails(supplierId.toString())],
      servers: this.getSupplierServers(7),
    };
    return demoSupplierDetails;
  };

  getDemoSwitch = (switchId: number): IntSwitchDto => {
    const demoSwitch: IntSwitchDto = {
      id: switchId,
      siteId: rnd.int(1, 15),
      locationId: this.realEstateItLocations[0].id,
      name: rnd.userName(),
    };

    return demoSwitch;
  };

  getDemoProfile = (profileId: number): IntRealEstateItProfileDto => {
    const demoProfile: IntRealEstateItProfileDto = {
      id: profileId,
      name: rnd.userName(),
      category: rnd.item('Category 1', 'Category 2', 'Category 3'),
      createdAt: subDays(new Date(), rndInt(1, 5)),
      updatedAt: subDays(new Date(), rndInt(1, 5)),
      deletedAt: subDays(new Date(), rndInt(1, 5)),
      allowInternet: rnd.bool(),
      allowInternal: rnd.bool(),
      allowLan: rnd.bool(),
      customAcl: rnd.item('ACL 1', 'ACL 2', 'ACL 3'),
      notes: rnd.words(20),
      acl: [
        {
          line: rnd.item(
            'lost internet',
            'no access',
            'device issue',
            'No battery'
          ),
          source: rnd.words(rndInt(2, 5)),
        },
      ],
      clientDevices: this.realEstateItAssets,
      network: this.getDemoNetwork(profileId),
      servers: this.getSupplierServers(7),
    };

    return demoProfile;
  };

  getDemoSite = (siteId: number): IntSiteDto => {
    const demoSite: IntSiteDto = {
      id: siteId,
      name: rnd.userName(),
    };

    return demoSite;
  };

  getDemoServer = (serverId: number): IntRealEstateItServerDto => {
    const demoServer: IntRealEstateItServerDto = {
      id: serverId,
      name: rnd.userName(),
      ipv4: rnd.ip(),
      supplierId: 1,
      profileId: 1,
      notes: '',
    };

    return demoServer;
  };

  getDemoPing = (id: number): IntPingResponseDto => {
    const assetPing: IntPingResponseDto = {
      latency: rnd.int(1, 999),
      lastPing: subMinutes(new Date(), rnd.int(0, 60 * 24 * 3)),
      state: rnd.bool(),
    };
    return assetPing;
  };

  getDemoAssets = (n: number): IntRealEstateItConnectedDeviceTableDto[] => {
    const assets: IntRealEstateItConnectedDeviceTableDto[] = [];
    for (let i = 0; i < n; i++) {
      // Use static IDs so links and reloads work
      const assetId = `0fe561ae-f4ke-un1t-${i
        .toString()
        .padStart(4, '0')}-20693c9dfa59`;

      assets.push(this.getDemoAsset(assetId));
    }
    return assets;
  };

  getDemoAsset = (assetId: string): IntRealEstateItConnectedDeviceTableDto => {
    const demoAsset: IntRealEstateItConnectedDeviceTableDto = {
      id: rnd.int(999, 9999),
      teliaAssetId: assetId,
      name: rnd.userName(),
      locationName: rnd.country(),
      city: rnd.country(),
      longitude: rnd.lng(),
      locationId: rnd.int(1, 10),
      ipv4: rnd.ip(),
      mac: rnd.mac(),
      netMask: rnd.int(10, 30).toString(),
      latency: rnd.int(999, 9999),
      state: rnd.bool(),
      lastPing: subMinutes(new Date(), rnd.int(0, 60 * 24 * 3)),
      latitude: rnd.lat(),
      profileName: this._profiles ? this._profiles[0].name : '',
      category: rnd.words(1),
      siteId: rnd.int(10, 9999),
    };

    return demoAsset;
  };

  getRow = (id: string): IntRealEstateItConnectedDeviceTableDto => {
    const asset = this.realEstateItAssets.find(x => x.teliaAssetId === id);

    if (!asset) {
      throw new Error('Asset missing');
    }

    return asset;
  };

  getDetails = (id: string): IntRealEstateItConnectedDeviceDto => {
    return {
      id: rnd.int(999, 9999),
      teliaAssetId: id,
      name: rnd.userName(),
      city: rnd.country(),
      manufacture: rnd.domainName(),
      model: rnd.modelNumber(),
      serialNumber: rnd.guid(),
      networkName: rnd.words(1),
      ipv4: rnd.ip(),
      netMask: rnd.int(10, 30).toString(),
      gateway: rnd.personalIdNumber(),
      mac: rnd.mac(),
      locationName: rnd.country(),
      locationId: rnd.int(99, 999),
      area: rnd.country(),
      address: rnd.address(),
      building: rnd.item('Apartment', 'Library', 'Burj Kalifa', 'Globen'),
      floor: rnd.int(1, 12).toString(),
      room: rnd.int(1, 50).toString(),
      rack: rnd.int(1, 24).toString(),
      longitude: rnd.lng(),
      latitude: rnd.lat(),
      profileName: this._profiles ? this._profiles[0].name : '',
      category: rnd.item('Cat 1', 'Cat 2', 'Cat 3'),
      closestSwitchId: rnd.int(1, 10),
      siteId: rnd.int(10, 9999),
      notes: rnd.words(20),
      state: rnd.bool(),
      latency: rnd.int(1, 999),
      lastPing: subMinutes(new Date(), rnd.int(0, 60 * 24 * 3)),
      acl: [
        {
          line: rnd.item(
            'lost internet',
            'no access',
            'device issue',
            'No battery'
          ),
          source: rnd.words(rndInt(2, 5)),
        },
      ],
      documents: [
        {
          id: rnd.int(99, 999),
          fileName: rnd.domainName(),
          size: rnd.mb(),
          uploadedBy: rnd.userName(),
          name: rnd.userName(),
          contentType: rnd.ratType(),
          createdAt: subDays(new Date(), rndInt(1, 5)),
          updatedAt: subDays(new Date(), rndInt(1, 5)),
        },
      ],
    };
  };

  getAll = (
    params: IntSearchDto
  ): IResponse<TableData<IntRealEstateItConnectedDeviceTableDto>> => {
    return {
      status: 200,
      data: paginate(this.realEstateItAssets, params),
    };
  };

  getLocations = (params: IntSearchDto): IResponse<TableData<IntPlaceDto>> => {
    return {
      status: 200,
      data: paginate(this.realEstateItLocations, params),
    };
  };

  getServers = (
    params: IntSearchDto
  ): IResponse<TableData<IntRealEstateItServerDto>> => {
    return {
      status: 200,
      data: paginate(this.getDemoServers(50), params),
    };
  };

  getNetworks = (
    params: IntSearchDto
  ): IResponse<TableData<IntRealEstateItNetworkDto>> => {
    return {
      status: 200,
      data: paginate(this.realEstateItNetworks, params),
    };
  };

  getDocuments = (
    params: IntSearchDto
  ): IResponse<TableData<IntDocumentDto>> => {
    return {
      status: 200,
      data: paginate(this.realEstateItDocuments, params),
    };
  };

  getProfiles = (
    params: IntSearchDto
  ): IResponse<TableData<IntRealEstateItProfileDto>> => {
    return {
      status: 200,
      data: paginate(this.realEstateItProfiles, params),
    };
  };

  getSites = (params: IntSearchDto): IResponse<TableData<IntSiteDto>> => {
    return {
      status: 200,
      data: paginate(this.realEstateItSites, params),
    };
  };

  getSuppliers = (
    params: IntSearchDto
  ): IResponse<TableData<IntSupplierTableDto>> => {
    return {
      status: 200,
      data: paginate(this.realEstateItSuppliers, params),
    };
  };

  getSwitches = (params: IntSearchDto): IResponse<TableData<IntSwitchDto>> => {
    return {
      status: 200,
      data: paginate(this.realEstateItSwitches, params),
    };
  };

  getAssetDetails = (
    id: string
  ): IResponse<IntRealEstateItConnectedDeviceDto> => {
    return {
      status: 200,
      data: this.getDetails(id),
    };
  };

  getAssetPing = (id: number): IResponse<IntPingResponseDto> => {
    return {
      status: 200,
      data: this.getDemoPing(id),
    };
  };

  getLocationDetails = (id: number): IResponse<IntPlaceDto> => {
    return {
      status: 200,
      data: this.getDemoLocation(id),
    };
  };

  getProfileDetails = (id: number): IResponse<IntRealEstateItProfileDto> => {
    return {
      status: 200,
      data: this.getDemoProfile(id),
    };
  };

  getSupplierDetails = (id: number): IResponse<IntSupplierDto> => {
    return {
      status: 200,
      data: this.getDemoSupplierDetails(id),
    };
  };

  deleteAsset = (): IResponse => ({ status: 200 });
  deleteProfile = (): IResponse => ({ status: 200 });
  deleteLocation = (): IResponse => ({ status: 200 });
  delete = (): IResponse => ({ status: 200 });

  create = (): IResponse => ({ status: 201 });

  update = (): IResponse => ({ status: 200 });
}

export const realEstateItDemoData = new RealEstateItDemoData();
