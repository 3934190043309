import { Box } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { useStyles } from './AtlasLabel.styles';

export interface IAtlasLabelProps {
  dataCy?: string;
  label: string;
  className?: string;
}

const AtlasLabel: React.FC<IAtlasLabelProps> = ({
  label = '',
  className,
  dataCy,
}) => {
  const classes = useStyles();

  return (
    <Box className={classNames(classes.root, className)} data-cy={dataCy}>
      {label}
    </Box>
  );
};

export default AtlasLabel;
