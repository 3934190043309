import { Checkbox } from '@mui/material';
import { Column, ColumnRenderProps } from 'react-table';
import { TableSelectState } from '../..';

export function checkBox<T>(
  selectState: TableSelectState<T>,
  dataCy?: string
): Column<T> {
  const { isRowSelected, selectRow, isRowDisabled, toggleAll, isAllSelected } =
    selectState;

  const checkboxStyle = {
    padding: 0,
    width: '100%',
    height: '100%',
  };

  return {
    id: 'selectCheck',
    className: 'p-0',
    Header: ({ data }: ColumnRenderProps) => {
      const rows = data
        .filter(row => {
          const isDisabled = isRowDisabled && isRowDisabled(row._original);
          return !isDisabled;
        })
        .map(row => row._original);
      const isChecked = Boolean(rows.length) && isAllSelected(rows);

      return (
        <Checkbox
          checked={isChecked}
          disabled={!rows.length}
          color="primary"
          size="small"
          onClick={() => toggleAll(rows, isChecked)}
          style={checkboxStyle}
        />
      );
    },
    accessor: () => '',
    resizable: false,
    sortable: false,
    Cell: row => {
      const checked = isRowSelected(row.original);

      return (
        <Checkbox
          data-cy={dataCy}
          checked={checked}
          color="primary"
          onChange={(_, c) => selectRow(row.original, c)}
          size="small"
          disabled={isRowDisabled && isRowDisabled(row.original)}
          style={checkboxStyle}
        />
      );
    },
    filterable: false,
    Filter: undefined,
    width: 36,
  };
}
