import { Role } from 'components/Auth/Role';
import { snackbar } from 'components/Snackbar';
import { column, TableData } from 'components/Table';
import { parseISO } from 'date-fns';
import {
  DynamicAttributeDataType,
  IntAttributeDefinitionDto,
  IntAttributeDto,
  IntAttributeResourceType,
  IntSearchDto,
  ServiceDataSpecificationColumnType,
} from 'generated';
import i18n from 'i18n';
import { isEmpty } from 'lodash';
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { assetAPI } from 'services/asset.service';
import {
  dynamicAttributesApi,
  IEditDefinitionsParams,
} from 'services/dynamicAttributes.service';
import { IntAttributeDefinitionRowDto } from 'views/Connectivity/Sim/SIMModals/HandleAttributeDefinitionsModal/HandleAttributeDefinitionsModal';
import { getColId } from 'views/Dashboard/ComponentTypes/TableComponent/makeTableViewModel';
import { cacheRequest } from 'views/Dashboard/DataSources/cacheWidgetData';
import {
  DataPropType,
  IDataProperty,
} from 'views/Dashboard/DataSources/dataPropTypes';
import { category } from 'views/Dashboard/DataSources/ServiceData/assetDataProperties';
import { RootStore } from './rootStore';
import { StoreBase } from './storeBase';

export enum AttributeAction {
  COPY = 'copy',
  DELETE = 'delete',
  UPDATE = 'update',
  CREATE = 'create',
}

type Attribute = 'asset' | 'connectivityUnit';

const convertToUnix = (date: string) => {
  const toUnix = Date.parse(date);
  return toUnix ? new Date(+toUnix).toISOString() : null;
};

const getRawValue = (row: any, dataProp: IDataProperty, type: Attribute) => {
  let rawValue;
  if (type === 'asset') {
    if (!row.assetAttributes) {
      return null;
    }

    rawValue = row.assetAttributes[dataProp.id];
  } else {
    if (!row.customAttributes) {
      return null;
    }

    rawValue = row.customAttributes[dataProp.id];
  }

  if (Array.isArray(rawValue)) {
    let formattedArr: any = [];
    if (dataProp.type === 'dateTime') {
      rawValue.forEach(el => {
        formattedArr.push(convertToUnix(el));
      });
    }
    rawValue = formattedArr.length > 0 ? formattedArr : rawValue;
  } else {
    if (dataProp.type === 'dateTime') {
      convertToUnix(rawValue);
    }
  }
  return rawValue;
};

export const getTableColumnForProperty = (
  dataProp: IDataProperty,
  type: Attribute = 'asset'
) => {
  const id = getColId(dataProp);
  const accessor = (row: any) => {
    const rawValue = getRawValue(row, dataProp, type);

    return rawValue ?? null;
  };

  const { name: header, description } = dataProp;

  const dateCol = (description?: string) =>
    column.date({
      id,
      header,
      accessor,
      showTime: true,
      sortable: true,
      filterable: true,
      tooltip: !isEmpty(description),
      tooltipText: description,
      useIntervalFiltering: type === 'connectivityUnit',
    });

  const boolCol = (description?: string) =>
    column.boolean({
      id,
      header,
      accessor,
      tooltip: !isEmpty(description),
      tooltipText: description,
      sortable: true,
      filterable: true,
    });

  const textCol = (description?: string) =>
    column.text({
      id,
      ellipsis: true,
      header,
      accessor,
      sortable: true,
      filterable: true,
      filter: 'options',
      tooltip: !isEmpty(description),
      tooltipText: description,
      width: 160,
      multiSelect: type === 'asset',
    });

  const numberCol = (description?: string) =>
    column.number({
      id,
      header,
      accessor,
      decimals: dataProp.decimals,
      unit: dataProp.unit,
      tooltip: !isEmpty(description),
      tooltipText: description,
      sortable: true,
      filterable: true,
    });

  if (type === 'asset') {
    if (dataProp.type === 'dateTime') {
      return dateCol(description);
    } else {
      return textCol(description);
    }
  } else {
    switch (dataProp.type) {
      case 'dateTime':
        return dateCol(description);

      case 'boolean':
        return boolCol(description);

      case 'number':
        return numberCol(description);

      default:
        return textCol(description);
    }
  }
};

export class DynamicAttributeStore extends StoreBase {
  @observable customAttributeTypes: IntAttributeDefinitionDto[] = [];
  @observable filteredCustomAttributeTypes: IntAttributeDefinitionDto[] = [];
  @observable definitions: IntAttributeDefinitionDto[] = [];
  @observable usedDefinitionKeys: string[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }

  @computed get shouldFilterGlobal() {
    return !this.rootStore.authStore.hasRole(
      Role.RoleNameEditGlobalConnectivityUnitAttributeDefinitions
    );
  }

  @observable isRequestLoading = false;
  @observable deleteModalState = false;
  @observable deletingAttribute?: IntAttributeDefinitionRowDto;
  @observable customAttributesChangeDetected = false;

  @action.bound setAttributesChangeDetection(state: boolean) {
    this.customAttributesChangeDetected = state;
  }

  @action.bound openDeleteModal(attribute: IntAttributeDefinitionRowDto) {
    this.deleteModalState = true;
    this.deletingAttribute = attribute;
  }

  @action.bound closeDeleteModal() {
    this.deleteModalState = false;
    this.deletingAttribute = undefined;
  }

  @action.bound setRequestLoading(state: boolean) {
    this.isRequestLoading = state;
  }

  @action.bound getAttributesByType(resourceType: IntAttributeResourceType) {
    switch (resourceType) {
      case IntAttributeResourceType.ConnectivityUnit:
        return this.filteredCustomAttributeTypes.filter(
          c => c.isGlobal !== true
        );
      case IntAttributeResourceType.Asset:
        return this.definitions.filter(c => c.isGlobal !== true);
      default:
        return [];
    }
  }

  @action.bound updateAttributesByType(resourceType: IntAttributeResourceType) {
    if (resourceType === IntAttributeResourceType.ConnectivityUnit) {
      this.getCustomAttributesFilterGlobal(true);
    } else if (resourceType === IntAttributeResourceType.Asset) {
      this.getDefinitions();
    }
  }

  @action.bound async deleteCustomAttributeType(
    attribute: IEditDefinitionsParams
  ) {
    const resp = await this.httpDelete(
      dynamicAttributesApi.deleteCustomAttributeType,
      {
        params: attribute,
      },
      this.setRequestLoading
    );

    if (resp.status === 200 || resp.status === 204) {
      this.setAttributesChangeDetection(true);
      snackbar(
        i18n.t('sim:attribute_definitions.notification.delete.success'),
        {
          variant: 'success',
        }
      );
      if (
        attribute.resourceType === IntAttributeResourceType.ConnectivityUnit
      ) {
        await this.getCustomAttributesFilterGlobal(true);
      } else {
        await this.getDefinitions();
      }
    } else {
      snackbar(i18n.t('sim:attribute_definitions.notification.delete.error'), {
        variant: 'error',
      });
    }

    this.closeDeleteModal();
  }

  @action.bound createCustomAttributeType = async (
    data: IntAttributeDefinitionDto
  ) => {
    const resp = await this.httpPost(
      dynamicAttributesApi.createCustomAttributeType,
      {
        params: data.resourceType,
        data,
      },
      this.setRequestLoading
    );

    if (resp.status === 201) {
      this.setAttributesChangeDetection(true);
      snackbar(
        i18n.t('sim:attribute_definitions.notification.create.success'),
        {
          variant: 'success',
        }
      );

      if (data.resourceType === IntAttributeResourceType.ConnectivityUnit) {
        await this.getCustomAttributesFilterGlobal(true);
      } else {
        await this.getDefinitions();
      }
    } else {
      snackbar(i18n.t('sim:attribute_definitions.notification.create.error'), {
        variant: 'error',
      });
    }
  };

  @action.bound updateCustomAttributeType = async (
    data: IntAttributeDefinitionDto
  ) => {
    const resp = await this.httpPut(
      dynamicAttributesApi.updateCustomAttributeType,
      {
        params: {
          definitionId: data.id ?? '',
          resourceType: data.resourceType,
        },
        data,
      },
      this.setRequestLoading
    );

    if (resp.status === 200) {
      this.setAttributesChangeDetection(true);
      snackbar(
        i18n.t('sim:attribute_definitions.notification.update.success'),
        {
          variant: 'success',
        }
      );

      if (data.resourceType === IntAttributeResourceType.ConnectivityUnit) {
        await this.getCustomAttributesFilterGlobal(true);
      } else {
        await this.getDefinitions();
      }
    } else {
      snackbar(i18n.t('sim:attribute_definitions.notification.update.error'), {
        variant: 'error',
      });
    }
  };

  @action.bound async getCustomAttributesFilterGlobal(
    forceRefresh = false
  ): Promise<void> {
    let key = 'getAllConnectivityUnitAttributeTypes';

    let searchDto: IntSearchDto = {
      filters: '',
      orderBy: '',
      orderDesc: false,
      page: 0,
      pageSize: 9999,
      search: '',
    };

    if (this.shouldFilterGlobal) {
      key = 'getAllConnectivityUnitAttributeTypes_filtered';
      searchDto.filters = JSON.stringify([{ id: 'isGlobal', value: false }]);
    }

    const request = async () => {
      const resp = await this.httpGet(
        dynamicAttributesApi.getCustomAttributes,
        searchDto,
        this.setRequestLoading
      );

      if (resp.status === 200 && resp.data) {
        return resp.data;
      }
      return [];
    };

    let customAttributes: IntAttributeDefinitionDto[];
    if (forceRefresh) {
      customAttributes = await request();
    } else {
      customAttributes = await cacheRequest({
        key,
        request,
        validForSeconds: 3600,
      });
    }

    runInAction(() => {
      this.filteredCustomAttributeTypes = customAttributes;
    });
  }

  @action.bound async getUsedDefinitionKeysForResource(
    resourceType: IntAttributeResourceType,
    resourceId: string,
    forceRefresh = false
  ): Promise<void> {
    const key = 'getAllConnectivityUnitAttributeTypes';

    const searchDto: IntSearchDto = {
      filters: '',
      orderBy: '',
      orderDesc: false,
      page: 0,
      pageSize: 9999,
      search: '',
    };

    const request = async () => {
      const resp =
        resourceType === IntAttributeResourceType.ConnectivityUnit
          ? await this.httpGet(
              dynamicAttributesApi.getCustomAttributesTable(
                resourceType,
                resourceId
              ),
              searchDto,
              this.setRequestLoading
            )
          : await this.httpGet(
              assetAPI.getAttributes(resourceId),
              searchDto,
              this.setRequestLoading
            );
      if (resp.status === 200 && resp.data) {
        return resp.data;
      }
      return null;
    };

    let attributes: TableData<IntAttributeDto> | null;
    if (forceRefresh) {
      attributes = await request();
    } else {
      attributes = await cacheRequest({
        key,
        request,
        validForSeconds: 3600,
      });
    }

    const usedDefKeys: string[] = [];
    if (attributes && attributes.rows != null) {
      attributes.rows.forEach((a: IntAttributeDto) => {
        if (
          a.definitionId != null &&
          usedDefKeys.indexOf(a.definitionId) === -1
        ) {
          usedDefKeys.push(a.definitionId);
        }
      });
    }

    runInAction(() => {
      this.usedDefinitionKeys = usedDefKeys;
    });
  }

  @action.bound async getCustomAttributes(): Promise<void> {
    const resp = await this.httpGet(
      dynamicAttributesApi.getCustomAttributes,
      undefined
    );

    if (resp.status === 200 && resp.data) {
      runInAction(() => {
        this.customAttributeTypes = resp.data || [];
      });
    }
  }

  @action.bound async getDefinitions(): Promise<void> {
    const resp = await this.httpGet(
      dynamicAttributesApi.getDefinitions,
      undefined
    );

    if (resp.status === 200 && resp.data) {
      runInAction(() => {
        this.definitions =
          resp.data?.filter(
            d =>
              ![
                DynamicAttributeDataType.File,
                DynamicAttributeDataType.Unknown,
              ].includes(d.dataType)
          ) || [];
      });
    }
  }

  @action.bound formatValue(value: any, dataType: DynamicAttributeDataType) {
    if (dataType === DynamicAttributeDataType.DateTime) {
      return value ? value.map((v: string) => parseISO(v)) : null;
    } else {
      return value;
    }
  }

  @computed get dynamicAttributeProperties(): IDataProperty[] {
    const { definitions } = this.rootStore.dynamicAttributeStore;

    return definitions.map(def => ({
      id: def.id || '',
      category,
      _get: asset => {
        if (!asset.AssetDetailsDynamicAttributes) {
          return null;
        }

        const value = asset.AssetDetailsDynamicAttributes[def.id || ''];

        return Array.isArray(value)
          ? this.formatValue(value, def.dataType)
          : value ?? null;
      },
      name: def.name,
      type: this.parseDefinitionValueType(def),
      description: def.description,

      column: {
        columnType: ServiceDataSpecificationColumnType.AssetAttribute,
        value: def.id || '',
      },
    }));
  }

  @computed get customAttributeProperties(): IDataProperty[] {
    const { customAttributeTypes } = this.rootStore.dynamicAttributeStore;

    return customAttributeTypes.map(cus => ({
      id: cus.id || '',
      _get: connectivityUnit => {
        if (!connectivityUnit.customAttributes) {
          return null;
        }
        const rawValue = connectivityUnit.customAttributes[cus.id || ''];

        if (cus.dataType === DynamicAttributeDataType.DateTime) {
          return rawValue ? parseISO(rawValue) : null;
        }

        return rawValue ?? null;
      },
      description: cus.description,
      name: cus.name,
      type: this.parseDefinitionValueType(cus),
    }));
  }

  parseDefinitionValueType(
    definition: IntAttributeDefinitionDto
  ): DataPropType {
    if (definition.dataType) {
      switch (definition.dataType) {
        case DynamicAttributeDataType.Text:
          return 'string';
        case DynamicAttributeDataType.Number:
          return 'number';
        case DynamicAttributeDataType.Boolean:
          return 'boolean';
        case DynamicAttributeDataType.DateTime:
          return 'dateTime';
      }
    }
    return 'string';
  }
}
