import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      color: theme.palette.background.paper,
      fontSize: '14px',
      alignItems: 'center',
      boxShadow: theme.shadows[24],
      borderRadius: 2,
    },
    success: {
      backgroundColor: theme.palette.success.main,
    },
    error: {
      backgroundColor: theme.palette.error.main,
    },
    warning: {
      backgroundColor: theme.palette.warning.main,
    },
  })
);
