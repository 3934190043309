import { IconButton, IconButtonProps } from '@mui/material';
import React from 'react';
import { useStyles } from './WidgetHeaderButton.styles';

const WidgetHeaderButton: React.FC<IconButtonProps> = props => {
  const classes = useStyles();

  return <IconButton {...props} className={classes.root} />;
};

export default WidgetHeaderButton;
