import {
  IntConnectivityType,
  IntProductConnectivityDetailsDto,
  IntProductDto,
  IntProductIpAccessDetailsDto,
  IntProductLocalizationDetailsDto,
  IntProductSmsAccessDetailsDto,
  ProductStatus,
  VpnStatus,
} from 'generated';
import { IResponse } from 'shared/interfaces/api';
import { rnd, rndInt, tableDemoData } from 'utils';

class ProductDemoData {
  private readonly numOfProducts = 4;
  private readonly productName = (num?: number) =>
    `Product ${num === undefined ? rnd.int(1, this.numOfProducts) : num}`;
  private readonly vpnListName = () => `VPN ${rnd.int(1, 9)}`;
  private readonly pricePlan = () => `PricePlan ${rnd.int(1, 9)}`;

  get getAll() {
    return tableDemoData<IntProductDto>(
      i => ({
        productId: `${rnd.int(1, 9)}`,
        name: this.productName(i + 1),
        startDate: new Date(),
        productStatus: ProductStatus.Active,
        productType: rndInt(1, 4),
      }),
      this.numOfProducts
    );
  }

  getConnectivityDetails = (): IResponse<IntProductConnectivityDetailsDto> => {
    return {
      status: 200,
      data: {
        status: rnd.int(0, 2),
        pricePlan: this.pricePlan(),
        homeNetwork: `Network ${rnd.int(1, 9)}`,
        formFactor: ['formfactor1', 'formfactor2'],
      },
    };
  };

  getIpAccessDetails = (): IResponse<IntProductIpAccessDetailsDto> => {
    return {
      status: 200,
      data: {
        status: rnd.int(0, 2),
        profile: rnd.int(0, 1),
        pricePlan: this.pricePlan(),
        vpnList: [
          {
            status: VpnStatus.Ready,
            name: this.vpnListName(),
          },
          {
            status: VpnStatus.Pending,
            name: this.vpnListName(),
          },
          {
            status: VpnStatus.Up,
            name: this.vpnListName(),
          },
          {
            status: VpnStatus.Unconfigured,
            name: this.vpnListName(),
          },
          {
            status: VpnStatus.NotFound,
            name: this.vpnListName(),
          },
        ],
        apnName: `APN #${rnd.int(1, 9)}`,
        allocatedIps: rnd.int(0, 20),
      },
    };
  };

  getSmsAccessDetails = (): IResponse<IntProductSmsAccessDetailsDto> => {
    return {
      status: 200,
      data: {
        status: rnd.int(0, 2),
        smsMoUrl: `MoUrl-${rnd.int(1, 9)}`,
        deliveryReportUrl: `deliveryReportUrl-${rnd.int(1, 9)}`,
      },
    };
  };

  getLocalizationDetails = (): IResponse<IntProductLocalizationDetailsDto> => {
    return {
      status: 200,
      data: {
        status: rnd.int(0, 2),
        countryName: rnd.country(),
        countryCode: `N/A`,
        priceProfileId: `${rnd.int(1, 999)}`,
      },
    };
  };

  getProducts = (): IResponse<IntConnectivityType[]> => {
    return {
      status: 200,
      data: [
        IntConnectivityType.GIC,
        IntConnectivityType.MIC,
        IntConnectivityType.LIC,
      ],
    };
  };
}

export const productDemoData = new ProductDemoData();
