import { AutomationRuleStatus } from 'automationcenter-api';

import i18n from 'i18next';
import { Column } from 'react-table';
import { CustomCell } from '../Helpers/CustomCell';
import { ICustomCellProps } from '../Helpers/CustomCellProps';
import { getOptionFilter } from '../Helpers/getOptionFilter';

export function automationStatus<T>(options: {
  id: string;
  accessor: (row: T) => AutomationRuleStatus;
  header?: string;
  defaultShow?: boolean;
  filterable?: boolean;
}): Column<T> {
  const {
    id,
    header = 'Status',
    accessor,
    defaultShow: show,
    filterable,
  } = options;

  const cellByStatus: Record<AutomationRuleStatus, ICustomCellProps> = {
    [AutomationRuleStatus.Enabled]: {
      iconType: 'StatusOk',
      text: i18n.t(`automation:status.${AutomationRuleStatus.Enabled}`),
    },
    [AutomationRuleStatus.Disabled]: {
      iconType: 'StatusWarning',
      text: i18n.t(`automation:status.${AutomationRuleStatus.Disabled}`),
    },
    [AutomationRuleStatus.Provisioning]: {
      iconType: 'StatusPending',
      text: i18n.t(`automation:status.${AutomationRuleStatus.Provisioning}`),
    },
    [AutomationRuleStatus.Error]: {
      iconType: 'StatusError',
      text: i18n.t(`automation:status.${AutomationRuleStatus.Error}`),
    },
  };

  return {
    id,
    Header: header,
    accessor,
    resizable: false,
    maxWidth: 130,
    filterable,
    show,
    Cell: row => {
      const iconProps = cellByStatus[row.value as AutomationRuleStatus] || {};
      return <CustomCell {...iconProps} index={row.index} />;
    },
    Filter: getOptionFilter([
      {
        label: i18n.t('all'),
        value: '',
      },
      {
        label: i18n.t(`automation:status.${AutomationRuleStatus.Enabled}`),
        value: AutomationRuleStatus.Enabled,
      },
      {
        label: i18n.t(`automation:status.${AutomationRuleStatus.Disabled}`),
        value: AutomationRuleStatus.Disabled,
      },
      {
        label: i18n.t(`automation:status.${AutomationRuleStatus.Provisioning}`),
        value: AutomationRuleStatus.Provisioning,
      },
      {
        label: i18n.t(`automation:status.${AutomationRuleStatus.Error}`),
        value: AutomationRuleStatus.Error,
      },
    ]),
  };
}
