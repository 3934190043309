import { action, makeObservable, observable } from 'mobx';
export class Poller {
  @observable currentTick = 0;
  timerId = 0;

  constructor() {
    makeObservable(this);
  }

  start(ms: number) {
    this.stop();
    this.timerId = window.setTimeout(this.tick, ms);
  }

  stop() {
    window.clearTimeout(this.timerId);
  }

  @action.bound tick() {
    this.currentTick++;
  }
}
