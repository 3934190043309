import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      borderRadius: 5000,
      paddingLeft: 10,
      width: '100%',
    },
    input: {
      paddingTop: 11,
      paddingBottom: 12,
    },
  })
);
