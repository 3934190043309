import {
  IntCopyProfileDto,
  IntCreateProfileDto,
  IntDefaultProfileDto,
  IntDetailsDto,
  IntProfileDto,
  IntStateDto,
  IntSubnetDto,
  IntUpdateProfileDto,
} from 'generated';
import {
  DeleteEndpoint,
  GetEndpoint,
  PostEndpoint,
  PutEndpoint,
} from 'shared/interfaces/api';
import { apnHubDemoData } from './demoData/apnHubDemoData';

type ApnHubAPI = {
  setDataTrafficStatus: PostEndpoint<{
    connectivityUnitId: string;
    blockDataTraffic: boolean;
  }>;
  updateDefaultEnterpriseProfile: PostEndpoint<
    string,
    IntUpdateProfileDto,
    IntProfileDto | undefined
  >;
  getDetails: GetEndpoint<string, IntDetailsDto>;
  getState: GetEndpoint<string, IntStateDto>;
  getSubnets: GetEndpoint<string, IntSubnetDto[]>;
  getProfiles: GetEndpoint<string, IntProfileDto[]>;
  createProfile: PostEndpoint<string, IntCreateProfileDto>;
  updateProfile: PutEndpoint<string, IntCreateProfileDto>;
  copyProfile: PostEndpoint<string, IntCopyProfileDto>;
  deleteProfile: DeleteEndpoint<{
    customerId: string;
    profileId: string;
  }>;
  getTrafficStatus: GetEndpoint<string, IntDefaultProfileDto>;
};

const baseUrl = '/api/apn';

export const apnHubAPI: ApnHubAPI = {
  setDataTrafficStatus: {
    url: ({ connectivityUnitId, blockDataTraffic }) =>
      `${baseUrl}/${connectivityUnitId}/setdatatraffic?blocked=${blockDataTraffic}`,
    mockData: apnHubDemoData.setDataTrafficStatus,
  },
  updateDefaultEnterpriseProfile: {
    url: customerId => `${baseUrl}/${customerId}/setdefaultprofile`,
    mockData: apnHubDemoData.updateDefaultEnterpriseProfile,
  },
  getDetails: {
    url: customerId => `${baseUrl}/details/${customerId}`,
    mockData: apnHubDemoData.getDetails,
  },
  getState: {
    url: customerId => `${baseUrl}/state/${customerId}`,
    mockData: apnHubDemoData.getState,
  },
  getSubnets: {
    url: customerId => `${baseUrl}/subnets/${customerId}`,
    mockData: apnHubDemoData.getSubnets,
  },
  getProfiles: {
    url: customerId => `${baseUrl}/profiles/${customerId}`,
    mockData: apnHubDemoData.getProfiles,
  },
  createProfile: {
    url: customerId => `${baseUrl}/profiles/${customerId}`,
    mockData: () => ({ status: 200 }),
  },
  updateProfile: {
    url: customerId => `${baseUrl}/profiles/${customerId}`,
    mockData: () => ({ status: 200 }),
  },
  copyProfile: {
    url: customerId => `${baseUrl}/profiles/${customerId}/copy`,
    mockData: () => ({ status: 200 }),
  },
  deleteProfile: {
    url: ({ customerId, profileId }) =>
      `${baseUrl}/profiles/${customerId}/${profileId}`,
    mockData: () => ({ status: 200 }),
  },
  getTrafficStatus: {
    url: customerId => `${baseUrl}/defaultProfile/${customerId}`,
    mockData: apnHubDemoData.getTrafficStatus,
  },
};
