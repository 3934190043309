import { IntSearchDto } from 'generated';
import { action, makeObservable } from 'mobx';
import { assetAPI } from 'services/asset.service';
import { RootStore } from 'store/rootStore';
import { StoreBase } from 'store/storeBase';

export class DynamicFilterStore extends StoreBase {
  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this);
  }

  @action.bound async getFilterOptions(
    definitionId: string,
    page = 0,
    search = ''
  ) {
    const tableParams: IntSearchDto = {
      page,
      pageSize: 100,
      orderBy: null,
      orderDesc: null,
      search: search,
      filters: `[{ id: 'definitionId', value: '${definitionId}' }]`,
    };
    const resp = await this.httpGet(
      assetAPI.getAttributeOptions,
      tableParams,
      loading => this.setIsLoading(loading)
    );

    return resp.data;
  }
}
