import { Box, Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ComponentContainer from 'views/Dashboard/ComponentContainer';
import { IDashboardComponent } from 'views/Dashboard/dashboardTypes';
import { useDataSource } from 'views/Dashboard/DataSources';
import { Control } from 'views/Dashboard/SettingControls';
import SettingsForShowLatestAssetUpdate from 'views/Dashboard/SettingsForShowLatestUpdateFromAsset';
import PolicyControl from '../../NumberGridComponent/SettingsSteps/PolicyControl';
import { IGaugeSettings } from '../gaugeConfig';

// Remove legacy color settings once all existing gauges have been updated to use policies, see PBI #68512
function showGaugeColorSettings(settings: IGaugeSettings): boolean {
  // Only show legacy range inputs if they were already configured and a policy hasn't been selected
  return (
    !settings.policyId &&
    !!Object.values(settings.colors).find(
      v => v.min !== undefined || v.max !== undefined
    )
  );
}

const GeneralSettingsStep: IDashboardComponent<IGaugeSettings> = ({
  component,
}) => {
  const { t } = useTranslation('dashboard');
  const { settings, dashboard } = component;
  const dataSource = useDataSource(settings.dataSource);
  const gaugeValueMin = 'gauge.value_min';
  const gaugeValueMax = 'gauge.value_max';

  const dataPropId =
    typeof settings.valueProp === 'object'
      ? settings.valueProp.id
      : settings.valueProp;

  // When changing the data prop - update the unit for convenience
  useEffect(() => {
    const newDataProp = dataSource.dataProperties.find(
      dp => dp.id === dataPropId
    );
    if (newDataProp && newDataProp.unit !== settings.valueUnit) {
      dashboard.updateSetting(settings, 'valueUnit', newDataProp.unit || '');
    }
  }, [dashboard, dataSource, dataPropId, settings]);

  const showOldColorSettings = showGaugeColorSettings(settings);

  return (
    <Grid container spacing={4}>
      <Grid item sm={6}>
        <Control.Group label={t('general_settings')}>
          <Control.Text
            object={settings}
            name="title"
            autoFocus
            label={t('title')}
          />
          <SettingsForShowLatestAssetUpdate settings={settings} />
          <Control.DataProperty
            object={settings}
            name="valueProp"
            label={t('gauge.property')}
            options={dataSource.dataProperties || []}
            allowDate={false}
            allowString={false}
          />
        </Control.Group>

        <Control.Group label={t('gauge.gauge_unit')}>
          <Control.Text
            object={settings}
            name="valueUnit"
            label={t('gauge.unit')}
          />
        </Control.Group>

        <Control.Group label={t('gauge.max_and_min')}>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <Control.Number
                object={settings}
                name="valueMin"
                label={t(gaugeValueMin)}
              />
            </Grid>
            <Grid item sm={6}>
              <Control.Number
                object={settings}
                name="valueMax"
                label={t(gaugeValueMax)}
              />
            </Grid>
          </Grid>
        </Control.Group>
      </Grid>
      <Grid item sm={6}>
        <Control.Group label={t('preview')}>
          <Box height={320}>
            <ComponentContainer component={component} />
          </Box>
        </Control.Group>

        <PolicyControl settings={settings} />
      </Grid>

      {showOldColorSettings && (
        <Control.Group label={t('gauge.ranges')}>
          <Grid container spacing={4}>
            <Grid item sm={6}>
              <Control.Group label={t('color.green')} variant="small">
                <Grid container spacing={2}>
                  <Grid item sm={6}>
                    <Control.Number
                      object={settings.colors.green}
                      name="min"
                      label={t(gaugeValueMin)}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <Control.Number
                      object={settings.colors.green}
                      name="max"
                      label={t(gaugeValueMax)}
                    />
                  </Grid>
                </Grid>
              </Control.Group>
            </Grid>

            <Grid item sm={6}>
              <Control.Group label={t('color.yellow')} variant="small">
                <Grid container spacing={2}>
                  <Grid item sm={6}>
                    <Control.Number
                      object={settings.colors.yellow}
                      name="min"
                      label={t(gaugeValueMin)}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <Control.Number
                      object={settings.colors.yellow}
                      name="max"
                      label={t(gaugeValueMax)}
                    />
                  </Grid>
                </Grid>
              </Control.Group>
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item sm={6}>
              <Control.Group label={t('color.orange')} variant="small">
                <Grid container spacing={2}>
                  <Grid item sm={6}>
                    <Control.Number
                      object={settings.colors.orange}
                      name="min"
                      label={t(gaugeValueMin)}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <Control.Number
                      object={settings.colors.orange}
                      name="max"
                      label={t(gaugeValueMax)}
                    />
                  </Grid>
                </Grid>
              </Control.Group>
            </Grid>

            <Grid item sm={6}>
              <Control.Group label={t('color.red')} variant="small">
                <Grid container spacing={2}>
                  <Grid item sm={6}>
                    <Control.Number
                      object={settings.colors.red}
                      name="min"
                      label={t(gaugeValueMin)}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <Control.Number
                      object={settings.colors.red}
                      name="max"
                      label={t(gaugeValueMax)}
                    />
                  </Grid>
                </Grid>
              </Control.Group>
            </Grid>
          </Grid>
        </Control.Group>
      )}
    </Grid>
  );
};

export default observer(GeneralSettingsStep);
