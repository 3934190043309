import { DashboardComponentType } from 'generated';
import { IDataSourceConfig } from '../../dataSourceTypes';

export interface ISimActivityByCountryDataSourceSettings {
  type: 'simActivityByCountry';
}

export const simActivityByCountryDataSourceConfig: IDataSourceConfig<ISimActivityByCountryDataSourceSettings> =
  {
    icon: 'Basketball',
    settingsSteps: [],
    getDefaultSettings: async () => {
      return {
        type: 'simActivityByCountry',
      };
    },
    enabledComponentTypes: {
      [DashboardComponentType.Table]: true,
      [DashboardComponentType.HighchartsMap]: true,
    },
    shouldShowDateFilter: true,
  };
