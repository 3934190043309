import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  listItem: {
    marginTop: `1px !important`,
  },
  root: {
    padding: '0 !important',
  },
  inputRoot: {
    padding: '18px 20px 2px 5px !important',
    display: 'flex',
    flexWrap: 'wrap',
  },
  input: {
    padding: '5px 12px 10px 12px !important',
  },
}));
