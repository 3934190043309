import { rnd } from 'utils';
import { IntProductTemplateOptionDto } from '../../generated/index';
class ProductTemplatesDemoData {
  get getProductTemplates() {
    return rnd.array<IntProductTemplateOptionDto>(5, i => ({
      productTemplateId: rnd.guid(),
      displayName: rnd.firstName(),
      active: rnd.bool(),
    }));
  }
}

export const productTemplatesDemoData = new ProductTemplatesDemoData();
