import { byteThreshold } from './constants';

const byteConversionUnitTypes = [
  'kB',
  'MB',
  'GB',
  'TB',
  'PB',
  'EB',
  'ZB',
  'YB',
];

function convertToUnitNumber(value: number, threshold: number): number {
  if (Math.abs(value) < threshold) {
    return value;
  }
  let u = -1;
  do {
    value /= threshold;
    ++u;
  } while (
    Math.abs(value) >= threshold &&
    u < byteConversionUnitTypes.length - 1
  );
  return value;
}

function getConversionUnit(value: number, threshold: number): string {
  if (Math.abs(value) < threshold) {
    return 'B';
  }
  const units = byteConversionUnitTypes;
  let u = -1;
  do {
    value /= threshold;
    ++u;
  } while (Math.abs(value) >= threshold && u < units.length - 1);

  return units[u];
}

export function convertByteToUnitNumber(bytes: number): number {
  return convertToUnitNumber(bytes, byteThreshold);
}

export function getByteConversionUnit(bytes: number): string {
  return getConversionUnit(bytes, byteThreshold);
}

export function getByteConversionUnitAndValue(bytes: number) {
  if (Math.abs(bytes) < byteThreshold) {
    return { unit: 'B', value: bytes };
  }
  let u = -1;
  do {
    bytes /= byteThreshold;
    ++u;
  } while (
    Math.abs(bytes) >= byteThreshold &&
    u < byteConversionUnitTypes.length - 1
  );

  return {
    unit: byteConversionUnitTypes[u],
    value: Math.round(bytes * 1e2) / 1e2,
  };
}

export function getHumanReadableByteConversion(bytes: number): string {
  const { value, unit } = getByteConversionUnitAndValue(bytes);

  return `${value} ${unit}`;
}

export const convertFileToBase64String: (
  file: File,
  handleConversionComplete: (base64: string | ArrayBuffer | null) => any
) => void = (file, handleConversionComplete) => {
  if (!!file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      handleConversionComplete(reader.result);
    };
  }
};

export const convertToBoolean = (input: string): boolean => {
  try {
    return JSON.parse(input.toLowerCase());
  } catch (e) {
    return false;
  }
};

export function roundDateToSecond(d: Date): Date {
  return new Date(Math.floor(d.getTime() / 1000) * 1000);
}
