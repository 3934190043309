import { Column } from 'react-table';

export function progressBar<T>(options: {
  id: string;
  header: string;
  accessor: (row: T) => number;
  max: number;
  width?: number;
}): Column<T> {
  const { id, header, accessor, width = 70, max } = options;

  return {
    id,
    Header: header,
    accessor,
    width,
    filterable: false,
    Cell: row => {
      // todo: colors from theme. useTheme() does NOT work here - investigate.
      const { red, yellow, green } = {
        red: '#85cc00',
        yellow: '#ffbf00',
        green: '#ff2e00',
      };
      const percentage = Math.min((row.value / max) * 100, 100);
      let color: string;

      if (percentage > 66) {
        color = red;
      } else {
        color = percentage > 33 ? yellow : green;
      }

      return (
        <div
          style={{
            width: '100%',
            height: 10,
            marginTop: 4,
            backgroundColor: 'rgba(255, 255, 255, .1)',
            borderRadius: 4,
          }}
          title={`${percentage}%`}
        >
          <div
            style={{
              width: `${percentage}%`,
              height: '100%',
              backgroundColor: color,
              borderRadius: 'inherit',
              transition: 'all .2s ease-out',
            }}
          />
        </div>
      );
    },
  };
}
