import {
  ReactPlugin,
  withAITracking,
} from '@microsoft/applicationinsights-react-js';
import {
  ApplicationInsights,
  ITelemetryItem,
} from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';
import {
  getEnvironmentVariable,
  getEnvSuffix,
  isDevelopment,
  isPreview,
} from 'utils/constants';

const reactPlugin = new ReactPlugin();
const browserHistory = createBrowserHistory({
  basename: '',
});
const envSuffix = getEnvSuffix();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: getEnvironmentVariable(
      'APPLICATION_INSIGHTS_CONNECTION_STRING'
    ),
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
    enableCorsCorrelation: true,
    enableAutoRouteTracking: false,
    autoTrackPageVisitTime: true,
    enableAjaxErrorStatusText: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    correlationHeaderExcludedDomains: ['*.queue.core.windows.net', 'localhost'],
    correlationHeaderDomains: ['*.teliaiot.com', '*.azurewebsites.net'],
    namePrefix: `.tip.atlas${envSuffix}`,
    disableFetchTracking: true,
    appId: `tip.atlas${envSuffix}`,
    userCookiePostfix: envSuffix,
    sessionCookiePostfix: envSuffix,
    cookieCfg: { domain: envSuffix, enabled: true, path: '/' },
  },
});
/**
 * Dont enable applicationInsights when on localhost and previewslots.
 */
if (!isDevelopment && !isPreview) {
  appInsights.loadAppInsights();
}
// Manually call the first page track to get things going
// https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript?tabs=npm#advanced-correlation
// https://github.com/microsoft/ApplicationInsights-JS/tree/master#telemetry-initializers
// appInsights.context.telemetryTrace.parentID = 'serverId'; // Det här ska vara serverns ID eller random?
// appInsights.context.telemetryTrace.traceID = Util.generateW3CId();
// See if this helps with the first id.
appInsights.trackPageView();

/**
 * Every piece of telemetry is sent through this function and allows us to filter or modify
 * the data before its sent to Application Insights.
 */
const telemetryProcessor = (env: ITelemetryItem) => {
  if (!env.tags) {
    env.tags = [];
  }

  env.tags['ai.cloud.role'] = getEnvironmentVariable(
    'REACT_APP_APPLICATION_INSIGHTS_ROLE_NAME'
  );

  return true;
};

appInsights.addTelemetryInitializer(telemetryProcessor);
// To completely stop sending stuff, uncomment this. For example during demo mode?
// appInsights.addTelemetryInitializer(() => false);

export function aiTrackEvent(name: string, props?: Record<string, any>) {
  appInsights.trackEvent({
    name: name,
    properties: props,
  });
}

// Old way to go!
export function withAppInsightsTrack<TProps>(
  Component: React.ComponentType<TProps>
) {
  return withAITracking(
    reactPlugin,
    Component
    // Component?.displayName ?? 'Atlas'
  );
}

export { reactPlugin, appInsights };
