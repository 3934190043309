import { Box } from '@mui/material';
import AtlasIcon from 'components/AtlasIcon';
import AtlasTooltip from 'components/AtlasTooltip';
import i18n from 'i18next';
import { CellInfo, Column } from 'react-table';
import { formatDate } from 'utils';
import ColArrayValue from '../Helpers/ColArrayValue';
import getDateFilter from '../Helpers/getDateFilter';
import getDateIntervalFilter from '../Helpers/getDateIntervalFilter';
import { textRight } from '../Helpers/textRight';

type AccessorType =
  | Date
  | Date[]
  | string
  | string[]
  | number
  | number[]
  | undefined
  | null;

export function date<T>(options: {
  id: string;
  header: string;
  defaultShow?: boolean;
  defaultSortDesc?: boolean;
  accessor: (row: T) => AccessorType;
  width?: number;
  filterable?: boolean;
  sortable?: boolean;
  showTime?: boolean;
  showSeconds?: boolean;
  missingValueText?: string;
  tooltip?: boolean;
  tooltipText?: string;
  useIntervalFiltering?: boolean;
  useIntervalTimeFiltering?: boolean;
}): Column<T> {
  const {
    id,
    header,
    accessor,
    width = 130,
    defaultShow: show = true,
    defaultSortDesc = false,
    filterable,
    sortable,
    showTime,
    showSeconds,
    missingValueText,
    tooltip = false,
    tooltipText = '',
    useIntervalFiltering,
    useIntervalTimeFiltering = false,
  } = options;
  const renderCell = (row: CellInfo) => {
    if (Array.isArray(row.value)) {
      return (
        <ColArrayValue
          value={row.value}
          showSeconds={true}
          showTime={true}
          type="date"
        />
      );
    } else {
      return formatDate(row.value, { time: showTime, seconds: showSeconds });
    }
  };
  return {
    id,
    Header: tooltip ? (
      <AtlasTooltip placement="top" title={tooltipText} arrow>
        <Box textAlign="right">
          {header}
          <AtlasIcon size={14} type="Info" />
        </Box>
      </AtlasTooltip>
    ) : (
      <Box textAlign="right">{header}</Box>
    ),
    accessor,
    defaultSortDesc,
    width,
    maxWidth: 140,
    Filter: useIntervalFiltering
      ? getDateIntervalFilter(useIntervalTimeFiltering)
      : getDateFilter(),
    show,
    filterable,
    sortable,
    className: textRight,
    Cell: row =>
      row.value ? (
        renderCell(row)
      ) : (
        <span className="text-muted">
          {missingValueText ?? i18n.t('not_applicable_abbreviation')}
        </span>
      ),
    Aggregated: () => null, // At least for now, we don't need aggregation here
  };
}
