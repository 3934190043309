import { AutocompleteInputChangeReason } from '@mui/material';
import { IntFilterOptionsResponseDto } from 'generated';
import { useCallback, useEffect, useState } from 'react';
import { DynamicFilterStore } from 'store/dynamicFilterStore';
import { DynamicFilterControl } from 'utils/hooks';

export const useDynamicFilterControl = (
  definitionId: string,
  store: DynamicFilterStore
): DynamicFilterControl => {
  const { getFilterOptions, isLoading } = store;

  const [page, setPage] = useState(0);
  const [searchString, setSearchString] = useState('');
  const [searchTimer, setSearchTimer] = useState(0);
  const [total, setTotal] = useState(0);
  const [options, setOptions] = useState<string[]>([]);
  const [hasLoadedInitialFilters, setHasLoadedInitialFilters] =
    useState<boolean>(false);

  const updateSearch = () => {
    setPage(0);
    fetchInitData();
  };

  const handleSearch = (
    newSearch: string,
    reason: AutocompleteInputChangeReason
  ) => {
    if (reason === 'reset' && !newSearch) {
      return;
    }

    if (newSearch === searchString) {
      return;
    }
    setSearchString(newSearch);
    window.clearTimeout(searchTimer);

    if (reason === 'clear') {
      updateSearch();
    } else if (reason === 'input') {
      setSearchTimer(
        window.setTimeout(() => {
          updateSearch();
        }, 250)
      );
    }
  };
  const mapToFilterOption = (data: IntFilterOptionsResponseDto[]): string[] =>
    data.map(o => o.value);
  const fetchInitData = useCallback(async () => {
    const data = await getFilterOptions(definitionId, 0);
    if (data) {
      setHasLoadedInitialFilters(true);
      setOptions(mapToFilterOption(data.rows));
      setTotal(data.total);
    }
  }, [definitionId, getFilterOptions]);

  useEffect(() => {
    window.setTimeout(() => {
      fetchInitData();
    }, 50);
  }, [fetchInitData]);

  const fetchMore = () => {
    const newPage = page + 1;
    const fetchData = async () => {
      const data = await getFilterOptions(definitionId, newPage, searchString);

      if (data) {
        const opts = mapToFilterOption(data.rows);
        const newOptions = newPage > 0 ? [...options, ...opts] : opts ?? [];
        setOptions(newOptions);
        setTotal(data.total);
        setPage(newPage);
      }
    };
    fetchData();
  };

  return {
    page,
    options,
    fetchMore,
    total,
    handleSearch,
    searchString,
    loadingFilters: isLoading,
    hasLoadedInitialFilters,
  };
};
