import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    statusCircle: {
      borderRadius: '50%',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#707070', // Fallback in case there's no status
    },
    statusCircleSmall: {
      marginRight: 4,
      width: 10,
      height: 10,
    },
    statusCircleLarge: {
      marginRight: 8,
      width: 44,
      height: 44,
    },
    green: {
      background: theme.palette.success.main,
    },
    yellow: {
      background: theme.palette.warning.main,
    },
    red: {
      background: theme.palette.error.main,
    },
    value: {
      fontWeight: 'bold',
    },
    valueSmall: {
      fontSize: 14,
    },
    valueLarge: {
      fontSize: 28,
    },
    valueRow: {
      display: 'flex',
      alignItems: 'center',
    },
    valueRowLarge: {
      marginBottom: 10,
    },
  })
);
