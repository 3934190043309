import React, { useEffect, useRef, useState } from 'react';

interface IProps {
  className?: string;
  paddingBottom?: number;
  minHeight?: number;
  disableHeightCalculation?: boolean;
}

const AutoHeightContainer: React.FC<IProps> = ({
  className,
  paddingBottom = 24,
  children,
  minHeight = 500,
  disableHeightCalculation,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number>();
  const [maxHeight, setMaxHeight] = useState<number>();

  const timerRef = useRef(0);

  useEffect(() => {
    const triggerUpdate = () => {
      if (timerRef.current) {
        window.clearTimeout(timerRef.current);
      }
      timerRef.current = window.setTimeout(() => {
        timerRef.current = 0;
        updateHeight();
      }, 50);
    };

    const updateHeight = () => {
      if (disableHeightCalculation) {
        return;
      }
      if (containerRef.current) {
        const { top } = containerRef.current.getBoundingClientRect();
        let newHeight = window.innerHeight - top - paddingBottom;

        !maxHeight && setMaxHeight(newHeight);

        if (newHeight < minHeight) {
          newHeight = minHeight;
        } else if (maxHeight && newHeight > maxHeight) {
          newHeight = maxHeight;
        }
        setHeight(newHeight);
      }
    };
    updateHeight();
    window.addEventListener('resize', triggerUpdate);
    const autoUpdateTimer = setTimeout(updateHeight, 10);

    return () => {
      window.removeEventListener('resize', triggerUpdate);
      clearTimeout(autoUpdateTimer);
    };
  }, [paddingBottom, minHeight, maxHeight, disableHeightCalculation]);

  return (
    <div className={className} ref={containerRef} style={{ height, minHeight }}>
      {children}
    </div>
  );
};

export default AutoHeightContainer;
