const cachedData: Record<string, any> = {};
const cachedPromises: Record<string, Promise<any>> = {};

export async function cacheRequest<T>(opts: {
  key: string;
  request: () => Promise<T>;
  validForSeconds?: number;
  isCanceled?: (response: T) => boolean;
}): Promise<T> {
  const { key, request, validForSeconds = 30, isCanceled } = opts;

  if (cachedData[key]) {
    return cachedData[key];
  }

  if (!cachedPromises[key]) {
    cachedPromises[key] = new Promise<T>(resolve => {
      request().then(resp => {
        delete cachedPromises[key];
        resolve(resp);

        if (isCanceled?.(resp)) {
          return;
        }

        cachedData[key] = resp;

        // When the data goes stale, remove the cached reference to prevent memory leaks
        window.setTimeout(() => {
          delete cachedData[key];
        }, validForSeconds * 1000);
      });
    });
  }

  return cachedPromises[key];
}
