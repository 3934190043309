import {
  AutocompleteInputChangeReason,
  PopoverOrigin,
  PopoverProps,
} from '@mui/material';
import { SetStateAction, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { rndInt, rndItem } from './random';

export function useEllipsis(ellipsisRef: React.RefObject<HTMLElement>) {
  const [requiresEllipsis, setRequiresEllipsis] = useState(false);
  const textContent = ellipsisRef.current?.textContent;

  useEffect(() => {
    if (ellipsisRef.current) {
      const ellipsis = ellipsisRef.current;
      const comparison = ellipsis.cloneNode(true) as HTMLElement;

      // this css is implicit when using this hook so no issue setting it here
      ellipsis.style.whiteSpace = 'nowrap';
      ellipsis.style.overflow = 'hidden';
      ellipsis.style.textOverflow = 'ellipsis';

      comparison.style.position = 'absolute';
      comparison.style.left = '-999em';
      comparison.style.top = '-999em';

      if (ellipsis.nextSibling) {
        ellipsis.parentNode?.insertBefore(comparison, ellipsis.nextSibling);
      } else {
        ellipsis.parentNode?.appendChild(comparison);
      }

      setRequiresEllipsis(ellipsis.offsetWidth < comparison.offsetWidth);

      comparison.remove();
    }
  }, [ellipsisRef, textContent]);

  return { requiresEllipsis };
}

export function useFakeLoad<T>(returnData: T) {
  const [data, setData] = useState<T | undefined>();
  useEffect(() => {
    const t = rndItem([rndInt(10, 200), rndInt(700, 1000)]);
    const timer = setTimeout(() => {
      setData(returnData);
    }, t);
    return () => clearTimeout(timer);
  }, [returnData]);
  return data;
}

export function useFakeLoad2<T>(generateData: () => T, dep: any = null) {
  const [data, setData] = useState<T | undefined>();

  useEffect(() => {
    // setData(undefined); is probably a good idea here to clear while refreshing - but add return param isLoading?
    const t = rndItem([rndInt(10, 200), rndInt(500, 1000)]);
    const timer = window.setTimeout(() => {
      setData(generateData());
    }, t);
    return () => window.clearTimeout(timer);
  }, [generateData, dep]);

  return data;
}

// Saves and loads a value from localStorage to persist between reloads. Todo: could be further improved.
export function useSavedState<T>(
  key: string,
  defaultValue: T
): [T, React.Dispatch<SetStateAction<T>>] {
  const storageKey = `atlasUI.${key}`;
  const [value, setValue] = useState<T>(() => {
    const storedValue = localStorage.getItem(storageKey);
    if (storedValue) {
      try {
        return JSON.parse(storedValue);
      } catch (error) {
        /* ignore */
      }
    }
    return defaultValue;
  });

  useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(value));
  }, [storageKey, value]);

  return [value, setValue];
}

const bottomRightOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
};
const topRightOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

type PropTypes =
  | 'open'
  | 'id'
  | 'anchorEl'
  | 'anchorOrigin'
  | 'transformOrigin';

export function usePopover(popoverId: string): [
  React.EventHandler<React.MouseEvent<HTMLElement>>,
  Pick<PopoverProps, PropTypes> & {
    onClose: () => void;
  },
] {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOpen: React.EventHandler<React.MouseEvent<HTMLElement>> = e => {
    setAnchorEl(e.currentTarget);
  };

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const isOpen = Boolean(anchorEl);
  const id = isOpen ? popoverId : undefined;

  // Close popovers when the route changes
  const { pathname } = useLocation();
  useEffect(onClose, [pathname, onClose]);

  return [
    handleOpen,
    {
      id,
      open: isOpen,
      anchorEl,
      onClose,
      anchorOrigin: bottomRightOrigin,
      transformOrigin: topRightOrigin,
    },
  ];
}

export const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
};

export interface DynamicFilterControl {
  page: number;
  options: string[] | undefined;
  fetchMore: () => void;
  total: number;
  searchString: string;
  loadingFilters: boolean;
  hasLoadedInitialFilters: boolean;
  handleSearch: (
    newSearch: string,
    reason: AutocompleteInputChangeReason
  ) => void;
}

// Allow components to register a function that refreshes their data, instead of the RootStore "unmount and remount everything" approach that has its issues
let refreshFunctions: (() => void)[] = [];

// Run all refresh functions registered above
export function refreshComponents() {
  refreshFunctions.forEach(fn => fn());
}

export function useRegisterComponentRefresh(fn: () => void) {
  useEffect(() => {
    refreshFunctions.push(fn);

    return () => {
      refreshFunctions = refreshFunctions.filter(f => f !== fn);
    };
  }, [fn]);
}
