import { Box, Popper } from '@mui/material';
import AtlasIcon from 'components/AtlasIcon';
import AtlasIconButton from 'components/AtlasIconButton';
import { IntDashboardDataStatusRuleDto } from 'generated';
import { sumBy } from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IMapFeature,
  IMapFeatureGrouped,
} from '../ImageComponent/ImagePinLayer';
import { useStyles } from './GroupedMapTooltip.styles';
import { MapWidgetState } from './MapWidgetState';
import StatusCircle from './StatusCircle';

interface IProps {
  state: MapWidgetState;
  features: IMapFeatureGrouped[];
}

interface ITooltipRuleCount {
  count: number;
  propertyName: string;
  rule: IntDashboardDataStatusRuleDto;
}

// From a list of grouped map features, return what red statuses are active and how many times across all assets
const getRuleCounts = (features: IMapFeature[]): ITooltipRuleCount[] => {
  const counts: Record<string, ITooltipRuleCount> = {};

  const addCount = (
    rule: IntDashboardDataStatusRuleDto,
    propertyName: string
  ) => {
    if (!counts[rule.id]) {
      counts[rule.id] = { rule, count: 0, propertyName };
    }
    counts[rule.id].count++;
  };

  features.forEach(f =>
    f.properties.allValues?.forEach(
      val =>
        val.activeRule?.color === 'red' &&
        addCount(val.activeRule, val.dataProp.name)
    )
  );

  return Object.values(counts);
};

const GroupedMapTooltip: React.FC<IProps> = ({ state, features }) => {
  const classes = useStyles();
  const selectedFeature =
    state.selectedFeatureId &&
    features.find(f => f.id === state.selectedFeatureId);

  const details = useMemo(() => {
    if (selectedFeature && selectedFeature.type === 'group') {
      const {
        features,
        properties: { displayName },
      } = selectedFeature;
      const assetCount = features.length;

      const ruleCounts = getRuleCounts(features);

      const totalDeviations = sumBy(ruleCounts, x => x.count);

      return {
        displayName,
        assetCount,
        ruleCounts,
        totalDeviations,
      };
    }
    return null;
  }, [selectedFeature]);

  useEffect(() => {
    const { map, onMapMoveStart, onMapMoveEnd } = state;
    map.events.add('movestart', onMapMoveStart);
    map.events.add('moveend', onMapMoveEnd);
    window.addEventListener('scroll', onMapMoveEnd);

    return () => {
      map.events.remove('movestart', onMapMoveStart);
      map.events.remove('moveend', onMapMoveEnd);
      window.removeEventListener('scroll', onMapMoveEnd);
    };
  }, [state]);

  const { t } = useTranslation('dashboard');

  return (
    <Popper
      id="mapTooltip"
      anchorEl={state.tooltipElement}
      open={!!state.tooltipElement && !!details}
      placement="right"
      modifiers={[
        {
          name: 'arrow',
          enabled: true,
          options: {
            element: '[data-popper-arrow]',
          },
        },
      ]}
    >
      <div className={classes.root}>
        {details && (
          <>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <div className={classes.totalDeviations}>
                {t('azure_maps.tooltip.deviation_count', {
                  count: details.totalDeviations,
                })}
                {details.totalDeviations > 0 && (
                  <Box ml={2}>
                    <AtlasIcon type="Alert" size={18} color="error" />
                  </Box>
                )}
              </div>
              <AtlasIconButton onClick={state.closeTooltip}>
                <AtlasIcon type="Close" size={16} />
              </AtlasIconButton>
            </Box>
            <div className={classes.header}>
              <Box display="flex" alignItems="center">
                <AtlasIcon
                  type="Maps"
                  size={16}
                  inline
                  className={classes.headerIcon}
                />
                {details.displayName}
              </Box>
              <div className={classes.assetCount}>
                {t('azure_maps.tooltip.asset_count', {
                  count: details.assetCount,
                })}
              </div>
            </div>

            <Box marginTop={2}>
              {details.ruleCounts.map(rc => (
                <div key={rc.rule.id} className={classes.deviationRow}>
                  <StatusCircle defaultIcon="Alert" rule={rc.rule} />
                  <span className={classes.deviationAssetCount}>
                    {rc.count}
                  </span>
                  {rc.rule.label || rc.propertyName}
                </div>
              ))}
            </Box>
          </>
        )}
      </div>
      <div data-popper-arrow className={classes.arrow} />
    </Popper>
  );
};

export default observer(GroupedMapTooltip);
