import { formatDistanceToNow, isAfter } from 'date-fns';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TableCellRenderer } from 'react-table';
import { formatDate, ParsableDate, parseDate } from 'utils';

export const TimeAgoCellComponent: React.FC<{
  value: ParsableDate | null | undefined;
  adjustFromUtcToLocal?: boolean;
}> = ({ value, adjustFromUtcToLocal = false }) => {
  const { t } = useTranslation();

  const parsedValue = useMemo(() => {
    if (value === null || value === undefined || value === '') {
      return null;
    }

    const parsedDate = parseDate(value, adjustFromUtcToLocal);

    // The backend sometimes returns Date(0), 1970, instead of null - display them as "Never"
    if (isAfter(parsedDate, new Date(0)) === false) {
      return null;
    }

    return {
      text: formatDistanceToNow(parsedDate, { addSuffix: true }),
      title: formatDate(parsedDate),
    };
  }, [value, adjustFromUtcToLocal]);

  if (!parsedValue) {
    return <span className="text-muted">{t('never')}</span>;
  }

  return <span title={parsedValue.title}>{parsedValue.text}</span>;
};

export const DataAmountCell: TableCellRenderer = row => (
  <div className="text-right">
    {row.value}
    <span className="text-unit">GB</span>
  </div>
);

export const MoneyAmountCell: TableCellRenderer = row => (
  <div className="text-right">
    {row.value}
    <span className="text-unit">SEK</span>
  </div>
);

export const TimeIntervalCell: TableCellRenderer = row => (
  <div className="text-right">
    {row.value}
    <span className="text-unit">min</span>
  </div>
);

export const NumberCell: TableCellRenderer = row => (
  <div className="text-right">{row.value}</div>
);
