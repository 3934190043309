import { snackbar } from 'components/Snackbar';
import { IntAutomationRuleStatus } from 'generated';
import i18n from 'i18n';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { automationApi } from 'services/automation.service';
import { Dashboard } from 'store/domains/dashboard';
import { StoreBase } from 'store/storeBase';
import { $enum } from 'ts-enum-util';
import { chartColors } from 'views/Connectivity/Groups/GroupDetailsChart';
import { IDataProperty } from '../../dataPropTypes';
import {
  DataPropsGetter,
  IDataSourceResponse,
  IDataSourceStore,
} from '../../dataSourceTypes';
import { getDataProp } from '../../getDataProp';
import {
  IAutomationRuleStatusDataSourceSettings,
  IAutomationStatusCounts,
} from './AutomationRuleStatus.config';

export class AutomationDataSource
  extends StoreBase
  implements IDataSourceStore<IAutomationRuleStatusDataSourceSettings>
{
  @observable.ref settings: IAutomationRuleStatusDataSourceSettings;
  isInitialized = true;
  @observable lastReceivedData: Date | undefined;
  dashboard: Dashboard;
  constructor(
    dashboard: Dashboard,
    settings: IAutomationRuleStatusDataSourceSettings
  ) {
    super(dashboard.rootStore);
    makeObservable(this);

    this.dashboard = dashboard;
    this.settings = settings;
  }

  dataProperties: IDataProperty<IAutomationStatusCounts>[] = $enum(
    IntAutomationRuleStatus
  ).map(status => ({
    id: `${status}`,
    category: i18n.t('dashboard:data.properties'),
    _get: item => item[status] || 0,
    name: i18n.t(`automation:status.${status}`),
    type: 'number',
    getColor: () => this.getStatusColor(status),
    decimals: 0,
  }));

  groupProperties = [];
  customerId = this.rootStore.authStore.user?.customerId;

  async getData(): Promise<IDataSourceResponse> {
    const resp = await this.httpGet(
      automationApi.getAutomationRuleStatusCount,
      this.customerId
    );

    if (resp.status === 204) {
      return {
        type: 'noContent',
      };
    } else if (resp.status !== 200) {
      const errorMessage =
        resp.statusText ||
        resp.exceptionMessage ||
        'Error loading Automation rule data';
      snackbar(errorMessage, { variant: 'error' });

      return {
        type: 'error',
        message: errorMessage,
      };
    }

    const parsedData: IAutomationStatusCounts = {};
    if (Array.isArray(resp.data)) {
      resp.data.forEach(({ status, count }) => (parsedData[status] = count));
    } else {
      console.error('Invalid response data:', resp.data);
    }
    runInAction(() => {
      this.lastReceivedData = new Date();
    });

    return {
      type: 'success',
      data: {
        type: 'object',
        item: parsedData,
      },
    };
  }

  getDataProps<T>(propGetter: DataPropsGetter<T>): T {
    return propGetter(prop => getDataProp(this, prop));
  }

  @computed get depString(): string {
    const { manualRefreshTrigger } = this.dashboard.rootStore.dashboardStore;
    const depObject = {
      manualRefreshTrigger,
    };

    return JSON.stringify(depObject);
  }
  getStatusColor = (status: IntAutomationRuleStatus): string => {
    switch (status) {
      case IntAutomationRuleStatus.Disabled:
        return chartColors[3];
      case IntAutomationRuleStatus.Enabled:
        return chartColors[1];
      case IntAutomationRuleStatus.Error:
        return chartColors[4];
      default:
        return chartColors[0];
    }
  };
}
