import { Box } from '@mui/material';
import classNames from 'classnames';
import useDisableTabKey from 'components/LoadingOverlay/useDisableTabKey';
import LoadingSpinner from 'components/LoadingSpinner';
import React from 'react';
import { useStyles } from '../../LoadingOverlay/LoadingOverlay.styles';
interface IProps {
  loading: boolean;
  loadingText: string;
}

const TableLoadingOverlay: React.FC<IProps> = ({ loading, loadingText }) => {
  const spinnerRef = useDisableTabKey(loading);
  const classes = useStyles();
  if (!loading) {
    return null;
  }
  return (
    <>
      <Box
        ref={spinnerRef}
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="absolute"
        top="35%"
        bottom="0"
        left="45%"
        right="0"
        bgcolor="rgba(255, 255, 255, .4)"
        borderRadius="8px"
        width={100}
        height={100}
        zIndex={500}
        className={classNames(classes.overlay, {
          [classes.open]: loading,
        })}
      >
        <LoadingSpinner disableShrink />
      </Box>
    </>
  );
};

export default TableLoadingOverlay;
