import { Box, Typography } from '@mui/material';
import classNames from 'classnames';
import ContentCard from 'components/ContentCard';
import ErrorIcon from 'components/ErrorIcon';
import { ErrorMessage, useField } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, ComponentPropsGetterR, Filter } from 'react-table';
import 'react-table/react-table.css';
import { PostTableEndpoint, TableEndpoint } from 'shared/interfaces/api';
import {
  Table,
  TableData,
  TableLoadingOverlay,
  tableParamsToSearchDto,
} from '..';
import { IntSearchDto } from '../../../generated';
import { checkBox } from '../Columns/Form/checkBox';
import { radio } from '../Columns/Form/radio';
import {
  useContainerWidth,
  useFormTableSelect,
  useGetOrPost,
  usePostTableFetch,
  useTableColumnSettings,
  useTableFetch,
  useTableStateMemory,
} from '../hooks';
import TableTopContent from '../TableTopContent/TableTopContent';
import { useStyles } from './FormTable.styles';

export interface FormTableProps<TRequestBody, TRow> {
  name: string;
  valueKey: string & keyof TRow; // Property used to check if a value is selected
  columns: Column<TRow>[];
  apiEndpoint: TableEndpoint<TRow> | PostTableEndpoint<TRequestBody, TRow>;
  endpointType?: 'get' | 'post';
  label?: string;
  enableSearch?: boolean;
  searchPlaceholder?: string;
  multiple?: boolean;
  filters?: Filter[];
  frontendFilter?: (item: TRow) => boolean;
  tableTitle?: string;
  required?: boolean;
  helperText?: string;
  getPostBody?: (searchDto: IntSearchDto) => TRequestBody;
}

function FormTable<TRow, TRequestBody = IntSearchDto>(
  props: FormTableProps<TRequestBody, TRow>
) {
  const {
    name,
    valueKey,
    enableSearch,
    searchPlaceholder,
    multiple,
    apiEndpoint,
    endpointType = 'get',
    filters,
    tableTitle,
    helperText,
    required,
    frontendFilter,
    label,
    getPostBody,
  } = props;

  const { columns, handleSortEnd, toggleShowColumn } = useTableColumnSettings(
    props.columns,
    name
  );

  const [, meta, helpers] = useField(name);
  const { value } = meta;
  const { setValue } = helpers;

  const { onSearchChange, params, tableParamProps, onResetFilter } =
    useTableStateMemory({
      pageSize: frontendFilter ? 200 : 50,
      initialFilters: filters,
    });

  const getBody = useCallback(() => {
    if (endpointType === 'get') {
      return undefined;
    }

    const searchDtoModel = tableParamsToSearchDto(params);

    if (getPostBody) {
      return getPostBody(searchDtoModel);
    }

    return searchDtoModel as TRequestBody;
  }, [params, getPostBody, endpointType]);

  const [data, errors, isLoading] = useGetOrPost<TRequestBody, TRow>(
    apiEndpoint,
    endpointType,
    params,
    useTableFetch,
    usePostTableFetch,
    getBody
  );
  const frontendData = useMemo((): TableData<TRow> => {
    if (data) {
      const copyData = { ...data };
      if (frontendFilter) {
        const filteredRows: TRow[] = copyData.rows.filter(row =>
          frontendFilter(row)
        );
        copyData.rows = filteredRows;
        copyData.total = filteredRows.length;
      }
      return copyData;
    } else return { rows: [], total: 0 };
  }, [data, frontendFilter]);

  const totalRows = frontendData?.total || '-';
  const currentlyShown = frontendData?.rows?.length || '-';
  const { t } = useTranslation();
  const [, selectState] = useFormTableSelect<any>(name, valueKey);
  const [showFilters, setShowFilters] = useState(false);
  const [tableRef, width] = useContainerWidth();
  const pages =
    frontendData && frontendData.total
      ? Math.ceil(frontendData.total / params.pageSize)
      : 0;
  const selectedId = !multiple ? value && value[valueKey] : undefined;
  const classes = useStyles();

  const getTrProps = useCallback<ComponentPropsGetterR>(
    (_, row) => {
      if (!row || !row.original) {
        return {};
      }
      const isSelected: boolean = selectedId === row.original[valueKey];

      return {
        className: isSelected ? 'selected' : '',
        onClick: () => {
          if (!multiple) {
            setValue(row.original);
          }
        },
      };
    },
    [selectedId, valueKey, multiple, setValue]
  );

  // Automatically select the first row on initial load. Might need a prop to disable this at some point.
  useEffect(() => {
    if (
      !multiple &&
      frontendData &&
      frontendData.rows &&
      frontendData.rows.length > 0 &&
      !value
    ) {
      setValue(frontendData.rows[0]);
    }
  }, [name, multiple, value, setValue, frontendData]);

  const columnsWithInput = useMemo(() => {
    if (multiple) {
      return [checkBox(selectState, `checkBox-${name}`), ...columns];
    } else {
      return [radio<TRow>(name, valueKey), ...columns];
    }
  }, [columns, name, valueKey, multiple, selectState]);

  if (errors) {
    return <ErrorIcon />;
  }

  const getTbodyProps = () => ({ 'data-cy': name });
  const dataCyTag = `FormTable-${name}`;

  return (
    <>
      {label && <Typography variant="h5">{label}</Typography>}
      <Box ref={tableRef}>
        <ContentCard
          className={classNames({
            [classes.tableError]: Boolean(meta.touched && meta.error),
          })}
          dataCy={dataCyTag}
        >
          <TableTopContent
            columns={columns}
            handleSortEnd={handleSortEnd}
            tableTitle={tableTitle}
            toggleShowColumn={toggleShowColumn}
            totalRows={totalRows}
            currentlyShown={currentlyShown}
            enableSearch={enableSearch}
            searchPlaceholder={searchPlaceholder}
            onSearchChange={onSearchChange}
            params={params}
            onFilterChange={() => setShowFilters(!showFilters)}
            disableTabKey={isLoading}
            required={required}
            helperText={helperText}
            showFilters={showFilters}
            handleResetFilter={() => {
              if (showFilters && params.filters?.length) {
                onResetFilter(filters);
              }
            }}
            parentTableWidth={width}
          />
          <Table
            filterable
            manual
            columns={columnsWithInput}
            filtered={params.filters ?? ([] as Filter[])}
            noDataText={!isLoading ? t('label.no_rows_found') : ''}
            className={classNames(classes.table, 'resource-table')}
            style={{ height: '100%' }}
            getTrProps={getTrProps}
            getTbodyProps={getTbodyProps}
            getPaginationProps={() => ({
              renderPageJump: (pageJumpProps: any) => (
                <div className="-pageJump">
                  <input
                    aria-label={pageJumpProps.pageJumpText}
                    type={pageJumpProps.inputType}
                    onChange={pageJumpProps.onChange}
                    value={pageJumpProps.value}
                    onBlur={pageJumpProps.onBlur}
                    onKeyDown={e => {
                      if (e.code === 'Enter') {
                        e.preventDefault();
                      }
                      return pageJumpProps.onKeyPress(e);
                    }}
                  />
                </div>
              ),
            })}
            getTheadFilterProps={(_state, _rowInfo, _column, _instance) => {
              const base = {
                transition: 'height .15s linear',
                minHeight: 0,
              };
              const close = { ...base, height: 0, display: 'none' };
              const open = { ...base, height: '38px', display: 'flex' };

              return {
                style: showFilters ? open : close,
              };
            }}
            LoadingComponent={TableLoadingOverlay}
            data={frontendData ? frontendData.rows : undefined}
            loading={isLoading}
            pages={pages}
            page={params.page}
            minRows={10}
            defaultPageSize={50}
            showPagination={!frontendFilter}
            {...tableParamProps}
          />
        </ContentCard>
      </Box>
      <ErrorMessage
        name={name}
        component="div"
        className={classes.textDanger}
      />
    </>
  );
}

export default FormTable;
