import { SnackbarProvider as SnackBarProvider } from 'notistack';
import React from 'react';

const SnackbarProvider: React.FC = ({ children }) => {
  return (
    <SnackBarProvider
      maxSnack={2}
      autoHideDuration={5000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      {children}
    </SnackBarProvider>
  );
};

export default SnackbarProvider;
