import * as azureMaps from 'azure-maps-control';
import { IntDashboardDataStatusRuleDto } from 'generated';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { AtlasIconType } from 'shared/types/atlas-icon';
import { IDataProperty } from 'views/Dashboard/DataSources/dataPropTypes';
import { PopupGetter } from '../AzureMapsComponent/getPopupContent';
import { useMapPopup } from '../AzureMapsComponent/hooks';
import { MapSpriteType } from '../AzureMapsComponent/hooks/useCustomSprites';
import { IMapPosition } from './ImageWidget';
import { mapLayerIds } from './mapLayerIds';

interface IProps {
  map: azureMaps.Map;
  features: IMapFeature[];
  getPopup?: PopupGetter;
  showDisplayName?: boolean;
  onFeatureClick?: (id: string) => void;
}

export type IMapFeatureGrouped<TData extends object = any> =
  | IMapFeature<TData>
  | IMapFeatureGroup<TData>;

export interface IMapFeature<TData extends object = any> {
  type?: 'single';
  id: string;
  position: IMapPosition;
  properties: IMapFeatureProperties<TData>;
}

export interface IMapFeatureGroup<TData extends object = any> {
  type: 'group';
  id: string;
  position: IMapPosition;
  features: IMapFeature<TData>[];
  properties: IMapFeatureProperties<TData>;
}

export interface IStatusColorCounts {
  red?: number;
  yellow?: number;
  green?: number;
}

export interface IMapFeatureProperties<TData extends object = any>
  extends IStatusColorCounts {
  id?: string; // Awkward to need this here, but it's way easier than relying on azuremaps IDs
  displayName: string;
  icon: MapSpriteType;
  iconAnchor: 'bottom' | 'center'; // Remove eventually
  data: TData;
  statusColor?: string | null; // This could be refined
  statusRule?: IntDashboardDataStatusRuleDto;
  defaultIconType?: AtlasIconType;
  isSelected?: boolean;
  allValues?: IPropertyRuleValue[]; // Doesn't exist on groups
  group?: string;
}

export interface IPropertyRuleValue {
  value: number | null;
  dataProp: IDataProperty;
  activeRule: IntDashboardDataStatusRuleDto | null;
}

const ImagePinLayer: React.FC<IProps> = ({
  map,
  features,
  onFeatureClick,
  getPopup,
  showDisplayName,
}) => {
  const [source] = useState(() => new azureMaps.source.DataSource());
  const [layer] = useState(
    () => new azureMaps.layer.SymbolLayer(source, mapLayerIds.features)
  );

  useEffect(() => {
    const options: azureMaps.SymbolLayerOptions = {
      iconOptions: {
        image: ['get', 'icon'],
        anchor: ['get', 'iconAnchor'],
        allowOverlap: true,
      },
    };

    if (showDisplayName) {
      options.textOptions = {
        textField: ['get', 'displayName'],
        anchor: 'top',
        size: 14,
        allowOverlap: true,
        color: '#000',
        offset: [0, 1.4],
      };
    }

    layer.setOptions(options);
  }, [layer, showDisplayName]);

  useEffect(() => {
    map.sources.add(source);
    map.layers.add(layer);

    const handleClick = (e: azureMaps.MapMouseEvent) => {
      if (e.shapes?.length) {
        const shape = e.shapes[0];
        if (shape instanceof azureMaps.Shape) {
          if (onFeatureClick) {
            onFeatureClick(`${shape.getId()}`);
          }
        }
      }
    };

    map.events.add('click', layer, handleClick);

    return () => {
      try {
        map.events.remove('click', handleClick as any);

        map.layers.remove(layer);
        map.sources.remove(source);
      } catch (error) {}
    };
  }, [map, source, layer, onFeatureClick]);

  useMapPopup(map, [layer], getPopup);

  // Add/update shapes from features
  features.forEach(point => {
    const existingFeature = source.getShapeById(point.id);

    if (!existingFeature) {
      const feature = new azureMaps.data.Feature(
        new azureMaps.data.Point(point.position),
        point.properties,
        point.id
      );
      source.add(feature);
    } else {
      existingFeature.setProperties(point.properties);
      existingFeature.setCoordinates(point.position);
    }
  });

  // Delete removed features
  source.getShapes().forEach(shape => {
    const shapeId = shape.getId();
    if (!features.find(feature => feature.id === shapeId)) {
      source.remove(shape);
    }
  });

  return null;
};

export default observer(ImagePinLayer);
