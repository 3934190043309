import { Collapse } from '@mui/material';
import AtlasIcon from 'components/AtlasIcon';
import { IntMenuItemDto } from 'generated';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { INavigationMenuState } from '../useNavMenuState';
import MenuItem from './MenuItem';
import MenuItemButton from './MenuItemButton';

interface IProps {
  item: IntMenuItemDto;
  navMenuState: INavigationMenuState;
}

const CollapseParent: React.FC<IProps> = ({ item, navMenuState }) => {
  const childIsActive = item.children.some(
    child => child === navMenuState.activeMenuItem
  );

  const [isOpen, setIsOpen] = useState(childIsActive);

  return (
    <>
      <MenuItem
        item={item}
        navMenuState={navMenuState}
        variant="collapseParent"
        endContent={
          <MenuItemButton
            onClick={e => {
              e.preventDefault();
              setIsOpen(!isOpen);
            }}
          >
            <AtlasIcon type={isOpen ? 'ChevronUp' : 'ChevronDown'} />
          </MenuItemButton>
        }
      />

      <Collapse in={isOpen}>
        <div>
          {item.children.map(childItem => (
            <MenuItem
              key={childItem.menuItemId}
              item={childItem}
              navMenuState={navMenuState}
              inset
            />
          ))}
        </div>
      </Collapse>
    </>
  );
};

export default observer(CollapseParent);
