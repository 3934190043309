import { DashboardComponentType } from 'generated';
import {
  IBaseComponentSettings,
  IComponentTypeConfig,
} from 'views/Dashboard/dashboardTypes';
import { IDataSourceSettings } from 'views/Dashboard/DataSources/dataSourceTypes';
import PreviewStep from '../PreviewStep';
import ColumnChartWidget from './ColumnChartContainer';
import GeneralSettingsStep from './SettingsSteps/GeneralSettingsStep';

export interface IColumnChartSettings extends IBaseComponentSettings {
  title: string;
  dataSource: IDataSourceSettings;

  nameProp: string;
  xAxis: {
    prop: string;
  };
  yAxis: {
    prop: string;
    label?: string;
    min?: number;
    max?: number;
  };
}

export const columnChartComponentConfig: IComponentTypeConfig<
  DashboardComponentType.ColumnChart,
  IColumnChartSettings
> = {
  widgetType: DashboardComponentType.ColumnChart,
  icon: 'Statistics',
  Component: ColumnChartWidget,
  settingsSteps: [
    {
      title: 'settings',
      Component: GeneralSettingsStep,
    },
    {
      title: 'final_preview',
      Component: PreviewStep,
    },
  ],
  getDefaultSettings: ({ dataSource }) => {
    // Attempt to find sensible default properties
    const nameProp =
      dataSource.groupProperties?.find(dp => dp.isDefaultDisplayName) ||
      dataSource.dataProperties.find(dp => dp.isDefaultDisplayName);

    const xProp =
      dataSource.dataProperties.find(dp => dp.id === 'registerDate') ??
      dataSource.dataProperties.find(dp => dp.type === 'dateTime');
    const yProp = dataSource.dataProperties.find(dp => dp.type === 'number');

    return {
      position: { x: 0, y: 0, w: 6, h: 6 },
      title: '',
      dataSource: dataSource.settings,
      nameProp: nameProp?.id || '',
      xAxis: {
        prop: xProp?.id || '', // consider replacing this with a datasource property
      },
      yAxis: {
        prop: yProp?.id || '',
        // plotBands: [{ color: 'red' }, { color: 'yellow' }, { color: 'green' }],
      },
    };
  },
};
