import { TextField } from '@mui/material';
import { format, isValid, parse } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const inputDateFormat = 'yyyy-MM-dd';

function parseDateIfValid(value: string): Date | undefined {
  const hasCorrectFormat = value.match(/[0-9]{4}-[0-9]{2}-[0-9]{2}/); // Could this be more forgiving? Maybe, but fine for now
  const parsedValue = parse(value, inputDateFormat, new Date());
  return hasCorrectFormat && isValid(parsedValue) ? parsedValue : undefined;
}

interface IProps {
  value: Date;
  onChange: (value: Date | null) => void;
  label: string;
  isDateRangeValid: boolean;
  errorMessage: string;
}

const DateTextInput: React.FC<IProps> = ({
  value,
  label,
  onChange,
  isDateRangeValid,
  errorMessage,
}) => {
  const { t } = useTranslation();
  const [stringValue, setStringValue] = useState(
    format(value, inputDateFormat)
  );

  useEffect(() => {
    setStringValue(format(value, inputDateFormat));
  }, [value]);

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const inputString = e.target.value.replace(/[^\d-]/g, '');
    setStringValue(inputString);

    const parsedValue = parseDateIfValid(inputString);
    onChange(parsedValue || null);
  };

  const isInputValid = !!(parseDateIfValid(stringValue) && isDateRangeValid);

  const helperText =
    !isInputValid &&
    (stringValue.match(/[0-9]{4}-[0-9]{2}-[0-9]{2}/)
      ? errorMessage
      : t('validation:invalid.format'));

  return (
    <TextField
      variant="outlined"
      label={label}
      fullWidth
      value={stringValue}
      onChange={handleInputChange}
      error={!isInputValid}
      helperText={helperText}
    />
  );
};

export default DateTextInput;
