import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  popoverContent: {
    width: '16.875rem',
    marginTop: '1.125rem',
    backgroundColor: theme.palette.background.paper,
  },
  allNotificationsText: {
    fontSize: '0.75rem',
    padding: theme.spacing(1),
  },
  linkUnderline: {
    textDecoration: 'none',
  },
  langSwitch: {
    marginLeft: theme.spacing(1),
  },
  iconWidth: {
    width: 51,
  },
  iconButton: {
    padding: 8,
  },
}));
