import { FontFace } from 'csstype';
import { fontSettings } from '../fontSettings';
import { IUiConfiguration } from '../IUiConfiguration';
import { IExtendedTypographyOptions, pxToRem } from '../makeAtlasTheme';

export const typographyConfig = (uiConfig: IUiConfiguration) => {
  const bodyFont = fontSettings[uiConfig.typography.bodyFont] || {
    fontStack: 'sans-serif',
  };
  const headerFont = fontSettings[uiConfig.typography.headerFont] || {
    fontStack: 'sans-serif',
  };

  const headerFontStack = headerFont.fontStack;
  const bodyFontStack = bodyFont.fontStack;
  const bodyBoldFontStack = fontSettings.TeliaSansBold.fontStack;

  const fontFaces: FontFace[] = [];

  if (headerFont.fontFace) {
    fontFaces.push(headerFont.fontFace);
  }
  if (bodyFont.fontFace && bodyFont !== headerFont) {
    fontFaces.push(bodyFont.fontFace);
  }

  const styleSheets: string[] = [];
  if (headerFont.styleSheet) {
    styleSheets.push(headerFont.styleSheet);
  }
  if (bodyFont.styleSheet && bodyFont !== headerFont) {
    styleSheets.push(bodyFont.styleSheet);
  }
  const headerTextTransform =
    uiConfig.typography.headerFont === 'Pebble' ? 'uppercase' : undefined;

  const assets = {
    fontFaces,
    styleSheets,
  };
  const typography: IExtendedTypographyOptions = {
    htmlFontSize: 16,
    headerFontFamily: headerFontStack,
    headerTextTransform: 'uppercase',
    h1: {
      fontFamily: headerFontStack,
      fontSize: pxToRem(60),
      lineHeight: pxToRem(60),
      textTransform: headerTextTransform,
    },
    h2: {
      fontFamily: headerFontStack,
      fontSize: pxToRem(48),
      lineHeight: pxToRem(48),
      textTransform: headerTextTransform,
    },
    h3: {
      fontFamily: headerFontStack,
      fontSize: pxToRem(36),
      lineHeight: pxToRem(42),
      textTransform: headerTextTransform,
    },
    h4: {
      fontFamily: bodyBoldFontStack,
      fontSize: pxToRem(30),
      lineHeight: pxToRem(34),
      fontWeight: 'bold',
    },
    h5: {
      fontFamily: bodyBoldFontStack,
      fontSize: pxToRem(24),
      lineHeight: pxToRem(28),
      fontWeight: 'bold',
    },
    h6: {
      fontFamily: bodyBoldFontStack,
      fontSize: pxToRem(18),
      lineHeight: pxToRem(22),
      fontWeight: 'bold',
    },
    //Preamble Large
    subtitle1: {
      fontSize: pxToRem(26),
      lineHeight: pxToRem(32),
    },
    //Preamble
    subtitle2: {
      fontSize: pxToRem(22),
      lineHeight: pxToRem(28),
    },
    //Using this as body-Medium
    caption: {
      fontFamily: bodyBoldFontStack,
      fontWeight: 'bold',
      fontSize: pxToRem(14),
      lineHeight: pxToRem(20),
    },
    //Body normal - Default by MUI in Spans
    body1: {
      fontSize: pxToRem(16),
      lineHeight: pxToRem(22),
    },
    //Body Small - Default by Mui in Links
    body2: {
      fontSize: pxToRem(12),
      lineHeight: pxToRem(18),
    },
    bodyLarge: {
      fontSize: pxToRem(20),
      lineHeight: pxToRem(26),
    },
    fontFamily: bodyFontStack,
  };

  return { typography, assets, bodyFontStack };
};
