import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    inputRoot: {
      minHeight: 30,
      maxHeight: 30,
    },
    textFieldRoot: {
      padding: '0 !important',
      overflowY: 'clip',
    },
    tagRoot: {
      margin: 4,
    },
    tagLabel: {
      maxWidth: 45,
    },
  })
);
