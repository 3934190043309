import { Box } from '@mui/material';
import AtlasIcon from 'components/AtlasIcon';
import { formatDistanceToNow, parseISO } from 'date-fns';
import React from 'react';
import { AtlasNotification, NotificationType } from '.';
import DropdownLink from '../DropdownLink/DropdownLink';
import { useStyles } from './NotificationPreview.styles';

function getNotificationIconType(type: NotificationType) {
  switch (type) {
    case 'maintenance':
      return 'Repair';

    case 'billing':
      return 'Money';

    case 'status':
      return 'BloodPressure';

    default:
      return 'Settings';
  }
}

const NotificationPreview: React.FC<AtlasNotification> = ({
  type,
  message,
  id,
  isRead,
  time,
}) => {
  const classes = useStyles();
  const iconType = getNotificationIconType(type);
  const timeAgo = formatDistanceToNow(parseISO(time), { addSuffix: true });

  return (
    <DropdownLink to={`/notifications/${id}`}>
      <Box padding={1}>
        <AtlasIcon type={iconType} size={32} />
      </Box>
      <Box width={1} pl={1} fontSize={12}>
        {message}
        <Box className={classes.timeAgo}>{timeAgo}</Box>
      </Box>
      {!isRead && <div className={classes.unreadIndicator}>&#11044;</div>}
    </DropdownLink>
  );
};

export default NotificationPreview;
