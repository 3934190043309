import { CSSInterpolation, lighten } from '@mui/material';
import { IUiConfiguration } from '../IUiConfiguration';
import { pxToRem } from '../makeAtlasTheme';

export interface IButton {
  root: CSSInterpolation;
  text: CSSInterpolation;
  textPrimary: CSSInterpolation;
  contained: CSSInterpolation;
  outlined: CSSInterpolation;
  containedPrimary: CSSInterpolation;
  outlinedPrimary: CSSInterpolation;
  sizeSmall: CSSInterpolation;
  outlinedSizeSmall: CSSInterpolation;
  containedSizeSmall: CSSInterpolation;
  textSizeSmall: CSSInterpolation;
}

const button = {
  padding: {
    noBorder: {
      y: 12,
      x: 16,
    },
    noBorderSmall: {
      y: 3,
      x: 12,
    },
  },
  borderWidth: 1,
};

export const buttonConfig = (
  uiConfig: IUiConfiguration,
  primaryColor: { dark?: string; darker: string },
  bodyFontStack: string
) => {
  const outlineSettings = {
    line: `2px solid ${primaryColor.darker}`,
    offset: '3px',
  };

  const buttonOptions: IButton = {
    root: {
      borderRadius: 9999,
      fontFamily: bodyFontStack,
      fontWeight: 500,
      textTransform: 'none',
      fontSize: pxToRem(16),
      whiteSpace: 'nowrap',
      border: `2px solid ${uiConfig.colors.primary}`,
      maxHeight: pxToRem(40),
    },
    text: {
      padding: `${button.padding.noBorder.y}px ${button.padding.noBorder.x}px`,
      border: 'none',
    },
    textPrimary: {
      color: uiConfig.colors.primary,
      '&:hover': {
        backgroundColor: 'transparent',
        color: primaryColor.dark,
      },
      '&:focus': {
        color: primaryColor.darker,
      },
      '&:focus-visible': {
        backgroundColor: lighten(uiConfig.colors.primary, 0.93),
        color: uiConfig.colors.primary,
        outline: outlineSettings.line,
        outlineOffset: outlineSettings.offset,
      },
      '&:disabled': {
        color: '#9C9C9C',
      },
    },
    outlined: {
      padding: `${button.padding.noBorder.y - button.borderWidth}px ${
        button.padding.noBorder.x - button.borderWidth
      }px`,
    },
    outlinedPrimary: {
      backgroundColor: '#FFF',
      '&:hover, &:focus': {
        color: '#FFF',
        border: `2px solid ${uiConfig.colors.primary}`,
      },
      '&:hover': {
        backgroundColor: uiConfig.colors.primary,
        borderColor: uiConfig.colors.primary,
      },
      '&:focus': {
        backgroundColor: primaryColor.darker,
        borderColor: primaryColor.darker,
      },
      '&:focus-visible': {
        backgroundColor: '#FFF',
        borderColor: uiConfig.colors.primary,
        color: uiConfig.colors.primary,
        outline: outlineSettings.line,
        outlineOffset: outlineSettings.offset,
      },
      '&:disabled': {
        color: '#7A7A7A',
        borderColor: '#9C9C9C',
        backgroundColor: '#FFF',
      },
    },
    contained: {
      padding: `${button.padding.noBorder.y}px ${button.padding.noBorder.x}px`,
      borderWidth: 1,
    },
    containedPrimary: {
      color: '#FFF',
      boxShadow: 'none !important',
      '&:hover': {
        backgroundColor: primaryColor.dark,
        borderColor: primaryColor.dark,
      },
      '&:focus': {
        backgroundColor: primaryColor.darker,
        borderColor: primaryColor.darker,
      },
      '&:focus-visible': {
        backgroundColor: uiConfig.colors.primary,
        borderColor: uiConfig.colors.primary,
        outline: outlineSettings.line,
        outlineOffset: outlineSettings.offset,
      },
      '&:disabled': {
        backgroundColor: '#BDBDBD',
        borderColor: '#BDBDBD',
        color: '#383838',
      },
    },
    textSizeSmall: {
      padding: `${button.padding.noBorderSmall.y}px ${button.padding.noBorderSmall.x}px`,
    },
    outlinedSizeSmall: {
      padding: `${button.padding.noBorderSmall.y - button.borderWidth}px ${
        button.padding.noBorderSmall.x - button.borderWidth
      }px`,
    },
    containedSizeSmall: {
      padding: `${button.padding.noBorderSmall.y}px ${button.padding.noBorderSmall.x}px`,
    },
    sizeSmall: {
      fontSize: '.875rem',
    },
  };

  return buttonOptions;
};
