import {
  ServiceDataSpecificationAttribute,
  ServiceDataSpecificationColumnType,
} from 'generated';
import i18n from 'i18n';
import { isNil } from 'lodash';
import { formatDate } from 'utils';
import { tryParseDate } from 'utils/parsing';
import { IDataPropCategory, IDataProperty } from '../dataPropTypes';

export const category: IDataPropCategory = {
  displayName: i18n.t('dashboard:asset_properties'),
};

function assetAttributeProperties(): Record<
  Exclude<
    ServiceDataSpecificationAttribute,
    ServiceDataSpecificationAttribute.AssetDetailsDynamicAttributes
  >,
  IDataProperty
> {
  return {
    [ServiceDataSpecificationAttribute.AssetDetailsAssetId]: {
      id: 'AssetDetailsAssetId',
      _get: asset => asset.AssetDetailsAssetId,
      name: i18n.t('asset:property.asset_id'),
      type: 'string',
      category,
      column: {
        columnType: ServiceDataSpecificationColumnType.AssetMetaData,
        value: 'AssetDetailsAssetId',
      },
    },
    [ServiceDataSpecificationAttribute.AssetDetailsAssetIdentifier]: {
      id: 'AssetDetailsAssetIdentifier',
      _get: asset => asset.AssetDetailsAssetIdentifier,
      name: i18n.t('asset:property.identifier'),
      type: 'string',
      category,
      column: {
        columnType: ServiceDataSpecificationColumnType.AssetMetaData,
        value: 'AssetDetailsAssetIdentifier',
      },
    },
    [ServiceDataSpecificationAttribute.AssetDetailsAssetName]: {
      id: 'AssetDetailsAssetName',
      _get: asset => asset.AssetDetailsAssetName,
      name: i18n.t('asset:property.asset_name'),
      type: 'string',
      category,
      isDefaultDisplayName: true,
      column: {
        columnType: ServiceDataSpecificationColumnType.AssetMetaData,
        value: 'AssetDetailsAssetName',
      },
    },
    [ServiceDataSpecificationAttribute.AssetDetailsCountryName]: {
      id: 'AssetDetailsCountryName',
      _get: asset => asset.AssetDetailsCountryName,
      name: i18n.t('asset:property.country'),
      type: 'string',
      category,
      column: {
        columnType: ServiceDataSpecificationColumnType.AssetMetaData,
        value: 'AssetDetailsCountryName',
      },
    },
    [ServiceDataSpecificationAttribute.AssetDetailsCustomerName]: {
      id: 'AssetDetailsCustomerName',
      _get: asset => asset.AssetDetailsCustomerName,
      name: i18n.t('asset:property.customer'),
      type: 'string',
      category,
      column: {
        columnType: ServiceDataSpecificationColumnType.AssetMetaData,
        value: 'AssetDetailsCustomerName',
      },
    },
    [ServiceDataSpecificationAttribute.AssetDetailsDealerName]: {
      id: 'AssetDetailsDealerName',
      _get: asset => asset.AssetDetailsDealerName,
      name: i18n.t('asset:property.dealer'),
      type: 'string',
      category,
      column: {
        columnType: ServiceDataSpecificationColumnType.AssetMetaData,
        value: 'AssetDetailsDealerName',
      },
    },
    [ServiceDataSpecificationAttribute.AssetDetailsMake]: {
      id: 'AssetDetailsMake',
      _get: asset => asset.AssetDetailsMake,
      name: i18n.t('asset:property.make'),
      type: 'string',
      category,
      column: {
        columnType: ServiceDataSpecificationColumnType.AssetMetaData,
        value: 'AssetDetailsMake',
      },
    },
    [ServiceDataSpecificationAttribute.AssetDetailsModel]: {
      id: 'AssetDetailsModel',
      _get: asset => asset.AssetDetailsModel,
      name: i18n.t('asset:property.model'),
      type: 'string',
      category,
      column: {
        columnType: ServiceDataSpecificationColumnType.AssetMetaData,
        value: 'AssetDetailsModel',
      },
    },
    [ServiceDataSpecificationAttribute.AssetDetailsType]: {
      id: 'AssetDetailsType',
      _get: asset => asset.AssetDetailsType,
      name: i18n.t('asset:property.type'),
      type: 'string',
      category,
      column: {
        columnType: ServiceDataSpecificationColumnType.AssetMetaData,
        value: 'AssetDetailsType',
      },
    },
    [ServiceDataSpecificationAttribute.AssetDetailsServiceMode]: {
      id: 'AssetDetailsServiceMode',
      _get: asset => asset.AssetDetailsServiceMode === 'True',
      name: i18n.t('asset:property.serviceMode'),
      type: 'boolean',
      category,
      column: {
        columnType: ServiceDataSpecificationColumnType.AssetMetaData,
        value: 'AssetDetailsServiceMode',
      },
    },
    [ServiceDataSpecificationAttribute.AssetDetailsTerminalPowerSource]: {
      id: 'AssetDetailsTerminalPowerSource',
      _get: asset => asset.AssetDetailsTerminalPowerSource,
      name: i18n.t('asset:property.terminal_power_source'),
      type: 'string',
      category,
      column: {
        columnType: ServiceDataSpecificationColumnType.AssetMetaData,
        value: 'AssetDetailsTerminalPowerSource',
      },
    },
    [ServiceDataSpecificationAttribute.AssetDetailsTerminalConnected]: {
      id: 'AssetDetailsTerminalConnected',
      _get: asset => {
        return asset.AssetDetailsTerminalConnected === 'True'
          ? true
          : asset.AssetDetailsTerminalConnected === 'False'
            ? false
            : null;
      },
      name: i18n.t('asset:property.connected'),
      type: 'boolean',
      category,
      column: {
        columnType: ServiceDataSpecificationColumnType.AssetMetaData,
        value: 'AssetDetailsTerminalConnected',
      },
    },
    [ServiceDataSpecificationAttribute.AssetDetailsInstallationDate]: {
      id: 'AssetDetailsInstallationDate',
      _get: asset => {
        const installationDate = tryParseDate(
          asset.AssetDetailsInstallationDate,
          true
        );
        return !isNil(installationDate)
          ? formatDate(installationDate, { time: true })
          : asset.AssetDetailsInstallationDate;
      },
      name: i18n.t('asset:property.installationDate'),
      type: 'dateTime',
      category,
      column: {
        columnType: ServiceDataSpecificationColumnType.AssetMetaData,
        value: 'AssetDetailsInstallationDate',
      },
    },
    [ServiceDataSpecificationAttribute.AssetDetailsAddress]: {
      id: 'AssetDetailsAddress',
      _get: asset => asset.AssetDetailsAddress,
      name: i18n.t('asset:property.address'),
      type: 'string',
      category,
      column: {
        columnType: ServiceDataSpecificationColumnType.AssetMetaData,
        value: 'AssetDetailsAddress',
      },
    },
    [ServiceDataSpecificationAttribute.AssetDetailsTerminalBatteryLevel]: {
      id: 'AssetDetailsTerminalBatteryLevel',
      _get: asset => asset.AssetDetailsTerminalBatteryLevel,
      name: i18n.t('asset:property.battery_level'),
      type: 'string',
      category,
      column: {
        columnType: ServiceDataSpecificationColumnType.AssetMetaData,
        value: 'AssetDetailsTerminalBatteryLevel',
      },
    },
  };
}

// To match old static attributes with new dynamic ones, normalize the name or ID to get a string common to both
export function getDataPropSearchName(labelOrId: string): string {
  return labelOrId
    .replace('AssetDetails', '')
    .replaceAll(' ', '')
    .toLowerCase();
}

// Remove when static attributes are removed and fully replaced by dynamic ones
function doesNotHaveDynamicReplacement(
  staticAttribute: IDataProperty,
  dynamicAttributes: IDataProperty[]
) {
  return !dynamicAttributes.find(
    dynamicAttribute =>
      getDataPropSearchName(dynamicAttribute.name) ===
      getDataPropSearchName(staticAttribute.name)
  );
}

export function getAssetDataProperties(
  dynamicAttributes: IDataProperty[] = []
): IDataProperty[] {
  return Object.values(assetAttributeProperties()).filter(staticProp =>
    doesNotHaveDynamicReplacement(staticProp, dynamicAttributes)
  );
}
