import i18n from 'i18next';
import { Column } from 'react-table';
import { AtlasIconType } from 'shared/types/atlas-icon';
import { GroupStatus } from 'shared/types/group-status';
import { StatusVariant } from 'shared/types/status-variant';
import { CustomCell } from '../Helpers/CustomCell';
import { getOptionFilter } from '../Helpers/getOptionFilter';

interface CustomCellProps {
  iconType?: AtlasIconType;
  title?: string;
  text?: string;
  variant?: StatusVariant;
  index?: number;
}

export function groupStatus<T>(options: {
  id: string;
  accessor: (row: T) => GroupStatus;
  header?: string;
  defaultShow?: boolean;
}): Column<T> {
  const groupStatusCellProps: Record<GroupStatus, CustomCellProps> = {
    success: {
      iconType: 'Status',
      variant: 'success',
      title: '',
    },
    failed: {
      iconType: 'Status',
      variant: 'error',
      title: i18n.t('group:title.status.failed'),
    },
    pending: {
      iconType: 'Alert',
      variant: 'default',
      title: i18n.t('group:title.status.pending'),
    },
  };
  const { id, header = 'Status', accessor, defaultShow: show } = options;

  return {
    id,
    Header: header,
    accessor,
    resizable: false,
    maxWidth: 80,
    show,
    Filter: getOptionFilter([
      { label: i18n.t('group:label.all'), value: '' },
      { label: i18n.t('group:label.success'), value: 'success' },
      { label: i18n.t('group:label.failed'), value: 'failed' },
      { label: i18n.t('group:label.pending'), value: 'pending' },
    ]),
    Cell: row => {
      const cellProps = groupStatusCellProps[row.value as GroupStatus] || {};
      return <CustomCell {...cellProps} />;
    },
  };
}
