import { Box } from '@mui/material';
import { LatestServiceDataContextType } from 'generated';
import i18n from 'i18next';
import { Column } from 'react-table';
import { FilterOption } from '../Helpers/filterOption';
import { getOptionFilter } from '../Helpers/getOptionFilter';

export function latestServiceDataContextType<T>(options: {
  id: string;
  accessor: (row: T) => LatestServiceDataContextType;
  header?: string;
  defaultShow?: boolean;
  filterable?: boolean;
  sortable?: boolean;
}): Column<T> {
  const {
    id,
    header = 'Connected',
    accessor,
    defaultShow: show,
    filterable,
    sortable,
  } = options;

  const filterOptions: FilterOption[] = [
    { label: i18n.t('common:all'), value: '' },
    { label: 'LatestPoint', value: LatestServiceDataContextType[0] },
    {
      label: 'LatestPointByRegisterDate',
      value: LatestServiceDataContextType[1],
    },
    {
      label: 'LatestCollection',
      value: LatestServiceDataContextType[2],
    },
  ];

  return {
    id,
    Header: header,
    accessor,
    resizable: false,
    maxWidth: 130,
    filterable,
    sortable,
    show,
    Cell: row => {
      return <Box>{LatestServiceDataContextType[row.value]}</Box>;
    },
    Filter: getOptionFilter(filterOptions),
  };
}
