import { FormHelperText, TextField } from '@mui/material';
import { DateTimePicker as MUIDateTimePicker } from '@mui/x-date-pickers';
import { format, isValid } from 'date-fns';
import { useField, useFormikContext } from 'formik';
import { FC } from 'react';
import { useStyles } from './DateTimePicker.styles';

interface IProps {
  name: string;
  label: string;
  disabled?: boolean;
  maxDateTime?: Date;
  fullWidth?: boolean;
  dateFormat?: string;
  required?: boolean;
}

const DateTimePicker: FC<IProps> = ({
  name,
  label,
  disabled = false,
  maxDateTime,
  fullWidth = false,
  dateFormat = "yyyy-MM-dd'T'HH:mm:SS'Z'",
  required = false,
}) => {
  const classes = useStyles();
  const [field, meta] = useField<string>(name);
  const { setFieldValue } = useFormikContext<any>();

  return (
    <>
      <MUIDateTimePicker
        renderInput={props => (
          <TextField
            {...props}
            fullWidth={fullWidth}
            required={required}
            error={meta.touched && !!meta.error}
          />
        )}
        label={label}
        value={field.value}
        inputFormat={dateFormat}
        onChange={newValue => {
          setFieldValue(
            name,
            newValue && isValid(newValue)
              ? format(newValue, dateFormat)
              : newValue
          );
        }}
        maxDateTime={maxDateTime}
        disabled={disabled}
      />
      {meta.error && meta.touched && (
        <FormHelperText className={classes.error}>{meta.error}</FormHelperText>
      )}
    </>
  );
};

export default DateTimePicker;
