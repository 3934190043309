import { CSSProperties } from '@mui/styles';
import { fontSettings } from '../fontSettings';

export interface ITable {
  oddRowColor: string;
  evenRowColor: string;
  selectedRowBackground: string;
  expandArrowColor: string;
  th: CSSProperties;
  td: CSSProperties;
}

export const tableConfig: ITable = {
  th: {
    fontFamily: fontSettings.TeliaSansBold.fontStack,
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'left',
    border: 'none',
    padding: '10px 6px',
  },
  td: {
    fontSize: 12,
  },
  oddRowColor: '#FFF',
  evenRowColor: '#f2f2f2',
  selectedRowBackground: '#9b009a45',
  expandArrowColor: 'inherit',
};
