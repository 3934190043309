import { ConnectivityUnitType } from 'generated';
import i18n from 'i18next';
import { Column } from 'react-table';
import { getOptionFilter } from '../Helpers/getOptionFilter';

export function simType<T>(options: {
  id: string;
  accessor: (row: T) => ConnectivityUnitType;
  header?: string;
  defaultShow?: boolean;
  filterable?: boolean;
  width?: number;
}): Column<T> {
  const {
    id,
    header = 'Type',
    accessor,
    defaultShow: show = true,
    filterable,
    width = 100,
  } = options;

  return {
    id,
    Header: header,
    accessor,
    resizable: true,
    width,
    show,
    filterable,
    Filter: getOptionFilter([
      { label: i18n.t('sms:label.all'), value: '' },
      {
        label: i18n.t(
          `sim:connectivity_unit.type.${ConnectivityUnitType.Single}`
        ),
        value: ConnectivityUnitType.Single,
      },
      {
        label: i18n.t(
          `sim:connectivity_unit.type.${ConnectivityUnitType.Multi}`
        ),
        value: ConnectivityUnitType.Multi,
      },
    ]),
    Cell: row => i18n.t(`sim:connectivity_unit.type.${row.value}`),
  };
}
