import { Box, useTheme } from '@mui/material';
import AtlasIcon from 'components/AtlasIcon';
import { isValidAtlasIconType } from 'components/AtlasIcon/iconUtils';
import React from 'react';

interface IProps {
  iconType: string;
}

const MenuItemIcon: React.FC<IProps> = ({ iconType }) => {
  const theme = useTheme();

  const parsedIconType = isValidAtlasIconType(iconType) && iconType;

  const { iconSize, height } = theme.settings.components.navigation.menuItem;

  return (
    <Box
      width={height}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {parsedIconType && <AtlasIcon type={parsedIconType} size={iconSize} />}
    </Box>
  );
};

export default MenuItemIcon;
