import { Menu, MenuItem } from '@mui/material';
import AtlasIcon from 'components/AtlasIcon';
import { useTranslation } from 'react-i18next';
import { usePopover } from 'utils';
import WidgetHeaderButton from './Widget/WidgetHeaderButton';

interface IProps {
  id: string;
  downloadCSV: () => void;
  downloadXLSX: () => void;
  disabled?: boolean;
}

const DownloadDataMenu: React.FC<IProps> = ({
  id,
  downloadCSV,
  downloadXLSX,
  disabled = false,
}) => {
  const [openDownloadMenu, popoverProps] = usePopover(id);
  const { t } = useTranslation('dashboard');

  return (
    <>
      <WidgetHeaderButton
        onClick={openDownloadMenu}
        data-cy="downloadWidgetData"
        aria-controls={popoverProps.open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        disabled={disabled}
      >
        <AtlasIcon type="Download" size={16} />
      </WidgetHeaderButton>

      <Menu {...popoverProps}>
        <MenuItem
          onClick={() => {
            popoverProps.onClose();
            downloadCSV();
          }}
        >
          {t('action.widget.export.csv')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            popoverProps.onClose();
            downloadXLSX();
          }}
        >
          {t('action.widget.export.xlsx')}
        </MenuItem>
      </Menu>
    </>
  );
};

export default DownloadDataMenu;
