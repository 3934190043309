import { YAxisPlotBandsOptions } from 'highcharts';
import { useState } from 'react';
import { Dashboard } from 'store/domains/dashboard';
import { IDataProperty } from 'views/Dashboard/DataSources/dataPropTypes';
import { usePolicy } from '../NumberGridComponent/usePolicy';
import { ILineChartSettings } from './lineChartConfig';
import { plotBandColors } from './plotBandColors';

function usePlotBandsFromPolicy(
  policyId: string,
  yValue: IDataProperty<number>
): YAxisPlotBandsOptions[] {
  const policy = usePolicy(policyId);
  const status = policy?.statuses?.find(s => s.servicePropertyId === yValue.id);
  const plotBands: YAxisPlotBandsOptions[] = [];

  status?.rules?.forEach(rule => {
    const { color, min, max } = rule;
    if (color) {
      plotBands.push({
        color: plotBandColors[color] || 'transparent',
        from: min ?? Number.MIN_SAFE_INTEGER,
        to: max ?? Number.MAX_SAFE_INTEGER,
      });
    }
  });

  return plotBands;
}

export const useLineChartSharedOptions = (
  fileName: string,
  xValue: IDataProperty<number>,
  yValue: IDataProperty<number>,
  settings: ILineChartSettings,
  dashboard: Dashboard
): [Highcharts.Options, boolean, (value: boolean) => void] => {
  const dateFilters = dashboard.filterState.getDateFilters();
  const [isZoomed, setIsZoomed] = useState(false);
  const policyPlotBands = usePlotBandsFromPolicy(settings.policyId, yValue);

  const chartOptions: Highcharts.Options = {
    title: {
      text: undefined,
    },
    lang: {
      noData: '',
    },
    exporting: {
      filename: fileName,
      csv: {
        // This function is called for each column header.
        columnHeaderFormatter: function (
          item: { name: string },
          key: { y: string }
        ) {
          if (!key) {
            return xValue.name;
          }
          return yValue.unit ? `${item.name} | ${yValue.unit}` : item.name;
        },
      },
      chartOptions: {
        title: { text: `${settings.title} ${yValue.unit}` },
      },
    },
    xAxis: {
      type: xValue.type === 'dateTime' ? 'datetime' : 'linear',
      crosshair: {
        dashStyle: 'Dash',
        color: 'rgb(180, 180, 180)',
        zIndex: 5,
      },
      title: {
        text: xValue.name,
      },
      events: {
        setExtremes: (e: any) => {
          if (e.target.zoomEnabled) {
            setIsZoomed(true);
          } else {
            setIsZoomed(false);
          }
        },
      },
      min: xValue.type === 'dateTime' ? dateFilters.from?.getTime() : undefined,
      max: xValue.type === 'dateTime' ? dateFilters.to?.getTime() : undefined,
      startOnTick: false,
      endOnTick: false,
    },
    yAxis: [
      {
        title: {
          text: yValue.name,
        },
        startOnTick: !settings.yAxis.min,
        endOnTick: !settings.yAxis.max,
        min: typeof settings.yAxis.min === 'number' ? settings.yAxis.min : null,
        max: typeof settings.yAxis.max === 'number' ? settings.yAxis.max : null,
        labels: {
          format: yValue.unit ? `{value}${yValue.unit}` : undefined,
        },
        plotBands: settings.policyId
          ? policyPlotBands
          : settings.yAxis.plotBands
              ?.filter(
                pb => typeof pb.from === 'number' && typeof pb.to === 'number'
              )
              .map(pb => ({
                from: pb.from,
                to: pb.to,
                color: plotBandColors[pb.color] || '',
              })),
      },
    ],
    time: {
      timezoneOffset: new Date().getTimezoneOffset(),
      useUTC: false,
    },
  };

  return [chartOptions, isZoomed, setIsZoomed];
};
