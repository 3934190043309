import { Box } from '@mui/material';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Link } from 'react-router-dom';
import { useStores } from 'store';
import { useItemStyles } from './MenuItemContainer.styles';

type MenuItemVariant = 'default' | 'active' | 'categoryHeader';

export interface IMenuItemContainerProps {
  url?: string;
  onClick?: () => void;
  color?: MenuItemVariant;
  inset?: boolean;
  dataCy?: string;
}

function isExternalURL(url: string) {
  if (url.startsWith('/')) {
    return false;
  }
  try {
    return new URL(url).origin !== document.location.origin;
  } catch (error) {
    return false;
  }
}

const MenuItemContainer: React.FC<IMenuItemContainerProps> = ({
  url,
  onClick,
  color = 'default',
  inset,
  dataCy,
  children,
}) => {
  const classes = useItemStyles();
  const {
    layoutStore: { sidenavOpen },
  } = useStores();

  const finalClasses = classNames(classes.root, {
    [classes.active]: color === 'active',
    [classes.categoryHeader]: color === 'categoryHeader',
    [classes.minimized]: !sidenavOpen,
    [classes.inset]: inset,
  });

  if (url) {
    if (isExternalURL(url)) {
      return (
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          onClick={onClick}
          className={finalClasses}
          data-cy={dataCy}
        >
          {children}
        </a>
      );
    }

    return (
      <Link
        to={url}
        onClick={onClick}
        className={finalClasses}
        data-cy={dataCy}
      >
        {children}
      </Link>
    );
  }

  return (
    <Box className={finalClasses} onClick={onClick} data-cy={dataCy}>
      {children}
    </Box>
  );
};

export default observer(MenuItemContainer);
