import { assetIdsToServiceDataFilters } from './assetIdsToServiceDataFilters';
import { IServiceDataSourceSettings } from './serviceDataConfig';
import { IServiceLatestDataSourceSettings } from './serviceLatestConfig';

export function fixServiceDataSourceSettings(
  settings: IServiceLatestDataSourceSettings | IServiceDataSourceSettings
) {
  const oldAssetIds = (settings as { filters?: { assetIds?: string[] } }) // The old format, now abandoned
    .filters?.assetIds;

  if (oldAssetIds) {
    if (oldAssetIds.length) {
      settings.serviceDataFilters = assetIdsToServiceDataFilters(oldAssetIds);
    }
    delete (settings as any).filters;
  }

  if (!settings.serviceDataFilters) {
    settings.serviceDataFilters = [];
  }
}
