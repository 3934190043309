import { Role } from 'components/Auth/Role';
import { snackbar } from 'components/Snackbar';
import { parseISO } from 'date-fns';
import {
  DynamicAttributeDataType,
  IntAttributeDefinitionDto,
  IntIntelligenceEventTableDto,
  IntIntelligenceEventTableRequestDto,
  ServiceDataFilterType,
} from 'generated';
import i18n from 'i18n';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { Column, SortingRule } from 'react-table';
import { IntelligenceEventAPI } from 'services/intelligenceEvent.service';
import { Dashboard } from 'store/domains/dashboard';
import { StoreBase } from 'store/storeBase';
import { parseDate } from 'utils';
import { TableWidgetState } from '../../ComponentTypes/TableComponent/TableWidgetState';
import {
  DataPropType,
  IDataPropCategory,
  IDataProperty,
} from '../dataPropTypes';
import {
  DataPropsGetter,
  IDataSourceResponse,
  IDataSourceStore,
} from '../dataSourceTypes';
import { getDataProp } from '../getDataProp';
import { category } from '../ServiceData/assetDataProperties';
import { getDecimals } from '../ServiceData/servicePropsToDataProps';
import AckEventButton from './AckEventButton';
import { getAssetTableDataProps } from './assetTableDataProps';
import { IIntelligenceEventsDataSourceSettings } from './intelligenceEventsConfig';

const eventProperties: IDataPropCategory = {
  displayName: i18n.t(
    'dashboard:intelligence_events.properties.category_header'
  ),
};

export class IntelligenceEventsDataSource
  extends StoreBase
  implements IDataSourceStore<IIntelligenceEventsDataSourceSettings>
{
  @observable.ref settings: IIntelligenceEventsDataSourceSettings;
  isInitialized = true;
  @observable lastReceivedData: Date | undefined;

  dashboard: Dashboard;

  constructor(
    dashboard: Dashboard,
    settings: IIntelligenceEventsDataSourceSettings
  ) {
    super(dashboard.rootStore);
    makeObservable(this);

    this.dashboard = dashboard;
    this.settings = settings;
  }

  dataProperties: IDataProperty<IntIntelligenceEventTableDto>[] = [
    {
      id: 'intelligenceEventId',
      _get: row => row.intelligenceEventId,
      name: i18n.t('dashboard:intelligence_events.properties.id'),
      type: 'string',
      isUniqueId: true,
      category: eventProperties,
    },
    {
      id: 'eventDate',
      _get: row => (row.eventDate ? parseDate(row.eventDate) : null),
      name: i18n.t('dashboard:intelligence_events.properties.event_date'),
      type: 'dateTime',
      category: eventProperties,
    },
    {
      id: 'definition.displayName',
      _get: row => row.eventDefinitionDisplayName,
      name: i18n.t('dashboard:intelligence_events.properties.definition_name'),
      type: 'string',
      isDefaultDisplayName: true,
      category: eventProperties,
    },
    {
      id: 'customer.customerName',
      _get: row => row.customerName,
      name: i18n.t('dashboard:intelligence_events.properties.customer'),
      type: 'string',
      category: eventProperties,
      hideByDefault: true,
    },
    {
      id: 'acknowledged',
      _get: row => !!row.acknowledgedDate,
      name: i18n.t(
        'dashboard:intelligence_events.properties.acknowledged.base'
      ),
      type: 'boolean',
      category: eventProperties,
    },
    {
      id: 'acknowledgedDate',
      _get: row =>
        row.acknowledgedDate ? parseDate(row.acknowledgedDate) : null,
      name: i18n.t(
        'dashboard:intelligence_events.properties.acknowledged.date'
      ),
      type: 'dateTime',
      category: eventProperties,
    },
    {
      id: 'actualValue',
      _get: row => {
        if (row.actualValue === null) {
          return null;
        } else {
          const numberValue = parseFloat(row.actualValue);

          if (!isNaN(numberValue)) {
            return numberValue;
          }

          return row.actualValue;
        }
      },
      name: i18n.t('dashboard:intelligence_events.properties.actual_value'),
      type: 'mixedType',
      category: eventProperties,
      getUnit: row => row.unit,
      getDecimals: row => getDecimals(row.displayFormat),
      getConversion: row => row.conversion,
    },
  ];

  @computed get groupProperties(): IDataProperty[] {
    const dynamicAttributeProperties = this.dynamicAttributeProperties;

    const allGroupProps: IDataProperty[] = [
      ...getAssetTableDataProps<IntIntelligenceEventTableDto>(
        row => row.assetTable,
        (row, definitionKey) => {
          if (!row.assetAttributes) {
            return null;
          }
          const definition = dynamicAttributeProperties.find(
            d => d.id === definitionKey || d.name === definitionKey
          );
          return definition?.id && row.assetAttributes[definition.id]
            ? row.assetAttributes[definition.id]
            : null;
        }
      ),
    ];

    allGroupProps.push(...dynamicAttributeProperties);

    return allGroupProps;
  }

  @computed get dynamicAttributeProperties(): IDataProperty[] {
    const { definitions } = this.rootStore.dynamicAttributeStore;

    return definitions.map(def => ({
      id: def.id || '',
      _get: event => {
        if (!event.assetAttributes) {
          return null;
        }

        const rawValue = event.assetAttributes[def.id || ''];

        if (def.dataType === DynamicAttributeDataType.DateTime) {
          return rawValue ? parseISO(rawValue) : null;
        }

        return rawValue ?? null;
      },
      name: def.name,
      type: this.parseDefinitionValueType(def),
      category,
    }));
  }

  parseDefinitionValueType(
    definition: IntAttributeDefinitionDto
  ): DataPropType {
    if (definition.dataType) {
      switch (definition.dataType) {
        case DynamicAttributeDataType.Text:
          return 'string';
        case DynamicAttributeDataType.Number:
          return 'number';
        case DynamicAttributeDataType.Boolean:
          return 'boolean';
        case DynamicAttributeDataType.DateTime:
          return 'dateTime';
      }
    }
    return 'string';
  }

  defaultSort: SortingRule = {
    id: 'eventDate',
    desc: true,
  };
  tableState = new TableWidgetState(this.defaultSort);

  async getData(): Promise<IDataSourceResponse> {
    const resp = await this.httpPost(IntelligenceEventAPI.getAll, {
      params: undefined,
      data: this.getRequestParams(),
    });

    if (resp.status === 204) {
      return {
        type: 'noContent',
      };
    } else if (resp.status !== 200) {
      const errorMessage =
        resp.statusText ||
        resp.exceptionMessage ||
        'Error loading Intelligence Events';

      snackbar(errorMessage, { variant: 'error' });

      return {
        type: 'error',
        message: errorMessage,
      };
    }

    runInAction(() => {
      this.lastReceivedData = new Date();
    });

    return {
      type: 'success',
      data: {
        type: 'list',
        items: resp.data?.rows || [],
        total: resp.data?.total || 0,
      },
    };
  }

  getDataProps<T>(propGetter: DataPropsGetter<T>): T {
    return propGetter(prop => getDataProp(this, prop));
  }

  @computed get extraColumns(): Column[] {
    if (this.rootStore.authStore.hasRole(Role.RoleNameEditIntelligenceEvent)) {
      return [
        {
          id: 'actions',
          accessor: () => '',
          sortable: false,
          width: 80,
          Header: i18n.t('dashboard:intelligence_events.actions.column_header'),
          className: 'button-column',
          Cell: ({ row }) => <AckEventButton event={row._original} />,
        },
      ];
    }
    return [];
  }

  getRequestParams = (): IntIntelligenceEventTableRequestDto => {
    const { assetFilterSpecDtos, getDateFilters } = this.dashboard.filterState;

    const dashboardFilters = [...assetFilterSpecDtos];

    const registerDate = getDateFilters();

    if (registerDate.from) {
      dashboardFilters.push({
        type: ServiceDataFilterType.RegisterDateStart,
        values: [registerDate.from.toISOString()],
      });
    }

    if (registerDate.to) {
      dashboardFilters.push({
        type: ServiceDataFilterType.RegisterDateEnd,
        values: [registerDate.to.toISOString()],
      });
    }

    return {
      dashboardFilters,
      filters: '',
      search: this.tableState.search,
      ...this.tableState.tableProps,
    };
  };

  @computed get depString(): string {
    const { manualRefreshTrigger } = this.dashboard.rootStore.dashboardStore;

    const depObject = {
      ...this.getRequestParams(),
      manualRefreshTrigger,
    };

    return JSON.stringify(depObject);
  }
}
