import { TableData } from 'components/Table';
import { addDays, differenceInCalendarDays } from 'date-fns';
import {
  IntCdrDataDto,
  IntCdrSmsDto,
  IntCountryDataConsumptionDto,
  IntSearchDto,
  IntUnitConsumptionDataDto,
  IntUnitConsumptionSmsDto,
} from 'generated';
import { IResponse } from 'shared/interfaces/api';
import { paginate, rnd, rndInt, rndItem } from 'utils';

// Set maximum available days to test correct padding
const maximumDays = 32;
const emptyDays = 5;

const padGaps = (k: number, t: number) => {
  if (k > t) {
    if (k > t + emptyDays * 2) {
      return k + emptyDays * 2;
    }
    return k + emptyDays;
  }
  return k;
};

class UsageDemoData {
  getDataConsumption = (
    params: { startDate: string; endDate: string },
    data: string[] = []
  ): IResponse<IntUnitConsumptionDataDto[]> => {
    const t = rndInt(emptyDays, maximumDays - 20);
    return {
      data: rnd.array<IntUnitConsumptionDataDto>(data.length, i => {
        let totalUploadedData = 0;
        let totalDownloadedData = 0;
        const connectivityUnitId = data[i];
        const identifier = connectivityUnitId.replaceAll('-', '');
        const startDate = new Date(params.startDate);
        const numberOfDays =
          differenceInCalendarDays(new Date(params.endDate), startDate) + 1;

        return {
          consumption: rnd.array<IntCdrDataDto>(
            numberOfDays > maximumDays ? maximumDays : numberOfDays,
            k => {
              const uploadedData = rnd.mb();
              const downloadedData = rnd.mb();
              const totalData = uploadedData + downloadedData;

              totalUploadedData += uploadedData;
              totalDownloadedData += downloadedData;

              return {
                downloadedData,
                uploadedData,
                totalData,
                source: 'Telia',
                date: addDays(
                  startDate,
                  numberOfDays > maximumDays ? 10 + padGaps(k, t) : k
                ),
                imsi: rnd.imsi(), //Should match profile imsi or this wont wöörk
                msisdn: rnd.msisdn(),
                iccId: rnd.iccid(),
                connectivityUnitId: rnd.guid(),
                customerId: rnd.guid(),
                identifier: rnd.euiccId(),
                label: rnd.label(),
                groupLabel: rnd.groupLabel(),
                connectivityUnitGroupId: rnd.guid(),
                country: rnd.country(),
                mobileCountryCode: rnd.countryCode(),
              };
            }
          ),
          connectivityUnitId: data[i],
          identifier,
          label: rnd.label(),
          totalUploadedData,
          totalDownloadedData,
          totalConsumption: totalUploadedData + totalDownloadedData,
        };
      }),
      status: 200,
    };
  };

  getSmsConsumption = (
    params: { startDate: string; endDate: string },
    data: string[] = []
  ): IResponse<IntUnitConsumptionSmsDto[]> => {
    const t = rndInt(emptyDays, maximumDays - 20);
    return {
      data: rnd.array<IntUnitConsumptionSmsDto>(data.length, i => {
        let totalIncomingMessages = 0;
        let totalOutgoingMessages = 0;
        const connectivityUnitId = data[i];
        const identifier = connectivityUnitId.replaceAll('-', '');
        const startDate = new Date(params.startDate);
        const numberOfDays =
          differenceInCalendarDays(new Date(params.endDate), startDate) + 1;

        return {
          consumption: rnd.array<IntCdrSmsDto>(
            numberOfDays > maximumDays ? maximumDays : numberOfDays,
            k => {
              const incomingMessages = rnd.int(10, 1000);
              const outgoingMessages = rnd.int(10, 100);

              totalIncomingMessages += incomingMessages;
              totalOutgoingMessages += outgoingMessages;

              return {
                incomingMessages,
                outgoingMessages,
                source: 'Telia',
                date: addDays(
                  startDate,
                  numberOfDays > maximumDays ? 10 + padGaps(k, t) : k
                ),
                imsi: rnd.imsi(), // Need to match somehow
              };
            }
          ),
          totalIncomingMessages,
          totalOutgoingMessages,
          connectivityUnitId,
          identifier,
          label: rnd.label(),
          totalConsumption: totalIncomingMessages + totalOutgoingMessages,
        };
      }),
      status: 200,
    };
  };

  getDataUsageByCountry = (): IResponse<IntCountryDataConsumptionDto[]> => {
    return {
      status: 200,
      data: rnd.array<IntCountryDataConsumptionDto>(30, i => {
        const uploaded = rndItem([rnd.mb(), rnd.kb(), rnd.gb(), rnd.b()]);
        const downloaded = rndItem([rnd.gb(), rnd.kb(), rnd.b(), rnd.mb()]);

        return {
          countryISO3166: rnd.locale().id,
          country: rnd.country(),
          customerId: rnd.guid(),
          downloaded,
          uploaded,
          total: uploaded + downloaded,
        };
      }),
    };
  };

  getDataUsageByDay = (
    params: IntSearchDto
  ): IResponse<TableData<IntCdrDataDto>> => {
    return {
      status: 200,
      data: paginate(
        rnd.array<IntCdrDataDto>(30, i => {
          const uploaded = rndItem([rnd.mb(), rnd.kb(), rnd.gb(), rnd.b()]);
          const downloaded = rndItem([rnd.gb(), rnd.kb(), rnd.b(), rnd.mb()]);

          return {
            connectivityUnitId: rnd.guid(),
            customerId: rnd.guid(),
            downloadedData: uploaded,
            uploadedData: downloaded,
            totalData: uploaded + downloaded,
            source: 'Apn Hub',
            date: rnd.date.anytime(),
            imsi: rnd.imsi(),
            msisdn: rnd.msisdn(),
            iccId: rnd.iccid(),
            identifier: rnd.euiccId(),
            label: rnd.label(),
            groupLabel: rnd.groupLabel(),
            connectivityUnitGroupId: rnd.guid(),
            country: rnd.country(),
            mobileCountryCode: rnd.countryCode(),
          };
        }),
        params
      ),
    };
  };
}

export const usageDemoData = new UsageDemoData();
