import { IntDashboardDataStatusDto } from 'generated';
import { useMemo } from 'react';
import { IGroupedData } from 'services/service.service';
import { dataProp } from 'views/Dashboard/DataSources';
import { IDataProperty } from 'views/Dashboard/DataSources/dataPropTypes';
import { serviceDataUtils } from 'views/Dashboard/DataSources/ServiceData/serviceDataUtils';
import { getActiveNumberStatusRule } from 'views/Dashboard/DataStatus/useActiveStatusRule';
import {
  IMapFeature,
  IPropertyRuleValue,
} from '../../ImageComponent/ImagePinLayer';
import { IMapLocationData } from './useCurrentLocationData';

// Combines location data with separate details data and creates features
export function useCombinedFeatures(
  pins: IMapLocationData[],
  vm: {
    pinValues: { status: IntDashboardDataStatusDto; prop: IDataProperty }[];
  },
  data?: IGroupedData[]
): IMapFeature[] {
  return useMemo(() => {
    const newFeatures: IMapFeature[] = [];
    pins.forEach(pin => {
      const details = data?.find(group => group.id === pin.id);

      // If the asset has location data but not, for example, weather data - show it anyway but without details
      const detailProperties = details
        ? {
            ...details.owner,
            ...serviceDataUtils.flattenLatestData(details),
          }
        : {};

      const { longitude, latitude, id, displayName, isConnected } = pin;

      const allValues = vm.pinValues.map(
        ({ prop, status }): IPropertyRuleValue => {
          const value = dataProp.getNumber(prop, detailProperties);
          return {
            activeRule: getActiveNumberStatusRule(status, value),
            dataProp: prop,
            value,
          };
        }
      );

      const getColorCount = (arr: IPropertyRuleValue[], color: string) =>
        arr.filter(item => item.activeRule?.color === color).length;

      const redCount = getColorCount(allValues, 'red');

      const feature: IMapFeature = {
        id,
        position: [longitude, latitude],
        properties: {
          id,
          displayName,
          icon: isConnected ? 'pin-green' : 'pin-red',
          iconAnchor: 'bottom',
          data: detailProperties,
          allValues,
          red: redCount,
          yellow: getColorCount(allValues, 'yellow'),
          green: getColorCount(allValues, 'green'),
          statusColor: redCount > 0 ? 'red' : 'green',
          group: pin.group,
        },
      };

      newFeatures.push(feature);
    });

    return newFeatures;
  }, [pins, vm, data]);
}
