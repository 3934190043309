import { TableData } from 'components/Table';
import {
  IntIntelligenceEventTableDto,
  IntIntelligenceEventTableRequestDto,
  ServicePropertyDisplayFormat,
} from 'generated';
import { IResponse } from 'shared/interfaces/api';
import { paginate, rnd } from 'utils';
import { assetDemoData } from './assetDemoData';

class IntelligenceEventDemoData {
  private _allEvents: IntIntelligenceEventTableDto[] | undefined;

  get allEvents() {
    if (!this._allEvents) {
      this._allEvents = rnd.array<IntIntelligenceEventTableDto>(133, () => {
        const asset = rnd.item(...assetDemoData.assets);

        return {
          intelligenceEventId: rnd.guid(),
          actualValue: rnd.int(10, 100).toString(),
          assetDisplayName: asset.displayName,
          customerName: asset.customerName,
          eventDate: new Date(),
          conversion: 0.1,
          eventDefinitionDisplayName: rnd.company(),
          eventDefinitionDisplayNameDe: rnd.company(),
          eventDefinitionDisplayNameEs: rnd.company(),
          eventDefinitionDisplayNameFr: rnd.company(),
          eventDefinitionDisplayNameSv: rnd.company(),
          acknowledgedDate: rnd.bool() ? rnd.date.past() : undefined,
          assetTable: asset,
          assetAttributes: null,
          triggerServiceId: '03eb3499-068f-438d-a936-0fbb987f0135',
          unit: '%',
          displayFormat: ServicePropertyDisplayFormat.NumberOneDecimal,
          triggeredServiceDataPoint: {
            temperature: (rnd.int(10, 300) / 10).toString(),
            relative_humidity: rnd.int(0, 100).toString(),
          },
          automationRuleName: 'Automation rule',
          displayNames: {
            'en-US': 'Automation rule en',
          },
        };
      });
    }
    return this._allEvents;
  }

  getAll = (
    _params: undefined,
    data?: IntIntelligenceEventTableRequestDto
  ): IResponse<TableData<IntIntelligenceEventTableDto>> => {
    if (!data) {
      return {
        status: 500,
      };
    }
    return {
      status: 200,
      data: paginate(this.allEvents, data),
    };
  };

  acknowledgeEvent = (params: string): IResponse => {
    const eventToAcknowledge = this.allEvents.find(
      event => event.intelligenceEventId === params
    );
    if (eventToAcknowledge) {
      eventToAcknowledge.acknowledgedDate = new Date();
      return {
        status: 200,
      };
    }
    return {
      status: 500,
      statusText: 'Event not found',
    };
  };
}

export const intelligenceEventDemoData = new IntelligenceEventDemoData();
