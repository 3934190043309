import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    fontSize: 'inherit',
    width: '100%',
  },
  input: {
    height: '30px !important',
    padding: '0 0 0 7px !important',
    border: 'none !important',
  },
  adornment: {
    paddingRight: 7,
  },
}));
