import { TableData } from 'components/Table';
import {
  IntAnalyticsCubeDataSpecificationRequestDto,
  IntAnalyticsCubeDetailsDto,
  IntAnalyticsCubeDto,
  IntAnalyticsCubeOutputResult,
  IntSearchDto,
} from 'generated';
import { GetEndpoint, PostEndpoint } from 'shared/interfaces/api';

export interface IntAnalyticsCubeOutputDto {
  assets: Record<string, string>[];
  results: IntAnalyticsCubeOutputResult[];
}

interface IAnalyticsCubeAPI {
  getAll: GetEndpoint<IntSearchDto, TableData<IntAnalyticsCubeDto>>;
  getDetails: GetEndpoint<string, IntAnalyticsCubeDetailsDto>;
  getData: PostEndpoint<
    string,
    IntAnalyticsCubeDataSpecificationRequestDto,
    IntAnalyticsCubeOutputDto
  >;
}

// Todo: implement proper demo data
export const analyticsCubeApi: IAnalyticsCubeAPI = {
  getAll: {
    url: '/api/analyticsCube',
  },
  getDetails: {
    url: cubeId => `/api/analyticsCube/${cubeId}`,
  },
  getData: {
    url: cubeId => `/api/analyticsCube/${cubeId}/data`,
  },
};
