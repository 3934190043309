import { column } from 'components/Table';
import i18n from 'i18n';
import { Column } from 'react-table';
import { Dashboard } from 'store/domains/dashboard';
import { rootStore } from 'store/RootStoreContext';
import { IDataProperty } from 'views/Dashboard/DataSources/dataPropTypes';
import {
  DataPropsGetter,
  IArrayWidgetData,
  IDataSourceSettings,
  IGroupedWidgetData,
  IObjectWidgetData,
  IWidgetData,
} from 'views/Dashboard/DataSources/dataSourceTypes';
import { getActiveNumberStatusRule } from 'views/Dashboard/DataStatus/useActiveStatusRule';
import { dataProp as dp } from '../../DataSources';
import { getCustomColumnOverride } from './customColumnOverrides';
import { getChartCell } from './getChartCell';
import { getPolicyColumnOverride } from './policyColumnOverrides';
import { ITableColumnSettings } from './tableConfig';

export interface ITableViewModel {
  columns: Column[];
  getRows: (data: IWidgetData) => { rows: any[]; total: number };
  pivotBy: IDataProperty[];
}

export const getColId = (dataProp: IDataProperty) =>
  dataProp.path || dataProp.id;

function getTableColumn(options: {
  policyId?: string;
  dataSourceSettings: IDataSourceSettings;
  columnSettings: ITableColumnSettings;
  dataProp: IDataProperty;
  xProp?: IDataProperty;
  dashboard: Dashboard;
}): Column | null {
  const {
    policyId,
    dataSourceSettings,
    columnSettings,
    dataProp,
    xProp,
    dashboard,
  } = options;
  const { name: header, unit, width } = dataProp;

  const id = getColId(dataProp);

  const accessor = (row: any) => row[dataProp.id];

  const policyColumn = getPolicyColumnOverride(
    {
      id,
      header,
      accessor,
      dataProp,
    },
    policyId
  );

  if (policyColumn) {
    return policyColumn;
  }

  if (dataProp.type === 'notFound') {
    return null;
  }

  if (dataSourceSettings.type === 'intelligenceEvents') {
    switch (dataProp.type) {
      case 'dateTime':
        return column.date({
          id,
          header,
          accessor,
          showTime: true,
        });

      case 'boolean':
        return column.boolean({
          id,
          header,
          accessor,
        });

      case 'number':
        return column.number({
          id,
          header,
          accessor,
          decimals: dataProp.decimals,
          unit: dataProp.unit,
        });
      case 'mixedType':
        return column.mixedType({
          id,
          header,
          accessor,
          getUnit: dataProp.getUnit,
          getDecimals: dataProp.getDecimals,
          getConversion: dataProp.getConversion,
        });

      default:
        return column.text({
          id,
          header,
          accessor,
        });
    }
  }

  if (
    columnSettings.type === 'dataPointProp' &&
    dataSourceSettings.type === 'service'
  ) {
    // Historical data
    if (dataProp.type === 'number' && xProp) {
      const { from, to } = dashboard.filterState.getDateFilters();

      return {
        id,
        Header: header,
        accessor,
        Cell: getChartCell({
          xValue: xProp,
          yValue: dataProp,
          xMin: from?.getTime(),
          xMax: to?.getTime(),
        }),
      };
    } else {
      return { id, Header: header, accessor: () => '' };
    }
  }

  // A few service properties need custom cells with icons (quickly), in the future this will be more customizable, for now they are hard-coded overrides
  const customColumn = getCustomColumnOverride({
    id,
    header,
    accessor,
    dataProp,
  });

  if (customColumn) {
    return customColumn;
  }

  switch (dataProp.type) {
    case 'dataUsage':
      return column.dataUsage({
        id,
        header,
        accessor,
        width,
      });
    case 'number':
      return column.number({
        id,
        header,
        accessor,
        unit,
        decimals: dataProp.decimals,
        aggregate: 'min-max',
      });

    case 'dateTime':
      if (dataProp.format === 'timeAgo') {
        return column.timeAgo({
          id,
          header,
          accessor,
        });
      }
      return column.date({
        id,
        header,
        accessor,
        showTime: true,
      });

    case 'boolean':
      return column.boolean({ id, header, accessor });

    case 'string':
    default: {
      if (dataProp.link) {
        return column.link({ id, header, accessor, to: dataProp.link });
      }

      return column.text({
        id,
        header,
        accessor,
        aggregate: 'unique',
      });
    }
  }
}

interface IMakeTableOptions {
  columns: ITableColumnSettings[];
  pivotBy: string[];
  dataSourceSettings: IDataSourceSettings;
  dashboard: Dashboard;
  policyId?: string;
  enablePolicySummaryColumn?: boolean;
}

export const makeTableViewModel = (
  opts: IMakeTableOptions
): DataPropsGetter<ITableViewModel> => {
  const {
    columns,
    pivotBy,
    dataSourceSettings,
    dashboard,
    policyId,
    enablePolicySummaryColumn,
  } = opts;

  return getProp => {
    // Group the column settings and their data props for ease of use
    const colDetails = columns.map(columnSettings => ({
      columnSettings,
      dataProp: getProp(columnSettings.prop),
    }));

    const serviceDataCols = colDetails.filter(
      col => col.columnSettings.type === 'dataPointProp'
    );

    const ownerCols = colDetails.filter(
      col => col.columnSettings.type === 'ownerProp'
    );

    const xDataProp = getProp('registerDate');

    const tableColumns = colDetails
      .map(({ columnSettings, dataProp }) =>
        getTableColumn({
          dataSourceSettings,
          policyId,
          columnSettings,
          dataProp,
          xProp: xDataProp,
          dashboard,
        })
      )
      .filter(col => col !== null) as Column[];

    const pivotByProps = pivotBy.map(getProp);

    if (pivotBy.length) {
      tableColumns.push({
        id: '__pivot',
        accessor: row => row.__pivot,
        Header: pivotByProps.map(pivotDp => pivotDp.name).join(', '),
      });
    }

    // Add a total status column, only useful for pivoted tables for now
    if (policyId && pivotBy.length && enablePolicySummaryColumn) {
      tableColumns.unshift(column.policyWarnings());
    }

    return {
      pivotBy: pivotByProps,
      columns: tableColumns,
      getRows: data => {
        if (data.type === 'list') {
          return getRowsTotalForList(data, serviceDataCols, ownerCols);
        } else if (data.type === 'groupedData') {
          return getRowsTotalForGroupedData(
            dataSourceSettings,
            data,
            ownerCols,
            serviceDataCols,
            xDataProp,
            pivotByProps,
            policyId
          );
        } else if (data.type === 'object') {
          return getRowsTotalForObject(colDetails, data);
        } else {
          return { rows: [], total: 0 };
        }
      },
    };
  };
};

const getRowsTotalForList = (
  data: IArrayWidgetData,
  serviceDataCols: {
    columnSettings: ITableColumnSettings;
    dataProp: IDataProperty;
  }[],
  ownerCols: {
    columnSettings: ITableColumnSettings;
    dataProp: IDataProperty;
  }[]
) => {
  let total = 0;
  const rows: any[] = [];

  total = data.total;
  data.items.forEach(item => {
    const newRow: any = { ...item };

    ownerCols.forEach(col => {
      newRow[col.dataProp.id] = dp.get(col.dataProp, item);
    });

    serviceDataCols.forEach(col => {
      newRow[col.dataProp.id] = dp.get(col.dataProp, item);
    });

    rows.push(newRow);
  });

  return { rows, total };
};

const getRowsTotalForGroupedData = (
  dataSourceSettings: IDataSourceSettings,
  data: IGroupedWidgetData,
  ownerCols: {
    columnSettings: ITableColumnSettings;
    dataProp: IDataProperty;
  }[],
  serviceDataCols: {
    columnSettings: ITableColumnSettings;
    dataProp: IDataProperty;
  }[],
  xDataProp: IDataProperty,
  pivotBy: IDataProperty[],
  policyId?: string
) => {
  let total = 0;
  const rows: any[] = [];

  const policy = rootStore.policyStore.policies?.find(
    p => p.policyId === policyId
  );

  const handleServiceLatest = () => {
    const serviceDataColumnsWithStatuses = serviceDataCols.map(spCol => ({
      sp: spCol.dataProp,
      status: policy?.statuses?.find(
        status => status.servicePropertyId === spCol.dataProp.id
      ),
    }));

    data.groups.forEach(assetDataPoint => {
      /*
    Make one row for each service data entry.
    Depending on the service context type setting, this could mean
    one row per asset (latest point/Latest point by register date)
    or multiple (latest collection)
    */
      const ownerProperties: any = { __id: assetDataPoint.id };

      ownerCols.forEach(col => {
        ownerProperties[col.dataProp.id] = dp.get(
          col.dataProp,
          assetDataPoint.owner
        );
      });

      // Add a special pivot column if needed
      if (pivotBy.length) {
        ownerProperties['__pivot'] =
          pivotBy
            .map(pivotDp => dp.getString(pivotDp, assetDataPoint.owner) ?? '')
            .filter(Boolean)
            .join(', ') || i18n.t('not_applicable_abbreviation');
      }

      // One row for each data point, each with the group properties from above
      assetDataPoint.dataPoints.forEach(dataPoint => {
        const newRow: any = { ...ownerProperties };

        if (policy) {
          const totalErrors = serviceDataColumnsWithStatuses.filter(
            item =>
              item.status &&
              getActiveNumberStatusRule(
                item.status,
                dp.getNumber(item.sp, dataPoint)
              )?.color === 'red'
          ).length;
          newRow['__totalPolicyWarnings'] = totalErrors;
        }

        serviceDataCols.forEach(col => {
          newRow[col.dataProp.id] = dp.get(col.dataProp, dataPoint);
        });

        rows.push(newRow);
      });
    });

    total = rows.length;
  };

  const handleService = () => {
    data.groups.forEach(assetData => {
      const newRow: any = {};
      ownerCols.forEach(col => {
        newRow[col.dataProp.id] = dp.get(col.dataProp, assetData.owner);
      });

      serviceDataCols.forEach(col => {
        // make a parsed array of [x, y] from the service data
        const xyData = assetData.dataPoints
          .map(dataPoint => {
            if (xDataProp.type !== 'number' && xDataProp.type !== 'dateTime') {
              return null;
            }

            const x = dp.getNumber(xDataProp, dataPoint);
            const y = dp.getNumber(col.dataProp, dataPoint);

            return [x, y];
          })
          .filter(point => point && point[0] !== null && point[1] !== null)
          .sort((a, b) => (a as any)[0] - (b as any)[0]);

        newRow[col.dataProp.id] = xyData;
      });

      rows.push(newRow);
    });

    total = rows.length;
  };

  if (dataSourceSettings.type === 'serviceLatest') {
    handleServiceLatest();
  } else if (dataSourceSettings.type === 'service') {
    handleService();
  }

  return { rows, total };
};

const getRowsTotalForObject = (
  colDetails: {
    columnSettings: ITableColumnSettings;
    dataProp: IDataProperty;
  }[],
  data: IObjectWidgetData
) => {
  let total = 0;
  const rows: any[] = [];
  const newRow: any = {};

  colDetails.forEach(col => {
    newRow[col.dataProp.id] = dp.get(col.dataProp, data.item);
  });

  rows.push(newRow);
  total = rows.length;

  return { rows, total };
};
