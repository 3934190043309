import { Box, Button, Grid } from '@mui/material';
import { isAfter } from 'date-fns';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateRangeType, IDateFilterValue } from '../dateFilterTypes';
import {
  getDateFilterOptions,
  getDateRangeFromFilterValue,
} from '../dateRangeOptions';
import DateRangePicker from './DateRangePicker';
import RangeOptionButton from './RangeOptionButton';

interface IProps {
  header?: string;
  enableTime?: boolean;
  limitFilterDays?: boolean;
  enableRangeOptions?: boolean;
  value: IDateFilterValue;
  onChange: (newValue: IDateFilterValue) => void;
  onCancel: () => void;
  onClear?: () => void;
  allowSingleDate?: boolean;
  disableFuture?: boolean;
}

const DateRangePickerContainer: React.FC<IProps> = ({
  header,
  enableTime = false,
  enableRangeOptions = true,
  limitFilterDays = false,
  value,
  onChange,
  onCancel,
  onClear,
  allowSingleDate = false,
  disableFuture = true,
}) => {
  const { t } = useTranslation();

  // The current value, only saved upon clicking 'accept'
  const [selectedValue, setSelectedValue] = useState(value);
  const [disableApplyButton, setDisableApplyButton] = useState(false);
  const applyChanges = () => {
    onChange(selectedValue);
  };

  const dateRange = getDateRangeFromFilterValue(selectedValue);

  const isDateRangeValid = allowSingleDate
    ? dateRange.endDate.toDateString() === dateRange.startDate.toDateString() ||
      isAfter(dateRange.endDate, dateRange.startDate)
    : isAfter(dateRange.endDate, dateRange.startDate);

  const rangeOptions = getDateFilterOptions(enableTime);

  return (
    <Box display={enableRangeOptions ? 'flex' : 'block'} padding={4}>
      {(header || onClear) && (
        <Grid container>
          <Grid item flexGrow={1}>
            {header && <h2>{header}</h2>}
          </Grid>

          <Grid item>
            {onClear && (
              <Button variant="outlined" color="primary" onClick={onClear}>
                {t('action.clear')}
              </Button>
            )}
          </Grid>
        </Grid>
      )}

      <DateRangePicker
        isDateRangeValid={isDateRangeValid}
        value={dateRange}
        onChange={({ startDate, endDate }) => {
          // We could check if the new dates are a pre-defined option here, instead of custom, but it seems like more effort than it's worth
          setSelectedValue({ type: 'custom', startDate, endDate });
        }}
        isValidDate={value => setDisableApplyButton(!value)}
        enableTime={enableTime}
        limitFilterDays={limitFilterDays}
        disableFuture={disableFuture}
      />

      <Box paddingLeft={2}>
        {enableRangeOptions && (
          <>
            {rangeOptions.map(rangeOption => (
              <RangeOptionButton
                isActive={rangeOption.type === selectedValue.type}
                key={rangeOption.type}
                dataCy={rangeOption.type}
                onClick={() => {
                  setSelectedValue({ type: rangeOption.type as DateRangeType });
                  setDisableApplyButton(false);
                }}
              >
                {t(`date.range.option.${rangeOption.type}`)}
              </RangeOptionButton>
            ))}
            <RangeOptionButton
              isActive={selectedValue.type === 'custom'}
              onClick={() =>
                setSelectedValue({
                  type: 'custom',
                  startDate: dateRange.startDate, // When switching to custom, keep whatever is selected
                  endDate: dateRange.endDate,
                })
              }
            >
              {t(`date.range.option.custom`)}
            </RangeOptionButton>
          </>
        )}

        <Box
          display="flex"
          justifyContent={enableRangeOptions ? 'space-between' : 'end'}
          paddingTop={3}
        >
          <Button variant="outlined" color="primary" onClick={onCancel}>
            {t('action.cancel')}
          </Button>
          <Button
            data-cy="applyButton"
            variant="contained"
            color="primary"
            onClick={applyChanges}
            disabled={!isDateRangeValid || disableApplyButton}
            style={{ marginLeft: '6px' }}
          >
            {t('action.apply')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default observer(DateRangePickerContainer);
