import accessendpoint from './accessendpoint.json';
import apnhub from './apnhub.json';
import asset from './asset.json';
import auth from './auth.json';
import automation from './automation.json';
import cdrsession from './cdrsession.json';
import common from './common.json';
import commonAttributes from './commonAttributes.json';
import configuration from './configuration.json';
import countries from './countries.json';
import currency from './currency.json';
import dashboard from './dashboard.json';
import dealer from './dealer.json';
import device from './device.json';
import enterprise from './enterprise.json';
import extension from './extension.json';
import group from './group.json';
import invoice from './invoice.json';
import navigation from './navigation.json';
import order from './order.json';
import policy from './policy.json';
import product from './product.json';
import profile from './profile.json';
import qrapp from './qrapp.json';
import realEstateIt from './realEstateIt.json';
import report from './report.json';
import reporting from './reporting.json';
import roles from './roles.json';
import route from './route.json';
import service from './service.json';
import servicerequest from './servicerequest.json';
import sim from './sim.json';
import sms from './sms.json';
import smshub from './smshub.json';
import subPortal from './subPortal.json';
import support from './support.json';
import uiconfig from './uiconfig.json';
import unit from './unit.json';
import user from './user.json';
import validation from './validation.json';
import vpnServer from './vpnServer.json';
import workload from './workload.json';

export const de = {
  realEstateIt,
  accessendpoint,
  apnhub,
  asset,
  auth,
  automation,
  cdrsession,
  common,
  commonAttributes,
  reporting,
  dashboard,
  dealer,
  device,
  enterprise,
  group,
  invoice,
  navigation,
  subPortal,
  order,
  policy,
  product,
  profile,
  report,
  route,
  roles,
  service,
  servicerequest,
  sim,
  sms,
  smshub,
  support,
  unit,
  user,
  validation,
  countries,
  currency,
  extension,
  workload,
  uiconfig,
  configuration,
  vpnServer,
  qrapp,
};
