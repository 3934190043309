import { Button } from '@mui/material';
import { snackbar } from 'components/Snackbar';
import { IntIntelligenceEventTableDto } from 'generated';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IntelligenceEventAPI } from 'services/intelligenceEvent.service';
import { usePost } from 'shared/api/hooks';
import { useMutate } from '../MutationContext';

interface IProps {
  event: IntIntelligenceEventTableDto;
}

const AckEventButton: React.FC<IProps> = ({ event }) => {
  const { t } = useTranslation('dashboard');
  const mutate = useMutate<IntIntelligenceEventTableDto>();
  const [ackEvent, isAcking] = usePost(IntelligenceEventAPI.acknowledgeEvent);

  const handleAckClick = async () => {
    const resp = await ackEvent({ params: event.intelligenceEventId });
    if (
      resp.status === 200 ||
      resp.status === 304 // Not modified (maybe someone else acknowledged)
    ) {
      mutate({
        ...event,
        acknowledgedDate: new Date(),
      });
    } else {
      snackbar(resp.statusText || 'Error acknowledging event', {
        variant: 'error',
      });
    }
  };

  if (event.acknowledgedDate) {
    return null;
  }

  return (
    <Button
      onClick={handleAckClick}
      disabled={isAcking}
      color="primary"
      style={{ fontSize: '0.6rem', padding: '2px 0px' }}
      variant="contained"
    >
      {t('intelligence_events.actions.acknowledge')}
    </Button>
  );
};

export default AckEventButton;
