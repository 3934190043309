import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import { useField } from 'formik';
import React, { useState } from 'react';
import { useStyles } from './PasswordField.styles';
import PasswordVisibilityIcon from './PasswordVisibilityIcon/PasswordVisibilityIcon';

interface IProps {
  name: string;
  label: string;
  placeholder?: string;
  rows?: string | number;
  required?: boolean;
  autoFocus?: boolean;
  helperText?: string;
  startAdornment?: JSX.Element;
}

const PasswordField: React.FC<IProps> = ({
  name,
  label,
  placeholder,
  rows,
  required,
  autoFocus,
  helperText,
  startAdornment,
}) => {
  const classes = useStyles();
  const [field, meta] = useField(name);
  const [visible, setVisible] = useState(false);

  const handleMouseDownPassword = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
  };

  return (
    <FormControl
      variant="outlined"
      fullWidth
      error={meta.touched && !!meta.error}
      className={classes.root}
    >
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <OutlinedInput
        id={name}
        type={visible ? 'text' : 'password'}
        autoFocus={autoFocus}
        placeholder={placeholder}
        rows={rows}
        required={required}
        label={label}
        inputProps={{
          'data-cy': name,
        }}
        startAdornment={
          startAdornment ? (
            <InputAdornment position="start">{startAdornment}</InputAdornment>
          ) : undefined
        }
        endAdornment={
          <PasswordVisibilityIcon
            visible={visible}
            handleClickShowPassword={() => setVisible(!visible)}
            handleMouseDownPassword={handleMouseDownPassword}
          />
        }
        {...field}
      />
      {((meta.touched && meta.error) || helperText) && (
        <FormHelperText id={`${name}-helper-text`} className={classes.subText}>
          {helperText || meta.error}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default PasswordField;
