import { ConnectedStatus } from 'generated';
import i18n from 'i18next';
import { Column } from 'react-table';
import { stylingNames } from '../Columns.styles';
import { CustomCell } from '../Helpers/CustomCell';
import { ICustomCellProps } from '../Helpers/CustomCellProps';
import { getOptionFilter } from '../Helpers/getOptionFilter';

export function terminalConnectionState<T>(options: {
  id: string;
  accessor: (row: T) => ConnectedStatus;
  header?: string;
  defaultShow?: boolean;
  filterable?: boolean;
  sortable?: boolean;
  connectedLabel?: string;
  notConnectedLabel?: string;
}): Column<T> {
  const {
    id,
    header = 'Status',
    accessor,
    defaultShow: show = true,
    filterable,
    sortable,
    notConnectedLabel,
    connectedLabel,
  } = options;

  const cellByStatus: Record<ConnectedStatus, ICustomCellProps> = {
    [ConnectedStatus.Connected]: {
      iconType: 'StatusOk',
      variant: 'success',
      text: i18n.t(connectedLabel || 'label.connected'),
    },
    [ConnectedStatus.NotConnected]: {
      iconType: 'StatusError',
      variant: 'error',
      text: i18n.t(notConnectedLabel || 'label.not_connected'),
    },
    [ConnectedStatus.PartlyConnected]: {
      iconType: 'StatusWarning',
      variant: 'error',
      text: i18n.t(notConnectedLabel || 'label.partly_connected'),
    },
    [ConnectedStatus.Unknown]: {
      iconType: 'StatusUnknown',
      variant: 'error',
      text: i18n.t(notConnectedLabel || 'label.unknown'),
    },
  };

  return {
    id,
    Header: header,
    accessor,
    className: stylingNames.textCenter,
    resizable: false,
    maxWidth: 140,
    show,
    filterable,
    sortable,
    Cell: row => {
      const iconProps = cellByStatus[row.value as ConnectedStatus] || {};
      return <CustomCell {...iconProps} index={row.index} />;
    },
    Filter: getOptionFilter([
      {
        label: i18n.t('label.status_all'),
        value: '',
      },
      {
        label: i18n.t(connectedLabel || 'label.connected'),
        value: 'true',
      },
      {
        label: i18n.t(notConnectedLabel || 'label.not_connected'),
        value: 'false',
      },
    ]),
  };
}
