import { Box } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { FilterProps } from './FilterProps';

interface MinMaxFilterValue {
  min?: string;
  max?: string;
}
export const MinMaxFilter: React.FC<FilterProps<MinMaxFilterValue>> = ({
  filter,
  onChange,
}) => {
  const initialValue = useRef(filter?.value || { min: '', max: '' });

  const [min, setMin] = useState(() => filter?.value?.min || '');
  const [max, setMax] = useState(() => filter?.value?.max || '');

  useEffect(() => {
    setMin(filter?.value?.min || '');
    setMax(filter?.value?.max || '');
  }, [filter?.value]);

  const handleBlur = () => {
    if (initialValue.current.min !== min || initialValue.current.max !== max) {
      onChange({ min, max });
    }
  };

  return (
    <Box display="flex">
      <input
        className="w-50 mr-1"
        value={min}
        type="text"
        onBlur={handleBlur}
        onChange={e => setMin(e.target.value)}
      />
      <input
        className="w-50 ml-1"
        value={max}
        type="text"
        onBlur={handleBlur}
        onChange={e => setMax(e.target.value)}
      />
    </Box>
  );
};
