import { IntPolicyDto, IntPolicyUsageDto } from 'generated';
import {
  DeleteEndpoint,
  GetEndpoint,
  PostEndpoint,
  PutEndpoint,
} from 'shared/interfaces/api';
import { policyDemoData } from './demoData/policyDemoData';

export interface IntCreateDashboardPolicyDto {
  name: string;
  customerId: string;
}

interface IDashboardPolicyAPI {
  getAll: GetEndpoint<undefined, IntPolicyDto[]>;
  createPolicy: PostEndpoint<
    undefined,
    IntCreateDashboardPolicyDto,
    IntPolicyDto
  >;
  updatePolicy: PutEndpoint<string, IntPolicyDto>;
  deletePolicy: DeleteEndpoint<string>;
  getPolicyUsage: GetEndpoint<string, IntPolicyUsageDto[]>;
}

const baseUrl = '/api/policy';

export const dashboardPolicyApi: IDashboardPolicyAPI = {
  getAll: {
    url: baseUrl,
    mockData: policyDemoData().getAll,
  },
  createPolicy: {
    url: baseUrl,
    mockData: policyDemoData().createPolicy,
  },
  updatePolicy: {
    url: policyId => `${baseUrl}/${policyId}`,
    mockData: policyDemoData().updatePolicy,
  },
  deletePolicy: {
    url: policyId => `${baseUrl}/${policyId}`,
    mockData: policyDemoData().deletePolicy,
  },
  getPolicyUsage: {
    url: policyId => `${baseUrl}/${policyId}/usage`,
    mockData: () => ({ status: 204 }),
  },
};
