import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => {
  // Create an object with color CSS variables from theme colors, eg. { "--red": #FF0000; } (for widget headers right now, will need a rework)
  const colorCSSVariables = Object.entries(theme.settings.colors).reduce(
    (colors, [colorName, colorValue]) => ({
      ...colors,
      [`--${colorName}`]: colorValue,
    }),
    {}
  );

  return createStyles({
    '@global': {
      'html, body, #root': {
        height: '100%',
        minHeight: '100%',
      },
      'html, html *': {
        scrollbarWidth: 'thin',
      },
      ':root': colorCSSVariables,
      '.p-0': {
        padding: '0 !important',
      },
      '.text-center': {
        textAlign: 'center',
      },
      '.text-right': {
        textAlign: 'right',
      },
      '.text-muted': {
        color: '#929292',
      },
      '.text-unit': {
        fontSize: '85%',
        paddingLeft: 4,
        color: '#8a8a8a',
        fontWeight: 'normal',
        lineHeight: 'inherit',
      },
      '.cursor-move': {
        cursor: 'move',
      },
      '.cursor-pointer': {
        cursor: 'pointer',
      },
      '::-webkit-scrollbar': {
        width: 8,
        height: 8,
      },
      '::-webkit-scrollbar-track': {
        background: 'transparent',
      },
      '::-webkit-scrollbar-thumb': {
        background: 'rgba(155,155,155,0.65)',
        borderRadius: 9,
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: 'rgba(155,155,155,0.9)',
      },
    },
  });
});

const GlobalStyles: React.FC = () => {
  useStyles();
  return null;
};

export default GlobalStyles;
