import { Box } from '@mui/material';
import classNames from 'classnames';
import AtlasIcon from 'components/AtlasIcon';
import React from 'react';
import { useStyles } from './FilterListItem.styles';

interface IProps {
  isActive: boolean;
  label: string;
  extraInfo?: string | JSX.Element | null;
  onClick?: () => void;
  className?: string;
  dataCy?: string;
}

const FilterListItem: React.FC<IProps> = ({
  label,
  extraInfo,
  isActive,
  onClick,
  className,
  dataCy,
}) => {
  const classes = useStyles();

  return (
    <Box data-cy={dataCy} className={classes.listItem} onClick={onClick}>
      <Box
        className={classNames(classes.item, {
          [classes.activeItem]: isActive,
        })}
      >
        <span className={classNames(classes.label, className)}>{label}</span>
        {extraInfo && (
          <span
            className={classNames(classes.extraInfo, {
              [classes.activeItem]: isActive,
            })}
          >
            {extraInfo}
          </span>
        )}
        {!isActive && (
          <AtlasIcon className={classes.icon} type="ChevronRight" size={16} />
        )}
      </Box>
    </Box>
  );
};

export default FilterListItem;
