import { data, HtmlMarker } from 'azure-maps-control';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import teliaIcons from 'shared/icons/telia-icons';
import { AtlasIconType } from 'shared/types/atlas-icon';
import { useStyles } from './HtmlMapPin.styles';

type IOptions = {
  id: string;
  position: data.Position;
  redCount: number;
  isSelected?: boolean;
} & (IClusteredOptions | ISingleOptions);

interface IClusteredOptions {
  variant: 'cluster';
  count: number;
}

interface ISingleOptions {
  variant: 'single';
  iconType: AtlasIconType;
}

export const SvgIcon: React.FC<{
  iconType?: AtlasIconType;
  className: string;
}> = ({ iconType, className }) => {
  if (!iconType) {
    return null;
  }

  const Svg = teliaIcons[iconType]; // Save a DOM element by using the svg straight up, without AtlasIcon wrapper

  return <Svg className={className} />;
};

export function useGetMapPin() {
  const classes = useStyles();

  return useCallback(
    (opts: IOptions) => {
      const { id, position, redCount, isSelected, variant } = opts;

      const htmlContent = renderToStaticMarkup(
        <div
          id={id}
          className={classNames(classes.root, {
            [classes.cluster]: variant === 'cluster',
          })}
        >
          {redCount > 0 && (
            <div className={classes.notification}>{redCount}</div>
          )}
          {opts.variant === 'cluster' ? (
            opts.count
          ) : (
            <SvgIcon
              className={classNames(classes.icon, {
                [classes.selected]: isSelected,
              })}
              iconType={opts.iconType}
            />
          )}
        </div>
      );

      return new HtmlMarker({
        position,
        anchor: variant === 'cluster' ? 'center' : 'bottom',
        htmlContent,
      });
    },
    [classes]
  );
}
