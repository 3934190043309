import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    button: {
      alignSelf: 'start',
      margin: '5px 0 0 20px',
    },
    dialogTitle: {
      color: theme.palette.common.black,
      padding: theme.spacing(8),
      paddingBottom: theme.spacing(4),
      alignItems: 'center',
    },
  })
);
