import { Direction } from '@mui/material';
import React from 'react';

interface IProps {
  children?: React.ReactNode;
  dir?: Direction;
  keepMounted?: boolean;
  index: number;
  value: any;
}

const TabPanel: React.FC<IProps> = props => {
  const { children, value, index, dir = 'ltr', keepMounted = false } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      dir={dir}
    >
      {(value === index || keepMounted) && children}
    </div>
  );
};
export default TabPanel;
