export const muiViewbox = '0 0 24 24';
export const flagViewbox = '0 0 512.002 512.002';
export const simViewbox = '0 0 42 60';
export const statusErrorViewbox = '0 0 19 19';
export const statusOkViewbox = '0 0 19 19';
export const statusPauseViewbox = '0 0 19 19';
export const statusPendingViewbox = '0 0 19 19';
export const statusUnknownViewbox = '0 0 19 19';
export const statusWarningViewbox = '0 0 18 19';
export const hubViewbox = '0 0 74 74';
export const externalLinkViewbox = '0 0 16 16';
export const applicationViewbox = '0 0 65 65';
export const customFlagViewbox = '0 0 513 342';
