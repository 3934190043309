import { Box } from '@mui/material';
import AtlasIcon from 'components/AtlasIcon';
import AtlasTooltip from 'components/AtlasTooltip';
import { ConnectedStatus } from 'generated';
import i18n from 'i18next';
import { Column } from 'react-table';
import { CustomCell } from '../Helpers/CustomCell';
import { ICustomCellProps } from '../Helpers/CustomCellProps';
import { FilterOption } from '../Helpers/filterOption';
import { getOptionFilter } from '../Helpers/getOptionFilter';

export function connectionStatus<T>(options: {
  id: string;
  accessor: (row: T) => ConnectedStatus;
  header?: string;
  defaultShow?: boolean;
  filterable?: boolean;
  sortable?: boolean;
  tooltip?: boolean;
  tooltipText?: string;
}): Column<T> {
  const {
    id,
    header = 'Connected',
    accessor,
    defaultShow: show,
    filterable,
    sortable,
    tooltip = false,
    tooltipText = '',
  } = options;

  const cellByStatus: Record<ConnectedStatus, ICustomCellProps> = {
    [ConnectedStatus.Unknown]: {
      iconType: 'Alert',
      variant: 'warning',
      title: i18n.t('label.unknown'),
    },
    [ConnectedStatus.Connected]: {
      iconType: 'Status',
      variant: 'success',
      title: i18n.t('label.connected'),
    },
    [ConnectedStatus.NotConnected]: {
      iconType: 'Status',
      variant: 'error',
      title: i18n.t('label.not_connected'),
    },
    [ConnectedStatus.PartlyConnected]: {
      iconType: 'Alert',
      variant: 'warning',
      title: i18n.t('label.partly_connected'),
    },
  };

  const filterOptions: FilterOption[] = [
    { label: i18n.t('common:all'), value: '' },
    { label: i18n.t('common:label.unknown'), value: ConnectedStatus.Unknown },
    {
      label: i18n.t('common:label.connected'),
      value: ConnectedStatus.Connected,
    },
    {
      label: i18n.t('common:label.not_connected'),
      value: ConnectedStatus.NotConnected,
    },
    {
      label: i18n.t('common:label.partly_connected'),
      value: ConnectedStatus.PartlyConnected,
    },
  ];

  return {
    id,
    Header: tooltip ? (
      <AtlasTooltip placement="top" title={tooltipText} arrow>
        <Box>
          {header}
          <AtlasIcon size={14} type="Info" />
        </Box>
      </AtlasTooltip>
    ) : (
      header
    ),
    accessor,
    resizable: false,
    maxWidth: 130,
    filterable,
    sortable,
    show,
    Cell: row => {
      const iconProps = cellByStatus[row.value as ConnectedStatus] || {};
      return <CustomCell {...iconProps} index={row.index} />;
    },
    Filter: getOptionFilter(filterOptions),
  };
}
