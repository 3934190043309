import { subDays } from 'date-fns';
import {
  ApnProvisioningStatus,
  FirewallRuleAction,
  IntActiveSessionTableDto,
  IntApnHubFirewallRuleDto,
  IntDetailsDto,
  IntHistoricalSessionTableDto,
  IntProfileDto,
  IntStateDto,
  IntSubnetDto,
  IntUpdateProfileDto,
  ProfileDescriptorStatus,
  SmsTraffic,
} from 'generated';
import { IResponse } from 'shared/interfaces/api';
import { rnd } from 'utils';
import { connectivityUnitDemoData } from './connectivityUnitDemoData';
import { ipAccessDemoData } from './ipAccessDemoData';

class ApnHubDemoData {
  private _sessionsTable: IntActiveSessionTableDto[] | undefined;
  private _historicalSessionsTable: IntHistoricalSessionTableDto[] | undefined;
  private demoDataTraffic = false;
  private readonly demoProfileNames = [
    'Block all traffic',
    'Full access to internet',
    'Block Netflix',
    'Allow Netflix',
    'Allow social media',
    'Block social media',
    'Allow swedish youtube',
  ];

  demoApnProfiles: IntProfileDto[] = rnd.array(
    rnd.int(2, this.demoProfileNames.length),
    i => ({
      profileId: `0fe561ae-f4k3-4pnn-${i
        .toString()
        .padStart(4, '0')}-20693c9dfa59`,
      name: this.demoProfileNames[i],
      global: rnd.bool(),
      default: i === 0,
      profileDescriptor: {
        nameservers: [rnd.ip()],
        defaultEnterpriseVpnUuid:
          i === 0 ? undefined : ipAccessDemoData.getDefaultVpn()?.uuid,
        stickyIpAllocation: rnd.bool(),
        status:
          i % 2
            ? ProfileDescriptorStatus.Ready
            : rnd.item(
                ProfileDescriptorStatus.Pending,
                ProfileDescriptorStatus.Error,
                ProfileDescriptorStatus.Unknown
              ),
        statusMessages: [],
        statusTrace: rnd.charSequence(32),
        firewallRules: {
          outbound: makeRandomFirewallRules(),
          inbound: [],
          breakout: makeRandomFirewallRules(),
        },
      },
    })
  );

  demoApnState: IntStateDto = {
    provisioningStatus: ApnProvisioningStatus.Ready,
    operationalStatus: rnd.item('Active', 'Blocked'),
    deviceCount: connectivityUnitDemoData?.units?.length,
  };

  demoApnSubnets = (): IntSubnetDto[] => {
    const subnets: IntSubnetDto[] = rnd.array<IntSubnetDto>(5, () => ({
      address: rnd.ip(),
      prefixLength: rnd.int(0, 10),
      activeDevices: 0,
    }));
    connectivityUnitDemoData.units.forEach(() => {
      rnd.item(...subnets).activeDevices++;
    });

    return subnets;
  };

  demoApnDetails: IntDetailsDto = {
    apnName: rnd.item(
      '5g.teliaiot.com',
      'gcxcglobal2014.com',
      'dev-manage.teliaiot.com'
    ),
    regionName: rnd.item('eu-north-1', 'eu-north-2', 'eu-west-1', 'eu-west-2'),
    profileName: this.demoApnProfiles[0].name,
    gatewayName: `Gateway ${rnd.int(0, 5)}`,
    gatewayEndpoints: rnd.array<string>(rnd.int(1, 4), () => rnd.ip()),
    gatewayRegion: rnd.country(),
  };

  get historicalSessions() {
    this._historicalSessionsTable = rnd.array<IntHistoricalSessionTableDto>(
      rnd.int(10, connectivityUnitDemoData.units.length),
      i => {
        const unit = connectivityUnitDemoData.units[i];
        return {
          connectivityUnitId: unit.connectivityUnitId,
          identifier: unit.identifier,
          label: unit.label,
          session: {
            general: {
              ended: subDays(new Date(), 1),
              started: subDays(new Date(), 2),
              lastUpdated: subDays(new Date(), 1),
              ipAddress: rnd.ip(),
              imsi: rnd.imsi(),
              msisdn: rnd.msisdn(),
              apn: 'gcxcglobal2020.com',
              primaryDns: rnd.ip(),
              secondaryDns: rnd.ip(),
              iPv6DNSNameServers: rnd.ip(),
              ratType: rnd.ratType(),
              blocked: true,
              terminating: false,
              homeMCCMNC: '23820',
              visitedMCCMNC: '24001',
            },
            usage: {
              timestamp: subDays(new Date(), 1),
              totalBytes: rnd.int(1000, 999999),
              inputBytes: rnd.kb(),
              outputBytes: rnd.b(),
              inputPackets: rnd.int(1, 99999),
              outputPackets: rnd.int(1, 99999),
              sessionRuntime: 61200,
            },
            uuid: {
              apnHubSessionUUID: rnd.guid(),
              apnHubDeviceUUID: rnd.guid(),
            },
          },
        };
      }
    );

    return this._historicalSessionsTable;
  }

  setDataTrafficStatus = (params: {
    connectivityUnitId: string;
    blockDataTraffic: boolean;
  }) => {
    const response: IResponse = {
      status: 200,
    };
    if (response.status === 200) {
      this.demoDataTraffic = params.blockDataTraffic;
    }
    return response;
  };

  updateDefaultEnterpriseProfile = (
    params: string,
    data: IntUpdateProfileDto | undefined
  ): IResponse<IntProfileDto | undefined> => ({
    status: 200,
    data: this.demoApnProfiles.find(p => p.profileId === data?.profileId),
  });

  getDetails = (): IResponse<IntDetailsDto> => ({
    status: 200,
    data: this.demoApnDetails,
  });

  getState = (): IResponse<IntStateDto> => ({
    status: 200,
    data: this.demoApnState,
  });

  getSubnets = (): IResponse<IntSubnetDto[]> => ({
    status: 200,
    data: this.demoApnSubnets(),
  });

  getProfiles = (): IResponse<IntProfileDto[]> => ({
    status: 200,
    data: this.demoApnProfiles,
  });

  getTrafficStatus = () => ({
    data: {
      agreedSince: new Date(),
      smsTraffic: rnd.enum(SmsTraffic),
    },
    status: 200,
  });
}

export const makeRandomFirewallRules = (
  inbound = false
): IntApnHubFirewallRuleDto[] =>
  rnd.array(rnd.int(2, 5), i => ({
    action:
      i % 2
        ? FirewallRuleAction.Accept
        : rnd.item(FirewallRuleAction.Drop, FirewallRuleAction.Reject),
    destination: inbound
      ? []
      : rnd.array(rnd.int(1, 3), int => `${rnd.ip()}/${rnd.int(int, 99)}`),
    protocol: rnd.item('tcp/ip', 'all', 'icmp', 'tcp', 'udp'),
    name: `Rule ${i + 1}`,
    port: [rnd.int(1000, 99999)],
    source: inbound
      ? rnd.array(rnd.int(1, 3), int => `${rnd.ip()}/${rnd.int(int, 99)}`)
      : [],
  }));

export const apnHubDemoData = new ApnHubDemoData();
