import { Box, Link, List, ListItem } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouteLink } from 'react-router-dom';
import { useStyles } from './AtlasValue.styles';

type ValueType = string[] | string | JSX.Element | number;
export type VariantType = 'danger' | 'warning' | 'success' | 'disabled';

export interface IAtlasValueProps extends IListTypes {
  name?: string;
  value?: ValueType;
  variant?: VariantType;
  uppercase?: boolean;
  icon?: JSX.Element;
  bold?: boolean | boolean[];
  link?: string | (string | undefined)[];
  className?: string;
  maxHeight?: number;
}

interface IListTypes {
  type?: 'ol' | 'ul' | 'scrollable';
}

interface IListItemProps {
  text: string;
  link?: string;
  bold?: boolean;
}

interface IListVariantProps extends IListTypes {
  items: IListItemProps[];
  maxHeight: number;
}

interface IWrapperProps {
  link?: string;
}

const ListVariant: React.FC<IListVariantProps> = ({
  type,
  items,
  maxHeight,
}) => {
  const classes = useStyles();
  const children = items.map((item, index) => {
    const formattedItem = item.bold ? <strong>{item.text}</strong> : item.text;
    return type === 'scrollable' ? (
      <ListItem key={item.text} dense>
        {item.text}
      </ListItem>
    ) : (
      <li key={index}>
        <Wrapper link={item.link}>{formattedItem}</Wrapper>
      </li>
    );
  });
  switch (type) {
    case 'scrollable':
      return (
        <div style={{ maxHeight }} className={classes.scrollableList}>
          <List>{children}</List>
        </div>
      );
    case 'ol':
      return <ol className={classes.list}>{children}</ol>;
    default:
      return <ul className={classes.list}>{children}</ul>;
  }
};

const Wrapper: React.FC<IWrapperProps> = ({ link, children }) => {
  if (link) {
    return (
      <Link to={link} component={RouteLink} underline="always">
        {children}
      </Link>
    );
  }
  return <>{children}</>;
};

const AtlasValue: React.FC<IAtlasValueProps> = ({
  name,
  value,
  variant,
  uppercase,
  icon,
  bold,
  type,
  link,
  className,
  maxHeight = 300,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  let mappedValue;

  if (Array.isArray(value)) {
    const items: IListItemProps[] = value.map((item, index) => ({
      text: item,
      link:
        Array.isArray(link) && index < link.length ? link[index] : undefined,
      bold: Array.isArray(bold) && bold[index],
    }));
    mappedValue = (
      <ListVariant type={type} items={items} maxHeight={maxHeight} />
    );
  } else if (value === '' || value === null || value === undefined) {
    mappedValue = t('not_applicable_abbreviation');
  } else {
    mappedValue = (
      <Wrapper link={typeof link === 'string' ? link : undefined}>
        {bold ? <strong>{value}</strong> : value}
      </Wrapper>
    );
  }

  const valueClasses = classNames({
    [classes.danger]: variant === 'danger',
    [classes.warning]: variant === 'warning',
    [classes.success]: variant === 'success',
    [classes.disabled]: variant === 'disabled',
    [classes.uppercase]: uppercase,
  });

  const title = ['string', 'number'].includes(typeof value)
    ? value?.toString()
    : undefined;

  return (
    <Box display="flex" alignItems="center" justifyContent="space-apart">
      {icon && <Box marginRight={1}>{icon}</Box>}
      <div
        data-cy={name}
        className={classNames(
          classes.root,
          valueClasses,
          classes.textOverflowEllipsis,
          className
        )}
        title={title}
      >
        {mappedValue}
      </div>
    </Box>
  );
};

export default AtlasValue;
