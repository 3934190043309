import { FieldProps } from 'formik';
import React from 'react';

const Radio: React.FC<FieldProps & { label: string; value: any }> = ({
  field: { name },
  form,
  label,
  value,
}) => {
  const id = `${name}_${value}`;

  return (
    <div className="custom-control custom-radio custom-control-inline">
      <input
        className="custom-control-input"
        type="radio"
        name={name}
        id={id}
        checked={value === form.values[name]}
        onChange={() => form.setFieldValue(name, value)}
      />
      <label className="custom-control-label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

export default Radio;
