import {
  ConfigurationSetting,
  featureFlagPrefix,
  FeatureFlagValue,
  isFeatureFlag,
  parseFeatureFlag,
} from '@azure/app-configuration';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { appInsights } from 'appInsights';
import { Role } from 'components/Auth/Role';
import {
  isFeature,
  isFeaturePascal,
  isTargetingClientFilter,
  isTimeWindowClientFilter,
} from 'components/FeatureFlag';
import { FeatureFilter } from 'components/FeatureFlag/CreateFeatureFilterModal/CreateFeatureFilterModal';
import {
  FeatureToggles,
  FeatureTogglesPascal,
} from 'components/FeatureFlag/Feature';
import { snackbar } from 'components/Snackbar';
import { isWithinInterval } from 'date-fns';
import i18n from 'i18n';
import { camelCase, mapKeys } from 'lodash';
import {
  action,
  autorun,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { featureService } from 'services/feature.service';
import { StoreBase } from 'store/storeBase';
import { RootStore } from './rootStore';

export enum FeatureAction {
  TOGGLE = 'toggle',
  EDIT = 'edit',
  ADVANCEDEDIT = 'advancededit',
  DELETE = 'delete',
}

/**
 * Store to handle everything feature related
 * Fetches all features every 15 minutes for now.
 */
export class FeatureStore extends StoreBase {
  @observable features: ConfigurationSetting<FeatureFlagValue>[] = [];
  @observable isLoadingFeatures = false;
  disposer;
  @observable openModal: {
    [Key in FeatureAction]: boolean;
  } = {
    advancededit: false,
    edit: false,
    delete: false,
    toggle: false,
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);

    // Not sure this is the best solution, but does the trick.
    this.disposer = autorun(
      () => {
        this.getFeatures();
      },
      {
        scheduler: run => {
          setInterval(run, 15 * 60000);
        },
      }
    );
  }

  dispose() {
    this.disposer();
  }

  /**
   * Used by the useFeatureFlag hook for single features as well as by the FeatureFlag wrapper
   */
  @computed get featureMap() {
    const list = Object.fromEntries(
      this.features
        .filter(f => isFeature(f.key.replace(featureFlagPrefix, '')))
        .map(f => this.filterFeatures(f))
    );

    return list as FeatureToggles;
  }

  /** Used by the useFeatureFlags hook that returns "typed" features */
  @computed get featureList() {
    const list = Object.fromEntries(
      this.features
        .filter(f => isFeature(f.key.replace(featureFlagPrefix, '')))
        .map(f => this.filterFeatures(f))
        .map(([key, value]) => {
          const pascalKey = key
            .replace(featureFlagPrefix, '')
            .split('.')
            .map(e => e.charAt(0).toUpperCase() + e.slice(1))
            .join('');

          if (isFeaturePascal(pascalKey)) {
            return [pascalKey, value];
          }
          return [key, false];
        })
    );

    return list as FeatureTogglesPascal;
  }

  @action.bound setOpenModal(modal: FeatureAction) {
    this.openModal[modal] = true;
  }

  @action.bound closeModal() {
    this.openModal = {
      advancededit: false,
      delete: false,
      edit: false,
      toggle: false,
    };
    this.setSelectedFeatureKey('');
  }

  @observable selectedFeatureKey: string = '';

  @action.bound setSelectedFeatureKey(key: string) {
    this.selectedFeatureKey = key;
  }

  @computed get selectedFeature() {
    return this.features.find(f => f.key === this.selectedFeatureKey);
  }

  @computed get selectedFeatureFilters() {
    return (
      (this.selectedFeature?.value.conditions
        .clientFilters as unknown as FeatureFilter[]) ?? []
    );
  }

  @action.bound setIsLoadingFeatures(loading: boolean) {
    this.isLoadingFeatures = loading;
  }

  /**
   * Get all features from backend and save them in store
   * Down the line this should be triggered by an event from backend or something similar and not on an interval.
   */
  @action.bound async getFeatures() {
    const resp = await this.httpGet(
      featureService.getFeatures,
      undefined,
      this.setIsLoadingFeatures
    );

    runInAction(() => {
      if (resp.data && resp.status === 200) {
        this.features = resp.data
          .map(this.convertToCamelCase)
          .filter(isFeatureFlag)
          .map(parseFeatureFlag);
      } else {
        appInsights.trackException({
          exception: new Error('Failed to get features'),
          properties: {
            response: resp,
          },
          severityLevel: SeverityLevel.Critical,
        });
      }
    });
  }

  /**
   * Get a specific feature from backend and return it.
   * onlyIfChanged checks the eTag value (in backend) and only gets the new value if it has been updated. Default is false.
   */
  @action.bound async getFeature(key: string, onlyIfChanged = false) {
    const resp = await this.httpGet(
      featureService.getFeature,
      {
        key,
        onlyIfChanged,
      },
      this.setIsLoadingFeatures
    );

    if (resp.status === 200 && resp.data && isFeatureFlag(resp.data)) {
      return parseFeatureFlag(this.convertToCamelCase(resp.data));
    } else {
      try {
        // If the call fails, see if we can return the same feature from the local store
        return this.features.find(f => f.key === key);
      } catch (error) {
        appInsights.trackException({
          exception: new Error('Failed to get feature'),
          properties: {
            response: resp,
            error: error,
            key: key,
          },
          severityLevel: SeverityLevel.Warning,
        });
        return undefined;
      }
    }
  }

  /**
   * Takes a key and a modified filter and passes it to the updateFeature method.
   * @param key The key of the feature to update
   * @param filters The updated filter
   */
  @action.bound async updateFilter(key: string, filters: FeatureFilter[]) {
    const feat = this.features.find(f => f.key === key);
    if (feat) {
      const modFeat: ConfigurationSetting = {
        ...feat,
        value: JSON.stringify({
          ...feat.value,
          conditions: {
            client_filters: filters,
          },
        }),
      };

      const resp = await this.updateFeature(key, modFeat);
      if (resp.status === 200) {
        snackbar(i18n.t('feature.filter.updated'), { variant: 'success' });
      } else {
        snackbar(i18n.t('feature.filter.error'), {
          variant: 'error',
        });
      }
    } else {
      snackbar(i18n.t('feature.error'), {
        variant: 'error',
      });
    }
  }

  /**
   * Toggle a feature and then sync its value / all features
   */
  @action.bound async toggleFeature(
    key: string,
    config: ConfigurationSetting<FeatureFlagValue>
  ) {
    const modifiedFeature: ConfigurationSetting = {
      ...config,
      value: JSON.stringify({
        ...config.value,
        conditions: {
          client_filters: config.value.conditions.clientFilters, // parseFeatureFlag changes client_filters to clientFilters so we need to change it back!
        },
        enabled: !config.value.enabled,
      }),
    };

    const resp = await this.updateFeature(key, modifiedFeature);

    runInAction(() => {
      if (resp.data && resp.status === 200) {
        snackbar(
          i18n.t('feature.toggle.success', {
            context: `${!config.value.enabled}`,
            feature: config.value.id,
          }),
          { variant: 'success' }
        );
      } else {
        snackbar(
          i18n.t('feature.toggle.error', {
            context: `${config.value.enabled}`,
            feature: config.value.id,
          }),
          {
            variant: 'error',
          }
        );
      }
    });
  }

  /**
   * Reusable action that sends requests to backend on behalf of all other feature actions
   */
  @action.bound async updateFeature(key: string, config: ConfigurationSetting) {
    const resp = await this.httpPut(
      featureService.updateFeature,
      {
        params: key,
        data: config,
      },
      this.setIsLoadingFeatures
    );

    runInAction(() => {
      if (resp.data && resp.status === 200) {
        const mid = this.convertToCamelCase(resp.data);
        if (isFeatureFlag(mid)) {
          const parsed = parseFeatureFlag(mid);

          try {
            this.features.splice(
              this.features.findIndex(f => f.key === parsed.key),
              1,
              parsed
            );
          } catch (error) {
            this.getFeatures();
          }
        }
      }
    });
    this.closeModal();
    return resp;
  }

  @action.bound async actionHandler(
    action: FeatureAction,
    config: ConfigurationSetting<FeatureFlagValue>
  ) {
    switch (action) {
      case FeatureAction.TOGGLE:
        return this.toggleFeature(config.key, config);
      case FeatureAction.EDIT:
        this.setSelectedFeatureKey(config.key);
        return this.setOpenModal(FeatureAction.EDIT);
      case FeatureAction.DELETE:
        this.setSelectedFeatureKey(config.key);
        return this.setOpenModal(FeatureAction.DELETE);
      case FeatureAction.ADVANCEDEDIT:
      default:
        break;
    }
  }

  @computed get authCheck() {
    // Only one role for now, adjust as needed
    const enabled = this.rootStore.authStore.hasRole(
      Role.EditFeatureManagement
    );
    return {
      TOGGLE: enabled,
      EDIT: enabled,
      ADVANCEDEDIT: false, // NA
      DELETE: enabled,
    };
  }

  /**
   * This is a temporary solution for the unfortunate fact that backend returns the object PascalCased.
   * @param input The response from backend, currently PascalCased
   * @returns The provided ConfigurationSetting, properly camelCased.
   */
  private convertToCamelCase(
    config: ConfigurationSetting
  ): ConfigurationSetting {
    return mapKeys(config, (v, k) =>
      camelCase(k)
    ) as unknown as ConfigurationSetting;
  }

  /**
   * Handles feature filtering on frontend
   * Currently checks if the logged in user is included in any Targeting filters (by customerId, userId and userName) as well
   * as if any TimeWindow filters are active. Custom filters are not supported.
   */
  private filterFeatures(
    feature: ConfigurationSetting<FeatureFlagValue>
  ): [string, boolean] {
    const clientFilter = feature.value.conditions?.clientFilters?.[0];
    const key = feature.key.replace(featureFlagPrefix, '');

    if (isTimeWindowClientFilter(clientFilter)) {
      const withinRange = isWithinInterval(new Date(), {
        start: new Date(clientFilter.parameters.Start ?? 0),
        end: clientFilter.parameters.End
          ? new Date(clientFilter.parameters.End)
          : new Date().setFullYear(9999),
      });

      return [key, withinRange];
    }

    if (isTargetingClientFilter(clientFilter)) {
      if (this.rootStore.authStore.user) {
        const { customerId, userId, userName } = this.rootStore.authStore.user;

        const inGroup = clientFilter.parameters.Audience.Groups.some(
          g => g.Name === customerId
        );

        const inUsers = clientFilter.parameters.Audience.Users.some(
          u => u === (userId || userName)
        );

        return [key, inUsers || inGroup];
      }
    }
    return [key, feature.value.enabled];
  }
}
