import { Box, Button, Typography } from '@mui/material';
import classNames from 'classnames';
import AtlasIcon from 'components/AtlasIcon';
import FilterListItem from 'components/FilterListItem';
import LoadingSpinner from 'components/LoadingSpinner';
import { IntServiceDataSpecificationFilterDto } from 'generated';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'store';
import { useGetFilterDisplayName } from 'views/Dashboard/ComponentTypes/FiltersComponent/getFilterDisplayName';
import { useAssetFilterOptions } from 'views/Dashboard/ComponentTypes/FiltersComponent/SettingsSteps/useAssetFilterOptions';
import AssetCountButtonContainer from './AssetCountButtonContainer';
import { useStyles } from './AssetFilterSettingsControl.styles';
import { AssetFilterSettingsState } from './AssetFilterSettingsState';
import PaginatedList from './PaginatedList';
import SelectedAssetsList from './SelectedAssetsList';
import SelectedFilterSettings from './SelectedFilterSettings';

interface IProps {
  filters: IntServiceDataSpecificationFilterDto[];
  isModal?: boolean;
}

// Basic estimation of how many filter types to show by default
// 60vh minus a bit of breathing room divided by each row height
const filterTypePageSize = Math.floor((window.innerHeight * 0.6 - 150) / 43);

const AssetFilterSettingsControl: React.FC<IProps> = ({
  filters,
  isModal = true,
}) => {
  const { t } = useTranslation('dashboard');
  const classes = useStyles();
  const filterOptions = useAssetFilterOptions();
  const getFilterDisplayName = useGetFilterDisplayName();
  const rootStore = useStores();
  const state = useMemo(
    () =>
      new AssetFilterSettingsState(
        rootStore,
        filters,
        filterOptions,
        getFilterDisplayName
      ),
    [rootStore, filters, filterOptions, getFilterDisplayName]
  );

  return (
    <>
      <div className={classes.rootGrid}>
        <div className={classes.topLeft}>
          <Typography>{t('service_data_filters.filter')}</Typography>

          <Button
            variant="outlined"
            color="primary"
            onClick={state.clearAllValues}
            disabled={!state.filters.find(filter => filter.values.length)}
          >
            {t('service_data_filters.clear_all')}
          </Button>
        </div>
        <div className={classes.middleLeft}>
          <PaginatedList
            array={state.optionStates}
            pageSize={filterTypePageSize}
          >
            {item => (
              <FilterListItem
                key={item.key}
                label={item.displayName}
                extraInfo={
                  <>
                    <span className={classes.firstSelectedFilterValue}>
                      {item.firstSelectedValue}
                    </span>
                    {item.selectedValuesCount}
                  </>
                }
                isActive={state.selectedOption === item}
                onClick={() => state.setSelectedKey(item.key)}
              />
            )}
          </PaginatedList>
        </div>

        {state.selectedOption && (
          <SelectedFilterSettings state={state.selectedOption} />
        )}

        {state.selectedKey === 'selectedAssets' && (
          <SelectedAssetsList state={state} />
        )}
      </div>

      <AssetCountButtonContainer
        centerButtonsRef={document.getElementById('modalCenterButtons')}
        isModal={isModal}
      >
        <div
          className={classNames(classes.assetCountButton, {
            [classes.assetCountSelected]:
              state.selectedKey === 'selectedAssets',
          })}
          onClick={() => state.setSelectedKey('selectedAssets')}
        >
          <span>
            {state.selectedOption &&
            state.selectedOption.selectedOptions.length > 0
              ? t('service_data_filters.selected_assets')
              : t('service_data_filters.included_assets')}
          </span>
          <Box
            display="flex"
            alignItems="center"
            className={classes.assetCountNumber}
          >
            {state.isLoadingAssetCount ? (
              <LoadingSpinner disableShrink size={16} />
            ) : (
              state.assetCount
            )}
            &nbsp;
            <AtlasIcon type="ChevronRight" size={16} />
          </Box>
        </div>
      </AssetCountButtonContainer>
    </>
  );
};

export default observer(AssetFilterSettingsControl);
