import { ListItemText } from '@mui/material';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IDataSourceStore } from 'views/Dashboard/DataSources/dataSourceTypes';
import { Control } from 'views/Dashboard/SettingControls';
import { ITableColumnSettings } from '../tableConfig';
import SelectDataPropButton from './SelectDataPropButton';

const ColumnRow: React.FC<{
  dataSource: IDataSourceStore;
  column: ITableColumnSettings;
}> = observer(({ column, dataSource }) => {
  const { dataProperties, groupProperties } = dataSource;

  const columnProp =
    column.type === 'ownerProp'
      ? groupProperties.find(dp => dp.id === column.prop)
      : dataProperties.find(dp => dp.id === column.prop);

  return <ListItemText primary={columnProp?.name || column.prop} />;
});

interface IProps {
  columns: ITableColumnSettings[];
  dataSource: IDataSourceStore;
}

const TableColumnSettings: React.FC<IProps> = ({ columns, dataSource }) => {
  const { t } = useTranslation('dashboard');

  const addDataPropRow = (dpId: string) => {
    const type = dataSource.dataProperties.find(dp => dp.id === dpId)
      ? 'dataPointProp'
      : 'ownerProp';
    runInAction(() => {
      columns.push({
        prop: dpId,
        type,
      });
    });
  };

  return (
    <Control.Group label={t('table.columns')}>
      <Control.SortableList items={columns}>
        {col => <ColumnRow column={col} dataSource={dataSource} />}
      </Control.SortableList>
      <SelectDataPropButton
        onChange={addDataPropRow}
        dataSource={dataSource}
        label={t('table.action.add_column')}
        id="addTableColumn"
        isOptionDisabled={dp => !!columns.find(col => col.prop === dp.id)}
      />
    </Control.Group>
  );
};

export default observer(TableColumnSettings);
