import {
  IntCreateProductTemplateDto,
  IntEditProductTemplateDto,
  IntProductTemplateDetailsDto,
  IntProductTemplateDto,
} from 'generated';
import {
  DeleteEndpoint,
  GetEndpoint,
  PostEndpoint,
  PutEndpoint,
  TableEndpoint,
} from 'shared/interfaces/api';
import { rnd } from 'utils';
import { IntProductTemplateOptionDto } from './../generated/index';
import { productTemplatesDemoData } from './demoData/productTemplatesDemoData';

interface IProductTemplateAPI {
  getProductTemplateOptions: TableEndpoint<IntProductTemplateDto>;
  getProductTemplateDetails: GetEndpoint<string, IntProductTemplateDetailsDto>;
  createProductTemplate: PostEndpoint<
    undefined,
    IntCreateProductTemplateDto,
    IntProductTemplateDto
  >;
  updateProductTemplate: PutEndpoint<
    string,
    IntEditProductTemplateDto,
    IntProductTemplateDetailsDto
  >;
  deleteProductTemplate: DeleteEndpoint<string>;
  getCustomerTemplateOptions: GetEndpoint<
    string,
    IntProductTemplateOptionDto[]
  >;
  updateCustomerTemplateOptions: PutEndpoint<string, string[]>;
}

const baseUrl = '/api/productTemplate';

export const productTemplateAPI: IProductTemplateAPI = {
  getProductTemplateOptions: {
    url: baseUrl,
    mockData: () => ({
      status: 200,
      data: {
        total: 50,
        rows: rnd.array<IntProductTemplateDto>(50, i => ({
          displayName: `Product ${i}`,
          productTemplateId: i.toString(),
          linkServiceName: `Link service ${i}`,
          description: 'Lorem ipsum',
        })),
      },
    }),
  },
  getProductTemplateDetails: {
    url: id => `${baseUrl}/${id}`,
    mockData: productId => ({
      status: 200,
      data: {
        productTemplateId: productId,
        displayName: `Template ${productId}`,
        menuItems: [],
        linkServiceName: `Link service ${productId}`,
        description: 'Lorem ipsum',
        groups: [
          {
            groupId: '67f558cc-c189-4cfe-a633-84623fc64cab',
            groupName: 'User',
            isDefault: true,
          },
          {
            groupId: '67f558cc-c189-4cfe-a633-84623fc64bac',
            groupName: 'Demo',
            isDefault: false,
          },
        ],
        attributeDefinitions: [
          {
            definitionId: '67f558cc-c189-4cfe-a633-84623fc64bad',
            definitionName: 'Custom Attribute',
            definitionDescription: '',
          },
        ],
      },
    }),
  },
  createProductTemplate: {
    url: baseUrl,
    mockData: (_, data) => {
      return {
        status: 201,
        data: {
          productTemplateId: rnd.guid(),
          displayName: data?.displayName || '',
          linkServiceName: `Link service ${data?.displayName}`,
          description: data?.description || '',
        },
      };
    },
  },
  updateProductTemplate: {
    url: id => `${baseUrl}/${id}`,
    mockData: (id, data) => {
      return {
        status: 201,
        data: {
          productTemplateId: id,
          description: data?.description || '',
          displayName: data?.displayName || '',
          linkServiceName: data?.linkServiceName || '',
          menuItems: data?.menuItems || [],
          groups: data?.groups || [],
          attributeDefinitions: data?.attributeDefinitions || [],
        },
      };
    },
  },
  deleteProductTemplate: {
    url: id => `${baseUrl}/${id}`,
    mockData: () => ({
      status: 200,
    }),
  },
  updateCustomerTemplateOptions: {
    url: id => `${baseUrl}/customer/${id}`,

    mockData: () => ({
      status: 200,
    }),
  },
  getCustomerTemplateOptions: {
    url: id => `${baseUrl}/customer/${id}`,
    mockData: () => ({
      status: 200,
      data: productTemplatesDemoData.getProductTemplates,
    }),
  },
};
