import { ListItemIcon, MenuItem, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import AtlasIcon from 'components/AtlasIcon';
import React, { useContext } from 'react';
import { AlignIconType } from 'shared/types/align-icon';
import { AtlasIconType } from 'shared/types/atlas-icon';
import { StatusVariant } from 'shared/types/status-variant';
import { DropdownContext } from './DropdownContext';

interface IProps {
  icon?: AtlasIconType;
  label: string;
  alignIcon?: AlignIconType;
  variant?: StatusVariant;
  disabled?: boolean;
  value: any;
  dataCy?: string;
}

const useStyles = makeStyles(theme =>
  createStyles({
    icon: {
      color: (props: IProps) => {
        switch (props.variant) {
          case 'error':
            return theme.palette.error.main;

          case 'warning':
            return theme.palette.warning.main;

          case 'success':
            return theme.palette.success.main;

          case 'unconfigured':
            return theme.palette.unconfigured.main;

          case 'default':
          default:
            return 'inherit';
        }
      },
    },
    listItemIconLeft: {
      minWidth: 24,
    },
    menuItemIconRight: {
      justifyContent: 'space-between',
    },
    listItemIconRight: {
      marginLeft: '16px',
      minWidth: '0px',
    },
  })
);

const DropdownMenuItem: React.FC<IProps> = props => {
  const classes = useStyles(props);
  const { label, icon, disabled, value, alignIcon = 'left', dataCy } = props;
  const { onOptionClick } = useContext(DropdownContext);
  return (
    <MenuItem
      onClick={() => onOptionClick(value)}
      disabled={disabled}
      className={
        icon && alignIcon === 'right' ? classes.menuItemIconRight : undefined
      }
      data-cy={dataCy}
    >
      {icon && alignIcon === 'left' && (
        <ListItemIcon className={classes.listItemIconLeft}>
          <AtlasIcon type={icon} className={classes.icon} size={14} />
        </ListItemIcon>
      )}
      <Typography variant="body2">{label}</Typography>
      {icon && alignIcon === 'right' && (
        <ListItemIcon className={classes.listItemIconRight}>
          <AtlasIcon type={icon} className={classes.icon} size={14} />
        </ListItemIcon>
      )}
    </MenuItem>
  );
};

export default DropdownMenuItem;
