import { Box, Grid } from '@mui/material';
import SearchField from 'components/SearchField';
import { Table } from 'components/Table';
import TotalRows from 'components/Table/TotalRows';
import { observer } from 'mobx-react-lite';
import React from 'react';
import {
  Column,
  ComponentPropsGetter0,
  ComponentPropsGetterR,
  Instance,
  RowInfo,
  SortingRule,
} from 'react-table';
import { TableWidgetState } from 'views/Dashboard/ComponentTypes/TableComponent/TableWidgetState';
import { ITableSettings } from './tableConfig';

interface IProps {
  settings: ITableSettings;
  columns: Column[];
  rows: any[];
  total: number;
  defaultSorted?: SortingRule;
  tableState?: TableWidgetState;
  tableRef?: (table: Instance) => void;
  subComponent?: (row: RowInfo) => JSX.Element | null;
  getTrProps?: ComponentPropsGetterR | ComponentPropsGetter0;
}

function getTotalPages(pageSize: number, totalRows: number) {
  return Math.ceil(totalRows / pageSize);
}

const getTdProps = (
  _finalState: any,
  _rowInfo?: RowInfo,
  column?: Column,
  _instance?: any
) => {
  if (typeof column?.Header === 'string') {
    return {
      'data-cy': `cell-${column?.Header ? column?.Header.replaceAll(' ', '-') : 'expand'}`,
    };
  } else {
    return {
      'data-cy': `cell-${(column?.Header as React.ReactElement)?.props?.children || 'expand'}`,
    };
  }
};

const TableComponent: React.FC<IProps> = ({
  settings,
  columns,
  rows,
  defaultSorted,
  tableState,
  total,
  tableRef,
  subComponent,
  getTrProps,
}) => {
  if (tableState) {
    const pages = getTotalPages(tableState.tableProps.pageSize, total);
    const { page, pageSize } = tableState.tableProps;

    return (
      <>
        <Box p="0 10px 12px">
          <Grid
            alignItems="center"
            justifyContent="flex-end"
            container
            spacing={1}
          >
            <Grid item lg={4} xs={12}>
              <SearchField
                value={tableState.search}
                onChange={tableState.setSearch}
              />
            </Grid>
            <Grid item lg={4} xs={12} className="text-right">
              <TotalRows currentlyShown={rows.length} totalRows={total} />
            </Grid>
          </Grid>
        </Box>
        <Table
          manual
          columns={columns}
          SubComponent={subComponent}
          data={rows}
          noDataText={false}
          getTrProps={getTrProps}
          getTdProps={getTdProps}
          collapseOnDataChange={false}
          showPagination={true}
          style={{ height: 'calc(100% - 56px)' }}
          minRows={0}
          pages={pages}
          onPageChange={tableState.handlePageChange}
          onPageSizeChange={tableState.handlePageSizeChange}
          onSortedChange={tableState.handleSortedChange}
          page={page}
          pageSize={pageSize}
          sorted={tableState.sorted}
          ref={tableRef}
        />
      </>
    );
  }

  return (
    <>
      <Box className="text-right">
        <TotalRows totalRows={total} />
      </Box>
      <Table
        columns={columns}
        data={rows}
        getTdProps={getTdProps}
        noDataText={false}
        showPagination={total > 100}
        style={{ height: 'calc(100% - 18px)' }}
        minRows={0}
        defaultSorted={defaultSorted ? [defaultSorted] : []}
        defaultPageSize={100}
        pivotBy={settings.pivotBy.length ? ['__pivot'] : []}
        collapseOnDataChange={false}
        ref={tableRef}
      />
    </>
  );
};

export default observer(TableComponent);
