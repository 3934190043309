import { Button, Grid, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { Role } from 'components/Auth/Role';
import { FormField } from 'components/FormFields';
import LoadingOverlay from 'components/LoadingOverlay';
import { snackbar } from 'components/Snackbar';
import TabPanel from 'components/TabPanel';
import { Form, Formik } from 'formik';
import {
  IntAtlasServicePropertyDto,
  IntPolicyDto,
  IntPolicyUsageDto,
  ServicePropertyDataType,
} from 'generated';
import i18n from 'i18n';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { RefObject, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dashboardPolicyApi } from 'services/dashboardPolicy.service';
import { useGet } from 'shared/api/hooks';
import { useStores } from 'store';
import { Yup } from 'utils';
import AddPropertyStatusButton from './AddPropertyStatusButton';
import ConfirmDeletePolicy from './ConfirmDeletePolicy';
import DataStatusForm from './DataStatusForm';
import LoadServiceDetails from './LoadServiceDetails';
import PolicyCustomerField from './PolicyCustomerField';
import PolicyUsage from './PolicyUsage';
import SortedStatuses from './SortedStatuses';
import StatusTabLabel from './StatusTabLabel';

const statusSchema = (serviceProperties: IntAtlasServicePropertyDto[]) => {
  return Yup.object().shape({
    id: Yup.string().required(),
    name: Yup.string(),
    customerId: Yup.string().nullable(),
    dealerId: Yup.string().nullable(),
    servicePropertyId: Yup.string().nullable(),
    rules: Yup.array().when('servicePropertyId', {
      is: (servicePropertyId: string) => {
        return (
          serviceProperties.find(
            sp => sp.servicePropertyId === servicePropertyId
          )?.dataType === ServicePropertyDataType.Text
        );
      },
      then: Yup.array().of(
        Yup.object({
          color: Yup.string().required(),
          icon: Yup.string().nullable(),
          id: Yup.string().required(),
          label: Yup.string().nullable(),
          description: Yup.string().nullable(),
          min: Yup.number().nullable(),
          max: Yup.number().nullable(),
          textValue: Yup.string().required(),
          textComparer: Yup.number().required(),
        })
      ),
      otherwise: Yup.array().of(
        Yup.object({
          color: Yup.string().required(),
          icon: Yup.string().nullable(),
          id: Yup.string().required(),
          label: Yup.string().nullable(),
          description: Yup.string().nullable(),
          textValue: Yup.string().nullable(),
          textComparer: Yup.number().nullable(),
          min: Yup.number()
            .nullable()
            .test(
              'minOrMaxRequired',
              i18n.t('policy:error.min_or_max_required'),
              function (item) {
                return item != null || this.parent.max != null;
              }
            )
            .test(
              'minSmallerThanMax',
              i18n.t('validation:invalid.max_value', { maxValue: 'max' }),
              function (item) {
                return (
                  item == null ||
                  this.parent.max == null ||
                  item <= this.parent.max
                );
              }
            ),
          max: Yup.number()
            .nullable()
            .test(
              'minOrMaxRequired',
              i18n.t('policy:error.min_or_max_required'),
              function (item) {
                return this.parent.min != null || item != null;
              }
            )
            .test(
              'minSmallerThanMax',
              i18n.t('validation:invalid.min_value', { minValue: 'min' }),
              function (item) {
                return (
                  item == null ||
                  this.parent.min == null ||
                  item >= this.parent.min
                );
              }
            ),
        })
      ),
    }),
  });
};

const policyValidationSchema = (
  serviceProperties: IntAtlasServicePropertyDto[]
) =>
  Yup.form<IntPolicyDto>({
    policyId: Yup.string().required(),
    name: Yup.string().required(),
    customerId: Yup.string(),
    statuses: Yup.array().of(statusSchema(serviceProperties)),
  });

interface IProps {
  policy: IntPolicyDto;
  topRef?: RefObject<HTMLDivElement> | null;
}

const PolicyForm: React.FC<IProps> = ({ policy, topRef }) => {
  const {
    authStore: { hasRole },
    policyStore: { updatePolicy },
    dashboardStore: { serviceProperties },
  } = useStores();
  const { t } = useTranslation('policy');
  const initialValues = toJS(policy);
  const [tabValue, setTabValue] = useState(0);
  const [policyTabValue, setPolicyTabValue] = useState(0);

  const theme = useTheme();

  const handleChange = (_e: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const [usages, setUsages] = useState<IntPolicyUsageDto[]>([]);
  const [isUnauthorized, setIsUnauthorized] = useState<boolean>(false);
  const [getUsage, isLoadingUsage] = useGet(dashboardPolicyApi.getPolicyUsage);

  const fetchUsageData = useCallback(async () => {
    const resp = await getUsage(policy.policyId);

    if (resp.status === 200 || resp.status === 204) {
      setUsages(resp.data || []);
    } else if (resp.status === 403) {
      setIsUnauthorized(true);
    } else {
      snackbar(t('error.usage'), { variant: 'error' });
    }
  }, [getUsage, policy, t]);

  useEffect(() => {
    if (policy) {
      fetchUsageData();
    }
  }, [fetchUsageData, policy]);

  const [showConfirmDeletePolicy, setShowConfirmDeletePolicy] = useState(false);

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={updatePolicy}
        enableReinitialize
        validationSchema={policyValidationSchema(serviceProperties)}
      >
        {({ values, isSubmitting, errors }) => (
          <Form id="editPolicyForm">
            <LoadServiceDetails>
              <LoadingOverlay isLoading={isSubmitting}>
                <Grid item sm={10} paddingBottom={8}>
                  <Tabs
                    value={policyTabValue}
                    indicatorColor="primary"
                    onChange={(_, value) => setPolicyTabValue(value)}
                  >
                    <Tab value={0} label={t('label.policy')} />
                    <Tab value={1} label={t('label.policy_usage')} />
                  </Tabs>
                </Grid>
                <TabPanel value={policyTabValue} index={0} keepMounted>
                  <Grid container spacing={2}>
                    <Grid item sm={12} display="flex">
                      <Typography
                        variant="h3"
                        marginBottom={theme.spacing(4)}
                        marginRight={theme.spacing(4)}
                      >
                        {values.name}
                      </Typography>
                      {hasRole(Role.RoleNameDeleteDashboardDataStatus) && (
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => setShowConfirmDeletePolicy(true)}
                        >
                          {t('action.delete_policy')}
                        </Button>
                      )}
                    </Grid>
                    <Grid item sm={6}>
                      <FormField.TextField name="name" label={t('name')} />
                    </Grid>
                    <Grid item sm={6}>
                      <PolicyCustomerField />
                    </Grid>
                    <Grid item sm={10}>
                      <Tabs
                        value={tabValue}
                        onChange={handleChange}
                        variant="scrollable"
                      >
                        {values.statuses?.map((status, i) => (
                          <Tab
                            key={status.id}
                            label={
                              <StatusTabLabel
                                isActive={tabValue === i}
                                status={status}
                                isError={!!errors.statuses?.[i]}
                                setTabIndex={setTabValue}
                              />
                            }
                          />
                        ))}
                      </Tabs>
                    </Grid>
                    <Grid item sm={2} display="flex" justifyContent="flex-end">
                      <AddPropertyStatusButton setTabIndex={setTabValue} />
                    </Grid>
                    <Grid item sm={12}>
                      {values.statuses?.map((status, i) => (
                        <TabPanel value={tabValue} index={i} key={status.id}>
                          <SortedStatuses
                            statusRules={status.rules}
                            isStringProperty={
                              serviceProperties.find(
                                sp =>
                                  sp.servicePropertyId ===
                                  status.servicePropertyId
                              )?.dataType === ServicePropertyDataType.Text
                            }
                          />

                          <DataStatusForm status={status} index={i} />
                        </TabPanel>
                      ))}
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={policyTabValue} index={1}>
                  {isUnauthorized ? (
                    <Typography marginBottom={4}>
                      {t('cannot_see_usage')}
                    </Typography>
                  ) : (
                    <PolicyUsage usages={usages} />
                  )}
                </TabPanel>
              </LoadingOverlay>
            </LoadServiceDetails>
          </Form>
        )}
      </Formik>
      <ConfirmDeletePolicy
        isOpen={showConfirmDeletePolicy}
        close={() => setShowConfirmDeletePolicy(false)}
        policy={policy}
        usages={usages}
        isUnauthorized={isUnauthorized}
        isLoading={isLoadingUsage}
      />
    </>
  );
};

export default observer(PolicyForm);
