import { subMonths } from 'date-fns';
import {
  IntCdrDataDto,
  IntConnectivityType,
  IntCountryDataConsumptionDto,
  IntGroupMinimalConsumptionDto,
  IntUnitConsumptionDataDto,
  IntUnitConsumptionSmsDto,
  IntUnitMinimalConsumptionDto,
} from 'generated';
import { stringify } from 'qs';
import {
  GetEndpoint,
  PostEndpoint,
  TableEndpoint,
} from 'shared/interfaces/api';
import { connectivityGroupDemoData } from './demoData/connectivityGroupDemoData';
import { connectivityUnitDemoData } from './demoData/connectivityUnitDemoData';
import { usageDemoData } from './demoData/usageDemoData';

const usageUrl = '/api/usage';

interface IUsageByCountryParams {
  startDate?: Date;
  endDate?: Date;
  customerId?: string;
  connectivityType?: IntConnectivityType;
}

type UsageAPI = {
  getDataConsumption: PostEndpoint<
    { startDate: string; endDate: string },
    string[],
    IntUnitConsumptionDataDto[]
  >;
  getSmsConsumption: PostEndpoint<
    { startDate: string; endDate: string },
    string[],
    IntUnitConsumptionSmsDto[]
  >;
  getMostActiveUnits: GetEndpoint<
    | {
        top?: number;
        startDate?: string;
        endDate?: string;
        connectivityType?: IntConnectivityType;
      }
    | undefined,
    IntUnitMinimalConsumptionDto[]
  >;
  getDataUsageByCountry: GetEndpoint<
    IUsageByCountryParams,
    IntCountryDataConsumptionDto[]
  >;
  getDataUsageByDay: (options: {
    startDate?: Date;
    endDate?: Date;
  }) => TableEndpoint<IntCdrDataDto>;
  getMostActiveGroups: GetEndpoint<
    { top?: number; startDate?: string; endDate?: string } | undefined,
    IntGroupMinimalConsumptionDto[]
  >;
};

export const usageAPI: UsageAPI = {
  getDataConsumption: {
    url: ({ startDate, endDate }) =>
      `${usageUrl}/data?startDate=${startDate}&endDate=${endDate}`,
    mockData: usageDemoData.getDataConsumption,
  },
  getSmsConsumption: {
    url: ({ startDate, endDate }) =>
      `${usageUrl}/sms?startDate=${startDate}&endDate=${endDate}`,
    mockData: usageDemoData.getSmsConsumption,
  },
  getMostActiveUnits: {
    url: (
      params = {
        top: 10,
        startDate: subMonths(new Date(), 1).toISOString(),
        endDate: new Date().toISOString(),
        connectivityType: IntConnectivityType.Unknown,
      }
    ) =>
      `${usageUrl}/data/top/${params.connectivityType}?top=${params.top}&startDate=${params.startDate}&endDate=${params.endDate}`,
    mockData: connectivityUnitDemoData.getMostActiveUnits,
  },
  getDataUsageByCountry: {
    url: params =>
      `${usageUrl}/data/country/${params.connectivityType}?${stringify({
        customerId: params.customerId,
        startDate: params.startDate?.toISOString(),
        endDate: params.endDate?.toISOString(),
      })}`,
    mockData: usageDemoData.getDataUsageByCountry,
  },
  getDataUsageByDay: ({ startDate, endDate }) => ({
    url: `${usageUrl}/data/day/?startDate=${startDate?.toISOString()} &endDate=${endDate?.toISOString()}`,
    mockData: usageDemoData.getDataUsageByDay,
  }),
  getMostActiveGroups: {
    url: (
      params = {
        top: 10,
        startDate: subMonths(new Date(), 1).toISOString(),
        endDate: new Date().toISOString(),
      }
    ) =>
      `${usageUrl}/data/group?top=${params.top}&startDate=${params.startDate}&endDate=${params.endDate}`,
    mockData: connectivityGroupDemoData.getMostActiveGroups,
  },
};
