import { Box, DialogActions } from '@mui/material';
import classNames from 'classnames';
import React, { ReactElement } from 'react';
import { useStyles } from './ModalFooterWithButtons.styles';

interface IProps {
  rootClasses?: string;
  leftButtons?: ReactElement;
  midButtons?: ReactElement;
  rightButtons?: ReactElement;
}

const ModalFooterWithButtons: React.FC<IProps> = ({
  rootClasses,
  leftButtons,
  midButtons,
  rightButtons,
}) => {
  const classes = useStyles();

  return (
    <DialogActions className={classNames(classes.outer, rootClasses)}>
      <Box display="flex" data-cy="leftButtons" className={classes.group}>
        {leftButtons}
      </Box>
      <Box display="flex" data-cy="midButtons" className={classes.group}>
        {midButtons}
      </Box>
      <Box display="flex" data-cy="rightButton" className={classes.group}>
        {rightButtons}
      </Box>
    </DialogActions>
  );
};

export default ModalFooterWithButtons;
