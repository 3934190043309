import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2),
    '& input::-ms-reveal': {
      display: 'none',
    },
  },
  subText: {
    fontSize: '0.8rem',
    lineHeight: '1.25em',
  },
}));
