import { AppBar, Toolbar } from '@mui/material';
import HeaderLogo from 'components/HeaderLogo/HeaderLogo';
import SiteTopBarContent from 'components/SiteTopBarContent';
import React from 'react';
import { useStyles } from './SiteTopBar.styles';

interface IProps {
  navDrawerOpen: boolean;
}

const SiteTopBar: React.FC<IProps> = ({ navDrawerOpen }) => {
  const classes = useStyles();
  return (
    <AppBar className={classes.root} color="default" position="fixed">
      <Toolbar className={classes.toolbar}>
        <HeaderLogo navDrawerOpen={navDrawerOpen} />
        <SiteTopBarContent />
      </Toolbar>
    </AppBar>
  );
};

export default SiteTopBar;
