import { PaletteOptions } from '@mui/material';
import { IUiConfiguration } from '../IUiConfiguration';

export const palatteConfig = (uiConfig: IUiConfiguration) => {
  function validateColor(color: string) {
    if (!color || color === '#') {
      return '#FFFFFF';
    }
    return color;
  }

  const palette: PaletteOptions = {
    primary: {
      main: validateColor(uiConfig.colors.primary),
    },
    secondary: {
      main: '#fef',
    },
    success: {
      main: '#00a552',
      light: '#029899',
    },
    background: {
      default: '#380354',
      paper: '#fff',
    },
    mode: 'light',
    error: { main: '#bf134d' },
    unconfigured: { main: '#a0a0a0' },
    warning: { main: '#ff9902' },
    divider: '#ccc',
  };

  return palette;
};
