import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { appInsights } from 'appInsights';
import Axios from 'axios';
import { action, makeObservable, observable } from 'mobx';
import {
  DeleteEndpoint,
  GetEndpoint,
  IResponse,
  PatchEndpoint,
  PostEndpoint,
  PostRequest,
  PutEndpoint,
} from 'shared/interfaces/api';
import { delay } from 'utils';
import { RootStore } from './rootStore';

export class StoreBase {
  @observable isLoading = false;

  constructor(public rootStore: RootStore) {
    makeObservable(this);
  }

  @action.bound setIsLoading = (loading: boolean) => {
    this.isLoading = loading;
  };

  getFakeResponseForPostPatchPutDelete<TParams, TData, TResponseData>(
    isLoadingFn: (loading: boolean) => void,
    endpoint:
      | PostEndpoint<TParams, TData, TResponseData>
      | PatchEndpoint<TParams, TData, TResponseData>
      | PutEndpoint<TParams, TData, TResponseData>
      | DeleteEndpoint<TParams, TData, TResponseData>,
    params: TParams,
    data: TData | undefined
  ) {
    // We don't want to send error on login
    const loginRoute = endpoint.url.toString().includes('account/Login');
    const statusAndStatusText = {
      status: this.rootStore.httpStatusCode,
      statusText: `Mocked ${this.rootStore.httpStatusCode} response`,
    };
    const mockData = endpoint.mockData?.(params, data) || { status: 200 };
    const fakeResponse: IResponse<TResponseData> =
      this.rootStore.errorMode && !loginRoute ? statusAndStatusText : mockData;

    isLoadingFn(false);

    return fakeResponse;
  }

  async postPatchPutOrDelete<TParams, TData, TResponseData>(
    method: 'post' | 'patch' | 'put' | 'delete',
    endpoint:
      | PostEndpoint<TParams, TData, TResponseData>
      | PatchEndpoint<TParams, TData, TResponseData>
      | PutEndpoint<TParams, TData, TResponseData>
      | DeleteEndpoint<TParams, TData, TResponseData>,
    request: PostRequest<TParams, TData>,
    isLoadingFn: (loading: boolean) => void = this.setIsLoading
  ): Promise<IResponse<TResponseData>> {
    const { params, data, headers, responseType, cancelToken } = request;

    const url =
      typeof endpoint.url === `function` ? endpoint.url(params) : endpoint.url;

    isLoadingFn(true);

    if (
      this.rootStore.demoMode ||
      this.rootStore.isStaticDemo ||
      endpoint.alwaysMockData
    ) {
      await delay(500, 2000);

      return this.getFakeResponseForPostPatchPutDelete(
        isLoadingFn,
        endpoint,
        params,
        data
      );
    } else {
      let response: IResponse<TResponseData>;

      try {
        const axiosResp = await Axios({
          cancelToken,
          method,
          url,
          data,
          headers,
          responseType,
        });

        response = {
          data: axiosResp.data,
          status: axiosResp.status,
          statusText: axiosResp.statusText,
        };
      } catch (error) {
        if (Axios.isCancel(error)) {
          return {
            status: 499,
          };
        }

        if (error?.response?.status === 401) {
          this.rootStore.authStore.clearUserContext();
        }

        const statusText =
          error?.response?.data?.ExceptionMessage ||
          error?.response?.data?.Message ||
          error?.response?.statusText;

        appInsights.trackException({
          error: new Error(statusText),
          severityLevel: SeverityLevel.Error,
        });

        response = {
          status: error?.response?.status,
          statusText,
          exceptionMessage: error?.response?.data?.Message,
        };
      }

      isLoadingFn(false);

      return response;
    }
  }

  @action.bound async httpPost<TParams, TPostData, TResponseData>(
    endpoint: PostEndpoint<TParams, TPostData, TResponseData>,
    request: PostRequest<TParams, TPostData>,
    isLoadingFn: (loading: boolean) => void = this.setIsLoading
  ): Promise<IResponse<TResponseData>> {
    return this.postPatchPutOrDelete('post', endpoint, request, isLoadingFn);
  }

  @action.bound async httpPatch<TParams, TBodyData, TResponseData>(
    endpoint: PatchEndpoint<TParams, TBodyData, TResponseData>,
    request: PostRequest<TParams, TBodyData>,
    isLoadingFn: (loading: boolean) => void = this.setIsLoading
  ): Promise<IResponse<TResponseData>> {
    return this.postPatchPutOrDelete('patch', endpoint, request, isLoadingFn);
  }

  @action.bound async httpPut<TParams, TPostData, TResponseData>(
    endpoint: PutEndpoint<TParams, TPostData, TResponseData>,
    request: PostRequest<TParams, TPostData>,
    isLoadingFn: (loading: boolean) => void = this.setIsLoading
  ): Promise<IResponse<TResponseData>> {
    return this.postPatchPutOrDelete('put', endpoint, request, isLoadingFn);
  }

  @action.bound async httpDelete<TParams, TPostData, TResponseData>(
    endpoint: DeleteEndpoint<TParams, TPostData, TResponseData>,
    request: PostRequest<TParams, TPostData>,
    isLoadingFn: (loading: boolean) => void = this.setIsLoading
  ): Promise<IResponse<TResponseData>> {
    return this.postPatchPutOrDelete('delete', endpoint, request, isLoadingFn);
  }

  demoOrLive<TParams, TData>(
    endpoint: GetEndpoint<TParams, TData> | DeleteEndpoint<TParams, TData>,
    handleDemoMode: () => Promise<IResponse<TData>>,
    handleLiveData: () => Promise<IResponse<TData>>
  ): Promise<IResponse<TData>> {
    if (
      this.rootStore.demoMode ||
      this.rootStore.isStaticDemo ||
      endpoint.alwaysMockData
    ) {
      return handleDemoMode();
    } else {
      return handleLiveData();
    }
  }

  async get<TParams, TData>(
    method: 'get',
    endpoint: GetEndpoint<TParams, TData>,
    params: TParams,
    isLoadingFn: (loading: boolean) => void = this.setIsLoading,
    headers?: { [key: string]: string }
  ): Promise<IResponse<TData>> {
    const handleDemoMode = async (): Promise<IResponse<TData>> => {
      await delay(500, 2000);

      // We don't want to send error on auth
      const isLoginRoute = endpoint.url
        .toString()
        .includes('users/authorization');
      const statusAndStatusText = {
        status: this.rootStore.httpStatusCode,
        statusText: `Mocked ${this.rootStore.httpStatusCode} response`,
      };
      const mockData = endpoint.mockData?.(params) || { status: 200 };
      const fakeResponse: IResponse<TData> =
        this.rootStore.errorMode && !isLoginRoute
          ? statusAndStatusText
          : mockData;

      isLoadingFn(false);

      return fakeResponse;
    };

    const handleLiveData = async (): Promise<IResponse<TData>> => {
      let response: IResponse<TData>;

      try {
        let axiosResp: IResponse<TData>;
        let url;

        if (typeof endpoint.url === 'function') {
          url = endpoint.url(params);
          axiosResp = await Axios({ method, url, headers });
        } else {
          url = endpoint.url;
          axiosResp = await Axios({ method, url, params, headers });
        }

        response = {
          data: axiosResp.data,
          status: axiosResp.status,
          statusText: axiosResp.statusText,
        };
      } catch (error) {
        if (error?.response?.status === 401) {
          this.rootStore.authStore.clearUserContext();
        }

        const statusText =
          error?.response?.data?.ExceptionMessage ||
          error?.response?.data?.Message ||
          error?.response?.statusText;

        appInsights.trackException({
          error: new Error(statusText),
          severityLevel: SeverityLevel.Error,
        });

        response = {
          status: error?.response?.status,
          statusText: statusText,
        };
      }

      isLoadingFn(false);

      return response;
    };

    isLoadingFn(true);

    return this.demoOrLive<TParams, TData>(
      endpoint,
      handleDemoMode,
      handleLiveData
    );
  }

  @action.bound async httpGet<TParams, TData>(
    endpoint: GetEndpoint<TParams, TData>,
    params: TParams,
    isLoadingFn: (loading: boolean) => void = this.setIsLoading,
    headers?: { [key: string]: string }
  ): Promise<IResponse<TData>> {
    return this.get('get', endpoint, params, isLoadingFn, headers);
  }
}
