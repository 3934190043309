import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { isDevelopment } from 'utils';
import { missingKeyHandler } from './missingKeyHandler';
import { resources } from './translations';

const initialLanguage = 'en-US';

i18n.use(initReactI18next).init({
  resources,
  lng: initialLanguage,
  saveMissing: isDevelopment,
  appendNamespaceToMissingKey: true,
  missingKeyHandler,
  fallbackLng: 'en',
  debug: false,
  ns: [
    'apnhub',
    'asset',
    'auth',
    'automation',
    'common',
    'commonAttributes',
    'realEstateIt',
    'dashboard',
    'device',
    'enterprise',
    'group',
    'invoice',
    'order',
    'product',
    'profile',
    'route',
    'servicerequest',
    'sim',
    'sms',
    'smshub',
    'unit',
    'user',
    'validation',
    'configuration',
    'qrapp',
    'accessendpoint',
  ],
  defaultNS: 'common',
  interpolation: {
    escapeValue: false,
    formatSeparator: ',',
    format: function (value, format) {
      switch (format) {
        case 'uppercase':
          return value?.toUpperCase();
        case 'lowercase':
          return value?.toLowerCase();
        case 'upperFirst':
          return value && `${value.charAt(0).toUpperCase()}${value.slice(1)}`;
        case 'array':
          return value && value.map((v: string) => v).join(', ');
        default:
          return value;
      }
    },
  },
  react: {
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'u'],
  },
});

export default i18n;
