import { aiTrackEvent } from 'appInsights';
import { Role } from 'components/Auth/Role';
import { snackbar } from 'components/Snackbar';
import {
  DashboardComponentType,
  IntDashboardDetailsDto,
  IntEditDashboardDetailsRequestDto,
  IntEditDashboardRequestDto,
  IntMenuItemDto,
} from 'generated';
import i18n from 'i18next';
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
  toJS,
} from 'mobx';
import { Layout } from 'react-grid-layout';
import { dashboardApi } from 'services/dashboard.service';
import { delayedTriggerWindowResize } from 'utils';
import { DashboardFilterState } from 'views/Dashboard/ComponentTypes/FiltersComponent/Filters/DashboardFilterState';
import { IFiltersSettings } from 'views/Dashboard/ComponentTypes/FiltersComponent/filtersConfig';
import {
  IDashboardComponentJSON,
  IGridPosition,
} from 'views/Dashboard/dashboardTypes';
import { parseDashboardComponentSettings } from 'views/Dashboard/parseComponentSettings';
import { RootStore } from '../rootStore';
import { StoreBase } from '../storeBase';
import { DashboardComponent } from './dashboardComponent';

type IDashboardConstructorOptions = {
  rootStore: RootStore;
  dashboardId?: string;
  staticWidgets?: IDashboardComponentJSON[];
};

export class Dashboard extends StoreBase {
  dashboardId: string;
  customerId = '';
  isStatic = false;
  @observable displayName = '';
  @observable components: DashboardComponent[] = [];
  @observable menuItem: IntMenuItemDto | null = null;
  @observable isTemplate: boolean = false;

  filterState: DashboardFilterState;

  @observable lastLoadedDetails: Date | undefined;
  @observable hasUnsavedChanges = false;
  @observable showAddModal = false;
  @observable selectedComponent: DashboardComponent | undefined;
  @observable detailsError = '';

  constructor(options: IDashboardConstructorOptions) {
    super(options.rootStore);

    const { dashboardId, staticWidgets = [] } = options;
    makeObservable(this);

    if (!staticWidgets.length && !dashboardId) {
      throw new Error(
        'Invalid constructor for dashboard, supply either a DashboardId or static widgets.'
      );
    }

    if (dashboardId) {
      this.dashboardId = dashboardId;
    } else {
      this.isStatic = true;
      this.importComponents(staticWidgets);
      this.dashboardId = '';
      this.displayName = '';
      this.customerId = '';
    }

    this.filterState = new DashboardFilterState(this);
  }

  @computed get isEditable() {
    // At some point this will probably require more advanced logic
    return (
      !this.isStatic &&
      this.rootStore.authStore.hasRole(Role.RoleNameEditDashboard)
    );
  }

  @computed get parentMenuItem() {
    return (
      this.menuItem &&
      this.rootStore.layoutStore.navigation?.menuItems?.find(
        item => item.menuItemId === this.menuItem?.parentId
      )
    );
  }

  @action.bound async loadDetails() {
    const {
      authStore: { hasRole },
      policyStore: { loadPolicies },
    } = this.rootStore;

    const resp = await this.httpGet(
      dashboardApi.getDashboardDetails,
      this.dashboardId
    );

    if (hasRole(Role.RoleNameViewDashboardDataStatus)) {
      await Promise.all([loadPolicies()]);
    }

    if (resp.status === 200 && resp.data) {
      this.setDetails(resp.data);
      delayedTriggerWindowResize();
    } else {
      runInAction(() => {
        this.detailsError = resp.statusText || resp.exceptionMessage || 'error';
      });
      snackbar(i18n.t('dashboard:error.loading'), {
        variant: 'warning',
      });
    }
  }

  @action.bound setDetails(details: IntDashboardDetailsDto) {
    const {
      components = [],
      displayName,
      customerId,
      menuItem,
      isTemplate,
    } = details;
    this.components = components.map(
      dto =>
        new DashboardComponent(this, {
          componentId: dto.dashboardComponentId,
          settings: parseDashboardComponentSettings(dto.settings),
          type: dto.componentType,
        })
    );
    this.customerId = customerId;
    this.displayName = displayName;
    this.menuItem = menuItem;
    this.lastLoadedDetails = new Date(); // Maybe this is clever? Keep track of when details were loaded?
    this.hasUnsavedChanges = false;
    this.isTemplate = isTemplate || false;
  }

  @action.bound importComponents(widgets: IDashboardComponentJSON[]) {
    this.components = widgets.map(
      widget => new DashboardComponent(this, widget)
    );
    this.hasUnsavedChanges = true;
  }

  @action.bound initialize() {
    if (!this.lastLoadedDetails) {
      this.loadDetails();
    }
  }

  @action.bound destroy() {
    if (this.hasUnsavedChanges) {
      this.components = [];
      this.lastLoadedDetails = undefined;
      this.hasUnsavedChanges = false;
    }
  }

  @action.bound addComponent(component: DashboardComponent) {
    this.components.unshift(component); // Inserting the new component first is important for making grid-layout position it at the top
    delayedTriggerWindowResize();
    this.hasUnsavedChanges = true;

    aiTrackEvent('Create', { title: 'Dashboard Widget' });
  }

  @action.bound removeComponent(component: DashboardComponent<any>) {
    this.components = this.components.filter(comp => comp !== component);
    this.hasUnsavedChanges = true;

    aiTrackEvent('Delete', { title: 'Dashboard Widget' });
  }

  @action.bound updatePositions(layouts: Layout[]) {
    this.components.forEach(component => {
      const newPosition = layouts.find(
        layout => layout.i === component.componentId
      );

      if (!newPosition) {
        return;
      }

      if (
        component.settings.position &&
        hasPositionChanged(component.settings.position, newPosition)
      ) {
        component.settings.position = {
          x: newPosition.x,
          y: newPosition.y,
          w: newPosition.w,
          h: newPosition.h,
        };
        this.hasUnsavedChanges = true;
      }
    });
  }

  @action.bound async setIsTemplate(isTemplate: boolean) {
    const requestDto: IntEditDashboardRequestDto = {
      customerId: this.customerId,
      displayName: this.displayName,
      isTemplate,
    };

    const resp = await this.httpPatch(dashboardApi.updateDashboardMetaData, {
      params: this.dashboardId,
      data: requestDto,
    });

    if (resp.status === 200) {
      runInAction(() => {
        this.isTemplate = isTemplate;
      });
    }

    return resp;
  }

  @action.bound async updateDashboardName(displayName: string) {
    const requestDto: IntEditDashboardRequestDto = {
      customerId: this.customerId,
      displayName,
    };

    const resp = await this.httpPatch(dashboardApi.updateDashboardMetaData, {
      params: this.dashboardId,
      data: requestDto,
    });
    const updatedDashboard = resp.data;
    if (resp.status === 200 && updatedDashboard) {
      runInAction(() => {
        this.displayName = displayName;
        if (updatedDashboard.menuItem) {
          this.rootStore.layoutStore.updateNavigation(
            updatedDashboard.menuItem,
            'update'
          );
        }
      });
    }

    return resp;
  }

  // Saves everything, including components
  @action.bound async saveChanges() {
    const requestDto: IntEditDashboardDetailsRequestDto = {
      components: this.components.map(comp => comp.toIntDto()),
      customerId: this.customerId,
      displayName: this.displayName,
      isTemplate: this.isTemplate,
    };

    const resp = await this.httpPut(dashboardApi.updateDashboardDetails, {
      params: this.dashboardId,
      data: requestDto,
    });

    if (resp.status === 200 && resp.data) {
      snackbar(i18n.t('dashboard:success.saved'), {
        variant: 'success',
      });

      aiTrackEvent('Update', { title: 'Dashboard' });
      this.setDetails(resp.data);
    } else {
      snackbar(i18n.t('dashboard:error.saving'), {
        variant: 'error',
      });
    }
  }

  // Used by setting controls to update values within an action.
  // Typing this, while probably possible (see controlProps.ts for inspiration), might be more effort than it's worth right now
  @action.bound updateSetting<TValue = any>(
    object: any,
    name: string,
    value: TValue
  ) {
    object[name] = value;
  }

  @action.bound selectComponent(component: DashboardComponent) {
    // Make a copy of the component to edit (makes reverting changes, if the user cancels, easy)
    const componentCopy = new DashboardComponent(this, {
      componentId: component.componentId,
      settings: toJS(component.settings),
      type: component.componentType,
    });
    this.selectedComponent = componentCopy;
  }

  @action.bound cancelEditComponent() {
    this.selectedComponent = undefined;
  }

  @action.bound completeEditComponent() {
    const changedComponent = this.selectedComponent;
    const component = this.components.find(
      comp => comp.componentId === changedComponent?.componentId
    );

    if (changedComponent && component) {
      component.setSettings(changedComponent.settings);
      this.selectedComponent = undefined;
      this.hasUnsavedChanges = true;
    }
  }

  @action.bound setShowAddModal(show: boolean) {
    this.showAddModal = show;
  }

  @computed get filtersComponent():
    | DashboardComponent<IFiltersSettings>
    | undefined {
    return this.components.find(
      component => component.componentType === DashboardComponentType.Filters
    ) as DashboardComponent<IFiltersSettings>;
  }

  @computed get gridComponents() {
    return this.components.filter(comp => !!comp.gridPosition);
  }

  @computed get componentsAsJSON() {
    const exportedWidgets: IDashboardComponentJSON[] = this.components.map(
      ({ componentType, settings }) => ({
        type: componentType,
        settings,
      })
    );
    return JSON.stringify(exportedWidgets, null, 2);
  }
}

// A helper to check if a grid position actually has changed, since react-grid-layout fires an onChangeLayout on mount
// Required for hasUnsavedChanges
function hasPositionChanged(from: IGridPosition, to: IGridPosition) {
  return (
    from.x !== to.x || from.y !== to.y || from.w !== to.w || from.h !== to.h
  );
}
