import React, { useContext } from 'react';
import { DashboardComponent } from 'store/domains/dashboardComponent';

// A simple context to pass down the component domain store
export const ComponentContext = React.createContext<DashboardComponent>(
  null as any
);

export const useComponentContext = function () {
  return useContext(ComponentContext);
};
