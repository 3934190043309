import {
  IntConfigurationDto,
  IntCreateConfigurationRequestDto,
  IntCreateExtensionRequestDto,
  IntExtensionDto,
  IntExtensionLogEntry,
  IntExtensionTableDto,
  IntReplaceConfigurationRequestDto,
} from 'generated';
import {
  DeleteEndpoint,
  GetEndpoint,
  PostEndpoint,
  PutEndpoint,
  TableEndpoint,
} from 'shared/interfaces/api';
import { extensionDemoData } from './demoData/extensionDemoData';

interface ExtensionAPI {
  getAll: () => TableEndpoint<IntExtensionTableDto>;
  getDetails: GetEndpoint<string, IntExtensionDto>;
  create: PostEndpoint<undefined, IntCreateExtensionRequestDto, string>;
  delete: DeleteEndpoint<string>;
  getConfigs: (extensionId: string) => TableEndpoint<IntConfigurationDto>;
  getConfig: (
    extensionId: string,
    configId: string
  ) => GetEndpoint<string, IntConfigurationDto>;
  deleteConfig: (
    extensionId: string,
    configId: string
  ) => DeleteEndpoint<string>;
  createConfig: (
    extensionId: string
  ) => PostEndpoint<
    undefined,
    IntCreateConfigurationRequestDto,
    IntConfigurationDto
  >;
  updateConfig: (
    extensionId: string,
    configId: string
  ) => PutEndpoint<
    undefined,
    IntReplaceConfigurationRequestDto,
    IntConfigurationDto
  >;
  getLogs: (extensionId: string) => TableEndpoint<IntExtensionLogEntry>;
}

const base = '/api/extensions';

export const extensionAPI: ExtensionAPI = {
  getAll: () => ({
    url: `${base}/table`,
    mockData: extensionDemoData.getExtensionsTable,
  }),
  getDetails: {
    url: extensionId => `${base}/${extensionId}`,
    mockData: extensionDemoData.getExtensionDetails,
  },
  create: {
    url: `${base}`,
    mockData: extensionDemoData.create,
  },
  delete: {
    url: extensionId => `${base}/${extensionId}`,
    mockData: extensionDemoData.delete,
  },
  getConfigs: extensionId => ({
    url: `${base}/${extensionId}/configuration/table`,
    mockData: extensionDemoData.getConfigs,
  }),
  getConfig: (extensionId, configId) => ({
    url: `${base}/${extensionId}/configuration/${configId}`,
    mockData: extensionDemoData.getConfig,
  }),
  deleteConfig: (extensionId, configId) => ({
    url: `${base}/${extensionId}/configuration/${configId}`,
    mockData: extensionDemoData.deleteConfig,
  }),
  createConfig: extensionId => ({
    url: `${base}/${extensionId}/configuration`,
    mockData: extensionDemoData.createConfig,
  }),
  updateConfig: (extensionId, configId) => ({
    url: `${base}/${extensionId}/configuration/${configId}`,
    mockData: extensionDemoData.updateConfig,
  }),
  getLogs: extensionId => ({
    url: `${base}/${extensionId}/logs`,
    mockData: extensionDemoData.getLogs,
  }),
};
