import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      borderRadius: 12,
      background: theme.palette.background.paper,
      padding: theme.spacing(4),
      minWidth: 320,
    },
    arrow: {
      '&::before': {
        content: '" "',
        margin: 'auto',
        display: 'block',
        width: 0,
        height: 0,
        borderStyle: 'solid',
      },
      '[data-popper-placement*="bottom"] &::before': {
        borderWidth: '0px 1em 1em',
        borderColor: 'transparent transparent rgb(255, 255, 255)',
      },
      '[data-popper-placement*="right"] &': {
        left: 0,
        marginLeft: '-0.9em',
        height: '2em',
        width: '1em',
        '&::before': {
          borderWidth: '1em 1em 1em 0px',
          borderColor: 'transparent rgb(255, 255, 255) transparent transparent',
        },
      },
      '[data-popper-placement*="left"] &': {
        right: 0,
        marginRight: '-0.9em',
        height: '2em',
        width: '1em',
        '&::before': {
          borderWidth: '1em 0px 1em 1em',
          borderColor: 'transparent transparent transparent rgb(255, 255, 255)',
        },
      },
    },
    header: {
      display: 'flex',
      alignItemc: 'center',
      justifyContent: 'space-between',
      fontSize: 20,
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(2),
      borderBottom: '1px solid lightgrey',
      borderTop: '1px solid lightgrey',
    },
    headerIcon: {
      marginRight: theme.spacing(1),
    },
    assetCount: {
      marginLeft: theme.spacing(2),
      fontSize: 14,
      lineHeight: '26px',
      padding: '0 10px',
      borderRadius: 13,
      background: '#000',
      color: '#FFF',
    },
    totalDeviations: {
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      fontSize: 16,
      color: '#000',
    },
    deviationRow: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 14,
      '& + &': {
        marginTop: theme.spacing(1),
      },
    },
    deviationAssetCount: {
      fontWeight: 'bold',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      display: 'inline-block',
    },
  })
);
