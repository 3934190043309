import { IntDashboardDataStatusDto, IntPolicyDto } from 'generated';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDataSource } from 'views/Dashboard/DataSources';
import { DataPropSetting } from 'views/Dashboard/DataSources/dataPropTypes';
import { IWidgetData } from 'views/Dashboard/DataSources/dataSourceTypes';
import NumberStatusValue from 'views/Dashboard/DataStatus/NumberStatusValue';
import { INumberGridSettings } from './numberGridConfig';

interface IProps {
  data: IWidgetData | undefined;
  propId: string;
  policy?: IntPolicyDto;
  settings: INumberGridSettings;
}

function usePolicyStatus(
  policy: IntPolicyDto | undefined,
  dataPropId: DataPropSetting
): IntDashboardDataStatusDto | undefined {
  return policy?.statuses?.find(s => s.servicePropertyId === dataPropId);
}

const PropertyCard: React.FC<IProps> = ({ data, propId, policy, settings }) => {
  const { t } = useTranslation('dashboard');

  const aggregatedValues: Record<string, number> =
    data?.type === 'aggregatedData'
      ? {
          averageResult: data.result[`${propId}_avg`],
          minimumResult: data.result[`${propId}_min`],
          maximumResult: data.result[`${propId}_max`],
        }
      : {};

  const dataSource = useDataSource(settings?.dataSource);
  const viewModel = dataSource.getDataProps(getProp => ({
    props: getProp(propId),
  }));
  const { icon, decimals = 0, name, unit } = viewModel.props;

  const status = usePolicyStatus(policy, propId);

  const topBigValue = (
    <NumberStatusValue
      defaultIcon={icon}
      decimals={decimals}
      size="lg"
      value={aggregatedValues.averageResult}
      status={status}
      unit={unit}
      label={name}
    />
  );

  // If there are multiple values, show min-max
  if (aggregatedValues.minimumResult && aggregatedValues.maximumResult) {
    return (
      <>
        {topBigValue}
        {t('number_grid.range')}&nbsp;
        <NumberStatusValue
          decimals={decimals}
          size="sm"
          value={aggregatedValues.minimumResult}
          status={status}
        />
        &nbsp; &#8211; &nbsp;
        <NumberStatusValue
          decimals={decimals}
          size="sm"
          value={aggregatedValues.maximumResult}
          status={status}
          unit={unit}
        />
      </>
    );
  }

  return <>{topBigValue}</>;
};

export default observer(PropertyCard);
