import { Box } from '@mui/material';
import { Column } from 'react-table';
import { FilterOption } from '../Helpers/filterOption';
import { getOptionFilter } from '../Helpers/getOptionFilter';
import { getTextFilter } from '../Helpers/getTextFilter';
import { textRight } from '../Helpers/textRight';

export function currency<T>(options: {
  id: string;
  header: string;
  accessor: (row: T) => number;
  filter?: boolean | 'options';
  filterOptions?: FilterOption[];
  decimals?: number;
  defaultShow?: boolean;
}): Column<T> {
  const {
    id,
    header,
    accessor,
    filter,
    filterOptions = [],
    decimals = 0,
    defaultShow: show = true,
  } = options;
  const textFilter = filter === false ? undefined : getTextFilter();

  return {
    id,
    Header: <Box textAlign="right">{header}</Box>,
    className: textRight,
    accessor,
    maxWidth: 140,
    show,
    Cell: row => (
      <span>
        {row.value.toFixed(decimals)}
        <span className="text-unit">SEK</span>
      </span>
    ),
    Filter: filter === 'options' ? getOptionFilter(filterOptions) : textFilter,
  };
}
