import {
  IntServiceDataFilterOptionDto,
  IntServiceDataSpecificationFilterDto,
} from 'generated';
import { action, computed, makeObservable } from 'mobx';
import { Dashboard } from 'store/domains/dashboard';
import { FilterControlState } from './FilterControlState';

export class DashboardFilterState {
  dashboard: Dashboard;

  @computed get selectedOptions(): Record<
    string,
    IntServiceDataFilterOptionDto[]
  > {
    return (
      this.dashboard.rootStore.dashboardStore.filterValuesByDashboardId[
        this.dashboard.dashboardId
      ] || {}
    );
  }

  constructor(dashboard: Dashboard) {
    makeObservable(this);

    this.dashboard = dashboard;
  }

  getDateFilters = (): { from: Date | null; to: Date | null } => {
    const { startDate, endDate } =
      this.dashboard.rootStore.layoutStore.getGlobalDateFilters();

    return {
      from: startDate,
      to: endDate,
    };
  };

  @computed get assetFilterSpecDtos(): IntServiceDataSpecificationFilterDto[] {
    return this.controls
      .map(control => control.specificationFilterDto)
      .filter(
        filterDto => filterDto && filterDto.values?.length > 0
      ) as IntServiceDataSpecificationFilterDto[]; // If you know how to fix this without a cast, call me
  }

  @computed get controls(): FilterControlState[] {
    return (
      this.dashboard.filtersComponent?.settings.filters.map(
        (filterSettings, i) => new FilterControlState(this, filterSettings, i)
      ) || []
    );
  }

  @action.bound clearFilters() {
    this.dashboard.rootStore.dashboardStore.filterValuesByDashboardId[
      this.dashboard.dashboardId
    ] = {};
  }

  @action.bound updateValues(
    newValues: Record<string, IntServiceDataFilterOptionDto[]>
  ) {
    this.dashboard.rootStore.dashboardStore.filterValuesByDashboardId[
      this.dashboard.dashboardId
    ] = {
      ...this.selectedOptions,
      ...newValues,
    };
  }

  @computed get canClear() {
    // Return true if any filter has a value, false of they're all "clear"
    return (
      this.controls.find(control => control.selectedOptions.length > 0) !==
      undefined
    );
  }
}
