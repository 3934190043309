import { Box } from '@mui/material';
import AtlasIcon from 'components/AtlasIcon';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { ICustomCellProps } from './CustomCellProps';
import { useStyles } from './TicketStatusCell.styles';

const TicketStatusCell: React.FC<ICustomCellProps> = ({
  variant,
  text,
  title,
  iconType,
  index,
  iconColor,
  backgroundColor,
  textColor,
}) => {
  const classes = useStyles();
  return (
    <Box
      className={classes.infoBox}
      display="inline-flex"
      alignItems="center"
      bgcolor={backgroundColor}
    >
      <Box margin={'-5px 0'}>
        <AtlasIcon
          size={16}
          type={iconType ?? 'PurpurAlert'}
          style={{ color: iconColor }}
        />
      </Box>
      <Box ml={2} color={textColor} className={classes.text}>
        {text}
      </Box>
    </Box>
  );
};

export default observer(TicketStatusCell);
