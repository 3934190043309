import { createStyles, makeStyles } from '@mui/styles';
import { fontSettings } from 'styles/themes/fontSettings';
import { pxToRem } from 'styles/themes/makeAtlasTheme';

export const useStyles = makeStyles(theme =>
  createStyles({
    tableTitle: {
      fontSize: pxToRem(14),
      fontFamily: fontSettings.TeliaSansBold.fontStack,
      fontWeight: 'bold',
      textTransform: 'uppercase',
      marginLeft: pxToRem(8),
      marginBottom: theme.spacing(0.5),
    },
    error: {
      paddingLeft: '9px !important',
      '& > :not(:last-child)': {
        marginRight: theme.spacing(2),
      },
    },
    selectedCount: {
      fontSize: pxToRem(12),
      lineHeight: pxToRem(18),
    },
    helperText: {
      marginLeft: theme.spacing(2),
    },
    required: {
      '&::after': {
        content: '"*"',
        fontSize: 20,
      },
    },
    tableSettingsText: {
      fontSize: pxToRem(12),
      paddingLeft: pxToRem(4),
    },
    moreMenu: {
      '& ul': {
        padding: 0,
      },
    },
    verticalLine: {
      borderLeft: `2px solid ${theme.palette.grey[200]}`,
      height: pxToRem(25),
    },
    topActionIconGrid: {
      textAlign: 'right',
      position: 'relative',
    },
    topActionIcon: {
      position: 'absolute',
      top: pxToRem(-8),
      right: 0,
      color: theme.palette.primary.main,
      opacity: 0,
      transform: 'scale(0.4)',
      transformOrigin: 'top right',
      transition: 'all 260ms cubic-bezier(0.190, 1.000, 0.220, 1.000)',
      transitionDelay: '220ms',
      '.ResourceTable:hover &': {
        opacity: 1,
        transform: 'none',
        transition: 'all 160ms cubic-bezier(0.190, 1.000, 0.220, 1.000)',
        transitionDelay: 'none',
      },
    },
  })
);
