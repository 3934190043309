import {
  ConfigurationSetting,
  featureFlagContentType,
  featureFlagPrefix,
} from '@azure/app-configuration';
import { IResponse } from 'shared/interfaces/api';
import { rnd } from 'utils';

class FeatureDemoData {
  private _features: ConfigurationSetting[] | undefined;

  get features() {
    if (!this._features) {
      this._features = initDemoFeatures();
    }
    return this._features;
  }

  updateFeature = (
    key: string,
    feature?: ConfigurationSetting
  ): IResponse<ConfigurationSetting> => {
    let existingFeature = this.features.find(f => f.key === key);

    if (feature && existingFeature) {
      const newFeat = Object.assign(existingFeature, feature);
      this.features.splice(this.features.indexOf(existingFeature), 1, newFeat);
      return {
        status: 200,
        data: newFeat,
      };
    } else {
      return { status: 500 };
    }
  };

  getFeatures = (): IResponse<ConfigurationSetting[]> => {
    return {
      status: 200,
      data: this.features,
    };
  };

  getFeature = (params: {
    key: string;
    onlyIfChanged: boolean;
  }): IResponse<ConfigurationSetting> => {
    if (this.features.find(f => f.key === params.key)) {
      return {
        status: 200,
        data: this.features.find(f => f.key === params.key),
      };
    } else {
      return {
        status: 404,
      };
    }
  };
}

export const featureDemoData = new FeatureDemoData();

function initDemoFeatures() {
  const features: ConfigurationSetting[] = [];
  for (let i = 0; i < 30; i++) {
    features.push(genFeature(`Feature${i}`));
  }
  // add specific feature to access all we need in automation rule form
  features.push({
    isReadOnly: true,
    key: 'automation.entitytype.customer',
    contentType: featureFlagContentType,
    value: JSON.stringify({
      conditions: {
        client_filters: generateFilters(),
      },
      enabled: rnd.bool(),
      description: rnd.faker.commerce.productDescription(),
      displayName: 'automation.entitytype.customer',
      id: 'automation.entitytype.customer',
    }),
    label: 'automation.entitytype.customer',
    lastModified: rnd.date.recent(),
  });
  return features;
}

function genFeature(name: string): ConfigurationSetting {
  return {
    isReadOnly: true,
    key: `${featureFlagPrefix}${name}`,
    contentType: featureFlagContentType,
    value: JSON.stringify({
      conditions: {
        client_filters: generateFilters(),
      },
      enabled: rnd.bool(),
      description: rnd.faker.commerce.productDescription(),
      displayName: `${name}`,
      id: name,
    }),
    label: `${name}Label`,
    lastModified: rnd.date.recent(),
  };
}

function generateFilters(): {
  name: string;
  parameters?: Record<string, unknown>;
}[] {
  return rnd.item(
    [],
    [
      {
        name: 'Microsoft.Targeting',
        parameters: {
          Audience: {
            Groups: [
              { Name: 'Div X Development', RolloutPercentage: rnd.int(0, 100) },
            ],
            Users: ['firstname.lastname@teliacompany.com'],
            DefaultRolloutPercentage: rnd.int(0, 30),
          },
        },
      },
    ],
    [
      {
        name: 'Microsoft.TimeWindow',
        parameters: {
          Start: rnd.date.recent().toUTCString(),
          End: rnd.date.future().toUTCString(),
        },
      },
    ],
    [
      {
        name: 'Microsoft.Percentage',
        parameters: { Value: rnd.int(0, 100) },
      },
    ],
    [
      {
        name: 'Custom.Filter',
        parameters: {
          Custom: {
            CustomKey1: 'customValue',
            CustomKey2: 2,
          },
          Custom2: 1337,
        },
      },
    ]
  );
}
