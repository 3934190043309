import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { Box, Button, Typography } from '@mui/material';
import { appInsights } from 'appInsights';
import React from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { isDevelopment } from 'utils';

interface IErrorBoundaryState {
  hasCaughtError: boolean;
}

class ErrorBoundary extends React.Component<
  WithTranslation,
  IErrorBoundaryState
> {
  state: IErrorBoundaryState = { hasCaughtError: false };

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({
      hasCaughtError: true,
    });
    if (!isDevelopment) {
      appInsights.trackException({
        error,
        properties: errorInfo,
        severityLevel: SeverityLevel.Error,
      });
    }
  }

  render() {
    const { t, children } = this.props;
    const { hasCaughtError } = this.state;

    if (hasCaughtError) {
      return (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          position="absolute"
          left={0}
          top={0}
          width="100%"
          height="100%"
          minHeight={220}
          minWidth={200}
          borderRadius="inherit"
          bgcolor="#ffe9e9"
          border="1px dashed #ff9797"
        >
          <Box marginX={4}>
            <Typography variant="h4" color="error" gutterBottom>
              {t('error_boundary.title')}
            </Typography>
            <Typography gutterBottom>
              <Trans i18nKey="error_boundary.body" />
            </Typography>
            <Button
              onClick={() => this.setState({ hasCaughtError: false })}
              color="primary"
              variant="contained"
            >
              {t('action.reload')}
            </Button>
          </Box>
        </Box>
      );
    }

    return children;
  }
}

export default withTranslation()(ErrorBoundary);
