import { isDate } from 'date-fns';
import i18n from 'i18n';
import { TFunction } from 'i18next';
import { formatDate, formatNumber } from 'utils';
import { IDataProperty } from 'views/Dashboard/DataSources/dataPropTypes';
import { dataProp } from '../../DataSources';
import { IMapFeatureProperties } from '../ImageComponent/ImagePinLayer';
import { IMapPopupSettings } from './azureMapsConfig';

export type PopupGetter = (props: IMapFeatureProperties) => string;

export function formatValue(value: any, decimals: number, renderHtml = true) {
  if (value === true) {
    return i18n.t('common:yes');
  } else if (value === false) {
    return i18n.t('common:no');
  } else if (isDate(value)) {
    return formatDate(value, { time: true });
  } else if (value === null || value === undefined) {
    return renderHtml
      ? `<span class="text-muted">${i18n.t(
          'common:not_applicable_abbreviation'
        )}</span>`
      : i18n.t('common:not_applicable_abbreviation');
  } else if (typeof value === 'number') {
    return formatNumber(value, {
      decimals,
    });
  } else {
    return value;
  }
}

export function makePopupGetter(
  settings: IMapPopupSettings,
  getProp: (id: string) => IDataProperty,
  t: TFunction
): PopupGetter {
  const dataProps = settings.rows.map(r => getProp(r.prop));

  return (featureProperties: IMapFeatureProperties) => {
    const getRowHtml = (
      rowProp: IDataProperty,
      dataPoint: any
    ): string | undefined => {
      const { name, unit = '', decimals = 1, type } = rowProp;
      const valueRaw = dataProp.get(rowProp, dataPoint);

      if (type === 'notFound') {
        return;
      }

      const unitFormatted =
        unit && valueRaw !== null && valueRaw !== undefined
          ? `<span class="text-unit">${unit}</span>`
          : '';
      const formattedValue = Array.isArray(valueRaw)
        ? valueRaw.map(v => formatValue(v, decimals)).join(', ')
        : formatValue(valueRaw, decimals);

      return `<tr><td class="popup-table__property">${name}</td><td class="popup-table__value" title="${valueRaw}">
      ${formattedValue}${unitFormatted}
      </td>`;
    };

    const tableBody = dataProps
      .map(dp => getRowHtml(dp, featureProperties.data))
      .join('\n');

    const titleRow = `<h2 class="MuiTypography-root MuiTypography-h2 popup-title">${featureProperties.displayName}</h2>`;
    const table = `<table class="popup-table"><tbody>${tableBody}</tbody></table>`;

    return titleRow + table;
  };
}
