import { subMinutes } from 'date-fns';
import { useEffect, useState } from 'react';
import { delay, rndInt } from 'utils';
import NotificationPreview from './NotificationPreview';

export type NotificationType = 'maintenance' | 'billing' | 'status';

export interface AtlasNotification {
  id: string;
  time: string;
  isRead: boolean;
  message: string;
  type: NotificationType;
}

export async function loadNotifications(): Promise<AtlasNotification[]> {
  await delay(rndInt(1000, 3000));
  const data: AtlasNotification[] = [
    {
      id: '123123',
      type: 'maintenance',
      isRead: false,
      message:
        'Maintenance will occur on Monday, 23 September 2019, between 07:59 to 08:59 UTC.',
      time: subMinutes(new Date(), 30).toISOString(),
    },
    {
      id: '123124',
      type: 'billing',
      isRead: true,
      message:
        'Something something about the Billing and other important stuff that should be here.',
      time: subMinutes(new Date(), 120).toISOString(),
    },
    {
      id: '123125',
      type: 'status',
      isRead: true,
      message:
        'There are 12 SIM Cards that just got deactivated in Solna, Sweden within a 6 minute span.',
      time: subMinutes(new Date(), 250).toISOString(),
    },
  ];
  return data;
}

export function useNotifications() {
  const [data, setData] = useState<AtlasNotification[]>([]);

  useEffect(() => {
    async function loadIt() {
      const notificationsData = await loadNotifications();
      setData(notificationsData);
    }
    loadIt();
  }, []);

  return data;
}

export default NotificationPreview;
