import { format } from 'date-fns';
import { IResponse } from 'shared/interfaces/api';
import * as XLSX from 'xlsx';

export const validateResponse = (
  response: IResponse<BlobPart>,
  fileNamePrefix: string,
  fileFormat: string
) => {
  if (response.status === 200 && response.data) {
    saveFile({
      data: response.data,
      fileName: `${fileNamePrefix}_${format(
        new Date(),
        'yyyy-MM-dd'
      )}.${fileFormat}`,
    });
  }
};

export const saveFile = (values: { data: BlobPart; fileName: string }) => {
  const { data, fileName } = values;
  const blob = new Blob([data], { type: 'application/zip' });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
  window.URL.revokeObjectURL(link.href);
};

export const downLoadWidgetData = <T,>(
  data: Array<T>,
  cellWidth: { wch: number }[],
  fileName: string
) => {
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(data, {
    skipHeader: true,
  });

  worksheet['!cols'] = cellWidth;
  XLSX.utils.book_append_sheet(workbook, worksheet);
  XLSX.writeFile(workbook, fileName);
};
