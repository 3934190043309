import { Box } from '@mui/material';
import AtlasIcon from 'components/AtlasIcon';
import AtlasTooltip from 'components/AtlasTooltip';
import { TerminalPowerVoltageStatus } from 'generated';
import i18n from 'i18next';
import { Column } from 'react-table';
import { BatteryCell } from '../Helpers/BatteryCell';
import { ICustomCellProps } from '../Helpers/CustomCellProps';
import { getOptionFilter } from '../Helpers/getOptionFilter';

export function batteryStatus<T>(options: {
  id: string;
  accessor: (row: T) => TerminalPowerVoltageStatus | null | undefined;
  header?: string;
  defaultShow?: boolean;
  filterable?: boolean;
  width?: number;
  tooltip?: boolean;
  tooltipText?: string;
}): Column<T> {
  const {
    id,
    header = i18n.t('asset:connection_information.row_heading.battery_status'),
    accessor,
    defaultShow: show = true,
    filterable,
    width = 140,
    tooltip = false,
    tooltipText = '',
  } = options;

  const cellByStatus: Record<TerminalPowerVoltageStatus, ICustomCellProps> = {
    [TerminalPowerVoltageStatus.CriticalLow]: {
      iconType: 'BatteryLow',
      variant: 'warning',
      text: i18n.t(
        `asset:device_power_status.${TerminalPowerVoltageStatus.CriticalLow}`
      ),
    },
    [TerminalPowerVoltageStatus.Low]: {
      iconType: 'BatteryAverage',
      variant: 'default',
      text: i18n.t(
        `asset:device_power_status.${TerminalPowerVoltageStatus.Low}`
      ),
    },
    [TerminalPowerVoltageStatus.Normal]: {
      iconType: 'BatteryHigh',
      variant: 'success',
      text: i18n.t(
        `asset:device_power_status.${TerminalPowerVoltageStatus.Normal}`
      ),
    },
  };

  return {
    id,
    Header: tooltip ? (
      <AtlasTooltip placement="top" title={tooltipText} arrow>
        <Box>
          {header}
          <AtlasIcon size={12} type="Info" />
        </Box>
      </AtlasTooltip>
    ) : (
      <Box>{header}</Box>
    ),
    accessor,
    resizable: true,
    maxWidth: 140,
    width,
    show,
    filterable,
    Cell: row => {
      if (!row.value && row.value !== 0) {
        return (
          <span className="text-muted">
            {i18n.t('not_applicable_abbreviation')}
          </span>
        );
      }
      const iconProps: ICustomCellProps =
        cellByStatus[row.value as TerminalPowerVoltageStatus];
      return <BatteryCell {...iconProps} />;
    },
    Filter: getOptionFilter([
      {
        label: i18n.t('sim:label.status_all'),
        value: '',
      },
      {
        label: i18n.t(
          `asset:device_power_status.${TerminalPowerVoltageStatus.CriticalLow}`
        ),
        value: TerminalPowerVoltageStatus.CriticalLow,
      },
      {
        label: i18n.t(
          `asset:device_power_status.${TerminalPowerVoltageStatus.Low}`
        ),
        value: TerminalPowerVoltageStatus.Low,
      },
      {
        label: i18n.t(
          `asset:device_power_status.${TerminalPowerVoltageStatus.Normal}`
        ),
        value: TerminalPowerVoltageStatus.Normal,
      },
    ]),
  };
}
