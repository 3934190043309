import { Portal, StyledEngineProvider, ThemeProvider } from '@mui/material';
import FullscreenLoadingOverlay from 'components/LoadingOverlay/FullscreenLoadingOverlay';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'store';
import { setLocale as setYupErrors } from 'yup';

const AtlasThemeProvider: React.FC = ({ children }) => {
  const { t } = useTranslation();

  const {
    layoutStore: { theme },
  } = useStores();

  // Todo: this could probably be improved - so that schemas can be defined outside of components and still pick up these default errors. Consider solutions when there's time.
  useEffect(() => {
    setYupErrors({
      mixed: {
        required: t('validation:invalid.required.field'),
      },
      array: {
        min: t('validation:invalid.required.array'),
      },
    });
  }, [t]);

  if (!theme) {
    return <FullscreenLoadingOverlay />;
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Portal container={document.head}>
          <>
            {!!theme.settings.assets.styleSheets?.length && (
              <>
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" />
                {theme.settings.assets.styleSheets.map(url => (
                  <link key={url} rel="stylesheet" href={url} />
                ))}
              </>
            )}
          </>
        </Portal>
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default observer(AtlasThemeProvider);
