import {
  IntConnectivityType,
  IntProductConnectivityDetailsDto,
  IntProductDto,
  IntProductIpAccessDetailsDto,
  IntProductLocalizationDetailsDto,
  IntProductSmsAccessDetailsDto,
} from 'generated';
import { GetEndpoint, TableEndpoint } from 'shared/interfaces/api';
import { productDemoData } from './demoData/productDemoData';

interface IProductAPI {
  getAll: (customerId: string) => TableEndpoint<IntProductDto>;
  getConnectivityDetails: GetEndpoint<string, IntProductConnectivityDetailsDto>;
  getIpAccessDetails: GetEndpoint<string, IntProductIpAccessDetailsDto>;
  getSmsAccessDetails: GetEndpoint<string, IntProductSmsAccessDetailsDto>;
  getLocalizationDetails: GetEndpoint<string, IntProductLocalizationDetailsDto>;
  getProducts: GetEndpoint<undefined, IntConnectivityType[]>;
}

export const productAPI: IProductAPI = {
  getAll: id => ({
    url: `/api/products/table/${id}`,
    mockData: productDemoData.getAll,
  }),
  getConnectivityDetails: {
    url: id => `/api/products/${id}/connectivitydetails`,
    mockData: productDemoData.getConnectivityDetails,
  },
  getIpAccessDetails: {
    url: id => `/api/products/${id}/ipaccessdetails`,
    mockData: productDemoData.getIpAccessDetails,
  },
  getSmsAccessDetails: {
    url: id => `/api/products/${id}/smsaccessdetails`,
    mockData: productDemoData.getSmsAccessDetails,
  },
  getLocalizationDetails: {
    url: id => `/api/products/${id}/localizationdetails`,
    mockData: productDemoData.getLocalizationDetails,
  },
  getProducts: {
    url: '/api/connectivityproducts',
    mockData: productDemoData.getProducts,
  },
};
