import { DashboardComponentType } from 'generated';
import { componentTypeConfigs } from './ComponentTypes/componentTypeConfigs';

export function fixOldComponentSettings(
  componentType: DashboardComponentType,
  settings: any
) {
  try {
    const { fixSettings } = componentTypeConfigs[componentType];
    if (fixSettings) {
      fixSettings(settings);
    }
  } catch (error) {
    console.warn(error);
  }
}
