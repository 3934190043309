import OptionalTooltip from 'components/OptionalTooltip';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { AtlasIconType } from 'shared/types/atlas-icon';
import { useStores } from 'store';
import EditPolicyModal from 'views/Dashboard/DataStatus/EditPolicyModal';
import MenuItemContainer from './MenuItem/MenuItemContainer';
import MenuItemIcon from './MenuItem/MenuItemIcon';
import MenuItemText from './MenuItem/MenuItemText';

interface IProps {
  icon: AtlasIconType;
  label: string;
}

const EditPolicyMenuItem: React.FC<IProps> = ({ icon, label }) => {
  const { layoutStore } = useStores();

  const [showPolicyModal, setShowPolicyModal] = useState(false);

  return (
    <>
      <OptionalTooltip text={label} enabled={!layoutStore.sidenavOpen}>
        <MenuItemContainer onClick={() => setShowPolicyModal(true)}>
          <MenuItemIcon iconType={icon} />
          {layoutStore.sidenavOpen && <MenuItemText>{label}</MenuItemText>}
        </MenuItemContainer>
      </OptionalTooltip>

      <EditPolicyModal
        isOpen={showPolicyModal}
        close={() => setShowPolicyModal(false)}
      />
    </>
  );
};

export default observer(EditPolicyMenuItem);
