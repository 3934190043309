import { Alert, AlertColor } from '@mui/material';
import classNames from 'classnames';
import AtlasIcon from 'components/AtlasIcon';
import i18n from 'i18n';
import { OptionsObject, SnackbarMessage, VariantType } from 'notistack';
import { AtlasIconType } from 'shared/types/atlas-icon';
import { useStyles } from './Snackbar.styles';
import { useSnackbarRef } from './SnackbarUtilsConfigurator';

const AlertBox = (
  message: SnackbarMessage,
  severity: AlertColor,
  icon: AtlasIconType,
  className?: string
) => {
  const classes = useStyles();

  return (
    <Alert
      severity={severity}
      className={classNames(classes.root, className)}
      variant="filled"
      icon={<AtlasIcon type={icon} size={24} />}
      data-cy={`snackbar_${severity}`}
    >
      {message}
    </Alert>
  );
};

const SnackbarContent = (
  message: SnackbarMessage,
  variantType: VariantType | undefined
) => {
  const classes = useStyles();

  switch (variantType) {
    case 'success':
      return AlertBox(
        message || i18n.t('generic.success'),
        'success',
        'Added',
        classes.success
      );
    case 'error':
      return AlertBox(
        message || i18n.t('generic.error'),
        'error',
        'SmileySad',
        classes.error
      );
    case 'warning':
      return AlertBox(message, 'warning', 'Alert', classes.warning);
    case 'info':
    default:
      return AlertBox(message, 'info', 'Info');
  }
};

export const snackbar = (
  message: SnackbarMessage,
  options: OptionsObject = {}
) => {
  return useSnackbarRef.enqueueSnackbar(message, {
    ...options,
    content: (_key, msg) => SnackbarContent(msg, options.variant),
  });
};
