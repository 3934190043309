import { List, ListItem } from '@mui/material';
import classNames from 'classnames';
import AtlasIcon from 'components/AtlasIcon';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStyles } from './ListControl.styles';

interface IProps<TValue> {
  addableItems: TValue[];
  addToArray: TValue[];
  children: (item: TValue) => React.ReactNode;
  disabled?: boolean;
}

function AddItemListControl<TValue>(props: IProps<TValue>) {
  const { addableItems, addToArray, children, disabled } = props;
  const classes = useStyles();

  const handleItemClick = (item: TValue) => {
    runInAction(() => {
      addToArray.push(item);
    });
  };

  return (
    <List className={classes.rowList} dense>
      {addableItems.map((item, i) => (
        <ListItem
          key={i}
          divider={i < addableItems.length - 1}
          className={classNames(classes.row, {
            [classes.clickableRow]: !disabled,
          })}
          onClick={disabled ? undefined : () => handleItemClick(item)}
          disabled={disabled}
        >
          <AtlasIcon type="Add" className={classes.icon} />

          <div className={classes.rowContent}>{children(item)}</div>
        </ListItem>
      ))}
    </List>
  );
}

export default observer(AddItemListControl);
