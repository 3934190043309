import { IconButton, Tooltip } from '@mui/material';
import classNames from 'classnames';
import AtlasIcon from 'components/AtlasIcon';
import MessageOverlay from 'components/MessageOverlay';
import { IntDashboardDataStatusDto } from 'generated';
import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { IDashboardComponent } from 'views/Dashboard/dashboardTypes';
import Widget from 'views/Dashboard/Widget';
import { useMapStyles } from '../AzureMapsComponent/AzureMap.styles';
import { useMapInstance, useRandomId } from '../AzureMapsComponent/hooks';
import { usePolicy } from '../NumberGridComponent/usePolicy';
import IconMarkerLayer from './IconMarkerLayer';
import { IImageSettings } from './imageConfig';
import { ImageLayer } from './ImageLayer';
import ImagePinLayer from './ImagePinLayer';
import { useStyles } from './ImageWidget.styles';
import { useImageWidgetData } from './useImageWidgetData';

export type IMapPosition = [number, number];

export function usePolicyStatus(
  policyId: string,
  servicePropId: string
): IntDashboardDataStatusDto | undefined {
  const policy = usePolicy(policyId);
  return policy?.statuses?.find(
    status => status.servicePropertyId === servicePropId
  );
}

const ImageWidget: IDashboardComponent<IImageSettings> = ({ component }) => {
  const mapClasses = useMapStyles();
  const classes = useStyles();
  const { t } = useTranslation();

  const mapId = useRandomId(); // Can't be componentId, as previews in the settings wizard steps need unique ids
  const { settings } = component;

  const map = useMapInstance(mapId, { style: 'blank' });

  const policy = usePolicy(settings.policyId);

  const { mapFeatures, dataSource, getPopup, error } = useImageWidgetData(
    settings,
    {
      policy,
    }
  );

  const handleFeatureClicked = useCallback(() => {
    // To be implemented later
  }, []);

  // Zoom settings
  if (map) {
    map.setUserInteraction({ interactive: settings.enableZoom });
  }

  const isInterActive = map?.getUserInteraction().interactive;

  const fullscreenRef = React.useRef<HTMLDivElement>(null);

  const openFullscreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
      return;
    }

    // Very basic but fine for modern browsers.
    fullscreenRef.current?.requestFullscreen();
  };

  return (
    <Widget isLoading={dataSource.isLoading}>
      <Widget.Header>{settings.title}</Widget.Header>
      <Widget.Content skeleton={!map}>
        <div
          className={classNames(classes.zoomArea, {
            [classes.disabledZoom]: !isInterActive,
          })}
          ref={fullscreenRef}
        >
          <div id={mapId} className={mapClasses.map} />
          {map && (
            <>
              <ImageLayer map={map} settings={settings} />
              {policy ? (
                <IconMarkerLayer
                  map={map}
                  features={mapFeatures}
                  getPopup={getPopup}
                />
              ) : (
                // Retain the old layer as a fallback for now, to not disturb existing customers
                <ImagePinLayer
                  map={map}
                  features={mapFeatures}
                  onFeatureClick={handleFeatureClicked}
                  getPopup={getPopup}
                />
              )}
            </>
          )}
          {error && <MessageOverlay message={error} />}

          <div className={classes.buttonOverlay}>
            <Tooltip arrow title={`${t('common:action.fullscreen')}`}>
              <IconButton
                onClick={openFullscreen}
                className={classes.button}
                color="primary"
                size="large"
              >
                <AtlasIcon type="Fullscreen" size={18} />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </Widget.Content>
    </Widget>
  );
};

export default observer(ImageWidget);
