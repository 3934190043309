import { Box, Grid, Zoom } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IDashboardComponent } from 'views/Dashboard/dashboardTypes';
import { Control } from 'views/Dashboard/SettingControls';
import { useMapStyles } from '../../AzureMapsComponent/AzureMap.styles';
import { useMapInstance, useRandomId } from '../../AzureMapsComponent/hooks';
import PolicyControl from '../../NumberGridComponent/SettingsSteps/PolicyControl';
import { usePolicy } from '../../NumberGridComponent/usePolicy';
import PopupDetailsSettings from '../../PopupDetailsSettings';
import IconMarkerLayer from '../IconMarkerLayer/IconMarkerLayer';
import { IImageSettings } from '../imageConfig';
import { ImageLayer } from '../ImageLayer';
import ImagePinLayer from '../ImagePinLayer';
import { useImageWidgetData } from '../useImageWidgetData';
import { EditMapPointState } from './EditMapPointsState';
import MapPointSettingsMenu from './MapPointSettingsMenu';

const useStyles = makeStyles(theme =>
  createStyles({
    map: {
      height: 550,
      width: '100%',
      position: 'relative',
    },
    clickable: {
      boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
      '& .mapboxgl-canvas-container.mapboxgl-interactive': {
        cursor: 'crosshair',
      },
    },
    messageOverlay: {
      padding: theme.spacing(2),
      background: theme.palette.background.paper,
      color: theme.palette.text.secondary,
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[0],
      fontSize: '1.2rem',
    },
  })
);

const AddAssetsStep: IDashboardComponent<IImageSettings> = ({ component }) => {
  const { settings } = component;
  const classes = useStyles();
  const mapClasses = useMapStyles();

  const [state] = useState(() => new EditMapPointState(settings));

  const mapId = useRandomId();
  const map = useMapInstance(mapId, { style: 'blank' });

  const policy = usePolicy(settings.policyId);
  const { mapFeatures, getPopup, dataSource } = useImageWidgetData(settings, {
    showFeaturesWithoutData: true,
    policy,
    selectedPinId: state.selectedId,
  });

  const { t } = useTranslation('dashboard');

  return (
    <>
      <Box position="relative">
        <div
          id={mapId}
          className={classNames(classes.map, mapClasses.map, {
            [classes.clickable]: state.clickMode,
          })}
        />
        <Zoom in={state.clickMode !== false}>
          <Box
            position="absolute"
            left={0}
            bottom={0}
            right={0}
            textAlign="center"
            color="#FFF"
            paddingTop={4}
            className={classes.messageOverlay}
          >
            {t('image.place_point')}
          </Box>
        </Zoom>
        {map && (
          <>
            <ImageLayer map={map} settings={settings} />

            {policy ? (
              <IconMarkerLayer
                map={map}
                features={mapFeatures}
                getPopup={getPopup}
                onMarkerClicked={state.setSelectedId}
                showDisplayName
              />
            ) : (
              // Retain the old layer as a fallback for now, so as not to disturb existing customers
              <ImagePinLayer
                map={map}
                features={mapFeatures}
                onFeatureClick={state.setSelectedId}
                getPopup={getPopup}
                showDisplayName
              />
            )}

            <MapPointSettingsMenu map={map} settings={settings} state={state} />
          </>
        )}
      </Box>

      <Control.Group label={t('image.marker_settings')}>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <PolicyControl settings={settings} />
          </Grid>
          <Grid item sm={6}>
            <Control.DataProperty
              name="markerProp"
              object={settings}
              label="Property"
              clearable
              options={dataSource.dataProperties}
              allowBoolean={false}
              allowDate={false}
              allowNumber
              allowString
            />
          </Grid>
        </Grid>
      </Control.Group>

      <Control.Group label={t('popup_settings.label.add_info')}>
        <PopupDetailsSettings
          rows={settings.popup.rows}
          dataSource={dataSource}
        />
      </Control.Group>
    </>
  );
};

export default observer(AddAssetsStep);
