import { Theme } from '@mui/material';
import { Role } from 'components/Auth/Role';
import {
  IDateFilterValue,
  ISavedDateFilterValue,
} from 'components/DateFilterDropdown/dateFilterTypes';
import {
  getDateRangeFromFilterValue,
  getSavedDateFilterValue,
} from 'components/DateFilterDropdown/dateRangeOptions';
import { applyHighchartsTheme } from 'components/Highcharts';
import { snackbar } from 'components/Snackbar';
import { IntMenuItemDto, IntUiConfigurationDetailsDto } from 'generated';
import i18n from 'i18n';
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { persist } from 'mobx-persist';
import { getDemoDataMenuItems } from 'services/demoData/navigationDemoData';
import { uiConfigApi } from 'services/uiConfig.service';
import { defaultUiConfiguration } from 'styles/themes/defaultUiConfiguration';
import { parseUiConfiguration } from 'styles/themes/parseUiConfiguration';
import { uiConfigToMuiTheme } from 'styles/themes/uiConfigToMuiTheme';
import { IDateRange } from 'views/Reports/DateRangeFilter/DateRangeFilter';
import { IntNavigationDto } from './../generated/index';
import { navigationAPI } from './../services/navigation.service';
import { RootStore } from './rootStore';
import { StoreBase } from './storeBase';

// When no navigation exists, this NavigationId is active. Room for improvement.
export const staticNavigationId = 'All menu items';

export class LayoutStore extends StoreBase {
  @persist @observable sidenavOpen = true;
  @observable header = true;
  @observable footer = true;
  @persist @observable settingsMenuOpen = false;

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }

  @action.bound toggleSidenavOpen() {
    this.sidenavOpen = !this.sidenavOpen;
  }

  @action.bound toggleSettingsMenuOpen() {
    this.settingsMenuOpen = !this.settingsMenuOpen;
  }

  @action.bound toggleHeader() {
    this.header = !this.header;
  }

  @action.bound toggleFooter() {
    this.footer = !this.footer;
  }

  @persist('object') @observable savedValues: ISavedDateFilterValue | undefined;

  @action.bound setDateFilter(value: IDateFilterValue) {
    this.savedValues = {
      type: value.type,
      startDate:
        value.type === 'custom' ? value.startDate.toISOString() : undefined,
      endDate:
        value.type === 'custom' ? value.endDate.toISOString() : undefined,
    };
  }

  getGlobalDateFilters = (): IDateRange & {
    startDateString: string;
    endDateString: string;
  } => {
    const { startDate, endDate } = getDateRangeFromFilterValue(
      getSavedDateFilterValue(this.savedValues)
    );

    return {
      startDate,
      endDate,
      startDateString: startDate.toISOString(),
      endDateString: endDate.toISOString(),
    };
  };

  // Navigation
  @action.bound updateNavigation(
    dashboardMenuItem: IntMenuItemDto,
    method: 'add' | 'delete' | 'update'
  ) {
    if (!this.navigation || !dashboardMenuItem.parentId) {
      return;
    }

    const parentMenuItem = this.navigation.menuItems.find(
      item => item.menuItemId === dashboardMenuItem?.parentId
    );

    if (!parentMenuItem) {
      return;
    }

    switch (method) {
      case 'add': {
        parentMenuItem.children.push(dashboardMenuItem);
        break;
      }
      case 'update': {
        const navigationItem = parentMenuItem.children.find(
          item => item.menuItemId === dashboardMenuItem?.menuItemId
        );
        if (!navigationItem) {
          return;
        }
        navigationItem.displayName = dashboardMenuItem.displayName;
        break;
      }
      case 'delete': {
        parentMenuItem.children = parentMenuItem.children.filter(item => {
          return item.menuItemId !== dashboardMenuItem?.menuItemId;
        });
        break;
      }
    }
  }

  // Allow developers to use demo data only for the navigation call, can help trouble shooting
  @persist @observable disableNewNavigation = false;
  @action.bound toggleDisableNewNavigation() {
    this.disableNewNavigation = !this.disableNewNavigation;
    document.location.reload();
  }

  @observable navigation: IntNavigationDto | undefined;
  @observable hasFetchedNavigation = false;

  @action.bound async getNavigation() {
    if (this.disableNewNavigation) {
      this.setNavigation(null);
      return;
    }

    const resp = await this.httpGet(
      navigationAPI.getActiveNavigation,
      undefined
    );

    if (resp.status === 200 || resp.status === 204) {
      this.setNavigation(resp.data || null);
    } else {
      snackbar(i18n.t('common:error.loading.navigation'), {
        variant: 'error',
      });
    }
  }

  @action.bound async loadLayoutData() {
    await this.getNavigation();
  }

  @action.bound async setNavigation(navigation: IntNavigationDto | null) {
    const {
      dashboardStore: { loadAllDashboards },
      authStore,
    } = this.rootStore;

    // At least for now, default to showing everything in the menu when there's no navigation set up for a customer
    if (!navigation) {
      const dashboards = authStore.hasRole(Role.RoleNameViewDashboard)
        ? await loadAllDashboards()
        : [];

      runInAction(() => {
        this.navigation = {
          navigationId: staticNavigationId,
          menuItems: getDemoDataMenuItems(dashboards),
          customerId: '',
          displayName: '',
          active: true,
        };
      });
    } else {
      this.navigation = navigation;
    }

    runInAction(() => (this.hasFetchedNavigation = true));
  }

  @computed get flatMenuItems() {
    const items: IntMenuItemDto[] = [];

    const addItem = (item: IntMenuItemDto) => {
      items.push(item);
      item.children.forEach(addItem);
    };

    this.navigation?.menuItems?.forEach(addItem);

    addItem(this.myDashboardMenuItem);

    return items;
  }

  // The label of the last breadcrumb can be set dynamically for detail views etc
  @observable customBreadcrumbText = '';
  @action.bound setCustomBreadcrumb(text: string) {
    this.customBreadcrumbText = text;
  }

  @observable detailsParentBreadcrumb = { path: '', label: '' };
  @action.bound setDetailsParentBreadcrumb(label: string, path: string) {
    this.detailsParentBreadcrumb = { path, label };
  }

  // The link to "my dashboard" requires more discussion (where is it visible? is it saved like any MenuItem? is the route always the same?)
  // As of now it can be hard-coded.
  myDashboardMenuItem: IntMenuItemDto = {
    roles: '',
    children: [],
    displayName: 'My dashboard',
    externalRoute: false,
    icon: 'Home',
    menuItemId: '/',
    navigationId: '',
    order: 0,
    route: '/',
  };

  // UI Configuration
  @action.bound async loadActiveUiConfig() {
    const resp = await this.httpGet(uiConfigApi.getActive, undefined);
    this.setUiConfiguration(resp.status === 200 ? resp.data : undefined);
  }

  @action.bound setUiConfiguration(details?: IntUiConfigurationDetailsDto) {
    this.activeUiConfigurationId = details?.uiConfigurationId;

    const uiConfig = details
      ? parseUiConfiguration(details.settings)
      : defaultUiConfiguration;

    applyHighchartsTheme(uiConfig);

    this.theme = uiConfigToMuiTheme(uiConfig);
  }

  @observable activeUiConfigurationId: string | undefined;
  @observable.ref theme: Theme | undefined;
}
