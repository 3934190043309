import { snackbar } from 'components/Snackbar';
import { IntSmsCustomerDto, IntSmsUrlType } from 'generated';
import i18n from 'i18n/i18n';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { smsService } from 'services/sms.service';
import { RootStore } from 'store/rootStore';
import { StoreBase } from 'store/storeBase';

export class SmsCustomer extends StoreBase implements IntSmsCustomerDto {
  @observable customerId = '';
  @observable customerName = '';
  @observable moUrl = '';
  @observable dlrUrl = '';
  @observable dlrEnabled = false;

  constructor(rootStore: RootStore, obj: IntSmsCustomerDto) {
    super(rootStore);
    makeObservable(this);
    Object.assign(this, obj);
  }

  @action.bound async setSmsUrl(url: string, type: IntSmsUrlType) {
    const resp = await this.httpPatch(
      smsService.setUrl,
      {
        params: this.customerId,
        data: { url, type },
      },
      this.setIsLoading
    );

    runInAction(() => {
      if (resp.status === 200) {
        switch (type) {
          case IntSmsUrlType.Mo: {
            this.moUrl = url;
            break;
          }
          case IntSmsUrlType.Dlr: {
            this.dlrUrl = url;
            break;
          }
          default: {
            break;
          }
        }
        snackbar(i18n.t('smshub:action.url.success'), { variant: 'success' });
      } else {
        this.rootStore.smsStore.error = resp?.statusText;
        snackbar(resp.statusText, { variant: 'error' });
      }
    });

    return resp;
  }

  @action.bound async toggleDlrState() {
    const resp = await this.httpPatch(
      smsService.setDlrState,
      {
        params: { customerId: this.customerId, state: !this.dlrEnabled },
      },
      this.setIsLoading
    );

    runInAction(() => {
      if (resp.status === 200) {
        this.dlrEnabled = !this.dlrEnabled;
        snackbar(i18n.t('smshub:action.state.success'), { variant: 'success' });
      } else {
        this.rootStore.smsStore.error = resp?.statusText;
        snackbar(resp.statusText, { variant: 'error' });
      }
    });
  }
}
