export const networkAddress =
  /^([0-9]{1,3}\.){3}[0-9]{1,3}(\/([0-9]|[1-2][0-9]|3[0-2]))?$/;
export const ipAddress =
  /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
export const guid =
  /^([0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12})$/i;
export const psk = /^[1-9a-zA-Z._][0-9a-zA-Z._]*$/;
export const defaultRestriction = /^(?!.*?0.0.0.0).*?[^.0]$/;
export const password = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{12,}$/;
export const city =
  /^['\da-zA-Z\u0080-\u024F]+(?:(?:[\s\-']|(?:\s))['a-zA-Z\u0080-\u024F]+)*$/;
export const cron =
  /(@(annually|yearly|monthly|weekly|daily|hourly|reboot))|(@every (\d+(ns|us|µs|ms|s|m|h))+)|((((\d+,)+\d+|(\d+(\/|-)\d+)|\d+|\*) ?){5,7})/;
export const kubernetesArtifactName = /^[0-9a-z](-*[0-9a-z])*$/;
export const macAddress = /^([0-9a-fA-F]{2}[:]){5}([0-9a-fA-F]{2})$/;
export const name = /^([a-zA-Z0-9-_ ]+)$/;
