import { Box, Typography } from '@mui/material';
import MessageOverlay from 'components/MessageOverlay';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { formatNumber } from 'utils';
import { IDashboardComponent } from 'views/Dashboard/dashboardTypes';
import { useDataSource, useDataSourceData } from 'views/Dashboard/DataSources';
import { getDataPropId } from 'views/Dashboard/DataSources/getDataProp';
import Widget from 'views/Dashboard/Widget';
import { INumberSettings } from './numberConfig';

const NumberWidget: IDashboardComponent<INumberSettings> = ({ component }) => {
  const { t } = useTranslation('dashboard');
  const { settings } = component;
  const fontColor =
    settings.backgroundColor === '#000000' ? 'white' : undefined;
  const dataSource = useDataSource(settings.dataSource);

  if (dataSource.getUsedAggregates) {
    dataSource.getUsedAggregates([settings.valueProp]);
  }

  const { data, noContent, error } = useDataSourceData(
    dataSource,
    component.componentType
  );

  const aggregateData: number | undefined =
    data?.type === 'aggregatedData'
      ? Object.values(data?.result)[0]
      : undefined;

  const getDecimals =
    dataSource.dataProperties.find(
      p => p.id === getDataPropId(settings.valueProp)
    )?.decimals ?? 1;

  return (
    <Widget
      isLoading={!dataSource.isInitialized || dataSource.isLoading}
      backgroundColor={settings.backgroundColor}
      widgetType="NumberWidget"
    >
      <Widget.Header color={fontColor}>{settings.title}</Widget.Header>
      <Widget.Content
        skeleton={!dataSource.isInitialized}
        backgroundColor={settings.backgroundColor}
        color={fontColor}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="100%"
          paddingBottom={3}
        >
          {aggregateData && (
            <Typography style={{ fontSize: settings.textSize }}>
              {formatNumber(aggregateData, {
                decimals: getDecimals,
              })}
              &nbsp;
              {settings.unit}
            </Typography>
          )}
        </Box>

        {noContent && <MessageOverlay message={t('no_data')} />}
        {error && <MessageOverlay message={error} />}
      </Widget.Content>
    </Widget>
  );
};

export default observer(NumberWidget);
