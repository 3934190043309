import { Button, Dialog, DialogContent } from '@mui/material';
import DialogTitleWrapper from 'components/DialogTitleWrapper';
import LoadingOverlay from 'components/LoadingOverlay';
import ModalFooterWithButtons from 'components/ModalFooterWithButtons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './MultiDialog.styles';

type DialogType = 'simple' | 'confirm';
type WidthType = 'sm' | 'xs' | 'md' | 'lg' | 'xl';

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  show: boolean;
  title: string;
  onClose: () => void;
  acceptButtonText?: string;
  cancelButtonText?: string;
  onConfirm?: () => void;
  type?: DialogType;
  maxWidth?: WidthType;
  isLoading?: boolean;
  dataCy?: string;
  disableAcceptButton?: boolean;
}

const MultiDialog: React.FC<IProps> = props => {
  const {
    type = 'simple',
    maxWidth = 'sm',
    show,
    onClose,
    onConfirm,
    title,
    children,
    acceptButtonText,
    cancelButtonText,
    isLoading = false,
    disableAcceptButton = false,
    dataCy = 'modalConfirm',
  } = props;

  const { t } = useTranslation();
  const classes = useStyles();
  let leftButtons: JSX.Element | undefined;
  let rightButtons: JSX.Element;

  if (type === 'confirm') {
    leftButtons =
      cancelButtonText !== undefined ? (
        <Button
          color="primary"
          variant="outlined"
          data-cy="modalCancel"
          onClick={onClose}
          disabled={isLoading}
        >
          {cancelButtonText}
        </Button>
      ) : undefined;

    rightButtons = (
      <Button
        color="primary"
        variant="contained"
        data-cy={dataCy}
        onClick={onConfirm}
        disabled={isLoading || disableAcceptButton}
      >
        {acceptButtonText}
      </Button>
    );
  } else {
    rightButtons = (
      <Button
        color="primary"
        variant="contained"
        onClick={onClose}
        data-cy="modalOk"
      >
        {t('action.ok')}
      </Button>
    );
  }

  return (
    <Dialog open={show} onClose={onClose} maxWidth={maxWidth} fullWidth>
      <LoadingOverlay isLoading={isLoading}>
        <DialogTitleWrapper id={title} onClose={onClose} title={title} />
        <DialogContent className={classes.dialogContent} data-cy="modalContent">
          {children}
        </DialogContent>
        <ModalFooterWithButtons
          leftButtons={leftButtons}
          rightButtons={rightButtons}
        />
      </LoadingOverlay>
    </Dialog>
  );
};

export default MultiDialog;
