import AddItemToList from './AddItemToListControl';
import AssetSelect from './AssetSelectControl';
import AutoComplete from './AutoCompleteControl';
import ButtonGroup from './ButtonGroupControl';
import Checkbox from './CheckboxControl';
import ControlGroup from './ControlGroup';
import DataProperty from './DataPropertyControl';
import ImageUpload from './ImageUploadControl';
import NumberControl from './NumberControl';
import PlotBands from './PlotBandsControl';
import RadioGroup from './RadioGroup';
import Select from './SelectControl';
import SortableList from './SortableListControl';
import Switch from './SwitchControl';
import Text from './TextControl';

export const Control = {
  DataProperty,
  Select,
  Text,
  PlotBands,
  Number: NumberControl,
  AutoComplete,
  Switch,
  ButtonGroup,
  SortableList,
  AddItemToList,
  Checkbox,
  ImageUpload,
  AssetSelect,
  Group: ControlGroup,
  RadioGroup,
};
