import { ProfileDescriptorStatus } from 'generated';
import i18n from 'i18next';
import { Column } from 'react-table';
import { CustomCell } from '../Helpers/CustomCell';
import { ICustomCellProps } from '../Helpers/CustomCellProps';
import { getOptionFilter } from '../Helpers/getOptionFilter';

export function profileStatus<T>(options: {
  id: string;
  accessor: (row: T) => ProfileDescriptorStatus;
  header?: string;
  show?: boolean;
  filterable?: boolean;
}): Column<T> {
  const { id, header = 'Status', accessor, show = true, filterable } = options;

  const cellByStatus: Record<ProfileDescriptorStatus, ICustomCellProps> = {
    [ProfileDescriptorStatus.Unknown]: {
      iconType: 'StatusUnknown',
      title: i18n.t(
        `apnhub:profile_status.tooltip.${ProfileDescriptorStatus.Unknown}`
      ),
      text: i18n.t(`apnhub:profile_status.${ProfileDescriptorStatus.Unknown}`),
    },
    [ProfileDescriptorStatus.Pending]: {
      iconType: 'StatusPending',
      title: i18n.t(
        `apnhub:profile_status.tooltip.${ProfileDescriptorStatus.Pending}`
      ),
      text: i18n.t(`apnhub:profile_status.${ProfileDescriptorStatus.Pending}`),
    },
    [ProfileDescriptorStatus.Ready]: {
      iconType: 'StatusOk',
      title: i18n.t(
        `apnhub:profile_status.tooltip.${ProfileDescriptorStatus.Ready}`
      ),
      text: i18n.t(`apnhub:profile_status.${ProfileDescriptorStatus.Ready}`),
    },
    [ProfileDescriptorStatus.Error]: {
      iconType: 'StatusError',
      title: i18n.t(
        `apnhub:profile_status.tooltip.${ProfileDescriptorStatus.Error}`
      ),
      text: i18n.t(`apnhub:profile_status.${ProfileDescriptorStatus.Error}`),
    },
  };

  return {
    id,
    Header: header,
    accessor,
    resizable: false,
    maxWidth: 130,
    filterable,
    show,
    Cell: row => {
      const iconProps =
        cellByStatus[row.value as ProfileDescriptorStatus] || {};
      return <CustomCell {...iconProps} index={row.index} />;
    },
    Filter: getOptionFilter([
      {
        label: i18n.t('all'),
        value: '',
      },
      {
        label: i18n.t(
          `apnhub:profile_status.${ProfileDescriptorStatus.Unknown}`
        ),
        value: ProfileDescriptorStatus.Unknown,
      },
      {
        label: i18n.t(
          `apnhub:profile_status.${ProfileDescriptorStatus.Pending}`
        ),
        value: ProfileDescriptorStatus.Pending,
      },
      {
        label: i18n.t(`apnhub:profile_status.${ProfileDescriptorStatus.Ready}`),
        value: ProfileDescriptorStatus.Ready,
      },
    ]),
  };
}
