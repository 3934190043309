import { Box } from '@mui/material';
import AtlasIcon from 'components/AtlasIcon';
import { Column } from 'react-table';
import { TypedAggregator } from '../PivotCells/TypedAggregator';

export interface IPolicyWarningColumnPivotData {
  warnings: number;
  total: number;
}

const aggregateNumberColumn: TypedAggregator<
  number | null,
  IPolicyWarningColumnPivotData | null
> = values => {
  const nonNullValues = values.filter(Boolean) as number[];

  const result: IPolicyWarningColumnPivotData = {
    warnings: nonNullValues.length,
    total: values.length,
  };

  return result;
};

function sortPolicyWarningsColumn(
  a: IPolicyWarningColumnPivotData | number,
  b: IPolicyWarningColumnPivotData | number
): number {
  const getValueToSortBy = (
    rawValue: IPolicyWarningColumnPivotData | number
  ): number => {
    if (typeof rawValue === 'number') {
      return rawValue;
    }

    return rawValue.warnings;
  };

  return getValueToSortBy(a) - getValueToSortBy(b);
}

export function policyWarnings(): Column<any> {
  return {
    id: '__status',
    Header: 'Status',
    width: 80,
    accessor: row => row.__totalPolicyWarnings,
    aggregate: aggregateNumberColumn,
    sortMethod: sortPolicyWarningsColumn,
    Aggregated: cellInfo => {
      const value = cellInfo.value as IPolicyWarningColumnPivotData;

      if (value.warnings) {
        return (
          <Box
            bgcolor={theme => theme.palette.error.main}
            borderRadius={4}
            display="inline-flex"
            alignItems="center"
            padding="1px 9px"
            color="#FFF"
            fontSize="12px"
          >
            {value.warnings} / {value.total}
            &nbsp;
            <AtlasIcon type="Alert" size={14} inline />
          </Box>
        );
      }

      return null;
    },
    Cell: () => null,
  };
}
