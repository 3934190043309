import { DashboardComponentType } from 'generated';
import { IDataSourceConfig } from '../../dataSourceTypes';

export interface IAutomationActivityDataSourceSettings {
  type: 'automationRuleActivity';
}

export type IAutomationActivity = Partial<Record<string, Date>>;

export const automationRuleActivityDataSourceConfig: IDataSourceConfig<IAutomationActivityDataSourceSettings> =
  {
    icon: 'Coffee',
    settingsSteps: [],
    getDefaultSettings: async () => {
      return {
        type: 'automationRuleActivity',
      };
    },
    enabledComponentTypes: {
      [DashboardComponentType.Table]: true,
    },
    shouldShowDateFilter: false,
  };
