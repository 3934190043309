import {
  IntServiceDataFilterOptionDto,
  IntServiceDataFilterOptionResponseDto,
  ServiceDataFilterType,
} from 'generated';
import i18n from 'i18n';
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { serviceApi } from 'services/service.service';
import { StoreBase } from 'store/storeBase';
import { IAssetFilterSettings } from 'views/Dashboard/ComponentTypes/FiltersComponent/filtersConfig';
import { AssetFilterSettingsState } from './AssetFilterSettingsState';
import { getFilterKey } from './getFilterKey';

export class FilterOptionState extends StoreBase {
  parentState: AssetFilterSettingsState;
  filterOption: IAssetFilterSettings;
  displayName: string;

  constructor(
    parentState: AssetFilterSettingsState,
    filterOption: IAssetFilterSettings,
    displayName: string
  ) {
    super(parentState.rootStore);
    makeObservable(this);
    this.parentState = parentState;
    this.filterOption = filterOption;
    this.displayName = displayName;
  }

  @computed get depString() {
    return JSON.stringify(this.otherFilters);
  }

  @computed get filterSpecification() {
    return this.parentState.filterSpecDtos.find(
      o => getFilterKey(o) === getFilterKey(this.filterOption)
    );
  }

  @computed get valueCount() {
    return this.filterSpecification?.values.length ?? 0;
  }

  @computed get firstSelectedValue() {
    return this.selectedNames[0];
  }

  @computed get selectedValuesCount() {
    return this.selectedNames.length > 1
      ? `, +${this.selectedNames.length - 1}`
      : '';
  }

  @computed get key() {
    return getFilterKey(this.filterOption);
  }

  @action.bound clearValues() {
    const {
      parentState: { filters },
      filterOption: options,
    } = this;

    filters.splice(
      filters.findIndex(f => getFilterKey(f) === getFilterKey(options)),
      1
    );
  }

  @action.bound toggleFilterValue(
    option: IntServiceDataFilterOptionDto,
    force?: boolean
  ) {
    const {
      parentState: { filters },
      filterOption: { type, definitionId },
    } = this;

    const item = filters.find(f => getFilterKey(f) === this.key);

    this.cachedNames[option.id] = option.name; // Keep the name to save a request for it

    const check = force ?? !item?.values.includes(option.id);

    if (check) {
      if (item) {
        item.values.push(option.id);
      } else {
        filters.push({
          type,
          definitionId,
          values: [option.id],
        });
      }
    } else {
      // Remove value
      if (item) {
        item.values = item.values.filter(v => v !== option.id);
        if (item.values.length === 0) {
          // We removed the last value, delete entire item
          filters.splice(filters.indexOf(item), 1);
        }
      }
    }
  }

  @observable search = '';
  searchTimer?: number;
  @action.bound setSearch(search: string) {
    this.search = search;

    this.page = 0; // bad
    this.loadOptions();
  }

  @observable response: IntServiceDataFilterOptionResponseDto | undefined;
  page = 0;

  @action.bound resetAndLoadOptions() {
    this.search = '';
    this.page = 0;
    this.loadOptions();
  }

  // All other selected filters, to filter these filters. Filters.
  @computed get otherFilters() {
    return this.parentState.filterSpecDtos.filter(
      f => getFilterKey(f) !== this.key
    );
  }

  @action.bound async loadOptions() {
    const { type, definitionId } = this.filterOption;

    const resp = await this.httpPost(serviceApi.getFilterOptions, {
      params: undefined,
      data: {
        filters: this.otherFilters,
        page: this.page,
        pageSize: 100,
        search: this.search,
        type,
        definitionId,
        includeEntityCount: true,
      },
    });

    this.setResponse(resp.data, this.page);
  }

  @action.bound setResponse(
    response: IntServiceDataFilterOptionResponseDto | undefined,
    page: number
  ) {
    if (page > 0 && response && this.response) {
      this.response = {
        filterOptions: [
          ...this.response.filterOptions,
          ...response.filterOptions,
        ],
        hasMore: response.hasMore,
      };
    } else {
      this.response = response;
    }
  }

  @action.bound loadMore() {
    this.page += 1;
    this.loadOptions();
  }

  @computed get selectedNames(): string[] {
    if (this.isLoadingSelectedNames) {
      return [];
    }
    return this.selectedOptions.map(opt => opt.name);
  }

  // Save a record of option names to quickly display them without constant reloading if the user is clicking wildly
  @observable cachedNames: Record<string, string> = {};
  @computed get selectedOptions(): IntServiceDataFilterOptionDto[] {
    // These filter types come from database entities and need to be loaded
    const needToLoadNames = [
      ServiceDataFilterType.AssetId,
      ServiceDataFilterType.CustomerId,
      ServiceDataFilterType.ResourceGroupId,
      ServiceDataFilterType.DealerId,
    ].includes(this.filterOption.type);

    const idsWithoutNames: string[] = [];
    const getOptionName = (id: string) => {
      if (needToLoadNames) {
        if (this.cachedNames[id] === undefined) {
          idsWithoutNames.push(id);
          this.cachedNames[id] = `${i18n.t('loading')}...`;
        }
        return this.cachedNames[id];
      }

      // Otherwise, id === name, since both are simple strings
      return id;
    };

    const selectedOptions =
      this.filterSpecification?.values.map(id => ({
        name: getOptionName(id),
        id,
        hierarchyName: '',
        children: [],
      })) || [];

    if (idsWithoutNames.length) {
      this.loadOptionNames(idsWithoutNames);
    }

    return selectedOptions;
  }

  @action.bound async loadOptionNames(idsToLoad: string[]) {
    const { type, definitionId } = this.filterOption;
    const resp = await this.httpPost(
      serviceApi.getFilterOptions,
      {
        params: undefined,
        data: {
          filters: [{ type, values: idsToLoad }],
          page: this.page,
          pageSize: 999999,
          search: '',
          type,
          definitionId,
          includeEntityCount: false,
        },
      },
      isLoading => runInAction(() => (this.isLoadingSelectedNames = isLoading))
    );

    if (resp.status === 200) {
      runInAction(() => {
        resp.data?.filterOptions.forEach(fo => {
          this.cachedNames[fo.id] = fo.name;
        });
      });
    }
  }
  @observable isLoadingSelectedNames = false;

  @computed get unselectedOptions(): IntServiceDataFilterOptionDto[] {
    return (
      this.response?.filterOptions.filter(
        o => !this.filterSpecification?.values.find(so => so.includes(o.id))
      ) || []
    );
  }
}
