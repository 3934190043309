import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'store';
import FullscreenLoadingOverlay from '../LoadingOverlay/FullscreenLoadingOverlay';

const AuthProvider: React.FC = ({ children }) => {
  const {
    authStore: { hasFetchedUser },
  } = useStores();

  if (!hasFetchedUser) {
    return <FullscreenLoadingOverlay />;
  }

  return <>{children}</>;
};

export default observer(AuthProvider);
