import * as azureMaps from 'azure-maps-control';
import { useEffect, useMemo, useRef, useState } from 'react';
import { PopupGetter } from '../../AzureMapsComponent/getPopupContent';
import { IMapFeature } from '../ImagePinLayer';

export function useMarkerPopup(
  map: azureMaps.Map,
  popupGetter: PopupGetter,
  opts?: { disableClickPopup?: boolean }
) {
  const [popup] = useState(
    new azureMaps.Popup({
      pixelOffset: [0, 0],
    })
  );

  useEffect(() => {
    popup.attach(map);
    return () => popup.remove();
  }, [map, popup]);

  const clickedIdRef = useRef('');

  return useMemo(
    () => ({
      addPopupEventsToMarker: (
        marker: azureMaps.HtmlMarker,
        feature: IMapFeature
      ) => {
        const { position, properties, id } = feature;

        const openPopupOnMarker = (clicked = false) => {
          popup.setOptions({
            position,
            content: popupGetter(properties),
          });
          popup.open();

          // Block clicks on pins behind tooltip when clicked open, but don't mess up mouseLeave events when hovered
          popup
            .getPopupContainer()
            .classList.toggle('enable-pointer-events', clicked);

          // If clicked, open a more sticky popup and disable hover listeners until closed.
          if (clicked) {
            popup._addEventListener(
              'close',
              () => {
                clickedIdRef.current = '';
              },
              true // Once
            );
          }
        };

        if (!opts?.disableClickPopup) {
          map.events.add('click', marker, () => {
            clickedIdRef.current = id;
            openPopupOnMarker(true);
          });
        }
        map.events.add('mouseenter', marker, () => {
          if (!clickedIdRef.current) {
            openPopupOnMarker();
          }
        });
        map.events.add('mouseleave', marker, () => {
          if (!clickedIdRef.current) {
            popup.close();
          }
        });
      },
    }),
    [map, popup, popupGetter, opts?.disableClickPopup]
  );
}
