import { IntServiceDataSpecificationFilterDto } from 'generated';

export function combineServiceDataFilters(
  a: IntServiceDataSpecificationFilterDto[],
  b: IntServiceDataSpecificationFilterDto[]
) {
  const getExistingFilter = (
    arr: IntServiceDataSpecificationFilterDto[],
    filter: IntServiceDataSpecificationFilterDto
  ) =>
    arr.find(
      item =>
        item.type === filter.type && item.definitionId === filter.definitionId
    );

  const combinedResult: IntServiceDataSpecificationFilterDto[] = [];

  const addFilter = (item: IntServiceDataSpecificationFilterDto) => {
    const existingFilter = getExistingFilter(combinedResult, item);
    if (existingFilter) {
      // Intersect both value arrays, selecting values present in both
      existingFilter.values = existingFilter.values.filter(value =>
        item.values.includes(value)
      );
    } else {
      combinedResult.push({ ...item, values: [...item.values] });
    }
  };

  const hasValues = (filterDto: IntServiceDataSpecificationFilterDto) =>
    filterDto.values.length > 0;

  a.filter(hasValues).forEach(addFilter);
  b.filter(hasValues).forEach(addFilter);

  return combinedResult;
}
