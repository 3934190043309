import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    dropzone: {
      borderWidth: 2,
      borderStyle: 'dashed',
      borderColor: theme.palette.primary.main,
      borderRadius: 6,
      height: 400,

      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },
    imagePreview: {
      maxWidth: 350,
      maxHeight: 330,
    },
  })
);
