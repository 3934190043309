import { IUiConfiguration } from './IUiConfiguration';

export const defaultUiConfiguration: IUiConfiguration = {
  logoUrl: '',
  colors: {
    primary: '#990ae3',
    secondary: '#fef',
  },
  typography: {
    headerFont: 'Pebble',
    bodyFont: 'TeliaSansReg',
  },
  navigation: {
    background: '#380354',
    menuItem: {
      colors: {
        active: '#af3ce8',
      },
    },
  },
};
