import LoadingOverlay from 'components/LoadingOverlay';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useStores } from 'store';

interface IProps {}

const LoadServiceDetails: React.FC<IProps> = ({ children }) => {
  const {
    dashboardStore: { serviceOptions, loadServiceOptions },
  } = useStores();

  useEffect(() => {
    loadServiceOptions();
  }, [loadServiceOptions]);

  if (!serviceOptions) {
    return <LoadingOverlay isLoading />;
  }

  return <>{children}</>;
};

export default observer(LoadServiceDetails);
