import { InfiniteScrollAutoComplete } from 'components/Inputs';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'store';
import { AssetSelectState } from './AssetSelectState';

interface IProps<T> {
  object: T;
  id: string;
  placeholder?: string;
  clearable?: boolean;
}

function AssetSelectControl<TObj extends { assetId: string }>(
  props: IProps<TObj>
) {
  const { object, id, placeholder, clearable = false } = props;

  const { t } = useTranslation();

  const rootStore = useStores();
  const state = useMemo(
    () => new AssetSelectState(rootStore, object),
    [rootStore, object]
  );

  return (
    <InfiniteScrollAutoComplete
      options={state.options}
      value={state.selectedOption}
      onChange={state.handleChange}
      placeholder={placeholder}
      multiple={false}
      getOptionLabel={option => option.name}
      fetchMore={state.fetchMore}
      getOptionValue={opt => opt.id}
      hasMore={state.hasMore}
      id={id}
      label={t('dashboard:filter.asset')}
      search={state.search}
      onSearchChange={state.handleSearchChanged}
      isLoading={state.isLoading}
      clearable={clearable}
      disabled={!state.hasLoadedInitialValue}
    />
  );
}

export default observer(AssetSelectControl);
