import { ServiceDataFilterType } from 'generated';
import { IDashboardFilterSettings } from '../filtersConfig';

export function onlyUnusedFilters<T extends IDashboardFilterSettings>(
  usedFilters: IDashboardFilterSettings[],
  allFilters: T[]
): T[] {
  return allFilters.filter(
    filter =>
      !usedFilters.find(usedFilter => {
        if (
          filter.entity === 'asset' &&
          usedFilter.entity === 'asset' &&
          filter.type === ServiceDataFilterType.DynamicAttribute &&
          usedFilter.type === ServiceDataFilterType.DynamicAttribute
        ) {
          return filter.definitionId === usedFilter.definitionId;
        }

        return (
          usedFilter.entity === filter.entity && usedFilter.type === filter.type
        );
      })
  );
}
