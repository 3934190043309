import { TextField } from '@mui/material';
import { useField } from 'formik';
import React from 'react';

interface IProps {
  name: string;
  label: string;
  required?: boolean;
  min?: number;
  max?: number;
  step?: number;
  disabled?: boolean;
  autoFocus?: boolean;
}

const NumberField: React.FC<IProps> = ({
  name,
  label,
  required,
  min,
  max,
  step,
  disabled,
  autoFocus,
}) => {
  const [field, meta] = useField(name);

  return (
    <TextField
      id={name}
      type="number"
      label={label}
      fullWidth
      autoFocus={autoFocus}
      error={meta.touched && !!meta.error}
      helperText={meta.touched && meta.error}
      required={required}
      disabled={disabled}
      data-cy={`formControl-${name}`}
      inputProps={{
        min,
        max,
        step,
      }}
      variant="outlined"
      {...field}
      value={field.value ?? ''}
    />
  );
};

export default NumberField;
