import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'block',
      border: '1px solid #dddddd',
      padding: theme.spacing(3),
      width: '100%',
      textAlign: 'start',
      fontSize: 16,
      '&:not(:first-of-type)': {
        borderTopColor: 'transparent',
      },
    },
    active: {
      borderColor: `${theme.palette.primary.main} !important`, // Overwrite borderTopColor
      color: theme.palette.primary.main,
    },
  })
);
