import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    previewPicture: {
      width: '3.375rem',
      display: 'block',
    },
    divider: {
      width: '87%',
      height: '0.063rem',
    },
    pictureRound: {
      borderRadius: 999,
    },
  })
);
