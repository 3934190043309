import { createStyles, makeStyles } from '@mui/styles';
import { fontSettings } from 'styles/themes/fontSettings';
import { pxToRem } from 'styles/themes/makeAtlasTheme';

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'block',
      fontFamily: fontSettings.TeliaSansMedium.fontStack,
      fontSize: pxToRem(12),
      fontWeight: 'bold',
      color: theme.palette.grey[700],
    },
  })
);
