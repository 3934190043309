import { Box, Typography } from '@mui/material';
import classNames from 'classnames';
import AtlasIcon from 'components/AtlasIcon';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useComponentContext } from '../contexts/componentContext';
import useElementSize from './useElementSize';
import { useStyles } from './WidgetHeader.styles';
import WidgetHeaderButton from './WidgetHeaderButton';

interface IProps {
  color?: string;
  centerContent?: JSX.Element;
  buttons?: JSX.Element;
}

const WidgetHeader: React.FC<IProps> = ({
  children,
  color,
  centerContent,
  buttons,
}) => {
  const { t } = useTranslation('dashboard');
  const classes = useStyles();
  const component = useComponentContext();
  const { gridPosition, remove, dashboard } = component;

  const [widthRef, { elementWidth }] = useElementSize();
  const isSmallWidget = elementWidth < 320;

  const handleRemoveClick = () => {
    if (window.confirm(t('confirm_delete_component'))) {
      remove();
    }
  };

  const handleSettingsClick = () => {
    dashboard.selectComponent(component);
  };

  const enableSettingButtons =
    dashboard.isEditable &&
    !dashboard.selectedComponent &&
    !dashboard.showAddModal;

  if (dashboard.isStatic && !children) {
    return null;
  }

  return (
    <div
      className={classNames(classes.root, {
        [classes.smallWidget]: isSmallWidget && centerContent?.props.children,
      })}
      ref={widthRef}
    >
      <Typography
        variant="h6"
        className={classNames('drag-handle', classes.title, {
          [classes.draggable]: gridPosition?.isDraggable,
        })}
        style={{ color }}
        data-cy="widgetHeader"
      >
        {children}
      </Typography>

      <Box display="flex" justifyItems="center" className="center-content">
        {centerContent}
      </Box>

      <div className={classNames(classes.buttonList, 'button-list')}>
        {buttons}

        {enableSettingButtons && (
          <>
            <WidgetHeaderButton
              onClick={handleSettingsClick}
              data-cy="openWidgetSettingsButton"
            >
              <AtlasIcon type="Settings" size={16} />
            </WidgetHeaderButton>
            <WidgetHeaderButton
              onClick={handleRemoveClick}
              data-cy="removeWidgetButton"
            >
              <AtlasIcon type="Clear" size={16} />
            </WidgetHeaderButton>
          </>
        )}
      </div>
    </div>
  );
};

export default observer(WidgetHeader);
