import axios from 'axios';
import { create } from 'mobx-persist';
import React from 'react';
import ReactDOM from 'react-dom';
import { rootStore } from 'store/RootStoreContext';
import { isPreview } from 'utils/constants';
import yupMethods from 'yupMethods';
import App from './App';
import * as serviceWorker from './serviceWorker';

const hydrate = create({
  storage: localStorage,
  jsonify: true,
});

/**
 * Improvement down the line
 * - Add a default axios config globally, with interceptors and such.
 * - Move this to its own axios config file
 * This works for now.
 */
axios.defaults.withCredentials = true;

if (isPreview) {
  axios.defaults.baseURL = process.env.REACT_APP_APIM_URL;
}

export default axios;

Promise.all([
  hydrate('layout', rootStore.layoutStore),
  hydrate('root', rootStore),
  hydrate('dashboard', rootStore.dashboardStore),
  hydrate('connectivityUnit', rootStore.connectivityUnitStore),
]).then(() => {
  rootStore.authStore.getUserAuthData();
  rootStore.layoutStore.loadActiveUiConfig();

  yupMethods();

  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
