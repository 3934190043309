import { createStyles, makeStyles } from '@mui/styles';

export const useItemStyles = makeStyles(theme => {
  const themeSettings = theme.settings.components.navigation.menuItem;

  return createStyles({
    root: {
      display: 'flex',
      cursor: 'pointer',
      textDecoration: 'none',
      transition: 'background-color 120ms ease-out',

      height: themeSettings.height,
      width: '100%',
      color: '#FFF',

      '&:hover': {
        transition: 'none',
        backgroundColor: 'hsla(0,0%,100%,.1)',
      },
    },
    active: {
      backgroundColor: themeSettings.colors.active,
    },
    categoryHeader: {
      backgroundColor: themeSettings.colors.header,
    },
    minimized: {
      justifyContent: 'center',
      position: 'relative',
    },
    inset: {
      paddingLeft: themeSettings.iconSize,
    },
  });
});
