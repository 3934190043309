import format from 'date-fns/format';
import { Options, PointOptionsObject } from 'highcharts';
import i18n from 'i18next';
import { isEmpty } from 'lodash';
import { formatNumber } from 'utils';
import { IDataProperty } from 'views/Dashboard/DataSources/dataPropTypes';
import { getPopupContent } from './PopupContent';

export function averageLineChartSeries(
  chartOptions: Options,
  averages: PointOptionsObject[],
  maxValues: PointOptionsObject[],
  minValues: PointOptionsObject[],
  ranges: PointOptionsObject[],
  yValue: IDataProperty<number>,
  warnings?: PointOptionsObject[]
) {
  i18n.loadNamespaces('dashboard');

  chartOptions.series = [
    {
      name: `${i18n.t('dashboard:line_chart.average')} ${yValue.name}`,
      data: averages,
      type: 'line',
      zIndex: 1,
      color: 'rgb(120, 120, 120)',
      tooltip: {
        headerFormat: '',
        pointFormatter() {
          return getPopupContent({
            type: 'header',
            title: this.series.name,
            description: format(new Date(this.x), 'eee dd MMM HH:mm', {
              locale: window.__DATEFNS_LOCALE__,
            }),
            yValue: this.y,
            unit: yValue.unit,
            decimals: yValue.decimals,
            isParentClassRequired: true,
          });
        },
      },
    },

    {
      name: 'Range',
      type: 'arearange',
      data: ranges,
      lineWidth: 1,
      linkedTo: ':previous',
      fillColor: 'rgba(237, 237, 237,0.6)',
      color: 'rgb(209, 209, 209)', // border
      borderWidth: 1,
      zIndex: 0,
      tooltip: {
        valueDecimals: yValue.decimals,
        pointFormatter() {
          return ''; // hiding range points in shared tooltip
        },
      },
      states: {
        hover: {
          enabled: false,
        },
        inactive: {
          opacity: 0.6,
        },
      },
      marker: {
        enabled: false,
      },
    },
  ];

  let i = 0;
  while (i < 2) {
    chartOptions.series.push({
      name: i === 0 ? 'Min ' : 'Max',
      type: 'spline',
      data: i === 0 ? minValues : maxValues,
      tooltip: {
        pointFormatter() {
          return getPopupContent({
            type: 'detail',
            title: this.series.name,
            description: this.name,
            yValue: this.y,
            unit: yValue.unit,
            decimals: yValue.decimals,
            isParentClassRequired: true,
          });
        },
      },
      showInLegend: false,
      lineWidth: 0,
      states: {
        hover: {
          enabled: false,
        },
      },
    });

    i++;
  }

  if (!isEmpty(warnings)) {
    chartOptions.series.push({
      name: 'Warnings',
      type: 'scatter',
      data: warnings,
      showInLegend: true,
      turboThreshold: 0, // At least for now we need to disable turbo mode since data points need to include asset names
      dataLabels: {
        enabled: true,
        formatter() {
          return `${formatNumber(this.y, { decimals: yValue.decimals })} ${
            yValue.unit
          } `;
        },
      },
      color: 'rgba(190, 0, 64, 0.7)',
      marker: {
        symbol: 'circle',
        states: {
          hover: {
            fillColor: '#BE0040',
            lineWidth: 0,
          },
          inactive: {
            opacity: 1,
          },
        },
      },
      cluster: {
        enabled: true,
        minimumClusterSize: 4,
        allowOverlap: false,
        drillToCluster: true,
        layoutAlgorithm: {
          type: 'grid',
          gridSize: 100,
          distance: 20,
        },
      },
      zIndex: 2,
      tooltip: {
        headerFormat: ``,
        pointFormatter() {
          return (
            getPopupContent({
              type: 'header',
              title: this.series.name,
              description: format(new Date(this.x), 'eee dd MMM HH:mm'),
              yValue: this.y,
              unit: yValue.unit,
              decimals: yValue.decimals,
              isParentClassRequired: true,
            }) +
            getPopupContent({
              type: 'detail',
              title: i18n.t('dashboard:line_chart.asset_name'),
              yValue: this.name,
              description: '',
              isParentClassRequired: true,
            })
          );
        },
      },
    });
  }
}
