import { Box, Grid, Typography } from '@mui/material';
import { IntIntelligenceEventTableDto } from 'generated';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { RowInfo } from 'react-table';
import { useStores } from 'store';
import { dataProp } from 'views/Dashboard/DataSources';
import { IDataProperty } from 'views/Dashboard/DataSources/dataPropTypes';
import {
  ITelemetry,
  servicePropToDataProp,
} from 'views/Dashboard/DataSources/ServiceData/servicePropsToDataProps';

interface IProps {
  rowInfo: RowInfo;
}

const IntelligenceEventsDetails: React.FC<IProps> = ({ rowInfo }) => {
  const { dashboardStore, languageCulture } = useStores();
  const intEventRow: IntIntelligenceEventTableDto = rowInfo.original;
  const intEventServiceDataPoints = intEventRow.triggeredServiceDataPoint;

  const service = dashboardStore.services?.find(
    s => s.serviceId === intEventRow.triggerServiceId
  );

  useEffect(() => {
    intEventRow.triggerServiceId &&
      dashboardStore.getService(intEventRow.triggerServiceId);
  }, [dashboardStore, intEventRow.triggerServiceId]);

  const findServiceProperties = service?.properties.filter(
    sp =>
      intEventServiceDataPoints &&
      Object.keys(intEventServiceDataPoints).includes(
        sp.storageColumnName.toLowerCase()
      )
  );

  const dataProps: IDataProperty<ITelemetry>[] =
    findServiceProperties?.map(sp => {
      return servicePropToDataProp({
        sp,
        languageCulture,
        category: 'IntelligenceEventsDataProp',
      });
    }) || [];

  if (dataProps.length === 0) {
    return null;
  }

  return (
    <Box className="rt-tr-details">
      <Box py={3}>
        {dataProps?.map(dp => (
          <Grid container key={dp.id} display="flex" mb={2} marginLeft={10}>
            <Grid item sm={2}>
              <Typography variant="body2">{dp.name}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">
                {dataProp.get(dp, intEventServiceDataPoints)}
                <span className="text-unit">{dp.unit}</span>
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Box>
    </Box>
  );
};

export default observer(IntelligenceEventsDetails);
