import { TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useDashboardContext } from '../contexts/dashboardContext';
import { IControlProps } from './controlProps';

interface IProps {
  placeholder?: string;
}

function TextControl<TObj>(props: IControlProps<TObj, string> & IProps) {
  const { name, object, label, placeholder, autoFocus } = props;

  const { updateSetting } = useDashboardContext();

  if (!object) {
    return null;
  }

  const value = object[name] || '';

  return (
    <TextField
      type="text"
      variant="outlined"
      fullWidth
      label={label || ''}
      value={value}
      placeholder={placeholder}
      autoFocus={autoFocus}
      onChange={e => updateSetting(object, name, e.target.value)}
      inputProps={{
        'data-testid': `${name}-input`,
      }}
    />
  );
}

export default observer(TextControl);
