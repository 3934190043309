import { Box, Tooltip } from '@mui/material';
import classNames from 'classnames';
import AtlasIcon from 'components/AtlasIcon';
import React from 'react';
import { AtlasIconType } from 'shared/types/atlas-icon';
import { formatNumber } from 'utils';
import { ICustomPolicyCellProps } from './CustomCellProps';
import { useStyles } from './CustomPolicyCell.styles';

export const CustomPolicyCell: React.FC<ICustomPolicyCellProps> = ({
  text,
  index,
  rule,
  dataProp,
  value,
}) => {
  const classes = useStyles();

  const defaultIcon = (
    color: string | null | undefined
  ): AtlasIconType | null => {
    if (color === 'green') {
      return 'StatusGood';
    } else if (color === 'yellow') {
      return 'StatusModerate';
    } else if (color === 'red') {
      return 'StatusBad';
    }
    return null;
  };

  const iconType = (rule?.icon as AtlasIconType) || defaultIcon(rule?.color);
  const iconColor = rule?.color || '#707070';
  const desc = rule?.description;

  const mainContents = (
    <>
      {iconType && (
        <Box
          marginY={rule?.label ? '-5px' : '0'}
          className={classNames(classes.icon, {
            [classes.green]: iconColor === 'green',
            [classes.yellow]: iconColor === 'yellow',
            [classes.red]: iconColor === 'red',
          })}
        >
          <AtlasIcon type={iconType} size="14px" />
        </Box>
      )}
      {<Box ml={iconType ? 2 : undefined}>{rule ? rule.label : '-'}</Box>}
    </>
  );

  const textContent = (
    <Box
      className="custom-cell custom-policy-cell"
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
    >
      {mainContents}
    </Box>
  );

  const popupText = () => {
    if (typeof value !== 'number') {
      return desc;
    }
    if (dataProp?.unit) {
      return `${desc} (${formatNumber(value, { decimals: dataProp.decimals || 0 })}${
        dataProp.unit
      })`;
    } else {
      return `${desc} (${formatNumber(value, {
        decimals: dataProp?.decimals || 0,
      })})`;
    }
  };
  const popupInfo = value ? popupText() : undefined;

  if (popupInfo && typeof index === 'number') {
    return (
      <Tooltip arrow title={popupInfo} placement="bottom">
        {textContent}
      </Tooltip>
    );
  }

  return textContent;
};
