import { useEffect, useMemo, useState } from 'react';
import { useStores } from 'store/RootStoreContext';
import { Features, FeatureTogglesPascal } from './Feature';

/**
 * Custom hook that returns one or many feature flags by key.
 * @returns A boolean for each specified feature
 * @example const { CoreFeature1, CoreFeature2 } = useFeatureFlags();
 */
const useFeatureFlags = (): FeatureTogglesPascal => {
  const {
    featureStore: { featureList },
  } = useStores();

  return featureList;
};

/**
 * Custom hook that returns the value of the specified feature
 * @param flagKey The key of the feature to get the value for
 * @returns A boolean for the specified feature.
 * @example const enabled = useFeatureFlag('core.feature.1);
 */
const useFeatureFlag = (flagKey: Features): boolean => {
  const {
    featureStore: { featureMap },
  } = useStores();

  const [feature, setFeature] = useState(false);

  const feat = useMemo(
    () => featureMap[flagKey] ?? false,
    [featureMap, flagKey]
  );

  useEffect(() => {
    setFeature(feat);
  }, [feat]);

  return feature;
};

export { useFeatureFlags, useFeatureFlag };
