import { Box, Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Control } from '.';
import { IPlotBand } from '../ComponentTypes/LineChartComponent/lineChartConfig';
import { plotBandColors } from '../ComponentTypes/LineChartComponent/plotBandColors';
import { IControlProps } from './controlProps';

function PlotBandsControl<TObj>(props: IControlProps<TObj, IPlotBand[]>) {
  const { name, object } = props;
  const { t } = useTranslation('dashboard');

  const value: IPlotBand[] = (object[name] as any) || [];

  // Currently the value is a more or less static 3-item array, hard-coded in default settings.
  // Built as an array with support for arbitrary numbers of plot bands in mind.

  if (value.length === 0) {
    return null;
  }

  return (
    <>
      {value.map((plotBand, i) => (
        <Grid key={i} container spacing={4}>
          <Grid item sm={2}>
            <Box
              style={{
                backgroundColor:
                  plotBandColors[plotBand.color] || plotBand.color,
              }}
              height="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="2px"
            >
              {t(`color.${plotBand.color}`)}
            </Box>
          </Grid>
          <Grid item sm={5}>
            <Control.Number name="from" object={plotBand} label={t('from')} />
          </Grid>
          <Grid item sm={5}>
            <Control.Number name="to" object={plotBand} label={t('to')} />
          </Grid>
        </Grid>
      ))}
    </>
  );
}

export default observer(PlotBandsControl);
