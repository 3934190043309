import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => {
  const { navMenu, topMenu } = theme.settings.components;

  const topMenuHeight = topMenu.height;
  const width =
    navMenu.width + navMenu.closerWidth || 240 + navMenu.closerWidth;

  const transition = theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: 80,
  });

  return createStyles({
    drawer: {
      flexShrink: 0,
      whiteSpace: 'nowrap',
      color: '#c9c9c9',
      transition,
      width: navMenu.collapsedWidth + navMenu.closerWidth,
      flexDirection: 'row',
    },
    paper: {
      top: topMenuHeight,
      backgroundColor: 'transparent',
      boxShadow: navMenu.boxShadow,
      border: 'none',
      justifyContent: 'space-between',
      height: `calc(100% - ${topMenuHeight}px)`,
      overflow: 'hidden',
      width: navMenu.collapsedWidth + navMenu.closerWidth,
      transition,
      flexDirection: 'row',
    },
    menuButton: {
      marginRight: 36,
    },
    drawerOpen: {
      width,
      transition,
    },
    contentBox: {
      overflowX: 'hidden',
      overflowY: 'auto',
    },
  });
});
