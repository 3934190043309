import { snackbar } from 'components/Snackbar';
import i18n from 'i18n';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { automationApi } from 'services/automation.service';
import { Dashboard } from 'store/domains/dashboard';
import { StoreBase } from 'store/storeBase';
import { parseDate } from 'utils';
import { IDataPropCategory, IDataProperty } from '../../dataPropTypes';
import {
  DataPropsGetter,
  IDataSourceResponse,
  IDataSourceStore,
} from '../../dataSourceTypes';
import { getDataProp } from '../../getDataProp';
import { IAutomationActivityDataSourceSettings } from './automationRuleActivityConfig';

type AutomationRuleActivity = {
  id: string;
  displayName: string | null;
  activeSince: Date | null;
  lastExecuted: Date | null;
  lastTriggered: Date | null;
};
const category: IDataPropCategory = {
  displayName: i18n.t('dashboard:data.properties'),
};

export class AutomationRuleActivityDataSource
  extends StoreBase
  implements IDataSourceStore<IAutomationActivityDataSourceSettings>
{
  @observable.ref settings: IAutomationActivityDataSourceSettings;
  isInitialized = true;
  @observable lastReceivedData: Date | undefined;
  dashboard: Dashboard;
  customerId = this.rootStore.authStore.user?.customerId ?? '';
  constructor(
    dashboard: Dashboard,
    settings: IAutomationActivityDataSourceSettings
  ) {
    super(dashboard.rootStore);
    makeObservable(this);

    this.dashboard = dashboard;
    this.settings = settings;
  }
  dataProperties: IDataProperty<AutomationRuleActivity>[] = [
    {
      id: 'name',
      type: 'string',
      category,
      _get: row => row.displayName,
      name: i18n.t('automation:label.table.name'),
      isDefaultDisplayName: true,
      isUniqueId: true,
      link: row => `/management/automations/${row.id}`,
    },

    {
      id: 'activeSince',
      type: 'dateTime',
      category,
      _get: row => (row.activeSince ? parseDate(row.activeSince) : null),
      name: 'Active Since',
    },

    {
      id: 'lastExecuted',
      type: 'dateTime',
      category,
      _get: row => (row.lastExecuted ? parseDate(row.lastExecuted) : null),
      name: 'Last Executed',
    },
    {
      id: 'lastTriggered',
      type: 'dateTime',
      category,
      _get: row => (row.lastTriggered ? parseDate(row.lastTriggered) : null),
      name: 'Last Triggered',
      width: 90,
    },
  ];

  groupProperties = [];

  getDataProps<T>(propGetter: DataPropsGetter<T>): T {
    return propGetter(prop => getDataProp(this, prop));
  }

  async getData(): Promise<IDataSourceResponse> {
    const resp = await this.httpGet(automationApi.getAll(this.customerId), {
      filters: '',
      orderBy: 'lastTriggered',
      orderDesc: true,
      search: '',
      page: 0,
      pageSize: 10,
    });

    if (resp.status === 204) {
      return {
        type: 'noContent',
      };
    } else if (resp.status !== 200) {
      const errorMessage =
        resp.statusText ??
        resp.exceptionMessage ??
        'Error loading Automation rule data';
      snackbar(errorMessage, { variant: 'error' });

      return {
        type: 'error',
        message: errorMessage,
      };
    }

    const parsedData: AutomationRuleActivity[] = [];
    if (resp.data && Array.isArray(resp.data)) {
      for (const {
        id,
        displayName,
        lastExecuted,
        lastTriggered,
        activeSince,
      } of resp.data) {
        if (lastTriggered) {
          parsedData.push({
            id,
            displayName,
            activeSince,
            lastExecuted,
            lastTriggered,
          });
        }
      }
    } else {
      console.error('Invalid response data', resp.data);
    }

    runInAction(() => {
      this.lastReceivedData = new Date();
    });

    return {
      type: 'success',
      data: {
        type: 'list',
        items: parsedData,
        total: parsedData.length || 0,
      },
    };
  }
  async getRows() {
    const res = await this.getData();
    if (res.type === 'success') {
      if (res.data.type === 'object') {
        return res.data.item;
      }
    }
  }
  @computed get depString(): string {
    const { manualRefreshTrigger } = this.dashboard.rootStore.dashboardStore;
    const depObject = {
      manualRefreshTrigger,
    };
    return JSON.stringify(depObject);
  }
}
