import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    accordion: {
      backgroundColor: '#f4f4f9',
      boxShadow: 'none',
    },
    accordionSummary: {
      padding: theme.spacing(0, 4),
    },
    accordionDetails: {
      padding: theme.spacing(1, 4, 4),
    },
  })
);
