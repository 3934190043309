import { IntSearchDto } from 'generated';
import { ReactElement } from 'react';
import {
  Column,
  ComponentPropsGetterR,
  Filter,
  RowInfo,
  SortingRule,
} from 'react-table';
import {
  IResponse,
  PostTableEndpoint,
  TableEndpoint,
} from 'shared/interfaces/api';
import { AtlasIconType } from 'shared/types/atlas-icon';
import * as column from './Columns/Columns';
import EditColumnsModal from './EditColumnsModal/EditColumnsModal';
import FormTable from './FormTable/FormTable';
import { useTableColumnSettings, useTableSelect } from './hooks';
import ResourceTable from './ResourceTable/ResourceTable';
import SimpleTable from './SimpleTable/SimpleTable';
import Table from './Table';
import TableLoadingOverlay from './TableLoadingOverlay/TableLoadingOverlay';

export type TableData<TRow> = {
  rows: TRow[];
  total: number;
};

export type TableDataAPIFn2<T> = (
  params: IntSearchDto
) => IResponse<TableData<T>>;

export interface IResourceTableProps<TRequestBody, TRow> {
  tableId: string;
  tableTitle?: string;
  disableSearch?: boolean;
  columns: Column<TRow>[];
  apiEndpoint: TableEndpoint<TRow> | PostTableEndpoint<TRequestBody, TRow>;
  endpointType?: 'get' | 'post';
  subComponent?: React.ComponentType<TableSubComponentProps<TRow>>;
  toolbar?: React.ReactNode;
  selectedRowsCount?: number;
  selectState?: TableSelectState<TRow>;
  searchPlaceholder?: string;
  height: number | string;
  disableHeightCalculation?: boolean;
  defaultSortCol?: string;
  defaultSortDesc?: boolean;
  onDataChanged?: (tableData: TableData<TRow>) => void;
  mapRows?: (rows: TRow[]) => TRow[];
  topMoreButtonItems?: TableTopMoreMenuItem[];
  topAction?: TableTopAction;
  getPostBody?: (searchDto: IntSearchDto) => TRequestBody;
  customError?: ReactElement;
  searchColumns?: (keyof TRow)[];
  getTrProps?: ComponentPropsGetterR;
  getTdProps?: ComponentPropsGetterR;
  hideFilterButton?: boolean;
}

export interface TableQueryParams {
  search?: string;
  page: number;
  pageSize: number;
  filters?: Filter[];
  sortRules: SortingRule[];
  searchColumns?: string;
}

export interface TableSubComponentProps<T> extends RowInfo {
  original: T;
}

export interface TableSelectState<T> {
  selectRow: (row: T, selected: boolean) => void;
  isRowSelected: (row: T) => boolean;
  toggleAll: (rows: T[], selected: boolean) => void;
  isAllSelected: (rows: T[]) => boolean;
  isRowDisabled?: (row: T) => boolean;
  selectedRowsCount?: number;
}

export interface TableTopMoreMenuItem {
  text: string;
  onClick: () => void;
  icon?: AtlasIconType;
  disabled?: boolean;
  dataCy?: string;
}

export interface TableTopAction {
  icon: AtlasIconType;
  items: TableTopActionItem[];
}

export interface TableTopActionItem {
  text: string;
  hoverTagText?: string;
  onClick: () => void;
  icon?: AtlasIconType;
  disabled?: boolean;
}

//interface to use if any implementation is using the proxy route. Needed to enable global search that was handled in Messify before.
export interface SearchWithColumnsDto extends IntSearchDto {
  searchColumns: string | null;
}

const tableParamsToSearchDto = (
  params: TableQueryParams
): SearchWithColumnsDto => ({
  filters: JSON.stringify(params.filters) || null,
  orderBy: params.sortRules[0]?.id || null,
  orderDesc: params.sortRules[0]?.desc || false,
  search: params.search || null,
  page: params.page,
  pageSize: params.pageSize,
  searchColumns: params.searchColumns || null,
});

export {
  ResourceTable,
  useTableColumnSettings,
  useTableSelect,
  FormTable,
  column,
  SimpleTable,
  TableLoadingOverlay,
  EditColumnsModal,
  Table,
  tableParamsToSearchDto,
};
