import {
  Box,
  InputAdornment,
  Popover,
  TextField as MUITextField,
  Typography,
} from '@mui/material';
import { useField } from 'formik';
import React, { useRef, useState } from 'react';
import { ColorChangeHandler, SketchPicker } from 'react-color';

export type InputType = 'text' | 'email' | 'phone' | 'number';

interface IProps {
  name: string;
  label: string;
  type?: InputType;
  placeholder?: string;
  className?: string;
  rowsMax?: string | number;
  required?: boolean;
  autoFocus?: boolean;
  maxLength?: number;
  disabled?: boolean;
  description?: string;
}

const ColorPicker: React.FC<IProps> = ({
  name,
  label,
  placeholder,
  type,
  rowsMax,
  required,
  autoFocus,
  maxLength,
  disabled = false,
  className,
  description,
}) => {
  const [field, meta, helpers] = useField<string>(name);
  const elRef = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleChange: ColorChangeHandler = color => {
    helpers.setValue(color.hex);
  };

  return (
    <>
      <MUITextField
        id={name}
        type={type}
        label={label}
        className={className}
        fullWidth
        autoFocus={autoFocus}
        placeholder={placeholder}
        error={meta.touched && !!meta.error}
        helperText={meta.touched && meta.error}
        maxRows={rowsMax}
        required={required}
        disabled={disabled}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Box
                width={18}
                height={18}
                position="relative"
                top={8}
                bgcolor={field.value || 'transparent'}
              />
            </InputAdornment>
          ),
        }}
        inputProps={{
          maxLength,
          'data-cy': name,
        }}
        variant="outlined"
        {...field}
        onFocus={() => setIsOpen(true)}
        ref={elRef}
      />
      {description && (
        <Typography variant="body2" color="textSecondary" gutterBottom>
          {description}
        </Typography>
      )}
      <Popover
        open={!!isOpen}
        anchorEl={elRef.current}
        onClose={() => setIsOpen(false)}
        disableRestoreFocus // This makes it impossible to use the actual TextField, but the picker has its own - improve if someone complains
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <SketchPicker
          color={field.value || undefined}
          onChange={handleChange}
          disableAlpha
          presetColors={[]}
        />
      </Popover>
    </>
  );
};

export default ColorPicker;
