import { Box, IconButton } from '@mui/material';
import AtlasIcon from 'components/AtlasIcon';
import { Column } from 'react-table';
import { AtlasIconType } from 'shared/types/atlas-icon';

export function iconButton<T, TValue>(options: {
  id: string;
  icon: AtlasIconType;
  accessor: (row: T) => TValue;
  onClick: (value: TValue) => void;
  align?: 'left' | 'right';
}): Column<T> {
  const { id, accessor, icon, onClick, align = 'left' } = options;
  const floatDirection = align === 'left' ? 'left' : 'right';
  return {
    id,
    accessor,
    Cell: ({ value }) => (
      <Box style={{ float: floatDirection }}>
        <IconButton onClick={() => onClick(value as TValue)} size="small">
          <AtlasIcon type={icon} size={16} />
        </IconButton>
      </Box>
    ),
    resizable: false,
    sortable: false,
  };
}
