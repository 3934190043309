import { alpha, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';

export interface IListBoxProps extends React.HTMLAttributes<HTMLElement> {
  id: string;
  hasMore: boolean;
  dataLength: number;
  fetchMore: () => void;
}

const useStyles = makeStyles(theme =>
  createStyles({
    infiniteScroll: {
      wordBreak: 'break-word',
      '& > .MuiAutocomplete-option[role="option"][aria-selected="false"]:hover':
        {
          background: alpha(theme.palette.common.black, 0.04),
        },
    },
  })
);

const ScrollPaginationListBox = React.forwardRef<HTMLDivElement, IListBoxProps>(
  (props, ref) => {
    const {
      dataLength,
      hasMore,
      fetchMore,
      id,
      children,
      // remove role since that currently makes autocomplete to scroll to top https://github.com/mui/material-ui/issues/30249
      role,
      ...rest
    } = props;
    const { t } = useTranslation();
    const classes = useStyles();
    return (
      <div {...rest} ref={ref} id={id}>
        <InfiniteScroll
          dataLength={dataLength}
          next={fetchMore}
          hasMore={hasMore}
          loader={
            <Typography color="textSecondary" align="center">
              {t('loading')}...
            </Typography>
          }
          scrollableTarget={id}
          className={classes.infiniteScroll}
        >
          {children}
        </InfiniteScroll>
      </div>
    );
  }
);

export default ScrollPaginationListBox;
