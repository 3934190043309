import { aiTrackEvent } from 'appInsights';
import { snackbar } from 'components/Snackbar';
import {
  IntConfigurationDto,
  IntCreateAccessEndpointRequestDto,
  IntCreateConfigurationRequestDto,
  IntCreateExtensionRequestDto,
  IntCreateWorkloadRequestDto,
  IntCronJobStatusDto,
  IntDeploymentStatusDto,
  IntExtensionDto,
  IntJobStatusDto,
  IntReplaceConfigurationRequestDto,
  IntUpdateWorkloadRequestDto,
  WorkloadType,
} from 'generated';
import i18n from 'i18n';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { accessEndpointAPI } from 'services/accessEndpoint.service';
import { extensionAPI } from 'services/extension.service';
import { workloadAPI } from 'services/workload.service';
import { IResponse } from 'shared/interfaces/api';
import { RootStore } from 'store/rootStore';
import { StoreBase } from 'store/storeBase';

const extensionConfiguration = 'Extension Configuration';

type State = {
  details: boolean;
  create: boolean;
  delete: boolean;
  createConfig: boolean;
  updateConfig: boolean;
  createWorkload: boolean;
  updateWorkload: boolean;
  createSecret: boolean;
  updateSecret: boolean;
  configs: boolean;
  validateImageName: boolean;
  latestJobs: boolean;
  createAccessEndpoint: boolean;
};

type LoadingState = State & {
  configs: boolean;
  secrets: boolean;
};

type ModalType = {
  createworkload: boolean;
  updateworkload: boolean;
  deleteWorkload: boolean;
  createExtension: boolean;
  deleteExtensions: boolean;
  createAccessEndpoint: boolean;
  deleteAccessEndpoint: boolean;
};

export type DetectedEventType = {
  extension: boolean;
  workload: boolean;
  config: boolean;
  accessEndpoint: boolean;
};

type latestJobTypes =
  | IntJobStatusDto
  | IntCronJobStatusDto
  | IntDeploymentStatusDto;

export class ExtensionStore extends StoreBase {
  @observable imageNameErrorMessage: string | undefined;
  @observable extensionEventsDetected: DetectedEventType = {
    extension: false,
    workload: false,
    config: false,
    accessEndpoint: false,
  };

  @observable error: State = {
    details: false,
    create: false,
    delete: false,
    createConfig: false,
    updateConfig: false,
    createWorkload: false,
    updateWorkload: false,
    createSecret: false,
    updateSecret: false,
    configs: false,
    validateImageName: false,
    latestJobs: false,
    createAccessEndpoint: false,
  };

  @observable loading: LoadingState = {
    details: false,
    create: false,
    delete: false,
    createConfig: false,
    updateConfig: false,
    createWorkload: false,
    updateWorkload: false,
    createSecret: false,
    updateSecret: false,
    validateImageName: false,
    secrets: false,
    latestJobs: false,
    configs: false,
    createAccessEndpoint: false,
  };

  @observable openModal: ModalType = {
    createworkload: false,
    updateworkload: false,
    deleteWorkload: false,
    createExtension: false,
    deleteExtensions: false,
    createAccessEndpoint: false,
    deleteAccessEndpoint: false,
  };

  @observable extensionDetails: IntExtensionDto | undefined;
  @observable secrets: IntConfigurationDto[] | undefined;
  @observable latestJobs: latestJobTypes | undefined;
  @observable configs: IntConfigurationDto[] | undefined;

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }

  @action.bound createExtension = async (
    data: IntCreateExtensionRequestDto,
    errorMsg: JSX.Element,
    successCallbackFn: () => void
  ) => {
    const resp = await this.httpPost(
      extensionAPI.create,
      {
        params: undefined,
        data,
      },
      loading => this.setLoading('create', loading)
    );

    if (resp.status === 201) {
      snackbar(i18n.t('extension:success.create'), {
        variant: 'success',
      });

      aiTrackEvent('Create', { title: 'Extension' });

      this.indicateExtensionEventDetected('extension');
      successCallbackFn();
    } else if (resp.status === 409) {
      snackbar(errorMsg, { variant: 'error' });
    } else {
      snackbar(i18n.t('extension:error.create'), {
        variant: 'error',
      });
    }
  };

  @action.bound createAccessEndpoint = async (
    data: IntCreateAccessEndpointRequestDto,
    successCallbackFn: () => void
  ) => {
    const resp = await this.httpPost(
      accessEndpointAPI.create,
      {
        params: undefined,
        data,
      },
      loading => this.setLoading('createAccessEndpoint', loading)
    );

    if (resp.status === 201) {
      snackbar(i18n.t('accessendpoint:success.create'), {
        variant: 'success',
      });

      aiTrackEvent('Create', { title: 'Access Endpoint' });

      this.indicateExtensionEventDetected('accessEndpoint');
      successCallbackFn();
    } else {
      snackbar(i18n.t('accessendpoint:error.create'), {
        variant: 'error',
      });
    }
  };

  @action.bound createConfig = async (
    data: IntCreateConfigurationRequestDto,
    successCallbackFn: () => void
  ) => {
    if (this.extensionDetails) {
      const resp = await this.httpPost(
        extensionAPI.createConfig(this.extensionDetails.id),
        {
          params: undefined,
          data,
        },
        loading => this.setLoading('createConfig', loading)
      );

      if (resp.status === 200) {
        snackbar(i18n.t('configuration:success.create'), {
          variant: 'success',
        });

        aiTrackEvent('Create', { title: extensionConfiguration });

        this.indicateExtensionEventDetected('config');
        successCallbackFn();
      } else {
        snackbar(i18n.t('configuration:error.create'), {
          variant: 'error',
        });
      }
    }
  };

  @action.bound updateConfig = async (
    data: IntReplaceConfigurationRequestDto,
    configId: string,
    successCallbackFn: () => void
  ) => {
    if (this.extensionDetails) {
      const resp = await this.httpPut(
        extensionAPI.updateConfig(this.extensionDetails.id, configId),
        {
          params: undefined,
          data,
        },
        loading => this.setLoading('updateConfig', loading)
      );

      if (resp.status === 200) {
        snackbar(i18n.t('configuration:success.update'), {
          variant: 'success',
        });

        aiTrackEvent('Update', { title: extensionConfiguration });

        this.indicateExtensionEventDetected('config');
        successCallbackFn();
      } else {
        snackbar(i18n.t('configuration:error.update'), {
          variant: 'error',
        });
      }
    }
  };

  @action.bound async deleteConfig(
    configId: string,
    onDeleteComplete: () => void
  ) {
    if (this.extensionDetails) {
      const resp = await this.httpDelete(
        extensionAPI.deleteConfig(this.extensionDetails.id, configId),
        {
          params: configId,
        }
      );

      if (resp.status === 200) {
        snackbar(i18n.t('configuration:success.delete'), {
          variant: 'success',
        });

        aiTrackEvent('Delete', { title: extensionConfiguration });

        onDeleteComplete();
        this.indicateExtensionEventDetected('config');
      } else {
        snackbar(i18n.t('configuration:error.delete'), {
          variant: 'error',
        });
      }
    }
  }

  @action.bound createWorkload = async (
    data: IntCreateWorkloadRequestDto,
    successCallbackFn: () => void
  ) => {
    const resp = await this.httpPost(
      workloadAPI.create,
      {
        params: undefined,
        data,
      },
      loading => this.setLoading('createWorkload', loading)
    );

    if (resp.status === 201) {
      snackbar(i18n.t('workload:success.create'), {
        variant: 'success',
      });

      aiTrackEvent('Create', { title: 'Extension Workload' });
      this.indicateExtensionEventDetected('workload');
      successCallbackFn();
    } else {
      snackbar(i18n.t('workload:error.create'), {
        variant: 'error',
      });
    }
  };

  @action.bound updateWorkload = async (
    id: string,
    data: IntUpdateWorkloadRequestDto,
    successCallbackFn: () => void
  ) => {
    const resp = await this.httpPut(
      workloadAPI.update,
      {
        params: id,
        data,
      },
      loading => this.setLoading('updateWorkload', loading)
    );

    if (resp.status === 200) {
      snackbar(i18n.t('workload:success.update'), {
        variant: 'success',
      });
      this.indicateExtensionEventDetected('workload');
      successCallbackFn();
    } else {
      snackbar(i18n.t('workload:error.update'), {
        variant: 'error',
      });
    }
  };

  @action.bound setError = (type: keyof State, error: boolean) => {
    this.error[type] = error;
  };

  @action.bound setLoading = (type: keyof LoadingState, loading: boolean) => {
    this.loading[type] = loading;
  };

  @action.bound setOpenModal(type: keyof ModalType, open: boolean) {
    this.openModal[type] = open;
  }

  @action.bound indicateExtensionEventDetected(
    eventType: keyof DetectedEventType
  ) {
    this.extensionEventsDetected[eventType] =
      !this.extensionEventsDetected[eventType];
  }

  @action.bound async getExtensionDetails(extensionId: string) {
    const response = await this.httpGet(
      extensionAPI.getDetails,
      extensionId,
      loading => {
        this.setLoading('details', loading);
      }
    );

    if (response.status === 200 && response.data) {
      this.setExtensionDetails(response.data);
    } else {
      snackbar(i18n.t('extension:error.details'), { variant: 'error' });
    }
  }

  @action.bound setExtensionDetails(
    extensionDetails: IntExtensionDto | undefined
  ) {
    this.extensionDetails = extensionDetails;
  }

  @action.bound setConfigs(configs: IntConfigurationDto[] | undefined) {
    this.configs = configs;
  }

  @action.bound async getConfigs(extensionId: string) {
    this.setError('configs', false);

    const response = await this.httpGet(
      extensionAPI.getConfigs(extensionId),
      {
        page: 0,
        pageSize: 9999999,
        orderBy: null,
        orderDesc: null,
        search: null,
        filters: null,
      },
      loading => {
        this.setLoading('configs', loading);
      }
    );

    if (response.status === 200 && response.data?.rows) {
      this.setConfigs(response.data.rows);
    } else {
      this.setError('configs', true);
    }
  }

  @action.bound setImageNameErrorMessage(msg?: string) {
    this.imageNameErrorMessage = msg;
  }

  @action.bound async validateImageName(
    name: string
  ): Promise<{ errorMsg?: string; fullImageName?: string }> {
    this.setImageNameErrorMessage();
    this.setError('validateImageName', false);

    const response = await this.httpGet(
      workloadAPI.validateImageName,
      name,
      loading => this.setLoading('validateImageName', loading)
    );

    if (response.status === 200 && response.data) {
      if (!response.data.valid) {
        const errorMsg = i18n.t('workload:validation.image_name', {
          reason: response.data.errorMessage,
        });

        this.setImageNameErrorMessage(errorMsg);
        this.setError('validateImageName', true);

        return { errorMsg };
      }
    } else {
      const errorMsg = i18n.t('workload:validation.image_name_unknown');

      this.setImageNameErrorMessage(errorMsg);
      this.setError('validateImageName', true);

      return { errorMsg };
    }

    return { fullImageName: response.data.image.fullImageName };
  }

  @action.bound async getLatestJobs(
    workloadId: string,
    workloadType: WorkloadType
  ) {
    this.setError('latestJobs', false);

    let resp: IResponse<
      IntJobStatusDto | IntCronJobStatusDto | IntDeploymentStatusDto
    >;

    switch (workloadType) {
      case WorkloadType.Job:
        resp = await this.httpGet(
          workloadAPI.getJobStatus,
          workloadId,
          loading => {
            this.setLoading('latestJobs', loading);
          }
        );
        break;
      case WorkloadType.CronJob:
        resp = await this.httpGet(
          workloadAPI.getCronJobStatus,
          workloadId,
          loading => {
            this.setLoading('latestJobs', loading);
          }
        );
        break;
      case WorkloadType.Deployment:
        resp = await this.httpGet(
          workloadAPI.getDeploymentStatus,
          workloadId,
          loading => {
            this.setLoading('latestJobs', loading);
          }
        );
        break;
      default:
        this.setError('latestJobs', true);
        return;
    }

    if (resp.status === 200 && resp.data) {
      runInAction(() => (this.latestJobs = resp.data));
    } else {
      this.setError('latestJobs', true);
    }
  }
}
