import { emptyGuid } from 'components/Auth/utils';
import {
  IntCustomerDto,
  IntGroupDto,
  IntSearchDto,
  IntUserCreateInfoDto,
  IntUserTableDto,
  PasswordOption,
} from 'generated';
import { IResponse } from 'shared/interfaces/api';
import { dummyData, paginate, rnd } from 'utils';

class UserDemoData {
  getUserTable = (data?: IntSearchDto) => {
    const randData = rnd.array<IntUserTableDto>(70, _ => ({
      userId: rnd.guid(),
      username: rnd.userName(),
      displayName: rnd.fullName(),
      customerId: rnd.customer().id,
      customerName: rnd.customer().name,
      groups: rnd.array<string>(rnd.int(1, 60), () =>
        rnd.item('0', '1', '2', '3')
      ),
      createdDate: rnd.date.past({ years: rnd.int(1, 4) }),
      lastActive: rnd.date.recent({ days: rnd.int(1, 365) }),
      phoneNumber: rnd.phoneNumber(),
      email: rnd.email,
      emailConfirmed: rnd.bool(),
      isEmailVerificationRequired: rnd.bool(),
      isLockedOut: rnd.bool(),
    }));

    return {
      status: 200,
      data: paginate(randData, data),
    };
  };

  getUserCreateInfo = (
    customerId: string | null
  ): IResponse<IntUserCreateInfoDto> => {
    const templateGroups: IntGroupDto[] = customerId
      ? [
          {
            groupName: 'Demo group from template ',
            groupId: rnd.guid(),
            fromTemplate: true,
            roles: [],
          },
        ]
      : [];

    return {
      data: {
        customers: dummyData<IntCustomerDto>(5, rnd.customer),
        groups: [
          {
            groupId: emptyGuid,
            groupName: 'Static group',
            fromTemplate: false,
            roles: [],
          },
          ...dummyData<IntGroupDto>(5, i => ({
            groupId: rnd.guid(),
            groupName: rnd.resourceGroupName(i),
            fromTemplate: false,
            roles: [],
          })),
          ...templateGroups,
        ],
      },
      status: 200,
    };
  };

  getUserFormInfo = () => {
    return {
      data: {
        userId: rnd.guid(),
        userName: rnd.userName(),
        firstName: rnd.firstName(),
        lastName: rnd.lastName(),
        password: '',
        customerId: rnd.customer().id,
        groups: [],
        lastActive: rnd.date.recent({ days: rnd.int(1, 365) }),
        phoneNumber: rnd.phoneNumber(),
        email: rnd.email(),
        isEmailVerificationRequired: rnd.bool(),
        languageCulture: 'en-US',
        passwordOption: PasswordOption.Manually,
        zipCode: '',
        profilePicturePath: '',
        city: '',
        address: '',
        countryISO3166: rnd.countryCode(),
        countryName: rnd.country(),
        imageData: '',
        imageFileName: '',
        deleteImage: false,
      },
      status: 200,
    };
  };
}

export const userDemoData = new UserDemoData();
