import { Option } from 'components/FormFields';
import { FontFace } from 'csstype';
import pebbleWoff from 'fonts/pebble/Pebble_W_Rg.woff';
import TeliaSansBoldWoff from 'fonts/TeliaSans/TeliaSans-Bold.woff';
import TeliaSansBoldWoff2 from 'fonts/TeliaSans/TeliaSans-Bold.woff2';
import TeliaSansMdWoff from 'fonts/TeliaSans/TeliaSans-Medium.woff';
import TeliaSansMdWoff2 from 'fonts/TeliaSans/TeliaSans-Medium.woff2';
import TeliaSansRegWoff from 'fonts/TeliaSans/TeliaSans-Regular.woff';
import TeliaSansRegWoff2 from 'fonts/TeliaSans/TeliaSans-Regular.woff2';

export type AtlasFontType =
  | 'Pebble'
  | 'HelveticaNeue'
  | 'OpenSans'
  | 'Roboto'
  | 'Montserrat'
  | 'TeliaSansReg'
  | 'TeliaSansBold'
  | 'TeliaSansMedium';

export const fontOptions: Option<AtlasFontType>[] = [
  {
    label: 'Pebble',
    value: 'Pebble',
  },
  {
    label: 'Helvetica Neue',
    value: 'HelveticaNeue',
  },
  {
    label: 'Open Sans',
    value: 'OpenSans',
  },
  {
    label: 'Roboto',
    value: 'Roboto',
  },
  {
    label: 'Montserrat',
    value: 'Montserrat',
  },
  {
    label: 'TeliaSansReg',
    value: 'TeliaSansReg',
  },
  {
    label: 'TeliaSansBold',
    value: 'TeliaSansBold',
  },
  {
    label: 'TeliaSansMedium',
    value: 'TeliaSansMedium',
  },
];

export interface IAtlasFontTypeConfig {
  fontStack: string;
  fontFace?: FontFace;
  styleSheet?: string;
}

export const fontSettings: Record<AtlasFontType, IAtlasFontTypeConfig> = {
  Pebble: {
    fontStack: 'Pebble, sans-serif',
    fontFace: {
      fontFamily: 'Pebble',
      src: `url(${pebbleWoff}) format('woff')`,
      fontWeight: 'normal',
      fontStyle: 'normal',
    },
  },
  HelveticaNeue: {
    fontStack: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  },
  OpenSans: {
    fontStack: '"Open Sans", sans-serif',
    styleSheet:
      'https://fonts.googleapis.com/css2?family=Open+Sans&display=swap)',
  },
  Roboto: {
    fontStack: 'Roboto, sans-serif',
    styleSheet: 'https://fonts.googleapis.com/css2?family=Roboto&display=swap)',
  },
  Montserrat: {
    fontStack: 'Montserrat, sans-serif',
    styleSheet:
      'https://fonts.googleapis.com/css2?family=Montserrat&display=swap)',
  },
  TeliaSansReg: {
    fontStack: 'TeliaSansReg, Arial, sans-serif',
    fontFace: {
      fontFamily: 'TeliaSansReg',
      src:
        `url(${TeliaSansRegWoff2}) format('woff2')` ||
        `url(${TeliaSansRegWoff}) format('woff')`,
      fontWeight: 'normal',
      fontStyle: 'normal',
    },
  },
  TeliaSansBold: {
    fontStack: 'TeliaSansBold, Arial, sans-serif',
    fontFace: {
      fontFamily: 'TeliaSansBold',
      src:
        `url(${TeliaSansBoldWoff2}) format('woff2')` ||
        `url(${TeliaSansBoldWoff}) format('woff')`,
      fontWeight: 'bold',
      fontStyle: 'normal',
    },
  },
  TeliaSansMedium: {
    fontStack: 'TeliaSansMedium, Arial, sans-serif',
    fontFace: {
      fontFamily: 'TeliaSansMedium',
      src:
        `url(${TeliaSansMdWoff2}) format('woff2')` ||
        `url(${TeliaSansMdWoff}) format('woff')`,
      fontWeight: 500,
      fontStyle: 'normal',
    },
  },
};
