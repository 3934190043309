import { format as dateFnsFormatDate } from 'date-fns';
import { isNil } from 'lodash';
import { tryParseDate } from './parsing';

export interface IDateOptions {
  time?: boolean;
  seconds?: boolean;
}

export function getDateFormatString(options?: IDateOptions) {
  const { time, seconds } = options || {};

  /*
  Please check with UX before changing the date format.
  Zeplin sketches differ in how dates are formatted so don't base an update solely on those
  */
  return `yyyy-MM-dd${time || seconds ? ' HH:mm' : ''}${seconds ? ':ss' : ''}`;
}

// If updating this also update the clone in cypress\support\utils.ts
export function formatDate(
  date: string | number | Date,
  options?: IDateOptions
): string {
  const asDate = tryParseDate(date);
  if (isNil(asDate)) {
    return '';
  }
  return dateFnsFormatDate(asDate, getDateFormatString(options), {
    locale: window.__DATEFNS_LOCALE__,
  });
}

export function formatLocalTimezoneToUTCString(date: Date): string {
  return dateFnsFormatDate(date, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
}

export function formatLocalTimezoneToUTC(date: Date): Date {
  return new Date(formatLocalTimezoneToUTCString(date));
}

export function formatNumber(
  value: number | null | undefined,
  options: { decimals?: number; fallback?: string }
): string {
  const { decimals, fallback = '-' } = options;

  if (typeof value !== 'number' || isNaN(value)) {
    return fallback;
  }

  if (decimals === undefined) {
    return value.toString();
  }

  const factor = Math.pow(10, decimals);

  return (Math.round(value * factor) / factor).toFixed(decimals);
}

export const splitOnCapitalLetters = (value: string): string =>
  value.replaceAll(/([a-z])([A-Z])/g, '$1 $2');

export const splitStringintoArray = (
  value: string,
  splitter: RegExp
): string[] => value.split(splitter).filter(v => v !== '');

export const splitIntoTwoDimenArray = (data: string[]) => {
  return data.map((val: string) => val.split(':'));
};
