import ContentCard from 'components/ContentCard';
import LoadingOverlay from 'components/LoadingOverlay';
import React from 'react';
import WidgetContent from './WidgetContent';
import WidgetHeader from './WidgetHeader';

interface IProps {
  isLoading?: boolean;
  backgroundColor?: string;
  children: React.ReactNode;
  widgetType?: string;
}

const WidgetContainer = ({
  isLoading = false,
  backgroundColor = 'white',
  children,
  widgetType,
}: IProps) => (
  <ContentCard
    backgroundColor={backgroundColor}
    className="WidgetContainer"
    dataCy={widgetType ?? ''}
  >
    {children}
    <LoadingOverlay isLoading={isLoading} variant="interactive" />
  </ContentCard>
);

WidgetContainer.Header = WidgetHeader;
WidgetContainer.Content = WidgetContent;

export default WidgetContainer;
