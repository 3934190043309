import { TableData } from 'components/Table';
import { subMinutes, subSeconds } from 'date-fns';
import {
  AvailabilityType,
  ConfigurationType,
  IntConfigurationDto,
  IntConfigurationReferenceDto,
  IntCronJobStatusDto,
  IntDeploymentStatusDto,
  IntExtensionLogEntry,
  IntJobStatusDto,
  IntPodStatusDto,
  IntSearchDto,
  IntValidateImageDto,
  IntWorkloadDto,
  IntWorkloadTableDto,
  PodPhase,
  WorkloadCategory,
  WorkloadType,
} from 'generated';
import { ITempConfiguration } from 'services/workload.service';
import { IResponse } from 'shared/interfaces/api';
import { dummyData, rnd, tableDemoData } from 'utils';
import { configs } from './extensionDemoData';

const generateWorkloadDetails = (): IntWorkloadDto => ({
  id: rnd.guid(),
  name: `workload-${rnd.int(1, 999)}`,
  image: `images/${rnd.int(1, 99)}`,
  extensionId: rnd.guid(),
  type: rnd.enum(WorkloadType),
  persistentStorage: rnd.bool(),
  category: rnd.enum(WorkloadCategory),
  schedule: `* * * * *`,
  configurations: configs
    .slice(0, rnd.int(1, 3))
    .map(cm => mapConfigMapToReferenceDto(cm)),
  createdDate: subMinutes(new Date(), rnd.int(10, 60 * 24 * 100)),
});

const mapConfigMapToReferenceDto = (
  cm: IntConfigurationDto
): IntConfigurationReferenceDto => {
  return {
    configuration: cm,
    availability: AvailabilityType.File,
    type: ConfigurationType.ConfigMap,
    mountPath: `/app/${cm.name}`,
  };
};

const generateConfiguration = (i: number): ITempConfiguration => ({
  name: `Configuration #${i}`,
  value: `Value ${rnd.int(1, 99)}`,
});

const generatePodStatus = (
  prefix: string,
  baseDate: Date
): IntPodStatusDto => ({
  name: `${prefix}-${rnd.int(111111, 999999)}`,
  created: new Date(),
  started: subSeconds(baseDate, rnd.int(45, 180)),
  stopped: baseDate,
  restartCount: rnd.int(0, 2),
  message: '',
  phase: rnd.enum(PodPhase),
});

class WorkloadDemoData {
  getWorkloads = tableDemoData<IntWorkloadTableDto>(i => ({
    id: rnd.guid(),
    extensionId: rnd.guid(),
    extensionName: `Extension ${rnd.int(1, 99)}`,
    namespaceId: rnd.guid(),
    namespaceName: `Namespace ${rnd.int(1, 99)}`,
    name: `workload-${rnd.int(1, 999)}`,
    type: rnd.int(1, 3),
    createdDate: subMinutes(new Date(), rnd.int(1, 999999)),
  }));

  getGeneralProperties() {
    return {
      status: 200,
      data: generateWorkloadDetails(),
    };
  }

  getConfigurations = (
    params: IntSearchDto
  ): IResponse<TableData<ITempConfiguration>> => {
    const configurations = rnd.array<ITempConfiguration>(
      rnd.int(1, 6),
      generateConfiguration
    );

    return {
      status: 200,
      data: {
        rows: configurations,
        total: configurations.length,
      },
    };
  };

  getLogs() {
    const startDate = new Date();
    let seconds = 0;

    return tableDemoData<IntExtensionLogEntry>(i => {
      seconds += rnd.int(60, 60 * 60 * 24);

      return {
        logEntry: `Eventtype ${rnd.int(0, 100)}`,
        logEntrySource: `Source ${rnd.int(0, 100)}`,
        timeGenerated: subSeconds(startDate, seconds),
      };
    });
  }

  create = (): IResponse => ({ status: 201 });

  update = (): IResponse => ({ status: 200 });

  validateImageName = (
    userEnteredImageName: string
  ): IResponse<IntValidateImageDto> => {
    return {
      status: 200,
      data: {
        valid: true,
        errorMessage: '',
        image: {
          registryPath: '',
          repositoryName: '',
          fullImageName: userEnteredImageName, // No way that I can see to reliably check if there is a path included so simply return the same value
          tags: [],
          lastUpdatedOn: new Date(),
        },
      },
    };
  };

  delete = (): IResponse => ({ status: 200 });

  getJobStatus = (): IResponse<IntJobStatusDto> => {
    let baseDate = new Date();

    return {
      status: 200,
      data: {
        name: `Job-${rnd.int(1, 99)}`,
        startTime: new Date(),
        stopTime: new Date(),
        succeeded: true,
        podStatuses: dummyData<IntPodStatusDto>(3, i => {
          const job = generatePodStatus('Job', baseDate);

          baseDate = job.started;

          return job;
        }),
      },
    };
  };

  getCronJobStatus = (): IResponse<IntCronJobStatusDto> => {
    let baseDate = new Date();

    return {
      status: 200,
      data: {
        name: `CronJob-${rnd.int(1, 99)}`,
        latestRun: new Date(),
        jobStatuses: dummyData<IntJobStatusDto>(3, i => {
          const job: IntJobStatusDto = {
            name: `CronJob-${rnd.int(111111, 999999)}`,
            startTime: new Date(),
            stopTime: new Date(),
            succeeded: rnd.bool(),
            podStatuses: [generatePodStatus('CronJob', baseDate)],
          };

          baseDate = job.podStatuses[0].started;

          return job;
        }),
      },
    };
  };

  getDeploymentStatus = (): IResponse<IntDeploymentStatusDto> => {
    let baseDate = new Date();

    return {
      status: 200,
      data: {
        name: `Deployment-${rnd.int(1, 99)}`,
        replicas: 1,
        updateReplicas: 1,
        availableReplicas: 1,
        podStatuses: dummyData<IntPodStatusDto>(3, i => {
          const job = generatePodStatus('Deployment', baseDate);

          baseDate = job.started;

          return job;
        }),
      },
    };
  };
}

export const workloadDemoData = new WorkloadDemoData();
