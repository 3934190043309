import { column } from 'components/Table';
import { Column } from 'react-table';
import { IDataProperty } from 'views/Dashboard/DataSources/dataPropTypes';

const servicePropIds = {
  // For the "Smarthome" service, https://manage.teliaiot.com/Management/Service/Edit?servicename=Smarthome
  smartHomeLowBattery: '7789f9ba-2b7d-440c-9188-6deb4fb729dd',
  smartHomeWaterIndication: 'f7721ae4-6d26-41b7-b610-dee9584abe1d',
  smartHomeFireIndication: '4a775bfa-d781-498c-8ed1-e4a3979dda58',
  smartHomeDeviceConnectionStatus: '8a5a9fdb-2a83-41cd-98ce-6c4e814de830',

  // For "Climate", https://manage.teliaiot.com/Management/Service/Edit?servicename=Climate
  climateIaqSensorCalibrationStatus: 'b19699d7-0cbe-484a-8351-282055ec83ea',
  climateAirQualityIndex: 'e0cfd1e0-e97d-456d-b77c-ca76305557a9',
  devWeatherIaqSensorCalibrationStatus: '9ee97a74-c518-4d5c-a207-2c9043fd8ebf', // Like calibration status above, but on weather in development for testing
  devWeatherAirQualityIndex: 'ada7c9cf-ba55-4d16-8527-b7081a8abaab', // Like air quality above, but on weather in dev
};

export function getCustomColumnOverride(params: {
  id: string;
  header: string;
  accessor: (row: any) => any;
  dataProp: IDataProperty;
}): Column | undefined {
  const { id, header, accessor, dataProp } = params;

  const columnBoolIcon = (errorText: string, successText = 'Normal') => {
    return column.boolIcon({
      id,
      header,
      accessor,
      cells: {
        true: {
          iconType: 'Status',
          text: errorText,
          variant: 'error',
        },
        false: {
          iconType: 'StatusOk',
          text: successText,
        },
      },
    });
  };

  if (dataProp.id === servicePropIds.smartHomeLowBattery) {
    return columnBoolIcon('Low');
  } else if (
    dataProp.id === servicePropIds.smartHomeWaterIndication ||
    dataProp.id === servicePropIds.smartHomeFireIndication
  ) {
    return columnBoolIcon('Alarm');
  } else if (dataProp.id === servicePropIds.smartHomeDeviceConnectionStatus) {
    return column.boolIcon({
      id,
      header,
      accessor,
      cells: {
        true: {
          iconType: 'StatusOk',
          text: 'Online',
        },
        false: {
          iconType: 'Status',
          text: 'Offline',
          variant: 'error',
        },
      },
      aggregate: (values: any[]) => {
        if (values.find(value => value !== null) === undefined) {
          return null;
        }
        // If any row isn't connected, show that
        return values.find(value => value === 0) !== undefined ? 0 : 1;
      },
    });
  } else if (
    dataProp.id === servicePropIds.climateAirQualityIndex ||
    dataProp.id === servicePropIds.devWeatherAirQualityIndex
  ) {
    return column.airQualityIndex({
      id,
      header,
      accessor,
    });
  } else if (
    dataProp.id === servicePropIds.climateIaqSensorCalibrationStatus ||
    dataProp.id === servicePropIds.devWeatherIaqSensorCalibrationStatus
  ) {
    return column.iaqSensorCalibrationStatus({
      id,
      header,
      accessor,
    });
  }
  return undefined;
}
