import {
  endOfDay,
  endOfMonth,
  parseISO,
  startOfDay,
  startOfMonth,
  sub,
} from 'date-fns';
import { IDateRange } from 'views/Reports/DateRangeFilter/DateRangeFilter';
import { IDateFilterValue, ISavedDateFilterValue } from './dateFilterTypes';

export function getDateFilterOptions(enableTime: boolean): IDateFilterValue[] {
  const options: IDateFilterValue[] = [
    {
      type: 'today',
    },
    {
      type: '7_days',
    },
    {
      type: '30_days',
    },
    {
      type: 'this_month',
    },
    {
      type: 'last_month',
    },
    {
      type: '6_months',
    },
    {
      type: '1_year',
    },
    {
      type: '2_years',
    },
  ];

  if (enableTime) {
    options.unshift({
      type: '1_hour',
    });
  }

  return options;
}

export function getSavedDateFilterValue(
  savedValue?: ISavedDateFilterValue
): IDateFilterValue {
  if (
    savedValue?.type === 'custom' &&
    savedValue.startDate &&
    savedValue.endDate
  ) {
    return {
      type: 'custom',
      startDate: parseISO(savedValue.startDate),
      endDate: parseISO(savedValue.endDate),
    };
  }

  if (savedValue && savedValue?.type !== 'custom') {
    return {
      type: savedValue.type,
    };
  }

  return { type: '30_days' };
}

export function getDateRangeFromFilterValue(
  selectedOption: IDateFilterValue
): IDateRange {
  switch (selectedOption.type) {
    case '1_hour':
      return { startDate: sub(new Date(), { hours: 1 }), endDate: new Date() };

    case 'today':
      return { startDate: startOfDay(new Date()), endDate: new Date() };

    case '7_days':
      return {
        startDate: startOfDay(sub(new Date(), { days: 7 })),
        endDate: new Date(),
      };

    case '30_days':
      return {
        startDate: startOfDay(sub(new Date(), { days: 30 })),
        endDate: new Date(),
      };

    case 'this_month':
      return { startDate: startOfMonth(new Date()), endDate: new Date() };

    case 'last_month':
      return {
        startDate: startOfMonth(sub(new Date(), { months: 1 })),
        endDate: endOfMonth(sub(new Date(), { months: 1 })),
      };

    case '6_months':
      return {
        startDate: startOfDay(sub(new Date(), { months: 6 })),
        endDate: new Date(),
      };

    case '1_year':
      return {
        startDate: startOfDay(sub(new Date(), { years: 1 })),
        endDate: new Date(),
      };

    case '2_years':
      return {
        startDate: startOfDay(sub(new Date(), { years: 2 })),
        endDate: new Date(),
      };

    case 'custom':
      return {
        startDate: selectedOption.startDate,
        endDate: selectedOption.endDate,
      };

    default:
      return {
        startDate: startOfDay(new Date()),
        endDate: endOfDay(new Date()),
      };
  }
}
