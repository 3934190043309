import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    label: {
      textAlign: 'center',
      fontWeight: 'lighter',
    },
    smallLabel: {
      fontSize: 22,
    },
    mediumLabel: {
      fontSize: 26,
    },
    largeLabel: {
      fontSize: 32,
    },
    unit: {
      opacity: 0.6,
    },
    outerGaugeTrend: {
      opacity: 0.3,
    },
  })
);
