import { TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { FilterProps } from './FilterProps';

interface ITextFilterProps extends FilterProps<string | number | null> {
  textType: 'text' | 'number';
}

export const TextFilter: React.FC<ITextFilterProps> = ({
  textType,
  filter,
  onChange,
}) => {
  const initialValue = useRef(filter?.value);
  const [value, setValue] = useState(() => filter?.value || '');

  useEffect(() => {
    setValue(filter?.value || '');
  }, [filter?.value]);

  const handleBlur = () => {
    if (initialValue.current !== value) {
      onChange(value);
    }
  };

  const handleKeyDown: React.EventHandler<
    React.KeyboardEvent<HTMLInputElement>
  > = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleBlur();
    }
  };

  return (
    <TextField
      value={value}
      type={textType}
      variant="outlined"
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      onChange={e => setValue(e.target.value)}
      style={{ width: '100%' }}
    />
  );
};
