import { FilterRender } from 'react-table';
import DateIntervalFilter from './DateIntervalFilter';
interface IFilterProps extends FilterRender {
  timeInterval?: boolean;
}
const getDateIntervalFilter =
  (timeInterval: boolean): IFilterProps =>
  ({ filter, onChange }) => {
    return (
      <DateIntervalFilter
        filter={filter}
        timeInterval={timeInterval}
        onChange={onChange}
      />
    );
  };

export default getDateIntervalFilter;
