import { Box } from '@mui/system';
import i18n from 'i18next';
import { Column } from 'react-table';
import { getOptionFilter } from '../Helpers/getOptionFilter';

export function enumType<T>(options: {
  id: string;
  accessor: (row: T) => number;
  header: string;
  defaultShow?: boolean;
  filterable?: boolean;
  enumInterface: { [key: string]: number | string };
  translationKey: string;
}): Column<T> {
  const {
    id,
    header,
    accessor,
    defaultShow: show,
    filterable,
    enumInterface,
    translationKey,
  } = options;

  return {
    id,
    Header: header,
    accessor,
    filterable,
    show,
    Cell: row => <Box>{i18n.t(`${translationKey}.${row.value}`)}</Box>,
    Filter: getOptionFilter([
      {
        label: i18n.t('all'),
        value: '',
      },
      ...Object.keys(enumInterface)
        .filter(v => isNaN(Number(v)))
        .map(code => ({
          label: i18n.t(`${translationKey}.${enumInterface[code]}`),
          value: enumInterface[code],
        })),
    ]),
  };
}
