import { createStyles, makeStyles } from '@mui/styles';
import RouteRenderer from 'components/Auth/RouteRenderer';
import React, { useEffect } from 'react';
import { useStores } from 'store';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      [theme.breakpoints.up('lg')]: {
        maxWidth: 800,
        margin: '0 auto',
      },
    },
  })
);

const ScanLayout: React.FC = () => {
  const { customerStore } = useStores();
  const classes = useStyles();

  useEffect(() => {
    customerStore.getCustomers();
  }, [customerStore]);

  return (
    <div className={classes.root}>
      <RouteRenderer layout="scan" />
    </div>
  );
};

export default ScanLayout;
