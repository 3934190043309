import * as azureMaps from 'azure-maps-control';
import { useEffect } from 'react';
import { useGeofence } from './useGeofence';

export function useMapControls(
  options: {
    showGeofence?: boolean;
    showStylePicker?: boolean;
    showZoomButtons?: boolean;
  },
  map?: azureMaps.Map
) {
  const { showGeofence, showStylePicker, showZoomButtons } = options;
  const { layer, source } = useGeofence();

  useEffect(() => {
    if (map && showStylePicker) {
      const styleControl = new azureMaps.control.StyleControl({
        mapStyles: [
          'road',
          'road_shaded_relief',
          'grayscale_light',
          'night',
          'grayscale_dark',
          'high_contrast_dark',
        ],
      });

      map.controls.add(styleControl, {
        position: azureMaps.ControlPosition.TopRight,
      });

      return () => {
        map.controls.remove(styleControl);
      };
    } else {
      return undefined;
    }
  }, [map, showStylePicker]);

  // Toggle zoom control
  useEffect(() => {
    if (map && showZoomButtons) {
      const zoomControl = new azureMaps.control.ZoomControl({
        zoomDelta: 1,
        style: azureMaps.ControlStyle.auto,
      });

      map.controls.add(zoomControl, {
        position: azureMaps.ControlPosition.BottomLeft,
      });
      return () => {
        map.controls.remove(zoomControl);
      };
    } else {
      return undefined;
    }
  }, [map, showZoomButtons]);

  //Enable/Disable Geoference
  useEffect(() => {
    if (map && showGeofence) {
      if (!map.sources.getSources().includes(source)) {
        map.sources.add(source);
        map.layers.add(layer, 'layers');
      }

      return () => {
        try {
          map.layers.remove(['polygonLayer', 'lineLayer', 'symbolLayer']);
          map.sources.remove(source);
        } catch (error) {}
      };
    } else {
      return undefined;
    }
  }, [layer, map, showGeofence, source]);
}
