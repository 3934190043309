import {
  IntApnHubFirewallRuleDto,
  IntEnterpriseVpnDto,
  IntEnterpriseVpnListDto,
  IntEnterpriseVpnRequestDto,
  IntVpnsExcelDto,
} from 'generated';
import {
  GetEndpoint,
  PatchEndpoint,
  PostEndpoint,
  TableEndpoint,
} from 'shared/interfaces/api';
import { ipAccessDemoData } from './demoData/ipAccessDemoData';

const baseUrl = '/api/ipaccess/vpns';

export interface TempFailedSessionAttemptsDto {
  timestamp: Date;
  label: string;
  simId: string;
  eventCause: string;
  imsi: string;
  msisdn: string;
  tgppSgsnMccMnc: string;
}

type IpAccessAPI = {
  getVpnTable: (customerId: string) => TableEndpoint<IntEnterpriseVpnListDto>;
  getVpn: GetEndpoint<string, IntEnterpriseVpnDto>;
  getEnterpriseVpns: GetEndpoint<string, IntEnterpriseVpnDto[]>;
  saveVpn: PostEndpoint<undefined, IntEnterpriseVpnRequestDto>;
  updateVpn: PostEndpoint<string, IntEnterpriseVpnRequestDto>;
  updateVpnFireWallRules: PatchEndpoint<string, IntApnHubFirewallRuleDto[]>;
  getFailedSessionAttempts: TableEndpoint<TempFailedSessionAttemptsDto>;
  getSpecifications: PostEndpoint<undefined, IntVpnsExcelDto, BlobPart>;
};

export const ipAccessAPI: IpAccessAPI = {
  getVpnTable: customerId => ({
    url: `${baseUrl}/${customerId}/table`,
    mockData: ipAccessDemoData.getVpnTable,
  }),
  getVpn: {
    url: id => `${baseUrl}/${id}`,
    mockData: ipAccessDemoData.getVpnDetails,
  },
  getEnterpriseVpns: {
    url: customerId => `${baseUrl}/${customerId}/all`,
    mockData: ipAccessDemoData.getEnterpriseVpns,
  },
  saveVpn: {
    url: baseUrl,
    mockData: () => ({ status: 200 }),
  },
  updateVpn: {
    url: vpnUuid => `${baseUrl}/${vpnUuid}`,
    mockData: () => ({ status: 200 }),
  },
  updateVpnFireWallRules: {
    url: vpnUuid => `${baseUrl}/${vpnUuid}/firewallrules`,
    mockData: () => ({ status: 200 }),
  },
  getFailedSessionAttempts: {
    url: `${baseUrl}`,
    mockData: ipAccessDemoData.getFailedSessionAttempts,
  },
  getSpecifications: {
    url: `${baseUrl}/excel`,
  },
};
