import { IconButton, OutlinedInput } from '@mui/material';
import AtlasIcon from 'components/AtlasIcon';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useStyles } from './SearchField.styles';

interface IProps {
  onChange: (searchTerm: string) => void;
  value: string;
  placeholder?: string;
  disableTabKey?: boolean;
}

const SearchField: React.FC<IProps> = ({
  onChange,
  value,
  placeholder,
  disableTabKey,
}) => {
  const classes = useStyles();
  const initialValue = useRef(value || '');
  const timerRef = useRef<number>();

  const [currentVal, setCurrentVal] = useState(initialValue.current);

  useEffect(() => {
    if (value !== initialValue.current) {
      initialValue.current = value;
      setCurrentVal(value);
    }
  }, [value]);

  const submitValue = useCallback(() => {
    if (initialValue.current !== currentVal) {
      initialValue.current = currentVal;
      onChange(currentVal);
    }
  }, [initialValue, onChange, currentVal]);

  const handleReset = () => {
    setCurrentVal('');
    initialValue.current = '';
    onChange('');
  };

  const handleKeyDown: React.EventHandler<
    React.KeyboardEvent<HTMLInputElement>
  > = e => {
    window.clearTimeout(timerRef.current);

    if (e.key === 'Escape') {
      e.preventDefault();
      e.stopPropagation();
      setTimeout(() => handleReset(), 1); // Awful, obviously, but good enough for POC? Probably move to a useEffect on a new state?
    } else if (e.key === 'Enter') {
      e.preventDefault();
      submitValue();
    }
  };

  useEffect(() => {
    if (initialValue.current !== currentVal) {
      timerRef.current = window.setTimeout(submitValue, 500);
    }
    return () => {
      window.clearTimeout(timerRef.current);
    };
  }, [currentVal, submitValue]);

  return (
    <OutlinedInput
      className={classes.root}
      placeholder={placeholder}
      inputProps={{
        className: classes.input,
        'data-cy': 'searchFieldInput',
      }}
      tabIndex={disableTabKey ? -1 : 1}
      endAdornment={
        <>
          <IconButton
            data-cy="searchFieldCloseButton"
            style={{ display: currentVal ? 'block' : 'none' }}
            onClick={handleReset}
            size="large"
          >
            <AtlasIcon type="Close" size={18} />
          </IconButton>
          <AtlasIcon type="Search" size={24} />
        </>
      }
      value={currentVal}
      onChange={e => setCurrentVal(e.target.value)}
      onBlur={submitValue}
      onKeyDown={handleKeyDown}
    />
  );
};

export default SearchField;
