import { faker } from '@faker-js/faker';
import { TableData } from 'components/Table';
import { subDays } from 'date-fns';
import {
  ConnectivityUnitLocalizedStatus,
  ConnectivityUnitProfileActivationStatus,
  ConnectivityUnitProfileDownloadStatus,
  ConnectivityUnitStatus,
  ConnectivityUnitType,
  IntAttributeDto,
  IntBulkSubscriptionStatusResultDto,
  IntChangeLabelDto,
  IntConnectivityType,
  IntConnectivityUnitDto,
  IntConnectivityUnitProfileDto,
  IntConnectivityUnitStatusCountDto,
  IntConnectivityUnitTableDto,
  IntConnectivityUnitTableRequestDto,
  IntCustomerDto,
  IntLocaleDto,
  IntSearchDto,
  IntSubscriptionStatusRequest,
  IntUnitMinimalConsumptionDto,
  TestModeStatus,
} from 'generated';
import {
  IGetLocalesParams,
  TPropertyName,
} from 'services/connectivityUnit.service';
import { IResponse } from 'shared/interfaces/api';
import { paginate, rnd } from 'utils';
import {
  AssignBootstrap,
  SubscriptionAssign,
} from 'views/Connectivity/Sim/Details/SIMSubscriptions/SubscriptionActionWizard/SubscriptionActionWizard';
import { connectivityGroupDemoData } from './connectivityGroupDemoData';

const locales = {
  tMobileAustria: 'T-Mobile Austria',
  chinaTelecom: 'China Telecom',
};

const operator = {
  teliaSonera: 'TeliaSonera',
  ctg: 'CTG',
};

const country = {
  austria: 'Austria',
  china: 'China',
  germany: 'Germany',
  switzerland: 'Switzerland',
  sweden: 'Sweden',
  denmark: 'Denmark',
  norway: 'Norway',
  finland: 'Finland',
  lithuania: 'Lithuania',
};

const homeNetworks = ['FI', 'NO', 'DK', 'EE', 'LT'];

const availableLocales: IntLocaleDto[] = [
  {
    id: locales.tMobileAustria,
    name: locales.tMobileAustria,
  },
  {
    id: locales.chinaTelecom,
    name: locales.chinaTelecom,
  },
];

// Combine both table and details data into one type for ease of use
type IDemoUnit = IntConnectivityUnitDto & IntConnectivityUnitTableDto;

class ConnectivityUnitDemoData {
  private _units: IDemoUnit[] | undefined;
  private _activeCustomAttributes: IntAttributeDto[] = [];
  get units() {
    if (!this._units) {
      this._units = getDemoUnits(183); // Don't generate data until it's needed
    }
    return this._units;
  }

  getUnit = (connectivityUnitId: string): IDemoUnit => {
    const unit = this.units.find(
      x => x.connectivityUnitId === connectivityUnitId
    );

    if (!unit) {
      throw new Error('ConnectivityUnit missing');
    }

    return unit;
  };

  getConnectivityUnitTable = (
    params: IntSearchDto,
    resourceGroupId?: string
  ): IResponse<TableData<IntConnectivityUnitTableDto>> => {
    let filteredUnits = this.units;
    if (resourceGroupId) {
      const group = connectivityGroupDemoData.getGroup(resourceGroupId);
      filteredUnits = filteredUnits.filter(
        unit => unit.resourceGroupName === group.label
      );
    }
    if (typeof params.search === 'string') {
      filteredUnits = filteredUnits.filter(
        unit =>
          params.search &&
          unit.label.toLowerCase().includes(params.search.toLowerCase())
      );
    }

    return {
      status: 200,
      data: paginate(filteredUnits, params),
    };
  };

  getConnectivityUnitTablePost = (
    _: undefined,
    data?: IntConnectivityUnitTableRequestDto,
    resourceGroupId?: string
  ): IResponse<TableData<IntConnectivityUnitTableDto>> => {
    let filteredUnits = this.units;
    if (resourceGroupId) {
      const group = connectivityGroupDemoData.getGroup(resourceGroupId);
      filteredUnits = filteredUnits.filter(
        unit => unit.resourceGroupName === group.label
      );
    }
    if (typeof data?.search === 'string') {
      filteredUnits = filteredUnits.filter(
        unit =>
          data.search &&
          unit.label.toLowerCase().includes(data.search.toLowerCase())
      );
    }

    return {
      status: 200,
      data: paginate(filteredUnits, data),
    };
  };

  getUnitsInGroupTable = (groupId: string) => {
    return (params: IntSearchDto) =>
      this.getConnectivityUnitTable(params, groupId);
  };

  getUnitDetails = (
    connectivityUnitId: string
  ): IResponse<IntConnectivityUnitDto> => {
    return {
      status: 200,
      data: this.getUnit(connectivityUnitId),
    };
  };

  setSubscriptionStatus = (params: {
    connectivityUnitId: string;
    targetStatus: IntSubscriptionStatusRequest;
  }): IResponse => {
    const { connectivityUnitId, targetStatus } = params;
    const unit = this.getUnit(connectivityUnitId);

    unit.activationStatus = ConnectivityUnitProfileActivationStatus.InProgress;

    window.setTimeout(() => {
      unit.activationStatus = getActivationStatusFromRequest(targetStatus);

      if (
        unit.activationStatus ===
        ConnectivityUnitProfileActivationStatus.Deactivated
      ) {
        unit.status = ConnectivityUnitStatus.TemporaryBlock;
      } else if (
        unit.activationStatus === ConnectivityUnitProfileActivationStatus.Active
      ) {
        unit.status = ConnectivityUnitStatus.Working;
      }
    }, 2000);

    return {
      status: 200,
    };
  };

  bulkSetSubscriptionStatus = (
    statusRequest: IntSubscriptionStatusRequest,
    data: string[] = []
  ): IResponse<IntBulkSubscriptionStatusResultDto> => {
    const targetStatus = getActivationStatusFromRequest(statusRequest);

    data.forEach(id => {
      const unit = this.getUnit(id);
      unit.status =
        statusRequest === IntSubscriptionStatusRequest.Activate
          ? ConnectivityUnitStatus.Working
          : ConnectivityUnitStatus.TemporaryBlock;
      unit.activationStatus = targetStatus;
      unit.enabledProfile.activationStatus = targetStatus;
    });

    return {
      status: 200,
      data: {
        success: data,
        errors: [],
      },
    };
  };

  setLabel = (
    connectivityUnitId: string,
    data?: IntChangeLabelDto
  ): IResponse => {
    const unit = this.getUnit(connectivityUnitId);

    unit.label = data?.label || '';

    return {
      status: 200,
    };
  };

  activateLocale = (params: {
    connectivityUnitId: string;
    localeId: string;
  }): IResponse => {
    const unit = this.getUnit(params.connectivityUnitId);
    const { profiles } = unit;

    let newEnabledProfile = profiles.find(
      profile => profile.localeId === params.localeId
    );

    // If it's not already in the list of profiles, create it
    if (!newEnabledProfile) {
      const newLocale = availableLocales.find(
        locale => locale.id === params.localeId
      );
      if (newLocale) {
        newEnabledProfile = getProfileFromLocale(newLocale);
        profiles.push(newEnabledProfile);
      } else {
        return { status: 500 };
      }
    }

    unit.enabledProfile.enabled = false;
    unit.localizedStatus = ConnectivityUnitLocalizedStatus.Localized;
    newEnabledProfile.enabled = true;
    unit.enabledProfile = newEnabledProfile;
    unit.localizedTo = newEnabledProfile.localeName;

    return {
      status: 200,
    };
  };

  activateBootstrap = (
    connectivityUnitId: string,
    data?: AssignBootstrap
  ): IResponse => {
    if (!data) {
      return { status: 500 };
    }

    const unit = this.getUnit(connectivityUnitId);
    unit.enabledProfile.enabled = false; // Disable previously active profile

    const bootstrapProfile = unit.profiles[0];
    bootstrapProfile.enabled = true;
    unit.enabledProfile = bootstrapProfile;
    unit.localizedStatus = ConnectivityUnitLocalizedStatus.NotLocalized;
    unit.localizedTo = '';
    unit.currentLocation = country.sweden;

    return {
      status: 200,
    };
  };

  downloadLocale = (params: SubscriptionAssign): IResponse => {
    const { connectivityUnitId, localeId } = params;

    const unit = this.getUnit(connectivityUnitId);
    const locale = availableLocales.find(
      availableLocale => availableLocale.id === localeId
    );
    if (!locale) {
      return { status: 500 };
    }
    const getProfile = getProfileFromLocale(locale, true);
    unit.profiles.push(getProfile);
    unit.activationStatus = ConnectivityUnitProfileActivationStatus.InProgress;

    setTimeout(() => {
      const selectedProfile = unit.profiles.find(
        profile =>
          profile.connectivityUnitProfileId ===
          getProfile.connectivityUnitProfileId
      );
      if (selectedProfile) {
        selectedProfile.activationStatus =
          ConnectivityUnitProfileActivationStatus.Active;
        selectedProfile.downloadStatus =
          ConnectivityUnitProfileDownloadStatus.Downloaded;
      }
      unit.activationStatus = ConnectivityUnitProfileActivationStatus.Active;
    }, 5000);

    return {
      status: 200,
    };
  };

  getLocales = (params: IGetLocalesParams): IResponse<IntLocaleDto[]> => {
    if (params.notDownloadedOnly) {
      const unit = this.getUnit(params.connectivityUnitId);

      return {
        status: 200,
        data: availableLocales.filter(
          locale =>
            !unit.profiles.find(profile => profile.localeId === locale.id)
        ),
      };
    }

    return {
      status: 200,
      data: availableLocales,
    };
  };

  getMostActiveUnits = (): IResponse<IntUnitMinimalConsumptionDto[]> => {
    return {
      status: 200,
      data: rnd
        .array<IntUnitMinimalConsumptionDto>(10, i => ({
          totalConsumption: i === 0 ? 0 : rnd.data(),
          identifier: this.units[i].identifier,
          label: this.units[i].label,
          connectivityUnitId: this.units[i].connectivityUnitId,
          lastActive: this.units[i].lastActive,
        }))
        .sort((a, b) => b.totalConsumption - a.totalConsumption),
    };
  };

  getStatusDonutChart = (): IResponse<IntConnectivityUnitStatusCountDto[]> => {
    const statusDtos: IntConnectivityUnitStatusCountDto[] = [];

    this.units.forEach(unit => {
      // Todo: These two enums (ConnectivityUnitProfileActivationStatus & IntProfileActivationStatus) are identical, but currently duplicated (?!)
      let dto = statusDtos.find(
        statusDto =>
          statusDto.connectivityUnitStatus === (unit.activationStatus as any)
      );

      if (!dto) {
        dto = {
          connectivityUnitStatus: unit.activationStatus as any,
          count: 0,
        };
        statusDtos.push(dto);
      }

      dto.count++;
    });

    return { status: 200, data: statusDtos };
  };

  getCSVData = (selectedUnits: string[] | undefined): IResponse<BlobPart> => {
    const data =
      this._units && selectedUnits
        ? getCSVFile(
            this._units.filter((unit: IDemoUnit) =>
              selectedUnits.includes(unit.connectivityUnitId)
            )
          )
        : undefined;
    return {
      status: 200,
      data,
    };
  };

  getLocalesInUse = (): IResponse<string[]> => {
    return { status: 200, data: Object.values(locales) };
  };

  getHomeNetworks = (): IResponse<string[]> => {
    return { status: 200, data: homeNetworks };
  };

  getProductions = (): IResponse<string[]> => {
    return { status: 200, data: Object.values(country) };
  };

  getProperties = (propertyName: TPropertyName): IResponse<string[]> => {
    switch (propertyName) {
      case 'homeNetwork':
        return this.getHomeNetworks();
      case 'production':
        return this.getProductions();
      case 'localized':
      default:
        return this.getLocalesInUse();
    }
  };

  upsertCustomAttribute = (
    newCustomAttribute: IntAttributeDto
  ): IResponse<IntAttributeDto> => {
    const index = this._activeCustomAttributes.findIndex(
      attr => attr.key === newCustomAttribute.key
    );
    if (index < 0) {
      // Create
      this._activeCustomAttributes.push(newCustomAttribute);
      return {
        status: 201,
        data: newCustomAttribute,
      };
    }

    // Update
    this._activeCustomAttributes.splice(index, 1, newCustomAttribute);

    return {
      status: 200,
      data: newCustomAttribute,
    };
  };

  deleteCustomAttribute = (id: string): IResponse => {
    const index = this._activeCustomAttributes.findIndex(
      customAttr => customAttr.id === id
    );

    this._activeCustomAttributes.splice(index, 1);

    return { status: 200 };
  };
}

const demoCustomer: IntCustomerDto = { id: 'customer1', name: 'Any customer' };

function getDemoUnits(n: number) {
  const units: IDemoUnit[] = [];
  for (let i = 0; i < n; i++) {
    // Use static IDs so links and reloads work
    const unitId = `0fe561ae-f4ke-un1t-${i
      .toString()
      .padStart(4, '0')}-20693c9dfa59`;

    units.push(getDemoUnit(unitId));
  }
  return units;
}

function getDemoUnit(connectivityUnitId: string): IDemoUnit {
  const bootstrapProfile: IntConnectivityUnitProfileDto = {
    connectivityUnitProfileId: rnd.guid(),
    msisdn: rnd.msisdn(),
    voice: rnd.bool(),
    subscriptionPackageName: rnd.fullName(),
    apns: rnd.item('Apn 1', 'Apn 2', 'Apn 3'),
    imsi: rnd.imsi(),
    iccId: rnd.iccid(),
    pin1: rnd.pin(),
    pin2: rnd.pin(),
    puk1: rnd.puk(),
    puk2: rnd.puk(),
    enabled: false,
    bootstrap: true,
    operatorName: operator.teliaSonera,
    currentLocation: rnd.weighted<string>(
      { value: country.sweden, weight: 10 },
      { value: '', weight: 2 },
      { value: country.denmark },
      { value: country.norway },
      { value: country.finland }
    ),
    currentOperator: operator.teliaSonera,
    localeName: '',
    localeId: '',
    downloadStatus: ConnectivityUnitProfileDownloadStatus.Downloaded,
    activationStatus: ConnectivityUnitProfileActivationStatus.Active,
    countryName: '',
  };

  // Add one extra profile (leaving one locale available to download later)
  const secondProfile = getProfileFromLocale(rnd.item(...availableLocales));

  let enabledProfile: IntConnectivityUnitProfileDto;

  // Most units should not be localized
  if (rnd.int(0, 100) > 66) {
    enabledProfile = secondProfile;
  } else {
    enabledProfile = bootstrapProfile;
  }

  enabledProfile.enabled = true;

  const { resourceGroupId, resourceGroupName } = rnd.resourceGroup();

  const demoUnit: IDemoUnit = {
    connectivityUnitId,
    customer: demoCustomer,
    status: ConnectivityUnitStatus.Working,
    label: rnd.label(),
    identifier: rnd.euiccId(),
    type: rnd.enum(ConnectivityUnitType),
    imsi: enabledProfile.imsi,
    iccId: enabledProfile.iccId,
    msisdn: rnd.msisdn(),
    currentLocation: enabledProfile.currentLocation,
    localizedTo: enabledProfile.localeName,
    lastActive: faker.date.recent(),
    resourceGroupId,
    resourceGroupName,
    localizedStatus: enabledProfile.bootstrap
      ? ConnectivityUnitLocalizedStatus.NotLocalized
      : ConnectivityUnitLocalizedStatus.Localized,
    imei: rnd.imei(),
    activationStatus: ConnectivityUnitProfileActivationStatus.Active,
    roaming: rnd.bool(),
    formFactor: rnd.formFactor(),
    simCardDescription: rnd.simDescription(),
    rateplanDisplayName: rnd.rateplan().displayName,
    enabledProfile,
    profiles: [bootstrapProfile, secondProfile],
    downloadedData: rnd.mb(),
    uploadedData: rnd.mb(),
    totalData: rnd.mb(),
    incomingMessages: rnd.int(0, 2048),
    outgoingMessages: rnd.int(0, 2048),
    totalMessages: rnd.int(0, 2048),
    testModeStatus: rnd.enum(TestModeStatus),
    connectivityProductType: rnd.enum(IntConnectivityType),
    customAttributes: null,
    homeNetwork: rnd.locale().id,
    production: rnd.locale().name,
  };

  if (!demoUnit.currentLocation) {
    demoUnit.lastActive = new Date(0); // The units without currentCountry have never been active.
  }

  // Most units should be active, but make a few deactivated/terminated
  const statusRandom = rnd.int(0, 100);
  if (statusRandom > 98) {
    demoUnit.status = ConnectivityUnitStatus.PermanentBlock;
    demoUnit.activationStatus =
      ConnectivityUnitProfileActivationStatus.Terminated;
    demoUnit.lastActive = subDays(new Date(), rnd.int(31, 93));
  } else if (statusRandom > 85) {
    demoUnit.status = ConnectivityUnitStatus.TemporaryBlock;
    demoUnit.activationStatus =
      ConnectivityUnitProfileActivationStatus.Deactivated;
  }

  if (demoUnit.testModeStatus === TestModeStatus.Expired) {
    demoUnit.testModeExpiredDate = rnd.date.recent();
  }

  return demoUnit;
}

function getProfileFromLocale(locale: IntLocaleDto, downloading = false) {
  const profile: IntConnectivityUnitProfileDto = {
    connectivityUnitProfileId: rnd.guid(),
    msisdn: rnd.msisdn(),
    imsi: rnd.imsi(),
    voice: rnd.bool(),
    subscriptionPackageName: rnd.fullName(),
    apns: rnd.item('Apn 1', 'Apn 2', 'Apn 3'),
    iccId: rnd.iccid(),
    pin1: rnd.pin(),
    pin2: rnd.pin(),
    puk1: rnd.puk(),
    puk2: rnd.puk(),
    enabled: false,
    bootstrap: false,
    operatorName: '',
    currentLocation: '',
    currentOperator: '',
    localeName: locale.name,
    localeId: locale.id,
    downloadStatus: downloading
      ? ConnectivityUnitProfileDownloadStatus.InProgress
      : ConnectivityUnitProfileDownloadStatus.Downloaded,
    activationStatus: downloading
      ? ConnectivityUnitProfileActivationStatus.Unknown
      : ConnectivityUnitProfileActivationStatus.Active,
    countryName: '',
  };

  if (locale.name === locales.chinaTelecom) {
    profile.operatorName = operator.ctg;
    profile.currentLocation = country.china;
    profile.currentOperator = operator.ctg;
    profile.countryName = country.china;
  } else if (locale.name === locales.tMobileAustria) {
    profile.operatorName = locales.tMobileAustria;
    profile.currentLocation = rnd.item(
      country.austria,
      country.germany,
      country.switzerland
    );
    profile.currentOperator = locales.tMobileAustria;
    profile.countryName = country.austria;
  }

  return profile;
}

export const connectivityUnitDemoData = new ConnectivityUnitDemoData();

function getActivationStatusFromRequest(
  statusRequest: IntSubscriptionStatusRequest
): ConnectivityUnitProfileActivationStatus {
  switch (statusRequest) {
    case IntSubscriptionStatusRequest.Activate:
      return ConnectivityUnitProfileActivationStatus.Active;

    case IntSubscriptionStatusRequest.Pause:
      return ConnectivityUnitProfileActivationStatus.Paused;

    case IntSubscriptionStatusRequest.Deactivate:
    default:
      return ConnectivityUnitProfileActivationStatus.Deactivated;
  }
}

const getCSVFile = (units: IDemoUnit[]) => {
  const rows = units.map(
    unit => `
      ${unit.connectivityUnitId}\
      ,${unit.status}\
      ,${unit.label}\
      ,${unit.identifier}\
      ,${unit.type}\
      ,${unit.imsi}\
      ,${unit.msisdn}\
      ,${unit.iccId}\
      ,${unit.currentLocation}\
      ,${unit.localizedTo}\
      ,${unit.lastActive}\
      ,${unit.resourceGroupId}\
      ,${unit.resourceGroupName}\
      ,${unit.uploadedData}\
      ,${unit.downloadedData}\
      ,${unit.totalData}\
      ,${unit.incomingMessages}\
      ,${unit.outgoingMessages}\
      ,${unit.customer.id}\
      ,${unit.customer.name}\
      ,${unit.testModeStatus}`
  );

  return `ConnectivityUnitId,\
  Status,\
  Label,\
  Identifier,\
  Type,\
  Imsi,\
  Msisdn,\
  IccId,\
  CurrentLocation,\
  LocalizedTo,\
  LastActive,\
  ResourceGroupId,\
  ResourceGroupName,\
  UploadedData,\
  DownloadedData,\
  TotalData,\
  IncomingMessages,\
  OutgoingMessages,\
  TotalMessages,\
  Customer.Id,\
  Customer.Name,\
  TestModeStatus\
    ${rows}`;
};
