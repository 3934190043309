import Unauthorized from 'components/Unauthorized';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Route, RouteComponentProps, RouteProps } from 'react-router-dom';
import { AtlasLayoutType } from 'shared/types/atlas-layout-type';
import { useStores } from 'store';
import DocumentTitle from '../DocumentTitle';
import { EFeature } from '../EFeature';
import { Role } from '../Role';
import { useRedirectToLogin } from '../useRedirectToLogin';

interface IProps extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any>>;
  title?: string;
  roles?: Role | Role[];
  feature?: EFeature;
  layout?: AtlasLayoutType;
}

// Remember to delete me in v2
const ProtectedRoute: React.FC<IProps> = ({
  roles,
  feature,
  component: Component,
  title,
  ...routeProps
}) => {
  const { authStore } = useStores();
  const isRolesAllowed = authStore.hasRole(roles);
  const isFeatureAllowed = authStore.hasFeature(feature);

  const redirectToLogin = useRedirectToLogin();

  if (roles && !authStore.isLoggedIn) {
    return redirectToLogin;
  }

  return (
    <Route
      {...routeProps}
      render={props => (
        <>
          {isRolesAllowed && isFeatureAllowed ? (
            <>
              <DocumentTitle title={title} />
              <Component {...props} />
            </>
          ) : (
            <Unauthorized />
          )}
        </>
      )}
    />
  );
};

export default observer(ProtectedRoute);
