import { useCallback, useEffect, useState } from 'react';

interface ISize {
  elementWidth: number;
  elementHeight: number;
}

function useElementSize<T extends HTMLElement = HTMLDivElement>(): [
  (node: T | null) => void,
  ISize,
] {
  const [ref, setRef] = useState<T | null>(null);
  const [size, setSize] = useState<ISize>({
    elementWidth: 0,
    elementHeight: 0,
  });

  const handleSize = useCallback(() => {
    setSize({
      elementWidth: ref?.offsetWidth || 0,
      elementHeight: ref?.offsetHeight || 0,
    });
  }, [ref?.offsetHeight, ref?.offsetWidth]);

  useEffect(() => {
    window.addEventListener('resize', handleSize);
    return () => {
      window.removeEventListener('resize', handleSize);
    };
  }, [handleSize]);

  return [setRef, size];
}

export default useElementSize;
