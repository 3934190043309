import { DashboardComponentType } from 'generated';
import {
  IBaseComponentSettings,
  IComponentTypeConfig,
} from 'views/Dashboard/dashboardTypes';
import { IDataSourceSettings } from 'views/Dashboard/DataSources/dataSourceTypes';
import PreviewStep from '../PreviewStep';
import HighchartsMapContainer from './HighchartsMapContainer';
import GeneralSettingsStep from './SettingsSteps/GeneralSettingsStep';

export interface IHighthchartsMapSettings extends IBaseComponentSettings {
  title: string;
  dataSource: IDataSourceSettings;

  valueProp: string;
}

export const highchartsMapComponentConfig: IComponentTypeConfig<
  DashboardComponentType.HighchartsMap,
  IHighthchartsMapSettings
> = {
  widgetType: DashboardComponentType.HighchartsMap,
  icon: 'Statistics',
  Component: HighchartsMapContainer,
  settingsSteps: [
    {
      title: 'settings',
      Component: GeneralSettingsStep,
    },
    {
      title: 'final_preview',
      Component: PreviewStep,
    },
  ],
  getDefaultSettings: ({ dataSource }) => {
    return {
      title: '',
      dataSource: dataSource.settings,
      position: { x: 0, y: 0, w: 6, h: 6 },
      valueProp:
        dataSource.dataProperties
          .slice()
          .reverse()
          .find(dp => dp.type === 'number')?.id || '',
    };
  },
  fixSettings: s => {
    if (!s.valueProp) {
      s.valueProp = '';
    }
  },
};
