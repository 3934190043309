import { Box, Container } from '@mui/material';
import classNames from 'classnames';
import { LoadingSpinner } from 'components';
import { observer } from 'mobx-react-lite';
import React, { Suspense } from 'react';
import { useStores } from 'store/RootStoreContext';
import { useStyles } from './ContentContainer.styles';

const tempSpinner = (
  <Box
    minHeight="80vh"
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    <LoadingSpinner size={60} />
  </Box>
);

const ContentContainer: React.FC = ({ children }) => {
  const { layoutStore } = useStores();
  const classes = useStyles();

  return (
    <div
      className={classNames(classes.root, {
        [classes.headerShown]: layoutStore.header,
        [classes.settingDrawerOpen]: layoutStore.settingsMenuOpen,
      })}
    >
      <Container className={classes.container}>
        <Suspense fallback={tempSpinner}>{children}</Suspense>
      </Container>
    </div>
  );
};

export default observer(ContentContainer);
