import { Box, CircularProgress, useTheme } from '@mui/material';
import Lottie, { LottieOptions } from 'lottie-react';
import React from 'react';
import { SpinnerType } from 'shared/types/spinner-type';
import animationData from '../../shared/animations/activity-indicator/TC_Activity_indicator_64x64_Core_Purple_500.json';

interface IProps {
  size?: number;
  disableShrink?: boolean;
  style?: React.CSSProperties;
  color?: 'inherit' | 'primary' | 'secondary';
}

const lottieOptions: LottieOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const LoadingSpinner: React.FC<IProps> = ({ size = 40, style, ...props }) => {
  const theme = useTheme();
  const spinnerType: SpinnerType = theme.settings?.spinner.type || 'telia';

  const Spinner: React.FC = () => {
    if (spinnerType === 'telia') {
      return (
        <Box height={size} width={size} style={style}>
          <Lottie {...lottieOptions} />
        </Box>
      );
    } else {
      return <CircularProgress {...props} size={size} style={style} />;
    }
  };

  return <Spinner />;
};
export default React.memo(LoadingSpinner);
