import { IGroupedData } from 'services/service.service';

export type IFlattenedServiceData = Record<string, string | null>;

// Since "latest" data can still contain several rows, we need to loop through them and find the actual values
// This approach won't work if we need the exact timestamp each service property was last updated, but is fine for now
function flattenLatestData(group: IGroupedData) {
  const flattened: IFlattenedServiceData = {};

  if (!group.dataPoints.length) {
    console.warn('Group missing data points'); // In reality, the backend should catch this. Just in case.
  }
  const keys = Object.keys(group.dataPoints[0]);
  keys.forEach(key => {
    const val = group.dataPoints.find(dataPoint => dataPoint[key] !== null);
    flattened[key] = val ? val[key] : null;
  });
  return flattened;
}

function flattenAndGroupById(data: IGroupedData[]) {
  const flattenedData: Record<string, IFlattenedServiceData> = {};

  data.forEach(group => {
    flattenedData[group.id] = serviceDataUtils.flattenLatestData(group);
  });

  return flattenedData;
}

export const serviceDataUtils = {
  flattenLatestData,
  flattenAndGroupById,
};
