import { Box } from '@mui/material';
import AtlasIcon from 'components/AtlasIcon';
import AtlasTooltip from 'components/AtlasTooltip';
import { Column } from 'react-table';
import { formatNumber } from 'utils';
import ColArrayValue from '../Helpers/ColArrayValue';
import { getTextFilter } from '../Helpers/getTextFilter';
import { textRight } from '../Helpers/textRight';
import { IColumnAggregationProps } from '../PivotCells/IColumnAggregationProps';
import { getNumberMinMaxAggregationProps } from '../PivotCells/NumberMinMax';

export function number<T>(options: {
  id: string;
  header: string;
  accessor: (row: T) => number | number[] | undefined | null;
  decimals?: number;
  defaultShow?: boolean;
  defaultSortDesc?: boolean;
  filterable?: boolean;
  sortable?: boolean;
  resizable?: boolean;
  width?: number;
  tooltip?: boolean;
  tooltipText?: string;
  maxWidth?: number;
  unit?: string;

  aggregate?: 'min-max';
}): Column<T> {
  const {
    id,
    header,
    accessor,
    defaultShow: show = true,
    defaultSortDesc = false,
    filterable,
    sortable,
    resizable,
    width = 100,
    tooltip = false,
    tooltipText = '',
    maxWidth = 150,
    unit,
    decimals,
    aggregate,
  } = options;

  let filterRender = getTextFilter('number');

  const aggregationProps: IColumnAggregationProps =
    aggregate === 'min-max' ? getNumberMinMaxAggregationProps({ unit }) : {};

  return {
    id,
    Header: tooltip ? (
      <AtlasTooltip placement="top" title={tooltipText} arrow>
        <Box textAlign="right">
          {header}
          <AtlasIcon size={14} type="Info" />
        </Box>
      </AtlasTooltip>
    ) : (
      <Box textAlign="right">{header}</Box>
    ),
    accessor,
    width,
    maxWidth,
    show,
    filterable,
    sortable,
    resizable,
    Filter: filterRender,
    className: textRight,
    defaultSortDesc,
    Cell: unit
      ? ({ value }) =>
          typeof value === 'number' && (
            <>
              {formatNumber(value, { decimals })}
              <span className="text-unit">{unit}</span>
            </>
          )
      : ({ value }) => {
          if (Array.isArray(value)) {
            return (
              <ColArrayValue value={value} decimals={decimals} type="number" />
            );
          } else {
            return formatNumber(value, { decimals });
          }
        },
    ...aggregationProps,
  };
}
