import { ServiceDataFilterType } from 'generated';
import i18n from 'i18n';
import { usageAPI } from 'services/usage.service';
import { formatLocalTimezoneToUTC } from 'utils/formatting';
import { IDataPropCategory } from '../../dataPropTypes';
import { makeGenericDataSource } from '../../makeGenericDataSource';

const category: IDataPropCategory = {
  displayName: i18n.t('dashboard:data.properties'),
};

export const simActivityByCountryDataSource = makeGenericDataSource({
  endpoint: usageAPI.getDataUsageByCountry,

  getParams: filterState => {
    const {
      getDateFilters,
      assetFilterSpecDtos,
      dashboard: { rootStore },
    } = filterState;
    const { from, to } = getDateFilters();

    const customerIds =
      assetFilterSpecDtos.find(
        filter => filter.type === ServiceDataFilterType.CustomerId
      )?.values || [];

    return {
      startDate: from ? formatLocalTimezoneToUTC(from) : undefined,
      endDate: to ? formatLocalTimezoneToUTC(to) : undefined,
      customerId: customerIds.length > 0 ? customerIds[0] : undefined,
      connectivityType: rootStore.connectivityUnitStore.currentConnectivityType,
    };
  },

  getDataProps: () => [
    {
      id: 'country',
      type: 'string',
      category,
      _get: row => row.country,
      name: i18n.t('sim:activity.country'),
      isDefaultDisplayName: true,
    },
    {
      id: 'downloaded',
      type: 'dataUsage',
      category,
      _get: row => row.downloaded,
      name: i18n.t('sim:activity.downloaded'),
      width: 100,
    },
    {
      id: 'uploaded',
      type: 'dataUsage',
      category,
      _get: row => row.uploaded,
      name: i18n.t('sim:activity.uploaded'),
    },
    {
      id: 'total',
      type: 'dataUsage',
      category,
      _get: row => row.total,
      name: i18n.t('sim:activity.total'),
    },
  ],
  defaultGroupBy: {
    id: 'country',
    aggregateByColumn: ['downloaded', 'uploaded', 'total'],
  },
});
