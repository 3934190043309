import { IColumnAggregationProps } from './IColumnAggregationProps';
import { TypedAggregator } from './TypedAggregator';

// Aggregate string column values into a list of sorted, unique, values, joined by commas
const aggregateUniqueStrings: TypedAggregator<string, string> = values => {
  const finalValues: string[] = [];

  values.forEach(val => {
    if (val && !finalValues.includes(val)) {
      finalValues.push(val);
    }
  });

  const sortedValues = [...finalValues].sort();

  return sortedValues.join(', ');
};

export const uniqueStringAggregationProps: IColumnAggregationProps = {
  aggregate: aggregateUniqueStrings,
};
