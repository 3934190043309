import { IntConnectivityConfigurationDto } from 'generated';
import { GetEndpoint, PutEndpoint } from 'shared/interfaces/api';
import { connecitivityConfigDemoData } from './demoData/connectivityConfigDemoData';

type ConnectivityConfigurationAPI = {
  getConnectivityConfigs: GetEndpoint<
    string,
    IntConnectivityConfigurationDto[]
  >;
  updateConnectivityConfigs: PutEndpoint<
    { intConfigurationType: number; customerId: string },
    IntConnectivityConfigurationDto[],
    IntConnectivityConfigurationDto[]
  >;
  validateConfigurationIdentifier: GetEndpoint<string, boolean>;
};

const baseUrl = '/api/connectivityconfiguration';

export const connectivityConfigurationAPI: ConnectivityConfigurationAPI = {
  getConnectivityConfigs: {
    url: customerId => `${baseUrl}/${customerId}`,
    mockData: customerId =>
      connecitivityConfigDemoData.getConnectivityConfigs(customerId),
  },
  updateConnectivityConfigs: {
    url: ({ intConfigurationType, customerId }) =>
      `${baseUrl}/${intConfigurationType}/${customerId}`,
    mockData: connecitivityConfigDemoData.updateConnectivityConfigs,
  },
  validateConfigurationIdentifier: {
    url: configurationIdentifier =>
      `${baseUrl}/configurationidentifierexists/${configurationIdentifier}`,
    mockData: configurationIdentifier => ({
      status: configurationIdentifier !== 'unavailable' ? 200 : 208,
    }),
  },
};
