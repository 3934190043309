import { useTranslation } from 'react-i18next/';

const useValueRangeFormat = () => {
  const { t } = useTranslation('policy');

  return (
    minValue: number | null | undefined,
    maxValue: number | null | undefined
  ) => {
    if (!minValue && !maxValue && minValue !== 0 && maxValue !== 0) {
      return t('no_values');
    } else if (!minValue && minValue !== 0) {
      return `< ${maxValue}`;
    } else if (!maxValue && maxValue !== 0) {
      return `> ${minValue}`;
    } else {
      return `${minValue} - ${maxValue}`;
    }
  };
};
export default useValueRangeFormat;
