import { DashboardComponentType, IntDashboardComponentDto } from 'generated';
import { action, computed, makeObservable, observable } from 'mobx';
import { Layout } from 'react-grid-layout';
import { rnd } from 'utils';
import { IWidgetSettings } from 'views/Dashboard/ComponentTypes/IWidgetSettings';
import { IBaseComponentSettings } from 'views/Dashboard/dashboardTypes';
import { fixOldComponentSettings } from 'views/Dashboard/fixOldComponentSettings';
import { fixOldDataSourceSettings } from 'views/Dashboard/fixOldDataSourceSettings';
import { flattenDashboardComponentSettings } from 'views/Dashboard/parseComponentSettings';
import { Dashboard } from './dashboard';

export class DashboardComponent<
  TSettings extends IBaseComponentSettings = IWidgetSettings,
> {
  dashboard: Dashboard;
  componentId: string;
  componentType: DashboardComponentType;
  @observable settings: TSettings;

  constructor(
    dashboard: Dashboard,
    opts: {
      componentId?: string;
      type: DashboardComponentType;
      settings: TSettings;
    }
  ) {
    makeObservable(this);

    const { componentId, type, settings } = opts;

    this.dashboard = dashboard;
    this.componentId = componentId || rnd.guid();
    this.componentType = type;

    fixOldComponentSettings(type, settings);
    if (settings.dataSource) {
      fixOldDataSourceSettings(settings.dataSource);
    }

    this.settings = settings; // Todo: merge with default settings or otherwise ensure all required settings are set (even if developers add new ones)
  }

  @computed get gridPosition(): Layout | null {
    if (!this.settings.position) {
      return null;
    }

    return {
      ...this.settings.position,
      minH: 2, // Todo: calculate these from the component type, maybe even its settings
      maxH: 20,
      minW: 3,
      isDraggable: this.dashboard.isEditable,
      i: this.componentId,
    };
  }

  @action.bound remove() {
    this.dashboard.removeComponent(this);
  }

  @action.bound setSettings(settings: TSettings) {
    this.settings = settings;
  }

  // Very basic validation, to be improved
  @observable settingsErrors: Record<string, string> = {};
  @action.bound setSettingsError(name: string, error?: string) {
    this.settingsErrors[name] = error || '';
  }

  // Basic settings validation, to be improved
  @computed get settingsAreValid() {
    return (
      Object.values(this.settingsErrors).find(error => !!error) === undefined
    );
  }

  toIntDto(): IntDashboardComponentDto {
    return {
      componentType: this.componentType,
      dashboardComponentId: this.componentId,
      settings: flattenDashboardComponentSettings(this.settings),
      components: null,
      parentDashboardComponent: null,
    };
  }
}
