import { IntDashboardDataStatusDto, IntPolicyDto } from 'generated';
import { useTranslation } from 'react-i18next';
import { useDataSource, useDataSourceData } from 'views/Dashboard/DataSources';
import { IDataProperty } from 'views/Dashboard/DataSources/dataPropTypes';
import { getDataPropId } from 'views/Dashboard/DataSources/getDataProp';
import {
  makePopupGetter,
  PopupGetter,
} from '../AzureMapsComponent/getPopupContent';
import { IImageSettings } from './imageConfig';
import { IMapPosition } from './ImageWidget';
import { useImageWidgetFeatures } from './useImageWidgetFeatures';

export interface IImageWidgetViewModel {
  displayName: IDataProperty;
  pins: {
    pinId: string;
    assetId: string;
    dataProp: IDataProperty; // Remove at some point
    position: IMapPosition;
  }[];
  getPopup: PopupGetter;

  pinValues: { status: IntDashboardDataStatusDto; prop: IDataProperty }[];
}

export function useImageWidgetData(
  settings: IImageSettings,
  options: {
    showFeaturesWithoutData?: boolean;
    selectedPinId?: string;
    policy?: IntPolicyDto;
  }
) {
  const dataSource = useDataSource(settings.dataSource);
  const { t } = useTranslation('dashboard');

  const primaryDataPropId = getDataPropId(settings.markerProp || '');

  const viewModel = dataSource.getDataProps<IImageWidgetViewModel>(getProp => ({
    displayName: getProp('AssetDetailsAssetName'),
    pins: settings.dataPoints.map(point => ({
      pinId: point.id,
      assetId: point.assetId,
      position: point.position,
      dataProp: getProp(point.dataProp),
    })),
    getPopup: makePopupGetter(settings.popup, getProp, t),
    pinValues:
      options.policy?.statuses
        ?.filter(status =>
          primaryDataPropId
            ? status.servicePropertyId === primaryDataPropId // If specified, only include this one
            : true
        )
        .map(status => ({
          status,
          prop: getProp(status.servicePropertyId || ''),
        })) || [],
  }));

  const { data, error } = useDataSourceData(dataSource);

  const mapFeatures = useImageWidgetFeatures({
    viewModel,
    selectedPinId: options.selectedPinId,
    showFeaturesWithoutData: options.showFeaturesWithoutData,
    data,
  });

  return {
    dataSource,
    mapFeatures,
    error,
    getPopup: viewModel.getPopup,
  };
}
