import { observer } from 'mobx-react-lite';
import React from 'react';
import BreadcrumbList from './BreadcrumbList';
import { useNavigationBreadcrumbs } from './useNavigationBreadcrumbs';

const BreadcrumbsContainer: React.FC = () => {
  const breadcrumbs = useNavigationBreadcrumbs();

  return <BreadcrumbList breadcrumbs={breadcrumbs} />;
};

export default observer(BreadcrumbsContainer);
