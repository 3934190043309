import { IconButton, InputAdornment } from '@mui/material';
import AtlasIcon from 'components/AtlasIcon'; // MUI's own Visibility/VisibilityOff icons look better, switch back if usage of MUI-icons gets approved
import React from 'react';

interface IProps {
  visible: boolean;
  handleClickShowPassword: () => void;
  handleMouseDownPassword: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const PasswordVisibilityIcon: React.FC<IProps> = ({
  visible,
  handleClickShowPassword,
  handleMouseDownPassword,
}) => {
  return (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={handleClickShowPassword}
        onMouseDown={handleMouseDownPassword}
        size="large"
      >
        <AtlasIcon
          type={visible ? 'PasswordVisible' : 'PasswordInvisible'}
          style={{ fontSize: 24 }}
        />
      </IconButton>
    </InputAdornment>
  );
};

export default PasswordVisibilityIcon;
