import Highcharts from 'components/Highcharts';
import { TableCellRenderer } from 'react-table';
import { IDataProperty } from 'views/Dashboard/DataSources/dataPropTypes';

export function getChartCell(opts: {
  xValue: IDataProperty;
  yValue: IDataProperty;
  xMin?: number;
  xMax?: number;
}): TableCellRenderer {
  const { xMin, xMax, xValue, yValue } = opts;

  return cellInfo => {
    if (
      !cellInfo.value ||
      !Array.isArray(cellInfo.value) ||
      cellInfo.value.length === 0
    ) {
      return null;
    }

    const value = cellInfo.value as number[][];

    const cleanChartOptions: Highcharts.Options = {
      chart: {
        backgroundColor: undefined,
        borderWidth: 0,
        type: 'area',
        margin: 0,
        height: 18,
        style: {
          overflow: 'visible',
        },
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        labels: {
          enabled: false,
        },
        title: {
          text: null,
        },
        startOnTick: false,
        endOnTick: false,
        tickPositions: [],
        type: xValue.type === 'dateTime' ? 'datetime' : 'linear',
        min: xMin,
        max: xMax,
      },
      yAxis: {
        endOnTick: false,
        startOnTick: false,
        labels: {
          enabled: false,
        },
        title: {
          text: null,
        },
        tickPositions: [0],
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        hideDelay: 0,
        outside: true,
        shared: true,
      },
      plotOptions: {
        series: {
          animation: false,
          lineWidth: 1,
          shadow: false,
          states: {
            hover: {
              lineWidth: 1,
            },
          },
          marker: {
            radius: 1,
            states: {
              hover: {
                radius: 2,
              },
            },
          },
        },
        column: {
          negativeColor: '#910000',
          borderColor: 'silver',
        },
        area: {
          fillOpacity: 0.25,
        },
      },
      series: [
        {
          type: 'area',
          name: yValue.name,

          tooltip: {
            valueSuffix: yValue.unit,
            valueDecimals: 1,
          },

          data: value,
        },
      ],
    };

    return <Highcharts options={cleanChartOptions} />;
  };
}
