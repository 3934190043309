import { IconButton, IconButtonProps } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { useStyles } from './AtlasIconButton.styles';

const AtlasIconButton: React.FC<IconButtonProps> = React.forwardRef(
  ({ className, children, ...props }, ref) => {
    const classes = useStyles();

    return (
      <IconButton
        className={classNames(classes.root, className)}
        ref={ref}
        {...props}
        disableRipple
        size="large"
      >
        {children}
      </IconButton>
    );
  }
);

export default AtlasIconButton;
