import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => {
  return {
    root: {
      height: '100%',
      position: 'relative',
      ...(theme.settings.components.contentCard as {}),
    },
  };
});
