import {
  Add,
  AddSharp,
  Airplay,
  ArrowDownward,
  ArrowLeft,
  ArrowRight,
  ArrowUpward,
  BarChart,
  BatteryFull,
  Build,
  Business,
  ChevronLeft,
  ChevronRight,
  Clear,
  Close,
  Cloud,
  Code,
  ContentCopy,
  Description,
  Devices,
  DragHandle,
  Edit,
  Email,
  ErrorOutline,
  Extension,
  FiberManualRecord,
  Fullscreen,
  HelpOutline,
  HighlightOff,
  Home,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Laptop,
  List,
  More,
  MoreHoriz,
  NotificationsNoneRounded,
  People,
  Person,
  Search,
  Settings,
  ShoppingCart,
  SimCard,
  Smartphone,
  Sms,
  SportsBasketball,
  TabletMac,
  TableView,
  Warning,
  Widgets,
  ZoomIn,
  ZoomOut,
} from '@mui/icons-material';
import { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';
import { AtlasIconType } from 'shared/types/atlas-icon';
import { mapToSvgIcon } from 'utils';
import { ReactComponent as ApplicationSvg } from './svg/application.svg';
import { ReactComponent as AttachmentSvg } from './svg/attachment.svg';
import { ReactComponent as BatteryOutline } from './svg/batteri-outline.svg';
import { ReactComponent as BatteryAverage } from './svg/battery-average.svg';
import { ReactComponent as BatteryHigh } from './svg/battery-high.svg';
import { ReactComponent as BatteryLow } from './svg/battery-low.svg';
import { ReactComponent as Box } from './svg/box.svg';
import { ReactComponent as InputCalendarSvg } from './svg/calendar.svg';
import { ReactComponent as DownloadSimSvg } from './svg/download-sim.svg';
import { ReactComponent as ErrorSimSvg } from './svg/error-sim.svg';
import { ReactComponent as ExternalLinkSvg } from './svg/external-link-alt-solid.svg';
import { ReactComponent as FlagFinland } from './svg/finland.svg';
import { ReactComponent as GreyCircle } from './svg/grey-circle.svg';
import { ReactComponent as HistoricalSvg } from './svg/historical.svg';
import { ReactComponent as InactiveSimSvg } from './svg/inactive-sim.svg';
import { ReactComponent as Premises } from './svg/premises.svg';
import { ReactComponent as RestartSvg } from './svg/restart.svg';
import { ReactComponent as ActiveSimSvg } from './svg/sim.svg';
import { ReactComponent as StatusBadSvg } from './svg/Status-bad.svg';
import { ReactComponent as StatusErrorSvg } from './svg/status-error.svg';
import { ReactComponent as StatusGoodSvg } from './svg/Status-good.svg';
import { ReactComponent as StatusModerateSvg } from './svg/Status-moderate.svg';
import { ReactComponent as StatusOkSvg } from './svg/status-ok.svg';
import { ReactComponent as StatusPauseSvg } from './svg/status-pause.svg';
import { ReactComponent as StatusPendingSvg } from './svg/status-pending.svg';
import { ReactComponent as StatusUnknownSvg } from './svg/status-unknown.svg';
import { ReactComponent as StatusWarningSvg } from './svg/status-warning.svg';
import { ReactComponent as FlagSweden } from './svg/sweden.svg';
import { ReactComponent as SyncSimSvg } from './svg/sync-sim.svg';
import { ReactComponent as RunSvg } from './svg/TC_Run.svg';
import { ReactComponent as SmsHubSvg } from './svg/TC_Sms_Hub.svg';
import { ReactComponent as VpnServer } from './svg/TC_Vpn-server.svg';
import { ReactComponent as TemperatureSvg } from './svg/temperature.svg';
import { ReactComponent as Time } from './svg/time.svg';
import { ReactComponent as TransmittingSvg } from './svg/transmitting.svg';
import { ReactComponent as FlagUK } from './svg/united-kingdom.svg';
import TeliaFlagIcons from './telia-flag-icons';
import {
  applicationViewbox,
  externalLinkViewbox,
  flagViewbox,
  muiViewbox,
  simViewbox,
  statusErrorViewbox,
  statusOkViewbox,
  statusPauseViewbox,
  statusPendingViewbox,
  statusUnknownViewbox,
  statusWarningViewbox,
} from './variables';

// replace the placeholder in the list below with chosen icon from MUI when needed
const Placeholder = Warning;

const MaterialIcons: Record<
  AtlasIconType,
  React.ComponentType<SvgIconProps>
> = {
  ActiveSim: mapToSvgIcon(ActiveSimSvg, simViewbox),
  DownloadSim: mapToSvgIcon(DownloadSimSvg, simViewbox),
  InactiveSim: mapToSvgIcon(InactiveSimSvg, simViewbox),
  SyncSim: mapToSvgIcon(SyncSimSvg, simViewbox),
  ErrorSim: mapToSvgIcon(ErrorSimSvg, simViewbox),
  ErrorCircle: ErrorOutline,
  Home,
  EndUser: Person,
  Extension,
  Add,
  Added: Add,
  Airplay,
  AiRobot: Placeholder,
  Alert: Warning,
  Application: mapToSvgIcon(ApplicationSvg, applicationViewbox),
  ArrowDown: ArrowDownward,
  ArrowLeft,
  ArrowRight,
  ArrowUp: ArrowUpward,
  Attachment: mapToSvgIcon(AttachmentSvg, muiViewbox),
  Basketball: SportsBasketball,
  Battery: BatteryFull,
  BatteryOutline: mapToSvgIcon(BatteryOutline),
  BatteryAverage: mapToSvgIcon(BatteryAverage),
  BatteryHigh: mapToSvgIcon(BatteryHigh),
  BatteryLow: mapToSvgIcon(BatteryLow),
  BestInTest: Placeholder,
  BloodPressure: Placeholder,
  Broadband: Placeholder,
  Bus: Placeholder,
  Box: mapToSvgIcon(Box, '0 0 24 24'),
  Shopping: ShoppingCart,
  BusinessContinuity: Placeholder,
  BusinessIntelligence: Placeholder,
  Calendar: Placeholder,
  Camera: Placeholder,
  Care: Placeholder,
  Cast: Placeholder,
  Chat: Placeholder,
  Checkmark: Placeholder,
  Chevron: Placeholder,
  ChevronDown: KeyboardArrowDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp: KeyboardArrowUp,
  Clear,
  Copy: ContentCopy,
  Close,
  CloseRound: HighlightOff,
  Cloud,
  Coffee: Placeholder,
  ConnectedBuilding: Business,
  Conversation: Placeholder,
  CustomerDialogue: Placeholder,
  DefaultImage: Placeholder,
  Delivery: Placeholder,
  Desktop: Placeholder,
  Devices,
  VpnServer: mapToSvgIcon(VpnServer),
  Doc: Placeholder,
  Download: Placeholder,
  DslHub: Placeholder,
  DragHandle,
  Email,
  Entertainment: Placeholder,
  EntertainmentFilm: Placeholder,
  Environment: Placeholder,
  EsimSimcard: SimCard,
  ExternalLink: mapToSvgIcon(ExternalLinkSvg, externalLinkViewbox),
  Favourites: Placeholder,
  Fiber: Placeholder,
  Filter: Placeholder,
  FlagFinland: mapToSvgIcon(FlagFinland, flagViewbox),
  FlagSweden: mapToSvgIcon(FlagSweden, flagViewbox),
  FlagUK: mapToSvgIcon(FlagUK, flagViewbox),
  Folder: Placeholder,
  Games: Placeholder,
  Gift: Placeholder,
  Global: Placeholder,
  Graph: Placeholder,
  GettingStarted: Placeholder,
  GuidingLight: Placeholder,
  HelpOutline,
  Historical: mapToSvgIcon(HistoricalSvg, muiViewbox),
  HomeCare: Placeholder,
  Hub: Placeholder,
  Info: Placeholder,
  InputCalendar: mapToSvgIcon(InputCalendarSvg, '0 0 14 14'),
  Internet: Placeholder,
  Invoice: Description,
  IoT: Placeholder,
  Ipad: TabletMac,
  Jobsearch: Placeholder,
  Laptop,
  Letter: Placeholder,
  Like: Placeholder,
  Link: Placeholder,
  Listen: Placeholder,
  Login: Placeholder,
  Logout: Placeholder,
  Love: Placeholder,
  Management: Build,
  Maps: Placeholder,
  MapPin: Placeholder,
  Megaphone: Placeholder,
  Menu: Placeholder,
  Mobile: Smartphone,
  Money: Placeholder,
  Moisture: Placeholder,
  More,
  MoreHoriz,
  Music: Placeholder,
  Network: Placeholder,
  NetworkLost: Placeholder,
  News: Placeholder,
  NotTransmitting: Placeholder,
  Number: Placeholder,
  Notifications: NotificationsNoneRounded,
  Offering: Placeholder,
  OpenSecurity: Placeholder,
  Optimization: Placeholder,
  OrgChart: Placeholder,
  ParentalGuide: Placeholder,
  PasswordInvisible: Placeholder,
  PasswordVisible: Placeholder,
  Payment: Placeholder,
  People,
  PeopleHub: Placeholder,
  Pencil: Edit,
  Phone: Placeholder,
  PhoneRinging: Placeholder,
  PieChart: Placeholder,
  Play: Placeholder,
  Plus: AddSharp,
  PlusMinus: Placeholder,
  Pod: Placeholder,
  PressButton: Placeholder,
  PurpurAdd: Placeholder,
  PurpurAlert: Placeholder,
  PurpurCheck: Placeholder,
  PurpurClose: Placeholder,
  PurpurInfo: Placeholder,
  PurpurQuestion: Placeholder,
  Recycle: Placeholder,
  RecyclePhone: Placeholder,
  RefillCard: Placeholder,
  RemoteControl: Placeholder,
  RemoveFilter: Placeholder,
  Repair: Build,
  Report: Placeholder,
  Restart: mapToSvgIcon(RestartSvg, muiViewbox),
  Run: mapToSvgIcon(RunSvg, muiViewbox),
  Router: Placeholder,
  Savings: Placeholder,
  Search,
  Secure: Placeholder,
  Secured: Placeholder,
  Security: Placeholder,
  SenseCar: Placeholder,
  Server: Placeholder,
  Settings,
  Share: Placeholder,
  Shuffle: Placeholder,
  Silent: Placeholder,
  Simcard: SimCard,
  SkipBack10sec: Placeholder,
  SkipBack30sec: Placeholder,
  SkipForward10sec: Placeholder,
  SkipForward30sec: Placeholder,
  SlowWifi: Placeholder,
  SmartWifi: Placeholder,
  Smiley: Placeholder,
  SmileySad: Placeholder,
  Sms,
  SmsHub: mapToSvgIcon(SmsHubSvg, muiViewbox),
  Snowflake: Placeholder,
  Statistics: BarChart,
  Status: FiberManualRecord,
  StatusError: mapToSvgIcon(StatusErrorSvg, statusErrorViewbox),
  StatusOk: mapToSvgIcon(StatusOkSvg, statusOkViewbox),
  StatusPause: mapToSvgIcon(StatusPauseSvg, statusPauseViewbox),
  StatusPending: mapToSvgIcon(StatusPendingSvg, statusPendingViewbox),
  StatusUnknown: mapToSvgIcon(StatusUnknownSvg, statusUnknownViewbox),
  StatusWarning: mapToSvgIcon(StatusWarningSvg, statusWarningViewbox),
  Summary: Placeholder,
  Support: Placeholder,
  Sustainability: Placeholder,
  SubLevel: Placeholder,
  Switch: Placeholder,
  Sync: Placeholder,
  TableView: TableView,
  Tachometer: Widgets,
  Time: mapToSvgIcon(Time),
  Trailer: Placeholder,
  TrashEmpty: Placeholder,
  Travel: Placeholder,
  TravelAbroad: Placeholder,
  Transmitting: mapToSvgIcon(TransmittingSvg),
  Temperature: mapToSvgIcon(TemperatureSvg),
  Tv: Placeholder,
  TvNext: Placeholder,
  TvPause: Placeholder,
  TvPlay: Placeholder,
  TvPrevious: Placeholder,
  Unlink: Placeholder,
  Usb: Placeholder,
  UserAdmin: Placeholder,
  Video: Placeholder,
  VideoConference: Placeholder,
  Volume: Placeholder,
  Wallet: Placeholder,
  Wallplug: Placeholder,
  WifiMobile: Placeholder,
  Wireless: Placeholder,
  Widgets,
  World: Placeholder,
  List,
  Qr: Code,
  Fullscreen,
  ZoomIn,
  ZoomOut,
  StatusGood: mapToSvgIcon(StatusGoodSvg, '0 0 24 24'),
  StatusModerate: mapToSvgIcon(StatusModerateSvg, '0 0 24 24'),
  StatusBad: mapToSvgIcon(StatusBadSvg, '0 0 24 24'),
  Premises: mapToSvgIcon(Premises, '0 0 25 25'),
  StatusNeutral: mapToSvgIcon(GreyCircle, '0 0 22 22'),

  ...TeliaFlagIcons,
};

export default MaterialIcons;
