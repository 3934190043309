import { Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IDashboardComponent } from 'views/Dashboard/dashboardTypes';
import { Control } from 'views/Dashboard/SettingControls';
import { IFiltersSettings } from '../filtersConfig';
import { useGetFilterDisplayName } from '../getFilterDisplayName';
import { useAssetFilterOptions } from './useAssetFilterOptions';

const maxNumberOfFilters = 10;

interface IAssetFilterProps {
  settings: IFiltersSettings;
}

const AssetFilterList: React.FC<IAssetFilterProps> = ({ settings }) => {
  const assetFilterOptions = useAssetFilterOptions(settings.filters);
  const getFilterDisplayName = useGetFilterDisplayName();

  return (
    <Control.AddItemToList
      addableItems={assetFilterOptions}
      addToArray={settings.filters}
      disabled={settings.filters.length >= maxNumberOfFilters}
    >
      {item => getFilterDisplayName(item)}
    </Control.AddItemToList>
  );
};

const GeneralSettingsStep: IDashboardComponent<IFiltersSettings> = ({
  component,
}) => {
  const { t } = useTranslation('dashboard');
  const { settings } = component;
  const getFilterDisplayName = useGetFilterDisplayName();

  return (
    <Grid container spacing={4}>
      <Grid item sm={6}>
        <Control.Group label={t('filter.available')}>
          <AssetFilterList settings={settings} />
        </Control.Group>
      </Grid>

      <Grid item sm={6}>
        <Control.Group
          label={t('filter.active', {
            count: settings.filters.length,
            max: maxNumberOfFilters,
          })}
        >
          <Control.SortableList items={settings.filters}>
            {item => getFilterDisplayName(item)}
          </Control.SortableList>
        </Control.Group>
      </Grid>
    </Grid>
  );
};

export default observer(GeneralSettingsStep);
