import { format, getMonth } from 'date-fns';
import Highcharts, { SeriesOptionsType } from 'highcharts';
import i18n from 'i18n';
import { isNil } from 'lodash';
import { IGroupedData } from 'services/service.service';
import { rnd } from 'utils';
import { IDataProperty } from 'views/Dashboard/DataSources/dataPropTypes';
import { IWidgetData } from 'views/Dashboard/DataSources/dataSourceTypes';
import { dataProp } from '../../DataSources';
import { getFormattedColumnHeaders } from '../ColumnChartComponent/useColumnChartOptions';
import { IGroupedBarChartSettings } from './groupedBarChartConfig';
import { IGroupedBarChartViewModel } from './GroupedBarChartContainer';

const getSeriesDataFromDataPoints = (
  group: IGroupedData,
  xValue: IDataProperty,
  yValue: IDataProperty
) => {
  const sortedDataPoints = [...group.dataPoints].sort(
    (a, b) =>
      new Date(a.register_date).valueOf() - new Date(b.register_date).valueOf()
  );

  return sortedDataPoints
    .map(datapoint => {
      const x =
        xValue.type === 'dateTime'
          ? getMonth(Date.parse(datapoint.register_date))
          : getMonth(datapoint.register_date);

      const y = dataProp.getNumber(yValue, datapoint);

      return [x, y];
    })
    .filter(point => point[0] !== null && point[1] !== null);
};

const sortDataByYear = (groups: IGroupedData<any>[]) =>
  groups.flatMap(group =>
    group.dataPoints.reduce((acc, dataPoint) => {
      const existingDataPoint = acc.find(
        (item: { dataPoints: { register_date: string }[] }) =>
          new Date(item.dataPoints[0].register_date).getFullYear() ===
          new Date(dataPoint.register_date).getFullYear()
      );

      if (existingDataPoint) {
        existingDataPoint.dataPoints.push(dataPoint);
      } else {
        acc.push({
          id: rnd.guid(),
          owner: {
            AssetDetailsAssetName: new Date(
              dataPoint.register_date
            ).getFullYear(),
            AssetName: group.owner.AssetDetailsAssetName,
          },
          dataPoints: [dataPoint],
        });
      }
      return acc;
    }, [])
  );

const percentageDiff = (values: number[]) => {
  const comparisonValue = values.shift() ?? 0; // else should never happen, only to please the shift()
  const difference: number[] = [comparisonValue];
  const multiplier = Math.pow(10, 1);

  for (const value of values) {
    let rawPercent = ((value - comparisonValue) / comparisonValue) * 100;

    const percentageDifference =
      Math.round(rawPercent * multiplier) / multiplier;

    difference.push(percentageDifference);
  }
  return difference;
};

export function useGroupedBarChartOptions(opts: {
  viewModel: IGroupedBarChartViewModel;
  settings: IGroupedBarChartSettings;
  data: IWidgetData;
}): Highcharts.Options {
  const {
    data,
    settings: { yAxis },
    viewModel: { name, yValue, xValue },
  } = opts;

  const fileName = `${opts.settings.title}-${format(
    new Date(),
    'yyyy-MM-dd HH:mm'
  )}`;

  const series: SeriesOptionsType[] = [];

  if (data.type === 'groupedData') {
    const dataByYear = sortDataByYear(data.groups).sort(
      (a, b) => a.owner.AssetDetailsAssetName - b.owner.AssetDetailsAssetName
    );

    dataByYear.forEach(group => {
      series.push({
        id: group.id,
        type: 'column',

        name: dataProp.getString(name, group.owner) || group.id,
        tooltip: {
          headerFormat: group.owner.AssetName,
          valueSuffix: yValue.unit,
          valueDecimals: yValue.decimals ?? 2,
        },

        data: getSeriesDataFromDataPoints(group, xValue, yValue),
      });
    });
  }

  return {
    title: {
      text: undefined,
    },
    exporting: {
      filename: fileName,
      csv: {
        columnHeaderFormatter: function (
          item: { name: string },
          key: { y: string }
        ) {
          if (!key) {
            return xValue.name;
          }
          return getFormattedColumnHeaders(yValue, item);
        },
      },
    },
    series: series.filter(s => {
      if (s.type === 'column') {
        return s.data?.length;
      }
      return true;
    }),
    time: {
      timezoneOffset: new Date().getTimezoneOffset(),
      useUTC: false,
    },
    tooltip: {
      style: {
        fontSize: '1rem',
      },
      shared: true,
      formatter: function () {
        const values = this.points?.map(p => {
          return p.y;
        });
        const suffix = series[0].tooltip?.valueSuffix;
        const decimal = series[0].tooltip?.valueDecimals;
        const month = i18n.t(`dashboard:month.${this.x}`);
        let tooltip = `<b>${series[0].tooltip?.headerFormat}, ${month}</b><br/><br/>`;
        if (!isNil(values) && values.length > 1) {
          const percentage = percentageDiff(values);
          this.points?.forEach((p, i) => {
            const showPercentage = i !== 0 ? `<b>${percentage[i]}%</b>` : '';
            tooltip += `<span style="color:${p.color}">●</span> ${p.series.name} \u00A0 ${p.y.toFixed(decimal)} ${suffix} \u00A0 ${showPercentage}<br/>`;
          });
        } else {
          this.points?.forEach(p => {
            tooltip += `<span style="color:${p.color}">●</span> ${p.series.name} \u00A0 ${p.y.toFixed(decimal)} ${suffix}`;
          });
        }
        return tooltip;
      },
    },
    xAxis: {
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Okt',
        'Nov',
        'Dec',
      ],
      crosshair: true,
      startOnTick: false,
      endOnTick: false,
    },
    yAxis: [
      {
        title: {
          text: yAxis.label ?? yValue.name,
        },
        min: typeof yAxis.min === 'number' ? yAxis.min : undefined,
        max: typeof yAxis.max === 'number' ? yAxis.max : undefined,
        labels: {
          format: yValue.unit ? `{value}${yValue.unit}` : undefined,
        },
      },
    ],
  };
}
