import { Chart } from 'highcharts';
import React from 'react';
import { useDownloadChartExcel } from 'views/Dashboard/Widget/useDownloadLineChartData';
import DownloadDataMenu from './DownloadDataMenu';

interface IProps {
  chartRef: React.MutableRefObject<Chart | undefined>;
  id: string;
  onBeforeDownload?: () => void;
}

const DownloadHighchartDataMenu: React.FC<IProps> = ({
  chartRef,
  id,
  onBeforeDownload,
}) => {
  const { downloadXLSX, downloadCSV } = useDownloadChartExcel(chartRef);

  const downloadData = (format?: 'CSV' | 'XLSX') => {
    onBeforeDownload?.();
    window.setTimeout(() => {
      if (format === 'CSV') {
        downloadCSV();
      } else {
        downloadXLSX();
      }
    }, 1);
  };

  return (
    <>
      <DownloadDataMenu
        id={id}
        downloadCSV={() => downloadData('CSV')}
        downloadXLSX={() => downloadData('XLSX')}
      />
    </>
  );
};

export default DownloadHighchartDataMenu;
