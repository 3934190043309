import { Role } from 'components/Auth/Role';
import { snackbar } from 'components/Snackbar';
import { IntSubPortalTableDto, SubPortalType } from 'generated';
import { useEffect, useState } from 'react';
import { subportalAPI } from 'services/subportal.service';
import { useGet } from 'shared/api/hooks';
import { useStores } from 'store';

export function useSubPortals(enable = true) {
  const { authStore } = useStores();

  const canViewSubportals = authStore.hasRole(Role.RoleNameViewDealer);

  const [subPortals, setSubPortals] = useState<IntSubPortalTableDto[]>();
  const [getSubportals, isLoadingSubportals] = useGet(subportalAPI.getAll);

  useEffect(() => {
    const load = async () => {
      const resp = await getSubportals({
        page: 0,
        pageSize: 9999,
        orderBy: '',
        orderDesc: false,
        search: '',
        filters: '',
      });

      if (resp.status === 200 || resp.status === 204) {
        setSubPortals(
          (resp.data?.rows ?? []).filter(
            subPortal => subPortal.type === SubPortalType.Atlas
          )
        );
      } else {
        snackbar('Error loading subportals', { variant: 'error' });
      }
    };

    if (canViewSubportals && enable && !subPortals) {
      load();
    }
  }, [getSubportals, canViewSubportals, enable, subPortals]);

  return { subPortals, isLoadingSubportals };
}
