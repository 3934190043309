import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    sortableItemContainer: {
      cursor: 'move',
      display: 'flex',
      '&.dragging': {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[10],
        zIndex: 1301,
      },
    },
    icon: {
      width: '25px',
      height: '60px',
      color: theme.palette.grey[500],
    },
  })
);
