import { addDays, format, startOfWeek } from 'date-fns';
import { enUS } from 'date-fns/locale';
import Highcharts from 'highcharts';
import HCReact from 'highcharts-react-official';
import addHighchartsMoreModule from 'highcharts/highcharts-more';
import ExportData from 'highcharts/modules/export-data';
import Exporting from 'highcharts/modules/exporting';
import addMapModule from 'highcharts/modules/map';
import markerClusters from 'highcharts/modules/marker-clusters';
import addGaugeModule from 'highcharts/modules/solid-gauge';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { User } from 'store/domains/user';
import { fontSettings } from 'styles/themes/fontSettings';
import { IUiConfiguration } from 'styles/themes/IUiConfiguration';

addMapModule(Highcharts);
addHighchartsMoreModule(Highcharts);
addGaugeModule(Highcharts);
Exporting(Highcharts);
ExportData(Highcharts);
markerClusters(Highcharts);

// Move to theme if/when needed
export const highchartsColors: string[] = [
  '#d35400',
  '#2980b9',
  '#2ecc71',
  '#f1c40f',
  '#2c3e50',
  '#7f8c8d',
];

// This should be improved.
export function applyHighchartsTheme(uiConfig: IUiConfiguration) {
  const font = fontSettings[uiConfig.typography.headerFont].fontStack;

  Highcharts.setOptions({
    colors: highchartsColors,
    chart: {
      style: {
        fontFamily: fontSettings.TeliaSansReg.fontStack,
        color: '#666666',
      },
      backgroundColor: 'transparent',
    },
    mapNavigation: {
      buttonOptions: {
        align: 'left',
        verticalAlign: 'bottom',
      },
    },

    title: {
      align: 'left',
      style: {
        fontFamily: fontSettings.TeliaSansBold.fontStack,
        fontWeight: 'bold',
      },
    },
    exporting: {
      enabled: false,
    },
    subtitle: {
      align: 'left',
      style: {
        fontFamily: font,
      },
    },
    legend: {
      align: 'center',
      verticalAlign: 'bottom',
      itemStyle: {
        color: '#636363',
      },
      itemHoverStyle: {
        color: '#232323',
      },
      itemHiddenStyle: {
        color: '#c3c3c3',
      },
    },
    xAxis: {
      gridLineWidth: 0,
      gridLineColor: '#FF0000',
      lineColor: '#FF0000',
      minorGridLineColor: '#FF0000',
      tickColor: '#F3F3F3',
      tickWidth: 1,
      lineWidth: 0,
    },
    yAxis: {
      gridLineColor: '#e8e8e8',
      lineColor: '#FF0000',
      minorGridLineColor: '#F3F3F3',
      tickColor: '#F3F3F3',
      tickWidth: 0,
    },
    plotOptions: {
      line: {
        marker: {
          enabled: false,
        },
      },
      spline: {
        marker: {
          enabled: false,
        },
      },
      area: {
        marker: {
          enabled: false,
        },
      },
      areaspline: {
        marker: {
          enabled: false,
        },
      },
      arearange: {
        marker: {
          enabled: false,
        },
      },
      bubble: {
        maxSize: '10%',
      },
      column: {
        dataLabels: {
          style: {
            fontFamily: font,
            textOutline: undefined,
            color: '#FFF',
            fontWeight: 'normal',
            fontSize: '14px',
          },
        },
      },
      pie: {
        dataLabels: {
          style: {
            textOutline: undefined,
            fontFamily: font,
            fontSize: '0.8rem',
            fontWeight: 'normal',
            color: '#FFF',
          },
        },
      },
    },
    credits: {
      enabled: false,
    },
  });
}

export function applyHighchartLocalization(user: User) {
  const { locale } = user;

  const months: string[] = [];
  const shortMonths: string[] = [];

  for (let month = 0; month < 12; month++) {
    const date = new Date(0, month);
    months.push(format(date, 'MMMM', { locale }));
    shortMonths.push(format(date, 'MMM', { locale }));
  }

  const weekdays: string[] = [];
  const shortWeekdays: string[] = [];
  let date = startOfWeek(new Date(), { locale: enUS }); // Highcharts wants weeks to start on sunday, ignore locale

  for (let i = 0; i < 7; i++) {
    weekdays.push(format(date, 'EEEE', { locale }));
    shortWeekdays.push(format(date, 'EE', { locale }));
    date = addDays(date, 1);
  }

  Highcharts.setOptions({
    lang: {
      months,
      shortMonths,

      weekdays,
      shortWeekdays,
    },
  });
}

type IProps = Omit<HCReact.Props, 'highcharts'>;

const ThemedHighcharts: React.FC<IProps> = props => (
  <HCReact highcharts={Highcharts} {...props} />
);

export default observer(ThemedHighcharts);
