import { FilterRender } from 'react-table';
import { TextFilter } from './TextFilter';

export function getTextFilter(
  textType: 'text' | 'number' = 'text'
): FilterRender {
  return ({ filter, onChange }) => (
    <TextFilter filter={filter} onChange={onChange} textType={textType} />
  );
}
