import { Box } from '@mui/material';
import AtlasIcon from 'components/AtlasIcon';
import AtlasTooltip from 'components/AtlasTooltip';
import i18n from 'i18next';
import { CellInfo, Column, FilterRender } from 'react-table';
import { textOverflowEllipsis } from '../Columns.styles';
import ColArrayValue from '../Helpers/ColArrayValue';
import { FilterOption } from '../Helpers/filterOption';
import { getMinMaxFilter } from '../Helpers/getMinMaxFilter';
import { getMultiSelectOptionsFilter } from '../Helpers/getMultiSelectOptionsFilter';
import { getOptionFilter } from '../Helpers/getOptionFilter';
import { getTextFilter } from '../Helpers/getTextFilter';
import { textRight } from '../Helpers/textRight';
import { uniqueStringAggregationProps } from '../PivotCells';
import { IColumnAggregationProps } from '../PivotCells/IColumnAggregationProps';

const renderCell = (
  row: CellInfo,
  ellipsis: boolean,
  cellRender?: (row: CellInfo) => React.ReactElement
): React.ReactElement => {
  if (!row.value) {
    return (
      <span className="text-muted">
        {i18n.t('not_applicable_abbreviation')}
      </span>
    );
  }

  if (cellRender) {
    return cellRender(row);
  } else {
    if (Array.isArray(row.value)) {
      return <ColArrayValue value={row.value} type="string" />;
    } else {
      return (
        <Box
          title={ellipsis ? row.value : undefined}
          style={ellipsis ? textOverflowEllipsis : undefined}
        >
          {row.value}
        </Box>
      );
    }
  }
};

type RowValueType = string | string[] | number | number[] | null | undefined;

export function text<T>(options: {
  id: string;
  header: string;
  accessor: (row: T) => RowValueType;
  width?: number;
  maxWidth?: number;
  resizable?: boolean;
  filter?: 'minMax' | 'options' | false;
  align?: 'left' | 'right';
  filterOptions?: FilterOption[];
  multiSelect?: boolean;
  filterable?: boolean;
  defaultShow?: boolean;
  defaultSortDesc?: boolean;
  sortable?: boolean;
  tooltip?: boolean;
  tooltipText?: string;
  ellipsis?: boolean;
  cellRender?: (row: CellInfo) => React.ReactElement;
  aggregate?: 'unique';
}): Column<T> {
  const {
    id,
    header,
    accessor,
    width,
    maxWidth,
    resizable,
    filter,
    filterOptions,
    multiSelect = false,
    align = 'left',
    defaultShow: show = true,
    defaultSortDesc = false,
    filterable,
    sortable,
    tooltip = false,
    tooltipText = '',
    ellipsis = false,
    cellRender,
    aggregate,
  } = options;

  let filterRender: FilterRender | undefined;

  if (filter === 'options' && multiSelect) {
    filterRender = getMultiSelectOptionsFilter(id);
  } else if (filter === 'options' && filterOptions) {
    filterRender = getOptionFilter(filterOptions);
  } else if (filter === 'minMax') {
    filterRender = getMinMaxFilter();
  } else if (filter !== false) {
    filterRender = getTextFilter();
  }

  const aggregationProps: IColumnAggregationProps =
    aggregate === 'unique' ? uniqueStringAggregationProps : {};
  return {
    id,
    Header: tooltip ? (
      <AtlasTooltip placement="top" title={tooltipText} arrow>
        <Box>
          {header}
          <AtlasIcon size={14} type="Info" />
        </Box>
      </AtlasTooltip>
    ) : (
      <Box textAlign={align}>{header}</Box>
    ),
    width,
    accessor,
    maxWidth,
    resizable,
    Filter: filterRender,
    show,
    filterable,
    className: align === 'left' ? undefined : textRight,
    sortable,
    defaultSortDesc,
    Cell: row => renderCell(row, ellipsis, cellRender),
    ...aggregationProps,
  };
}
