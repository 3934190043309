import { CssBaseline } from '@mui/material';
import { AuthProvider } from 'components/Auth';
import DatepickerLocaleProvider from 'components/DateFilterDropdown/DatepickerLocaleProvider';
import GlobalStyles from 'components/GlobalStyles';
import {
  SnackbarProvider,
  SnackbarUtilsConfigurator,
} from 'components/Snackbar';
import 'i18n/i18n';
import AuthLayout from 'layouts/AuthLayout';
import Layout from 'layouts/Layout';
import PublicLayout from 'layouts/PublicLayout';
import ScanLayout from 'layouts/ScanLayout';
import React, { Suspense } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { routePaths } from 'Routes';
import AtlasThemeProvider from 'styles/themes/AtlasThemeProvider';

const App: React.FC = () => {
  return (
    <Suspense fallback={null}>
      <DatepickerLocaleProvider>
        <BrowserRouter>
          <AtlasThemeProvider>
            <SnackbarProvider>
              <AuthProvider>
                <SnackbarUtilsConfigurator />
                <CssBaseline />
                <GlobalStyles />
                <Switch>
                  <Route
                    path="/atlas"
                    render={({ location }) => (
                      <Redirect
                        to={{
                          pathname: location.pathname.replace(
                            new RegExp(/(^\/(atlas))/i),
                            ''
                          ),
                          search: location.search,
                        }}
                      />
                    )}
                  />
                  <Route path={routePaths('auth')} exact>
                    <AuthLayout />
                  </Route>
                  <Route path={routePaths('scan')} exact>
                    <ScanLayout />
                  </Route>
                  <Route path={routePaths('public')} exact>
                    <PublicLayout />
                  </Route>
                  <Route path={routePaths()} exact>
                    <Layout />
                  </Route>
                  <Route>
                    <Layout errorPage />
                  </Route>
                </Switch>
              </AuthProvider>
            </SnackbarProvider>
          </AtlasThemeProvider>
        </BrowserRouter>
      </DatepickerLocaleProvider>
    </Suspense>
  );
};

export default App;
