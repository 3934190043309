import { snackbar } from 'components/Snackbar';
import { IntPolicyDto } from 'generated';
import i18n from 'i18n';
import { action, makeObservable, observable, runInAction } from 'mobx';
import {
  dashboardPolicyApi,
  IntCreateDashboardPolicyDto,
} from 'services/dashboardPolicy.service';
import { RootStore } from './rootStore';
import { StoreBase } from './storeBase';

export class PolicyStore extends StoreBase {
  @observable policies: IntPolicyDto[] | undefined;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this);
  }

  @action.bound async loadPolicies() {
    if (this.policies) {
      return;
    }

    const resp = await this.httpGet(dashboardPolicyApi.getAll, undefined);

    if (resp.status === 200 || resp.status === 204) {
      runInAction(() => (this.policies = resp.data || []));
    } else {
      snackbar(i18n.t('policy:error.load'), {
        variant: 'error',
      });
    }
  }

  @action.bound async createNewPolicy(
    newStatus: IntCreateDashboardPolicyDto
  ): Promise<IntPolicyDto | undefined> {
    const resp = await this.httpPost(dashboardPolicyApi.createPolicy, {
      params: undefined,
      data: newStatus,
    });

    if (resp.status === 201) {
      runInAction(() => {
        if (!this.policies) {
          this.policies = [];
        }
        resp.data && this.policies.push(resp.data);
      });
      return resp.data;
    } else {
      snackbar(i18n.t('policy:error.create'), { variant: 'error' });
      return undefined;
    }
  }

  @action.bound async updatePolicy(updatedPolicy: IntPolicyDto) {
    const resp = await this.httpPut(dashboardPolicyApi.updatePolicy, {
      params: updatedPolicy.policyId,
      data: updatedPolicy,
    });

    if (resp.status === 200) {
      runInAction(() => {
        this.policies = this.policies?.map(policy =>
          policy.policyId === updatedPolicy.policyId ? updatedPolicy : policy
        );
        snackbar(i18n.t('policy:updated'), { variant: 'success' });
      });
    } else {
      snackbar(i18n.t('policy:error.update'), { variant: 'error' });
    }
  }

  @action.bound async deletePolicy(policyToDelete: IntPolicyDto) {
    const resp = await this.httpDelete(dashboardPolicyApi.deletePolicy, {
      params: policyToDelete.policyId,
    });

    if (resp.status === 204) {
      runInAction(() => {
        this.policies = this.policies?.filter(
          policy => policy !== policyToDelete
        );
      });
    } else {
      snackbar(i18n.t('policy:error.delete'), { variant: 'error' });
    }
  }
}
