import { useTheme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { UnprotectedRoute } from 'components/Auth';
import RouteRenderer from 'components/Auth/RouteRenderer';
import BreadcrumbsContainer from 'components/Breadcrumbs/BreadcrumbsContainer';
import FullscreenLoadingOverlay from 'components/LoadingOverlay/FullscreenLoadingOverlay';
import SettingsDrawer from 'components/SettingsDrawer/SettingsDrawer';
import SideNav from 'components/SideNav';
import SiteTopBar from 'components/SiteTopBar/SiteTopBar';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useStores } from 'store/RootStoreContext';
import { triggerWindowResize } from 'utils';
import { ErrorPage } from 'views';
import { useRedirectToLogin } from '../../components/Auth/useRedirectToLogin';
import ContentContainer from './ContentContainer';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection:
        theme.settings.components.navMenu.position === 'right'
          ? 'row-reverse'
          : 'row',
    },
  })
);

interface IProps {
  errorPage?: boolean;
}

const Layout: React.FC<IProps> = ({ errorPage }) => {
  const { authStore, layoutStore, isRefreshing } = useStores();
  const classes = useStyles();
  const { pathname } = useLocation();

  useEffect(() => {
    const t = window.setTimeout(triggerWindowResize, 350);
    return () => window.clearTimeout(t);
  }, [layoutStore.sidenavOpen, layoutStore.settingsMenuOpen]);

  const theme = useTheme();
  const alignRight = theme.settings.components.navMenu.position === 'right';

  const sideNavNode = (
    <SideNav
      isOpen={layoutStore.sidenavOpen}
      toggle={layoutStore.toggleSidenavOpen}
    />
  );

  useEffect(() => {
    if (authStore.isLoggedIn) {
      layoutStore.loadLayoutData();
    }
  }, [layoutStore, authStore.isLoggedIn]);

  useEffect(() => {
    if (authStore.isLoggedIn && authStore.activeSubPortalDomainAlias) {
      authStore.redirectToSubPortal();
    }
  }, [authStore]);

  // This layout always requires logging in, so redirect immediately here to avoid mounting the content at all without prior auth
  const redirectToLogin = useRedirectToLogin();
  const errorPageRoute = <UnprotectedRoute component={ErrorPage} />;

  if (errorPage && pathname.substring(0, 6) === '/scan/') {
    return errorPageRoute;
  }

  if (!authStore.isLoggedIn) {
    return !errorPage ? redirectToLogin : errorPageRoute;
  }

  if (!layoutStore.hasFetchedNavigation) {
    return <FullscreenLoadingOverlay />;
  }

  return (
    <div className={classes.root}>
      <SiteTopBar navDrawerOpen={layoutStore.sidenavOpen} />

      {!alignRight && sideNavNode}

      <ContentContainer>
        <BreadcrumbsContainer />
        {errorPage ? errorPageRoute : !isRefreshing && <RouteRenderer />}
      </ContentContainer>

      <SettingsDrawer
        isOpen={layoutStore.settingsMenuOpen}
        toggle={layoutStore.toggleSettingsMenuOpen}
      />
      {alignRight && sideNavNode}
    </div>
  );
};

export default observer(Layout);
