import { Box, Grid, Typography } from '@mui/material';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import ComponentContainer from 'views/Dashboard/ComponentContainer';
import { IDashboardComponent } from 'views/Dashboard/dashboardTypes';
import { useDataSource } from 'views/Dashboard/DataSources';
import { getDataProp } from 'views/Dashboard/DataSources/getDataProp';
import { Control } from 'views/Dashboard/SettingControls';
import SelectDataPropButton from '../../TableComponent/SettingsSteps/SelectDataPropButton';
import {
  INumberGridSettings,
  MultipleNumberSizeOptions,
} from '../numberGridConfig';
import PolicyControl from './PolicyControl';

const GeneralSettingsStep: IDashboardComponent<INumberGridSettings> = ({
  component,
}) => {
  const { t } = useTranslation('dashboard');
  const { settings } = component;
  const dataSource = useDataSource(settings.dataSource);

  return (
    <>
      <Control.Group label={t('preview')}>
        <Box height={275} overflow="auto">
          <ComponentContainer component={component} />
        </Box>
      </Control.Group>

      <PolicyControl settings={settings} />

      <Control.Group label={t('number_grid.cards')}>
        <Control.SortableList items={settings.cards}>
          {card => (
            <Grid container spacing={2}>
              <Grid item sm={3}>
                <Typography variant="h6">
                  {getDataProp(dataSource, card.prop)?.name}
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Control.AutoComplete
                  object={card}
                  name="size"
                  options={MultipleNumberSizeOptions}
                  label={t('number_grid.size')}
                />
              </Grid>
            </Grid>
          )}
        </Control.SortableList>
        <SelectDataPropButton
          onChange={prop => {
            const dp = dataSource.dataProperties.find(
              property => property.id === prop
            );
            dp &&
              runInAction(() =>
                settings.cards.push({
                  prop,
                  size: 'medium',
                })
              );
          }}
          dataSource={dataSource}
          label={t('number_grid.action.add_card')}
          id="addProperty"
          showGroupProps={false}
          isOptionDisabled={dp =>
            !!settings.cards.find(card => card.prop === dp.id) ||
            dp.type !== 'number'
          }
        />
      </Control.Group>
    </>
  );
};

export default observer(GeneralSettingsStep);
