import { IntSmsCustomerDto, IntSmsUrlDto } from 'generated';
import {
  GetEndpoint,
  PatchEndpoint,
  PostEndpoint,
} from 'shared/interfaces/api';
import { smsDemoData } from './demoData/smsDemoData';

export interface ISetStateParams {
  customerId: string;
  state: boolean;
}
interface SMSApi {
  getDefaultProfile: GetEndpoint<string, IntSmsCustomerDto>;
  setDlrState: PostEndpoint<ISetStateParams>;
  setUrl: PatchEndpoint<string, IntSmsUrlDto>;
}

const baseUrl = '/api/sms';

export const smsService: SMSApi = {
  getDefaultProfile: {
    url: customerId => `${baseUrl}/customer/${customerId}`,
    mockData: smsDemoData.getDefaultProfile,
  },
  setDlrState: {
    url: ({ customerId, state }) =>
      `${baseUrl}/customer/${customerId}/dlrenabled?state=${state}`,
    mockData: () => ({ status: 200 }),
  },
  setUrl: {
    url: customerId => `${baseUrl}/customer/${customerId}/url`,
    mockData: () => ({ status: 200 }),
  },
};
