import { AssetState, IntAssetTableDto } from 'generated';
import i18n from 'i18n';
import { IDataPropCategory, IDataProperty } from '../dataPropTypes';

const category: IDataPropCategory = {
  displayName: i18n.t(`asset:property.category_header`),
};

const propName = (key: string) => i18n.t(`asset:property.${key}`);

export function getAssetTableDataProps<T>(
  getNullableAsset: (obj: T) => IntAssetTableDto | null,
  getAttributeValue: (obj: T, key: string) => any | null
): IDataProperty<T>[] {
  const getAsset = (o: T): IntAssetTableDto =>
    getNullableAsset(o) || ({} as IntAssetTableDto);

  return [
    {
      id: 'asset.displayName',
      _get: row => getAsset(row).displayName,
      name: propName('displayName'),
      type: 'string',
      category,
    },
    {
      id: 'asset.model',
      _get: row => getAsset(row).model,
      name: propName('model'),
      type: 'string',
      category,
      hideByDefault: true,
    },
    {
      id: 'asset.type',
      _get: row => getAsset(row).type,
      name: propName('type'),
      type: 'string',
      category,
      hideByDefault: true,
    },
    {
      id: 'asset.lastConnected',
      _get: row => getAsset(row).lastConnected || null,
      name: propName('lastConnected'),
      type: 'dateTime',
      category,
      hideByDefault: true,
    },
    {
      id: 'asset.installationDate',
      _get: row => getAsset(row).installationDate || null,
      name: propName('installationDate'),
      type: 'dateTime',
      category,
      hideByDefault: true,
    },
    {
      id: 'asset.address',
      _get: row => getAsset(row).address,
      name: propName('address'),
      type: 'string',
      category,
      hideByDefault: true,
    },
    {
      id: 'asset.serviceMode',
      _get: row => getAsset(row).serviceMode || null,
      name: propName('serviceMode'),
      type: 'boolean',
      category,
      hideByDefault: true,
    },
    {
      id: 'asset.referenceLabel',
      _get: row => getAsset(row).referenceLabel,
      name: propName('referenceLabel'),
      type: 'string',
      category,
      hideByDefault: true,
    },
    {
      id: 'asset.state',
      _get: row => AssetState[getAsset(row).state],
      name: propName('state'),
      type: 'string',
      category,
      hideByDefault: true,
    },
    {
      id: 'asset.city',
      _get: row => getAttributeValue(row, 'City'),
      name: propName('city'),
      type: 'string',
      category,
      hideByDefault: true,
      path: 'asset.assetMetaData.city',
    },
    {
      id: 'asset.apartmentId',
      _get: row => getAttributeValue(row, 'ApartmentId'),
      name: propName('apartmentId'),
      type: 'string',
      category,
      hideByDefault: true,
      path: 'asset.assetMetaData.apartmentId',
    },
    {
      id: 'asset.vehicleRef',
      _get: row => getAttributeValue(row, 'VehicleRef'),
      name: propName('vehicleRef'),
      type: 'string',
      category,
      hideByDefault: true,
      path: 'asset.assetMetaData.vehicleRef',
    },
    {
      id: 'asset.maintenanceUnit',
      _get: row => getAttributeValue(row, 'MaintenanceUnit'),
      name: propName('maintenanceUnit'),
      type: 'string',
      category,
      hideByDefault: true,
      path: 'asset.assetMetaData.maintenanceUnit',
    },
    {
      id: 'asset.building',
      _get: row => getAttributeValue(row, 'Building'),
      name: propName('building'),
      type: 'string',
      category,
      hideByDefault: true,
      path: 'asset.assetMetaData.building',
    },
    {
      id: 'asset.floor',
      _get: row => getAttributeValue(row, 'Floor'),
      name: propName('floor'),
      type: 'string',
      category,
      hideByDefault: true,
      path: 'asset.assetMetaData.floor',
    },
    {
      id: 'asset.subSpace',
      _get: row => getAttributeValue(row, 'SubSpace'),
      name: propName('subSpace'),
      type: 'string',
      category,
      hideByDefault: true,
      path: 'asset.assetMetaData.subSpace',
    },
  ];
}
