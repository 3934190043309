import {
  AutomationRuleStatusCountV1Dto,
  AutomationRuleTableV1Dto,
  TriggerHistoryTableRowV1Dto,
} from 'automationcenter-api';
import {
  IntAssetTableDto,
  IntAutomationRuleDetailsDto,
  IntAutomationRuleDto,
  IntAutomationRuleSubscriptionTableDto,
  IntCreateAutomationRuleDto,
  IntCreateLegacyAutomationRuleDto,
  IntLegacyAutomationRuleDto,
  IntSearchDto,
  IntSubscribeToAutomationRuleDto,
} from 'generated';
import {
  DeleteEndpoint,
  GetEndpoint,
  PostEndpoint,
  PostTableEndpoint,
  PutEndpoint,
  TableEndpoint,
} from 'shared/interfaces/api';
import { assetDemoData } from './demoData/assetDemoData';
import { automationRuleDemoData } from './demoData/automationRuleDemoData';

interface IAutomationAPI {
  getAll: (customerId: string) => TableEndpoint<AutomationRuleTableV1Dto>;
  createAutomationRule: PostEndpoint<
    undefined,
    IntCreateAutomationRuleDto,
    IntAutomationRuleDto
  >;
  createAutomationRuleLegacy: PostEndpoint<
    undefined,
    IntCreateLegacyAutomationRuleDto,
    string
  >;
  validateAutomationRuleName: GetEndpoint<
    { customerId: string; automationRuleName: string },
    boolean
  >;
  getSingle: GetEndpoint<string, IntAutomationRuleDto>;
  getSingleLegacy: GetEndpoint<string, IntLegacyAutomationRuleDto>;
  getAutomationRuleDetails: GetEndpoint<string, IntAutomationRuleDetailsDto>;
  deleteAutomationRule: DeleteEndpoint<string>;
  modifyAutomationRule: PutEndpoint<string, IntCreateAutomationRuleDto>;
  modifyAutomationRuleLegacy: PutEndpoint<
    string,
    IntCreateLegacyAutomationRuleDto
  >;
  getSubscriptionTable: TableEndpoint<IntAutomationRuleSubscriptionTableDto>;
  addSubscriptions: PutEndpoint<undefined, IntSubscribeToAutomationRuleDto[]>;

  forceExecuteAutomationRule: PostEndpoint<{ guid: string }>;
  getAutomationRuleHistory: (
    automationId: string
  ) => TableEndpoint<TriggerHistoryTableRowV1Dto>;

  getFilteredAssets: (
    automationId: string
  ) => PostTableEndpoint<IntSearchDto, IntAssetTableDto>;

  getAutomationRuleStatusCount: GetEndpoint<
    string | undefined,
    AutomationRuleStatusCountV1Dto[]
  >;
}
const baseUrl = '/api/automationrule';
const automationProxyBaseUrl = '/api/proxy/automation';
export const automationApi: IAutomationAPI = {
  getAll: customerId => ({
    url: `${automationProxyBaseUrl}/automationrule/table/?customerId=${customerId}`,
    mockData: automationRuleDemoData.getAutomationRuleTable,
    headers: {
      Paginated: 'true',
    },
  }),
  getSingle: {
    url: automationRuleId => `${baseUrl}/${automationRuleId}`,
    mockData: automationRuleId =>
      automationRuleDemoData.getSingle(automationRuleId),
  },
  getSingleLegacy: {
    url: automationRuleId => `${baseUrl}/${automationRuleId}/legacy`,
    mockData: automationRuleId =>
      automationRuleDemoData.getSingleLegacy(automationRuleId),
  },
  getAutomationRuleDetails: {
    url: automationRuleId => `${baseUrl}/${automationRuleId}/details`,
    mockData: automationRuleDemoData.getAutomationRuleDetails,
  },
  deleteAutomationRule: {
    url: automationRuleId => `${baseUrl}/${automationRuleId}`,
    mockData: () => {
      return {
        status: 200,
      };
    },
  },
  createAutomationRule: {
    url: baseUrl,
    mockData: () => ({ status: 201 }),
  },
  createAutomationRuleLegacy: {
    url: baseUrl + '/legacy',
    mockData: () => ({ status: 201 }),
  },
  validateAutomationRuleName: {
    url: ({ customerId, automationRuleName }) =>
      `${automationProxyBaseUrl}/automationrule/validateAutomationRuleName/${automationRuleName}/?customerId=${customerId}`,
    mockData: ({ automationRuleName }) => ({
      status: 200,
      data: automationRuleName !== 'unavailable' ? true : false,
    }),
  },
  modifyAutomationRule: {
    url: automationRuleId => `${baseUrl}/${automationRuleId}`,
    mockData: () => ({ status: 200 }),
  },
  modifyAutomationRuleLegacy: {
    url: automationRuleId => `${baseUrl}/${automationRuleId}/legacy`,
    mockData: () => ({ status: 200 }),
  },
  forceExecuteAutomationRule: {
    url: ({ guid }) =>
      `${automationProxyBaseUrl}/automationrule/${guid}/forceexecution`,
    mockData: () => ({ status: 200 }),
  },
  getAutomationRuleHistory: automationId => ({
    url: `${automationProxyBaseUrl}/automationtriggerhistory/${automationId}`,
    headers: { Paginated: 'true' },
    mockData: () => ({
      status: 200,
      data: automationRuleDemoData.getRuleHistoryTable,
    }),
  }),
  getFilteredAssets: automationId => ({
    url: `${baseUrl}/${automationId}/assets/table`,
    mockData: () => ({
      status: 200,
      mockData: assetDemoData.getAllAssets,
    }),
  }),
  getAutomationRuleStatusCount: {
    url: customerId =>
      `${automationProxyBaseUrl}/automationrule/dashboard/statuscount/?customerId=${customerId}`,
    mockData: automationRuleDemoData.getAutomationRuleStatusCount,
  },
  getSubscriptionTable: {
    url: `${baseUrl}/subscription/table`,
    mockData: automationRuleDemoData.getSubscriptionTableDemoData,
    headers: {
      Paginated: 'true',
    },
  },
  addSubscriptions: {
    url: `${baseUrl}/subscribe`,
    mockData: () => ({ status: 200 }),
  },
};
