import { Role } from 'components/Auth/Role';
import { ServiceDataFilterType } from 'generated';
import { AuthStore } from 'store/authStore';

export function isFilterTypeEnabled(
  authStore: AuthStore,
  filterType: ServiceDataFilterType
) {
  const { hasRole } = authStore;

  switch (filterType) {
    case ServiceDataFilterType.CustomerId:
      return hasRole(Role.RoleNameViewCustomer);

    case ServiceDataFilterType.DealerId:
      return hasRole(Role.RoleNameViewDealer);

    default:
      // All other filters currently relate to assets
      return hasRole(Role.RoleNameViewAsset);
  }
}
