import { getSeriesColorIndex } from 'components/Highcharts';
import { PointOptionsObject } from 'highcharts';
import { dataProp } from 'views/Dashboard/DataSources';
import { IDataProperty } from 'views/Dashboard/DataSources/dataPropTypes';
import { IGroupedWidgetData } from 'views/Dashboard/DataSources/dataSourceTypes';
import { getChartWarnings } from './getChartWarnings';
import { ILineChartSettings } from './lineChartConfig';

export interface ILineChartGroup {
  id: string;
  name: string;
  colorIndex: number | undefined;
  data: PointOptionsObject[];
}

export function dataForAverageChartSeries(
  data: IGroupedWidgetData,
  name: IDataProperty<string>,
  xValue: IDataProperty<number>,
  yValue: IDataProperty<number>,
  settings: ILineChartSettings
): {
  averages: PointOptionsObject[];
  ranges: [number, number | null, number | null][];
  minValues: PointOptionsObject[];
  maxValues: PointOptionsObject[];
  warnings: PointOptionsObject[];
} {
  const groups = data.groups.map(
    (group): ILineChartGroup => ({
      id: group.id,
      colorIndex: getSeriesColorIndex(group.id),
      name: dataProp.getString(name, group.owner) || group.id,
      data: (
        group.dataPoints
          .map(datapoint => {
            const x = dataProp.getNumber(xValue, datapoint);
            const y = dataProp.getNumber(yValue, datapoint);

            return { x, y };
          })
          .filter(point => point.x !== null && point.y !== null) as {
          y: number;
          x: number;
        }[]
      ).sort((a, b) => a.x - b.x),
    })
  );

  const dataByDate: Record<string, { x: number; y: number[] }> = {};

  groups.forEach(group => {
    group.data.forEach(({ x, y }) => {
      if (x === undefined || y === undefined || y === null) {
        return;
      }
      if (!dataByDate[x]) {
        dataByDate[x] = { x, y: [] };
      }
      dataByDate[x].y.push(y);
    });
  });

  const averages: PointOptionsObject[] = [];
  const ranges: [number, number | null, number | null][] = [];
  const minValues: PointOptionsObject[] = [];
  const maxValues: PointOptionsObject[] = [];

  const sortedByX = Object.values(dataByDate).sort((a, b) => a.x - b.x);

  sortedByX.forEach(({ x, y }) => {
    let min: number | null = null;
    let max: number | null = null;
    let sum = 0;
    y.forEach(val => {
      if (min === null || val < min) {
        min = val;
      }
      if (max === null || val > max) {
        max = val;
      }
      sum += val;
    });
    const avg = sum / y.length;

    const nameMin = groups.find(g =>
      g.data.find(gdata => gdata.x === x && gdata.y === min)
    );
    const nameMax = groups.find(g =>
      g.data.find(gdata => gdata.x === x && gdata.y === max)
    );

    averages.push({ x, y: avg });
    ranges.push([x, min, max]);

    minValues.push({
      name: nameMin?.name,
      x: x,
      y: min,
    });
    maxValues.push({
      name: nameMax?.name,
      x: x,
      y: max,
    });
  });

  const warnings = getChartWarnings(groups, settings);

  return { averages, ranges, minValues, maxValues, warnings };
}
