import { CSSProperties } from '@mui/styles';
import classNames from 'classnames';
import ErrorBoundary from 'components/ErrorBoundary';
import React from 'react';
import { useStyles } from './ContentCard.styles';

interface IProps {
  className?: string;
  backgroundColor?: string;
  onClick?: React.EventHandler<React.MouseEvent<HTMLDivElement>>;
  style?: CSSProperties;
  dataCy?: string;
}

// Todo: selectable etc. This is very basic so far. Maybe even replace with MUI card.
const ContentCard: React.FC<IProps> = ({
  className,
  children,
  backgroundColor,
  style,
  dataCy,
}) => {
  const classes = useStyles();

  const cardProps = {
    className: classNames(classes.root, className),
    style: { backgroundColor, ...(style as {}) },
    ...(dataCy && { 'data-cy': dataCy }),
  };

  return (
    <ErrorBoundary>
      <div {...cardProps}>{children}</div>
    </ErrorBoundary>
  );
};

export default ContentCard;
