import { AllFeatures, Features, FeaturesPascal } from './Feature';
import { FeatureFlag } from './FeatureFlag';
import { useFeatureFlags } from './hooks';

export type { Features };
export { FeatureFlag, useFeatureFlags };

/**
 * typeguard for timewindow client filter
 */
export function isTimeWindowClientFilter(
  clientFilter: any
): clientFilter is { parameters: { Start?: string; End?: string } } {
  return (
    clientFilter &&
    clientFilter.name === 'Microsoft.TimeWindow' &&
    clientFilter.parameters &&
    (clientFilter.parameters['Start'] || clientFilter.parameters['End']) &&
    (typeof clientFilter.parameters['Start'] === 'string' ||
      typeof clientFilter.parameters['End'] === 'string')
  );
}

/**
 * typeguard for targeting client filter
 */
export function isTargetingClientFilter(clientFilter: any): clientFilter is {
  parameters: {
    Audience: {
      Groups: Array<{ Name: string; RolloutPercentage: number }>;
      Users: Array<string>;
      DefaultRolloutPercentage: number;
    };
  };
} {
  return (
    clientFilter &&
    clientFilter.name === 'Microsoft.Targeting' &&
    clientFilter.parameters &&
    clientFilter.parameters['Audience'] &&
    Array.isArray(clientFilter.parameters['Audience']['Groups']) &&
    Array.isArray(clientFilter.parameters['Audience']['Users']) &&
    typeof clientFilter.parameters['Audience']['DefaultRolloutPercentage'] ===
      'number'
  );
}

/**
 * typeguard for checking if given string is of type Features (aka added to the features list in Feature.ts)
 */
export const isFeature = (featureKey: string): featureKey is Features => {
  return Object.values(AllFeatures).includes(featureKey as Features);
};

export const isFeaturePascal = (
  featureKey: string
): featureKey is FeaturesPascal => {
  return Object.keys(AllFeatures).includes(featureKey as FeaturesPascal);
};
