import { createStyles, makeStyles } from '@mui/styles';

export const widgetHeaderHeight = 38;

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      paddingRight: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: widgetHeaderHeight,

      '& + .WidgetContent': {
        height: `calc(100% - ${widgetHeaderHeight}px)`, // I truly can't come up with any other way to reliably accomplish this in Firefox
      },
    },
    draggable: {
      cursor: 'move',
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    title: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '100%',
    },
    buttonList: {
      display: 'flex',
    },
    smallWidget: {
      height: widgetHeaderHeight * 1.75,
      flexWrap: 'wrap',

      '& h6': {
        width: '100%',
      },

      '& .button-list': {
        position: 'absolute',
        top: 3,
        right: 0,
      },

      '& + .WidgetContent': {
        height: `calc(100% - ${widgetHeaderHeight * 1.75}px)`,
      },
    },
  })
);
