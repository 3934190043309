import { AutoComplete } from 'components/Inputs';
import { useField } from 'formik';
import { IntDashboardDataStatusDto, ServicePropertyDataType } from 'generated';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'store';
import { rnd } from 'utils';

interface IProps {
  setTabIndex: (i: number) => void;
}

const AddPropertyStatusButton: React.FC<IProps> = ({ setTabIndex }) => {
  const {
    dashboardStore: { serviceOptions = [] },
  } = useStores();
  const { t } = useTranslation('policy');

  const [, { value }, { setValue }] = useField<
    IntDashboardDataStatusDto[] | null
  >('statuses');

  const addStatus = (
    oldStatuses: IntDashboardDataStatusDto[],
    servicePropertyId: string
  ): IntDashboardDataStatusDto[] => [
    ...oldStatuses,
    {
      id: rnd.guid(),
      name: '',
      rules: [],
      customerId: null,
      dealerId: null,
      servicePropertyId,
    },
  ];

  interface IGroupedServiceProp {
    id: string;
    name: string;
    serviceName: string;
    type: ServicePropertyDataType;
  }

  const allServiceProps: IGroupedServiceProp[] = [];
  serviceOptions.forEach(service => {
    service.properties?.forEach(prop =>
      allServiceProps.push({
        id: prop.servicePropertyId,
        name: prop.displayName,
        serviceName: service.displayName,
        type: prop.dataType,
      })
    );
  });

  const getDisabledOptions = () => {
    return (serviceProperty: IGroupedServiceProp) =>
      (serviceProperty.type !== ServicePropertyDataType.Text &&
        serviceProperty.type !== ServicePropertyDataType.Number) ||
      !!value?.find(status => status.servicePropertyId === serviceProperty.id);
  };

  return (
    <AutoComplete
      options={allServiceProps}
      getOptionLabel={sp => sp.name}
      label={t('dashboard:popup_settings.action.add')}
      getOptionValue={sp => sp.id}
      onChange={sp => {
        if (typeof sp === 'string') {
          const newStatuses = addStatus(value || [], sp);
          setValue(newStatuses);
          setTabIndex(newStatuses.length - 1);
        }
      }}
      value={null}
      getOptionDisabled={getDisabledOptions()}
      groupBy={sp => sp.serviceName}
      blurOnSelect
      fullWidth
    />
  );
};

export default observer(AddPropertyStatusButton);
