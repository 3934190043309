import * as azureMaps from 'azure-maps-control';
import { useEffect } from 'react';
import { PopupGetter } from '../getPopupContent';

export function useMapPopup(
  map: azureMaps.Map,
  layer: (azureMaps.layer.SymbolLayer | azureMaps.layer.BubbleLayer)[],
  getPopupContent?: PopupGetter
) {
  useEffect(() => {
    if (!getPopupContent) {
      return undefined;
    }

    const popup = new azureMaps.Popup();
    popup.attach(map);

    const eventHandlers = getPopupHandlers({
      popup,
      openPopup: shape => {
        popup.setOptions({
          content: getPopupContent(shape.getProperties()),
          position: shape.getCoordinates(),
        });

        try {
          popup.open();
        } catch (error) {}
      },
    });

    map.events.add('mousemove', layer, eventHandlers.mouseMove);
    map.events.add('mouseleave', layer, eventHandlers.mouseLeave);
    map.events.add('click', layer, eventHandlers.featureClicked);

    return () => {
      map.events.remove('mousemove', layer, eventHandlers.mouseMove as any); // Infuriating types
      map.events.remove('mouseleave', layer, eventHandlers.mouseLeave);
      map.events.remove('click', layer, eventHandlers.featureClicked as any);
      popup.remove();
    };
  }, [map, layer, getPopupContent]);
}

interface IPopupHandlersParams {
  popup: azureMaps.Popup;
  openPopup: (shape: any) => void;
}

function getPopupHandlers(params: IPopupHandlersParams) {
  const { popup, openPopup } = params;

  let hoveredId = '';
  let clickedId = '';

  return {
    mouseMove: (e: azureMaps.MapMouseEvent) => {
      if (clickedId) {
        return;
      }

      if (e.shapes && e.shapes.length > 0) {
        const shape = e.shapes[0] as any;
        if (!shape.getId) {
          return;
        }
        const id = shape.getId();
        if (id === hoveredId) {
          return;
        }
        hoveredId = id;
        openPopup(shape);
      }
    },
    mouseLeave: () => {
      if (clickedId) {
        return;
      }
      hoveredId = '';
      popup.close();
    },
    featureClicked: (e: azureMaps.MapMouseEvent) => {
      popup.close();

      if (e.shapes && e.shapes.length > 0) {
        const shape = e.shapes[0] as any;
        if (!shape.getId) {
          return;
        }
        const id = shape.getId();
        if (clickedId === id) {
          return;
        }

        const el = (popup as any).containerDiv as HTMLDivElement;
        el.style.pointerEvents = 'all';

        clickedId = id;

        // If closed with the "X", allow hovering once again
        popup._addEventListener(
          'close',
          () => {
            clickedId = '';
            hoveredId = '';
            el.style.pointerEvents = '';
          },
          true // Once
        );
        openPopup(shape);
      }
    },
  };
}
