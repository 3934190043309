import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => {
  return createStyles({
    inputRoot: {
      '& .MuiAutocomplete-tag': {
        marginTop: 0,
        position: 'relative',
        top: '2px',
      },
      backgroundColor: theme.palette.common.white,
      paddingTop: '20px !important',
      '&.Mui-disabled': {
        backgroundColor: '#F3F3F3',
      },
      minHeight: 54,
      '&.MuiAutocomplete-inputRoot': {
        flexWrap: 'nowrap !important',
      },
      '&.Mui-focused': { flexWrap: 'wrap !important' },
    },
    input: {
      margin: '0 !important',
    },
    loadingSpinner: {
      position: 'absolute',
      right: 40,
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      top: 0,
    },
  });
});
