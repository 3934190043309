import { IWidgetData } from 'views/Dashboard/DataSources/dataSourceTypes';
import { serviceDataUtils } from 'views/Dashboard/DataSources/ServiceData/serviceDataUtils';
import { sortArrayByStatusRuleColor } from 'views/Dashboard/DataStatus';
import {
  getActiveNumberStatusRule,
  getActiveStringStatusRule,
} from 'views/Dashboard/DataStatus/useActiveStatusRule';
import { dataProp } from '../../DataSources';
import { IMapFeature } from './ImagePinLayer';
import { IImageWidgetViewModel } from './useImageWidgetData';

// Combines location data with separate details data and creates features
export function useImageWidgetFeatures(opts: {
  viewModel: IImageWidgetViewModel;
  showFeaturesWithoutData?: boolean;
  selectedPinId?: string;
  data?: IWidgetData;
}): IMapFeature[] {
  const { viewModel, showFeaturesWithoutData, selectedPinId, data } = opts;
  const newFeatures: IMapFeature[] = [];

  viewModel.pins.forEach(pin => {
    if (!data && !showFeaturesWithoutData) {
      return;
    }

    const feature: IMapFeature = {
      id: pin.pinId,
      position: pin.position,
      properties: {
        id: pin.pinId,
        displayName: '',
        icon: 'circle-blue',
        iconAnchor: 'center',
        data: {},
      },
    };

    let hasData = false;

    if (data?.type === 'groupedData') {
      const assetData = data.groups.find(group => group.id === pin.assetId);

      if (assetData) {
        hasData = true;

        feature.properties.displayName = dataProp.getString(
          viewModel.displayName,
          assetData.owner
        );

        const flattenedServiceData =
          serviceDataUtils.flattenLatestData(assetData);

        feature.properties.data = {
          ...flattenedServiceData,
          ...assetData.owner,
        };

        if (viewModel.pinValues.length) {
          const allValues = viewModel.pinValues.map(({ status, prop }) => {
            const value =
              prop.type === 'string'
                ? dataProp.getString(prop, flattenedServiceData)
                : dataProp.getNumber(prop, flattenedServiceData);
            const rule =
              typeof value === 'string'
                ? getActiveStringStatusRule(status, value)
                : getActiveNumberStatusRule(status, value);

            return {
              value,
              rule,
              prop,
            };
          });

          const activeValue = sortArrayByStatusRuleColor(
            allValues,
            item => item.rule?.color
          )[0];

          // Grab a default icon from the dataProp, in case no rule is matched
          feature.properties.defaultIconType = activeValue?.prop.icon;

          if (activeValue?.rule) {
            feature.properties.statusColor = activeValue.rule.color;
            feature.properties.statusRule = activeValue.rule;
          }
        }
      }
    }

    if (selectedPinId === pin.pinId) {
      feature.properties.icon = 'circle-selected';
    }

    if (hasData || showFeaturesWithoutData) {
      newFeatures.push(feature);
    }
  });

  return newFeatures;
}
