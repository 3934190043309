/**
 * Basic type safety for features.
 */

/**
 * Features that handle core functionality, can also be referred to as Permantent/long-lived etc.
 */
const CoreFeatures = {
  CoreLanguageSelector: 'core.language.selector',
  CoreAlert: 'core.alert',
  CoreNavigator: 'core.navigator',
} as const;

/**
 * Features that will act as killswitches, and disable functionality when enabled
 */
const KillSwitchFeatures = {
  KillSwitchOne: 'placeholder.killswitch.one',
  KillSwitchTwo: 'placeholder.killswitch.two',
} as const;

/**
 * Features that are only meant to exist for a certain amount of time, f.e. until tested thoroughly
 */
const ShortLivedFeatures = {
  AuditEventsCustomer: 'audit.events.customer',
  AuditEventsUser: 'audit.events.user',
  AuditEventsProfile: 'audit.events.profile',
  AutomationEntitytypeCustomer: 'automation.entitytype.customer',
  AutomationTriggertypeTestmode: 'automation.triggertype.testmode',
  AutomationTriggertypeCountry: 'automation.triggertype.country',
  AutomationTriggertypeTerminated: 'automation.triggertype.terminated',
  AutomationDashboard: 'automation.automationtable.dashboard',
  AutomationTriggerCombination: 'automation.triggercondition.options',
} as const;

/**
 * Features included in the old "preview features"
 */
const PreviewFeatures = {
  Placeholder: 'placeholder.feature.one',
} as const;

export const AllFeatures = {
  ...CoreFeatures,
  ...KillSwitchFeatures,
  ...ShortLivedFeatures,
  ...PreviewFeatures,
} as const;

export type Features = (typeof AllFeatures)[keyof typeof AllFeatures];

export type FeaturesPascal = keyof {
  -readonly [K in keyof typeof AllFeatures]: boolean;
};

export type FeatureTogglesPascal = Record<FeaturesPascal, boolean>;

export type FeatureToggles = Record<Features, boolean>;
