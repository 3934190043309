import MessageOverlay from 'components/MessageOverlay';
import { observer } from 'mobx-react-lite';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ComponentPropsGetterR, Instance, RowInfo } from 'react-table';
import { IDashboardComponent } from 'views/Dashboard/dashboardTypes';
import { useDataSource, useDataSourceData } from 'views/Dashboard/DataSources';
import { DataSourceType } from 'views/Dashboard/DataSources/dataSourceTypes';
import DownloadTableDataMenu from 'views/Dashboard/DownloadTableDataMenu';
import Widget from 'views/Dashboard/Widget';
import { MutationContext } from '../../DataSources/MutationContext';
import IntelligenceEventsDetails from './IntelligenceEventsDetails';
import { makeTableViewModel } from './makeTableViewModel';
import TableComponent from './TableComponent';
import { ITableSettings } from './tableConfig';

type ISubComponentProps = {
  subComponent?: (rowInfo: RowInfo) => JSX.Element;
  getTrProps?: ComponentPropsGetterR;
};

const getSubComponentProps = (
  dataSourceType: DataSourceType
): ISubComponentProps => {
  if (dataSourceType === 'intelligenceEvents') {
    return {
      subComponent: (rowInfo: RowInfo) => (
        <IntelligenceEventsDetails rowInfo={rowInfo} />
      ),
      getTrProps: (_, rowInfo) => {
        if (
          Object.keys(rowInfo?.original.triggeredServiceDataPoint).length === 0
        ) {
          return {
            className: 'not-expandable',
          };
        }
        return {};
      },
    };
  }
  return {};
};

const TableComponentContainer: IDashboardComponent<ITableSettings> = ({
  component,
}) => {
  const { t } = useTranslation('dashboard');
  const currTableRef = useRef<Instance>();
  const { settings, dashboard } = component;

  const dataSource = useDataSource(settings.dataSource);

  const { columns, getRows } = dataSource.getDataProps(
    makeTableViewModel({
      columns: settings.columns,
      dashboard,
      dataSourceSettings: settings.dataSource,
      pivotBy: settings.pivotBy,
      policyId: settings.policyId,
    })
  );

  const { data, noContent, mutate, error } = useDataSourceData(dataSource);

  const parsedData = data ? getRows(data) : { rows: [], total: 0 };

  const allColumns = dataSource.extraColumns
    ? [...columns, ...dataSource.extraColumns]
    : columns;

  const subComponentSettings = getSubComponentProps(dataSource.settings.type);

  return (
    <Widget isLoading={!dataSource.isInitialized || dataSource.isLoading}>
      <Widget.Header
        buttons={
          <DownloadTableDataMenu
            tableRef={currTableRef}
            id={component.componentId}
            title={settings.title}
            disabled={parsedData.total <= 0}
          />
        }
      >
        {settings.title}
      </Widget.Header>
      <Widget.Content skeleton={!dataSource.isInitialized}>
        <MutationContext.Provider value={mutate}>
          <TableComponent
            columns={allColumns}
            settings={settings}
            getTrProps={subComponentSettings.getTrProps}
            subComponent={subComponentSettings.subComponent}
            rows={parsedData.rows}
            total={parsedData.total}
            defaultSorted={dataSource.defaultSort}
            tableState={dataSource.tableState}
            tableRef={table => (currTableRef.current = table)}
          />
        </MutationContext.Provider>

        {noContent && <MessageOverlay message={t('no_data')} />}
        {error && <MessageOverlay message={error} />}
      </Widget.Content>
    </Widget>
  );
};

export default observer(TableComponentContainer);
