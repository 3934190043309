import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles(theme => {
  const themeSettings = theme.settings.components.navigation.menuItem;

  return createStyles({
    text: {
      height: '100%',
      fontSize: themeSettings.fontSize,
      flex: 1,
      paddingLeft: theme.spacing(1),

      textDecoration: 'none',
      lineHeight: `${themeSettings.height}px`,
    },
  });
});

interface IProps {
  children: string;
}

const MenuItemText = React.forwardRef<HTMLDivElement, IProps>((props, ref) => {
  const classes = useStyles();

  return (
    <div ref={ref} className={classes.text}>
      {props.children}
    </div>
  );
});

export default MenuItemText;
