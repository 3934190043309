import { sumBy } from 'lodash';
import {
  IMapFeature,
  IMapFeatureGroup,
  IMapFeatureGrouped,
} from '../../ImageComponent/ImagePinLayer';

function groupMapFeatures(rawFeatures: IMapFeature[]): IMapFeatureGrouped[] {
  const groupsByKey: Record<string, IMapFeature[]> = {};
  const ungrouped: IMapFeature[] = [];

  rawFeatures.forEach(feature => {
    const { group } = feature.properties;

    if (!group) {
      ungrouped.push(feature);
      return;
    }

    if (!groupsByKey[group]) {
      groupsByKey[group] = [];
    }

    groupsByKey[group].push(feature);
  });

  const groupedPins: IMapFeatureGroup[] = Object.entries(groupsByKey).map(
    ([label, features]) => {
      const statusColors = {
        green: sumBy(features, f => f.properties.green ?? 0),
        yellow: sumBy(features, f => f.properties.yellow ?? 0),
        red: sumBy(features, f => f.properties.red ?? 0),
      };

      const getStatusColor = () => {
        if (statusColors.red) {
          return 'red';
        }
        if (statusColors.yellow) {
          return 'yellow';
        }
        if (statusColors.green) {
          return 'green';
        }
        return 'blue';
      };

      const group: IMapFeatureGroup = {
        type: 'group',
        id: label,
        features,
        position: features[0].position,
        properties: {
          id: label,
          data: {},
          displayName: label,
          icon: 'circle-blue',
          iconAnchor: 'center',
          defaultIconType: 'MapPin',
          statusColor: getStatusColor(),
          group: label,
          ...statusColors,
        },
      };
      return group;
    }
  );

  return [...groupedPins, ...ungrouped];
}

export function useGroupedFeatures(
  rawFeatures: IMapFeature[]
): IMapFeatureGrouped[] {
  return groupMapFeatures(rawFeatures);
}
