import { FilterRender } from 'react-table';
import MultiSelectOptionsFilter from './MultiSelectOptionsFilter';

export function getMultiSelectOptionsFilter(
  definitionId: string
): FilterRender {
  return ({ filter, onChange }) => (
    <MultiSelectOptionsFilter
      filter={filter}
      onChange={onChange}
      definitionId={definitionId}
    />
  );
}
