import { format } from 'date-fns';
import { SeriesOptionsType } from 'highcharts';
import { IGroupedData } from 'services/service.service';
import { useDashboardContext } from 'views/Dashboard/contexts/dashboardContext';
import { IDataProperty } from 'views/Dashboard/DataSources/dataPropTypes';
import { IWidgetData } from 'views/Dashboard/DataSources/dataSourceTypes';
import { dataProp } from '../../DataSources';
import { IColumnChartSettings } from './columnChartConfig';
import { IColumnChartViewModel } from './ColumnChartContainer';

const getSeriesDataFromDataPoints = (
  group: IGroupedData,
  xValue: IDataProperty,
  yValue: IDataProperty
) => {
  return group.dataPoints
    .map(datapoint => {
      const x =
        xValue.type === 'dateTime'
          ? dataProp.getNumber(xValue, datapoint)
          : dataProp.getString(xValue, datapoint);

      const y = dataProp.getNumber(yValue, datapoint);

      return [x, y];
    })
    .filter(point => point[0] !== null && point[1] !== null)
    .sort((a, b) => (a[0] as any) - (b[0] as any));
};

export function useColumnChartOptions(opts: {
  viewModel: IColumnChartViewModel;
  settings: IColumnChartSettings;
  data: IWidgetData;
}): Highcharts.Options {
  const {
    data,
    settings: { yAxis },
    viewModel: { name, yValue, xValue },
  } = opts;

  const { filterState } = useDashboardContext();
  const fileName = `${opts.settings.title}-${format(
    new Date(),
    'yyyy-MM-dd HH:mm'
  )}`;

  const series: SeriesOptionsType[] = [];

  if (data.type === 'groupedData') {
    data.groups.forEach(group => {
      series.push({
        id: group.id,
        type: 'column',

        name: dataProp.getString(name, group.owner) || group.id,
        tooltip: {
          valueSuffix: yValue.unit,
          valueDecimals: yValue.decimals ?? 2,
        },

        data: getSeriesDataFromDataPoints(group, xValue, yValue),
      });
    });
  } else if (data.type === 'list') {
    series.push({
      type: 'column',
      name: yValue.name,
      data: data.items.map(item => ({
        y: dataProp.getNumber(yValue, item),
        name: dataProp.getString(name, item),
        color: name.getColor?.(item),
      })),
    });
  } else if (data.type === 'object') {
    series.push({
      type: 'column',
      name: yValue.name,
      color: yValue.getColor?.(data.item),
      data: [
        {
          y: dataProp.getNumber(yValue, data.item),
        },
      ],
    });
  }

  return {
    title: {
      text: undefined,
    },
    exporting: {
      filename: fileName,
      csv: {
        columnHeaderFormatter: function (
          item: { name: string },
          key: { y: string }
        ) {
          if (!key) {
            return xValue.name;
          }
          return getFormattedColumnHeaders(yValue, item);
        },
      },
    },
    xAxis: {
      type: xValue.type === 'dateTime' ? 'datetime' : 'category',
      title: {
        text: xValue.name,
      },
      min:
        xValue.type === 'dateTime'
          ? filterState.getDateFilters().from?.getTime()
          : undefined,
      max:
        xValue.type === 'dateTime'
          ? filterState.getDateFilters().to?.getTime()
          : undefined,
      startOnTick: false,
      endOnTick: false,
    },
    yAxis: [
      {
        title: {
          text: yAxis.label || yValue.name,
        },
        min: typeof yAxis.min === 'number' ? yAxis.min : undefined,
        max: typeof yAxis.max === 'number' ? yAxis.max : undefined,
        labels: {
          format: yValue.unit ? `{value}${yValue.unit}` : undefined,
        },
      },
    ],
    series: series.filter(s => {
      if (s.type === 'column') {
        return s.data?.length;
      }
      return true;
    }),
    time: {
      timezoneOffset: new Date().getTimezoneOffset(),
      useUTC: false,
    },
  };
}

export const getFormattedColumnHeaders = (
  yValue: IDataProperty<number>,
  item: { name: string }
) => {
  return yValue.unit ? `${item.name} | ${yValue.unit}` : item.name;
};
