import * as azureMaps from 'azure-maps-control';
import classNames from 'classnames';
import { IntDashboardDataStatusRuleDto } from 'generated';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import teliaIcons from 'shared/icons/telia-icons';
import { AtlasIconType } from 'shared/types/atlas-icon';
import { PopupGetter } from '../../AzureMapsComponent/getPopupContent';
import { IMapFeatureGrouped } from '../ImagePinLayer';
import { markerSize, useStyles } from './IconMarkerLayer.styles';
import { useMarkerPopup } from './useMarkerPopup';

interface IProps {
  features: IMapFeatureGrouped[];
  map: azureMaps.Map;
  getPopup: PopupGetter;
  showDisplayName?: boolean;
  onMarkerClicked?: (featureId: string) => void;
}

const SvgIcon: React.FC<{ iconType?: AtlasIconType; className: string }> = ({
  iconType,
  className,
}) => {
  if (!iconType) {
    return null;
  }

  const Svg = teliaIcons[iconType]; // Save a DOM element by using the svg straight up, without AtlasIcon wrapper

  return <Svg className={className} />;
};

const IconMarkerLayer: React.FC<IProps> = ({
  map,
  features,
  getPopup,
  showDisplayName,
  onMarkerClicked,
}) => {
  const classes = useStyles();
  const { addPopupEventsToMarker } = useMarkerPopup(map, getPopup, {
    disableClickPopup: !!onMarkerClicked, // The 'x' button in the popup submits forms, and we don't need the click popup when selecting anyways
  });

  useEffect(() => {
    features.forEach(feature => {
      const {
        id,
        position,
        properties: { statusRule, defaultIconType, displayName, icon },
      } = feature;

      const iconType = statusRule
        ? getStatusRuleIcon(statusRule)
        : defaultIconType || 'Status';

      const marker = new azureMaps.HtmlMarker({
        position: position,
        pixelOffset: [0, markerSize / 2],
        htmlContent: renderToStaticMarkup(
          <div
            className={classNames(classes.root, {
              [classes.green]: statusRule?.color === 'green',
              [classes.yellow]: statusRule?.color === 'yellow',
              [classes.red]: statusRule?.color === 'red',
              [classes.blue]: !statusRule,
              [classes.selected]: icon === 'circle-selected', // Consider adding an isSelected boolean if/when removing the old symbol layer variant
            })}
          >
            {<SvgIcon className={classes.icon} iconType={iconType} />}
            {showDisplayName && displayName && (
              <span className={classes.label}>{displayName}</span>
            )}
          </div>
        ),
      });

      // Group don't need full support here, at least for now
      if (feature.type !== 'group') {
        addPopupEventsToMarker(marker, feature);
      }

      if (onMarkerClicked) {
        map.events.add('click', marker, () => onMarkerClicked(id));
      }

      map.markers.add(marker);
    });

    return () => map.markers.clear(); // Change this if ever adding other markers on the map
  }, [
    features,
    showDisplayName,
    onMarkerClicked,
    classes,
    map,
    addPopupEventsToMarker,
  ]);

  return null;
};

function getStatusRuleIcon(rule: IntDashboardDataStatusRuleDto): AtlasIconType {
  if (rule.icon) {
    return rule.icon as AtlasIconType;
  }

  switch (rule.color) {
    case 'red':
      return 'StatusBad';
    case 'yellow':
      return 'StatusModerate';
    case 'green':
      return 'StatusGood';
    default:
      return 'Status';
  }
}

export default observer(IconMarkerLayer);
