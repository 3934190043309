import {
  Checkbox,
  darken,
  FormControlLabel,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';

type IProps = {
  label: string;
  endContent?: React.ReactNode;
  dataCy?: string;
  labelFontSize?: string;
} & (
  | {
      checkbox: true;
      isChecked: boolean;
      disabled?: boolean;
      onClick?: () => void;
    }
  | { checkbox?: false }
);

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: '#f4f4fa',
      marginBottom: theme.spacing(2),
      borderRadius: 7,
      minHeight: 58,
      fontSize: '1rem',
    },
    clickable: {
      '&:hover': {
        backgroundColor: darken('#f4f4fa', 0.03),
      },
    },
  })
);

const CheckboxListItem: React.FC<IProps> = props => {
  const { label, endContent, checkbox, dataCy, labelFontSize } = props;
  const classes = useStyles();

  if (checkbox) {
    return (
      <ListItemButton
        className={classNames(classes.root, classes.clickable)}
        onClick={props.onClick}
        data-cy={dataCy}
      >
        <FormControlLabel
          label={label}
          control={
            <Checkbox checked={props.isChecked} disabled={props.disabled} />
          }
        />
        {endContent}
      </ListItemButton>
    );
  }

  return (
    <ListItem className={classes.root}>
      <ListItemText primaryTypographyProps={{ fontSize: labelFontSize }}>
        {props.label}
      </ListItemText>

      {endContent}
    </ListItem>
  );
};

export default CheckboxListItem;
