import { Button, Dialog, DialogContent } from '@mui/material';
import { Box } from '@mui/system';
import DialogTitleWrapper from 'components/DialogTitleWrapper';
import LoadingOverlay from 'components/LoadingOverlay';
import ModalFooterWithButtons from 'components/ModalFooterWithButtons';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'store';
import CreatePolicyForm from './CreatePolicyForm';
import PolicyForm from './PolicyForm';
import PolicyList from './PolicyList';

interface IProps {
  isOpen: boolean;
  close: () => void;
}

const EditPolicyModal: React.FC<IProps> = ({ isOpen, close }) => {
  const { t } = useTranslation('policy');
  const {
    policyStore: { policies = [], isLoading, loadPolicies },
    dashboardStore: { loadServiceOptions },
  } = useStores();

  const [isCreating, setIsCreating] = useState(false);
  const [policyId, setPolicyId] = useState(policies[0]?.policyId || null);

  // When closing the dialog, cancel create-mode
  useEffect(() => {
    if (!isOpen && isCreating) {
      setIsCreating(false);
    }
  }, [isOpen, isCreating]);

  const selectedPolicy = policies.find(policy => policy.policyId === policyId);
  const topRightRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen) {
      loadPolicies();
      loadServiceOptions();
    }
  }, [isOpen, loadServiceOptions, loadPolicies]);

  return (
    <Dialog
      open={isOpen}
      onClose={close}
      maxWidth="lg"
      fullWidth
      keepMounted={false}
    >
      <DialogTitleWrapper title={t('dialog_title')} onClose={close} />

      {!isCreating && (
        <PolicyList
          policy={policyId}
          topRightRef={topRightRef}
          handleChange={setPolicyId}
          isCreating={isCreating}
          setIsCreating={setIsCreating}
        />
      )}

      <DialogContent>
        <LoadingOverlay isLoading={isLoading}>
          <Box minHeight={400}>
            {isCreating ? (
              <CreatePolicyForm
                onComplete={createdStatus => {
                  setPolicyId(createdStatus.policyId);
                  setIsCreating(false);
                }}
                onCancel={() => setIsCreating(false)}
              />
            ) : (
              selectedPolicy && (
                <PolicyForm policy={selectedPolicy} topRef={topRightRef} />
              )
            )}
          </Box>
        </LoadingOverlay>
      </DialogContent>
      {!isCreating && (
        <ModalFooterWithButtons
          leftButtons={
            <Button variant="outlined" color="primary" onClick={() => close()}>
              {t('common:action.cancel')}
            </Button>
          }
          rightButtons={
            <Button
              type="submit"
              color="primary"
              style={{ marginLeft: 5 }}
              variant="contained"
              form="editPolicyForm"
            >
              {t('action.save_policy')}
            </Button>
          }
        />
      )}
    </Dialog>
  );
};

export default observer(EditPolicyModal);
