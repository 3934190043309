import { Breadcrumbs as Crumbs, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import classNames from 'classnames';
import LoadingSpinner from 'components/LoadingSpinner';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Link } from 'react-router-dom';

export interface IBreadcrumb {
  to?: string;
  text: string;
  isLast?: boolean;
}

interface IProps {
  breadcrumbs: IBreadcrumb[];
}

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      minHeight: 30,
    },
    breadcrumb: {
      color: theme.settings.colors.lightgray,
    },
    activeBreadcrumb: {
      color: theme.palette.primary.main,
    },
  })
);

const BreadcrumbList: React.FC<IProps> = ({ breadcrumbs }) => {
  const classes = useStyles();

  return (
    <Crumbs aria-label="Breadcrumbs" className={classes.root}>
      {breadcrumbs.length > 1 &&
        breadcrumbs.map(({ text, to, isLast }, i) => {
          const crumbClasses = classNames(classes.breadcrumb, {
            [classes.activeBreadcrumb]: isLast,
          });
          const id = isLast ? 'activeBreadcrumb' : `breadcrumb${i}`;
          return to && text.length > 0 ? (
            <Link key={to} to={to} className={crumbClasses} data-cy={id}>
              {text}
            </Link>
          ) : (
            <Typography
              key={to}
              display="inline"
              component="span"
              className={crumbClasses}
              data-cy={id}
            >
              {text.length > 0 ? (
                text
              ) : (
                <LoadingSpinner size={12} style={{ marginBottom: '5px' }} />
              )}
            </Typography>
          );
        })}
    </Crumbs>
  );
};

export default observer(BreadcrumbList);
