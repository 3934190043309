import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    disabled: {
      opacity: '.5',
    },
    gutterBottom: {
      marginBottom: '0.35em',
    },
    inline: {
      display: 'inline-flex',
    },
  })
);
