import { Box, Button, Typography } from '@mui/material';
import { snackbar } from 'components/Snackbar';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { impersonateApi } from 'services/impersonate.service';
import { usePost } from 'shared/api/hooks';
import { useStores } from 'store';
import { useStyles } from './ImpersonationWarning.styles';

const ImpersonationWarning: React.FC = () => {
  const { t } = useTranslation();
  const {
    authStore: { user },
  } = useStores();
  const classes = useStyles();

  const [revertImpersonate] = usePost(impersonateApi.revert);

  const handleRevertImpersonate = async () => {
    const resp = await revertImpersonate({
      params: undefined,
    });
    if (resp.status === 204) {
      document.location.href = document.location.origin;
    } else {
      snackbar(t('common:error.occurred'), { variant: 'error' });
    }
  };

  return (
    <Box display="flex" alignItems="center" m={2} className={classes.root}>
      <div>
        <Typography>
          {`${t('user:user.impersonate.impersonating')}:`}
        </Typography>
        <Typography fontWeight={'bold'}>{user?.fullName}</Typography>
      </div>
      <div>
        <Button variant="contained" onClick={handleRevertImpersonate}>
          {t('common:disable')}
        </Button>
      </div>
    </Box>
  );
};

export default observer(ImpersonationWarning);
