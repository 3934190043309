import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => {
  return createStyles({
    listItem: {
      borderBottom: `1px solid #eaeaed`,
      cursor: 'pointer',
      padding: theme.spacing(1, 1, 1, 0),
    },
    item: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      borderRadius: 10,
      padding: theme.spacing(2),
      position: 'relative',
      '&:hover': {
        backgroundColor: 'rgba(232,232,237,0.5)',
      },
    },
    label: {
      marginRight: theme.spacing(2),
    },
    extraInfo: {
      marginRight: theme.spacing(3),
      display: 'flex',
      color: theme.palette.primary.main,
    },
    activeItem: {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      fontWeight: 'bold',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    icon: {
      position: 'absolute',
      right: 5,
    },
  });
});
