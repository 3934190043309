import {
  IntCreateUiConfigurationRequestDto,
  IntReplaceUiConfigurationRequestDto,
  IntUiConfigurationDetailsDto,
  IntUiConfigurationDto,
} from 'generated';
import {
  DeleteEndpoint,
  GetEndpoint,
  PostEndpoint,
  PutEndpoint,
} from 'shared/interfaces/api';
import { rnd } from 'utils';

interface IThemeAPI {
  getOptions: GetEndpoint<undefined, IntUiConfigurationDto[]>;
  getDetails: GetEndpoint<string, IntUiConfigurationDetailsDto>;
  create: PostEndpoint<
    undefined,
    IntCreateUiConfigurationRequestDto,
    IntUiConfigurationDetailsDto
  >;
  update: PutEndpoint<undefined, IntReplaceUiConfigurationRequestDto>;
  uploadImage: PostEndpoint<string, FormData, string>;
  getActive: GetEndpoint<undefined, IntUiConfigurationDetailsDto>;
  delete: DeleteEndpoint<string>;
}

const baseUrl = '/api/uiConfig';

export const uiConfigApi: IThemeAPI = {
  getOptions: {
    url: baseUrl,
    mockData: () => ({
      status: 204,
      data: [],
    }),
  },
  getDetails: {
    url: uiConfigId => `${baseUrl}/${uiConfigId}`,
    mockData: uiConfigurationId => ({
      status: 200,
      data: {
        displayName: 'Example theme',
        active: true,
        created: new Date(),
        lastUpdated: new Date(),
        settings: [],
        uiConfigurationId,
      },
    }),
  },
  create: {
    url: baseUrl,
    mockData: () => ({
      status: 201,
      data: {
        created: new Date(),
        active: true,
        displayName: 'New template',
        lastUpdated: new Date(),
        settings: [],
        uiConfigurationId: rnd.guid(),
      },
    }),
  },
  update: {
    url: baseUrl,
    mockData: () => ({
      status: 200,
      data: undefined,
    }),
  },
  uploadImage: {
    url: themeId => `${baseUrl}/${themeId}/imageUpload`,
    mockData: () => {
      console.warn('Cannot upload images in demo mode');
      return {
        status: 500,
      };
    },
  },
  getActive: {
    url: `${baseUrl}/active`,
    mockData: () => ({
      status: 204,
    }),
  },
  delete: {
    url: themeId => `${baseUrl}/${themeId}`,
    mockData: () => ({
      status: 200,
    }),
  },
};
