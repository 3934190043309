import { Box, Button, IconButton, Typography } from '@mui/material';
import * as azureMaps from 'azure-maps-control';
import AtlasIcon from 'components/AtlasIcon';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Control } from 'views/Dashboard/SettingControls';
import { IImageSettings } from '../imageConfig';
import { EditMapPointState } from './EditMapPointsState';
import MapOverlayMenu from './MapOverlayMenu';

interface IProps {
  state: EditMapPointState;
  map: azureMaps.Map;
  settings: IImageSettings;
}

const MapPointSettingsMenu: React.FC<IProps> = ({ state, map, settings }) => {
  const { t } = useTranslation('dashboard');

  useEffect(() => {
    map.events.add('click', state.handleMapClick);

    return () => {
      map.events.remove('click', state.handleMapClick as any);
    };
  });

  // When the used asset ids change, we save it to the data source settings, so requests can use them
  const usedAssetIds = settings.dataPoints
    .map(dp => dp.assetId)
    .filter(Boolean)
    .sort()
    .join(',');

  useEffect(() => {
    state.updateDataSourceSettings();
  }, [state, usedAssetIds]);

  if (state.selectedPoint && state.clickMode !== 'move') {
    return (
      <MapOverlayMenu>
        <Typography variant="h6" mb={2}>
          {t('image.data_point.settings')}
        </Typography>
        <Control.AssetSelect object={state.selectedPoint} id="assetIdSelect" />
        <Box display="flex" justifyContent="space-between" mt={4}>
          <IconButton
            onClick={state.deleteSelectedPoint}
            size="small"
            title={t('common:delete')}
          >
            <AtlasIcon type="TrashEmpty" size={16} />
          </IconButton>

          <Button
            onClick={state.startMovePoint}
            color="primary"
            variant="outlined"
          >
            {t('common:action.move')}
          </Button>

          <Button
            onClick={() => state.setSelectedId('')}
            color="primary"
            variant="contained"
          >
            {t('common:action.close')}
          </Button>
        </Box>
      </MapOverlayMenu>
    );
  }

  return (
    <Box position="absolute" top={2} left={2}>
      <Button
        onClick={state.clickMode ? state.cancelAddMode : state.startAddMode}
        color="primary"
        variant="contained"
        fullWidth
      >
        {state.clickMode
          ? t('common:action.cancel')
          : t('image.data_point.add')}
      </Button>
    </Box>
  );
};

export default observer(MapPointSettingsMenu);
