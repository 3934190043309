import { DashboardComponentType, ServiceDataFilterType } from 'generated';
import {
  IBaseComponentSettings,
  IComponentTypeConfig,
} from 'views/Dashboard/dashboardTypes';
import FiltersComponent from './FiltersComponent';
import GeneralSettingsStep from './SettingsSteps/GeneralSettingsStep';

export interface IFiltersSettings extends IBaseComponentSettings {
  filters: IDashboardFilterSettings[];
}

export type IDashboardFilterSettings = IAssetFilterSettings;

export interface IAssetFilterSettings {
  entity: 'asset';
  type: ServiceDataFilterType;
  definitionId?: string;
}

export const defaultFilterSettings: IFiltersSettings = {
  filters: [
    {
      entity: 'asset',
      type: ServiceDataFilterType.CustomerId,
    },
    {
      entity: 'asset',
      type: ServiceDataFilterType.Model,
    },
    {
      entity: 'asset',
      type: ServiceDataFilterType.Type,
    },
    {
      entity: 'asset',
      type: ServiceDataFilterType.AssetId,
    },
  ],
};

export const filtersConfig: IComponentTypeConfig<
  DashboardComponentType.Filters,
  IFiltersSettings
> = {
  widgetType: DashboardComponentType.Filters,
  icon: 'Chat',
  Component: FiltersComponent,
  settingsSteps: [{ title: '', Component: GeneralSettingsStep }],
  getDefaultSettings: () => defaultFilterSettings,
  fixSettings: settings => {
    settings.filters.forEach(filter => {
      if (!filter.entity as any) {
        filter.entity = 'asset';
      }
    });
  },
};
