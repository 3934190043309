import {
  AutomationRuleFilterType,
  AutomationRuleStatus,
  AutomationRuleStatusCountV1Dto,
  AutomationRuleTableV1Dto,
  EntityPropertyName,
  FilterEntityType,
  TriggerHistoryTableRowV1Dto,
} from 'automationcenter-api';
import { TableData } from 'components/Table';
import { subDays } from 'date-fns';
import {
  AutomationRuleActionWebHookAuthType,
  AutomationRuleActionWebHookMethod,
  AutomationRuleActionWebHookPayloadType,
  IntAutomationActionType,
  IntAutomationRuleDetailsDto,
  IntAutomationRuleDto,
  IntAutomationRuleFilterType,
  IntAutomationRuleSubscriptionTableDto,
  IntAutomationRuleType,
  IntAutomationTriggerType,
  IntEntityPropertyName,
  IntFilterEntityType,
  IntLegacyAutomationRuleDto,
  IntSearchDto,
  IntTriggerConditionTimeInterval,
  IntTriggerConditionType,
} from 'generated';
import { IResponse } from 'shared/interfaces/api';
import { paginate, rnd, rndInt } from 'utils';
const ruleType = rnd.enum(IntAutomationRuleType);

class AutomationRuleDemoData {
  getAutomationRuleTable(data?: IntSearchDto) {
    const resData = rnd.array<AutomationRuleTableV1Dto>(70, _ => ({
      status: rnd.enum(AutomationRuleStatus),
      displayName: `Automation_no ${rndInt(1, 50)}`,
      id: rnd.guid(),
      activeSince: subDays(new Date(), rndInt(0, 60)),
      lastEvaluated: subDays(new Date(), rndInt(0, 60)),
      lastTriggered: subDays(new Date(), rndInt(0, 60)),
      lastExecuted: rnd.date.past(),
      filterEntityType: rnd.enum(FilterEntityType),
      automationFilterType: rnd.enum(AutomationRuleFilterType),
    }));
    return {
      status: 200,
      data: paginate(resData, data),
    };
  }

  getSubscriptionTableDemoData(data?: IntSearchDto) {
    const resData = rnd.array<IntAutomationRuleSubscriptionTableDto>(10, _ => ({
      automationRuleId: rnd.guid(),
      displayName: `Automation_no ${rndInt(1, 50)}`,
      activated: true,
      dateTime: subDays(new Date(), rndInt(0, 60)),
      availableSubscriptions: [
        IntAutomationActionType.SendSms,
        IntAutomationActionType.CreateEvent,
        IntAutomationActionType.SendEmail,
      ],
      activeSubscriptions: rnd.array(rnd.int(0, 2), () =>
        rnd.enum(IntAutomationActionType)
      ),
    }));
    return {
      status: 200,
      data: paginate(resData, data),
    };
  }

  getAutomationRuleStatusCount = (): IResponse<
    AutomationRuleStatusCountV1Dto[]
  > => {
    return {
      status: 200,
      data: rnd.array<AutomationRuleStatusCountV1Dto>(10, _i => ({
        status: rnd.enum(AutomationRuleStatus),
        count: rndInt(1, 10),
      })),
    };
  };

  get getRuleHistoryTable(): TableData<TriggerHistoryTableRowV1Dto> {
    const numRows = rnd.int(1, 50);

    const genArrays = (): TriggerHistoryTableRowV1Dto => {
      const hasTriggered = rnd.bool()
        ? subDays(new Date(), rndInt(0, 60))
        : null;
      return {
        entityId: rnd.guid(),
        entityLabel: `Automation_no ${rndInt(1, 50)}`,
        lastEvaluatedFromValue: '',
        lastEvaluatedToValue: '',
        triggerType: rnd.enum(EntityPropertyName),
        evaluationTime: hasTriggered,
        executionTime: hasTriggered,
      };
    };

    const tableDemoData = rnd.array<TriggerHistoryTableRowV1Dto>(
      numRows,
      genArrays
    );
    return { total: tableDemoData.length, rows: tableDemoData };
  }

  getSingle = (automationRuleId: string): IResponse<IntAutomationRuleDto> => {
    return {
      status: 200,
      data: {
        id: automationRuleId,
        status: rndInt(0, 3),
        disableOnExecution: false,
        displayName: `Automation_no ${rndInt(1, 50)}`,
        customerId: rnd.guid(),
        ruleType,
        automationRuleFilterType: rnd.enum(IntAutomationRuleFilterType),
        filterIds: [
          {
            id: rnd.array(rnd.int(1, 3), () => rnd.guid()).toString(),
            entityId: rnd.array(rnd.int(1, 3), () => rnd.guid()).toString(),
            label: `Filter_no ${rndInt(1, 50)}`,
          },
        ],
        trigger: {
          id: '1',
          triggerType: rnd.enum(IntAutomationTriggerType),
          triggerCondition: {
            id: '1',
            triggerServiceId:
              ruleType === IntAutomationRuleType.ServiceDataChanged
                ? 'weather'
                : '',
            triggerServicePropertyId:
              ruleType === IntAutomationRuleType.ServiceDataChanged
                ? 'temperature'
                : '',
            type: rndInt(0, 5),
            timeInterval:
              ruleType !== IntAutomationRuleType.ServiceDataChanged
                ? rndInt(0, 1)
                : null,
            propertyName:
              ruleType !== IntAutomationRuleType.ServiceDataChanged
                ? IntEntityPropertyName.TotalDataUsage
                : undefined,
            triggerUnitValue: `${rndInt(1, 50)}`,
            triggerTypeValue: undefined,
            propertyUnit: rnd.item('kB', 'MB', 'GB'),
            triggerListValue: [],
            triggerConditionOptions: [],
          },
        },
        action: {
          id: '1',
          actionType: rndInt(0, 2),
          sendTo: [rnd.phoneNumber()],
          subjectTemplate: `Subject ${rndInt(1, 50)}`,
          bodyTemplate: `Body ${rndInt(1, 50)}`,
          notifyConnectivityUnitActionByEmail: false,
          connectivityUnitEventType: null,
          eventBody: 'Event body',
          eventBodyTranslations: {
            'en-US': 'Event body',
          },
          useDefaultBody: true,
          webhook: undefined,
        },
        lastEvaluated: subDays(new Date(), rndInt(0, 60)),
        lastTriggered: subDays(new Date(), rndInt(0, 60)),
        filterEntityType: rnd.enum(IntFilterEntityType),
      },
    };
  };

  getSingleLegacy = (
    automationRuleId: string
  ): IResponse<IntLegacyAutomationRuleDto> => {
    return {
      status: 200,
      data: {
        id: automationRuleId,
        displayName: `Automation_no ${rndInt(1, 50)}`,
        customerId: rnd.guid(),
        ruleType,
        active: false,
        assetFilters: [],
        triggerServiceData: {
          triggerCondition: {
            cooldown: 1,
            serviceId: '',
            triggerValue: 1,
            type: IntTriggerConditionType.EqualTo,
            servicePropertyId: '',
            triggerInterval: rndInt(3, 7),
            cooldownInterval: rndInt(3, 7),
            maximumTriggerFrequency: 0,
            maximumTriggerFrequencyInterval: rndInt(3, 7),
            serviceDisplayName: 'Climate',
            servicePropertyDisplayName: 'Temperature',
          },
          triggerType: IntAutomationTriggerType.TimeSinceLastServiceData,
        },
        action: {
          id: '1',
          actionType: rndInt(0, 2),
          sendTo: [rnd.phoneNumber()],
          subjectTemplate: `Subject ${rndInt(1, 50)}`,
          bodyTemplate: `Body ${rndInt(1, 50)}`,
          notifyConnectivityUnitActionByEmail: false,
          eventBody: '',
          eventBodyTranslations: {},
          connectivityUnitEventType: null,
          useDefaultBody: true,
          webhook: undefined,
        },
        actions: [],
        filterEntityType: IntFilterEntityType.Asset,
      },
    };
  };

  getAutomationRuleDetails = (): IResponse<IntAutomationRuleDetailsDto> => {
    return {
      status: 200,
      data: {
        created: rnd.date.past(),
        createdBy: rnd.firstName(),
        numberOfEntities: rnd.int(1, 100),
        trigger: rnd.enum(IntAutomationTriggerType),
        condition: rnd.enum(IntTriggerConditionType),
        level: `Level ${rndInt(1, 50)}`,
        actions: [
          {
            id: '1',
            actionType: rnd.enum(IntAutomationActionType),
            sendTo: [`Send to ${rndInt(1, 50)}`],
            subjectTemplate: `Subject template ${rndInt(1, 50)}`,
            bodyTemplate: `Body template ${rndInt(1, 50)}`,
            notifyConnectivityUnitActionByEmail: false,
            connectivityUnitEventType: null,
            eventBody: 'Event body',
            eventBodyTranslations: {
              'en-US': 'Event body',
            },
            useDefaultBody: true,
            webhook: {
              url: '',
              httpMethod: AutomationRuleActionWebHookMethod.Get,
              payloadType: AutomationRuleActionWebHookPayloadType.None,
              authenticationType:
                AutomationRuleActionWebHookAuthType.NoAuthentication,
              payload: '',
              username: '',
              password: '',
              tokenEndpoint: '',
              grantType: '',
              accessToken: '',
              customHeaders: [],
            },
          },
        ],
        evaluationPeriod: rnd.enum(IntTriggerConditionTimeInterval),
        serviceName: rnd.item('Weather', 'Location', 'Climate'),
        lastExecuted: rnd.date.past(),
        lastEvaluated: subDays(new Date(), rndInt(0, 60)),
        lastTriggered: subDays(new Date(), rndInt(0, 60)),
        activeSince: subDays(new Date(), rndInt(0, 60)),
        propertyName:
          ruleType !== IntAutomationRuleType.ServiceDataChanged
            ? IntEntityPropertyName.TotalDataUsage.toString()
            : rnd.item('Temperature', 'Light', 'RegisterDate'),
        triggerUnitValue: rnd.item('77', '30', '21'),
        propertyUnitType: rnd.item('%', '°C'),
        triggerListValue: [],
        disableOnExecution: false,
      },
    };
  };
}

export const automationRuleDemoData = new AutomationRuleDemoData();
