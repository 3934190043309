import { Box, Grid, ListItemText } from '@mui/material';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ComponentContainer from 'views/Dashboard/ComponentContainer';
import { IDashboardComponent } from 'views/Dashboard/dashboardTypes';
import { useDataSource } from 'views/Dashboard/DataSources';
import { IDataSourceStore } from 'views/Dashboard/DataSources/dataSourceTypes';
import { Control } from 'views/Dashboard/SettingControls';
import PolicyControl from '../../NumberGridComponent/SettingsSteps/PolicyControl';
import { ITableColumnSettings, ITableSettings } from '../tableConfig';
import SelectDataPropButton from './SelectDataPropButton';

const ColumnRow: React.FC<{
  dataSource: IDataSourceStore;
  column: ITableColumnSettings;
}> = observer(({ column, dataSource }) => {
  const { dataProperties, groupProperties } = dataSource;

  const columnProp =
    column.type === 'ownerProp'
      ? groupProperties.find(dp => dp.id === column.prop)
      : dataProperties.find(dp => dp.id === column.prop);

  return <ListItemText primary={columnProp?.name || column.prop} />;
});

const GeneralSettingsStep: IDashboardComponent<ITableSettings> = ({
  component,
}) => {
  const { t } = useTranslation('dashboard');
  const { settings } = component;
  const dataSource = useDataSource(settings.dataSource);

  const addDataPropRow = (dpId: string) => {
    const type = dataSource.dataProperties.find(dp => dp.id === dpId)
      ? 'dataPointProp'
      : 'ownerProp';
    runInAction(() => {
      settings.columns.push({
        prop: dpId,
        type,
      });
    });
  };

  const addPivot = (id: string) =>
    runInAction(() => {
      settings.pivotBy.push(id);
    });

  return (
    <Grid container spacing={4}>
      <Grid item sm={4}>
        <Control.Group label={t('table.columns')}>
          <Control.SortableList items={settings.columns}>
            {col => <ColumnRow column={col} dataSource={dataSource} />}
          </Control.SortableList>
          <SelectDataPropButton
            onChange={addDataPropRow}
            dataSource={dataSource}
            label={t('table.action.add_column')}
            id="addTableColumn"
            isOptionDisabled={dp =>
              !!settings.columns.find(col => col.prop === dp.id)
            }
          />
        </Control.Group>
        {settings.dataSource.type === 'serviceLatest' && (
          <Control.Group label={t('table.pivot')}>
            <Control.SortableList items={settings.pivotBy}>
              {colId => (
                <div>
                  {dataSource.groupProperties.find(dp => dp.id === colId)
                    ?.name ||
                    dataSource.dataProperties.find(dp => dp.id === colId)
                      ?.name ||
                    colId}
                </div>
              )}
            </Control.SortableList>

            <SelectDataPropButton
              onChange={addPivot}
              dataSource={dataSource}
              id="addPivot"
              label={t('table.action.add_pivot')}
              isOptionDisabled={dp => settings.pivotBy.includes(dp.id)}
              showDataProps={false}
            />
          </Control.Group>
        )}
      </Grid>

      <Grid item sm={8}>
        <Control.Group label={t('preview')}>
          <Box height={340}>
            <ComponentContainer component={component} />
          </Box>
        </Control.Group>
        <PolicyControl settings={settings} />
      </Grid>
    </Grid>
  );
};

export default observer(GeneralSettingsStep);
