import i18n from 'i18n';
import {
  DataPropSetting,
  IDataProperty,
  IDataPropPropSettings,
} from './dataPropTypes';
import { IDataSourceStore } from './dataSourceTypes';
import { getDataPropSearchName } from './ServiceData/assetDataProperties';

export function getDpSetting(settings: DataPropSetting): IDataPropPropSettings {
  return typeof settings === 'string' ? {} : settings;
}

export function getDataPropId(setting: DataPropSetting) {
  return typeof setting === 'string' ? setting : setting?.id || '';
}

export function getDataProp(
  dataSource: IDataSourceStore,
  value: DataPropSetting
): IDataProperty {
  const { dynamicAttributeProperties } =
    dataSource.rootStore.dynamicAttributeStore;

  const id = getDataPropId(value);

  const settings = getDpSetting(value);

  const foundProp =
    dataSource.dataProperties.find(p => p.id === id) ||
    dataSource.groupProperties.find(p => p.id === id) ||
    dynamicAttributeProperties.find(
      x => getDataPropSearchName(x.name) === getDataPropSearchName(id) // Fall back to attempting to find a dynamic attribute property. This row might get deleted if/when we do a database migration
    );

  if (foundProp) {
    if (settings.agg && foundProp.aggregations) {
      return foundProp.aggregations[settings.agg];
    }
    return foundProp;
  }

  return {
    type: 'notFound',
    category: i18n.t('dashboard:data.properties'),
    _get: () => null,
    id,
    name: '',
  };
}
