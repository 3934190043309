import { Box, Typography } from '@mui/material';
import AtlasIcon from 'components/AtlasIcon';
import AtlasIconButton from 'components/AtlasIconButton';
import { useTranslation } from 'react-i18next';
import { useStores } from 'store';
import { iconSize } from '../SiteTopBarContent';
import { useStyles } from '../SiteTopBarContent.styles';

const DeveloperButton: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    layoutStore: { toggleSettingsMenuOpen },
  } = useStores();
  return (
    <AtlasIconButton
      onClick={toggleSettingsMenuOpen}
      className={classes.iconButton}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <AtlasIcon type="AiRobot" size={iconSize} />
        <Typography variant="body2">{t('dev_tools')}</Typography>
      </Box>
    </AtlasIconButton>
  );
};

export default DeveloperButton;
