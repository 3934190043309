import {
  IntCreateDealerRequest,
  IntDealerDetailsDto,
  IntDealerDto,
  IntDealerTableDto,
  IntUpdateDealerRequestDto,
} from 'generated';
import {
  DeleteEndpoint,
  GetEndpoint,
  PostEndpoint,
  PutEndpoint,
  TableEndpoint,
} from 'shared/interfaces/api';
import { rnd } from 'utils';
import { dealerDemoData } from './demoData/dealerDemoData';

interface IDealerAPI {
  getAll: TableEndpoint<IntDealerDto>;
  detailedTable: TableEndpoint<IntDealerTableDto>;
  getDealer: GetEndpoint<string, IntDealerDetailsDto>;
  createDealer: PostEndpoint<
    undefined,
    IntCreateDealerRequest,
    IntDealerDetailsDto
  >;
  deleteDealer: DeleteEndpoint<string>;
  editDealer: PutEndpoint<string, IntUpdateDealerRequestDto>;
}

const baseUrl = '/api/dealer';

export const dealerAPI: IDealerAPI = {
  getAll: {
    url: baseUrl,
    mockData: () => ({
      status: 200,
      data: {
        total: 2,
        rows: [
          { dealerId: rnd.guid(), dealerName: 'Axelerate AB' },
          { dealerId: rnd.guid(), dealerName: 'Telia Company' },
        ],
      },
    }),
  },
  detailedTable: {
    url: `${baseUrl}/table`,
    mockData: dealerDemoData.getDetailedTable,
  },
  getDealer: {
    url: dealerId => `${baseUrl}/${dealerId}`,
    mockData: dealerDemoData.getDealer,
  },
  createDealer: {
    url: baseUrl,
    mockData: () => ({ status: 200 }),
  },
  deleteDealer: {
    url: dealerId => `${baseUrl}/${dealerId}`,
    mockData: () => ({
      status: 200,
    }),
  },
  editDealer: {
    url: dealerId => `${baseUrl}/${dealerId}`,
    mockData: () => ({
      status: 200,
    }),
  },
};
