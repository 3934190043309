import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { FormField } from 'components/FormFields';
import { Form, Formik } from 'formik';
import { IntPolicyDto } from 'generated';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IntCreateDashboardPolicyDto } from 'services/dashboardPolicy.service';
import { useStores } from 'store';
import { Yup } from 'utils';
import PolicyCustomerField from './PolicyCustomerField';

interface IProps {
  onComplete: (createdPolicy: IntPolicyDto) => void;
  onCancel: () => void;
}

const createPolicyValidationSchema = Yup.form<IntCreateDashboardPolicyDto>({
  name: Yup.string().required(),
  customerId: Yup.string(),
});

const CreatePolicyForm: React.FC<IProps> = ({ onComplete, onCancel }) => {
  const { t } = useTranslation('policy');
  const {
    authStore: { user },
    policyStore: { createNewPolicy, isLoading },
  } = useStores();

  const initialValues: IntCreateDashboardPolicyDto = {
    name: '',
    customerId: user?.customerId || '',
  };

  const handleSubmit = async (values: IntCreateDashboardPolicyDto) => {
    const createdPolicy = await createNewPolicy(values);
    createdPolicy && onComplete(createdPolicy);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={createPolicyValidationSchema}
    >
      <Form>
        <FormField.TextField
          name="name"
          label={t('name')}
          maxLength={75}
          autoFocus
          required
        />
        <PolicyCustomerField />

        <Box display="flex" justifyContent="space-between" mt={10}>
          <Button onClick={onCancel} variant="outlined" color="primary">
            {t('common:action.cancel')}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isLoading}
          >
            {t('action.add_policy')}
          </Button>
        </Box>
      </Form>
    </Formik>
  );
};

export default observer(CreatePolicyForm);
