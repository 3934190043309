import { mergeWith } from 'lodash';
import { applyUIConfiguration } from 'styles/themes/applyUIConfiguration';
import { makeAtlasTheme } from 'styles/themes/makeAtlasTheme';
import { defaultUiConfiguration } from './defaultUiConfiguration';
import { IUiConfiguration } from './IUiConfiguration';

// Overwrite empty strings with values from the default theme, avoiding possible errors
// (important! especially when new parameters are added to UIConfigs)
export function mergeUiConfigWithDefaultValues(uiConfig: IUiConfiguration) {
  return mergeWith({}, defaultUiConfiguration, uiConfig, (value, srcValue) => {
    // If/when needed, expand this to cover numbers etc, for now strings are enough
    if (srcValue === '') {
      return value;
    }
    return undefined; // Lodash merge default logic applies
  });
}

export const uiConfigToMuiTheme = (uiConfig: IUiConfiguration) => {
  const settingsWithDefaults = mergeUiConfigWithDefaultValues(uiConfig);

  // Themesettings, and with it this whole step, could be removed, but it's slightly annoying and out of scope of the MVP in which this excuse is written.
  const themeSettings = applyUIConfiguration(settingsWithDefaults);

  return makeAtlasTheme(themeSettings);
};
