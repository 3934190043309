import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => {
  const { topMenu, settingsMenu } = theme.settings.components;

  return createStyles({
    drawer: {
      width: settingsMenu.width,
      top: topMenu.height,
      height: `calc(100% - ${topMenu.height}px)`,
      flexShrink: 0,
    },
    tab: {
      fontSize: '0.8rem',
      minWidth: 'auto',
    },
    tabContent: {
      flexGrow: 1,
      overflowY: 'auto',
    },
  });
});
