import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from '@mui/material';
import AtlasIcon from 'components/AtlasIcon';
import BigListItem from 'components/BigListItem';
import LoadingOverlay from 'components/LoadingOverlay';
import SearchField from 'components/SearchField';
import { ServiceDataFilterType } from 'generated';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterOptionState } from './FilterOptionState';
import PaginatedList from './PaginatedList';

interface IProps {
  state: FilterOptionState;
}

const SelectedFilterSettings: React.FC<IProps> = ({ state }) => {
  const { t } = useTranslation('dashboard');

  useEffect(() => {
    state.resetAndLoadOptions();
  }, [state, state.depString]);

  // Little point in displaying "1" on every option in this case
  const showAssetCount =
    state.filterOption.type !== ServiceDataFilterType.AssetId;

  return (
    <>
      <Box gridArea="1 / 2 / 2 / 3">
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <SearchField
              value={state.search}
              onChange={state.setSearch}
              placeholder={state.displayName}
            />
          </Grid>
          <Grid item xs={3}>
            <Box onClick={state.clearValues} textAlign="right">
              <FormControlLabel
                label={t('service_data_filters.all')}
                control={
                  <Switch
                    checked={state.valueCount === 0}
                    disabled={state.valueCount === 0}
                  />
                }
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box gridArea="2 / 2 / 4 / 3" overflow="auto">
        <LoadingOverlay isLoading={state.isLoading} height="100%">
          {state.selectedOptions.length > 0 && (
            <Box mb={2}>
              <Typography variant="h6" gutterBottom>
                {t('service_data_filters.selected', {
                  count: state.selectedOptions.length,
                })}
              </Typography>
              <PaginatedList
                array={state.selectedOptions}
                pageSize={5}
                buttonTextAlign="center"
              >
                {opt => (
                  <BigListItem
                    key={opt.id}
                    label={opt.name}
                    isChecked
                    checkbox
                    onClick={() => state.toggleFilterValue(opt)}
                  />
                )}
              </PaginatedList>
            </Box>
          )}

          <Typography variant="h6" gutterBottom>
            {t('service_data_filters.available_to_select')}
          </Typography>

          {state.unselectedOptions.map(opt => (
            <BigListItem
              dataCy={`filter-option-${opt.id}`}
              key={opt.id}
              label={opt.name}
              checkbox
              isChecked={false}
              onClick={() => state.toggleFilterValue(opt)}
              endContent={showAssetCount && opt.entityCount}
            />
          ))}

          {state.response &&
            state.unselectedOptions.length === 0 &&
            !state.isLoading && (
              <Typography align="center" variant="caption">
                {t('service_data_filters.no_unselected_options')}
              </Typography>
            )}

          {state.response?.hasMore && (
            <Box textAlign="center">
              <Button onClick={state.loadMore} disabled={state.isLoading}>
                {t('common:action.show_more')}&nbsp;
                <AtlasIcon type="ChevronDown" size={14} />
              </Button>
            </Box>
          )}
        </LoadingOverlay>
      </Box>
    </>
  );
};

export default observer(SelectedFilterSettings);
