import { Box } from '@mui/material';
import React from 'react';

interface IProps {
  flex?: number;
}

const FilterColumn: React.FC<IProps> = ({ flex = 1, children }) => (
  <Box flex={flex} padding={1} pt={0} pb={2}>
    {children}
  </Box>
);

export default FilterColumn;
