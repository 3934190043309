import { InvoiceStatus } from 'dtos/invoices-table-dto';
import i18n from 'i18next';
import { Column } from 'react-table';
import { stylingNames } from '../Columns.styles';
import { CustomCell } from '../Helpers/CustomCell';
import { ICustomCellProps } from '../Helpers/CustomCellProps';
import { getOptionFilter } from '../Helpers/getOptionFilter';

export function invoiceStatus<T>(options: {
  id: string;
  accessor: (row: T) => InvoiceStatus;
  header?: string;
  defaultShow?: boolean;
}): Column<T> {
  const { id, header = 'Status', accessor, defaultShow: show } = options;

  const cellByStatus: Record<InvoiceStatus, ICustomCellProps> = {
    paid: {
      iconType: 'Status',
      variant: 'success',
      title: i18n.t('invoice:label_status.paid'),
    },
    notPaid: {
      iconType: 'Alert',
      variant: 'warning',
      title: i18n.t('invoice:label_status.not_paid'),
    },
    pending: {
      iconType: 'Email',
      variant: 'default',
      title: i18n.t('invoice:label_status.pending'),
    },
  };

  return {
    id,
    Header: header,
    accessor,
    className: stylingNames.textCenter,
    resizable: false,
    maxWidth: 80,
    show,
    Cell: row => {
      const iconProps = cellByStatus[row.value as InvoiceStatus] || {};
      return <CustomCell {...iconProps} index={row.index} />;
    },
    Filter: getOptionFilter([
      {
        label: i18n.t('invoice:label_status.all'),
        value: '',
      },
      {
        label: i18n.t('invoice:label_status.paid'),
        value: 'paid',
      },
      {
        label: i18n.t('invoice:label_status.not_paid'),
        value: 'notPaid',
      },
      {
        label: i18n.t('invoice:label_status.pending'),
        value: 'pending',
      },
    ]),
  };
}
