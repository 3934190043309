import { VpnStatus } from 'generated';
import i18n from 'i18next';
import { Column } from 'react-table';
import { textRight } from '../Helpers/textRight';
import LinkButton from '../LinkButton';

export function configureVpn<T>(options: {
  id?: string;
  accessor: (row: T) => VpnStatus;
  onClick: (row: T) => void;
  canEdit: boolean;
}): Column<T> {
  const { id = 'configure', onClick, accessor, canEdit } = options;

  return {
    id,
    accessor,
    filterable: false,
    sortable: false,
    className: textRight,
    Cell: row => {
      if (row.value === VpnStatus.Pending) {
        return null;
      } else if (row.value === VpnStatus.Unconfigured) {
        return (
          canEdit && (
            <LinkButton onClick={() => onClick(row.original)}>
              {i18n.t('common:action.configure')}
            </LinkButton>
          )
        );
      } else if (canEdit) {
        return (
          <LinkButton onClick={() => onClick(row.original)}>
            {i18n.t('common:action.update')}
          </LinkButton>
        );
      }
      return null;
    },
  };
}
