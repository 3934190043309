import { Role } from 'components/Auth/Role';
import { snackbar } from 'components/Snackbar';
import i18n from 'i18next';
import {
  action,
  computed,
  configure,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { enableLogging } from 'mobx-logger';
import { persist } from 'mobx-persist';
import { httpStatusCodes } from 'shared/interfaces/api';
import { isDevelopment, isDevOrLocal } from 'utils';
import { RealEstateItStore } from 'views/RealEstateIt/store/realEstateItStore';
import { ApnStore } from './apnStore';
import { AssetStore } from './assetStore';
import { AuthStore } from './authStore';
import { AutomationStore } from './automationStore';
import { ConnectivityConfigStore } from './connectivityConfigStore';
import { ConnectivityUnitStore } from './connectivityUnitStore';
import { CustomerStore } from './customerStore';
import { DashboardStore } from './dashboardStore';
import { DealerStore } from './dealerStore';
import { DynamicAttributeStore } from './dynamicAttributeStore';
import { ExtensionStore } from './extensionStore';
import { FeatureStore } from './featureStore';
import { LayoutStore } from './layoutStore';
import { PolicyStore } from './policyStore';
import { SmsStore } from './smsStore';

export class RootStore {
  layoutStore: LayoutStore;
  authStore: AuthStore;
  connectivityConfigStore: ConnectivityConfigStore;
  connectivityUnitStore: ConnectivityUnitStore;
  customerStore: CustomerStore;
  dashboardStore: DashboardStore;
  dynamicAttributeStore: DynamicAttributeStore;
  apnStore: ApnStore;
  automationStore: AutomationStore;
  smsStore: SmsStore;
  assetStore: AssetStore;
  extensionStore: ExtensionStore;
  realEstateItStore: RealEstateItStore;
  dealerStore: DealerStore;
  policyStore: PolicyStore;
  featureStore: FeatureStore;

  @persist @observable demoMode = false;
  @persist @observable errorMode = false;
  @persist @observable httpStatusCode: httpStatusCodes = 200;

  constructor() {
    makeObservable(this);
    this.layoutStore = new LayoutStore(this);
    this.authStore = new AuthStore(this);
    this.connectivityConfigStore = new ConnectivityConfigStore(this);
    this.connectivityUnitStore = new ConnectivityUnitStore(this);
    this.customerStore = new CustomerStore(this);
    this.dashboardStore = new DashboardStore(this);
    this.dynamicAttributeStore = new DynamicAttributeStore(this);
    this.apnStore = new ApnStore(this);
    this.automationStore = new AutomationStore(this);
    this.smsStore = new SmsStore(this);
    this.assetStore = new AssetStore(this);
    this.extensionStore = new ExtensionStore(this);
    this.realEstateItStore = new RealEstateItStore(this);
    this.dealerStore = new DealerStore(this);
    this.policyStore = new PolicyStore(this);
    this.featureStore = new FeatureStore(this);
  }

  @computed get isStaticDemo() {
    return this.authStore.hasRole(Role.RoleNameStaticDemo);
  }

  @computed get allowDemoMode() {
    return isDevOrLocal && !this.isStaticDemo;
  }

  @action.bound toggleDemoData = () => {
    this.demoMode = !this.demoMode;
    this.authStore.getUserAuthData();
    // Temp solution as stores dont get reset when changing between real and demo data
    // this.refreshPageData();
    window.location.reload();

    snackbar(
      this.demoMode
        ? i18n.t('demo_mode.enabled')
        : i18n.t('demo_mode.disabled'),
      { variant: 'info' }
    );
  };

  @action.bound toggleErrorMode = () => {
    this.errorMode = !this.errorMode;

    if (!this.errorMode) {
      this.httpStatusCode = 500;
    }

    snackbar(
      this.errorMode
        ? i18n.t('error_mode.enable', { statusCode: this.httpStatusCode })
        : i18n.t('error_mode.disable', { statusCode: 200 }),
      {
        variant: 'info',
      }
    );
  };

  @action.bound setHttpStatusCode = (httpCode = '200') => {
    //If the field is empty, this will throw a error if not a 200 return
    runInAction(() => {
      this.httpStatusCode = parseInt(httpCode ? httpCode : '200');
    });

    snackbar(i18n.t('http_status', { statusCode: this.httpStatusCode }), {
      variant: 'info',
    });
  };

  // A quick and easy way to refresh all data on a page (unmounts and then re-mounts ContentContainer in Layout.tsx)
  // Remove when MobX is fully implemented and it's no longer needed
  @observable isRefreshing = false;

  @action.bound refreshPageData() {
    this.isRefreshing = true;
    window.setTimeout(
      () =>
        runInAction(() => {
          this.isRefreshing = false;
        }),
      50
    );
  }

  // Sometimes a form needs disabled while something in it loads
  @observable formLoadingMessage = '';

  @action.bound setFormLoadingMessage(message = '') {
    this.formLoadingMessage = message;
  }

  @computed get languageCulture() {
    return this.authStore.user?.languageCulture || 'en-US';
  }
}

if (isDevelopment) {
  enableLogging({
    action: false,
    reaction: false,
    transaction: false,
    compute: false,
  });
}

configure({ enforceActions: 'observed' });
