import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useStores } from 'store';
import { useStyles } from './DemoWarning.styles';

const DemoWarning: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { toggleDemoData } = useStores();
  return (
    <Box
      justifyContent="space-between"
      display="flex"
      alignItems="center"
      m={2}
      className={classes.root}
    >
      <img
        src={`${process.env.PUBLIC_URL}/demo_siren.gif`}
        height="40px"
        width="40px"
        alt={t('label.demo_mode_active')}
      />
      <Typography variant="body2">{t('label.demo_mode_active')}</Typography>
      <img
        src={`${process.env.PUBLIC_URL}/demo_siren.gif`}
        height="40px"
        width="40px"
        alt="spinner_image"
      />
      <Button
        variant="contained"
        color="primary"
        data-cy="disableDemoModeButton"
        onClick={() => {
          toggleDemoData();
        }}
      >
        {t('action.demo_mode.disable')}
      </Button>
    </Box>
  );
};

export default DemoWarning;
