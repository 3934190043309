import { ButtonBase } from '@mui/material';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'store';
import { useStyles } from './MenuItemButton.styles';

interface IProps {
  onClick: React.EventHandler<React.MouseEvent<HTMLButtonElement>>;
}

const MenuItemButton: React.FC<IProps> = ({ onClick, children }) => {
  const classes = useStyles();
  const {
    layoutStore: { sidenavOpen },
  } = useStores();

  return (
    <ButtonBase
      onClick={onClick}
      className={classNames(classes.root, {
        [classes.menuClosed]: !sidenavOpen,
      })}
    >
      {children}
    </ButtonBase>
  );
};

export default observer(MenuItemButton);
