import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: '0 !important',
    },
    inputRoot: {
      padding: '18px 60px 2px 0 !important',
      display: 'flex',
      flexWrap: 'wrap',
    },
    input: {
      padding: '5px 12px 10px 12px !important',
    },
  })
);
