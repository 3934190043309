import { Box, Typography } from '@mui/material';
import classNames from 'classnames';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import React from 'react';
import { useStyles } from './LoadingOverlay.styles';
import useDisableTabKey from './useDisableTabKey';

interface IBulkActionTexts {
  processed: string;
  failed?: string;
}

interface IOverlayProps {
  isLoading: boolean;
  variant?: 'interactive' | 'default' | 'nonTransparent';
  loadingText?: string;
  cypressId?: string;
  bulkActionTexts?: IBulkActionTexts;
}

const Overlay: React.FC<IOverlayProps> = ({
  isLoading,
  loadingText,
  cypressId,
  bulkActionTexts,
  variant = 'default',
}) => {
  const classes = useStyles();
  const spinnerRef = useDisableTabKey(isLoading);

  return (
    <Box
      ref={spinnerRef}
      position="absolute"
      top="0"
      bottom="0"
      left="0"
      right="0"
      display="flex"
      justifyContent="center"
      alignItems="center"
      borderRadius="inherit"
      flexDirection="column"
      className={classNames(classes.overlay, {
        [classes.open]: isLoading,
        [classes.interactiveOverlay]: variant === 'interactive' && isLoading,
        [classes.nonTransparent]: variant === 'nonTransparent' && isLoading,
      })}
    >
      <LoadingSpinner disableShrink size={70} data-cy={cypressId} />

      {bulkActionTexts && (
        <>
          <Box className={classes.bulkCounter}>{bulkActionTexts.processed}</Box>
          <Box className={classNames(classes.bulkCounter, classes.bulkError)}>
            {bulkActionTexts.failed}
          </Box>
        </>
      )}

      {loadingText && (
        <Typography
          className={classes.margin}
          variant="h6"
          data-cy={`${cypressId}-text`}
        >
          {loadingText}
        </Typography>
      )}
    </Box>
  );
};

export interface ILoadingOverlayProps extends IOverlayProps {
  className?: string;
  height?: string | number;
  style?: React.CSSProperties;
  doProvideContext?: boolean;
}

const LoadingOverlay: React.FC<ILoadingOverlayProps> = ({
  isLoading,
  children,
  className,
  height,
  style,
  loadingText,
  doProvideContext,
  cypressId,
  bulkActionTexts,
  variant = 'default',
}) => {
  const overlay = (
    <Overlay
      isLoading={isLoading}
      loadingText={loadingText}
      cypressId={cypressId}
      bulkActionTexts={bulkActionTexts}
      variant={variant}
    />
  );

  return !children && !doProvideContext ? (
    overlay
  ) : (
    <Box
      position="relative"
      style={style}
      className={className}
      height={height}
    >
      {children}
      {overlay}
    </Box>
  );
};

export default LoadingOverlay;
