import { Form as FormikForm, FormikFormProps } from 'formik';
import React from 'react';

interface IProps extends FormikFormProps {
  onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
}

const Form: React.FC<IProps> = props => {
  return (
    <FormikForm noValidate {...props}>
      {props.children}
    </FormikForm>
  );
};

export default Form;
