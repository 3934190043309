import { Badge, Box, Popover, Typography } from '@mui/material';
import AtlasIcon from 'components/AtlasIcon';
import AtlasIconButton from 'components/AtlasIconButton';
import { useTranslation } from 'react-i18next';
import { usePopover } from 'utils';
import DropdownLink from '../DropdownLink';
import NotificationPreview, { useNotifications } from '../Notification';
import { iconSize } from '../SiteTopBarContent';
import { useStyles } from '../SiteTopBarContent.styles';

const NotificationPopover: React.FC = () => {
  const { t } = useTranslation();
  const notifications = useNotifications();
  const [openPopover, popoverProps] = usePopover('notifications');

  const classes = useStyles();
  return (
    <>
      <Popover
        {...popoverProps}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box className={classes.popoverContent}>
          {notifications.map(notification => (
            <NotificationPreview key={notification.id} {...notification} />
          ))}
          <DropdownLink to="/notifications" exact>
            <Box textAlign="center" fontSize={10} color="#9933ff">
              {t('label.see_recent_notifications')}
            </Box>
          </DropdownLink>
        </Box>
      </Popover>
      <AtlasIconButton onClick={openPopover} className={classes.iconButton}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Badge variant="dot" color="error" overlap="circular">
            <AtlasIcon type="Notifications" size={iconSize} />
          </Badge>
          <Typography className={classes.iconWidth} variant="body2">
            {t('alert')}
          </Typography>
        </Box>
      </AtlasIconButton>
    </>
  );
};

export default NotificationPopover;
