import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import AtlasIcon from 'components/AtlasIcon';
import { AutoComplete } from 'components/Inputs';
import { snackbar } from 'components/Snackbar';
import React from 'react';
import { subportalAPI } from 'services/subportal.service';
import { usePost } from 'shared/api/hooks';
import { useStores } from 'store';
import { useSubPortals } from 'views/UiConfiguration/useSubPortals';

interface IProps {
  navDrawerOpen: boolean;
}

const SimulateSubPortalSetting: React.FC<IProps> = ({ navDrawerOpen }) => {
  const { authStore } = useStores();
  const { subPortals, isLoadingSubportals } = useSubPortals(navDrawerOpen);

  const [simulateSubPortal, isPostingSimulateSubPortal] = usePost(
    subportalAPI.impersonate
  );

  const handleChangeSubportal = async (subPortalAlias: string | null) => {
    const resp = await simulateSubPortal({
      params: { subPortalAlias },
    });
    if (resp.status === 200) {
      document.location.reload();
    } else {
      snackbar('Error setting subportal', { variant: 'error' });
    }
  };

  return (
    <ListItem>
      <ListItemIcon>
        <AtlasIcon type="Widgets" />
      </ListItemIcon>
      <ListItemText
        primary={
          <AutoComplete
            label="Subportal"
            value={authStore.activeSubPortalDomainAlias || null}
            options={subPortals || []}
            getOptionLabel={sub => sub.subPortalName}
            getOptionValue={sub => sub.domainAlias}
            onChange={handleChangeSubportal}
            isLoading={isLoadingSubportals || isPostingSimulateSubPortal}
            disabled={isPostingSimulateSubPortal || !subPortals}
            clearable
          />
        }
        secondary="Manually set a subportal to test its theme and features."
      />
    </ListItem>
  );
};

export default SimulateSubPortalSetting;
