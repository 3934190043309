import {
  Autocomplete,
  InputAdornment,
  ListItemIcon,
  Popper,
  TextField,
} from '@mui/material';
import AtlasIcon from 'components/AtlasIcon';
import LoadingSpinner from 'components/LoadingSpinner';
import OptionalTooltip from 'components/OptionalTooltip';
import { observer } from 'mobx-react-lite';
import React, {
  FocusEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { AtlasIconType } from 'shared/types/atlas-icon';
import { useStyles } from './AutoComplete.styles';

interface IProps<TOption, TValue extends string | number> {
  label: string;
  placeholder?: string;
  clearable?: boolean;
  blurOnSelect?: boolean;
  options: TOption[] | undefined;
  getOptionLabel: (option: TOption) => string;
  getOptionValue: (option: TOption) => TValue | null;
  getOptionIcon?: (option: TOption) => AtlasIconType;
  onChange: (value: TValue | null) => void;
  isLoading?: boolean;
  disabled?: boolean;
  dataCy?: string;
  value: TValue | null;
  required?: boolean;
  onBlur?: FocusEventHandler<any>;
  error?: boolean;
  helperText?: string | false;
  fullWidth?: boolean;
  dense?: boolean;
  groupBy?: (option: TOption) => string;
  getOptionDisabled?: (option: TOption) => boolean;
  customWidth?: boolean;
}

function AutoComplete<TOption extends object, TValue extends string | number>(
  props: IProps<TOption, TValue>
) {
  const {
    label,
    options,
    placeholder,
    getOptionLabel,
    onChange,
    clearable = false,
    required = false,
    blurOnSelect = false,
    error = false,
    isLoading,
    disabled,
    dataCy,
    value,
    getOptionValue,
    getOptionIcon,
    onBlur,
    helperText,
    fullWidth = false,
    dense = false,
    getOptionDisabled,
    groupBy,
    customWidth = false,
  } = props;
  const classes = useStyles(dense);
  const inputRef = useRef<HTMLInputElement>(null);
  const [requiresEllipsis, setRequiresEllipsis] = useState(false);
  const selectedOption =
    options?.find(opt => getOptionValue(opt) === value) || null;

  const isEllipsisActive = (ellipsisRef: React.RefObject<HTMLInputElement>) => {
    if (!ellipsisRef.current) {
      return false;
    }
    return ellipsisRef.current.clientWidth < ellipsisRef.current.scrollWidth;
  };

  useEffect(() => {
    setRequiresEllipsis(isEllipsisActive(inputRef));
  }, [inputRef.current?.value, inputRef.current?.scrollWidth]);

  const customPopperComponent = useCallback(
    popperProps => {
      const contentWidth = customWidth
        ? (popperProps?.anchorEl as HTMLElement).clientWidth
        : 'fit-content';
      return (
        <Popper
          {...popperProps}
          style={{
            width: contentWidth,
          }}
          placement="bottom-start"
        />
      );
    },
    [customWidth]
  );

  return (
    <Autocomplete
      PopperComponent={customPopperComponent}
      options={options || []}
      classes={classes}
      disableClearable={!clearable}
      blurOnSelect={blurOnSelect}
      loading={isLoading}
      onBlur={onBlur}
      autoComplete
      groupBy={groupBy}
      autoHighlight
      fullWidth={fullWidth}
      disabled={disabled}
      onChange={(_, newValue) =>
        onChange(newValue ? getOptionValue(newValue) : null)
      }
      getOptionDisabled={getOptionDisabled}
      id={dataCy}
      getOptionLabel={getOptionLabel}
      renderOption={(optionProps, option) => (
        <li {...optionProps} key={getOptionValue(option)}>
          {getOptionIcon && (
            <ListItemIcon className={classes.listItemIcon}>
              <AtlasIcon type={getOptionIcon(option)} size={18} />
            </ListItemIcon>
          )}
          {getOptionLabel(option)}
        </li>
      )}
      value={selectedOption}
      renderInput={params => {
        if (/\n/.test((params.inputProps as any).value)) {
          (params.inputProps as any).value = (
            params.inputProps as any
          ).value.replaceAll(/\n/g, ' ');
        }
        return (
          <OptionalTooltip
            enabled={requiresEllipsis}
            text={selectedOption ? getOptionLabel(selectedOption) : ''}
          >
            <TextField
              {...params}
              label={label}
              variant="outlined"
              inputRef={inputRef}
              InputProps={{
                ...params.InputProps,
                disabled: disabled,
                startAdornment:
                  getOptionIcon && selectedOption ? (
                    <InputAdornment
                      position="start"
                      className={classes.iconInputAdornment}
                    >
                      <AtlasIcon
                        type={getOptionIcon(selectedOption)}
                        size={18}
                      />
                    </InputAdornment>
                  ) : undefined,
                endAdornment: (
                  <>
                    {isLoading ? (
                      <LoadingSpinner
                        style={{ position: 'absolute', right: 40 }}
                        color="inherit"
                        size={18}
                      />
                    ) : null}
                    <InputAdornment
                      style={{ marginLeft: '10px' }}
                      position="end"
                    >
                      {params.InputProps.endAdornment}
                    </InputAdornment>
                  </>
                ),
              }}
              required={required}
              placeholder={placeholder}
              data-cy={dataCy}
              error={error}
              helperText={helperText}
            />
          </OptionalTooltip>
        );
      }}
    />
  );
}

export default observer(AutoComplete);
