import * as azureMaps from 'azure-maps-control';
import { Role } from 'components/Auth/Role';
import MessageOverlay from 'components/MessageOverlay';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'store';
import { useDataSourceData } from 'views/Dashboard/DataSources';
import { IDataSourceStore } from 'views/Dashboard/DataSources/dataSourceTypes';
import ImagePinLayer from '../ImageComponent/ImagePinLayer';
import { usePolicy } from '../NumberGridComponent/usePolicy';
import { IAzureMapsSettings } from './azureMapsConfig';
import { IAzureMapsViewModel } from './AzureMapsWidgetContainer';
import GeofenceLayer from './GeofenceLayer';
import GroupedMapContent from './GroupedMapContent';
import { useAutoZoom, useCurrentLocationData, useMapControls } from './hooks';
import { useCombinedFeatures } from './hooks/useCombinedFeatures';
import { ILocationDataSource } from './hooks/useCurrentLocationDataSource';
import { useCustomMapControl } from './IconButtonMapControl';
import { MapWidgetState } from './MapWidgetState';

interface IProps {
  settings: IAzureMapsSettings;
  map: azureMaps.Map;
  location: ILocationDataSource;
  detailsDataSource: IDataSourceStore;
  viewModel: IAzureMapsViewModel;
}

const AzureMapsContent: React.FC<IProps> = ({
  settings,
  map,
  location,
  detailsDataSource,
  viewModel,
}) => {
  const { t } = useTranslation('dashboard');
  const locationData = useCurrentLocationData(location, settings);
  const [showGeofenceVisibilityIcon, setShowGeofenceVisibilityIcon] =
    useState(false);
  const { authStore } = useStores();
  const showGeofences = authStore.hasRole(
    Role.RoleNameViewAutomationRuleTriggerGeofence
  );
  const policy = usePolicy(settings.policyId);
  const { data: detailsData, error } = useDataSourceData(detailsDataSource);

  useMapControls(
    {
      showGeofence: settings.controls.geofence,
      showStylePicker: settings.controls.stylePicker,
      showZoomButtons: settings.controls.zoomButtons,
    },
    map
  );

  useEffect(() => {
    if (map && settings.controls.geofence) {
      setShowGeofenceVisibilityIcon(true);
      return () => {
        setShowGeofenceVisibilityIcon(false);
      };
    } else {
      return undefined;
    }
  }, [map, settings.controls.geofence]);

  const [state] = useState(() => new MapWidgetState(map));

  useCustomMapControl(map, 'Restart', state.resetCamera);

  const features = useCombinedFeatures(
    locationData,
    viewModel,
    detailsData?.type === 'groupedData' ? detailsData.groups : undefined
  );

  useAutoZoom(map, features, state.setDefaultCameraPosition);

  if (
    detailsDataSource.lastReceivedData &&
    location.dataSource.lastReceivedData &&
    !features.length &&
    !showGeofences
  ) {
    return <MessageOverlay message={t('no_data')} />;
  }

  if (error) {
    return <MessageOverlay message={error} />;
  }

  // For now, only show the fancy new clustered HtmlMarkerLayer when settings have been updated
  const enableNewClusteredLayer = !!policy;

  return (
    <>
      {showGeofences && (
        <GeofenceLayer
          map={map}
          mapDataExists={features.length > 0}
          showGeofenceVisibilityIcon={showGeofenceVisibilityIcon}
        />
      )}

      {enableNewClusteredLayer ? (
        <GroupedMapContent
          settings={settings}
          features={features}
          map={map}
          state={state}
          viewModel={viewModel}
          data={detailsData || { type: 'groupedData', groups: [] }}
        />
      ) : (
        <ImagePinLayer
          features={features}
          getPopup={viewModel.getPopupContent}
          map={map}
        />
      )}
    </>
  );
};

export default observer(AzureMapsContent);
