import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  error: {
    fontSize: '.7rem',
    marginTop: theme.spacing(1),
    color: theme.palette.error.main,
  },
  fullWidth: {
    width: '100%',
  },
}));
