import {
  IntCreateDeviceRequestDto,
  IntCreateDocumentRequestDto,
  IntCreatePlaceRequestDto,
  IntCreateProfileRequestDto,
  IntCreateSupplierRequestDto,
  IntDocumentDto,
  IntPingResponseDto,
  IntPlaceDto,
  IntRealEstateItConnectedDeviceDto,
  IntRealEstateItConnectedDeviceTableDto,
  IntRealEstateItNetworkDto,
  IntRealEstateItProfileDto,
  IntRealEstateItServerDto,
  IntSiteDto,
  IntSupplierDto,
  IntSupplierTableDto,
  IntSwitchDto,
  IntUpdateDeviceRequestDto,
  IntUpdatePlaceRequestDto,
  IntUpdateProfileRequestDto,
  IntUpdateSupplierRequestDto,
} from 'generated';
import {
  DeleteEndpoint,
  GetEndpoint,
  PatchEndpoint,
  PostEndpoint,
  TableEndpoint,
} from 'shared/interfaces/api';
import { realEstateItDemoData } from './demoData/realEstateItDemoData';

interface RealEstateItAPI {
  getAll: (options: {
    customerId?: string;
    startDate?: Date;
    endDate?: Date;
  }) => TableEndpoint<IntRealEstateItConnectedDeviceTableDto>;
  getSuppliers: (options: {
    customerId?: string;
    startDate?: Date;
    endDate?: Date;
  }) => TableEndpoint<IntSupplierTableDto>;
  getSwitches: (options: {
    customerId?: string;
    startDate?: Date;
    endDate?: Date;
  }) => TableEndpoint<IntSwitchDto>;
  getProfiles: (options: {
    customerId?: string;
    startDate?: Date;
    endDate?: Date;
  }) => TableEndpoint<IntRealEstateItProfileDto>;
  getLocations: (options: {
    customerId?: string;
    startDate?: Date;
    endDate?: Date;
  }) => TableEndpoint<IntPlaceDto>;
  getSites: (options: {
    customerId?: string;
    startDate?: Date;
    endDate?: Date;
  }) => TableEndpoint<IntSiteDto>;
  getNetworks: (options: {
    customerId?: string;
    startDate?: Date;
    endDate?: Date;
  }) => TableEndpoint<IntRealEstateItNetworkDto>;
  getDocuments: (options: {
    customerId?: string;
    startDate?: Date;
    endDate?: Date;
  }) => TableEndpoint<IntDocumentDto>;
  getDocumentsByAsset: (assetId: number) => TableEndpoint<IntDocumentDto>;
  getServersBySupplier: (
    supplierId: number
  ) => TableEndpoint<IntRealEstateItServerDto>;
  getConnectedDevicesBySupplier: (
    supplierId: number
  ) => TableEndpoint<IntRealEstateItConnectedDeviceTableDto>;
  deleteAsset: DeleteEndpoint<number>;
  deleteLocation: DeleteEndpoint<number>;
  deleteProfile: DeleteEndpoint<number>;
  deleteDocument: DeleteEndpoint<number>;
  getDetails: GetEndpoint<string, IntRealEstateItConnectedDeviceDto>;
  getAssetPing: GetEndpoint<number, IntPingResponseDto>;
  getLocationDetails: GetEndpoint<number, IntPlaceDto>;
  getProfileDetails: GetEndpoint<number, IntRealEstateItProfileDto>;
  getSupplierDetails: GetEndpoint<number, IntSupplierDto>;
  create: PostEndpoint<undefined, IntCreateDeviceRequestDto>;
  update: PatchEndpoint<string, Partial<IntUpdateDeviceRequestDto>>;
  createLocation: PostEndpoint<undefined, IntCreatePlaceRequestDto>;
  updateLocation: PatchEndpoint<string, Partial<IntUpdatePlaceRequestDto>>;
  createSupplier: PostEndpoint<undefined, IntCreateSupplierRequestDto>;
  updateSupplier: PatchEndpoint<string, Partial<IntUpdateSupplierRequestDto>>;
  deleteSupplier: DeleteEndpoint<number>;
  createProfile: PostEndpoint<undefined, IntCreateProfileRequestDto>;
  createDocument: PostEndpoint<undefined, IntCreateDocumentRequestDto>;
  updateProfile: PatchEndpoint<string, Partial<IntUpdateProfileRequestDto>>;
}

const baseUrl = '/api/realestateit';

export const realEstateItAPI: RealEstateItAPI = {
  getAll: ({ customerId, startDate, endDate }) => ({
    url: `${baseUrl}/connecteddevice/table?customerId=${
      customerId || ''
    }&startDate=${startDate?.toISOString() || ''}&endDate=${
      endDate?.toISOString() || ''
    }`,
    mockData: realEstateItDemoData.getAll,
  }),
  deleteAsset: {
    url: assetId => `${baseUrl}/connecteddevice/${assetId}`,
    mockData: realEstateItDemoData.delete,
  },
  deleteLocation: {
    url: locationId => `${baseUrl}/place/${locationId}`,
    mockData: realEstateItDemoData.delete,
  },
  deleteProfile: {
    url: profileId => `${baseUrl}/profile/${profileId}`,
    mockData: realEstateItDemoData.delete,
  },
  deleteDocument: {
    url: documentId => `${baseUrl}/document/${documentId}`,
    mockData: realEstateItDemoData.delete,
  },
  getSuppliers: ({ customerId, startDate, endDate }) => ({
    url: `${baseUrl}/supplier/table?customerId=${customerId || ''}&startDate=${
      startDate?.toISOString() || ''
    }&endDate=${endDate?.toISOString() || ''}`,
    mockData: realEstateItDemoData.getSuppliers,
  }),
  getSwitches: ({ customerId, startDate, endDate }) => ({
    url: `${baseUrl}/switch/table?customerId=${customerId || ''}&startDate=${
      startDate?.toISOString() || ''
    }&endDate=${endDate?.toISOString() || ''}`,
    mockData: realEstateItDemoData.getSwitches,
  }),
  getProfiles: ({ customerId, startDate, endDate }) => ({
    url: `${baseUrl}/profile/table?customerId=${customerId || ''}&startDate=${
      startDate?.toISOString() || ''
    }&endDate=${endDate?.toISOString() || ''}`,
    mockData: realEstateItDemoData.getProfiles,
  }),
  getSites: ({ customerId, startDate, endDate }) => ({
    url: `${baseUrl}/site/table?customerId=${customerId || ''}&startDate=${
      startDate?.toISOString() || ''
    }&endDate=${endDate?.toISOString() || ''}`,
    mockData: realEstateItDemoData.getSites,
  }),
  getNetworks: ({ customerId, startDate, endDate }) => ({
    url: `${baseUrl}/networks/table?customerId=${customerId || ''}&startDate=${
      startDate?.toISOString() || ''
    }&endDate=${endDate?.toISOString() || ''}`,
    mockData: realEstateItDemoData.getNetworks,
  }),
  getDocuments: ({ customerId, startDate, endDate }) => ({
    url: `${baseUrl}/document/table?customerId=${customerId || ''}&startDate=${
      startDate?.toISOString() || ''
    }&endDate=${endDate?.toISOString() || ''}`,
    mockData: realEstateItDemoData.getDocuments,
  }),
  getDocumentsByAsset: assetId => ({
    url: `${baseUrl}/connecteddevice/${assetId}/documents`,
    mockData: realEstateItDemoData.getDocuments,
  }),
  getServersBySupplier: supplierId => ({
    url: `${baseUrl}/supplier/${supplierId}/servers`,
    mockData: realEstateItDemoData.getServers,
  }),
  getConnectedDevicesBySupplier: supplierId => ({
    url: `${baseUrl}/supplier/${supplierId}/connecteddevices`,
    mockData: realEstateItDemoData.getAll,
  }),
  getLocations: ({ customerId, startDate, endDate }) => ({
    url: `${baseUrl}/place/table?customerId=${customerId || ''}&startDate=${
      startDate?.toISOString() || ''
    }&endDate=${endDate?.toISOString() || ''}`,
    mockData: realEstateItDemoData.getLocations,
  }),
  getDetails: {
    url: id => `${baseUrl}/connecteddevice/${id}`,
    mockData: realEstateItDemoData.getAssetDetails,
  },
  getAssetPing: {
    url: id => `${baseUrl}/connecteddevice/${id}/ping`,
    mockData: realEstateItDemoData.getAssetPing,
  },
  getLocationDetails: {
    url: id => `${baseUrl}/place/${id}`,
    mockData: realEstateItDemoData.getLocationDetails,
  },
  getProfileDetails: {
    url: id => `${baseUrl}/profile/${id}`,
    mockData: realEstateItDemoData.getProfileDetails,
  },
  getSupplierDetails: {
    url: id => `${baseUrl}/supplier/${id}`,
    mockData: realEstateItDemoData.getSupplierDetails,
  },
  create: {
    url: `${baseUrl}/connecteddevice`,
    mockData: realEstateItDemoData.create,
  },
  createLocation: {
    url: `${baseUrl}/place`,
    mockData: realEstateItDemoData.create,
  },
  createProfile: {
    url: `${baseUrl}/profile`,
    mockData: realEstateItDemoData.create,
  },
  createDocument: {
    url: `${baseUrl}/document`,
    mockData: realEstateItDemoData.create,
  },
  update: {
    url: assetId => `${baseUrl}/connecteddevice/${assetId}`,
    mockData: realEstateItDemoData.update,
  },
  updateLocation: {
    url: locationId => `${baseUrl}/place/${locationId}`,
    mockData: realEstateItDemoData.update,
  },
  createSupplier: {
    url: `${baseUrl}/supplier`,
    mockData: realEstateItDemoData.create,
  },
  updateSupplier: {
    url: supplierId => `${baseUrl}/supplier/${supplierId}`,
    mockData: realEstateItDemoData.update,
  },
  deleteSupplier: {
    url: supplierId => `${baseUrl}/supplier/${supplierId}`,
    mockData: realEstateItDemoData.delete,
  },
  updateProfile: {
    url: profileId => `${baseUrl}/profile/${profileId}`,
    mockData: realEstateItDemoData.update,
  },
};
