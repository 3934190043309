import { AutomationRuleStatus } from 'automationcenter-api';
import { DashboardComponentType } from 'generated';
import { IDataSourceConfig } from '../../dataSourceTypes';

export interface IAutomationRuleStatusDataSourceSettings {
  type: 'automationRuleStatus';
}
export type IAutomationStatusCounts = Partial<
  Record<AutomationRuleStatus, number>
>;

export const automationRuleStatusConfig: IDataSourceConfig<IAutomationRuleStatusDataSourceSettings> =
  {
    icon: 'Coffee',
    settingsSteps: [],
    getDefaultSettings: async () => {
      return {
        type: 'automationRuleStatus',
      };
    },
    enabledComponentTypes: {
      [DashboardComponentType.PieChart]: true,
    },
    shouldShowDateFilter: false,
  };
