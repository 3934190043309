import { ConnectivityUnitStatus } from 'generated';
import i18n from 'i18next';
import { Column } from 'react-table';
import { stylingNames } from '../Columns.styles';
import { CustomCell } from '../Helpers/CustomCell';
import { ICustomCellProps } from '../Helpers/CustomCellProps';
import { getOptionFilter } from '../Helpers/getOptionFilter';

export function euiccStatus<T>(options: {
  id: string;
  accessor: (row: T) => ConnectivityUnitStatus;
  header?: string;
  defaultShow?: boolean;
  filterable?: boolean;
  width?: number;
}): Column<T> {
  const {
    id,
    header = i18n.t('common:status.base'),
    accessor,
    defaultShow: show = true,
    filterable,
    width = 140,
  } = options;

  const cellByStatus: Record<ConnectivityUnitStatus, ICustomCellProps> = {
    [ConnectivityUnitStatus.Unknown]: {
      iconType: 'StatusWarning',
      variant: 'warning',
      text: i18n.t(
        `sim:connectivity_unit.status.${ConnectivityUnitStatus.Unknown}`
      ),
    },
    [ConnectivityUnitStatus.Working]: {
      iconType: 'StatusOk',
      variant: 'success',
      text: i18n.t(
        `sim:connectivity_unit.status.${ConnectivityUnitStatus.Working}`
      ),
    },
    [ConnectivityUnitStatus.TemporaryBlock]: {
      iconType: 'StatusWarning',
      variant: 'warning',
      text: i18n.t(
        `sim:connectivity_unit.status.${ConnectivityUnitStatus.TemporaryBlock}`
      ),
    },
    [ConnectivityUnitStatus.PermanentBlock]: {
      iconType: 'StatusError',
      variant: 'error',
      text: i18n.t(
        `sim:connectivity_unit.status.${ConnectivityUnitStatus.PermanentBlock}`
      ),
    },
    [ConnectivityUnitStatus.Updating]: {
      iconType: 'StatusPending',
      variant: 'unconfigured',
      text: i18n.t(
        `sim:connectivity_unit.status.${ConnectivityUnitStatus.Updating}`
      ),
    },
  };

  return {
    id,
    Header: header,
    accessor,
    className: stylingNames.textCenter,
    resizable: true,
    maxWidth: 140,
    width,
    show,
    filterable,
    Cell: row => {
      const iconProps = cellByStatus[row.value as ConnectivityUnitStatus] || {};
      return <CustomCell {...iconProps} index={row.index} />;
    },
    Filter: getOptionFilter([
      {
        label: i18n.t('sim:label.status_all'),
        value: '',
      },
      {
        label: i18n.t(
          `sim:connectivity_unit.status.${ConnectivityUnitStatus.Unknown}`
        ),
        value: ConnectivityUnitStatus.Unknown,
      },
      {
        label: i18n.t(
          `sim:connectivity_unit.status.${ConnectivityUnitStatus.Working}`
        ),
        value: ConnectivityUnitStatus.Working,
      },
      {
        label: i18n.t(
          `sim:connectivity_unit.status.${ConnectivityUnitStatus.TemporaryBlock}`
        ),
        value: ConnectivityUnitStatus.TemporaryBlock,
      },
      {
        label: i18n.t(
          `sim:connectivity_unit.status.${ConnectivityUnitStatus.PermanentBlock}`
        ),
        value: ConnectivityUnitStatus.PermanentBlock,
      },
      {
        label: i18n.t(
          `sim:connectivity_unit.status.${ConnectivityUnitStatus.Updating}`
        ),
        value: ConnectivityUnitStatus.Updating,
      },
    ]),
  };
}
