import { IntEventState } from 'generated';
import i18n from 'i18next';
import { Column } from 'react-table';
import { stylingNames } from '../Columns.styles';
import { CustomCell } from '../Helpers/CustomCell';
import { ICustomCellProps } from '../Helpers/CustomCellProps';
import { getOptionFilter } from '../Helpers/getOptionFilter';

export function eventState<T>(options: {
  id: string;
  accessor: (row: T) => IntEventState;
  header?: string;
  show?: boolean;
}): Column<T> {
  const { id, header = 'State', accessor, show } = options;
  const eventStateCellProps: Record<IntEventState, ICustomCellProps> = {
    0: {
      iconType: 'StatusUnknown',
      variant: 'default',
      text: i18n.t('sim:event_state.0'),
    },
    1: {
      iconType: 'StatusPending',
      variant: 'default',
      text: i18n.t('sim:event_state.1'),
    },
    2: {
      iconType: 'StatusOk',
      variant: 'success',
      text: i18n.t('sim:event_state.2'),
    },
    3: {
      iconType: 'StatusError',
      variant: 'error',
      text: i18n.t('sim:event_state.3'),
    },
  };
  return {
    id,
    Header: header,
    accessor,
    className: stylingNames.textCenter,
    width: 100,
    show,
    Filter: getOptionFilter([
      {
        label: i18n.t('all'),
        value: '',
      },
      ...Object.keys(IntEventState)
        .filter(v => isNaN(Number(v)))
        .map(code => ({
          label: i18n.t(`sim:event_state.${IntEventState[code as any]}`),
          value: IntEventState[code as any],
        })),
    ]),
    Cell: row => {
      const cellProps = eventStateCellProps[row.value as IntEventState] || {};
      return <CustomCell {...cellProps} />;
    },
  };
}
