import { Grid, Skeleton } from '@mui/material';
import { Box } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useDashboardContext } from 'views/Dashboard/contexts/dashboardContext';
import { IDashboardComponent } from 'views/Dashboard/dashboardTypes';
import { useDataSource, useDataSourceData } from 'views/Dashboard/DataSources';
import { Control } from 'views/Dashboard/SettingControls';
import { makeTableViewModel } from '../../TableComponent/makeTableViewModel';
import TableColumnSettings from '../../TableComponent/SettingsSteps/TableColumnSettings';
import { IAzureMapsSettings } from '../azureMapsConfig';
import MapTable from '../MapTable';

const MapTableSettingsStep: IDashboardComponent<IAzureMapsSettings> = ({
  component,
}) => {
  const { settings } = component;
  const dataSource = useDataSource(settings.dataSource);
  const { t } = useTranslation('dashboard');
  const dashboard = useDashboardContext();

  const tableVm = dataSource.getDataProps(
    makeTableViewModel({
      columns: settings.tableColumns,
      dashboard,
      dataSourceSettings: settings.dataSource,
      pivotBy: settings.groupProperties,
      policyId: settings.policyId,
    })
  );

  const { data, error, noContent } = useDataSourceData(dataSource);

  return (
    <div>
      <Box height={400}>
        {data || !!error || noContent ? (
          <MapTable
            tableViewModel={tableVm}
            data={data || { type: 'groupedData', groups: [] }}
          />
        ) : (
          <Skeleton />
        )}
      </Box>
      <Grid container spacing={4}>
        <Grid item sm={4}>
          <Control.Checkbox
            object={settings}
            name="showTable"
            label={t('azure_maps.show_table')}
          />
        </Grid>
        <Grid item sm={8}>
          <TableColumnSettings
            columns={settings.tableColumns}
            dataSource={dataSource}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default observer(MapTableSettingsStep);
