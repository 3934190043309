import {
  AssetState,
  ConnectedStatus,
  IntCustomerProfileDto,
  IntCustomerTableDto,
  IntEditCustomerProfileDto,
  IntSearchDto,
} from 'generated';
import { paginate, rnd } from 'utils';

class CustomerDemoData {
  private _userData: IntCustomerProfileDto = {
    countryName: 'Sweden',
    customerName: rnd.customerName(),
    dealerId: rnd.guid(),
    dealerName: rnd.company(),
    customerLogo: '',
    orgNumber: '361208-1432',
    customerId: rnd.guid(),
    email: rnd.email(),
    phoneNumber: rnd.phoneNumber(),
    city: 'Solna',
    zipCode: rnd.int(11111, 99999).toString(),
    address: 'Stjärntorget 1',
    created: rnd.date.past(),
    customerLanguages: ['en-US', 'sv-SE'],
    sharedAssets: [
      {
        assetId: '7dc6367d-fa08-4d96-9bfc-78a1bbe60a30',
        customerId: 'e2bf95ca-da3c-47d1-98b4-b999680d2bba',
        asset: {
          assetId: '1',
          displayName: 'Asset 1',
          serialNumber: 'SN12345',
          imei: 'IMEI67890',
          connected: ConnectedStatus.Connected,
          assetTypeId: '123',
          dealerId: '456',
          customerId: '789',
          customerName: 'Customer A',
          make: 'Make A',
          model: 'Model X',
          type: 'Type 1',
          lastConnected: new Date('2023-10-15T12:00:00'),
          installationDate: new Date('2023-01-20T09:30:00'),
          address: '123 Main St, City A',
          serviceMode: false,
          referenceLabel: 'Label 1',
          state: AssetState.Active,
          assetAttributes: {
            attribute1: 'Value A',
            attribute2: 42,
            attribute3: true,
          },
        },
      },
    ],
  };

  getAll = (params?: IntSearchDto) => {
    const randData = rnd.array<IntCustomerTableDto>(70, i => ({
      customerId: rnd.guid().toString(),
      customerName: rnd.customerName(),
      created: rnd.date.past(),
    }));

    return {
      status: 200,
      data: paginate(randData, params),
    };
  };
  getLinks = (customerId: string) => ({
    status: 200,
    data: [
      {
        customerLinkId: '1234',
        customerId,
        linkProvider: 0,
        customerLinkProviderId: '270d83ff9d8efd69cce8f0ef28657a57',
      },
    ],
  });

  updateLinks = () => ({ status: 200 });

  getCustomerInfo = () => {
    return {
      status: 200,
      data: this._userData,
    };
  };

  updateCustomerInfo = (updatedInfo?: IntEditCustomerProfileDto) => {
    if (updatedInfo) {
      this._userData = {
        ...this._userData,
        ...updatedInfo,
        ...{ customerLogo: updatedInfo.imageData },
      };
    }

    return {
      status: 200,
    };
  };
}

export const customerDemoData = new CustomerDemoData();
