import { ButtonBase } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';

interface IProps {
  onClick?: () => void;
}

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      textDecoration: 'underline',
      cursor: 'pointer',
      color: theme.palette.primary.main,
    },
  })
);

const LinkButton: React.FC<IProps> = ({ onClick, children }) => {
  const classes = useStyles();

  return (
    <ButtonBase className={classes.root} onClick={onClick}>
      {children}
    </ButtonBase>
  );
};

export default LinkButton;
