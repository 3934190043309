import {
  IDataSourceResponse,
  IDataSourceStore,
  IWidgetData,
} from 'views/Dashboard/DataSources/dataSourceTypes';
import { dataProp } from './';
import { MutateFn } from './MutationContext';

export function useMutateFn(
  dataSource: IDataSourceStore,
  setResponse: React.Dispatch<
    React.SetStateAction<IDataSourceResponse | undefined>
  >
): MutateFn {
  return changes => {
    const idDataProp = dataSource.dataProperties.find(dp => dp.isUniqueId);

    const idToUpdate = idDataProp && dataProp.get(idDataProp, changes);

    if (!idDataProp || !idToUpdate) {
      return;
    }

    const patchData = (data: IWidgetData): IWidgetData => {
      if (data.type === 'list') {
        return {
          ...data,
          items: data.items.map(item => {
            const itemId = dataProp.get(idDataProp, item);
            if (itemId === idToUpdate) {
              return changes;
            }
            return item;
          }),
        };
      }
      return data;
    };

    setResponse(resp => {
      if (resp?.type === 'success') {
        return {
          ...resp,
          data: patchData(resp.data),
        };
      }
      return resp;
    });
  };
}
