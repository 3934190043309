import { IntSearchDto, IntSubPortalTableDto, SubPortalType } from 'generated';
import { paginate, rnd } from 'utils';

class SubPortalDemoData {
  getAll = (params?: IntSearchDto) => {
    const randData = rnd.array<IntSubPortalTableDto>(70, i => ({
      subPortalName: `Subportal ${i + 1}`,
      subPortalId: `subPortal${i}`,
      domainAlias: `domainAlias${i}`,
      type: SubPortalType.Atlas,
      dealerId: rnd.guid(),
      dealerName: rnd.company(),
    }));

    return {
      status: 200,
      data: paginate(randData, params),
    };
  };
}

export const subPortalDemoData = new SubPortalDemoData();
