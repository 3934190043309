import {
  IntAttributeDto,
  IntBulkSubscriptionStatusResultDto,
  IntChangeLabelDto,
  IntConnectivityUnitAccessDetailsDto,
  IntConnectivityUnitCountByStatusRequestDto,
  IntConnectivityUnitDto,
  IntConnectivityUnitStatusCountDto,
  IntConnectivityUnitTableBulkSelectFilterDto,
  IntConnectivityUnitTableDto,
  IntConnectivityUnitTableRequestDto,
  IntIpAllocationType,
  IntLocaleDto,
  IntSubscriptionStatusRequest,
  IntUpdateCustomerDetailsDto,
} from 'generated';
import { connectivityUnitDemoData } from 'services/demoData/connectivityUnitDemoData';
import {
  DeleteEndpoint,
  GetEndpoint,
  PostEndpoint,
  PostTableEndpoint,
  PutEndpoint,
  TableEndpoint,
} from 'shared/interfaces/api';
import {
  AssignBootstrap,
  SubscriptionAssign,
} from 'views/Connectivity/Sim/Details/SIMSubscriptions/SubscriptionActionWizard/SubscriptionActionWizard';
import { cdrSessionDemoData } from './demoData/cdrSessionDemoData';

const baseUrl = '/api/connectivityunits';

export type TPropertyName = 'homeNetwork' | 'localized' | 'production';

type IConnectivityUnitAPI = {
  getAll: (options: {
    customerId?: string;
    startDate?: Date;
    endDate?: Date;
  }) => TableEndpoint<IntConnectivityUnitTableDto>;
  getAllPost: (options: {
    customerId?: string;
    startDate?: Date;
    endDate?: Date;
  }) => PostTableEndpoint<
    IntConnectivityUnitTableRequestDto,
    IntConnectivityUnitTableDto
  >;
  getUnitsInGroup: (options: {
    resourceGroupId: string;
    startDate?: Date;
    endDate?: Date;
  }) => TableEndpoint<IntConnectivityUnitTableDto>;
  getAllConnectivityUnitsByCustomerId: (
    customerId: string
  ) => TableEndpoint<IntConnectivityUnitTableDto>;
  getDetails: GetEndpoint<string, IntConnectivityUnitDto>;
  getLocales: GetEndpoint<IGetLocalesParams, IntLocaleDto[]>;
  downloadLocale: PostEndpoint<SubscriptionAssign>;
  activateLocale: PostEndpoint<
    { connectivityUnitId: string; localeId: string },
    SubscriptionAssign
  >;
  activateBootstrap: PostEndpoint<string, AssignBootstrap>;
  setSubscriptionStatus: PostEndpoint<{
    connectivityUnitId: string;
    targetStatus: IntSubscriptionStatusRequest;
  }>;
  getApnHubAccessDetails: GetEndpoint<
    string,
    IntConnectivityUnitAccessDetailsDto
  >;
  setBulkSubscriptionStatus: PostEndpoint<
    IntSubscriptionStatusRequest,
    string[],
    IntBulkSubscriptionStatusResultDto
  >;
  terminateSession: DeleteEndpoint<string>;
  setLabel: PostEndpoint<string, IntChangeLabelDto>;
  setIpAllocation: PostEndpoint<
    {
      customerId: string;
      connectivityUnitId: string;
      intIpAllocationType: IntIpAllocationType;
    },
    string
  >;
  getStatus: PostEndpoint<
    undefined,
    IntConnectivityUnitCountByStatusRequestDto,
    IntConnectivityUnitStatusCountDto[]
  >;
  getCSV: PostEndpoint<
    IntConnectivityUnitTableBulkSelectFilterDto,
    string[],
    BlobPart
  >;
  updateCustomerId: PostEndpoint<string, IntUpdateCustomerDetailsDto>;
  getLocalesInUse: GetEndpoint<undefined, string[]>;
  getProperties: GetEndpoint<TPropertyName, string[]>;
  upsertConnectivityUnitAttributeValue: PutEndpoint<
    { connectivityUnitId: string; customAttribute: IntAttributeDto },
    IntAttributeDto,
    IntAttributeDto
  >;
  deleteCustomAttribute: DeleteEndpoint<string>;
};

export type IGetLocalesParams = {
  connectivityUnitId: string;
  notDownloadedOnly: boolean;
};

export const connectivityUnitAPI: IConnectivityUnitAPI = {
  getDetails: {
    url: id => `${baseUrl}/${id}`,
    mockData: connectivityUnitDemoData.getUnitDetails,
  },
  getLocales: {
    url: ({ connectivityUnitId, notDownloadedOnly }) =>
      `${baseUrl}/${connectivityUnitId}/locales?notDownloadedOnly=${notDownloadedOnly}`,
    mockData: connectivityUnitDemoData.getLocales,
  },
  getAll: ({ customerId, startDate, endDate }) => ({
    url: `${baseUrl}/table?customerId=${customerId || ''}&startDate=${
      startDate?.toISOString() || ''
    }&endDate=${endDate?.toISOString() || ''}`,
    mockData: connectivityUnitDemoData.getConnectivityUnitTable,
  }),
  getAllPost: ({ customerId, startDate, endDate }) => ({
    url: `${baseUrl}/table?customerId=${customerId || ''}`,
    mockData: connectivityUnitDemoData.getConnectivityUnitTablePost,
  }),
  getUnitsInGroup: ({ resourceGroupId, startDate, endDate }) => ({
    url: `${baseUrl}/table/group/${resourceGroupId}?startDate=${startDate?.toISOString()}&endDate=${endDate?.toISOString()}`,
    mockData: connectivityUnitDemoData.getUnitsInGroupTable(resourceGroupId),
  }),
  getAllConnectivityUnitsByCustomerId: customerId => ({
    url: `${baseUrl}/table/customer/${customerId}/`,
    mockData: connectivityUnitDemoData.getConnectivityUnitTable,
  }),
  downloadLocale: {
    url: params =>
      `${baseUrl}/${params.connectivityUnitId}/locales/download?localeId=${params.localeId}`,
    mockData: connectivityUnitDemoData.downloadLocale,
  },
  activateLocale: {
    url: params =>
      `${baseUrl}/${params.connectivityUnitId}/locales/activate?localeId=${params.localeId}`,
    mockData: connectivityUnitDemoData.activateLocale,
  },
  activateBootstrap: {
    url: connectivityUnitId =>
      `${baseUrl}/${connectivityUnitId}/locales/bootstrap`,
    mockData: connectivityUnitDemoData.activateBootstrap,
  },
  setSubscriptionStatus: {
    url: ({ connectivityUnitId, targetStatus }) =>
      `${baseUrl}/${connectivityUnitId}/subscription/${targetStatus}`,
    mockData: connectivityUnitDemoData.setSubscriptionStatus,
  },
  setBulkSubscriptionStatus: {
    url: targetStatus => `${baseUrl}/subscriptions/${targetStatus}`,
    mockData: connectivityUnitDemoData.bulkSetSubscriptionStatus,
  },
  getApnHubAccessDetails: {
    url: connectivityUnitId => `${baseUrl}/${connectivityUnitId}/accessDetails`,
    mockData: cdrSessionDemoData.getActiveSession,
  },
  terminateSession: {
    url: sessionId => `${baseUrl}/TerminateSession/${sessionId}`,
    mockData: () => ({ status: 200 }),
  },
  setLabel: {
    url: connectivityUnitId => `${baseUrl}/${connectivityUnitId}/label`,
    mockData: connectivityUnitDemoData.setLabel,
  },
  setIpAllocation: {
    url: ({ customerId, connectivityUnitId, intIpAllocationType }) =>
      `${baseUrl}/${customerId}/setipallocation/${connectivityUnitId}/${intIpAllocationType}`,
    mockData: () => ({ status: 200 }),
  },
  getStatus: {
    url: `${baseUrl}/statusCount`,
    mockData: connectivityUnitDemoData.getStatusDonutChart,
  },
  getCSV: {
    url: ({ startDate, endDate, orderBy, orderDesc }) =>
      `${baseUrl}/table/bulk/select?startDate=${startDate?.toISOString()}&endDate=${endDate?.toISOString()}&orderBy=${orderBy}&orderDesc=${orderDesc}`,
    mockData: (_, rows) => connectivityUnitDemoData.getCSVData(rows),
  },
  updateCustomerId: {
    url: connectivityUnitId =>
      `${baseUrl}/${connectivityUnitId}/updateCustomerId`,
    mockData: () => ({ status: 200 }),
  },
  getLocalesInUse: {
    url: `${baseUrl}/localesInUse`,
    mockData: connectivityUnitDemoData.getLocalesInUse,
  },
  getProperties: {
    url: propertyName =>
      `${baseUrl}/filterOptions?propertyName=${propertyName}`,
    mockData: connectivityUnitDemoData.getProperties,
  },
  upsertConnectivityUnitAttributeValue: {
    url: ({ connectivityUnitId }) =>
      `${baseUrl}/${connectivityUnitId}/attributes`,
    mockData: ({ customAttribute }) =>
      connectivityUnitDemoData.upsertCustomAttribute(customAttribute),
  },
  deleteCustomAttribute: {
    url: customAttributeValueId =>
      `${baseUrl}/attributes/${customAttributeValueId}`,
    mockData: customAttributeValueId =>
      connectivityUnitDemoData.deleteCustomAttribute(customAttributeValueId),
  },
};
