import { IntAutomationActionType } from 'generated';
import i18n from 'i18next';
import { Column } from 'react-table';
import ColArrayValue from '../Helpers/ColArrayValue';
import { getOptionFilter } from '../Helpers/getOptionFilter';

export function automationActionTypes<T>(options: {
  id: string;
  accessor: (row: T) => IntAutomationActionType[];
  header?: string;
  defaultShow?: boolean;
  filterable?: boolean;
}): Column<T> {
  const { id, header, accessor, defaultShow: show, filterable } = options;

  return {
    id,
    Header: header,
    accessor,
    resizable: false,
    filterable,
    sortable: false,
    show,
    Cell: row => {
      return row.value.length ? (
        <ColArrayValue
          value={row.value.map((v: IntAutomationActionType) =>
            i18n.t(`automation:subscription.action_types.${v}`)
          )}
          type="string"
        />
      ) : (
        <span className="text-muted">
          {i18n.t('not_applicable_abbreviation')}
        </span>
      );
    },
    Filter: getOptionFilter([
      {
        label: i18n.t('all'),
        value: '',
      },
      {
        label: i18n.t(
          `automation:action.types.${IntAutomationActionType.SendEmail}`
        ),
        value: IntAutomationActionType.SendEmail,
      },
      {
        label: i18n.t(
          `automation:action.types.${IntAutomationActionType.SendSms}`
        ),
        value: IntAutomationActionType.SendSms,
      },
      {
        label: i18n.t(
          `automation:action.types.${IntAutomationActionType.CreateEvent}`
        ),
        value: IntAutomationActionType.CreateEvent,
      },
    ]),
  };
}
