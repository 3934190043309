import { makeAutoObservable } from 'mobx';
import {
  PageChangeFunction,
  PageSizeChangeFunction,
  SortedChangeFunction,
  SortingRule,
} from 'react-table';

export class TableWidgetState {
  constructor(defaultSort?: SortingRule) {
    makeAutoObservable(this);

    this.tableProps = {
      page: 0,
      pageSize: 50,
      orderBy: defaultSort?.id || '',
      orderDesc: defaultSort?.desc || false,
    };
  }

  search = '';
  tableProps: {
    page: number;
    pageSize: number;
    orderBy: string;
    orderDesc: boolean;
  };

  get sorted(): SortingRule[] {
    if (this.tableProps.orderBy) {
      return [{ id: this.tableProps.orderBy, desc: this.tableProps.orderDesc }];
    }
    return [];
  }

  handlePageChange: PageChangeFunction = newPage => {
    this.tableProps.page = newPage;
  };

  handlePageSizeChange: PageSizeChangeFunction = (newPageSize, newPage) => {
    this.tableProps.pageSize = newPageSize;
    this.tableProps.page = newPage;
  };

  handleSortedChange: SortedChangeFunction = newSorted => {
    const newSortRule: SortingRule = newSorted.length
      ? newSorted[0]
      : { id: '', desc: false };
    this.tableProps.orderBy = newSortRule.id;
    this.tableProps.orderDesc = newSortRule.desc;
  };

  setSearch = (newSearch: string) => {
    this.search = newSearch;
  };
}
