import { CancelToken } from 'axios';
import { snackbar } from 'components/Snackbar';
import { differenceInCalendarDays } from 'date-fns';
import {
  AnalyticsCubeFilterType,
  AnalyticsCubeGroupingType,
  AnalyticsCubeParameterType,
  AnalyticsCubeRegisterDateGroupingResolution,
  IntAnalyticsCubeDataSpecificationRequestDto,
  IntAnalyticsCubeFilterDto,
  IntAnalyticsCubeGroupingDto,
  IntServiceDataSpecificationColumnDto,
  IntServiceDataSpecificationFilterDto,
  ServiceDataFilterType,
  ServiceDataSpecificationColumnType,
} from 'generated';
import i18n from 'i18n';
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { analyticsCubeApi } from 'services/analyticsCube.service';
import { IGroupedData } from 'services/service.service';
import { Dashboard } from 'store/domains/dashboard';
import { StoreBase } from 'store/storeBase';
import { IDateRange } from 'views/Reports/DateRangeFilter/DateRangeFilter';
import { IDataProperty } from '../dataPropTypes';
import {
  DataPropsGetter,
  IDataSourceResponse,
  IDataSourceStore,
} from '../dataSourceTypes';
import { getDataProp } from '../getDataProp';
import { IAnalyticsCubeDataSourceSettings } from './analyticsCubeConfig';

export interface ICubeData {
  group: Date | string;
  value: number;
}

export class AnalyticsCubeDataSource
  extends StoreBase
  implements IDataSourceStore<IAnalyticsCubeDataSourceSettings>
{
  @observable.ref settings: IAnalyticsCubeDataSourceSettings;
  @observable isInitialized = false;
  @observable lastReceivedData: Date | undefined;

  dashboard: Dashboard;

  constructor(
    dashboard: Dashboard,
    settings: IAnalyticsCubeDataSourceSettings
  ) {
    super(dashboard.rootStore);
    makeObservable(this);

    this.dashboard = dashboard;
    this.settings = settings;
  }

  @computed get analyticsCube() {
    const { analyticsCubeId } = this.settings;
    return this.rootStore.dashboardStore.analyticsCubes.find(
      cube => cube.analyticsCubeId === analyticsCubeId
    );
  }

  @computed get groupInfo(): IntAnalyticsCubeGroupingDto | null {
    return this.analyticsCube?.groupings &&
      this.analyticsCube?.groupings.length > 0
      ? this.analyticsCube?.groupings[0]
      : null;
  }

  getXAxisType() {
    let xAxisType: 'date' | 'asset';

    const assetGroup = this.analyticsCube?.groupings.find(
      g => g.type === AnalyticsCubeGroupingType.Asset
    );

    if (assetGroup) {
      xAxisType = 'asset';
    } else {
      xAxisType = 'date';
    }
    return xAxisType;
  }

  // This can be improved - many lessons learned from v1
  @computed get dataProperties(): IDataProperty[] {
    const dps: IDataProperty[] = [];

    const xAxisType = this.getXAxisType();

    const xDataProp: IDataProperty<ICubeData> = {
      id: `group`,
      category: i18n.t('dashboard:data.properties'),
      _get: data => data.group as any,
      type: xAxisType === 'date' ? 'dateTime' : 'string',
      name: xAxisType === 'date' ? 'Date' : '',
    };
    dps.push(xDataProp);

    this.analyticsCube?.aggregates.forEach(agg => {
      const yDataProp: IDataProperty<Record<string, number>> = {
        id: `${agg.analyticsCubeAggregateId}_value`,
        category: i18n.t('dashboard:data.properties'),
        type: 'number',
        _get: data => data['value'],
        name: agg.displayName,
        unit: agg.unit,
        min: agg.valueMin,
        max: agg.valueMax,
      };
      dps.push(yDataProp);
    });

    return dps;
  }

  @computed get groupProperties(): IDataProperty[] {
    return (
      this.analyticsCube?.aggregates.map(agg => ({
        type: 'string',
        category: {
          displayName: i18n.t(
            'dashboard:intelligence_events.properties.category_header'
          ),
        },
        isDefaultDisplayName: true,
        _get: data => data.displayName,
        id: `${agg.analyticsCubeAggregateId}_name`,
        name: agg.displayName,
        unit: agg.unit,
      })) || []
    );
  }

  @action.bound async initialize() {
    if (this.isInitialized) {
      return;
    }
    const { analyticsCubeId } = this.settings;
    const { dashboardStore } = this.rootStore;

    await dashboardStore.getAnalyticsCube(analyticsCubeId);

    // This timeout, ugly as it is, prevents a critical error that aborts the first request right as the cube is loaded.
    // As useDataSourceData listens to dataSource.isInitialized AND depstring, it seems to runs twice on initial load, and somehow both calls cancel eath other (sweet).
    // Todo: make more elegant
    window.setTimeout(() => {
      runInAction(() => {
        this.isInitialized = true;
      });
    }, 10);
  }

  getFilter(filterId?: string): IntAnalyticsCubeFilterDto | undefined {
    if (!filterId) {
      return;
    }
    return this.analyticsCube?.filters.find(
      filter => filter.analyticsCubeFilterId === filterId
    );
  }

  getServiceFilterValue = (serviceFilterType: ServiceDataFilterType) => {
    const { filterState } = this.dashboard;

    return (
      filterState.assetFilterSpecDtos
        .find(t => t.type === serviceFilterType)
        ?.values.join('||') || ''
    );
  };

  getFilterValue = (
    filterId: string,
    from: Date | null,
    to: Date | null
  ): string => {
    const filter = this.getFilter(filterId);
    if (!filter) {
      return '';
    }

    switch (filter.type) {
      case AnalyticsCubeFilterType.RegisterDateStart:
        return `${from?.getTime() || ''}`;

      case AnalyticsCubeFilterType.RegisterDateEnd:
        return `${to?.getTime() || ''}`;

      case AnalyticsCubeFilterType.Asset:
        return this.getServiceFilterValue(ServiceDataFilterType.AssetId);

      case AnalyticsCubeFilterType.AssetAddress:
        return this.getServiceFilterValue(ServiceDataFilterType.Address);

      case AnalyticsCubeFilterType.AssetBuilding:
        return this.getServiceFilterValue(ServiceDataFilterType.Building);

      case AnalyticsCubeFilterType.AssetCity:
        return this.getServiceFilterValue(ServiceDataFilterType.City);

      case AnalyticsCubeFilterType.AssetCustomer:
        return this.getServiceFilterValue(ServiceDataFilterType.CustomerId);

      case AnalyticsCubeFilterType.AssetFloor:
        return this.getServiceFilterValue(ServiceDataFilterType.Floor);

      case AnalyticsCubeFilterType.AssetGroup:
        return this.getServiceFilterValue(
          ServiceDataFilterType.ResourceGroupId
        );

      case AnalyticsCubeFilterType.AssetMaintenanceUnit:
        return this.getServiceFilterValue(
          ServiceDataFilterType.MaintenanceUnit
        );

      case AnalyticsCubeFilterType.AssetMake:
        return this.getServiceFilterValue(ServiceDataFilterType.Make);

      case AnalyticsCubeFilterType.AssetModel:
        return this.getServiceFilterValue(ServiceDataFilterType.Model);

      case AnalyticsCubeFilterType.AssetSubSpace:
        return this.getServiceFilterValue(ServiceDataFilterType.SubSpace);

      case AnalyticsCubeFilterType.AssetType:
        return this.getServiceFilterValue(ServiceDataFilterType.Type);

      case AnalyticsCubeFilterType.AssetReferenceLabel:
      case AnalyticsCubeFilterType.AssetDealer:
      case AnalyticsCubeFilterType.Service:
      case AnalyticsCubeFilterType.ServiceProperty:
      default:
        return ''; // Not yet implemented
    }
  };

  // Returns the applicable AnalyticsCubeParameters and their values, as well as ServiceDataFilters in case the cube is missing asset filter parameters
  getParameterValues(): {
    parameterValues: Record<string, string>;
    filters: IntServiceDataSpecificationFilterDto[];
  } {
    const parameterValues: Record<string, string> = {};

    if (!this.analyticsCube) {
      return { parameterValues, filters: [] };
    }

    const { filterState } = this.dashboard;
    const { parameters } = this.analyticsCube;
    const { assetFilterSpecDtos } = filterState;
    const dateFilters = filterState.getDateFilters();

    const filters: IntServiceDataSpecificationFilterDto[] = [
      ...assetFilterSpecDtos,
    ];

    if (dateFilters.from) {
      filters.push({
        type: ServiceDataFilterType.RegisterDateStart,
        values: [dateFilters.from.toISOString()],
      });
    }

    if (dateFilters.to) {
      filters.push({
        type: ServiceDataFilterType.RegisterDateEnd,
        values: [dateFilters.to.toISOString()],
      });
    }

    parameters.forEach(param => {
      const { type, analyticsCubeParameterId, filterId } = param;
      let filterValue = '';

      if (type === AnalyticsCubeParameterType.Filter && filterId) {
        filterValue = this.getFilterValue(
          filterId,
          dateFilters.from,
          dateFilters.to
        );
      } else if (type === AnalyticsCubeParameterType.Grouping) {
        const {
          rootStore: {
            layoutStore: { getGlobalDateFilters },
          },
        } = this.dashboard;

        filterValue = getResolutionFromDateRange(
          getGlobalDateFilters()
        ).toString();
      }

      if (filterValue) {
        parameterValues[analyticsCubeParameterId] = filterValue;
      }
    });

    return { parameterValues, filters };
  }

  async getData(opts?: {
    lowResolution?: boolean;
    cancelToken?: CancelToken;
  }): Promise<IDataSourceResponse> {
    const { analyticsCubeId } = this.settings;
    const { filters, parameterValues } = this.getParameterValues();

    const tempColumns: IntServiceDataSpecificationColumnDto[] = [
      {
        columnType: ServiceDataSpecificationColumnType.AssetMetaData,
        value: 'AssetDetailsAssetName',
      },
      {
        columnType: ServiceDataSpecificationColumnType.AssetMetaData,
        value: 'AssetDetailsAssetId',
      },
    ];

    const bodyParameters: IntAnalyticsCubeDataSpecificationRequestDto = {
      columns: tempColumns,
      parameters: parameterValues,
      timeZone: '',
      filters,
    };

    const resp = await this.httpPost(analyticsCubeApi.getData, {
      params: analyticsCubeId,
      data: bodyParameters,
      cancelToken: opts?.cancelToken,
    });

    if (resp.status === 204) {
      return {
        type: 'noContent',
      };
    } else if (
      resp.status === 403 &&
      resp.statusText === 'Too many assets in filter'
    ) {
      return {
        type: 'error',
        message: i18n.t('dashboard:error.too_many_assets'),
      };
    } else if (resp.status === 499) {
      return { type: 'canceled' };
    } else if (resp.status !== 200) {
      snackbar(i18n.t('dashboard:error.analytics_cube'), { variant: 'error' });
      return {
        type: 'error',
      };
    }

    const cube = this.analyticsCube;
    const data = resp.data;

    const groupedData: IGroupedData<ICubeData>[] = [];

    if (cube && data) {
      const xType = this.getXAxisType();

      data.results.forEach(aggregateResult => {
        const aggregate = cube.aggregates.find(
          agg => agg.analyticsCubeAggregateId === aggregateResult.aggregateId
        );
        if (!aggregate) {
          console.error('Analytics cube missing aggregate!', this);
          return;
        }

        const cubeData: ICubeData[] = [];
        Object.keys(aggregateResult.groupedData).forEach(key => {
          cubeData.push({
            group: xType === 'date' ? new Date(parseInt(key)) : key,
            value: aggregateResult.groupedData[key],
          });
        });

        groupedData.push({
          id: aggregate.analyticsCubeAggregateId,
          owner: { displayName: aggregate.displayName }, // Todo: not exactly bullet-proof, asset grouping is out of scope in v1.
          dataPoints: cubeData,
        });
      });
    }

    runInAction(() => {
      this.lastReceivedData = new Date();
    });

    return {
      type: 'success',
      data: { type: 'groupedData', groups: groupedData },
    };
  }

  getDataProps<T>(propGetter: DataPropsGetter<T>): T {
    return propGetter(prop => getDataProp(this, prop));
  }

  @computed get depString(): string {
    const { manualRefreshTrigger } = this.dashboard.rootStore.dashboardStore;
    const depObject = {
      ...this.getParameterValues(),
      manualRefreshTrigger,
    };

    return JSON.stringify(depObject);
  }
}

// Logic copied from GetServiceDataPointInterval() in SensorAnalyzer.cs
function getResolutionFromDateRange({
  startDate,
  endDate,
}: IDateRange): AnalyticsCubeRegisterDateGroupingResolution {
  const days = differenceInCalendarDays(endDate, startDate);

  if (days <= 2) {
    return AnalyticsCubeRegisterDateGroupingResolution.Minute;
  }

  if (days <= 31) {
    return AnalyticsCubeRegisterDateGroupingResolution.Hour;
  }

  if (days <= 366) {
    return AnalyticsCubeRegisterDateGroupingResolution.Day;
  }

  return AnalyticsCubeRegisterDateGroupingResolution.Month;
}
