import { IntCustomerTableDto } from 'generated';
import i18n from 'i18next';
import { Column } from 'react-table';
import { TableData } from '../..';
import { FilterOption } from '../Helpers/filterOption';
import { getOptionFilter } from '../Helpers/getOptionFilter';

export function customer<T>(options: {
  id: string;
  accessor: (row: T) => string | number;
  header: string;
  defaultShow?: boolean;
  defaultSortDesc?: boolean;
  filterable?: boolean;
  sortable?: boolean;
  data: TableData<IntCustomerTableDto> | undefined;
  width?: number;
}): Column<T> {
  const {
    id,
    header,
    accessor,
    defaultShow: show = true,
    defaultSortDesc = false,
    filterable,
    sortable,
    data,
    width = 130,
  } = options;
  const filterOptions: FilterOption[] = [
    { label: i18n.t('sim:label.status_all'), value: '' },
  ];
  if (data) {
    data.rows.forEach((c: IntCustomerTableDto) => {
      filterOptions.push({
        label: c.customerName,
        value: c.customerId,
      });
    });
  }

  return {
    id,
    Header: header,
    accessor,
    defaultSortDesc,
    width,
    maxWidth: 260,
    show,
    filterable,
    sortable,
    Filter: getOptionFilter(filterOptions),
  };
}
