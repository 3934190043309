import { createStyles, makeStyles } from '@mui/styles';

export const useMapStyles = makeStyles(theme =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      '& path[visibility="hidden"]': {
        visibility: 'visible',
        fill: theme.palette.grey['300'],
        stroke: theme.palette.grey['400'],
        '&:hover': {
          stroke: theme.palette.common.black,
        },
      },
    },
  })
);
