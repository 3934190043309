import { emptyGuid } from 'components/Auth/utils';
import {
  DynamicAttributeDataType,
  IntAttributeDefinitionDto,
  IntAttributeDto,
  IntAttributeResourceType,
  IntBulkUpdateCustomAttributeRequestDto,
  IntSearchDto,
} from 'generated';
import {
  DeleteEndpoint,
  GetEndpoint,
  PostEndpoint,
  PutEndpoint,
  TableEndpoint,
} from 'shared/interfaces/api';
import { rnd } from 'utils';
import { dynamicAttributesDemoData } from './demoData/dynamicAttributesDemoData';

export type IEditDefinitionsParams = {
  resourceType: IntAttributeResourceType;
  definitionId: string;
};
interface IDynamicAttributesAPI {
  getCustomAttributes: GetEndpoint<
    IntSearchDto | undefined,
    IntAttributeDefinitionDto[]
  >;
  getCustomAttributesTable: (
    customAttributeResourceType: IntAttributeResourceType,
    resourceId: string
  ) => TableEndpoint<IntAttributeDto>;
  getDefinitions: GetEndpoint<undefined, IntAttributeDefinitionDto[]>;
  createCustomAttributeType: PostEndpoint<
    IntAttributeResourceType,
    IntAttributeDefinitionDto
  >;
  updateCustomAttributeType: PutEndpoint<
    IEditDefinitionsParams,
    IntAttributeDefinitionDto
  >;
  deleteCustomAttributeType: DeleteEndpoint<IEditDefinitionsParams>;
  bulkUpdate: PutEndpoint<
    IntAttributeResourceType,
    IntBulkUpdateCustomAttributeRequestDto
  >;
}

export const dynamicAttributesApi: IDynamicAttributesAPI = {
  getCustomAttributes: {
    url: '/api/customattributetype',
    mockData: () => ({
      status: 200,
      data: [
        {
          id: rnd.guid(),
          customerId: rnd.guid(),
          isGlobal: false,
          isReadOnly: false,
          name: 'Country',
          description: 'Country descr',
          dataType: DynamicAttributeDataType.Text,
          resourceType: IntAttributeResourceType.ConnectivityUnit,
        },
        {
          id: rnd.guid(),
          customerId: rnd.guid(),
          isGlobal: false,
          isReadOnly: true,
          name: 'City',
          description: 'City descr',
          dataType: DynamicAttributeDataType.Text,
          resourceType: IntAttributeResourceType.ConnectivityUnit,
        },
        {
          id: rnd.guid(),
          customerId: rnd.guid(),
          isGlobal: true,
          isReadOnly: true,
          name: 'Area',
          description: 'Area descr',
          dataType: DynamicAttributeDataType.Text,
          resourceType: IntAttributeResourceType.ConnectivityUnit,
        },
        {
          id: rnd.guid(),
          customerId: rnd.guid(),
          isGlobal: false,
          isReadOnly: false,
          name: 'MySecondConnectivtiyUnitAttribute',
          description: 'My Second Connectivty Unit Attribute',
          dataType: DynamicAttributeDataType.Text,
          resourceType: IntAttributeResourceType.ConnectivityUnit,
        },
      ],
    }),
  },
  getCustomAttributesTable: (customAttributeResourceType, resourceId) => ({
    url: `/api/customattribute/${customAttributeResourceType}/${resourceId}`,
    mockData: dynamicAttributesDemoData.getCustomAttributesTable,
  }),
  getDefinitions: {
    url: '/api/assets/attributes',
    mockData: () => ({
      status: 200,
      data: [
        {
          id: emptyGuid,
          isGlobal: true,
          isReadOnly: false,
          name: 'Test attribute',
          description: 'Test attribute',
          dataType: DynamicAttributeDataType.Number,
          resourceType: IntAttributeResourceType.ConnectivityUnit,
        },
      ],
    }),
  },
  createCustomAttributeType: {
    url: resourceType => `/api/attributedefinitions/${resourceType}`,
    mockData: () => ({
      status: 201,
    }),
  },
  updateCustomAttributeType: {
    url: ({ definitionId, resourceType }) =>
      `/api/attributedefinitions/${resourceType}/${definitionId}`,
    mockData: () => ({
      status: 200,
    }),
  },
  deleteCustomAttributeType: {
    url: ({ definitionId, resourceType }) =>
      `/api/attributedefinitions/${resourceType}/${definitionId}`,
    mockData: () => ({ status: 200 }),
  },
  bulkUpdate: {
    url: customAttributeResourceType =>
      `/api/customattribute/${customAttributeResourceType}/bulkupdate`,
    mockData: () => ({ status: 200 }),
  },
};
