import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  fullWidth: {
    width: '100%',
  },
  noMargin: {
    margin: 0,
  },
}));
