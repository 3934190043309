import { IntAttributeDto, IntAttributeResourceType } from 'generated';
import { rnd, rndInt, tableDemoData } from 'utils';

class DynamicAttributesDemoData {
  get getCustomAttributesTable() {
    return tableDemoData<IntAttributeDto>(() => {
      return {
        id: '345675',
        resourceId: 'b3cd1f20-e203-4567-5b38-08dac94beddc',
        resourceType: IntAttributeResourceType.ConnectivityUnit,
        key: rnd.item(
          `Machine${rndInt(1, 50)} ID`,
          `Building${rndInt(1, 50)} ID`
        ),
        values: [rnd.int(10000, 99999).toString()],
      };
    });
  }
}

export const dynamicAttributesDemoData = new DynamicAttributesDemoData();
