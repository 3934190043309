// fallback to material ui for missing icons
import {
  AddSharp,
  Clear,
  DragHandle,
  Edit,
  Fullscreen,
  HelpOutline,
  MoreHoriz,
  NotificationsNoneRounded,
  ZoomIn,
  ZoomOut,
} from '@mui/icons-material';
import { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';
import { AtlasIconType } from 'shared/types/atlas-icon';
import { mapToSvgIcon } from 'utils';
import { ReactComponent as ApplicationSvg } from './svg/application.svg';
import { ReactComponent as AttachmentSvg } from './svg/attachment.svg';
import { ReactComponent as BatteryOutline } from './svg/batteri-outline.svg';
import { ReactComponent as BatteryAverage } from './svg/battery-average.svg';
import { ReactComponent as BatteryHigh } from './svg/battery-high.svg';
import { ReactComponent as BatteryLow } from './svg/battery-low.svg';
import { ReactComponent as Box } from './svg/box.svg';
import { ReactComponent as BusinessContinuitySvg } from './svg/business-continuity.svg';
import { ReactComponent as InputCalendarSvg } from './svg/calendar.svg';
import { ReactComponent as CloseRoundSvg } from './svg/close-icon-round.svg';
import { ReactComponent as CopySvg } from './svg/copy.svg';
import { ReactComponent as DownloadSimSvg } from './svg/download-sim.svg';
import { ReactComponent as ErrorCircle } from './svg/error-circle.svg';
import { ReactComponent as ErrorSimSvg } from './svg/error-sim.svg';
import { ReactComponent as ExtensionSvg } from './svg/extension-icon.svg';
import { ReactComponent as ExternalLinkSvg } from './svg/external-link-alt-solid.svg';
import { ReactComponent as FlagFinland } from './svg/finland.svg';
import { ReactComponent as GreyCircle } from './svg/grey-circle.svg';
import { ReactComponent as HistoricalSvg } from './svg/historical.svg';
import { ReactComponent as InactiveSimSvg } from './svg/inactive-sim.svg';
import { ReactComponent as List } from './svg/list.svg';
import { ReactComponent as MapPinSvg } from './svg/map-pin.svg';
import { ReactComponent as MoistureSvg } from './svg/moisture.svg';
import { ReactComponent as NotTransmittingSvg } from './svg/notTransmitting.svg';
import { ReactComponent as Premises } from './svg/premises.svg';
import { ReactComponent as PurpurAddSvg } from './svg/purpur-add.svg';
import { ReactComponent as PurpurAlertSvg } from './svg/purpur-alert.svg';
import { ReactComponent as PurpurCheckSvg } from './svg/purpur-check.svg';
import { ReactComponent as PurpurCloseSvg } from './svg/purpur-close.svg';
import { ReactComponent as PurpurInfoSvg } from './svg/purpur-info.svg';
import { ReactComponent as PurpurQuestionSvg } from './svg/purpur-question.svg';
import { ReactComponent as Qr } from './svg/qr.svg';
import { ReactComponent as RemoveFilterSvg } from './svg/removefilter.svg';
import { ReactComponent as ReportSvg } from './svg/report.svg';
import { ReactComponent as RestartSvg } from './svg/restart.svg';
import { ReactComponent as ActiveSimSvg } from './svg/sim.svg';
import { ReactComponent as StatusBadSvg } from './svg/Status-bad.svg';
import { ReactComponent as StatusErrorSvg } from './svg/status-error.svg';
import { ReactComponent as StatusGoodSvg } from './svg/Status-good.svg';
import { ReactComponent as StatusModerateSvg } from './svg/Status-moderate.svg';
import { ReactComponent as StatusOkSvg } from './svg/status-ok.svg';
import { ReactComponent as StatusPauseSvg } from './svg/status-pause.svg';
import { ReactComponent as StatusPendingSvg } from './svg/status-pending.svg';
import { ReactComponent as StatusUnknownSvg } from './svg/status-unknown.svg';
import { ReactComponent as StatusWarningSvg } from './svg/status-warning.svg';
import { ReactComponent as StatusSvg } from './svg/status.svg';
import { ReactComponent as FlagSweden } from './svg/sweden.svg';
import { ReactComponent as SyncSimSvg } from './svg/sync-sim.svg';
import { ReactComponent as TableViewSvg } from './svg/table-view.svg';
import { ReactComponent as AddSvg } from './svg/TC_Add.svg';
import { ReactComponent as AddedSvg } from './svg/TC_Added.svg';
import { ReactComponent as AirplaySvg } from './svg/TC_Airplay.svg';
import { ReactComponent as AiRobotSvg } from './svg/TC_AI_Robot.svg';
import { ReactComponent as AlertSvg } from './svg/TC_Alert.svg';
import { ReactComponent as ArrowDownSvg } from './svg/TC_Arrow_Down.svg';
import { ReactComponent as ArrowLeftSvg } from './svg/TC_Arrow_Left.svg';
import { ReactComponent as ArrowRightSvg } from './svg/TC_Arrow_Right.svg';
import { ReactComponent as ArrowUpSvg } from './svg/TC_Arrow_Up.svg';
import { ReactComponent as BasketballSvg } from './svg/TC_Basketball.svg';
import { ReactComponent as BatterySvg } from './svg/TC_Battery.svg';
import { ReactComponent as BestInTestSvg } from './svg/TC_Best_In_Test.svg';
import { ReactComponent as BloodPressureSvg } from './svg/TC_Blood_Pressure.svg';
import { ReactComponent as BroadbandSvg } from './svg/TC_Broadband.svg';
import { ReactComponent as BusSvg } from './svg/TC_Bus.svg';
import { ReactComponent as BusinessIntelligenceSvg } from './svg/TC_Business_Intelligence.svg';
import { ReactComponent as BuySvg } from './svg/TC_Buy.svg';
import { ReactComponent as CalendarSvg } from './svg/TC_Calendar.svg';
import { ReactComponent as CameraSvg } from './svg/TC_Camera.svg';
import { ReactComponent as CareSvg } from './svg/TC_Care.svg';
import { ReactComponent as CastSvg } from './svg/TC_Cast.svg';
import { ReactComponent as ChatSvg } from './svg/TC_Chat.svg';
import { ReactComponent as CheckmarkSvg } from './svg/TC_Checkmark.svg';
import { ReactComponent as ChevronSvg } from './svg/TC_Chevron.svg';
import { ReactComponent as ChevronDownSvg } from './svg/TC_Chevron_Down.svg';
import { ReactComponent as ChevronLeftSvg } from './svg/TC_Chevron_Left.svg';
import { ReactComponent as ChevronRightSvg } from './svg/TC_Chevron_Right.svg';
import { ReactComponent as ChevronUpSvg } from './svg/TC_Chevron_Up.svg';
import { ReactComponent as CloseSvg } from './svg/TC_Close.svg';
import { ReactComponent as CloudSvg } from './svg/TC_Cloud.svg';
import { ReactComponent as CoffeeSvg } from './svg/TC_Coffee.svg';
import { ReactComponent as ConnectedBuildingSvg } from './svg/TC_Connected_Building.svg';
import { ReactComponent as ConversationSvg } from './svg/TC_Conversation.svg';
import { ReactComponent as CustomerDialogueSvg } from './svg/TC_Customer_Dialogue.svg';
import { ReactComponent as DefaultImageSvg } from './svg/TC_Default_Image.svg';
import { ReactComponent as DeliverySvg } from './svg/TC_Delivery.svg';
import { ReactComponent as DesktopSvg } from './svg/TC_Desktop.svg';
import { ReactComponent as DevicesSvg } from './svg/TC_Devices.svg';
import { ReactComponent as DocSvg } from './svg/TC_Doc.svg';
import { ReactComponent as DownloadSvg } from './svg/TC_Download.svg';
import { ReactComponent as DslHubSvg } from './svg/TC_DSL_Hub.svg';
import { ReactComponent as EmailSvg } from './svg/TC_Email.svg';
import { ReactComponent as EndUserSvg } from './svg/TC_End_user.svg';
import { ReactComponent as EntertainmentSvg } from './svg/TC_Entertainment.svg';
import { ReactComponent as EntertainmentFilmSvg } from './svg/TC_Entertainment_Film.svg';
import { ReactComponent as EnvironmentSvg } from './svg/TC_Environment.svg';
import { ReactComponent as EsimSimcardSvg } from './svg/TC_Esim_Simcard.svg';
import { ReactComponent as FavouritesSvg } from './svg/TC_Favourites.svg';
import { ReactComponent as FiberSvg } from './svg/TC_Fiber.svg';
import { ReactComponent as FilterSvg } from './svg/TC_Filter.svg';
import { ReactComponent as FolderSvg } from './svg/TC_Folder.svg';
import { ReactComponent as GamesSvg } from './svg/TC_Games.svg';
import { ReactComponent as GettingStartedSvg } from './svg/TC_Getting_Started.svg';
import { ReactComponent as GiftSvg } from './svg/TC_Gift.svg';
import { ReactComponent as GlobalSvg } from './svg/TC_Global.svg';
import { ReactComponent as GraphSvg } from './svg/TC_Graph.svg';
import { ReactComponent as GuidingLightSvg } from './svg/TC_Guiding_Light.svg';
import { ReactComponent as HomeSvg } from './svg/TC_Home.svg';
import { ReactComponent as HomeCareSvg } from './svg/TC_HomeCare.svg';
import { ReactComponent as Hub } from './svg/TC_Hub.svg';
import { ReactComponent as InfoSvg } from './svg/TC_Info.svg';
import { ReactComponent as InternetSvg } from './svg/TC_Internet.svg';
import { ReactComponent as InvoiceSvg } from './svg/TC_Invoice.svg';
import { ReactComponent as IoTSvg } from './svg/TC_IoT.svg';
import { ReactComponent as IpadSvg } from './svg/TC_Ipad.svg';
import { ReactComponent as JobsearchSvg } from './svg/TC_Jobsearch.svg';
import { ReactComponent as LaptopSvg } from './svg/TC_Laptop.svg';
import { ReactComponent as LetterSvg } from './svg/TC_Letter.svg';
import { ReactComponent as LikeSvg } from './svg/TC_Like.svg';
import { ReactComponent as LinkSvg } from './svg/TC_Link.svg';
import { ReactComponent as ListenSvg } from './svg/TC_Listen.svg';
import { ReactComponent as LoginSvg } from './svg/TC_Login.svg';
import { ReactComponent as LogoutSvg } from './svg/TC_Logout.svg';
import { ReactComponent as LoveSvg } from './svg/TC_Love.svg';
import { ReactComponent as MapSvg } from './svg/TC_Map.svg';
import { ReactComponent as MegaphoneSvg } from './svg/TC_Megaphone.svg';
import { ReactComponent as MenuSvg } from './svg/TC_Menu.svg';
import { ReactComponent as MobileSvg } from './svg/TC_Mobile.svg';
import { ReactComponent as MoneySvg } from './svg/TC_Money.svg';
import { ReactComponent as MoreSvg } from './svg/TC_More.svg';
import { ReactComponent as MusicSvg } from './svg/TC_Music.svg';
import { ReactComponent as NetworkSvg } from './svg/TC_Network.svg';
import { ReactComponent as NetworkLostSvg } from './svg/TC_Network_Lost.svg';
import { ReactComponent as NewsSvg } from './svg/TC_News.svg';
import { ReactComponent as NumberSvg } from './svg/TC_Number.svg';
import { ReactComponent as OfferingSvg } from './svg/TC_Offering.svg';
import { ReactComponent as OpenSecuritySvg } from './svg/TC_Open_Security.svg';
import { ReactComponent as OptimizationSvg } from './svg/TC_Optimization.svg';
import { ReactComponent as OrgChartSvg } from './svg/TC_Org_Chart.svg';
import { ReactComponent as ParentalGuideSvg } from './svg/TC_Parental_Guide.svg';
import { ReactComponent as PasswordInvisibleSvg } from './svg/TC_Password_Invisible.svg';
import { ReactComponent as PasswordVisibleSvg } from './svg/TC_Password_Visible.svg';
import { ReactComponent as PaymentSvg } from './svg/TC_Payment.svg';
import { ReactComponent as PeopleSvg } from './svg/TC_People.svg';
import { ReactComponent as PeopleHubSvg } from './svg/TC_People_Hub.svg';
import { ReactComponent as PhoneSvg } from './svg/TC_Phone.svg';
import { ReactComponent as PhoneRingingSvg } from './svg/TC_Phone_Ringing.svg';
import { ReactComponent as PieChartSvg } from './svg/TC_Pie_Chart.svg';
import { ReactComponent as PlaySvg } from './svg/TC_Play.svg';
import { ReactComponent as PlayerSettingsSvg } from './svg/TC_Player_Settings.svg';
import { ReactComponent as PlusMinusSvg } from './svg/TC_Plus_Minus.svg';
import { ReactComponent as PodSvg } from './svg/TC_Pod.svg';
import { ReactComponent as PressButtonSvg } from './svg/TC_Press_Button.svg';
import { ReactComponent as RecycleSvg } from './svg/TC_Recycle.svg';
import { ReactComponent as RecyclePhoneSvg } from './svg/TC_Recycle_Phone.svg';
import { ReactComponent as RefillCardSvg } from './svg/TC_Refill_Card.svg';
import { ReactComponent as RemoteControlSvg } from './svg/TC_Remote_Control.svg';
import { ReactComponent as RepairSvg } from './svg/TC_Repair.svg';
import { ReactComponent as RouterSvg } from './svg/TC_Router.svg';
import { ReactComponent as RunSvg } from './svg/TC_Run.svg';
import { ReactComponent as SavingsSvg } from './svg/TC_Savings.svg';
import { ReactComponent as SearchSvg } from './svg/TC_Search.svg';
import { ReactComponent as SecureSvg } from './svg/TC_Secure.svg';
import { ReactComponent as SecuredSvg } from './svg/TC_Secured.svg';
import { ReactComponent as SecuritySvg } from './svg/TC_Security.svg';
import { ReactComponent as SenseCarSvg } from './svg/TC_Sense_Car.svg';
import { ReactComponent as ServerSvg } from './svg/TC_Server.svg';
import { ReactComponent as SettingsSvg } from './svg/TC_Settings.svg';
import { ReactComponent as ShareSvg } from './svg/TC_Share.svg';
import { ReactComponent as ShuffleSvg } from './svg/TC_Shuffle.svg';
import { ReactComponent as SilentSvg } from './svg/TC_Silent.svg';
import { ReactComponent as SimcardSvg } from './svg/TC_Simcard.svg';
import { ReactComponent as SkipBack10secSvg } from './svg/TC_Skip_Back_10sec.svg';
import { ReactComponent as SkipBack30secSvg } from './svg/TC_Skip_Back_30sec.svg';
import { ReactComponent as SkipForward10secSvg } from './svg/TC_Skip_Forward_10sec.svg';
import { ReactComponent as SkipForward30secSvg } from './svg/TC_Skip_Forward_30sec.svg';
import { ReactComponent as SlowWifiSvg } from './svg/TC_Slow_Wifi.svg';
import { ReactComponent as SmartWifiSvg } from './svg/TC_SmartWifi.svg';
import { ReactComponent as SmileySvg } from './svg/TC_Smiley.svg';
import { ReactComponent as SmileySadSvg } from './svg/TC_Smiley_Sad.svg';
import { ReactComponent as SmsHubSvg } from './svg/TC_Sms_Hub.svg';
import { ReactComponent as SnowflakeSvg } from './svg/TC_Snowflake.svg';
import { ReactComponent as StatisticsSvg } from './svg/TC_Statistics.svg';
import { ReactComponent as SubLevelSvg } from './svg/TC_Sub_Level.svg';
import { ReactComponent as SummarySvg } from './svg/TC_Summary.svg';
import { ReactComponent as SupportSvg } from './svg/TC_Support.svg';
import { ReactComponent as SustainabilitySvg } from './svg/TC_Sustainability.svg';
import { ReactComponent as SwitchSvg } from './svg/TC_Switch.svg';
import { ReactComponent as SyncSvg } from './svg/TC_Sync.svg';
import { ReactComponent as TachometerSvg } from './svg/TC_Tachometer.svg';
import { ReactComponent as TrailerSvg } from './svg/TC_Trailer.svg';
import { ReactComponent as TrashEmptySvg } from './svg/TC_Trash_Empty.svg';
import { ReactComponent as TravelSvg } from './svg/TC_Travel.svg';
import { ReactComponent as TravelAbroadSvg } from './svg/TC_Travel_Abroad.svg';
import { ReactComponent as TvSvg } from './svg/TC_TV.svg';
import { ReactComponent as TvNextSvg } from './svg/TC_TV_Next.svg';
import { ReactComponent as TvPauseSvg } from './svg/TC_TV_Pause.svg';
import { ReactComponent as TvPlaySvg } from './svg/TC_TV_Play.svg';
import { ReactComponent as TvPreviousSvg } from './svg/TC_TV_Previous.svg';
import { ReactComponent as UsbSvg } from './svg/TC_USB.svg';
import { ReactComponent as VideoSvg } from './svg/TC_Video.svg';
import { ReactComponent as VideoConferenceSvg } from './svg/TC_Video_Conference.svg';
import { ReactComponent as VolumeSvg } from './svg/TC_Volume.svg';
import { ReactComponent as VpnServerSvg } from './svg/TC_Vpn-server.svg';
import { ReactComponent as WalletSvg } from './svg/TC_Wallet.svg';
import { ReactComponent as WallplugSvg } from './svg/TC_Wallplug.svg';
import { ReactComponent as WifiMobileSvg } from './svg/TC_Wifi_Mobile.svg';
import { ReactComponent as WirelessSvg } from './svg/TC_Wireless.svg';
import { ReactComponent as WorldSvg } from './svg/TC_World.svg';
import { ReactComponent as TemperatureSvg } from './svg/temperature.svg';
import { ReactComponent as Time } from './svg/time.svg';
import { ReactComponent as TransmittingSvg } from './svg/transmitting.svg';
import { ReactComponent as FlagUK } from './svg/united-kingdom.svg';
import { ReactComponent as UnlinkSvg } from './svg/unlink.svg';
import { ReactComponent as UserAdminSvg } from './svg/user-admin.svg';
import TeliaFlagIcons from './telia-flag-icons';
import {
  applicationViewbox,
  externalLinkViewbox,
  flagViewbox,
  hubViewbox,
  muiViewbox,
  simViewbox,
  statusErrorViewbox,
  statusOkViewbox,
  statusPauseViewbox,
  statusPendingViewbox,
  statusUnknownViewbox,
  statusWarningViewbox,
} from './variables';

const TeliaIcons: Record<AtlasIconType, React.ComponentType<SvgIconProps>> = {
  ActiveSim: mapToSvgIcon(ActiveSimSvg, simViewbox),
  DownloadSim: mapToSvgIcon(DownloadSimSvg, simViewbox),
  InactiveSim: mapToSvgIcon(InactiveSimSvg, simViewbox),
  SyncSim: mapToSvgIcon(SyncSimSvg, simViewbox),
  ErrorSim: mapToSvgIcon(ErrorSimSvg, simViewbox),
  Extension: mapToSvgIcon(ExtensionSvg),
  Home: mapToSvgIcon(HomeSvg),
  EndUser: mapToSvgIcon(EndUserSvg),
  Add: mapToSvgIcon(AddSvg),
  Added: mapToSvgIcon(AddedSvg),
  Airplay: mapToSvgIcon(AirplaySvg),
  AiRobot: mapToSvgIcon(AiRobotSvg),
  Alert: mapToSvgIcon(AlertSvg),
  Application: mapToSvgIcon(ApplicationSvg, applicationViewbox),
  ArrowDown: mapToSvgIcon(ArrowDownSvg),
  ArrowLeft: mapToSvgIcon(ArrowLeftSvg),
  ArrowRight: mapToSvgIcon(ArrowRightSvg),
  ArrowUp: mapToSvgIcon(ArrowUpSvg),
  Attachment: mapToSvgIcon(AttachmentSvg, muiViewbox),
  Basketball: mapToSvgIcon(BasketballSvg),
  Battery: mapToSvgIcon(BatterySvg),
  BatteryOutline: mapToSvgIcon(BatteryOutline),
  BatteryHigh: mapToSvgIcon(BatteryHigh, muiViewbox),
  BatteryAverage: mapToSvgIcon(BatteryAverage, muiViewbox),
  BatteryLow: mapToSvgIcon(BatteryLow, muiViewbox),
  BestInTest: mapToSvgIcon(BestInTestSvg),
  BloodPressure: mapToSvgIcon(BloodPressureSvg),
  Broadband: mapToSvgIcon(BroadbandSvg),
  Bus: mapToSvgIcon(BusSvg),
  BusinessContinuity: mapToSvgIcon(BusinessContinuitySvg, muiViewbox),
  BusinessIntelligence: mapToSvgIcon(BusinessIntelligenceSvg),
  Calendar: mapToSvgIcon(CalendarSvg),
  Camera: mapToSvgIcon(CameraSvg),
  Care: mapToSvgIcon(CareSvg),
  Cast: mapToSvgIcon(CastSvg),
  Chat: mapToSvgIcon(ChatSvg),
  Checkmark: mapToSvgIcon(CheckmarkSvg),
  Chevron: mapToSvgIcon(ChevronSvg),
  ChevronDown: mapToSvgIcon(ChevronDownSvg),
  ChevronLeft: mapToSvgIcon(ChevronLeftSvg),
  ChevronRight: mapToSvgIcon(ChevronRightSvg),
  ChevronUp: mapToSvgIcon(ChevronUpSvg),
  Clear, // Missing Telia Icon
  Copy: mapToSvgIcon(CopySvg, '0 0 24 24'),
  Close: mapToSvgIcon(CloseSvg),
  CloseRound: mapToSvgIcon(CloseRoundSvg),
  Cloud: mapToSvgIcon(CloudSvg),
  Coffee: mapToSvgIcon(CoffeeSvg),
  ConnectedBuilding: mapToSvgIcon(ConnectedBuildingSvg),
  Conversation: mapToSvgIcon(ConversationSvg),
  CustomerDialogue: mapToSvgIcon(CustomerDialogueSvg),
  DefaultImage: mapToSvgIcon(DefaultImageSvg),
  Delivery: mapToSvgIcon(DeliverySvg),
  Desktop: mapToSvgIcon(DesktopSvg),
  Devices: mapToSvgIcon(DevicesSvg),
  VpnServer: mapToSvgIcon(VpnServerSvg, muiViewbox),
  Doc: mapToSvgIcon(DocSvg, '11 3 41 57'),
  Download: mapToSvgIcon(DownloadSvg),
  DslHub: mapToSvgIcon(DslHubSvg),
  DragHandle: DragHandle, // Missing Telia Icon
  ErrorCircle: mapToSvgIcon(ErrorCircle),
  Email: mapToSvgIcon(EmailSvg),
  Entertainment: mapToSvgIcon(EntertainmentSvg),
  EntertainmentFilm: mapToSvgIcon(EntertainmentFilmSvg),
  Environment: mapToSvgIcon(EnvironmentSvg),
  EsimSimcard: mapToSvgIcon(EsimSimcardSvg),
  ExternalLink: mapToSvgIcon(ExternalLinkSvg, externalLinkViewbox),
  Favourites: mapToSvgIcon(FavouritesSvg),
  Fiber: mapToSvgIcon(FiberSvg),
  Filter: mapToSvgIcon(FilterSvg),
  FlagFinland: mapToSvgIcon(FlagFinland, flagViewbox),
  FlagSweden: mapToSvgIcon(FlagSweden, flagViewbox),
  FlagUK: mapToSvgIcon(FlagUK, flagViewbox),
  Folder: mapToSvgIcon(FolderSvg),
  Games: mapToSvgIcon(GamesSvg),
  Gift: mapToSvgIcon(GiftSvg),
  Graph: mapToSvgIcon(GraphSvg),
  GettingStarted: mapToSvgIcon(GettingStartedSvg),
  Global: mapToSvgIcon(GlobalSvg),
  GuidingLight: mapToSvgIcon(GuidingLightSvg),
  HelpOutline,
  Historical: mapToSvgIcon(HistoricalSvg, muiViewbox),
  HomeCare: mapToSvgIcon(HomeCareSvg),
  Hub: mapToSvgIcon(Hub, hubViewbox),
  Info: mapToSvgIcon(InfoSvg),
  InputCalendar: mapToSvgIcon(InputCalendarSvg, '0 0 14 14'),
  Internet: mapToSvgIcon(InternetSvg),
  Invoice: mapToSvgIcon(InvoiceSvg),
  IoT: mapToSvgIcon(IoTSvg, '0 0 723 723'),
  Ipad: mapToSvgIcon(IpadSvg),
  Jobsearch: mapToSvgIcon(JobsearchSvg),
  Laptop: mapToSvgIcon(LaptopSvg),
  Letter: mapToSvgIcon(LetterSvg),
  Like: mapToSvgIcon(LikeSvg),
  Link: mapToSvgIcon(LinkSvg),
  Listen: mapToSvgIcon(ListenSvg),
  Login: mapToSvgIcon(LoginSvg),
  Logout: mapToSvgIcon(LogoutSvg),
  Love: mapToSvgIcon(LoveSvg),
  Maps: mapToSvgIcon(MapSvg),
  MapPin: mapToSvgIcon(MapPinSvg, '0 0 25 33'),
  Megaphone: mapToSvgIcon(MegaphoneSvg),
  Menu: mapToSvgIcon(MenuSvg),
  Mobile: mapToSvgIcon(MobileSvg),
  Money: mapToSvgIcon(MoneySvg),
  More: mapToSvgIcon(MoreSvg),
  Moisture: mapToSvgIcon(MoistureSvg, '0 0 24 24'),
  MoreHoriz, // Missing Telia Icon
  Music: mapToSvgIcon(MusicSvg),
  Management: mapToSvgIcon(SummarySvg),
  Network: mapToSvgIcon(NetworkSvg),
  NetworkLost: mapToSvgIcon(NetworkLostSvg),
  News: mapToSvgIcon(NewsSvg),
  NotTransmitting: mapToSvgIcon(NotTransmittingSvg, '0 0 40 40'),
  Number: mapToSvgIcon(NumberSvg, '0 0 25 25'),
  Notifications: NotificationsNoneRounded, // Missing Telia Icon
  Offering: mapToSvgIcon(OfferingSvg),
  OpenSecurity: mapToSvgIcon(OpenSecuritySvg),
  Optimization: mapToSvgIcon(OptimizationSvg),
  OrgChart: mapToSvgIcon(OrgChartSvg),
  ParentalGuide: mapToSvgIcon(ParentalGuideSvg),
  PasswordInvisible: mapToSvgIcon(PasswordInvisibleSvg),
  PasswordVisible: mapToSvgIcon(PasswordVisibleSvg),
  Payment: mapToSvgIcon(PaymentSvg),
  People: mapToSvgIcon(PeopleSvg),
  PeopleHub: mapToSvgIcon(PeopleHubSvg),
  Pencil: Edit, // Missing Telia Icon
  Phone: mapToSvgIcon(PhoneSvg),
  PhoneRinging: mapToSvgIcon(PhoneRingingSvg),
  PieChart: mapToSvgIcon(PieChartSvg),
  Play: mapToSvgIcon(PlaySvg),
  Plus: AddSharp,
  PlusMinus: mapToSvgIcon(PlusMinusSvg),
  Pod: mapToSvgIcon(PodSvg),
  PressButton: mapToSvgIcon(PressButtonSvg),
  PurpurAdd: mapToSvgIcon(PurpurAddSvg, '0 0 14 14'),
  PurpurAlert: mapToSvgIcon(PurpurAlertSvg, '0 0 14 14'),
  PurpurCheck: mapToSvgIcon(PurpurCheckSvg, '0 0 14 14'),
  PurpurClose: mapToSvgIcon(PurpurCloseSvg, '0 0 14 14'),
  PurpurInfo: mapToSvgIcon(PurpurInfoSvg, '0 0 14 14'),
  PurpurQuestion: mapToSvgIcon(PurpurQuestionSvg, '0 0 14 14'),
  Recycle: mapToSvgIcon(RecycleSvg),
  RecyclePhone: mapToSvgIcon(RecyclePhoneSvg),
  RefillCard: mapToSvgIcon(RefillCardSvg),
  RemoteControl: mapToSvgIcon(RemoteControlSvg),
  RemoveFilter: mapToSvgIcon(RemoveFilterSvg, '0 0 40 40'),
  Repair: mapToSvgIcon(RepairSvg),
  Report: mapToSvgIcon(ReportSvg, muiViewbox),
  Restart: mapToSvgIcon(RestartSvg, muiViewbox),
  Router: mapToSvgIcon(RouterSvg),
  Savings: mapToSvgIcon(SavingsSvg),
  Search: mapToSvgIcon(SearchSvg),
  Secure: mapToSvgIcon(SecureSvg),
  Secured: mapToSvgIcon(SecuredSvg),
  Security: mapToSvgIcon(SecuritySvg),
  SenseCar: mapToSvgIcon(SenseCarSvg),
  Server: mapToSvgIcon(ServerSvg),
  Settings: mapToSvgIcon(SettingsSvg),
  Share: mapToSvgIcon(ShareSvg),
  Shopping: mapToSvgIcon(BuySvg),
  Shuffle: mapToSvgIcon(ShuffleSvg),
  Silent: mapToSvgIcon(SilentSvg),
  Simcard: mapToSvgIcon(SimcardSvg),
  SkipBack10sec: mapToSvgIcon(SkipBack10secSvg),
  SkipBack30sec: mapToSvgIcon(SkipBack30secSvg),
  SkipForward10sec: mapToSvgIcon(SkipForward10secSvg),
  SkipForward30sec: mapToSvgIcon(SkipForward30secSvg),
  SlowWifi: mapToSvgIcon(SlowWifiSvg),
  SmartWifi: mapToSvgIcon(SmartWifiSvg),
  Smiley: mapToSvgIcon(SmileySvg),
  SmileySad: mapToSvgIcon(SmileySadSvg),
  Sms: mapToSvgIcon(PlayerSettingsSvg),
  SmsHub: mapToSvgIcon(SmsHubSvg),
  Snowflake: mapToSvgIcon(SnowflakeSvg),
  Statistics: mapToSvgIcon(StatisticsSvg),
  Status: mapToSvgIcon(StatusSvg),
  StatusError: mapToSvgIcon(StatusErrorSvg, statusErrorViewbox),
  StatusOk: mapToSvgIcon(StatusOkSvg, statusOkViewbox),
  StatusPending: mapToSvgIcon(StatusPendingSvg, statusPendingViewbox),
  StatusUnknown: mapToSvgIcon(StatusUnknownSvg, statusUnknownViewbox),
  StatusWarning: mapToSvgIcon(StatusWarningSvg, statusWarningViewbox),
  StatusPause: mapToSvgIcon(StatusPauseSvg, statusPauseViewbox),
  Summary: mapToSvgIcon(SummarySvg),
  Support: mapToSvgIcon(SupportSvg),
  Sustainability: mapToSvgIcon(SustainabilitySvg),
  Switch: mapToSvgIcon(SwitchSvg),
  SubLevel: mapToSvgIcon(SubLevelSvg),
  Sync: mapToSvgIcon(SyncSvg),
  TableView: mapToSvgIcon(TableViewSvg, '0 0 24 24'),
  Tachometer: mapToSvgIcon(TachometerSvg, '0 0 24 24'),
  Time: mapToSvgIcon(Time),
  Temperature: mapToSvgIcon(TemperatureSvg, '0 0 19 19'),
  Transmitting: mapToSvgIcon(TransmittingSvg, '0 0 40 40'),
  Trailer: mapToSvgIcon(TrailerSvg),
  TrashEmpty: mapToSvgIcon(TrashEmptySvg),
  Travel: mapToSvgIcon(TravelSvg),
  TravelAbroad: mapToSvgIcon(TravelAbroadSvg),
  Tv: mapToSvgIcon(TvSvg),
  TvNext: mapToSvgIcon(TvNextSvg),
  TvPause: mapToSvgIcon(TvPauseSvg),
  TvPlay: mapToSvgIcon(TvPlaySvg),
  TvPrevious: mapToSvgIcon(TvPreviousSvg),
  Unlink: mapToSvgIcon(UnlinkSvg),
  Usb: mapToSvgIcon(UsbSvg),
  UserAdmin: mapToSvgIcon(UserAdminSvg, muiViewbox),
  Video: mapToSvgIcon(VideoSvg),
  VideoConference: mapToSvgIcon(VideoConferenceSvg),
  Volume: mapToSvgIcon(VolumeSvg),
  Wallet: mapToSvgIcon(WalletSvg),
  Wallplug: mapToSvgIcon(WallplugSvg),
  WifiMobile: mapToSvgIcon(WifiMobileSvg),
  Wireless: mapToSvgIcon(WirelessSvg),
  Widgets: mapToSvgIcon(OrgChartSvg), // missing icon
  World: mapToSvgIcon(WorldSvg),
  List: mapToSvgIcon(List, '0 0 32 32'), // missing icon
  Qr: mapToSvgIcon(Qr, '0 0 32 32'), // missing icon
  Run: mapToSvgIcon(RunSvg),
  Box: mapToSvgIcon(Box, '0 0 24 24'),
  Fullscreen, // missing icon
  ZoomIn, // missing icon
  ZoomOut, // missing icon
  StatusGood: mapToSvgIcon(StatusGoodSvg, '0 0 24 24'),
  StatusModerate: mapToSvgIcon(StatusModerateSvg, '0 0 24 24'),
  StatusBad: mapToSvgIcon(StatusBadSvg, '0 0 24 24'),
  Premises: mapToSvgIcon(Premises, '0 0 25 25'),
  StatusNeutral: mapToSvgIcon(GreyCircle, '0 0 22 22'),
  ...TeliaFlagIcons,
};

export default TeliaIcons;
