import {
  Box,
  Grid,
  GridSize,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  useTheme,
} from '@mui/material';
import classNames from 'classnames';
import AtlasIcon from 'components/AtlasIcon';
import AtlasIconButton from 'components/AtlasIconButton';
import SearchField from 'components/SearchField';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SortEndHandler } from 'react-sortable-hoc';
import { Column } from 'react-table';
import { usePopover } from 'utils';
import {
  EditColumnsModal,
  TableQueryParams,
  TableSelectState,
  TableTopAction,
  TableTopMoreMenuItem,
} from '..';
import TotalRows from '../TotalRows';
import { useStyles } from './TableTopContent.styles';

interface ITableTopContentProps<T> {
  columns: Column<T>[];
  tableTitle?: string;
  handleSortEnd: SortEndHandler;
  toggleShowColumn: (colId: string, show: boolean) => void;
  currentlyShown: number | '-';
  totalRows: number | '-';
  enableSearch?: boolean;
  searchPlaceholder?: string;
  onSearchChange: (searchTerm: string) => void;
  params: TableQueryParams;
  error?: React.ReactNode;
  toolbar?: React.ReactNode;
  selectState?: TableSelectState<T>;
  onFilterChange: () => void;
  disableTabKey?: boolean;
  required?: boolean;
  helperText?: string;
  handleResetFilter: () => void;
  showFilters?: boolean;
  hideFilterButton?: boolean;
  parentTableWidth: number;
  topMoreButtonItems?: TableTopMoreMenuItem[];
  topAction?: TableTopAction;
}

function TableTopContent<TRow = any>(props: ITableTopContentProps<TRow>) {
  const {
    columns,
    tableTitle,
    handleSortEnd,
    toggleShowColumn,
    totalRows,
    currentlyShown,
    enableSearch,
    searchPlaceholder,
    onSearchChange,
    params,
    toolbar,
    selectState,
    onFilterChange,
    disableTabKey,
    helperText,
    required = false,
    handleResetFilter,
    showFilters = false,
    hideFilterButton = false,
    parentTableWidth,
    topMoreButtonItems,
    topAction,
  } = props;

  const [showColumnModal, setShowColumnModal] = useState(false);
  const [moreMenuElement, setMoreMenuElement] = useState<null | HTMLElement>(
    null
  );
  const [openTopActionPopover, topActionPopover] = usePopover('downloadMenu');

  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  const hasFilterableColumns = columns.some(col => col.filterable !== false);
  const hasTopActionItem = () => topAction && topAction.items.length > 0;

  const mdBreakpoint = theme.breakpoints.values.md;
  const checkParentTableWidth = parentTableWidth < mdBreakpoint;
  const customGridColumnSize: GridSize = checkParentTableWidth ? 12 : 4;

  const closeMoreMenu = () => {
    setMoreMenuElement(null);
  };

  return (
    <>
      <EditColumnsModal
        show={showColumnModal}
        close={() => setShowColumnModal(false)}
        columns={columns}
        onSortEnd={handleSortEnd}
        toggleShowColumn={toggleShowColumn}
      />

      {hasTopActionItem() && (
        <Menu {...topActionPopover}>
          {topAction?.items.map(item => {
            return (
              <MenuItem
                key={item.text}
                onClick={() => {
                  topActionPopover.onClose();
                  item.onClick();
                }}
                disabled={item.disabled}
              >
                {item.icon && (
                  <ListItemIcon>
                    <AtlasIcon type={item.icon} size={24} />
                  </ListItemIcon>
                )}
                <ListItemText>{item.text}</ListItemText>
              </MenuItem>
            );
          })}
        </Menu>
      )}

      {topMoreButtonItems && topMoreButtonItems.length > 0 && (
        <Menu
          id="more-menu"
          anchorEl={moreMenuElement}
          open={Boolean(moreMenuElement)}
          onClose={closeMoreMenu}
          className={classes.moreMenu}
          MenuListProps={{
            'aria-labelledby': 'more-menu-button',
          }}
        >
          {topMoreButtonItems.map(item => {
            return (
              <MenuItem
                key={item.text}
                data-cy={item.dataCy}
                onClick={() => {
                  closeMoreMenu();
                  item.onClick();
                }}
                disabled={item.disabled}
              >
                {item.icon && (
                  <ListItemIcon>
                    <AtlasIcon type={item.icon} size={24} />
                  </ListItemIcon>
                )}
                <ListItemText>{item.text}</ListItemText>
              </MenuItem>
            );
          })}
        </Menu>
      )}

      <Box p="16px 5px">
        {tableTitle && (
          <Grid container spacing={0.5}>
            <Grid
              item
              xs={hasTopActionItem() ? 11 : 12}
              className={classNames({
                [classes.required]: required,
              })}
            >
              <span className={classes.tableTitle}>{tableTitle}</span>
            </Grid>
            {topAction && hasTopActionItem() && (
              <Grid item xs={1} className={classes.topActionIconGrid}>
                <IconButton
                  className={classes.topActionIcon}
                  onClick={openTopActionPopover}
                >
                  <AtlasIcon type={topAction.icon} size={16} />
                </IconButton>
              </Grid>
            )}
            {helperText && (
              <Grid item xs={12} className={classes.helperText}>
                {helperText}
              </Grid>
            )}
          </Grid>
        )}
        <Grid alignItems="center" container spacing={1}>
          <Grid
            item
            container
            lg={enableSearch ? customGridColumnSize : 4}
            xs={12}
            alignItems="center"
            className={classes.error}
          >
            {selectState?.selectedRowsCount !== undefined ? (
              <span className={classes.selectedCount}>
                {t('resource_table.selected_rows_count', {
                  count: selectState.selectedRowsCount,
                })}
              </span>
            ) : undefined}
            {toolbar}
          </Grid>

          {enableSearch && (
            <Grid item lg={customGridColumnSize} xs={12}>
              <SearchField
                value={params.search || ''}
                onChange={onSearchChange}
                placeholder={searchPlaceholder ?? t('table.placeholder')}
                disableTabKey={disableTabKey}
              />
            </Grid>
          )}
          <Grid
            item
            lg={enableSearch ? customGridColumnSize : 8}
            xs={12}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <TotalRows currentlyShown={currentlyShown} totalRows={totalRows} />
            <span className={classes.verticalLine} />
            <AtlasIconButton
              color="primary"
              onClick={() => setShowColumnModal(!showColumnModal)}
              title={t('label.edit.columns')}
            >
              <AtlasIcon type="TableView" size={24} />
            </AtlasIconButton>
            {!hideFilterButton && (
              <AtlasIconButton
                color="primary"
                onClick={() => {
                  onFilterChange();
                  handleResetFilter();
                }}
                title={t('label.toggle_filters')}
                disabled={!hasFilterableColumns}
              >
                <AtlasIcon
                  type={showFilters ? 'RemoveFilter' : 'Filter'}
                  size={24}
                />
              </AtlasIconButton>
            )}
            {topMoreButtonItems && topMoreButtonItems.length > 0 && (
              <AtlasIconButton
                id="more-menu-button"
                data-cy="more-menu-button"
                color="primary"
                onClick={event => setMoreMenuElement(event.currentTarget)}
                title={t('resource_table.more_button')}
              >
                <AtlasIcon type="MoreHoriz" size={24} />
              </AtlasIconButton>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default TableTopContent;
