import { faker } from '@faker-js/faker';
import { TableDataAPIFn2 } from 'components/Table';
import { IntCustomerDto, IntLocaleDto, IntRateplanDto } from 'generated';
import { byteThreshold } from './constants';

export function rndItem<T>(items: T[]): T {
  return items[Math.floor(Math.random() * items.length)];
}

export function rndInt(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function dummyData<T>(n: number, f: (i: number) => T | undefined): T[] {
  const a: T[] = [];
  let callResult;

  for (let i = 0; i < n; i++) {
    callResult = f(i);

    if (callResult !== undefined) {
      a.push(callResult);
    }
  }
  return a;
}

export function tableDemoData<T>(
  generate: (i: number) => T,
  totalRows = 70
): TableDataAPIFn2<T> {
  let rows: T[];

  return ({ page, pageSize }) => {
    page = page ?? 0;
    pageSize = pageSize ?? 50;
    // Lazy - don't generate rows until needed.
    if (!rows) {
      rows = dummyData(totalRows, generate);
    }

    return {
      status: 200,
      data: {
        rows: rows.slice(page * pageSize, page * pageSize + pageSize),
        total: rows.length,
      },
    };
  };
}

function randomArray<T>(n: number, genFn: (i: number) => T): T[] {
  const items: T[] = [];
  for (let i = 0; i < n; i++) {
    items.push(genFn(i));
  }
  return items;
}

const locales: IntLocaleDto[] = [
  { id: 'TMA', name: 'Austria' },
  { id: 'SE', name: 'Sweden' },
  { id: 'JP', name: 'Japan' },
  { id: 'PAK', name: 'Pakistan' },
  { id: 'PAN', name: 'Panama' },
  { id: 'CTG', name: 'China' },
];

export const rnd = {
  int: rndInt,
  lat: faker.location.latitude,
  lng: faker.location.longitude,
  item: function <T>(...items: T[]): T {
    return items[Math.floor(Math.random() * items.length)];
  },
  date: faker.date,
  country: faker.location.country,
  countryCode: faker.location.countryCode,
  customerName: () => 'Any Company',
  customer: (i?: number): IntCustomerDto => {
    return {
      id: rnd.staticGuid(i ?? rnd.int(10000, 99999)),
      name: rnd.customerName(),
    };
  },
  enum: function <T>(input: T): T[keyof T] {
    const values = Object.values(input as any)
      .map(n => Number.parseInt(n as string))
      .filter(n => !Number.isNaN(n)) as unknown as T[keyof T][];
    return values[rnd.int(0, values.length - 1)];
  },
  arrayGenerator: function <T>(n: number, genFn: (i: number) => T): () => T[] {
    return () => randomArray(n, genFn);
  },
  array: randomArray,
  imei: () => rndInt(100000000000000, 999999000000000).toString(),
  imsi: () => rndInt(238200000000000, 238209999999999).toString(),
  bool: faker.datatype.boolean,
  guid: faker.string.uuid,
  staticGuid: (i: number) =>
    `00000000-0000-0000-0000-${i.toString().padStart(12, '0')}`,
  msisdn: () =>
    `${rndInt(1, 999)}${rndInt(1000, 9999)}${rndInt(100000, 999999)}`,
  iccid: () => rndInt(89000000000000000000, 89999999999999999999).toString(),
  pin: () => rndInt(1000, 9999).toString(),
  puk: () => rndInt(100000, 99990).toString(),
  euiccId: () =>
    `${rndInt(111, 99999)}${rndInt(1111111, 9999999)}${rndInt(
      1111111111,
      9999999999
    )}${rndInt(1111111111, 9999999999)}`.padStart(32, '0'),
  resourceGroup: (num?: number) => {
    const resourceGroup = num === undefined ? rndInt(1, 10) : num;

    return {
      resourceGroupId: `2d416c62-f4ke-gr0p-${resourceGroup
        .toString()
        .padStart(4, '0')}-6ee880310766`,
      resourceGroupName: `Group ${resourceGroup}`,
    };
  },
  resourceGroupName: (num?: number) =>
    `Group ${num === undefined ? rndInt(1, 10) : num}`,
  label: () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

    return `${rndItem(chars)}${rndItem(chars)}${rndItem(chars)}-${rndInt(0, 999)
      .toString()
      .padStart(3, '0')}`;
  },
  groupLabel: (i = 1) => `${faker.finance.currencyCode()}-${i * 100}`,
  req: () => `REQ${faker.finance.accountNumber(12)}`,
  fullName: faker.person.fullName,
  firstName: faker.person.firstName,
  lastName: faker.person.lastName,
  userName: () => faker.internet.userName().padEnd(6, '123'),
  password: () => faker.internet.password(),
  faker,
  roles: () =>
    rndItem(['Administrator', 'SuperAdmin', 'User', 'Visitor', 'Custom Role']),
  description: () =>
    rndItem([
      'Global Connectvity',
      'Building Climate Control',
      'Consid Customer',
      'APIM',
      'DevOps',
      'Test',
    ]),
  b: () => rndInt(0, byteThreshold - 1),
  kb: () => rndInt(byteThreshold, Math.pow(byteThreshold, 2) - 1),
  mb: () => rndInt(Math.pow(byteThreshold, 2), Math.pow(byteThreshold, 3) - 1),
  gb: () => rndInt(Math.pow(byteThreshold, 3), Math.pow(byteThreshold, 4) - 1),
  data: () => rndInt(0, Math.pow(byteThreshold, 4) - 1),
  address: faker.location.streetAddress,
  company: faker.company.name,
  letters: (count = 1) => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
    const result = [];

    for (let i = 0; i < count; i++) {
      result.push(rndItem(chars));
    }

    return result.join('');
  },
  charSequence: (count = 1) => {
    const chars = '0123456789abcdefghijklmnopqrstuvwxyz'.split('');
    const result = [];

    for (let i = 0; i < count; i++) {
      result.push(rndItem(chars));
    }

    return result.join('');
  },
  operator: () =>
    rndItem([
      'Telia',
      'Vodaphone',
      'Telenor',
      'China Telecom',
      'Tele 2',
      'Tre',
      'Hallon',
      'Halebop',
      'T-Mobile',
    ]),
  locale: () => rndItem(locales),
  locales: (): IntLocaleDto[] => locales.slice(0, rndInt(2, 7)),
  mac: () => faker.internet.mac().toUpperCase(),
  modelNumber: () => rnd.item(`${rndInt(1, 9)}000E Tronic`, '-'),
  email: faker.internet.exampleEmail,
  phoneNumber: () =>
    rndItem(['+46 703018570', '+46 73 1268945', '+46 736431289']),
  personalIdNumber: (twelveDigits = false) => {
    const year = rnd.int(twelveDigits ? 1930 : 30, twelveDigits ? 1999 : 99);
    const month = rnd.int(1, 12).toString().padStart(2, '0');
    const day = rnd.int(1, 28).toString().padStart(2, '0');
    const digits = rnd.int(1111, 9999);

    return `${year}${month}${day}-${digits}`;
  },
  formFactor: () =>
    rnd.item(
      'Plug 85 TRIO eUICC',
      'Plug 105 2FF eUICC (standard mini)',
      'Embedded eUICC SIM'
    ),
  ip: faker.internet.ip,
  ratType: () => rndItem(['Bluetooth', 'Wi-Fi', 'GSM', 'UMTS', 'LTE', '5G NR']),
  rateplan: (): IntRateplanDto => ({
    rateplanLinkId: rnd.guid(),
    ratePlanType: rnd.int(0, 2),
    identifier: `${rnd.charSequence(4)}${rnd.int(
      100,
      999
    )}-${rnd.date.past()}_${rnd.charSequence(2)}_0${rnd.int(1, 9)}`,
    displayName: rnd.item(
      'EU, USA & China (Zone A1+A2+B)',
      'World III. (Zone A1+A2+B+C+D+E)',
      `${rnd.customer().name}, World VII, ${rnd.charSequence(4)}`
    ),
  }),
  simDescription: () =>
    `Plug ${rnd.item('85', '105')} TRIO eUICC ${faker.location.countryCode()}`,
  serviceRequestId: () => `REQ${rnd.int(111_111_111_111, 999_999_999_999)}`,
  weighted<T>(...options: { value: T; weight?: number }[]): T {
    // Return a random item, but allow options to have different weights for more realistic results
    const indexes: number[] = [];
    options.forEach((option, optionIndex) => {
      for (let i = 0; i < (option.weight ?? 1); i++) {
        indexes.push(optionIndex);
      }
    });
    const chosenIndex = rnd.item(...indexes);
    return options[chosenIndex].value;
  },
  domainName: () => faker.internet.domainName(),
  mccmnc: () => rnd.int(20000, 99999).toString(),
  words: (count: number) => faker.word.words(count),
};
