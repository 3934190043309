import { Box, Button } from '@mui/material';
import { Column } from 'react-table';

export function button<T>(options: {
  id: string;
  header: string;
  accessor: (row: T) => string | number;
  onClick: (row: T) => void;
  defaultShow?: boolean;
  width?: number;
  variant: 'text' | 'outlined' | 'contained';
  textAlign?: 'left' | 'center' | 'right';
}): Column<T> {
  const {
    id,
    header,
    accessor,
    onClick,
    variant = 'contained',
    defaultShow: show = true,
    width,
    textAlign = 'left',
  } = options;

  //no padding for text buttons in tables, others will have the standrad padding
  const padding = variant === 'text' ? 0 : '16px 12px';

  return {
    id,
    Header: header,
    accessor,
    show,
    Cell: row => (
      <Box textAlign={textAlign}>
        <Button
          onClick={() => onClick(row.original)}
          variant={variant}
          color="primary"
          sx={{ fontSize: 12, padding }}
        >
          {row.value}
        </Button>
      </Box>
    ),
    width,
  };
}
