import { ColorAxisDataClassesOptions } from 'highcharts';
import { getByteConversionUnitAndValue } from 'utils';
import { getNumberRangeArray } from 'utils/various';

interface IUnitRangeProps {
  min: number;
  max: number;
}

const getDataClassName = (
  rangeCount: number,
  index: number,
  item: IUnitRangeProps
) => {
  const from = getByteConversionUnitAndValue(item.min);
  const to = getByteConversionUnitAndValue(item.max);
  const fromValue = Math.trunc(from.value);
  const fromUnit = from.unit !== to.unit ? from.unit : '';
  const toValue = Math.trunc(to.value);

  switch (index) {
    case 0:
      return `< ${toValue} ${to.unit}`;
    case rangeCount - 1:
      return `> ${fromValue} ${from.unit}`;
    default:
      return `${fromValue} ${fromUnit} - ${toValue} ${to.unit}`;
  }
};

export function generateDataClasses(min: number, max: number, interval = 5) {
  const ranges = getNumberRangeArray(min, max, interval);
  const dataClasses: Array<ColorAxisDataClassesOptions> = [];
  const rangeCount = ranges.length;

  if (rangeCount > 0) {
    ranges.forEach((item, index) => {
      const dataClassName = getDataClassName(rangeCount, index, item);
      dataClasses.push({
        from: index > 0 ? item.min : 0,
        to: item.max,
        name: dataClassName,
      });
    });
  }

  return dataClasses;
}
