import { useTheme } from '@mui/material';
import * as azureMaps from 'azure-maps-control';
import {
  GeofenceType,
  IntGeofenceDto,
  IntPolygonPointDto,
  IntServiceDataSpecificationFilterDto,
} from 'generated';
import { useCallback, useEffect, useState } from 'react';
import { GeofenceService } from 'services/geofence.service';
import { useGet } from 'shared/api/hooks';
import { cloneJSON } from 'utils';
import { useDashboardContext } from 'views/Dashboard/contexts/dashboardContext';

export function useGeofence(showGeofence = false) {
  const theme = useTheme();
  const [getGeofences] = useGet(GeofenceService.getGeofences);
  const [geofences, setGeofences] = useState<IntGeofenceDto[]>([]);
  const [source] = useState(() => new azureMaps.source.DataSource());
  const [layer] = useState(() => [
    new azureMaps.layer.PolygonLayer(source, 'polygonLayer', {
      fillColor: [
        'case',
        ['has', 'zoneColor'],
        ['get', 'zoneColor'],
        theme.palette.primary.main,
      ],
      fillOpacity: 0.3,
    }),
    new azureMaps.layer.LineLayer(source, 'lineLayer', {
      strokeColor: [
        'case',
        ['has', 'zoneColor'],
        ['get', 'zoneColor'],
        theme.palette.primary.main,
      ],
      strokeWidth: 2,
    }),
    new azureMaps.layer.SymbolLayer(source, 'symbolLayer', {
      iconOptions: {
        image: 'none',
      },
      textOptions: {
        textField: ['get', 'displayName'],
        color: [
          'case',
          ['has', 'zoneColor'],
          ['get', 'zoneColor'],
          theme.palette.primary.main,
        ],
      },
    }),
  ]);

  const setPolygonPoints = (points: IntPolygonPointDto[]) => [
    [...points]
      .sort((a, b) => a.order - b.order)
      .map(point => [point.longitude, point.latitude]),
  ];

  const addGeofencesSource = useCallback(() => {
    geofences.forEach(fence => {
      const geofence =
        fence.geofenceType === GeofenceType.Polygon
          ? new azureMaps.data.Feature(
              new azureMaps.data.Polygon(setPolygonPoints(fence.polygonPoints)),
              {
                displayName: fence.displayName,
              },
              fence.id
            )
          : new azureMaps.data.Feature(
              new azureMaps.data.Point([
                fence.geofenceRadius.longitude,
                fence.geofenceRadius.latitude,
              ]),
              {
                subType: 'Circle',
                radius: fence.geofenceRadius.radius,
                displayName: fence.displayName,
              },
              fence.id
            );
      source.add(geofence);
    });
  }, [geofences, source]);

  const { filterState } = useDashboardContext() || {};

  useEffect(() => {
    let isMounted = true;

    const fetchGeofenceData = async () => {
      const assetFilters: IntServiceDataSpecificationFilterDto[] = cloneJSON(
        filterState?.assetFilterSpecDtos || []
      );
      const filters = {
        filters: JSON.stringify(assetFilters),
      };
      const response = await getGeofences(
        assetFilters.length > 0 ? filters : {}
      );

      if (response.status === 200 && response.data) {
        const { rows } = response.data;
        isMounted && setGeofences(rows);
      } else {
        isMounted && setGeofences([]);
      }
    };
    showGeofence && fetchGeofenceData();

    return () => {
      isMounted = false;
    };
  }, [filterState?.assetFilterSpecDtos, getGeofences, showGeofence]);

  useEffect(() => {
    geofences && source.clear();
    showGeofence && addGeofencesSource();
  }, [geofences, addGeofencesSource, source, showGeofence]);

  return { geofences, layer, source };
}
