import { snackbar } from 'components/Snackbar';
import { isArray } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Instance } from 'react-table';
import { downLoadWidgetData } from 'utils';
import DownloadDataMenu from './DownloadDataMenu';

interface IProps {
  tableRef: React.MutableRefObject<Instance | undefined>;
  id: string;
  title: string;
  disabled: boolean;
}

function getCellRawValue(cell: any) {
  return isArray(cell) ? cell.join(', ') : cell;
}

const DownloadTableDataMenu: React.FC<IProps> = ({
  tableRef,
  id,
  title,
  disabled,
}) => {
  const { t } = useTranslation('dashboard');

  const downloadFile = (format: 'csv' | 'xlsx') => {
    if (!tableRef.current) {
      snackbar(t('error.export.xlsx'), { variant: 'error' });
    } else {
      // Preparing the data
      const { columns, data } = tableRef.current.getResolvedState();

      let columnIdArray: Array<string> = [];
      let XLSXData: Array<any> = [];

      const headers: Array<string> = columns.map((column: any) => {
        // this will be further used for mapping data and column
        columnIdArray.push(column.id);

        return column.Header.props
          ? column.Header.props.children
          : column.Header;
      });

      /* To have data present in the respective column, since 
        the data we are getting form react-table and the headers 
        are not in the same order
      */
      if (data.length > 0 && columns.length > 0) {
        for (let col of data) {
          let tempData: Array<any> = [];
          for (let i = 0; i < columns.length; i++) {
            tempData[i] = getCellRawValue(col[columnIdArray[i]]);
          }
          XLSXData.push(tempData);
        }
      }

      const fileName = `${title}.${format}`;
      XLSXData.unshift(headers);

      // // Defining cell size
      const cellWidth: { wch: number }[] = [];
      headers.forEach(() => cellWidth.push({ wch: 20 }));

      downLoadWidgetData(XLSXData, cellWidth, fileName);
    }
  };

  return (
    <>
      <DownloadDataMenu
        id={id}
        downloadCSV={() => downloadFile('csv')}
        downloadXLSX={() => downloadFile('xlsx')}
        disabled={disabled}
      />
    </>
  );
};

export default DownloadTableDataMenu;
