import {
  AutomationRuleActionAwsIotCorePayloadType,
  AutomationRuleActionEventHubPayloadType,
  AutomationRuleActionIotHubPayloadType,
  AutomationRuleActionWebHookAuthType,
  AutomationRuleActionWebHookPayloadType,
  IntAutomationActionType,
  IntAutomationRuleType,
  IntAutomationTriggerType,
  IntEntityPropertyName,
  IntFilterEntityType,
  IntTriggerConditionOptionType,
  IntTriggerConditionTimeInterval,
} from 'generated';
import { TFunction } from 'i18next';
import { defaultRestriction, ipAddress } from 'shared/regex';
import {
  areDhGroups,
  isIpAddress,
  isPositiveValue,
  isUniqueInArray,
  Yup,
} from 'utils';
import {
  ActionsPropertiesValidation,
  CustomValidation,
  TriggerConditionsPropertyValidation,
  TriggerConditionValidation,
} from './AutomationRuleValidationTypes';

export const getGeneralSettingsValidation: CustomValidation['getGeneralSettingsValidation'] =
  ({
    requiredFieldValidationText,
    t,
    endpoint,
    customerId,
    initialValues,
    isEditing,
  }) => {
    return {
      displayName: Yup.string()
        .required(requiredFieldValidationText)
        .max(40, t('validation:invalid.automation_rule_name'))
        .trim()
        .test(
          'checkAutomationRuleNameUnique',
          t('validation:already_exists.automation_rule_name'),
          async (automationRuleName = '') => {
            if (
              !isEditing ||
              (isEditing && initialValues.displayName !== automationRuleName)
            ) {
              const response = await endpoint({
                automationRuleName,
                customerId,
              });
              return response.data !== false;
            }
            return true;
          }
        ),
      ruleType: Yup.number().required(requiredFieldValidationText),
      automationRuleFilterType: Yup.number().required(
        requiredFieldValidationText
      ),
      filterEntityType: Yup.number().required(),
    };
  };

export const getEntityStepValidation: CustomValidation['getEntityStepValidation'] =
  ({ requiredFieldValidationText }) => {
    return {
      entities: Yup.array().when(
        ['ruleType', 'filterEntityType'],
        (ruleType, filterEntityType) => {
          if (
            ruleType === IntAutomationRuleType.EntityChanged &&
            filterEntityType !== IntFilterEntityType.Terminal
          ) {
            return Yup.array()
              .min(1, requiredFieldValidationText)
              .required(requiredFieldValidationText);
          } else {
            return Yup.array();
          }
        }
      ),
      assetFilters: Yup.array(),
    };
  };

export const getTriggersSettingsValidation: CustomValidation['getTriggersSettingsValidation'] =
  ({ requiredFieldValidationText, automationStore, t }) => {
    return {
      triggerServiceData: Yup.object().when('$filterEntityType', {
        is: (val: IntFilterEntityType) => {
          return (
            val === IntFilterEntityType.Asset ||
            val === IntFilterEntityType.Terminal
          );
        },
        then: Yup.form({
          triggerCondition: Yup.object()
            .when('triggerType', {
              is: IntAutomationTriggerType.TimeSinceLastServiceData,
              then: Yup.form({
                serviceId: Yup.string().required(requiredFieldValidationText),
                triggerValue: Yup.number()
                  .required(requiredFieldValidationText)
                  .min(
                    1,
                    t(
                      'automation:dialog_create_automation.validation.trigger_time_greater_than_zero'
                    )
                  ),
                cooldown: Yup.number()
                  .min(0)
                  .test(
                    'is-less-than-or-equal',
                    t(
                      'automation:dialog_create_automation.validation.cooldown_less_than_trigger'
                    ),
                    function (cooldown: number | undefined, context: any) {
                      const triggerValue = context.parent?.triggerValue;
                      const triggerInterval = context.parent?.triggerInterval;
                      const cooldownInterval = context.parent?.cooldownInterval;
                      if (
                        typeof cooldown === 'number' &&
                        typeof triggerValue === 'number' &&
                        typeof triggerInterval === 'number' &&
                        typeof cooldownInterval === 'number'
                      ) {
                        return (
                          cooldownInterval < triggerInterval ||
                          cooldown <= triggerValue
                        );
                      }
                      return true; // If one value is not a number, validation will pass
                    }
                  ),
                triggerInterval: Yup.number().required(
                  requiredFieldValidationText
                ),
                cooldownInterval: Yup.number(),
              }),
            })
            .when('triggerType', {
              is: IntAutomationTriggerType.Threshold,
              then: Yup.form({
                serviceId: Yup.string().when('$filterEntityType', {
                  is: IntFilterEntityType.Asset,
                  then: Yup.string().required(requiredFieldValidationText),
                  otherwise: Yup.string().nullable(),
                }),
                servicePropertyId: Yup.string().required(),
                triggerTerminalAttribute: Yup.string().when(
                  '$filterEntityType',
                  {
                    is: IntFilterEntityType.Terminal,
                    then: Yup.string().required(requiredFieldValidationText),
                    otherwise: Yup.string().nullable(),
                  }
                ),
                triggerValue: Yup.number()
                  .required(requiredFieldValidationText)
                  .test(
                    'is-less-than-or-equal',
                    t(
                      'automation:dialog_create_automation.validation.service_property_max_less_than_trigger'
                    ),
                    function (triggerValue: number | undefined, context: any) {
                      const sp = automationStore.getSelectedServiceProperty(
                        context.parent.serviceId,
                        context.parent.servicePropertyId
                      );
                      if (
                        typeof triggerValue === 'number' &&
                        typeof sp?.dataRangeMax === 'number'
                      ) {
                        return triggerValue <= sp.dataRangeMax;
                      }
                      return true; // If one value is not a number, validation will pass
                    }
                  )
                  .test(
                    'is-greater-than-or-equal',
                    t(
                      'automation:dialog_create_automation.validation.service_property_min_greater_than_trigger'
                    ),
                    function (triggerValue: number | undefined, context: any) {
                      const sp = automationStore.getSelectedServiceProperty(
                        context.parent.serviceId,
                        context.parent.servicePropertyId
                      );
                      if (
                        typeof triggerValue === 'number' &&
                        typeof sp?.dataRangeMin === 'number'
                      ) {
                        return triggerValue >= sp.dataRangeMin;
                      }
                      return true; // If one value is not a number, validation will pass
                    }
                  ),
                cooldown: Yup.number(),
                maximumTriggerFrequency: Yup.number().min(
                  0,
                  t(
                    'automation:dialog_create_automation.validation.maximum_frequency_not_minus'
                  )
                ),
              }),
            }),
        }),
      }),
      trigger: Yup.object().when('$filterEntityType', {
        is: IntFilterEntityType.ConnectivityUnit,
        then: Yup.form<TriggerConditionValidation>({
          triggerType: Yup.number()
            .typeError(requiredFieldValidationText)
            .required(requiredFieldValidationText),
          triggerCondition: Yup.object()
            .when('triggerType', {
              is: IntAutomationTriggerType.PropertyChanged,
              then: Yup.form<TriggerConditionsPropertyValidation>({
                propertyName: Yup.number().required(
                  requiredFieldValidationText
                ),
                triggerListValue: Yup.array()
                  .when('propertyName', {
                    is: IntEntityPropertyName.Imei,
                    then: Yup.array().of(
                      Yup.string()
                        .test(
                          'is valid number',
                          t('validation:invalid.format'),
                          value =>
                            !isNaN(parseFloat(value!)) &&
                            isFinite(parseFloat(value!))
                        )
                        .min(
                          8,
                          t('validation:invalid.min_value', { minValue: 8 })
                        )
                        .max(
                          8,
                          t('validation:invalid.max_value', { maxValue: 8 })
                        )
                    ),
                  })
                  .nullable(),
                triggerTypeValue: Yup.string()
                  .when('propertyName', {
                    is: IntEntityPropertyName.Status,
                    then: Yup.string().required(requiredFieldValidationText),
                  })
                  .when('propertyName', {
                    is: IntEntityPropertyName.ActionReason,
                    then: Yup.string().required(requiredFieldValidationText),
                  }),
                triggerConditionOptions: Yup.array().of(
                  getTriggerOptions(requiredFieldValidationText, t)
                ),
              }),
            })
            .when('triggerType', {
              is: IntAutomationTriggerType.Threshold,
              then: Yup.form({
                type: Yup.number()
                  .typeError(requiredFieldValidationText)
                  .required(requiredFieldValidationText),
                propertyName: Yup.number().required(
                  requiredFieldValidationText
                ),
                propertyUnit: Yup.string().required(
                  requiredFieldValidationText
                ),
                triggerUnitValue: Yup.string()
                  .required(requiredFieldValidationText)
                  .test(
                    'is valid number',
                    t('validation:invalid.format'),
                    value => !isNaN(parseFloat(value!))
                  )
                  .test(
                    'is valid number',
                    t('validation:invalid.min_value', { minValue: 0 }),
                    value => isPositiveValue(parseFloat(value!))
                  ),
                timeInterval: Yup.number()
                  .oneOf(
                    [
                      IntTriggerConditionTimeInterval.CurrentDay,
                      IntTriggerConditionTimeInterval.CurrentMonth,
                    ],
                    t('validation:invalid.one_of', {
                      values: [
                        t(
                          `time_intervals.${IntTriggerConditionTimeInterval.CurrentDay}`
                        ),
                        t(
                          `time_intervals.${IntTriggerConditionTimeInterval.CurrentMonth}`
                        ),
                      ],
                    })
                  )
                  .typeError(requiredFieldValidationText)
                  .required(requiredFieldValidationText),
                triggerConditionOptions: Yup.array().of(
                  getTriggerOptions(requiredFieldValidationText, t)
                ),
              }),
            }),
        }),
      }),
    };
  };

export const getTriggerOptions = (
  requiredFieldValidationText: string,
  t: TFunction
) => {
  return Yup.object({
    type: Yup.number(),
    timeInterval: Yup.number().when('type', {
      is: (type: number) => type === IntTriggerConditionOptionType.Timer,
      then: Yup.number().required(requiredFieldValidationText),
    }),
    timeValue: Yup.number().when('type', {
      is: (type: number) => type === IntTriggerConditionOptionType.Timer,
      then: Yup.number()
        .required(requiredFieldValidationText)
        .test(
          'is valid number',
          t('validation:invalid.format'),
          value => value !== undefined && !isNaN(value)
        )
        .test(
          'is valid number',
          t('validation:invalid.min_value', { minValue: 1 }),
          value => value !== undefined && value >= 1
        ),
    }),
  }).nullable();
};

const getWebhookValidation = (
  requiredFieldValidationText: string,
  t: TFunction
) => {
  return Yup.object({
    url: Yup.string().required().url(t('validation:invalid.url')),
    httpMethod: Yup.number(),
    payloadType: Yup.number(),
    authenticationType: Yup.number(),
    payload: Yup.string().when(['payloadType'], {
      is: (payloadType: AutomationRuleActionWebHookPayloadType) =>
        payloadType !== AutomationRuleActionWebHookPayloadType.None,
      then: Yup.string().required(requiredFieldValidationText),
    }),
    username: Yup.string().when(['authenticationType'], {
      is: (authenticationType: AutomationRuleActionWebHookAuthType) =>
        authenticationType ===
          AutomationRuleActionWebHookAuthType.BasicAuthentication ||
        authenticationType === AutomationRuleActionWebHookAuthType.OAuth2,
      then: Yup.string().required(requiredFieldValidationText),
    }),
    password: Yup.string().when(['authenticationType'], {
      is: (authenticationType: AutomationRuleActionWebHookAuthType) =>
        authenticationType ===
          AutomationRuleActionWebHookAuthType.BasicAuthentication ||
        authenticationType === AutomationRuleActionWebHookAuthType.OAuth2,
      then: Yup.string().required(requiredFieldValidationText),
    }),
    tokenEndpoint: Yup.string().when('authenticationType', {
      is: AutomationRuleActionWebHookAuthType.OAuth2,
      then: Yup.string().required(requiredFieldValidationText),
    }),
    grantType: Yup.string().when('authenticationType', {
      is: AutomationRuleActionWebHookAuthType.OAuth2,
      then: Yup.string().required(requiredFieldValidationText),
    }),
    accessToken: Yup.string().when('authenticationType', {
      is: AutomationRuleActionWebHookAuthType.BearerToken,
      then: Yup.string().required(requiredFieldValidationText),
    }),
    customHeaders: Yup.array().of(
      Yup.object().shape({
        webhookCustomHeaderId: Yup.string(),
        header: Yup.string(),
        value: Yup.string(),
      })
    ),
  });
};

const getAwsIotCoreValidation = (
  requiredFieldValidationText: string,
  _t: TFunction
) => {
  return Yup.object().when('actionType', {
    is: IntAutomationActionType.AwsIoTCore,
    then: Yup.object({
      payloadType: Yup.number()
        .oneOf([
          AutomationRuleActionAwsIotCorePayloadType.Json,
          AutomationRuleActionAwsIotCorePayloadType.None,
        ])
        .required(requiredFieldValidationText),
      payload: Yup.string().when('payloadType', {
        is: AutomationRuleActionAwsIotCorePayloadType.Json,
        then: Yup.string().required(requiredFieldValidationText),
      }),
      topic: Yup.string().required(requiredFieldValidationText),
      certificateIdentifier: Yup.string().required(requiredFieldValidationText),
      endpoint: Yup.string().required(requiredFieldValidationText),
      thingId: Yup.string().required(requiredFieldValidationText),
    }),
  });
};

const getEventHubValidation = (requiredFieldValidationText: string) => {
  return Yup.object().when('actionType', {
    is: IntAutomationActionType.EventHub,
    then: Yup.object({
      payloadType: Yup.number()
        .oneOf([
          AutomationRuleActionEventHubPayloadType.Json,
          AutomationRuleActionEventHubPayloadType.None,
        ])
        .required(requiredFieldValidationText),
      payload: Yup.string().when('payloadType', {
        is: AutomationRuleActionEventHubPayloadType.Json,
        then: Yup.string().required(requiredFieldValidationText),
      }),
      eventHubName: Yup.string().required(requiredFieldValidationText),
      eventHubConnectionString: Yup.string().required(
        requiredFieldValidationText
      ),
    }),
  });
};

export const getIotHubValidation = (requiredFieldValidationText: string) => {
  return Yup.object().when('actionType', {
    is: IntAutomationActionType.IoTHub,
    then: Yup.object({
      payloadType: Yup.number()
        .oneOf([
          AutomationRuleActionIotHubPayloadType.Json,
          AutomationRuleActionIotHubPayloadType.None,
        ])
        .required(requiredFieldValidationText),
      payload: Yup.string().when('payloadType', {
        is: AutomationRuleActionAwsIotCorePayloadType.Json,
        then: Yup.string().required(requiredFieldValidationText),
      }),
      connectionString: Yup.string().required(requiredFieldValidationText),
    }),
  });
};

const getGooglePubSubValidation = (requiredFieldValidationText: string) => {
  return Yup.object().when('actionType', {
    is: IntAutomationActionType.GoogleCloudPubSub,
    then: Yup.object().shape({
      projectId: Yup.string().required(requiredFieldValidationText),
      topic: Yup.string().required(requiredFieldValidationText),
      connectionIdentifier: Yup.string().required(requiredFieldValidationText),
    }),
  });
};

const serviceDataSchema = (
  requiredFieldValidationText: string,
  t: TFunction
) => {
  return Yup.array().of(
    Yup.form<ActionsPropertiesValidation>({
      actionType: Yup.number()
        .typeError(requiredFieldValidationText)
        .required(requiredFieldValidationText),
      sendTo: Yup.array()
        .when('actionType', {
          is: IntAutomationActionType.SendEmail,
          then: Yup.array()
            .of(Yup.string().email(t('validation:invalid.email')))
            .isUnique(value => value),
        })
        .when('actionType', {
          is: IntAutomationActionType.SendSms,
          then: Yup.array()
            .of(Yup.string().isPhone())
            .isUnique(value => value),
        }),
      eventBody: Yup.string().when('actionType', {
        is: IntAutomationActionType.CreateEvent,
        then: Yup.string().required(),
      }),
      bodyTemplate: Yup.string().when(['actionType', 'useDefaultBody'], {
        is: (actionType: IntAutomationActionType, useDefaultBody: boolean) =>
          (actionType === IntAutomationActionType.SendSms ||
            actionType === IntAutomationActionType.SendEmail) &&
          !useDefaultBody,
        then: Yup.string()
          .required(requiredFieldValidationText)
          .typeError(requiredFieldValidationText),
        otherwise: Yup.string().nullable(),
      }),
      webhook: Yup.object().when('actionType', {
        is: IntAutomationActionType.Webhook,
        then: getWebhookValidation(requiredFieldValidationText, t),
      }),
      awsIoTCore: Yup.object().when('actionType', {
        is: IntAutomationActionType.AwsIoTCore,
        then: getAwsIotCoreValidation(requiredFieldValidationText, t),
      }),
      eventHub: Yup.object().when('actionType', {
        is: IntAutomationActionType.EventHub,
        then: getEventHubValidation(requiredFieldValidationText),
      }),
      ioTHub: Yup.object().when('actionType', {
        is: IntAutomationActionType.IoTHub,
        then: getIotHubValidation(requiredFieldValidationText),
      }),
      googleCloudPubSub: Yup.object().when('actionType', {
        is: IntAutomationActionType.GoogleCloudPubSub,
        then: getGooglePubSubValidation(requiredFieldValidationText),
      }),
    })
  );
};

const entitySchema = (requiredFieldValidationText: string, t: Function) => {
  return Yup.array().of(
    Yup.form<ActionsPropertiesValidation>({
      actionType: Yup.number()
        .typeError(requiredFieldValidationText)
        .required(requiredFieldValidationText),
      sendTo: Yup.array()
        .when('actionType', {
          is: IntAutomationActionType.SendEmail,
          then: Yup.array()
            .min(1, requiredFieldValidationText)
            .of(Yup.string().email(t('validation:invalid.email')).required())
            .isUnique(value => value),
        })
        .when('actionType', {
          is: IntAutomationActionType.SendConnectivtyUnitChangeEvent,
          then: Yup.array().when('notifyConnectivityUnitActionByEmail', {
            is: true,
            then: Yup.array()
              .min(1, requiredFieldValidationText)
              .of(Yup.string().email(t('validation:invalid.email')).required())
              .isUnique(value => value),
          }),
        })
        .when('actionType', {
          is: IntAutomationActionType.SendSms,
          then: Yup.array()
            .required()
            .min(1)
            .of(Yup.string().required().isPhone())
            .isUnique(value => value),
        }),
      bodyTemplate: Yup.string().when('actionType', {
        is: (actionType: IntAutomationActionType) =>
          actionType === IntAutomationActionType.SendEmail ||
          actionType === IntAutomationActionType.SendSms,
        then: Yup.string().required(),
      }),
      connectivityUnitEventType: Yup.number()
        .nullable()
        .when('actionType', {
          is: IntAutomationActionType.SendConnectivtyUnitChangeEvent,
          then: Yup.number().nullable().required(requiredFieldValidationText),
        }),
    })
  );
};

export const getActionsSettingsValidation: CustomValidation['getActionsSettingsValidation'] =
  ({ requiredFieldValidationText, t }) => {
    return {
      actions: Yup.array().when('$filterEntityType', {
        is: (val: IntFilterEntityType) => {
          return (
            val === IntFilterEntityType.Asset ||
            val === IntFilterEntityType.Terminal
          );
        },
        then: serviceDataSchema(requiredFieldValidationText, t),
        otherwise: entitySchema(requiredFieldValidationText, t),
      }),
    };
  };

export const getYupSchema = (
  fieldName: string,
  fieldValues: string[],
  t: TFunction,
  type: string
) => {
  const invalidFormatString = t('validation:invalid.format');
  const invalidDHFormatString = t('validation:invalid.format_dh_group');
  const duplicateContent = t('validation:invalid.duplicate');

  const isDuplicateContent = (value: string) =>
    isUniqueInArray(value, fieldValues);

  if (type === 'email') {
    return Yup.string()
      .email()
      .test('duplicateContent', duplicateContent, (value = '') =>
        isDuplicateContent(value)
      );
  }

  if (fieldName.includes('dhGroups')) {
    return Yup.string()
      .test('dhGroups', invalidDHFormatString, (groups = '') =>
        areDhGroups(groups)
      )
      .test('duplicateContent', duplicateContent, (value = '') =>
        isDuplicateContent(value)
      );
  }

  if (fieldName === 'networks') {
    return Yup.string()
      .test('network', invalidFormatString, (network = '') =>
        isIpAddress(network, defaultRestriction)
      )
      .test('duplicateContent', duplicateContent, (value = '') =>
        isDuplicateContent(value)
      );
  }

  if (fieldName === 'nameServers') {
    return Yup.string()
      .required()
      .test('name server', invalidFormatString, (ipAdress = '') =>
        ipAddress.test(ipAdress)
      )
      .test('duplicateContent', duplicateContent, (value = '') =>
        isDuplicateContent(value)
      );
  }

  return Yup.mixed().notRequired(); // Return a schema that allows any value
};
