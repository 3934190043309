import { aiTrackEvent } from 'appInsights';
import { snackbar } from 'components/Snackbar';
import { TableData } from 'components/Table';
import {
  IntCustomerLinkDto,
  IntCustomerProfileDto,
  IntCustomerTableDto,
  IntDefaultProfileDto,
  IntEditCustomerProfileDto,
} from 'generated';
import i18n from 'i18n';
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { apnHubAPI } from 'services/apnHub.service';
import { customerAPI } from 'services/customer.service';
import { productTemplateAPI } from 'services/productTemplate.service';
import { IntProductTemplateOptionDto } from './../generated/index';
import { RootStore } from './rootStore';
import { StoreBase } from './storeBase';

type LoadingState = {
  customerInfo: boolean;
  customerAgreements: boolean;
  links: boolean;
  productTemplates: boolean;
};

type ErrorState = {
  customerInfo?: string;
  customerAgreements?: string;
};

type ModalOpenState = {
  editCustomerInfo: boolean;
  editExternalIdentifiers: boolean;
  editProductTemplates: boolean;
  editServiceFilters: boolean;
};

const errorOccurred = i18n.t('common:error.occurred');

export class CustomerStore extends StoreBase {
  @observable customerInfo: IntCustomerProfileDto | undefined;
  @observable customerAgreements: IntDefaultProfileDto | undefined;
  @observable links: IntCustomerLinkDto[] | undefined;
  @observable showChangeProfileInfoModal = false;
  @observable productTemplates: IntProductTemplateOptionDto[] | undefined;
  @observable errorFetchingTemplates = false;
  @observable error: ErrorState = {};
  @observable loading: LoadingState = {
    customerInfo: false,
    customerAgreements: false,
    links: false,
    productTemplates: false,
  };
  @observable modals: ModalOpenState = {
    editCustomerInfo: false,
    editExternalIdentifiers: false,
    editProductTemplates: false,
    editServiceFilters: false,
  };

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this);
  }

  @observable customerTableData?: TableData<IntCustomerTableDto> = undefined;

  getCustomerInitiated = false;

  @computed get availableCustomers() {
    if (!this.getCustomerInitiated) {
      this.getCustomers();
    }
    return this.customerTableData?.rows || [];
  }

  @computed get availableCustomerTable() {
    if (!this.getCustomerInitiated) {
      this.getCustomers();
    }
    return this.customerTableData;
  }

  @action.bound async getCustomers() {
    this.getCustomerInitiated = true;

    const response = await this.httpGet(
      customerAPI.getAll,
      {
        customerIds: [],
        page: 0,
        pageSize: 999999,
        orderBy: null,
        orderDesc: false,
        search: '',
        filters: '',
      },
      this.setIsLoading
    );

    runInAction(() => {
      if (response.status === 200 && response.data) {
        this.customerTableData = response.data;
      } else if (![204, 401, 403].includes(response.status)) {
        snackbar(i18n.t('enterprise:error.get_customers'), {
          variant: 'error',
        });
      }
    });
  }

  @action.bound async getCustomerAgreements(customerId: string) {
    this.customerAgreements = undefined;
    this.error.customerAgreements = undefined;

    const response = await this.httpGet(
      apnHubAPI.getTrafficStatus,
      customerId,
      loading => {
        this.setLoading('customerAgreements', loading);
      }
    );

    if ([200, 204].includes(response.status)) {
      runInAction(() => {
        this.customerAgreements = response.data || undefined;
      });
    } else {
      runInAction(() => {
        this.error.customerAgreements = response?.statusText || errorOccurred;
      });
    }
  }

  @action.bound setLoading(type: keyof LoadingState, loading: boolean) {
    this.loading[type] = loading;
  }

  @action.bound async getCustomerInfo(customerId: string) {
    this.customerInfo = undefined;
    this.error.customerInfo = undefined;

    const response = await this.httpGet(
      customerAPI.getCustomerInfo,
      customerId,
      loading => {
        this.setLoading('customerInfo', loading);
      }
    );

    if (response.status === 200 && response.data) {
      runInAction(() => {
        this.customerInfo = response.data;
      });
    } else {
      runInAction(() => {
        this.error.customerInfo = response?.statusText || errorOccurred;
      });
    }
  }

  @action.bound async updateCustomerInfo(
    updatedInfo: IntEditCustomerProfileDto
  ) {
    this.error.customerInfo = undefined;

    const customerId = this.customerInfo?.customerId || '';
    const resp = await this.httpPost(
      customerAPI.updateCustomerInfo,
      {
        params: customerId,
        data: updatedInfo,
      },
      loading => {
        this.setLoading('customerInfo', loading);
      }
    );

    if (resp.status === 200) {
      this.closeModal('editCustomerInfo');
      await this.getCustomerInfo(customerId);
      if (
        this.customerInfo?.customerId ===
        this.rootStore.authStore.user?.customerId
      ) {
        this.rootStore.authStore.user?.updateCustomerLogo(
          this.customerInfo?.customerLogo || ''
        );
      }

      aiTrackEvent('Update', { title: 'Enterprise' });

      snackbar(i18n.t('profile:message.user_update.success'), {
        variant: 'success',
      });
    } else {
      snackbar(
        i18n.t('profile:message.user_update.error', {
          reason: resp.statusText,
        }),
        {
          variant: 'error',
        }
      );
    }
  }

  @action.bound openModal(modal: keyof ModalOpenState) {
    this.modals[modal] = true;
  }

  @action.bound closeModal(modal: keyof ModalOpenState) {
    this.modals[modal] = false;
  }

  @action.bound async getLinks() {
    const resp = await this.httpGet(
      customerAPI.getLinks,
      this.customerInfo?.customerId || '',
      loading => {
        this.loading.links = loading;
      }
    );
    if ([200, 204].includes(resp.status)) {
      this.links = resp.data || [];
    } else {
      snackbar(resp.statusText, { variant: 'error' });
    }
  }

  @action.bound async updateLinks(data: IntCustomerLinkDto[]) {
    const resp = await this.httpPost(
      customerAPI.updateLinks,
      { params: this.customerInfo?.customerId || '', data },
      loading => {
        this.loading.links = loading;
      }
    );

    this.closeModal('editExternalIdentifiers');

    if (resp.status === 200) {
      snackbar(i18n.t('profile:validation.submit_links_success'), {
        variant: 'success',
      });
    } else {
      runInAction(() => {
        this.links = [];
      });
      snackbar(resp.statusText, { variant: 'error' });
    }
  }

  @action.bound async getProductTemplates(customerId: string) {
    const response = await this.httpGet(
      productTemplateAPI.getCustomerTemplateOptions,
      customerId,
      loading => {
        this.setLoading('productTemplates', loading);
      }
    );

    if (response.status === 200) {
      runInAction(() => {
        this.errorFetchingTemplates = false;
        this.productTemplates = response.data;
      });
    } else {
      runInAction(() => {
        this.errorFetchingTemplates = true;
      });
    }
  }

  @action.bound async updateProductTemplates(
    templatesIds: string[],
    customerId: string
  ) {
    const response = await this.httpPost(
      productTemplateAPI.updateCustomerTemplateOptions,
      {
        params: customerId,
        data: templatesIds,
      },
      loading => {
        this.setLoading('productTemplates', loading);
      }
    );

    if (response.status === 200) {
      this.rootStore.layoutStore.getNavigation();
      snackbar(i18n.t('profile:validation.submit_product_templates_success'), {
        variant: 'success',
      });
    } else {
      snackbar(i18n.t('profile:message.customer_settings.error.update'), {
        variant: 'error',
      });
    }

    this.closeModal('editProductTemplates');
  }
}
