import * as azureMaps from 'azure-maps-control';
import AtlasIcon from 'components/AtlasIcon';
import AtlasIconButton from 'components/AtlasIconButton';
import MessageOverlay from 'components/MessageOverlay';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMapStyles } from './AzureMap.styles';
import { useGeofence } from './hooks/useGeofence';

interface IProps {
  map: azureMaps.Map;
  mapDataExists: boolean;
  showGeofenceVisibilityIcon?: boolean;
}

interface IconProps {
  showLayer: boolean;
  theme: string;
}

const VisibilityIcon = (props: IconProps) => {
  const darkThemes = [
    'night',
    'satellite',
    'grayscale_dark',
    'high_contrast_dark',
  ];

  return (
    <AtlasIcon
      type={props.showLayer ? 'PasswordVisible' : 'PasswordInvisible'}
      htmlColor={darkThemes.includes(props.theme) ? '#FFF' : '#000'}
    />
  );
};

const GeofenceLayer: React.FC<IProps> = ({
  map,
  mapDataExists,
  showGeofenceVisibilityIcon = false,
}) => {
  const classes = useMapStyles();
  const { t } = useTranslation('dashboard');
  const [mapStyle, setMapStyle] = useState(map.getStyle().style || 'roads');
  const [showLayer, setShowLayer] = useState(true);
  const { geofences, layer, source } = useGeofence(showGeofenceVisibilityIcon);

  useEffect(() => {
    map.events.remove('styledata', () =>
      setMapStyle(map.getStyle().style || 'roads')
    );

    if (showLayer) {
      map.sources.add(source);
      map.layers.add(layer, 'layers');
    } else if (map.sources.getSources().includes(source)) {
      map.layers.remove(['polygonLayer', 'lineLayer', 'symbolLayer']);
      map.sources.remove(source);
    }
    return () => {
      try {
        map.events.remove('styledata', () =>
          setMapStyle(map.getStyle().style || 'roads')
        );
        map.layers.remove(['polygonLayer', 'lineLayer', 'symbolLayer']);
        map.sources.remove(source);
      } catch (error) {}
    };
  }, [map, source, layer, showLayer]);

  const notFound = !mapDataExists && <MessageOverlay message={t('no_data')} />;
  const geofenceVisibilityIcon =
    Boolean(geofences.length) && showGeofenceVisibilityIcon ? (
      <AtlasIconButton
        onClick={() => setShowLayer(!showLayer)}
        classes={{
          root: classes.toggle,
        }}
      >
        <VisibilityIcon showLayer={showLayer} theme={mapStyle} />
      </AtlasIconButton>
    ) : null;

  return (
    <>
      {notFound}
      {geofenceVisibilityIcon}
    </>
  );
};

export default GeofenceLayer;
