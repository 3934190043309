import {
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup as MUIRadioGroup,
} from '@mui/material';
import { useField } from 'formik';
import React from 'react';
import { Option } from '..';

interface IProps<TValue> {
  name: string;
  options: Option<TValue>[];
  label?: string;
  parse?: (value: string) => TValue;
  className?: string;
  labelClassName?: string;
  row?: boolean;
}

function RadioGroup<TValue>(props: IProps<TValue>) {
  const {
    name,
    options,
    label,
    parse,
    className,
    labelClassName,
    row = false,
  } = props;
  const [field, , helpers] = useField(name);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parsedValue = parse ? parse(e.target.value) : e.target.value;
    helpers.setValue(parsedValue);
  };

  React.useEffect(() => {
    if (options.length === 1) {
      helpers.setValue(+options[0]?.value);
    }
    // eslint-disable-next-line
  }, [JSON.stringify(props)]);

  return (
    <FormGroup data-cy={name}>
      <FormLabel className={labelClassName}>{label}</FormLabel>
      <MUIRadioGroup
        {...field}
        onChange={handleChange}
        className={className}
        row={row}
      >
        {options?.map(opt => (
          <FormControlLabel
            key={`${name}_${opt.value}`}
            value={opt.value}
            control={<Radio color="primary" />}
            label={opt.label}
            disabled={opt.disabled}
          />
        ))}
      </MUIRadioGroup>
    </FormGroup>
  );
}

export default RadioGroup;
