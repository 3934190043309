import { useSnackbar, WithSnackbarProps } from 'notistack';
import React from 'react';

export let useSnackbarRef: WithSnackbarProps;

const setUseSnackbarRef = (useSnackbarRefProp: WithSnackbarProps) => {
  useSnackbarRef = useSnackbarRefProp;
};

export const SnackbarUtilsConfigurator: React.FC = ({ children }) => {
  setUseSnackbarRef(useSnackbar());

  return <>{children}</>;
};
