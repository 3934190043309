import { IColumnAggregationProps } from './IColumnAggregationProps';
import { TypedAggregator } from './TypedAggregator';

const aggregateMaxNullableValue: TypedAggregator<
  number | null,
  number | null
> = values => {
  let max: number | null = null;
  values.forEach(value => {
    if (value !== null && (max === null || value > max)) {
      max = value;
    }
  });
  return max;
};

export const numberMaxAggregationProps: IColumnAggregationProps = {
  aggregate: aggregateMaxNullableValue,
};
