import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactTable, { TableProps } from 'react-table';
import 'react-table/react-table.css';
import { useStyles } from './Table.styles';

const Table = React.forwardRef<any, Partial<TableProps>>((props, ref) => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const paginationTranslations = {
    nextText: '',
    previousText: '',
    // nextText: t('table.next'),
    // previousText: t('table.previous'),
    pageText: t('table.page'),
    ofText: t('table.of').toLowerCase(),
    rowsText: t('table.rows').toLowerCase(),
  };

  return (
    <ReactTable
      {...props}
      {...paginationTranslations}
      className={classNames(classes.root, props.className)}
      ref={ref}
    />
  );
});

export default Table;
