export enum EFeature {
  EnablePreviewFeatures = 'AtlasEnablePreviewFeatures',
  EnableSupportTicket = 'AtlasEnableSupportTicket',
  EnableMyDashboard = 'AtlasEnableMyDashboard',
  EnableDashboards = 'AtlasEnableDashboards',
  EnableManagement = 'AtlasEnableManagement',
  EnableLanguageSelector = 'AtlasEnableLanguageSelector',
  EnableRedirectToSubPortal = 'AtlasEnableRedirectToSubPortal',
  ConnectivityEnableSimInventory = 'AtlasConnectivityEnableSimInventory',
  ConnectivityEnableReports = 'AtlasConnectivityEnableReports',
  ConnectivityEnableSimInventoryStatistics = 'AtlasConnectivityEnableSimInventoryStatistics',
  ConnectivityEnableSimOrders = 'AtlasConnectivityEnableSimOrders',
  ConnectivityEnableSimGroups = 'AtlasConnectivityEnableSimGroups',
  ConnectivityEnableSmsHistory = 'AtlasConnectivityEnableSmsHistory',
  ConnectivityEnableTriggersAndRules = 'AtlasConnectivityEnableTriggersAndRules',
  ConnectivityEnableIpSettings = 'AtlasConnectivityEnableIpSettings',
}
