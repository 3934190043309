import { Dashboard } from 'store/domains/dashboard';
import { AnalyticsCubeDataSource } from './AnalyticsCube/AnalyticsCubeDataSource';
import { AutomationRuleActivityDataSource } from './Automation/AutomationActivity/automationRuleActivityDataSource';
import { AutomationDataSource } from './Automation/AutomationStatus/AutomationRuleStatusDataSource';
import { simActivityDataSource } from './Connectivity/SimActivity/SimActivityDataSource';
import { simActivityByCountryDataSource } from './Connectivity/SimActivityByCountry/SimActivityByCountryDataSource';
import { simGroupActivityDataSource } from './Connectivity/SimGroupActivity/SimGroupActivityDataSource';
import { SimStatusDataSource } from './Connectivity/SimStatus/SimStatusDataSource';
import { IDataSourceSettings, IDataSourceStore } from './dataSourceTypes';
import { IntelligenceEventsDataSource } from './IntelligenceEvents/intelligenceEventsDataSource';
import { ServiceDataSource } from './ServiceData/ServiceDataSource';

export function getDataSource(
  dashboard: Dashboard,
  dataSourceSettings: IDataSourceSettings
): IDataSourceStore<any> {
  if (!dataSourceSettings) {
    throw new Error('Data source missing');
  }

  switch (dataSourceSettings.type) {
    case 'service':
    case 'serviceLatest':
      return new ServiceDataSource(dashboard, dataSourceSettings);
    case 'analyticsCube':
      return new AnalyticsCubeDataSource(dashboard, dataSourceSettings);
    case 'simStatus':
      return new SimStatusDataSource(dashboard, dataSourceSettings);
    case 'intelligenceEvents':
      return new IntelligenceEventsDataSource(dashboard, dataSourceSettings);
    case 'simActivity':
      return simActivityDataSource(dashboard, dataSourceSettings);
    case 'simGroupActivity':
      return simGroupActivityDataSource(dashboard, dataSourceSettings);
    case 'simActivityByCountry':
      return simActivityByCountryDataSource(dashboard, dataSourceSettings);
    case 'automationRuleActivity':
      return new AutomationRuleActivityDataSource(
        dashboard,
        dataSourceSettings
      );
    case 'automationRuleStatus':
      return new AutomationDataSource(dashboard, dataSourceSettings);
    default:
      throw new Error('Invalid data source type');
  }
}
