export const emptyGuid = '00000000-0000-0000-0000-000000000000'; // Todo: shouldn't be needed - rework the API endpoint (also, combine user and roles into a single endpoint)

// Component to convert the role const strings in tc-iot-platform\Messify Operate\Messify Operate\Identity\Managers\MessifyRoleManager.cs to a typescript enum.
// Only for development
// export const ParseRoles: React.FC = () => {
//   const [v, setV] = useState('');
//   const [parsedValue, setParsedValue] = useState('');
//
//   const parse = () => {
//     // let result = 'const allRoles = {\n';
//     let result = 'export enum allRoles {\n';
//     const regex = /public const string (\w+) = "([^"]+)"/gm;
//     let m: any;
//
//     while ((m = regex.exec(v)) !== null) {
//       if (m.index === regex.lastIndex) {
//         regex.lastIndex++;
//       }
//
//       // result += `  ${m[1]}: '${m[2]}',\n`;
//       result += `  ${m[1]} = '${m[2]}',\n`;
//     }
//     result += '};';
//
//     setParsedValue(result);
//   };
//
//   return (
//     <div
//       style={{
//         position: 'fixed',
//         left: 10,
//         top: 10,
//         padding: 20,
//         right: 10,
//         backgroundColor: '#FFF'
//       }}
//     >
//       <textarea
//         value={v}
//         onChange={e => setV(e.target.value)}
//         className="form-control"
//         rows={20}
//       />
//       <button onClick={parse}>Parse</button>
//       <textarea
//         value={parsedValue}
//         onChange={e => setParsedValue(e.target.value)}
//         className="form-control"
//         rows={20}
//       />
//     </div>
//   );
// };
