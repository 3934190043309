import { useEffect, useMemo } from 'react';
import { useDashboardContext } from '../contexts/dashboardContext';
import { IDataSourceSettings, IDataSourceStore } from './dataSourceTypes';
import { getDataSource } from './getDataSource';

export function useDataSource<TSettings extends IDataSourceSettings>(
  settings: IDataSourceSettings
): IDataSourceStore<TSettings> {
  const dashboard = useDashboardContext();

  const dataSource = useMemo(
    () => getDataSource(dashboard, settings),
    [dashboard, settings]
  );

  useEffect(() => {
    dataSource.initialize?.();

    return () => dataSource.destroy?.();
  }, [dataSource]);

  return dataSource;
}
