import { Option } from 'components/FormFields';
import { IntConnectivityType } from 'generated';
import i18n from 'i18next';
import { Column } from 'react-table';
import { FilterOption } from '../Helpers/filterOption';
import { getOptionFilter } from '../Helpers/getOptionFilter';

export function productType<T>(options: {
  id: string;
  accessor: (row: T) => IntConnectivityType;
  header?: string;
  extraFilterOptions: Option<IntConnectivityType>[] | undefined;
  defaultShow?: boolean;
}): Column<T> {
  const {
    id,
    header = 'Product',
    accessor,
    extraFilterOptions,
    defaultShow: show = true,
  } = options;

  const filterOptions: FilterOption[] = [
    { label: i18n.t('sms:label.all'), value: '' },
  ];

  if (extraFilterOptions) {
    extraFilterOptions.forEach(item => {
      if (item.value !== IntConnectivityType.Unknown) {
        filterOptions.push({
          label: i18n.t(`sim:connectivity_unit.product.${item.value}`),
          value: IntConnectivityType[item.value],
        });
      }
    });
  }

  return {
    id,
    Header: header,
    accessor,
    resizable: true,
    width: 100,
    show,
    Filter: getOptionFilter(filterOptions),
    Cell: row => i18n.t(`sim:connectivity_unit.product.${row.value}`),
  };
}
