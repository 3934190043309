import { Theme } from '@mui/material';
import { Components, createTheme } from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import { CSSProperties } from '@mui/styles';
import { fontSettings } from './fontSettings';
import { IAtlasThemeSettings } from './IAtlasThemeSettings';
import { ThemeComponents } from './themeComponents';

export interface IExtendedTypographyOptions extends TypographyOptions {
  headerFontFamily?: string;
  headerTextTransform?: 'uppercase'; // TS makes a fuss if simple string when using this value to set textTransform in a *.styles.ts file. Add more options here as needed
  bodyLarge: CSSProperties;
}

const dialog = {
  padding: 36,
};

export const pxToRem = (pixels: number) => {
  return `${pixels / 16}rem`;
};

export function muiButtonComponents(components: ThemeComponents): Components {
  return {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: components.button.root,
        text: components.button.text,
        textPrimary: components.button.textPrimary,
        containedPrimary: components.button.containedPrimary,
        outlinedPrimary: components.button.outlinedPrimary,
        contained: components.button.contained,
        outlined: components.button.outlined,
        sizeSmall: components.button.sizeSmall,
        outlinedSizeSmall: components.button.outlinedSizeSmall,
        containedSizeSmall: components.button.containedSizeSmall,
        textSizeSmall: components.button.textSizeSmall,
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: components.iconButton.root.padding,
          '&:focus': {
            outline: 'none',
          },
          '&:hover': {
            backgroundColor:
              components.iconButton.hoverBackgroundColor.backgroundColor,
          },
        },
        sizeSmall: {
          padding: 3,
          fontSize: '1.125rem',
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        contained: {
          boxShadow: 'none',
        },
      },
    },
  };
}

export function muiFormComponents(
  spacing: (factor: number) => number
): Components {
  return {
    MuiFormControlLabel: {},
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          lineHeight: 'inherit',
          marginTop: '5px',
          fontSize: '16px',
          transform: 'translate(12px, 12px)',
          transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
          '&.MuiInputLabel-shrink': {
            fontSize: '12px',
            transform: 'translate(12px, 0px)',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        tag: {
          marginTop: spacing(0.75),
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          height: '1.1876em',
        },
        root: {
          background: '#FFF',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          minHeight: 'auto',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 2,
          '&.Mui-disabled': {
            backgroundColor: '#f3f3f3',
          },
        },
        input: {
          padding: '24px 12px 10px 12px',
          fontSize: '16px',
          overflowY: 'hidden',
          lineHeight: '18px',
        },
        multiline: {
          padding: 0,
        },
        notchedOutline: {
          top: 0,
          borderWidth: '1px !important', // Not the most elegant?
          '& legend': {
            display: 'none', // removes outline notch - labels are inside
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& + &, & + .MuiAutocomplete-root, .MuiAutocomplete-root + &': {
            marginTop: 20,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '.7rem',
          fontFamily: fontSettings.TeliaSansReg.fontStack,
          fontWeight: 'normal',
          minHeight: 0,
          lineHeight: 'normal',
        },
        contained: {
          margin: '5px 12px 12px',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: 12,
        },
      },
    },
  };
}

export function muiDialogComponents(): Components {
  return {
    MuiDialog: {
      styleOverrides: {
        paper: {
          width: '100%',
        },
        paperWidthMd: {
          maxWidth: 1024,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          display: 'flex',
          justifyContent: 'space-between',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: `${dialog.padding / 2}px ${dialog.padding}px`,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: dialog.padding,
          '&:not(:first-of-type)': {
            paddingTop: 0,
          },
        },
      },
    },
  };
}

export function muiCardComponents(): Components {
  return {
    MuiCard: {
      styleOverrides: {
        root: {
          overflow: 'hidden',
          boxShadow: 'none',
          border: '1px solid rgba(0, 0, 0, 0.125)',
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: '6px 18px',
        },
        title: {
          textTransform: 'uppercase',
          lineHeight: '24px',
          fontSize: '0.875rem',
          fontWeight: 'bold',
        },
        action: {
          marginTop: 0,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 18,
        },
      },
    },
  };
}

export function makeAtlasTheme(settings: IAtlasThemeSettings): Theme {
  const { assets, components } = settings;
  const spacing = (factor: number) => factor * settings.spacingFactor;
  const muiButton = muiButtonComponents(components);
  const muiForm = muiFormComponents(spacing);
  const muiDialog = muiDialogComponents();
  const muiCard = muiCardComponents();

  const theme = createTheme({
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          fallbacks: assets.fontFaces?.map(ff => ({ '@font-face': ff })) || [],
          body: components.body,
          '.MuiAutocomplete-root + .MuiAutocomplete-root': {
            marginTop: 20,
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          gutters: {
            paddingLeft: spacing(2),
            paddingRight: spacing(2),
          },
        },
      },
      //Button
      ...muiButton,

      // Form fields
      ...muiForm,

      MuiPaper: {
        styleOverrides: {
          rounded: {
            borderRadius: 0, // Not. Good.
          },
        },
      },

      //Dialog
      ...muiDialog,

      MuiDivider: {
        styleOverrides: {
          root: {
            backgroundColor: settings.palette.divider,
          },
          middle: {
            marginLeft: spacing(4),
            marginRight: spacing(4),
          },
        },
      },

      //Card
      ...muiCard,

      MuiFab: {
        styleOverrides: {
          root: components.fab.root,
          primary: components.fab.primary,
          extended: {
            padding: '0 20px',
          },
        },
      },
      MuiStepper: {
        styleOverrides: {
          root: {
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: spacing(10),
            '&:first-of-type': {
              paddingTop: 0,
            },
            '& .MuiStepLabel-active': {
              color: '#990ae3',
            },
            '& .MuiStepLabel-label': {
              fontSize: '14px',
            },
          },
        },
      },
      MuiStepIcon: {
        styleOverrides: {
          text: {
            fontSize: '0.9rem',
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            color: '#9b09e4',
          },
        },
      },
      MuiBreadcrumbs: {
        styleOverrides: {
          root: {
            fontFamily: fontSettings.Pebble.fontStack,
            paddingBottom: spacing(2),
            letterSpacing: 0.5,
            fontSize: 14,
            textTransform: 'uppercase',
            '& a, span': {
              fontSize: 'inherit',
              fontFamily: 'inherit',
            },
          },
          separator: {
            margin: `0 ${spacing(1)}`,
            color: settings.colors.lightgray,
          },
        },
      },
    },
    palette: settings.palette,
    typography: settings.typography,
    breakpoints: settings.breakpoints,
    transitions: {
      duration: {
        enteringScreen: 125,
        leavingScreen: 125,
      },
    },
    spacing,
  });

  theme.settings = settings;
  return theme;
}
