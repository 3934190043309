import { MenuItem, TextField } from '@mui/material';
import LoadingSpinner from 'components/LoadingSpinner';
import { useField } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Option } from '..';

interface IProps<TValue> {
  name: string;
  label: string;
  options?: Option<TValue>[];
  required?: boolean;
  native?: boolean;
  disabled?: boolean;
  children?: JSX.Element | JSX.Element[];
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  autoFocus?: boolean;
  isLoading?: boolean;
  selectedValue?: TValue;
  size?: 'small' | 'medium';
}

function renderOptions<TValue extends string | number>(
  options: Option<TValue>[] | undefined,
  selectedValue: TValue,
  native = false
) {
  if (!options) {
    return null;
  }

  if (native) {
    // Number values in native mode might not work, not yet needed, add parsing when it is (why are you using native mode?)
    return options.map(opt => (
      <option
        key={opt.value}
        value={opt.value}
        selected={opt.value === selectedValue}
      >
        {opt.label}
      </option>
    ));
  }

  return options.map(opt => (
    <MenuItem
      key={opt.value}
      value={opt.value}
      disabled={opt.disabled}
      data-cy={opt.label}
      selected={opt.value === selectedValue}
    >
      {opt.label}
    </MenuItem>
  ));
}

function SimpleSelect<TValue extends string | number = string>(
  props: IProps<TValue>
) {
  const {
    name,
    label,
    options,
    required,
    children,
    native,
    disabled = false,
    onChange,
    autoFocus = false,
    isLoading = false,
    selectedValue = '',
    size = 'medium',
  } = props;
  const { t } = useTranslation();
  const [field, meta, helpers] = useField<TValue>(name);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    helpers.setValue(event.target.value as TValue);
    onChange && onChange(event);
  };

  return (
    <TextField
      id={name}
      select
      size={size}
      disabled={disabled}
      variant="outlined"
      fullWidth
      {...field}
      label={label}
      required={required}
      error={meta.touched && !!meta.error}
      helperText={meta.touched && meta.error}
      data-cy={name}
      SelectProps={{ native }}
      onChange={handleChange}
      autoFocus={autoFocus}
      InputProps={{
        style: { padding: 0 },
        endAdornment: (
          <>
            {isLoading ? (
              <LoadingSpinner
                style={{ position: 'absolute', right: 40 }}
                color="inherit"
                size={18}
              />
            ) : null}
          </>
        ),
      }}
    >
      {!required && (
        <MenuItem key="none" value="" data-cy="none">
          {t('label.none')}
        </MenuItem>
      )}
      {renderOptions(options, selectedValue, native) || children}
    </TextField>
  );
}

export default SimpleSelect;
