import * as azureMaps from 'azure-maps-control';
import { useEffect, useState } from 'react';
import { useStores } from 'store';
import { AzureMapsStyle } from '../azureMapsConfig';
import { addCustomSprites, useCustomSprites } from './useCustomSprites';

export function useMapInstance(
  mapId: string,
  settings: { style: AzureMapsStyle; renderWorldCopies?: boolean } // IAzureMapsSettings
): azureMaps.Map | undefined {
  const [map, setMap] = useState<azureMaps.Map>();
  const { authStore } = useStores();
  const customSprites = useCustomSprites();

  const mapLanguage = authStore.user?.languageCulture || 'en-US';

  useEffect(() => {
    const mapInstance = new azureMaps.Map(mapId, {
      language: mapLanguage,
      authOptions: {
        authType: azureMaps.AuthenticationType.subscriptionKey,
        subscriptionKey: 'jYKcUEhko8bJxpp5EV8a2HBsQCs200tU5XwFWo7plnY', // Place in env vars instead.
      },
      renderWorldCopies: false,
      showFeedbackLink: false,
      showLogo: false,
      style: settings.style,
      disableTelemetry: true,
    });

    mapInstance.events.add('ready', async () => {
      await addCustomSprites(mapInstance, customSprites);
      setMap(mapInstance);
    });

    return () => {
      mapInstance.dispose();
    };
  }, [mapId, setMap, settings.style, mapLanguage, customSprites]);

  return map;
}
