import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    zoomArea: {
      height: '100%',
      width: '100%',
      background: theme.palette.background.paper,
      '& > .react-transform-component, & .react-transform-element': {
        height: '100%',
        width: '100%',
      },
      '& .mapboxgl-marker-anchor-bottom .popup-arrow': {
        // Insane, to be sure, but the only quick fix I can find to an awkward gap between popup and arrow sometimes
        top: -2,
        position: 'relative',
      },
    },
    disabledZoom: {
      '& .mapboxgl-canvas-container.mapboxgl-interactive': {
        cursor: 'default',
      },
    },
    imageWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
    },
    image: {
      maxWidth: '100%',
      maxHeight: '100%',
    },
    buttonOverlay: {
      position: 'absolute',
      left: theme.spacing(2),
      top: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
    },
    button: {
      '&:not(:last-child)': {
        marginBottom: theme.spacing(1),
      },
    },
  })
);
