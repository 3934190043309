import { PostEndpoint } from 'shared/interfaces/api';

interface IImpersonateApi {
  impersonate: PostEndpoint<string>;
  revert: PostEndpoint<undefined>;
}

const base = '/api/impersonation';

export const impersonateApi: IImpersonateApi = {
  impersonate: {
    url: userId => `${base}/impersonate?userId=${userId}`,
    mockData: () => ({ status: 204 }),
  },
  revert: {
    url: `${base}/revert`,
    mockData: () => ({ status: 204 }),
  },
};
