import { AutocompleteInputChangeReason } from '@mui/material';
import { aiTrackEvent } from 'appInsights';
import { snackbar } from 'components/Snackbar';
import {
  IntCreateDeviceRequestDto,
  IntCreateDocumentRequestDto,
  IntCreatePlaceRequestDto,
  IntCreateProfileRequestDto,
  IntCreateSupplierRequestDto,
  IntPlaceDto,
  IntRealEstateItNetworkDto,
  IntRealEstateItProfileDto,
  IntSearchDto,
  IntSiteDto,
  IntSupplierTableDto,
  IntSwitchDto,
  IntUpdateDeviceRequestDto,
  IntUpdatePlaceRequestDto,
  IntUpdateProfileRequestDto,
  IntUpdateSupplierRequestDto,
} from 'generated';
import i18n from 'i18n';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { realEstateItAPI } from 'services/realEstateIt.service';
import { RootStore } from 'store/rootStore';
import { StoreBase } from 'store/storeBase';

type State = {
  createAsset: boolean;
  updateAsset: boolean;
  deleteAsset: boolean;
  createLocation: boolean;
  updateLocation: boolean;
  deleteLocation: boolean;
  createProfile: boolean;
  updateProfile: boolean;
  deleteProfile: boolean;
  createSupplier: boolean;
  updateSupplier: boolean;
  deleteSupplier: boolean;
  createDocument: boolean;
  getDocuments: boolean;
  switchOptions: boolean;
};

type ModalType = {
  createAsset: boolean;
  updateAsset: boolean;
  deleteAsset: boolean;
  createLocation: boolean;
  updateLocation: boolean;
  deleteLocation: boolean;
  createProfile: boolean;
  updateProfile: boolean;
  deleteProfile: boolean;
  createSupplier: boolean;
  updateSupplier: boolean;
  deleteSupplier: boolean;
  createDocument: boolean;
  deleteDocument: boolean;
};

export type DetectedEventType = {
  asset: boolean;
  location: boolean;
  supplier: boolean;
  profile: boolean;
  document: boolean;
  fileDeletion: boolean;
};

const optsError = 'realEstateIt:error.options';
const pageSize = 100;

export class RealEstateItStore extends StoreBase {
  @observable suppliers: IntSupplierTableDto[] | undefined;
  @observable profiles: IntRealEstateItProfileDto[] | undefined;
  @observable switches: IntSwitchDto[] | undefined;
  @observable locations: IntPlaceDto[] = [];
  @observable networks: IntRealEstateItNetworkDto[] | undefined;
  @observable sites: IntSiteDto[] = [];
  @observable switchOptions: IntSwitchDto[] = [];
  @observable selectedSite: IntSiteDto | undefined;
  @observable hasLoadedInitialSites = false;
  @observable isSitesLoading = false;
  @observable hasMoreSites = false;
  @observable searchSites = '';
  @observable selectedLocation: IntPlaceDto | undefined;
  @observable hasLoadedInitialLocations = false;
  @observable isLocationsLoading = false;
  @observable hasMoreLocations = false;
  @observable searchLocations = '';

  searchTimer = 0;
  locationPage = 0;
  sitePage = 0;

  @observable openModal: ModalType = {
    createAsset: false,
    updateAsset: false,
    deleteAsset: false,
    createLocation: false,
    updateLocation: false,
    deleteLocation: false,
    createProfile: false,
    updateProfile: false,
    deleteProfile: false,
    createSupplier: false,
    updateSupplier: false,
    deleteSupplier: false,
    createDocument: false,
    deleteDocument: false,
  };

  @observable error: State = {
    createAsset: false,
    updateAsset: false,
    deleteAsset: false,
    createLocation: false,
    updateLocation: false,
    deleteLocation: false,
    createProfile: false,
    updateProfile: false,
    deleteProfile: false,
    createSupplier: false,
    updateSupplier: false,
    deleteSupplier: false,
    createDocument: false,
    getDocuments: false,
    switchOptions: false,
  };

  @observable loading: State = {
    createAsset: false,
    updateAsset: false,
    deleteAsset: false,
    createLocation: false,
    updateLocation: false,
    deleteLocation: false,
    createProfile: false,
    updateProfile: false,
    deleteProfile: false,
    createSupplier: false,
    updateSupplier: false,
    deleteSupplier: false,
    createDocument: false,
    getDocuments: false,
    switchOptions: false,
  };

  @observable eventsDetected: DetectedEventType = {
    asset: false,
    location: false,
    supplier: false,
    profile: false,
    document: false,
    fileDeletion: false,
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }

  @action.bound createAsset = async (
    data: IntCreateDeviceRequestDto,
    successCallbackFn: () => void
  ) => {
    const resp = await this.httpPost(
      realEstateItAPI.create,
      {
        params: undefined,
        data,
      },
      loading => this.setLoading('createAsset', loading)
    );

    if (resp.status === 201) {
      snackbar(i18n.t('realEstateIt:success.create'), {
        variant: 'success',
      });

      aiTrackEvent('Create', { title: 'Real Estate IT Asset' });
      this.indicateEventDetected('asset');
      successCallbackFn();
    } else {
      snackbar(i18n.t('realEstateIt:error.create'), {
        variant: 'error',
      });
    }
  };

  @action.bound createProfile = async (
    data: IntCreateProfileRequestDto,
    successCallbackFn: () => void
  ) => {
    const resp = await this.httpPost(
      realEstateItAPI.createProfile,
      {
        params: undefined,
        data,
      },
      loading => this.setLoading('createProfile', loading)
    );

    if (resp.status === 201) {
      snackbar(i18n.t('realEstateIt:success.create_profile'), {
        variant: 'success',
      });

      aiTrackEvent('Create', { title: 'Real Estate IT Profile' });
      this.indicateEventDetected('profile');
      successCallbackFn();
    } else {
      snackbar(i18n.t('realEstateIt:error.create_profile'), {
        variant: 'error',
      });
    }
  };

  @action.bound updateProfile = async (
    id: string,
    data: IntUpdateProfileRequestDto,
    successCallbackFn: () => void
  ) => {
    const resp = await this.httpPatch(
      realEstateItAPI.updateProfile,
      {
        params: id,
        data,
      },
      loading => this.setLoading('updateProfile', loading)
    );

    if (resp.status === 200) {
      snackbar(i18n.t('realEstateIt:success.update_profile'), {
        variant: 'success',
      });
      aiTrackEvent('Update', { title: 'Real Estate IT Profile' });
      this.indicateEventDetected('profile');
      successCallbackFn();
    } else {
      snackbar(i18n.t('realEstateIt:error.update_profile'), {
        variant: 'error',
      });
    }
  };

  @action.bound createLocation = async (
    data: IntCreatePlaceRequestDto,
    successCallbackFn: () => void
  ) => {
    const resp = await this.httpPost(
      realEstateItAPI.createLocation,
      {
        params: undefined,
        data,
      },
      loading => this.setLoading('createLocation', loading)
    );

    if (resp.status === 201) {
      snackbar(i18n.t('realEstateIt:success.create_location'), {
        variant: 'success',
      });

      aiTrackEvent('Create', { title: 'Real Estate IT Location' });
      this.indicateEventDetected('location');
      successCallbackFn();
    } else {
      snackbar(i18n.t('realEstateIt:error.create_location'), {
        variant: 'error',
      });
    }
  };

  @action.bound createDocument = async (
    data: IntCreateDocumentRequestDto,
    successCallbackFn: () => void
  ) => {
    const resp = await this.httpPost(
      realEstateItAPI.createDocument,
      {
        params: undefined,
        data,
      },
      loading => this.setLoading('createDocument', loading)
    );

    if (resp.status === 201) {
      snackbar(i18n.t('realEstateIt:success.create_document'), {
        variant: 'success',
      });

      aiTrackEvent('Create', { title: 'Real Estate IT Document' });
      this.indicateEventDetected('document');
      successCallbackFn();
    } else {
      snackbar(i18n.t('realEstateIt:error.create_document'), {
        variant: 'error',
      });
    }
  };

  @action.bound deleteDocument = async (fileId: number) => {
    const resp = await this.httpDelete(realEstateItAPI.deleteDocument, {
      params: fileId,
    });
    if (resp.status === 204) {
      snackbar(i18n.t('realEstateIt:success.delete_document'), {
        variant: 'success',
      });
      aiTrackEvent('Delete', { title: 'Real Estate IT Document' });
      this.indicateEventDetected('fileDeletion');
    } else {
      snackbar(i18n.t('realEstateIt:error.delete_document'), {
        variant: 'error',
      });
    }
    this.setOpenModal('deleteDocument', false);
  };

  @action.bound updateLocation = async (
    id: string,
    data: IntUpdatePlaceRequestDto,
    successCallbackFn: () => void
  ) => {
    const resp = await this.httpPatch(
      realEstateItAPI.updateLocation,
      {
        params: id,
        data,
      },
      loading => this.setLoading('updateLocation', loading)
    );

    if (resp.status === 200) {
      snackbar(i18n.t('realEstateIt:success.update_location'), {
        variant: 'success',
      });
      aiTrackEvent('Update', { title: 'Real Estate IT Location' });
      this.indicateEventDetected('location');
      successCallbackFn();
    } else {
      snackbar(i18n.t('realEstateIt:error.update_location'), {
        variant: 'error',
      });
    }
  };

  @action.bound setSwitchOptions(opts: IntSwitchDto[]) {
    this.switchOptions = opts;
  }

  @action.bound async getSupplierOptions() {
    const tableParams: IntSearchDto = {
      page: 0,
      pageSize,
      orderBy: null,
      orderDesc: null,
      search: null,
      filters: null,
    };
    const resp = await this.httpGet(
      realEstateItAPI.getSuppliers({}),
      tableParams
    );

    runInAction(() => {
      if (resp.status !== 200 && resp.status !== 204) {
        snackbar(i18n.t(optsError), {
          variant: 'error',
        });
      }
      this.suppliers = resp.data?.rows ?? [];
    });
  }

  @action.bound async getDocumentsByAsset(assetId: number) {
    const tableParams: IntSearchDto = {
      page: 0,
      pageSize: 99999,
      search: '',
      orderBy: null,
      orderDesc: false,
      filters: null,
    };
    const resp = await this.httpGet(
      realEstateItAPI.getDocumentsByAsset(assetId),
      tableParams,
      loading => this.setLoading('getDocuments', loading)
    );

    if (resp.status !== 200 && resp.status !== 204) {
      snackbar(i18n.t(optsError), {
        variant: 'error',
      });
    }
    return resp.data?.rows ?? [];
  }

  @action.bound createSupplier = async (
    data: IntCreateSupplierRequestDto,
    successCallbackFn: () => void
  ) => {
    const resp = await this.httpPost(
      realEstateItAPI.createSupplier,
      {
        params: undefined,
        data,
      },
      loading => this.setLoading('createSupplier', loading)
    );

    if (resp.status === 201) {
      snackbar(i18n.t('realEstateIt:success.create_supplier'), {
        variant: 'success',
      });

      aiTrackEvent('Create', { title: 'Real Estate IT Supplier' });
      this.indicateEventDetected('supplier');
      successCallbackFn();
    } else {
      snackbar(i18n.t('realEstateIt:error.create_supplier'), {
        variant: 'error',
      });
    }
  };

  @action.bound updateSupplier = async (
    id: string,
    data: IntUpdateSupplierRequestDto,
    successCallbackFn: () => void
  ) => {
    const resp = await this.httpPatch(
      realEstateItAPI.updateSupplier,
      {
        params: id,
        data,
      },
      loading => this.setLoading('updateSupplier', loading)
    );

    if (resp.status === 200) {
      snackbar(i18n.t('realEstateIt:success.update_supplier'), {
        variant: 'success',
      });
      aiTrackEvent('Update', { title: 'Real Estate IT Supplier' });
      this.indicateEventDetected('supplier');
      successCallbackFn();
    } else {
      snackbar(i18n.t('realEstateIt:error.update_supplier'), {
        variant: 'error',
      });
    }
  };
  @action.bound async getNetworkOptions() {
    const tableParams: IntSearchDto = {
      page: 0,
      pageSize: 99999,
      orderBy: null,
      orderDesc: null,
      search: null,
      filters: null,
    };
    const resp = await this.httpGet(
      realEstateItAPI.getNetworks({}),
      tableParams
    );

    runInAction(() => {
      if (resp.status !== 200 && resp.status !== 204) {
        snackbar(i18n.t(optsError), {
          variant: 'error',
        });
      }
      this.networks = resp.data?.rows ?? [];
    });
  }

  @action.bound async getSiteOptions(page = 0) {
    const tableParams: IntSearchDto = {
      page,
      pageSize,
      orderBy: null,
      orderDesc: null,
      search: this.searchSites,
      filters: null,
    };
    const resp = await this.httpGet(
      realEstateItAPI.getSites({}),
      tableParams,
      loading =>
        runInAction(() => {
          this.isSitesLoading = loading;
        })
    );

    runInAction(() => {
      if (resp.status !== 200 && resp.status !== 204) {
        snackbar(i18n.t(optsError), {
          variant: 'error',
        });
      } else {
        this.hasLoadedInitialSites = true;
        this.sitePage = page;
        const newData = resp.data?.rows;
        const totals = resp.data?.total ?? 0;
        if (newData) {
          this.sites = page > 0 ? [...this.sites, ...newData] : newData ?? [];
        } else {
          this.sites = [];
        }
        this.hasMoreSites = this.sites?.length < totals ? true : false;
      }
    });
  }

  @action.bound handleSiteChange(newValue: IntSiteDto) {
    this.selectedSite = newValue;
  }

  @action.bound updateSiteSearch() {
    this.sitePage = 0;
    this.getSiteOptions();
  }

  @action.bound fetchMoreSites() {
    this.getSiteOptions(this.sitePage + 1);
  }

  @action.bound handleSiteSearchChange(
    newSearch: string,
    reason: AutocompleteInputChangeReason
  ) {
    if (reason === 'reset' && !newSearch) {
      return;
    }

    if (newSearch === this.searchSites) {
      return;
    }

    this.searchSites = newSearch;
    window.clearTimeout(this.searchTimer);

    if (reason === 'clear') {
      this.updateSiteSearch();
    } else if (reason === 'input') {
      this.searchTimer = window.setTimeout(this.updateSiteSearch, 250);
    }
  }

  @action.bound async getProfileOptions() {
    const tableParams: IntSearchDto = {
      page: 0,
      pageSize,
      orderBy: null,
      orderDesc: null,
      search: null,
      filters: null,
    };
    const resp = await this.httpGet(
      realEstateItAPI.getProfiles({}),
      tableParams
    );

    runInAction(() => {
      if (resp.status !== 200 && resp.status !== 204) {
        snackbar(i18n.t(optsError), {
          variant: 'error',
        });
      }
      this.profiles = resp.data?.rows ?? [];
    });
  }

  @action.bound async getSwitchOptions(locationId?: number) {
    const tableParams: IntSearchDto = {
      page: 0,
      pageSize,
      orderBy: null,
      orderDesc: null,
      search: null,
      filters: locationId
        ? `[{ id: 'locationId', value: ${locationId} }]`
        : null,
    };
    const resp = await this.httpGet(
      realEstateItAPI.getSwitches({}),
      tableParams,
      loading => this.setLoading('switchOptions', loading)
    );

    runInAction(() => {
      if (resp.status !== 200 && resp.status !== 204) {
        snackbar(i18n.t(optsError), {
          variant: 'error',
        });
      }
      this.switches = resp.data?.rows ?? [];
      this.setSwitchOptions(this.switches);
    });
  }

  @action.bound async getLocationOptions(page = 0) {
    const tableParams: IntSearchDto = {
      page,
      pageSize,
      orderBy: null,
      orderDesc: null,
      search: this.searchLocations,
      filters: null,
    };
    const resp = await this.httpGet(
      realEstateItAPI.getLocations({}),
      tableParams,
      loading =>
        runInAction(() => {
          this.isLocationsLoading = loading;
        })
    );

    runInAction(() => {
      if (resp.status !== 200 && resp.status !== 204) {
        snackbar(i18n.t(optsError), {
          variant: 'error',
        });
      } else {
        this.hasLoadedInitialLocations = true;
        this.locationPage = page;
        const newData = resp.data?.rows;
        const totals = resp.data?.total ?? 0;
        if (newData) {
          this.locations =
            page > 0 ? [...this.locations, ...newData] : newData ?? [];
        } else {
          this.locations = [];
        }
        this.hasMoreLocations = this.locations.length < totals ? true : false;
      }
    });
  }

  @action.bound handleLocationChange(newValue: IntPlaceDto) {
    this.getSwitchOptions(newValue.id);
    this.selectedLocation = newValue;
  }

  @action.bound updateLocationSearch() {
    this.locationPage = 0;
    this.getLocationOptions();
  }

  @action.bound fetchMoreLocations() {
    this.getLocationOptions(this.locationPage + 1);
  }

  @action.bound handleLocationSearchChange(
    newSearch: string,
    reason: AutocompleteInputChangeReason
  ) {
    if (reason === 'reset' && !newSearch) {
      return;
    }

    if (newSearch === this.searchLocations) {
      return;
    }

    this.searchLocations = newSearch;
    window.clearTimeout(this.searchTimer);

    if (reason === 'clear') {
      this.updateLocationSearch();
    } else if (reason === 'input') {
      this.searchTimer = window.setTimeout(this.updateLocationSearch, 250);
    }
  }

  @action.bound setLocations(locations: IntPlaceDto[]) {
    this.locations = locations;
  }

  @action.bound setSuppliers(suppliers: IntSupplierTableDto[]) {
    this.suppliers = suppliers;
  }

  @action.bound updateAsset = async (
    id: string,
    data: IntUpdateDeviceRequestDto,
    successCallbackFn: () => void
  ) => {
    const resp = await this.httpPatch(
      realEstateItAPI.update,
      {
        params: id,
        data,
      },
      loading => this.setLoading('updateAsset', loading)
    );

    if (resp.status === 200) {
      snackbar(i18n.t('realEstateIt:success.update'), {
        variant: 'success',
      });
      aiTrackEvent('Update', { title: 'Real Estate IT Asset' });
      this.indicateEventDetected('asset');
      successCallbackFn();
    } else {
      snackbar(i18n.t('realEstateIt:error.update'), {
        variant: 'error',
      });
    }
  };

  @action.bound setError = (type: keyof State, error: boolean) => {
    this.error[type] = error;
  };

  @action.bound setLoading = (type: keyof State, loading: boolean) => {
    this.loading[type] = loading;
  };

  @action.bound setOpenModal(type: keyof ModalType, open: boolean) {
    this.openModal[type] = open;
  }

  @action.bound indicateEventDetected(eventType: keyof DetectedEventType) {
    this.eventsDetected[eventType] = !this.eventsDetected[eventType];
  }
}
