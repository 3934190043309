import axios from 'axios';
import { DashboardComponentType } from 'generated';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IDataSourceResponse,
  IDataSourceStore,
} from 'views/Dashboard/DataSources/dataSourceTypes';
import { cacheRequest } from './cacheWidgetData';
import { useMutateFn } from './useMutateFn';

export function useDataSourceData(
  dataSource: IDataSourceStore,
  componentType?: DashboardComponentType
) {
  const { t } = useTranslation();
  const [response, setResponse] = useState<IDataSourceResponse>();
  useEffect(() => {
    let isMounted = true;
    const cancelToken = axios.CancelToken.source();
    const loadData = async () => {
      const token = cancelToken.token;

      // Manually set isLoading here, in case a cached promise is reused and the data source doesn't set it
      dataSource.setIsLoading(true);

      const dataSourceResponse = await cacheRequest({
        key: `${dataSource.settings.type}_${dataSource.depString}`,
        request: () =>
          dataSource.getData({
            cancelToken: token,
            componentType: componentType,
          }),
        isCanceled: resp => resp.type === 'canceled',
      });

      if (!isMounted || dataSourceResponse.type === 'canceled') {
        return;
      }

      dataSource.setIsLoading(false);
      setResponse(dataSourceResponse);
    };

    if (dataSource.isInitialized) {
      loadData();
    }

    return () => {
      cancelToken.cancel();
      isMounted = false;
    };
  }, [
    dataSource,
    dataSource.isInitialized,
    dataSource.depString,
    componentType,
  ]);

  const mutate = useMutateFn(dataSource, setResponse);

  return {
    noContent: response?.type === 'noContent',
    data: response?.type === 'success' ? response.data : undefined,
    error:
      response?.type === 'error'
        ? response.message || t('common:error.base')
        : undefined,
    mutate,
  };
}
