import { Grid } from '@mui/material';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { IDashboardComponent } from 'views/Dashboard/dashboardTypes';
import { Control } from 'views/Dashboard/SettingControls';
import { IImageSettings } from '../imageConfig';

const GeneralSettingsStep: IDashboardComponent<IImageSettings> = ({
  component,
}) => {
  const { settings, componentId, dashboard } = component;
  const { t } = useTranslation('dashboard');

  const handleImageLoaded = (imgEl: HTMLImageElement) => {
    if (!imgEl) {
      return;
    }

    const { naturalWidth, naturalHeight } = imgEl;

    runInAction(() => {
      settings.imageWidth = naturalWidth;
      settings.imageHeight = naturalHeight;
    });
  };

  return (
    <Grid container spacing={4}>
      <Grid item sm={12}>
        <Control.ImageUpload
          object={settings}
          name="imageUrl"
          dashboardId={dashboard.dashboardId}
          dashboardComponentId={componentId}
          onPreviewLoaded={handleImageLoaded}
        />
        <Control.Checkbox
          label={t('image.enable_zoom')}
          object={settings}
          name="enableZoom"
        />
      </Grid>
    </Grid>
  );
};

export default observer(GeneralSettingsStep);
