import { useTheme } from '@mui/styles';
import * as azureMaps from 'azure-maps-control';
import { trimEnd } from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useRandomId } from '../../../AzureMapsComponent/hooks';
import { MapWidgetState } from '../../../AzureMapsComponent/MapWidgetState';
import { IMapFeatureGrouped, IMapFeatureProperties } from '../../ImagePinLayer';
import { mapLayerIds } from '../../mapLayerIds';
import { useGetMapPin } from './HtmlMapPin';
import { HtmlMarkerLayer } from './HtmlMarkerLayer/HtmlMarkerLayer';
import { useUpdateMapDataSourceFeatures } from './useUpdateMapDataSourceFeatures';

interface IProps {
  map: azureMaps.Map;
  features: IMapFeatureGrouped[];
  state: MapWidgetState;
}

interface ISingleProperties extends IMapFeatureProperties {
  cluster: false | undefined;
}

// Properties added by azuremaps when clustered
interface IClusterProperties extends IMapFeatureProperties {
  id: string;
  cluster: true;
  cluster_id: number;
  red: number;
  blue: number;
  childIds: string;
  point_count: number;
  point_count_abbreviated: string;
}

type ClusterOrSingleProperties = IClusterProperties | ISingleProperties;

const clusterProperties: Record<string, azureMaps.AggregateExpression> = {
  red: ['+', ['get', 'red'], 0],
  childIds: ['concat', ['concat', ['get', 'id'], '____']], // There has to be a better way right? meh
};

const ClusteredMarkerLayer: React.FC<IProps> = ({ map, state, features }) => {
  const dataSourceId = useRandomId();
  const [source] = useState(
    () =>
      new azureMaps.source.DataSource(dataSourceId, {
        cluster: true,
        clusterRadius: 36,
        clusterProperties,
      })
  );

  useEffect(() => {
    if (!map.sources.getById(dataSourceId)) {
      map.sources.add(source);
    }
  }, [map, source, dataSourceId]);

  const { palette } = useTheme();
  const getMapPin = useGetMapPin();

  useEffect(() => {
    // An awkward way to do this, but it seems inescapable
    let handled = false;
    const markClickAsHandled = () => {
      handled = true;
      window.setTimeout(() => {
        handled = false;
      }, 1);
    };

    const clusterLayer = new HtmlMarkerLayer(source, mapLayerIds.features, {
      minZoom: 0,
      maxZoom: 24,
      markerCallback: function (
        featureId,
        position,
        properties: ClusterOrSingleProperties
      ) {
        // Check to see if marker represents a cluster.
        if (properties.cluster) {
          const childIds = trimEnd(properties.childIds, '____');
          const pin = getMapPin({
            id: childIds,
            position,
            variant: 'cluster',
            count: properties.point_count,
            redCount: properties.red,
          });
          state.storeMarker(childIds.split('____'), pin);
          return pin;
        }

        const pin = getMapPin({
          id: properties.id || featureId,
          position,
          variant: 'single',
          iconType: properties.defaultIconType || 'MapPin',
          redCount: properties.red ?? 0,
          isSelected: properties.isSelected,
        });

        state.storeMarker(properties.id || featureId, pin);
        return pin;
      },
    });

    map.layers.add(clusterLayer);

    const handleFeatureClicked = ({ target }: azureMaps.TargetedEvent) => {
      if (target && target instanceof azureMaps.HtmlMarker && !handled) {
        const clickedFeature = target as unknown as azureMaps.data.Feature<
          azureMaps.data.Geometry,
          ClusterOrSingleProperties
        >;

        markClickAsHandled();

        if (clickedFeature.properties?.cluster) {
          state.selectFeature();

          // For clusters, zoom in until it splits up
          source
            .getClusterExpansionZoom(clickedFeature.properties.cluster_id)
            .then(zoom => {
              map.setCamera({
                center: (target as any).options.position, // point.geometry.coordinates should work, but azmaps types...
                zoom: zoom + 0.2, // Go in slightly tighter, sometimes it seems to undershoot
                type: 'ease',
                duration: 180,
              });
            });
        } else {
          // For single features, select them
          state.selectFeature(clickedFeature.properties?.id);
        }
      }
    };

    map.events.add('click', clusterLayer, handleFeatureClicked);

    return () => {
      map.events.remove('click', handleFeatureClicked as any);
      const clusterLayer = map.layers.getLayerById(mapLayerIds.features);
      if (clusterLayer) {
        map.layers.remove(clusterLayer);
      }
    };
  }, [map, source, palette, getMapPin, state]);

  useUpdateMapDataSourceFeatures(source, features, state.selectedFeatureId);

  return null;
};

export default observer(ClusteredMarkerLayer);
