import { Box, Card, CardContent } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles(theme =>
  createStyles({
    card: {
      boxShadow: theme.shadows[3],
    },
  })
);

const MapOverlayMenu: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <Box
      position="absolute"
      left={0}
      top={0}
      padding={2}
      width={350}
      maxHeight="100%"
      overflow="auto"
    >
      <Card elevation={1} className={classes.card}>
        <CardContent>{children}</CardContent>
      </Card>
    </Box>
  );
};

export default MapOverlayMenu;
