import { subDays, subMinutes } from 'date-fns';
import {
  IntAtlasServiceDto,
  IntAtlasServicePropertyDto,
  IntServiceDataFilterOptionResponseDto,
  IntServiceDataPointInterval,
  IntServiceDataSpecificationRequestDto,
  IntServiceDetailDto,
  IntServiceTableDto,
  ServiceDataFilterType,
  ServiceDataSpecificationColumnType,
  ServicePropertyDataType,
} from 'generated';
import {
  IAggregatedServiceDataResponse,
  IServiceDataResponse,
} from 'services/service.service';
import { IResponse } from 'shared/interfaces/api';
import { rootStore } from 'store/RootStoreContext';
import { rnd } from 'utils';
import { ITelemetry } from 'views/Dashboard/DataSources/ServiceData/servicePropsToDataProps';

class ServiceDemoData {
  private readonly locationService: IntAtlasServiceDto = {
    serviceId: 'e2de052e-84a4-4bb0-ab9f-62d3ebffd5f0',
    displayName: 'Location',
    icon: 'fa-location-arrow',
    properties: [
      {
        servicePropertyId: '3e820c70-a595-486c-b57b-2226703d69b5',
        servicePropertyName: 'Altitude',
        displayName: 'Altitude',
        storageColumnName: 'altitude',
        displayFormat: 0,
        dataType: 1,
        unit: '',
        displayNames: { 'sv-SE': 'Höjd', 'en-US': 'Altitude' },
      },
      {
        servicePropertyId: 'fc9d8c89-999d-4504-909e-3c223aa9e14d',
        servicePropertyName: 'Speed',
        displayName: 'Speed',
        storageColumnName: 'speed',
        dataType: 1,
        unit: '',
        displayNames: { 'sv-SE': 'Hastighet', 'en-US': 'Speed' },
      },
      {
        servicePropertyId: '49c24efb-8eb7-4f41-a6fb-6aed3b88673d',
        servicePropertyName: 'Latitude',
        displayName: 'Latitude',
        storageColumnName: 'latitude',
        displayFormat: 5,
        dataType: 1,
        unit: '',
        displayNames: { 'sv-SE': 'Latitud', 'en-US': 'Latitude' },
      },
      {
        servicePropertyId: '2275f5da-eed7-4849-8b75-7efc6a9337fb',
        servicePropertyName: 'Longitude',
        displayName: 'Longitude',
        storageColumnName: 'longitude',
        dataType: 1,
        unit: '',
        displayNames: { 'sv-SE': 'Longitud', 'en-US': 'Longitude' },
      },
      {
        servicePropertyId: '30739919-6220-4194-a866-c3c7d42c0885',
        servicePropertyName: 'Heading',
        displayName: 'Heading',
        storageColumnName: 'heading',
        dataType: 1,
        unit: '',
        displayNames: { 'sv-SE': 'Kurs', 'en-US': 'Heading' },
      },
    ],
  };

  private getDemoWeatherData(): ITelemetry[] {
    return rnd.array(31, i => ({
      register_date: subDays(new Date(), i).toISOString(),
      temperature: `${(rnd.int(1800, 2200) / 100).toFixed(2)}`,
      humidity: `${rnd.int(20, 100)}`,
      light: `${rnd.int(0, 100)}`,
    }));
  }

  // The newer endpoint, without serviceId
  getServiceDataNoServiceId = (
    _urlParams: undefined,
    params: IntServiceDataSpecificationRequestDto = {
      columns: [],
      filters: [],
      interval: IntServiceDataPointInterval.None,
    }
  ): IResponse<IServiceDataResponse> => {
    const assetIds =
      params?.filters.find(f => f.type === ServiceDataFilterType.AssetId)
        ?.values || rnd.array(10, rnd.staticGuid);

    return {
      status: 200,
      data: {
        interval: IntServiceDataPointInterval.None,
        groupedData: assetIds.map(id => ({
          id,
          owner: {
            AssetDetailsAssetName: `Fake asset ${id}`,
          },
          dataPoints: this.getDataPointsNoServiceId(params),
        })),
      },
    };
  };

  private getDataPointsNoServiceId(
    request: IntServiceDataSpecificationRequestDto
  ): ITelemetry[] {
    const {
      dashboardStore: { serviceProperties },
    } = rootStore;

    const isLatest = request.interval === IntServiceDataPointInterval.Latest;

    const dataPoints: ITelemetry[] = [];
    for (let i = 0; i < (isLatest ? 1 : 20); i++) {
      dataPoints.push({});
    }

    const getSpValue = (sp: IntAtlasServicePropertyDto): any => {
      if (sp.dataType === ServicePropertyDataType.Number) {
        return rnd.int(sp.dataRangeMin ?? 0, sp.dataRangeMax ?? 100);
      }
      if (sp.dataType === ServicePropertyDataType.DateTime) {
        return subMinutes(new Date(), rnd.int(0, 30));
      }
      return null;
    };

    request.columns
      .filter(
        col =>
          col.columnType === ServiceDataSpecificationColumnType.ServiceProperty
      )
      .forEach(col => {
        const sp = serviceProperties.find(
          sp => sp.servicePropertyId === col.value
        );
        if (!sp) {
          return;
        }
        dataPoints.forEach(data => {
          const key =
            sp.servicePropertyId === 'registerDate'
              ? 'registerDate'
              : `${sp.servicePropertyId}_avg`;
          data[key] = getSpValue(sp);
        });
      });

    return dataPoints;
  }

  private getDemoLocationData() {
    return rnd.array(31, i => ({
      register_date: subDays(new Date(), i).toISOString(),
      latitude: `${59.326944 + (rnd.int(-100, 100) / 1000) * 59.326944}`,
      longitude: `${18.071667 + (rnd.int(-200, 0) / 1000) * 18.071667}`,
    }));
  }

  getAllServices = (): IResponse<{
    total: number;
    rows: IntAtlasServiceDto[];
  }> => {
    return {
      status: 200,
      data: {
        total: 2,
        rows: [
          {
            serviceId: 'weather',
            displayName: 'Weather',
            icon: '',
            properties: [
              {
                servicePropertyId: 'c324ef58-5572-4857-b515-01d4a77da0a9',
                servicePropertyName: 'Relative Humidity',
                displayName: 'Relative Humidity',
                displayNames: {
                  'en-US': 'Relative Humidity',
                  'sv-SE': 'Luftfuktighet',
                },
                storageColumnName: 'relative_humidity',
                displayFormat: 0,
                dataType: 1,
                unit: '%',
                dataRangeMin: 0,
                dataRangeMax: 100,
              },

              {
                servicePropertyId: 'fe12a8b7-25f8-415c-a83e-cd93c3029902',
                servicePropertyName: 'Temperature',
                displayName: 'Temperature',
                displayNames: {
                  'en-US': 'Temperature',
                  'sv-SE': 'Temperatur',
                },
                storageColumnName: 'temperature',
                displayFormat: 0,
                dataType: 1,
                unit: '°C',
                dataRangeMin: -272.15,
                dataRangeMax: 200,
              },
            ],
          },
          this.locationService,
        ],
      },
    };
  };

  getServiceTable = (): IResponse<{
    total: number;
    rows: IntServiceTableDto[];
  }> => {
    return {
      status: 200,
      data: {
        total: 1,
        rows: [
          {
            serviceId: 'weather',
            serviceName: 'weather',
            displayName: 'Weather',
            safeServiceName: 'weather',
            createDate: 1413519542882,
            globallyAvailable: false,
            customerName: 'testcustomer',
            latestServiceDataContextType: 1,
            serviceDataTTL: -1,
          },
        ],
      },
    };
  };

  getServiceDetails = (_serviceId: string): IResponse<IntServiceDetailDto> => {
    return {
      status: 200,
      data: {
        serviceId: 'weather',
        serviceName: "Daniel'sservice",
        displayName: "Daniel's Awesome Service",
        safeServiceName: "daniel'sservice",
        createDate: 1680081730000,
        globallyAvailable: false,
        customerId: '89830733-5fd5-4da9-89cc-3f22905776ba',
        customerName: 'GC_DEV_01',
        latestServiceDataContextType: 0,
        serviceDataTTL: -2,
        properties: [
          {
            servicePropertyId: rnd.guid(),
            serviceId: 'weather',
            servicePropertyName: 'testing a new property validation',
            displayName: 'testing a new property validation',
            displayNames: {
              'en-US': 'testing a new property validation',
            },
            storageColumnName: 'testing_a_new_property_validation',
            unit: '',
            imperialUnit: '',
            imperialConversion: undefined,
            dataType: 0,
            numberDataRangeMin: undefined,
            numberDataRangeMax: undefined,
            valueType: 0,
            allowZero: false,
            conversion: undefined,
            displayFormat: 0,
            enumConstants: [],
          },
        ],
        vendorApis: [
          {
            serviceVendorApiId: rnd.guid(),
            serviceId: 'weather',
            type: 2,
            activated: false,
            mappings: [
              {
                serviceVendorApiMappingId:
                  '08986056-64fc-40a5-8f85-159db3cea5b1',
                serviceVendorApiId: 'ce30b70f-aed0-4d93-b940-9233db4dccfd',
                servicePropertyId: '99e87b3c-e069-4811-86ae-0ccbb16ed63f',
                dataKey: 'OkAlarm1',
              },
            ],
          },
        ],
      },
    };
  };

  getService = (serviceId: string): IResponse<IntAtlasServiceDto> => {
    if (serviceId === this.locationService.serviceId) {
      return {
        status: 200,
        data: this.locationService,
      };
    }

    // Default to weather
    return {
      status: 200,
      data: {
        serviceId: 'weather',
        displayName: 'Weather',
        icon: '',
        properties: [
          {
            displayName: 'Temperature',
            unit: '°C',
            dataType: ServicePropertyDataType.Number,
            servicePropertyId: 'temperature',
            servicePropertyName: 'Temperature',
            storageColumnName: 'temperature',
            dataRangeMax: 100,
            dataRangeMin: -50,
            displayNames: { 'sv-SE': 'Temperatur', 'en-US': 'Temperature' },
          },
          {
            displayName: 'Humidity',
            unit: '%',
            dataType: ServicePropertyDataType.Number,
            servicePropertyId: 'humidity',
            servicePropertyName: 'Humidity',
            storageColumnName: 'humidity',
            dataRangeMax: 100,
            dataRangeMin: 0,
            displayNames: { 'sv-SE': 'Luftfuktighet', 'en-US': 'Humidity' },
          },
          {
            displayName: 'Light',
            unit: '',
            dataType: ServicePropertyDataType.Number,
            servicePropertyId: 'light',
            servicePropertyName: 'Light',
            storageColumnName: 'light',
            dataRangeMax: 100,
            dataRangeMin: 0,
            displayNames: { 'sv-SE': 'Ljus', 'en-US': 'Light' },
          },
        ],
      },
    };
  };

  getServiceData = (
    serviceId: string,
    params?: IntServiceDataSpecificationRequestDto
  ): IResponse<IServiceDataResponse> => {
    if (!serviceId && params) {
      return this.getServiceDataNoServiceId(undefined, params);
    }
    // This does not respect interval or any filters or columns. It works well enough to test widgets but could be improved.
    // Location
    if (serviceId === this.locationService.serviceId) {
      return {
        status: 200,
        data: {
          interval: IntServiceDataPointInterval.None,
          groupedData: [
            {
              id: 'asset1',
              owner: {
                AssetDetailsAssetName: 'Fake asset 1',
                AssetDetailsCustomerName: 'Devops',
                AssetDetailsTerminalConnected: rnd.bool() ? 'True' : 'False',
              },
              dataPoints: this.getDemoLocationData(),
            },
            {
              id: 'asset2',
              owner: {
                AssetDetailsAssetName: 'Fake asset 2',
                AssetDetailsCustomerName: 'Accelerate',
                AssetDetailsTerminalConnected: rnd.bool() ? 'True' : 'False',
              },
              dataPoints: this.getDemoLocationData(),
            },
          ],
        },
      };
    }

    return {
      status: 200,
      data: {
        interval: IntServiceDataPointInterval.None,
        groupedData: [
          {
            id: 'asset1',
            owner: {
              AssetDetailsAssetName: 'Fake asset 1',
            },
            dataPoints: this.getDemoWeatherData(),
          },
          {
            id: 'asset2',
            owner: {
              AssetDetailsAssetName: 'Fake asset 2',
            },
            dataPoints: this.getDemoWeatherData(),
          },
        ],
      },
    };
  };

  getFilterOptions = (): IResponse<IntServiceDataFilterOptionResponseDto> => {
    return {
      status: 200,
      data: {
        filterOptions: [
          {
            id: 'Option 1',
            name: 'Option 1',
            children: [],
            hierarchyName: '',
          },
          {
            id: 'Option 2',
            name: 'Option 2',
            children: [],
            hierarchyName: '',
          },
        ],
        hasMore: false,
      },
    };
  };

  getAggregatedServiceData = (
    _serviceId: string
  ): IResponse<IAggregatedServiceDataResponse> => {
    return {
      status: 200,
      data: {
        interval: IntServiceDataPointInterval.None,
        result: {
          temperature_avg: 7.32,
        },
      },
    };
  };

  getAggregatedServiceDataNew =
    (): IResponse<IAggregatedServiceDataResponse> => {
      return {
        status: 200,
        data: {
          interval: IntServiceDataPointInterval.None,
          result: {
            temperature_avg: 7.32,
          },
        },
      };
    };
}

export const serviceDemoData = new ServiceDemoData();
