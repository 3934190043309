import React from 'react';
import { Trans } from 'react-i18next';
import { useStyles } from './TotalRows.styles';

interface IProps {
  currentlyShown?: number | '-';
  totalRows: number | '-';
}

const TotalRows: React.FC<IProps> = ({ currentlyShown, totalRows }) => {
  const classes = useStyles();
  return (
    <span className={`rowsShown ${classes.totalRowsPadding}`}>
      <Trans
        i18nKey={
          currentlyShown
            ? 'resource_table.shown_rows_count'
            : 'resource_table.total_rows'
        }
        ns="common"
        values={{ total: totalRows, current: currentlyShown }}
      />
    </span>
  );
};

export default TotalRows;
