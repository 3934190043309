import { VpnServerStatus } from 'generated';
import i18n from 'i18next';
import { Column } from 'react-table';
import { CustomCell } from '../Helpers/CustomCell';
import { ICustomCellProps } from '../Helpers/CustomCellProps';
import { getOptionFilter } from '../Helpers/getOptionFilter';

export function vpnServerStatusType<T>(options: {
  id: string;
  accessor: (row: T) => VpnServerStatus;
  header?: string;
  defaultShow?: boolean;
  filterable?: boolean;
}): Column<T> {
  const {
    id,
    header = 'Status',
    accessor,
    defaultShow: show,
    filterable,
  } = options;

  const cellByStatus: Record<VpnServerStatus, ICustomCellProps> = {
    [VpnServerStatus.Offline]: {
      text: i18n.t(`vpnServer:status.${VpnServerStatus.Offline}`),
    },
    [VpnServerStatus.Online]: {
      text: i18n.t(`vpnServer:status.${VpnServerStatus.Online}`),
    },
  };

  return {
    id,
    Header: header,
    accessor,
    resizable: false,
    maxWidth: 130,
    filterable,
    show,
    Cell: row => {
      const iconProps = cellByStatus[row.value as VpnServerStatus] || {};
      return <CustomCell {...iconProps} index={row.index} />;
    },
    Filter: getOptionFilter([
      {
        label: i18n.t('all'),
        value: '',
      },
      {
        label: i18n.t(`vpnServer:status.${VpnServerStatus.Offline}`),
        value: VpnServerStatus.Offline,
      },
      {
        label: i18n.t(`vpnServer:status.${VpnServerStatus.Online}`),
        value: VpnServerStatus.Online,
      },
    ]),
  };
}
