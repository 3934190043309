import { DashboardComponentType, IntStatus } from 'generated';
import { IDataSourceConfig } from '../../dataSourceTypes';

export interface ISimStatusDataSourceSettings {
  type: 'simStatus';
}

export type IActivationStatusCounts = Partial<Record<IntStatus, number>>;

export const simStatusDataSourceConfig: IDataSourceConfig<ISimStatusDataSourceSettings> =
  {
    icon: 'Basketball',
    settingsSteps: [],
    getDefaultSettings: async () => {
      return {
        type: 'simStatus',
      };
    },
    enabledComponentTypes: {
      [DashboardComponentType.AzureMaps]: false,
      [DashboardComponentType.ColumnChart]: false,
      [DashboardComponentType.Gauge]: false,
      [DashboardComponentType.Image]: false,
      [DashboardComponentType.LineChart]: false,
      [DashboardComponentType.Number]: true,
      [DashboardComponentType.Table]: true,
      [DashboardComponentType.PieChart]: true,
    },
    shouldShowDateFilter: false,
  };
