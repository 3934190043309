import { Autocomplete, TextField } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Option } from 'components/FormFields';
import { observer } from 'mobx-react-lite';
import { useDashboardContext } from '../contexts/dashboardContext';
import { IControlProps } from './controlProps';

interface IProps<TValue> {
  placeholder?: string;
  clearable?: boolean;
  options: Option<TValue>[];
}

const useStyles = makeStyles(() =>
  createStyles({
    inputRoot: {
      padding: '0 !important',
    },
    input: {
      padding: '24px 12px 10px 12px !important', // Not an ideal solution but suprisingly annoying to fix. Improve when white-labeling is properly implemented.
    },
  })
);

function AutoCompleteControl<TObj, TValue extends string | number | null>(
  props: IControlProps<TObj, TValue> & IProps<TValue>
) {
  const {
    name,
    object,
    label,
    options,
    placeholder,
    clearable = false,
  } = props;
  const classes = useStyles();
  const { updateSetting } = useDashboardContext();

  const value: string = (object[name] as any) || '';

  const selectedValue = options?.find(opt => opt.value === value) || null;

  return (
    <Autocomplete
      options={options || []}
      classes={classes}
      disableClearable={!clearable}
      value={selectedValue}
      placeholder={placeholder}
      autoComplete
      autoHighlight
      onChange={(_, newValue) => updateSetting(object, name, newValue?.value)}
      getOptionLabel={option => option.label}
      getOptionDisabled={option => !!option.disabled}
      renderOption={(optionProps, option) => (
        <li {...optionProps} key={option.value}>
          {option.label}
        </li>
      )}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          placeholder={placeholder}
          data-cy={name}
        />
      )}
    />
  );
}

export default observer(AutoCompleteControl);
