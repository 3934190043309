import { Option } from 'components/FormFields';
import i18n from 'i18next';
import countries from '../i18n/translations/en/countries.json';

export const getTranslatedCountries = (compareList: string[]): string[] =>
  Object.entries(countries)
    .filter(([_key, value]) => compareList.find(country => country === value))
    .map(country => i18n.t(`countries:${country[0]}`))
    .sort((a, b) => a.localeCompare(b));

export const getCountriesAsOptions = (returnCountryCode = false): Option[] =>
  Object.entries(countries).map(([key, value]) => ({
    value: returnCountryCode ? key : value,
    label: i18n.t(`countries:${key}`),
  }));
