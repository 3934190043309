import { Box, Link, List, ListItem } from '@mui/material';
import classNames from 'classnames';
import AtlasIcon from 'components/AtlasIcon';
import OptionalTooltip from 'components/OptionalTooltip';
import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useStores } from 'store';
import { useEllipsis } from 'utils';
import { useStyles } from './SideNavFooter.styles';

const SideNavFooter: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { layoutStore } = useStores();

  const ellipsisElem = useRef<HTMLDivElement>(null);
  const { requiresEllipsis } = useEllipsis(ellipsisElem);

  return (
    <Box
      className={classNames(classes.navFooter, {
        [classes.navFooterCollapsed]: layoutStore.sidenavOpen,
      })}
    >
      <List className={classes.list}>
        <ListItem className={classes.listItem} disableGutters>
          {layoutStore.sidenavOpen && (
            <p className={classNames(classes.infoText, classes.footerText)}>
              <Trans i18nKey="label.contact_info_text" />:
            </p>
          )}
        </ListItem>
        <OptionalTooltip
          enabled={!layoutStore.sidenavOpen || requiresEllipsis}
          text={t('support.base')}
        >
          <Link
            className={classNames(classes.link, {
              [classes.listItemCollapsed]: !layoutStore.sidenavOpen,
            })}
            href="https://business.teliacompany.com/contact"
            target="_blank"
          >
            <ListItem className={classes.listItem} disableGutters>
              <AtlasIcon type="Support" className={classes.icon} />
              {layoutStore.sidenavOpen && t('support.contact_us')}
            </ListItem>
          </Link>
        </OptionalTooltip>
        <OptionalTooltip
          enabled={!layoutStore.sidenavOpen || requiresEllipsis}
          text={t('support.contact_url')}
        >
          <Link
            className={classNames(classes.link, {
              [classes.listItemCollapsed]: !layoutStore.sidenavOpen,
            })}
            href="https://business.teliacompany.com/internet-of-things"
            target="_blank"
          >
            <ListItem className={classes.listItem} disableGutters>
              <AtlasIcon type="Internet" className={classes.icon} />
              {layoutStore.sidenavOpen && t('support.contact_url')}
            </ListItem>
          </Link>
        </OptionalTooltip>
      </List>
    </Box>
  );
};

export default observer(SideNavFooter);
