import { DashboardComponentType } from 'generated';
import {
  IBaseComponentSettings,
  IComponentTypeConfig,
} from 'views/Dashboard/dashboardTypes';
import { IDataSourceSettings } from 'views/Dashboard/DataSources/dataSourceTypes';
import PreviewStep from '../PreviewStep';
import GeneralSettingsStep from './SettingsSteps/GeneralSettingsStep';
import TableComponentContainer from './TableComponentContainer';

export interface ITableSettings extends IBaseComponentSettings {
  title: string;
  dataSource: IDataSourceSettings;
  columns: ITableColumnSettings[];
  pivotBy: string[];
  policyId?: string;
}

export interface ITableColumnSettings {
  type: 'ownerProp' | 'dataPointProp';
  prop: string;
}

export const tableComponentConfig: IComponentTypeConfig<
  DashboardComponentType.Table,
  ITableSettings
> = {
  widgetType: DashboardComponentType.Table,
  icon: 'Graph',
  Component: TableComponentContainer,
  settingsSteps: [
    {
      title: 'settings',
      Component: GeneralSettingsStep,
    },
    {
      title: 'final_preview',
      Component: PreviewStep,
    },
  ],
  getDefaultSettings: ({ dataSource }) => {
    const ownerColumns: ITableColumnSettings[] = dataSource.groupProperties
      .filter(dp => dp.isDefaultDisplayName)
      .map(dp => ({
        type: 'ownerProp',
        prop: dp.id,
      }));

    return {
      position: { x: 0, y: 0, w: 12, h: 8 },
      title: '',
      dataSource: dataSource.settings,
      columns: [...ownerColumns],
      pivotBy: [],
    };
  },
  fixSettings: settings => {
    if (!settings.pivotBy) {
      settings.pivotBy = [];
    }
  },
};
