import { IconButton, List, ListItem, ListItemText } from '@mui/material';
import AtlasIcon from 'components/AtlasIcon';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  SortableContainer,
  SortableElement,
  SortEndHandler,
} from 'react-sortable-hoc';
import { arrayMoveMutate } from 'utils';
import { IDataSourceStore } from 'views/Dashboard/DataSources/dataSourceTypes';
import DataPropertyList from '../../DataPropertyList';
import { IMapPopupRow } from '../AzureMapsComponent/azureMapsConfig';
import { useStyles } from './PopupDetailsSettings.styles';

const SortableList = SortableContainer(List);

interface IListItemProps {
  className: string;
  divider?: boolean;
}
const MyListItem: React.FC<IListItemProps> = ({
  className,
  divider,
  children,
}) => (
  // react-sortable-hoc doesn't play entirely nice with ListItem if this div isn't present
  <div className={className}>
    <ListItem divider={divider}>{children}</ListItem>
  </div>
);
const SortableListItem = SortableElement(MyListItem);

interface IProps {
  rows: IMapPopupRow[];
  dataSource: IDataSourceStore;
}

const PopupDetailsSettings: React.FC<IProps> = ({ rows, dataSource }) => {
  const { t } = useTranslation('dashboard');
  const classes = useStyles();

  const addDataPropRow = (dpId: string) => {
    runInAction(() => {
      rows.push({ prop: dpId });
    });
  };

  const handleRemoveClick = (index: number) => {
    runInAction(() => {
      rows.splice(index, 1);
    });
  };

  const handleSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
    runInAction(() => {
      arrayMoveMutate(rows, oldIndex, newIndex);
    });
  };

  return (
    <>
      <SortableList
        className={classes.rowList}
        dense
        onSortEnd={handleSortEnd}
        helperClass="is-dragging"
        distance={10}
        lockAxis="y"
      >
        {rows.map((row, i) => (
          <SortableListItem
            key={row.prop}
            index={i}
            divider={i < rows.length - 1}
            className={classes.row}
          >
            <ListItemText>
              {dataSource.dataProperties.find(dp => dp.id === row.prop)?.name ||
                dataSource.groupProperties.find(dp => dp.id === row.prop)
                  ?.name ||
                row.prop}
            </ListItemText>

            <IconButton onClick={() => handleRemoveClick(i)} size="large">
              <AtlasIcon type="Clear" size={12} />
            </IconButton>
          </SortableListItem>
        ))}
      </SortableList>

      <DataPropertyList
        dataSource={dataSource}
        isOptionDisabled={dp => !!rows.find(row => row.prop === dp.id)}
        onDataPropSelect={addDataPropRow}
        dataPropertiesTitle={t('data.properties')}
        groupPropertiesTitle={t('asset_properties')} // Might need to be more dynamic in the future, only assets for now
        label={t('dashboard:popup_settings.action.add')}
      />
    </>
  );
};

export default observer(PopupDetailsSettings);
