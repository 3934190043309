import { InfiniteScrollAutoComplete } from 'components/Inputs';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useGetFilterDisplayName } from '../getFilterDisplayName';
import FilterColumn from './FilterColumn';
import { FilterControlState } from './FilterControlState';

interface IProps {
  control: FilterControlState;
}

const DropdownFilterControl: React.FC<IProps> = ({ control }) => {
  const {
    settings,
    key,
    selectedOptions,
    fetchMore,
    setValue,
    search,
    setSearch,
    isLoading,
    hasMore,
    options,
    index,
    isDisabled,
  } = control;
  const getFilterDisplayName = useGetFilterDisplayName();

  return (
    <FilterColumn>
      <InfiniteScrollAutoComplete
        id={key}
        options={options}
        getOptionLabel={opt => opt.name || opt.id}
        getOptionValue={opt => opt.id}
        label={getFilterDisplayName(settings)}
        clearable
        isLoading={isLoading}
        hasMore={hasMore}
        search={search}
        onSearchChange={setSearch}
        fetchMore={fetchMore}
        value={selectedOptions}
        onChange={setValue}
        dataCy={`dashboardFilter${index}`}
        multiple
        disabled={isDisabled}
        onBlur={() => control.setSearch('', 'clear')}
      />
    </FilterColumn>
  );
};

export default observer(DropdownFilterControl);
