import { Box, Switch } from '@mui/material';
import React from 'react';

interface IProps {
  value: boolean;
  leftText: string;
  rightText: string;
  onChange: (value: boolean) => void;
}

const TwoTextSwitch: React.FC<IProps> = ({
  value,
  leftText,
  rightText,
  onChange,
}) => (
  <Box display="flex" alignItems="center">
    {leftText}
    <Switch
      checked={!value}
      onClick={() => onChange(!value)}
      data-cy="TwoTextSwitch"
    />
    {rightText}
  </Box>
);

export default TwoTextSwitch;
