import { IntTicketStatus } from 'generated';
import i18n from 'i18next';
import { Column } from 'react-table';
import { SupportColors } from 'styles/themes/supportColors';
import { ICustomCellProps } from '../Helpers/CustomCellProps';
import { FilterOption } from '../Helpers/filterOption';
import { getOptionFilter } from '../Helpers/getOptionFilter';
import TicketStatusCell from '../Helpers/TicketStatusCell';

export function ticketStatus<T>(options: {
  id: string;
  accessor: (row: T) => IntTicketStatus;
  header?: string;
  defaultShow?: boolean;
  filterable?: boolean;
  sortable?: boolean;
  selectedStatus: string;
  palette: SupportColors;
}): Column<T> {
  const {
    id,
    header = 'Connected',
    accessor,
    defaultShow: show,
    filterable,
    sortable,
    selectedStatus,
    palette,
  } = options;

  const cellByStatus: Record<IntTicketStatus, ICustomCellProps> = {
    [IntTicketStatus.New]: {
      iconType: 'PurpurAdd',
      variant: 'new',
      text: i18n.t(`support:ticket_status.${IntTicketStatus.New}`),
      iconColor: palette.darkBlue,
      textColor: palette.darkBlue,
      backgroundColor: palette.lightBlue,
    },
    [IntTicketStatus.Open]: {
      iconType: 'PurpurInfo',
      variant: 'error',
      text: i18n.t(`support:ticket_status.${IntTicketStatus.Open}`),
      iconColor: palette.darkPurple,
      textColor: palette.darkPurple,
      backgroundColor: palette.lightPurple,
    },
    [IntTicketStatus.Closed]: {
      iconType: 'PurpurAlert',
      variant: 'default',
      text: i18n.t(`support:ticket_status.${IntTicketStatus.Closed}`),
      iconColor: palette.darkGray,
      textColor: palette.darkGray,
      backgroundColor: palette.lightGray,
    },
    [IntTicketStatus.Resolved]: {
      iconType: 'PurpurCheck',
      variant: 'success',
      text: i18n.t(`support:ticket_status.${IntTicketStatus.Resolved}`),
      iconColor: palette.darkGreen,
      textColor: palette.darkGreen,
      backgroundColor: palette.lightGreen,
    },
    [IntTicketStatus.AwaitingInfo]: {
      iconType: 'PurpurQuestion',
      variant: 'warning',
      text: i18n.t(`support:ticket_status.${IntTicketStatus.AwaitingInfo}`),
      iconColor: palette.darkOrange,
      textColor: palette.darkOrange,
      backgroundColor: palette.lightOrange,
    },
    [IntTicketStatus.Cancelled]: {
      iconType: 'PurpurClose',
      variant: 'unconfigured',
      text: i18n.t(`support:ticket_status.${IntTicketStatus.Cancelled}`),
      iconColor: palette.darkRed,
      textColor: palette.darkRed,
      backgroundColor: palette.lightRed,
    },
  };

  const filterOptionsOpen: FilterOption[] = [
    {
      label: i18n.t('sim:label.status_all'),
      value: '',
    },
    {
      label: i18n.t('common:action.ticket_status.open'),
      value: IntTicketStatus.Open,
    },
    {
      label: i18n.t('common:action.ticket_status.new'),
      value: IntTicketStatus.New,
    },
    {
      label: i18n.t('common:action.ticket_status.awaiting_info'),
      value: IntTicketStatus.AwaitingInfo,
    },
  ];

  const filterOptionsAll: FilterOption[] = [
    {
      label: i18n.t('sim:label.status_all'),
      value: '',
    },
    {
      label: i18n.t('common:action.ticket_status.open'),
      value: IntTicketStatus.Open,
    },
    {
      label: i18n.t('common:action.ticket_status.new'),
      value: IntTicketStatus.New,
    },
    {
      label: i18n.t('common:action.ticket_status.closed'),
      value: IntTicketStatus.Closed,
    },
    {
      label: i18n.t('common:action.ticket_status.resolved'),
      value: IntTicketStatus.Resolved,
    },
    {
      label: i18n.t('common:action.ticket_status.awaiting_info'),
      value: IntTicketStatus.AwaitingInfo,
    },
    {
      label: i18n.t('common:action.ticket_status.cancelled'),
      value: IntTicketStatus.Cancelled,
    },
  ];

  return {
    id,
    Header: header,
    accessor,
    resizable: false,
    maxWidth: 130,
    filterable,
    sortable,
    show,
    Cell: row => {
      const iconProps = cellByStatus[row.value as IntTicketStatus] || {};
      return <TicketStatusCell {...iconProps} index={row.index} />;
    },
    Filter: getOptionFilter(
      selectedStatus === 'Active' ? filterOptionsOpen : filterOptionsAll
    ),
  };
}
