import { aiTrackEvent, appInsights } from 'appInsights';
import { EFeature } from 'components/Auth/EFeature';
import { redirectToSubPortal } from 'components/Auth/RedirectToSubPortal';
import { Role } from 'components/Auth/Role';
import { snackbar } from 'components/Snackbar';
import {
  IntConfirmEmailDto,
  IntEditUserProfileDto,
  IntFeatureDto,
  IntForgotPasswordDto,
  IntLoginFormDto,
  IntLoginResult,
  IntResetPasswordDto,
  IntUserDto,
  UserRoleAccessLevel,
} from 'generated';
import i18n from 'i18n/i18n';
import {
  action,
  autorun,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import qs from 'qs';
import { accountService } from 'services/account.service';
import { userAPI } from 'services/user.service';
import {
  ITwoFactorForm,
  TEmailVerificationState,
  TLoginState,
  TResetPasswordState,
} from 'shared/interfaces/auth';
import { IRouteItem } from 'shared/interfaces/route-item';
import { isDevOrLocal } from 'utils';
import { User } from './domains/user';
import { RootStore } from './rootStore';
import { StoreBase } from './storeBase';

const defaultRedirectUrl = '/';

export enum UserAction {
  UPDATE = 'update',
  CREATE = 'create',
  CHANGEPASSWORD = 'changepassword',
  DELETE = 'delete',
}

const sortAccessLevelIndex: Record<UserRoleAccessLevel, number> = {
  [UserRoleAccessLevel.Global]: 5,
  [UserRoleAccessLevel.ManagingDealer]: 4,
  [UserRoleAccessLevel.Dealer]: 3,
  [UserRoleAccessLevel.Customer]: 2,
  [UserRoleAccessLevel.User]: 1,
  [UserRoleAccessLevel.NoAccess]: 0,
};

export class AuthStore extends StoreBase {
  @observable user: User | undefined;
  @observable features: IntFeatureDto[] = [];
  @observable solutionRoutes: IRouteItem[] = [];
  @observable hasFetchedUser = false;
  @observable loginState: TLoginState;
  @observable redirectUrl: string = defaultRedirectUrl;
  @observable providers: string[] | undefined;
  @observable loadingProviders = false;
  @observable selectedProvider = '';
  @observable loadingSelectProvider = false;
  @observable twoFaError: string | undefined;
  @observable verifyingTwoFaCode = false;
  @observable roleAndAccessLevels:
    | Record<string, UserRoleAccessLevel>
    | undefined;

  @observable resetPwdState: TResetPasswordState;
  @observable emailVerificationState: TEmailVerificationState;
  @observable deleteUserState = false;
  @observable forgotPwdStatus: boolean | undefined;
  @observable activeSubPortalDomainAlias: string | undefined;
  @observable isImpersonating: boolean = false;

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);

    autorun(() => {
      if (this.hasFetchedUser) {
        if (this.isLoggedIn && this.user) {
          appInsights.setAuthenticatedUserContext(
            this.user.id,
            this.user.userName,
            true
          );
          appInsights.addTelemetryInitializer(env => {
            if (!env.data) {
              env.data = {};
            }
            env.data.customerName = this.user?.customerName;
            env.data.customerId = this.user?.customerId;
            env.data.activeSubPortalDomainAlias =
              this.activeSubPortalDomainAlias ?? 'None';
          });
          this.rootStore.featureStore.getFeatures();
        } else {
          appInsights.addTelemetryInitializer(() => false);
          this.clearUserContext();
        }
      }
    });
  }

  @computed get isLoggedIn() {
    return !!this.user;
  }

  @computed get featureRecord() {
    const featureStatusByFeature: Record<string, boolean> = {};
    this.features.forEach(feature => {
      featureStatusByFeature[feature.featureKey] = feature.enabled;
    });
    return featureStatusByFeature;
  }

  @computed get initialUserFormValues(): IntEditUserProfileDto {
    return {
      userId: this.user?.userId || '',
      userName: this.user?.userName || '',
      firstName: this.user?.firstName || '',
      lastName: this.user?.lastName || '',
      email: this.user?.email || '',
      isEmailVerificationRequired: false,
      phoneNumber: this.user?.phoneNumber || '',
      languageCulture: this.user?.languageCulture || 'en-US',
      city: this.user?.city || '',
      zipCode: this.user?.zipCode || '',
      address: this.user?.address || '',
      countryName: this.user?.countryName || '',
      imageData: '',
      imageFileName: '',
      deleteImage: false,
    };
  }

  // replace with feature
  @computed get enablePreviewFeatures() {
    return this.hasFeature(EFeature.EnablePreviewFeatures);
  }

  // replace with feature
  @computed get enableLanguageSelector() {
    return this.hasFeature(EFeature.EnableLanguageSelector);
  }

  @computed get twoFactorFormIsLoading() {
    return (
      this.loadingProviders ||
      this.loadingSelectProvider ||
      this.verifyingTwoFaCode
    );
  }

  @action.bound setEmailVerificationState(state: TEmailVerificationState) {
    this.emailVerificationState = state;
  }

  @action.bound async postEmailVerification(values: IntConfirmEmailDto) {
    this.setEmailVerificationState(undefined);

    const response = await this.httpPost(accountService.emailVerification, {
      params: undefined,
      data: values,
    });

    if (response.status === 200) {
      this.setEmailVerificationState(response.data);
    } else {
      this.setEmailVerificationState('apiError');
    }
  }

  @action.bound setResetPwdState(state: TResetPasswordState) {
    this.resetPwdState = state;
  }

  @action.bound async resetPassword(values: IntResetPasswordDto) {
    this.setResetPwdState(undefined);

    const response = await this.httpPost(accountService.resetPassword, {
      params: undefined,
      data: values,
    });

    if (response.status === 200) {
      this.setResetPwdState(response.data);
    } else {
      this.setResetPwdState('apiError');
    }
  }

  @action.bound setForgotPwdStatus(status = false) {
    this.forgotPwdStatus = status;
  }

  @action.bound async requestNewPwd(values: IntForgotPasswordDto) {
    this.setForgotPwdStatus();

    const response = await this.httpPost(accountService.forgotPassword, {
      params: undefined,
      data: values,
    });

    this.setForgotPwdStatus(response.status === 200);
  }

  @action.bound setLoginState(state: TLoginState) {
    this.loginState = state;
  }

  @action.bound setRedirectUrl(search: string) {
    const { returnUrl } = qs.parse(search, { ignoreQueryPrefix: true });

    this.redirectUrl =
      typeof returnUrl === 'string' ? returnUrl : defaultRedirectUrl;
  }

  @action.bound clearUserContext() {
    this.setFeatures([]);
    this.setUser();
    this.rootStore.featureStore.dispose();
    this.setLoginState(undefined);
    appInsights.clearAuthenticatedUserContext();
  }

  @action.bound async getProviders() {
    const response = await this.httpGet(
      accountService.get2faProviders,
      undefined,
      loading => runInAction(() => (this.loadingProviders = loading))
    );

    if (response.status === 200 && response.data) {
      this.setProviders(response.data);
    } else {
      this.setTwoFaError(response.statusText);
    }
  }

  @action.bound setProviders(newProviders?: string[]) {
    this.providers = newProviders;
  }

  @action.bound async selectProvider(value: string) {
    this.setSelectedProvider('');

    const resp = await this.httpPost(
      accountService.sendCode,
      {
        params: undefined,
        data: { selectedProvider: value },
      },
      loading => runInAction(() => (this.loadingSelectProvider = loading))
    );

    if (resp.status === 200) {
      this.setSelectedProvider(value);
    } else {
      this.setTwoFaError(resp.statusText);
      this.setProviders();
    }
  }

  @action.bound setSelectedProvider(provider: string) {
    this.selectedProvider = provider;
  }

  @action.bound setTwoFaError(error?: string) {
    this.twoFaError = error;
  }

  @action.bound async verifyTwoFaCode(
    values: ITwoFactorForm,
    rememberMe: boolean,
    setFormikFieldError: (translationKey: string, statusText?: string) => void
  ) {
    const { status, data, statusText } = await this.httpPost(
      accountService.verifyCode,
      {
        params: undefined,
        data: {
          code: values.code,
          provider: this.selectedProvider,
          rememberMe,
          rememberBrowser: rememberMe,
        },
      },
      loading => runInAction(() => (this.verifyingTwoFaCode = loading))
    );

    if (status === 200 && data) {
      switch (data.loginResult) {
        case IntLoginResult.Success:
          this.setLoginState(data.loginResult);
          this.getUserAuthData();
          break;
        case IntLoginResult.LockedOut:
          this.setTwoFaError(i18n.t('auth:2fa.account_locked'));
          this.setSelectedProvider('');
          this.setProviders();
          break;
        case IntLoginResult.Failure:
        default:
          setFormikFieldError('auth:2fa.code.invalid');
          break;
      }
    } else {
      setFormikFieldError('error.api', statusText);
    }
  }

  @action.bound async getUserAuthData() {
    const {
      dynamicAttributeStore: { getDefinitions, getCustomAttributes },
    } = this.rootStore;

    const userAuthData = await this.httpGet(
      accountService.getUserAuthorization,
      undefined
    );

    if (userAuthData.status === 200 && userAuthData.data) {
      const {
        features,
        userModel,
        activeSubPortalDomainAlias,
        isImpersonating,
        roleAndAccessLevels,
      } = userAuthData.data;

      this.setIsImpersonating(isImpersonating);
      this.setActiveSubPortalDomainAlias(activeSubPortalDomainAlias);
      this.setRoleAndAccessLevels(roleAndAccessLevels);
      this.setFeatures(features || []);
      this.setUser(userModel);

      if (this.hasRole(Role.RoleNameViewAsset)) {
        await getDefinitions();
      }

      if (this.hasRole(Role.RoleNameViewConnectivityUnitAttributeDefinitions)) {
        await getCustomAttributes();
      }
    } else if (
      userAuthData.status === 204 ||
      userAuthData.status === 400 ||
      userAuthData.status === 503
    ) {
      this.setLoginState('userRelatedError');
      this.clearUserContext();
    } else {
      this.clearUserContext();
    }
    runInAction(() => {
      this.hasFetchedUser = true;
    });
  }

  @action.bound async login(values: IntLoginFormDto) {
    this.setLoginState(undefined);

    const response = await this.httpPost(accountService.login, {
      params: undefined,
      data: values,
    });

    if (response.status === 200) {
      this.setLoginState(response.data);
      response.data === IntLoginResult.Success && this.getUserAuthData();
    } else {
      this.setLoginState('apiError');
    }
  }

  @action.bound async logout() {
    const logoutResp = await this.httpPost(accountService.logout, {
      params: undefined,
    });
    if (logoutResp.status !== 200) {
      snackbar(logoutResp.statusText, { variant: 'error' });
    }
    this.clearUserContext();
  }

  @action.bound setUser(user?: IntUserDto) {
    this.user = user ? new User(this.rootStore, user) : undefined;
  }

  @action.bound toggleRole = (role: Role, force = false) => {
    if (!this.roleAndAccessLevels) {
      return;
    }
    const roleIsActive =
      role in this.roleAndAccessLevels &&
      sortAccessLevelIndex[this.roleAndAccessLevels[role]] >
        sortAccessLevelIndex[UserRoleAccessLevel.NoAccess];

    if (force || !roleIsActive) {
      this.roleAndAccessLevels[role] = UserRoleAccessLevel.Global;
    } else {
      delete this.roleAndAccessLevels[role];
    }
  };

  @action.bound setRoleAndAccessLevels(
    roleAndAccessLevels: Record<string, UserRoleAccessLevel>
  ) {
    this.roleAndAccessLevels = roleAndAccessLevels;
  }

  @action.bound setFeatures(features: IntFeatureDto[]) {
    this.features = features;
  }

  @action.bound setDeleteUserState(state: boolean) {
    this.deleteUserState = state;
  }

  @action.bound async deleteUser(userId: string) {
    if (userId === this.user?.userId) {
      snackbar(i18n.t('user:user.delete.unapproved'), { variant: 'error' });
    } else if (userId) {
      const response = await this.httpDelete(userAPI.deleteUser, {
        params: userId,
      });

      if ([200, 204].includes(response.status)) {
        this.setDeleteUserState(true);
        snackbar(i18n.t('user:user.delete.success'), {
          variant: 'success',
        });

        aiTrackEvent('Delete', { title: 'User' });

        this.setDeleteUserState(false);
      } else {
        this.setDeleteUserState(false);
        snackbar(
          i18n.t('user:user.delete.failure', { reason: response.statusText }),
          {
            variant: 'error',
          }
        );
      }
    }
  }

  hasRole = (
    requiredRoles: Role | Role[] | undefined,
    minAccessLevel = UserRoleAccessLevel.User
  ): boolean => {
    if (!requiredRoles) {
      return true;
    }

    if (!this.roleAndAccessLevels) {
      return false;
    }

    if (typeof requiredRoles === 'string') {
      return (
        requiredRoles in this.roleAndAccessLevels &&
        sortAccessLevelIndex[this.roleAndAccessLevels[requiredRoles]] >=
          sortAccessLevelIndex[minAccessLevel]
      );
    }

    for (const role of requiredRoles) {
      if (
        !(role in this.roleAndAccessLevels) ||
        sortAccessLevelIndex[this.roleAndAccessLevels[role]] <
          sortAccessLevelIndex[minAccessLevel]
      ) {
        return false;
      }
    }

    return true;
  };

  hasFeature = (feature: EFeature | undefined): boolean => {
    if (!isDevOrLocal && feature === EFeature.EnablePreviewFeatures) {
      return this.featureRecord[feature] || false;
    }
    return feature === undefined || this.featureRecord[feature] !== false;
  };

  @action.bound async redirectToSubPortal() {
    const isFeatureFlagEnabled = this.hasFeature(
      EFeature.EnableRedirectToSubPortal
    );
    return redirectToSubPortal(
      this.isLoggedIn,
      this.activeSubPortalDomainAlias,
      isFeatureFlagEnabled
    );
  }

  @action.bound setActiveSubPortalDomainAlias(domain?: string) {
    this.activeSubPortalDomainAlias = domain;
  }

  @action.bound setIsImpersonating(impersonating: boolean) {
    this.isImpersonating = impersonating;
  }
}
