import React from 'react';
import { useTranslation } from 'react-i18next';
import DataPropertyList from 'views/Dashboard/DataPropertyList';
import { IDataProperty } from 'views/Dashboard/DataSources/dataPropTypes';
import { IDataSourceStore } from 'views/Dashboard/DataSources/dataSourceTypes';

interface IProps {
  id: string;
  label: string;
  dataSource: IDataSourceStore;
  onChange: (id: string) => void;
  isOptionDisabled?: (dataProp: IDataProperty) => boolean;
  showDataProps?: boolean;
  showGroupProps?: boolean;
}

const SelectDataPropButton: React.FC<IProps> = ({
  id,
  dataSource,
  onChange,
  isOptionDisabled,
  label,
  showDataProps,
  showGroupProps,
}) => {
  const { t } = useTranslation('dashboard');

  return (
    <DataPropertyList
      label={label}
      dataSource={dataSource}
      isOptionDisabled={isOptionDisabled}
      onDataPropSelect={onChange}
      dataPropertiesTitle={t('data.properties')}
      groupPropertiesTitle={t('asset_properties')} // Might need to be more dynamic in the future, only assets for now
      showDataProps={showDataProps}
      showGroupProps={showGroupProps}
    />
  );
};

export default SelectDataPropButton;
