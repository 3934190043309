import { Box } from '@mui/material';
import AtlasIcon from 'components/AtlasIcon';
import AtlasTooltip from 'components/AtlasTooltip';
import { AssetState } from 'generated';
import i18n from 'i18next';
import { Column } from 'react-table';
import { stylingNames } from '../Columns.styles';
import { CustomCell } from '../Helpers/CustomCell';
import { ICustomCellProps } from '../Helpers/CustomCellProps';
import { FilterOption } from '../Helpers/filterOption';
import { getOptionFilter } from '../Helpers/getOptionFilter';

export function assetState<T>(options: {
  id: string;
  accessor: (row: T) => AssetState;
  header?: string;
  filterable?: boolean;
  sortable?: boolean;
  tooltip?: boolean;
  tooltipText?: string;
}): Column<T> {
  const {
    id,
    header = 'Status',
    accessor,
    sortable,
    filterable,
    tooltip = false,
    tooltipText = '',
  } = options;
  const cellByState: Record<AssetState, ICustomCellProps> = {
    [AssetState.Unknown]: {
      text: i18n.t('asset:asset_status.unknown'),
    },
    [AssetState.Provisioned]: {
      text: i18n.t('asset:asset_status.provisioned'),
    },
    [AssetState.Active]: {
      text: i18n.t('asset:asset_status.active'),
    },
    [AssetState.Terminated]: {
      text: i18n.t('asset:asset_status.terminated'),
    },
  };

  const filterOptions: FilterOption[] = [
    { label: i18n.t('common:all'), value: '' },
    { label: i18n.t('asset:asset_status.unknown'), value: AssetState.Unknown },
    {
      label: i18n.t('asset:asset_status.provisioned'),
      value: AssetState.Provisioned,
    },
    { label: i18n.t('asset:asset_status.active'), value: AssetState.Active },
    {
      label: i18n.t('asset:asset_status.terminated'),
      value: AssetState.Terminated,
    },
  ];

  return {
    id,
    Header: tooltip ? (
      <AtlasTooltip placement="top" title={tooltipText} arrow>
        <Box>
          {header}
          <AtlasIcon size={14} type="Info" />
        </Box>
      </AtlasTooltip>
    ) : (
      header
    ),
    accessor,
    className: stylingNames.textCenter,
    resizable: false,
    maxWidth: 130,
    filterable,
    sortable,
    Filter: getOptionFilter(filterOptions),
    Cell: row => {
      const iconProps = cellByState[row.value as AssetState] || {};
      return <CustomCell {...iconProps} index={row.index} />;
    },
  };
}
