import React from 'react';
import SwipeableViews from 'react-swipeable-views';

interface SwipeWrapperProps {
  disabled?: boolean;
  index: number;
}

const SwipeWrapper: React.FC<SwipeWrapperProps> = ({
  disabled,
  index,
  children,
}) => {
  return (
    <>
      {disabled ? (
        <>{children}</>
      ) : (
        <SwipeableViews
          slideStyle={{ overflow: 'visible' }}
          containerStyle={{ flex: 1 }}
          index={index}
        >
          {children}
        </SwipeableViews>
      )}
    </>
  );
};

export default SwipeWrapper;
