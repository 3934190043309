import { InputAdornment, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect } from 'react';
import { useComponentContext } from '../contexts/componentContext';
import { useDashboardContext } from '../contexts/dashboardContext';
import { IControlProps } from './controlProps';

interface IProps {
  placeholder?: string;
  min?: number;
  max?: number;
  suffix?: string;
  helperText?: string;
  getValidationMsg?: (value: string) => string | undefined;
}

function NumberControl<TObj>(props: IControlProps<TObj, number> & IProps) {
  const {
    name,
    object,
    label,
    placeholder = '',
    min,
    max,
    suffix,
    helperText,
    getValidationMsg,
  } = props;
  const { updateSetting } = useDashboardContext();
  const { settingsErrors, setSettingsError } = useComponentContext();

  const getValue = useCallback(() => {
    if (object) {
      const rawValue = object[name] as any;

      return rawValue === undefined ? '' : rawValue;
    }

    return '';
  }, [name, object]);

  useEffect(() => {
    if (getValidationMsg) {
      setSettingsError(name, getValidationMsg(getValue()));
    }

    return () => {
      setSettingsError(name);
    };
  }, [getValidationMsg, object, setSettingsError, name, getValue]);

  if (!object) {
    return null;
  }

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    updateSetting(
      object,
      name,
      isNaN(e.target.valueAsNumber) ? e.target.value : e.target.valueAsNumber
    );
  };

  const hasError = !!settingsErrors[name];

  return (
    <TextField
      type="number"
      variant="outlined"
      fullWidth
      label={label || ''}
      value={getValue()}
      placeholder={placeholder}
      error={hasError}
      onChange={handleChange}
      onBlur={e => {
        if (getValidationMsg) {
          setSettingsError(name, getValidationMsg(e.currentTarget.value));
        }
      }}
      helperText={settingsErrors[name] || helperText}
      onKeyDown={e => {
        // disable "e" and "E"
        if (e.key === 'e' || e.key === 'E') {
          e.preventDefault();
        }

        if (hasError && e.key === 'Enter') {
          e.preventDefault();
        }
      }}
      onPaste={e => {
        // disable pasting "e" and "E"
        if (e.clipboardData.getData('text').search(/[eE]/) !== -1) {
          e.preventDefault();
        }
      }}
      inputProps={{
        min,
        max,
        'data-testid': `${name}-input`,
      }}
      InputProps={{
        endAdornment: suffix && (
          <InputAdornment
            style={{ top: 6, position: 'relative' }}
            position="end"
          >
            {suffix}
          </InputAdornment>
        ),
      }}
    />
  );
}

export default observer(NumberControl);
