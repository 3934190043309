import {
  IntAccessEndpointDto,
  IntCreateAccessEndpointRequestDto,
} from 'generated';
import {
  DeleteEndpoint,
  GetEndpoint,
  PostEndpoint,
  TableEndpoint,
} from 'shared/interfaces/api';
import { accessEndpointDemoData } from './demoData/accessEndpointDemoData';

interface AccessEndpointAPI {
  get: GetEndpoint<string, IntAccessEndpointDto>;
  getAll: (workloadId: string) => TableEndpoint<IntAccessEndpointDto>;
  create: PostEndpoint<
    undefined,
    IntCreateAccessEndpointRequestDto,
    IntAccessEndpointDto
  >;
  delete: DeleteEndpoint<string>;
}

const base = '/api/accessendpoints';

export const accessEndpointAPI: AccessEndpointAPI = {
  getAll: workloadId => ({
    url: `${base}/byworkload/${workloadId}`,
    mockData: accessEndpointDemoData.getAll,
  }),
  get: {
    url: accessEndpointId => `${base}/${accessEndpointId}`,
    mockData: accessEndpointDemoData.get,
  },
  create: {
    url: `${base}`,
    mockData: accessEndpointDemoData.create,
  },
  delete: {
    url: accessEndpointId => `${base}/${accessEndpointId}`,
    mockData: accessEndpointDemoData.delete,
  },
};
