import { IDataProperty } from 'views/Dashboard/DataSources/dataPropTypes';

function getNumber(prop: IDataProperty, data: any): number | null {
  if (prop.type === 'dateTime') {
    return prop._get(data)?.getTime() || null;
  }
  if (prop.type !== 'number') {
    return null;
  }
  return prop._get(data);
}

function getString(prop: IDataProperty, data: any): string {
  const value = prop._get(data) ?? '';
  if (prop.type === 'string') {
    return value as string;
  }
  return `${value}`;
}

function getBool(prop: IDataProperty, data: any): boolean {
  const value = prop._get(data) || '';
  if (prop.type === 'boolean') {
    return value as boolean;
  }
  return Boolean(value);
}

function get(prop: IDataProperty, data: any): any {
  return prop._get(data);
}

export const dataProp = {
  getNumber,
  getString,
  getBool,
  get,
};
