import { Box, Button } from '@mui/material';
import AtlasIcon from 'components/AtlasIcon';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { IDashboardComponent } from 'views/Dashboard/dashboardTypes';
import Widget from 'views/Dashboard/Widget';
import FilterControlContainer from './Filters/FilterControlContainer';
import { FilterControlState } from './Filters/FilterControlState';
import { IFiltersSettings } from './filtersConfig';

// Divide filters evenly between one or two rows of up to 5 controls, with the upper row getting one more if it's an uneven number
function getControlRows(
  controls: FilterControlState[]
): FilterControlState[][] {
  if (controls.length <= 5) {
    return [controls];
  }
  const topRowLength = Math.ceil(controls.length / 2);
  return [controls.slice(0, topRowLength), controls.slice(topRowLength)];
}

const FiltersComponent: IDashboardComponent<IFiltersSettings> = ({
  component,
}) => {
  const { t } = useTranslation('dashboard');
  const {
    dashboard: { filterState },
  } = component;

  const rows = getControlRows(filterState.controls);

  return (
    <Widget>
      <Widget.Header>
        {t('filter.base')}
        {filterState.canClear && (
          <Button
            onClick={filterState.clearFilters}
            size="small"
            data-cy="clearFilters"
            style={{
              position: 'absolute',
              right: 74,
              top: 8,
              backgroundColor: '#e0e0e0',
              lineHeight: 1.4,
              fontSize: 12,
              fontWeight: 'normal',
            }}
          >
            {t('common:action.clear')}&nbsp;
            <AtlasIcon type="Clear" size={12} />
          </Button>
        )}
      </Widget.Header>

      <Widget.Content>
        {rows.map((row, rowIndex) => (
          <Box key={rowIndex} px={1} display="flex">
            {row.map(control => (
              <FilterControlContainer key={control.key} control={control} />
            ))}
          </Box>
        ))}
      </Widget.Content>
    </Widget>
  );
};

export default observer(FiltersComponent);
