import * as azureMaps from 'azure-maps-control';
import { ServiceDataFilterType } from 'generated';
import { makeAutoObservable } from 'mobx';
import { rnd } from 'utils';
import { IImagePointSettings, IImageSettings } from '../imageConfig';

export class EditMapPointState {
  settings: IImageSettings;

  constructor(settings: IImageSettings) {
    makeAutoObservable(this);

    this.settings = settings;
  }

  clickMode: false | 'add' | 'move' = false;

  selectedId = '';

  setSelectedId = (id: string) => {
    this.selectedId = id;
  };

  get selectedPoint() {
    if (!this.selectedId) {
      return undefined;
    }
    return this.settings.dataPoints.find(dp => dp.id === this.selectedId);
  }

  startAddMode = () => {
    this.clickMode = 'add';
  };

  cancelAddMode = () => {
    this.clickMode = false;
  };

  updateDataSourceSettings = () => {
    const { dataSource, dataPoints } = this.settings;
    if (dataSource.type === 'serviceLatest') {
      // Can't select filters manually, fine to overwrite
      dataSource.serviceDataFilters = [
        {
          type: ServiceDataFilterType.AssetId,
          values: dataPoints
            .map(p => p.assetId)
            .filter(Boolean)
            .sort(),
        },
      ];
    } else {
      // Nothing else is supported (for now?)
    }
  };

  deleteSelectedPoint = () => {
    if (this.selectedId) {
      this.settings.dataPoints = this.settings.dataPoints.filter(
        p => p.id !== this.selectedId
      );
      this.selectedId = '';
    }
  };

  startMovePoint = () => {
    this.clickMode = 'move';
  };

  handleMapClick = (e: azureMaps.MapMouseEvent) => {
    const { position } = e;

    if (!this.clickMode || !position) {
      return;
    }

    const { dataPoints } = this.settings;

    let defaultDataProp = '';
    if (dataPoints.length) {
      defaultDataProp = dataPoints[dataPoints.length - 1].dataProp;
    }

    if (this.clickMode === 'add') {
      const newPoint: IImagePointSettings = {
        id: rnd.guid(),
        assetId: '',
        position: [position[0], position[1]],
        dataProp: defaultDataProp,
      };
      this.settings.dataPoints.push(newPoint);
      this.selectedId = newPoint.id;
    } else if (this.clickMode === 'move' && this.selectedPoint) {
      this.selectedPoint.position = [position[0], position[1]];
    }
    this.clickMode = false;
  };
}
