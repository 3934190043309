import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    unreadIndicator: {
      color: '#fe3263',
      fontSize: '0.6rem',
      padding: theme.spacing(1),
    },
    timeAgo: {
      color: '#c0c0c0',
    },
  })
);
