import { IntMenuItemDto } from 'generated';
import { useEffect, useMemo, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { flattenedRoutes } from 'Routes';
import { IRouteItem } from 'shared/interfaces/route-item';
import { useStores } from 'store';
import { staticNavigationId } from 'store/layoutStore';

export interface INavigationMenuState {
  activeMenuItem?: IntMenuItemDto;
  activeRoute?: IRouteItem;
  activeRootItem?: IntMenuItemDto;
  setActiveRootItem: (item?: IntMenuItemDto) => void;
  canCreateDashboard: boolean;
}

function menuItemCanContainDashboards(rootItem: IntMenuItemDto | undefined) {
  if (!rootItem) {
    return false;
  }
  if (rootItem.displayName.toLowerCase() === 'management') {
    return false;
  }
  if (rootItem.route.includes('/connectivity')) {
    return false;
  }
  if (rootItem.displayName.toLowerCase() === 'my dashboard') {
    return false;
  }
  return true;
}

export function useNavMenuState(): INavigationMenuState {
  const {
    layoutStore: { flatMenuItems, navigation },
  } = useStores();
  const { pathname, search } = useLocation();

  const { activeMenuItem, activeRoute } = useMemo(() => {
    const fullPath = pathname + search;

    // Get the active route by matching, the same way rect-router does it
    const currentlyActiveRoute = flattenedRoutes.find(r =>
      matchPath(fullPath, { path: r.path, exact: r.exact })
    );

    return {
      activeMenuItem:
        // First try to find exact match (important for items linking to an exact guid, eg dashboards)
        flatMenuItems.find(item => item.route === fullPath) ||
        // Or the path without search params (table sorts etc)
        flatMenuItems.find(item => item.route === pathname) ||
        // Otherwise highlight the menu item for "overview" pages, even if we're on a details page without its own menuitem
        flatMenuItems.find(
          item => item.route === currentlyActiveRoute?.parentItem?.path
        ) ||
        // If we're on a sub-details page without a direct parent overview page, highlight the next parents overview page
        flatMenuItems.find(
          item =>
            item.route === currentlyActiveRoute?.parentItem?.parentItem?.path
        ),
      activeRoute: currentlyActiveRoute,
    };
  }, [pathname, search, flatMenuItems]);

  const [activeRootItem, setActiveRootItem] = useState(() =>
    activeMenuItem ? getRootMenuItem(activeMenuItem, flatMenuItems) : undefined
  );

  const canCreateDashboard =
    navigation?.navigationId !== staticNavigationId && // Creating dashboards in a static menu makes little sense, disable
    menuItemCanContainDashboards(activeRootItem);

  // Update the active root item when the user clicks on links
  useEffect(() => {
    setActiveRootItem(
      activeMenuItem
        ? getRootMenuItem(activeMenuItem, flatMenuItems)
        : undefined
    );
  }, [activeMenuItem, flatMenuItems]);

  return {
    activeMenuItem,
    activeRoute,
    activeRootItem,
    setActiveRootItem,
    canCreateDashboard,
  };
}

// Traverse up the tree and return the top-level MenuItem
function getRootMenuItem(
  activeItem: IntMenuItemDto,
  allItems: IntMenuItemDto[]
) {
  const getParent = (item: IntMenuItemDto): IntMenuItemDto => {
    if (!item.parentId) {
      return item;
    }

    const parent = allItems.find(x => x.menuItemId === item.parentId);
    if (!parent) {
      throw new Error('Could not find parent for item');
    }

    return getParent(parent);
  };

  return getParent(activeItem);
}
