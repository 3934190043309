import { ServiceDataFilterType } from 'generated';
import { useMemo } from 'react';
import { useStores } from 'store';
import { $enum } from 'ts-enum-util';
import { sortByString } from 'utils';
import {
  IAssetFilterSettings,
  IDashboardFilterSettings,
} from '../filtersConfig';
import { useGetFilterDisplayName } from '../getFilterDisplayName';
import { onlyUnusedFilters } from './onlyUnusedFilters';

export function useAssetFilterOptions(
  alreadyUsedFilters?: IDashboardFilterSettings[]
): IAssetFilterSettings[] {
  const {
    dynamicAttributeStore: { definitions },
  } = useStores();
  const getFilterDisplayName = useGetFilterDisplayName();

  return useMemo(() => {
    const assetFilterOptions: IAssetFilterSettings[] = $enum(
      ServiceDataFilterType
    )
      .map(item => ({
        entity: 'asset' as const,
        type: item,
      }))
      .filter(controlType => {
        // These FilterTypes do not have controls, but are set through other means.
        switch (controlType.type) {
          case ServiceDataFilterType.Limit:
          case ServiceDataFilterType.ResourceGroupId:
          case ServiceDataFilterType.RegisterDateStart:
          case ServiceDataFilterType.RegisterDateEnd:
          case ServiceDataFilterType.City:
          case ServiceDataFilterType.Building:
          case ServiceDataFilterType.Floor:
          case ServiceDataFilterType.SubSpace:
          case ServiceDataFilterType.MaintenanceUnit:
          case ServiceDataFilterType.ApartmentId:
          case ServiceDataFilterType.ReferenceLabel:
          case ServiceDataFilterType.DynamicAttribute: // Added below
            return false;

          default:
            return true;
        }
      });

    const dynamicAttributeFilterOptions: IAssetFilterSettings[] =
      definitions.map(
        (def): IAssetFilterSettings => ({
          entity: 'asset',
          type: ServiceDataFilterType.DynamicAttribute,
          definitionId: def.id || '',
        })
      );

    const filterTypeOptions = [
      ...assetFilterOptions,
      ...dynamicAttributeFilterOptions,
    ];

    const unusedOptions = alreadyUsedFilters?.length
      ? onlyUnusedFilters(alreadyUsedFilters, filterTypeOptions)
      : filterTypeOptions;

    return [...unusedOptions].sort(sortByString(getFilterDisplayName));
  }, [definitions, getFilterDisplayName, alreadyUsedFilters]);
}
