import { createStyles, makeStyles } from '@mui/styles';
import { fontSettings } from 'styles/themes/fontSettings';
import chevronLeft from '../../shared/icons/svg/chevron-left.svg';
import chevronRight from '../../shared/icons/svg/chevron-right.svg';

export const useStyles = makeStyles(theme => {
  const tableSettings = theme.settings.components.table;

  return createStyles({
    root: {
      border: 'none !important',

      '& .rt-td': {
        ...(tableSettings.td as {}),
      },

      '& .rt-td.button-cell': {
        padding: 4,
      },

      '& .rt-th': { ...(tableSettings.th as {}) },

      '&.widget-table .rt-td:first-of-type': {
        paddingLeft: '1.125rem',
      },

      '&.widget-table .rt-td:last-child': {
        paddingRight: '1.125rem',
      },
      '&.ReactTable': {
        '& .rt-noData': {
          background: 'transparent',
        },
        '& .rt-td.button-column': {
          padding: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        '& .rt-thead': {
          position: 'sticky',
          background: 'white',
          zIndex: 2,
          boxShadow: 'none',
          '&.-header': {
            top: 0,
          },
          '&.-filters': {
            top: '31px',
          },
        },
        '& .rt-thead .rt-resizable-header, & .rt-thead .rt-tr .-cursor-pointer':
          {
            paddingTop: 7,
          },
        // Sorting indicator top/bottom borders
        '& .rt-thead .rt-th.-sort-desc, & .rt-thead .rt-td.-sort-desc': {
          boxShadow: `inset 0 -3px 0 0 ${theme.palette.primary.main}`,
        },
        '& .rt-thead .rt-th.-sort-asc, & .rt-thead .rt-td.-sort-asc': {
          boxShadow: `inset 0 3px 0 0 ${theme.palette.primary.main}`,
        },
        // ResourceTable specific styles
        '&.resource-table': {
          '& .rt-thead.-header': {
            borderBottom: '1px solid darkgrey',
          },
        },
        // Styles shared by several table types (potential for cleanup here)
        '&.resource-table, &.form-table, &.event-table': {
          '& .rt-thead.-filters': {
            '& input, & select': {
              height: 30,
            },
            '& input': {
              paddingTop: 7,
            },
            '& .rt-th': {
              overflow: 'visible',
            },
          },
        },
        '& .rt-tr': {
          '&.-even': {
            backgroundColor: tableSettings.evenRowColor,
            '& + .rt-tr-details': {
              backgroundColor: tableSettings.evenRowColor,
            },
          },
          '&.-odd': {
            backgroundColor: tableSettings.oddRowColor,
            '& + .rt-tr-details': {
              backgroundColor: tableSettings.oddRowColor,
            },
          },
          '&.not-expandable > .rt-expandable': {
            visibility: 'hidden',
          },
        },
        '& .rt-tr-group': {
          borderBottom: 'none !important',
          flexGrow: 0,
          '& > .rt-tr-group': {
            boxShadow: '2px 0 0 0 red',
          },
        },
        '& .rt-tbody': {
          background: 'transparent',
          overflow: 'visible',
        },
        // Fix input fields inside table cells without ruining column filters outside of the table body
        '& .rt-tbody .MuiFormControl-root, & .rt-tbody .MuiFormControl-root *':
          {
            boxSizing: 'content-box',
          },

        '& .rt-tr.selected': {
          backgroundColor: tableSettings.selectedRowBackground,
        },

        '& .rt-expandable': {
          '& .rt-expander::after': {
            borderTopColor: theme.palette.text.primary,
          },
          '&:hover .rt-expander::after': {
            borderTopColor: theme.palette.primary.light,
          },
        },
        '& .pagination-bottom': {
          '& .-pagination': {
            padding: '10px 3px',
            background: 'white',
            '& input, & select': {
              padding: '2px 7px',
            },
          },
          '& .-previous': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          },
          '& .-center': {
            flex: 'inherit',
            justifyContent: 'center',
            fontSize: '1rem',
            alignItems: 'center',
            '& .-pageInfo': {
              margin: '3px 10px 3px 20px',
            },
            '& .select-wrap': {
              margin: '3px 20px 3px 10px',
            },
          },
          '& .-next': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
          },

          '& button.-btn': {
            borderRadius: 999,
            color: '#000',
            background: 'none',
            fontSize: '1rem',
            fontFamily: fontSettings.TeliaSansReg.fontStack,
            fontWeight: 500,
            lineHeight: 1,
            width: 'auto',
            height: '40px',

            '&:hover': {
              background: 'none',
            },
            '&:focus-visible': {
              offset: '3px',
            },
            '&[disabled]': {
              opacity: 0,
            },
          },
          '& .-next button.-btn::after, .-previous button.-btn::before': {
            content: '""',
            display: 'inline-block',
            width: 20,
            height: 20,
            backgroundSize: 25,
            backgroundPosition: -4,
            transform: 'none',
            transition: 'transform 120ms ease-out',
            position: 'relative',
          },
          '& .-next button.-btn': {
            '&::after': {
              maskImage: `url(${chevronRight})`,
              '::-webkit-mask-image': `url(${chevronRight})`,
              backgroundColor: '#000',
            },
          },
          '& .-previous button.-btn': {
            '&::before': {
              maskImage: `url(${chevronLeft})`,
              '::-webkit-mask-image': `url(${chevronLeft})`,
              backgroundColor: '#000',
            },
          },
        },
      },
    },
  });
});
