import {
  IntAtlasServiceDto,
  IntAtlasServicePropertyDto,
  IntServiceDataSpecificationColumnDto,
  ServiceDataSpecificationColumnType,
  ServicePropertyDataType,
  ServicePropertyDisplayFormat,
} from 'generated';
import i18n from 'i18n';
import { AtlasIconType } from 'shared/types/atlas-icon';
import { parseDate, translateTextContent } from 'utils';
import {
  IDataProperty,
  IDataPropPropSettings,
  INumberDataProp,
  ServiceAggregationType,
} from '../dataPropTypes';

// Service data
export type ITelemetry = Record<string, string>;

export const registerDateDataProp: IDataProperty = {
  id: 'registerDate',
  _get: val => parseDate(val.register_date),
  type: 'dateTime',
  name: i18n.t('asset:property.date'),
};

// These properties always exist on all services
export function addHardCodedServiceProperties(service: IntAtlasServiceDto) {
  if (!service || !service.properties) {
    return;
  }

  service.properties.push({
    servicePropertyId: 'registerDate',
    dataType: ServicePropertyDataType.DateTime,
    displayName: i18n.t('asset:property.date'),
    servicePropertyName: 'RegisterDate',
    storageColumnName: 'register_date',
    unit: '',
    displayNames: {},
  });
}

const getRawValue = (
  storageColumnName: string,
  data: ITelemetry,
  settings: IDataPropPropSettings
): string | null => {
  const { agg = 'avg' } = settings; // Default to average, then fall back to storageColumnName (absolute)
  const path =
    agg === 'abs' ? storageColumnName : `${storageColumnName}_${agg}`;

  return data && path ? data[path] ?? data[storageColumnName] : null;
};

function getServicePropIcon(displayName: string): AtlasIconType {
  switch (displayName.toLowerCase()) {
    case 'temperature':
      return 'Temperature';

    case 'relative humidity':
    case 'humidity':
      return 'Moisture';

    case 'air quality index':
    case 'aqi':
      return 'Cloud';

    default:
      return 'Status';
  }
}

export function servicePropToDataProp(opts: {
  sp: IntAtlasServicePropertyDto;
  entity?: 'asset' | 'connectivityUnit';
  isLatest?: boolean;
  languageCulture: string;
  category: string;
}): IDataProperty<ITelemetry> {
  const {
    sp,
    entity = 'asset',
    isLatest = false,
    languageCulture,
    category,
  } = opts;
  const {
    dataType,
    displayName,
    displayNames = {},
    servicePropertyId: id,
    dataRangeMax,
    dataRangeMin,
    unit,
    conversion,
    storageColumnName,
  } = sp;

  const propIdentifier =
    category === 'IntelligenceEventsDataProp' ? storageColumnName : id;

  const name =
    translateTextContent(displayNames, languageCulture) || displayName;

  const icon = getServicePropIcon(displayName);

  const column: IntServiceDataSpecificationColumnDto | undefined =
    entity === 'asset'
      ? {
          columnType: ServiceDataSpecificationColumnType.ServiceProperty,
          value: id,
        }
      : undefined;

  if (dataType === ServicePropertyDataType.DateTime) {
    return {
      id,
      type: 'dateTime',
      category: { displayName: category },
      _get: data => {
        const rawValue = getRawValue(propIdentifier, data, { agg: 'abs' });
        if (!rawValue) {
          return null;
        }
        return new Date(rawValue);
      },
      name,
      column,
      unit,
      icon,
    };
  } else if (dataType === ServicePropertyDataType.Number) {
    const getNumberProp = (
      agg?: ServiceAggregationType
    ): INumberDataProp<ITelemetry> => ({
      id,
      type: 'number',
      category: { displayName: category },
      _get: data => {
        const rawValue = getRawValue(propIdentifier, data, { agg });

        let parsedValue: number | null = null;
        if (typeof rawValue === 'number') {
          parsedValue = rawValue;
        } else if (typeof rawValue === 'string' && rawValue) {
          parsedValue = parseFloat(rawValue);
        }

        if (parsedValue !== null) {
          if (conversion) {
            return parsedValue * conversion;
          }
          return parsedValue;
        } else {
          return null;
        }
      },
      name,
      unit,
      min: dataRangeMin,
      max: dataRangeMax,
      column,
      decimals: getDecimals(sp.displayFormat) ?? 1,
      icon,
      agg,
    });
    const prop = getNumberProp();

    // For historical data we can choose what aggregation to select, each with it's own dataProp
    if (!isLatest) {
      prop.aggregations = {
        min: getNumberProp('min'),
        max: getNumberProp('max'),
        abs: getNumberProp('abs'),
        avg: getNumberProp('avg'),
      };
    }

    return prop;
  }

  // Default to string
  return {
    id,
    name,
    category: { displayName: category },
    type: 'string',
    _get: data => getRawValue(propIdentifier, data, { agg: 'abs' }),
    unit,
    column,
    icon,
  };
}

export function getDecimals(
  displayFormat: ServicePropertyDisplayFormat | undefined
): number | undefined {
  switch (displayFormat) {
    case ServicePropertyDisplayFormat.NumberSixDecimals:
      return 6;

    case ServicePropertyDisplayFormat.NumberFiveDecimals:
      return 5;

    case ServicePropertyDisplayFormat.NumberFourDecimals:
      return 4;

    case ServicePropertyDisplayFormat.NumberThreeDecimals:
      return 3;

    case ServicePropertyDisplayFormat.NumberTwoDecimals:
      return 2;

    case ServicePropertyDisplayFormat.NumberOneDecimal:
      return 1;

    case ServicePropertyDisplayFormat.NumberZeroDecimals:
      return 0;

    case ServicePropertyDisplayFormat.Raw:
    default:
      return undefined;
  }
}
