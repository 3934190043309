import { snackbar } from 'components/Snackbar';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { SortingRule } from 'react-table';
import { GetEndpoint } from 'shared/interfaces/api';
import { Dashboard } from 'store/domains/dashboard';
import { StoreBase } from 'store/storeBase';
import { aggregateArrayOfObjects } from 'utils/various';
import { DashboardFilterState } from 'views/Dashboard/ComponentTypes/FiltersComponent/Filters/DashboardFilterState';
import { IDataGroupBy, IDataProperty } from './dataPropTypes';
import {
  DataPropsGetter,
  IDataSourceResponse,
  IDataSourceSettings,
  IDataSourceStore,
} from './dataSourceTypes';
import { getDataProp } from './getDataProp';

interface IGenericDataSourceOptions<TParams, TData> {
  endpoint: GetEndpoint<TParams, TData[]>;
  getDataProps: () => IDataProperty<TData>[];
  getParams: (filterState: DashboardFilterState) => TParams;
  defaultSort?: SortingRule;
  defaultGroupBy?: IDataGroupBy;
}

export function makeGenericDataSource<
  TSettings extends IDataSourceSettings,
  TData,
  TParams,
>(
  opts: IGenericDataSourceOptions<TParams, TData>
): (dashboard: Dashboard, settings: TSettings) => IDataSourceStore<TSettings> {
  class GenericSimpleDataSource
    extends StoreBase
    implements IDataSourceStore<TSettings>
  {
    @observable.ref settings: TSettings;
    isInitialized = true;
    @observable lastReceivedData: Date | undefined;
    defaultSort = opts.defaultSort;
    dashboard: Dashboard;

    constructor(dashboard: Dashboard, settings: TSettings) {
      super(dashboard.rootStore);
      makeObservable(this);

      this.dashboard = dashboard;
      this.settings = settings;
    }

    @computed get dataProperties(): IDataProperty<TData>[] {
      return opts.getDataProps(); // Hacky solution to async translations
    }

    groupProperties = [];

    @computed get params(): TParams {
      return opts.getParams(this.dashboard.filterState);
    }

    async getData(): Promise<IDataSourceResponse> {
      const resp = await this.httpGet(opts.endpoint, this.params);

      if (resp.status === 204 || !resp.data?.length) {
        return {
          type: 'noContent',
        };
      } else if (resp.status !== 200) {
        const errorMessage =
          resp.statusText || resp.exceptionMessage || 'Error data';
        snackbar(errorMessage, { variant: 'error' });

        return {
          type: 'error',
          message: errorMessage,
        };
      }

      runInAction(() => {
        this.lastReceivedData = new Date();
      });
      const items = opts.defaultGroupBy
        ? aggregateArrayOfObjects(opts.defaultGroupBy, resp.data)
        : resp.data;
      return {
        type: 'success',
        data: {
          type: 'list',
          items,
          total: items.length,
        },
      };
    }

    getDataProps<T>(propGetter: DataPropsGetter<T>): T {
      return propGetter(prop => getDataProp(this, prop));
    }

    @computed get depString(): string {
      const { manualRefreshTrigger } = this.dashboard.rootStore.dashboardStore;
      const depObject = {
        manualRefreshTrigger,
        params: this.params,
      };

      return JSON.stringify(depObject);
    }
  }

  return (dashboard, settings) =>
    new GenericSimpleDataSource(dashboard, settings);
}
