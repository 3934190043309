import { createStyles, makeStyles } from '@mui/styles';
import { pxToRem } from 'styles/themes/makeAtlasTheme';

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      fontSize: pxToRem(14),
      width: '100%',
    },
    danger: {
      color: theme.palette.error.main,
    },
    warning: {
      color: theme.palette.warning.main,
    },
    success: {
      color: theme.palette.success.light,
    },
    disabled: {
      color: theme.palette.text.disabled,
    },
    uppercase: {
      textTransform: 'uppercase',
    },
    list: {
      paddingLeft: 15,
      marginTop: 0,
    },
    textOverflowEllipsis: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    scrollableList: {
      width: '100%',
      border: `1px solid ${theme.palette.divider}`,
      overflowY: 'scroll',
      overflowX: 'hidden',
    },
  })
);
