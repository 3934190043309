import { Aggregator, Column } from 'react-table';
import { CustomCell } from '../Helpers/CustomCell';
import { ICustomCellProps } from '../Helpers/CustomCellProps';

export function boolIcon<T>(options: {
  id: string;
  accessor: (row: T) => any;
  header?: string;
  defaultShow?: boolean;
  filterable?: boolean;
  cells: {
    true: ICustomCellProps;
    false: ICustomCellProps;
  };
  aggregate?: Aggregator;
}): Column<T> {
  const {
    id,
    header = 'Status',
    accessor,
    defaultShow: show = true,
    filterable,
    cells,
    aggregate = (values: any[]) => {
      if (values.find(value => value !== null) === undefined) {
        return null;
      }
      return values.find(Boolean) !== undefined; // Default to showing true if any child row contains a truthy value
    },
  } = options;

  const getCellProps = (value: any): ICustomCellProps => {
    if (value === 0 || value === false) {
      return cells.false;
    } else if (value === 1 || value === true) {
      return cells.true;
    } else if (!value) {
      return { text: '-' };
    }
    return {
      text: `${value}`,
    };
  };

  return {
    id,
    Header: header,
    accessor,
    resizable: false,
    maxWidth: 130,
    filterable,
    show,
    className: 'text-left',
    Cell: row => <CustomCell {...getCellProps(row.value)} index={row.index} />,
    sortMethod: (a, b) => (a ?? -1) - (b ?? -1), // Fix sorting for null values (last)
    aggregate,
    Aggregated: row => (
      <CustomCell {...getCellProps(row.value)} index={row.index} />
    ),
  };
}
