import { CancelToken } from 'axios';
import { DashboardComponentType } from 'generated';
import { Column, SortingRule } from 'react-table';
import { IGroupedData } from 'services/service.service';
import { AtlasIconType } from 'shared/types/atlas-icon';
import { Dashboard } from 'store/domains/dashboard';
import { StoreBase } from 'store/storeBase';
import { TableWidgetState } from '../ComponentTypes/TableComponent/TableWidgetState';
import {
  analyticsCubeDataSourceConfig,
  IAnalyticsCubeDataSourceSettings,
} from './AnalyticsCube/analyticsCubeConfig';
import {
  automationRuleActivityDataSourceConfig,
  IAutomationActivityDataSourceSettings,
} from './Automation/AutomationActivity/automationRuleActivityConfig';
import {
  automationRuleStatusConfig,
  IAutomationRuleStatusDataSourceSettings,
} from './Automation/AutomationStatus/AutomationRuleStatus.config';
import {
  ISimActivityDataSourceSettings,
  simActivityDataSourceConfig,
} from './Connectivity/SimActivity/simActivityConfig';
import {
  ISimActivityByCountryDataSourceSettings,
  simActivityByCountryDataSourceConfig,
} from './Connectivity/SimActivityByCountry/simActivityByCountryConfig';
import {
  ISimGroupActivityDataSourceSettings,
  simGroupActivityDataSourceConfig,
} from './Connectivity/SimGroupActivity/simGroupActivityConfig';
import {
  ISimStatusDataSourceSettings,
  simStatusDataSourceConfig,
} from './Connectivity/SimStatus/simStatusConfig';
import { DataPropSetting, IDataGroupBy, IDataProperty } from './dataPropTypes';
import {
  IIntelligenceEventsDataSourceSettings,
  intelligenceEventsDataSourceConfig,
} from './IntelligenceEvents/intelligenceEventsConfig';
import {
  IServiceDataSourceSettings,
  serviceDataSourceConfig,
} from './ServiceData/serviceDataConfig';
import {
  IServiceLatestDataSourceSettings,
  serviceLatestDataSourceConfig,
} from './ServiceData/serviceLatestConfig';

// Add future dataSourceTypes here
export type DataSourceType =
  | 'service'
  | 'serviceLatest'
  | 'analyticsCube'
  | 'simStatus'
  | 'intelligenceEvents'
  | 'simActivity'
  | 'simGroupActivity'
  | 'simActivityByCountry'
  | 'automationRuleActivity'
  | 'automationRuleStatus';

export interface IDataSourceSettingsMenuProps<TSettings> {
  settings: TSettings;
}

export interface IDataSourceConfig<TSettings extends { type: DataSourceType }> {
  icon: AtlasIconType;
  settingsSteps: {
    title: string;
    Component: React.FC<IDataSourceSettingsMenuProps<TSettings>>;
  }[];
  getDefaultSettings: (dashboard: Dashboard) => Promise<TSettings>;
  enabledComponentTypes: Partial<Record<DashboardComponentType, boolean>>;
  shouldShowDateFilter: boolean;
  fixSettings?: (settings: TSettings) => void;
}

// All available data sources have configs mapped here
export const dataSourceConfigs: Record<
  DataSourceType,
  IDataSourceConfig<any>
> = {
  service: serviceDataSourceConfig,
  serviceLatest: serviceLatestDataSourceConfig,
  analyticsCube: analyticsCubeDataSourceConfig,
  simStatus: simStatusDataSourceConfig,
  intelligenceEvents: intelligenceEventsDataSourceConfig,
  simActivity: simActivityDataSourceConfig,
  simActivityByCountry: simActivityByCountryDataSourceConfig,
  simGroupActivity: simGroupActivityDataSourceConfig,
  automationRuleActivity: automationRuleActivityDataSourceConfig,
  automationRuleStatus: automationRuleStatusConfig,
};

export const dataSourceTypes: DataSourceType[] = Object.keys(
  dataSourceConfigs
) as DataSourceType[];

// Add future dataSourceType settings here
export type IDataSourceSettings =
  | IServiceDataSourceSettings
  | IServiceLatestDataSourceSettings
  | IAnalyticsCubeDataSourceSettings
  | ISimStatusDataSourceSettings
  | IIntelligenceEventsDataSourceSettings
  | ISimActivityDataSourceSettings
  | ISimGroupActivityDataSourceSettings
  | ISimActivityByCountryDataSourceSettings
  | IAutomationActivityDataSourceSettings
  | IAutomationRuleStatusDataSourceSettings;

export type DataPropsGetter<T> = (
  getProp: (setting: DataPropSetting) => IDataProperty
) => T;

export interface IGroupedWidgetData<TDataPoint = any> {
  type: 'groupedData';
  groups: IGroupedData<TDataPoint>[];
}

export interface IAggregatedData {
  type: 'aggregatedData';
  result: Record<string, number>;
}

export interface IArrayWidgetData<TDataPoint = any> {
  type: 'list';
  items: TDataPoint[];
  total: number;
}

export interface IObjectWidgetData<TDataPoint = any> {
  type: 'object';
  item: TDataPoint;
}

export type IWidgetData =
  | IGroupedWidgetData
  | IArrayWidgetData
  | IObjectWidgetData
  | IAggregatedData;

export type IDataSourceResponse =
  | {
      type: 'noContent';
    }
  | {
      type: 'error';
      message?: string;
    }
  | {
      type: 'canceled';
    }
  | {
      type: 'success';
      data: IWidgetData;
    };

// Data source stores must implement this interface
export interface IDataSourceStore<
  TSettings extends IDataSourceSettings = IDataSourceSettings,
> extends StoreBase {
  settings: TSettings;

  isInitialized: boolean;
  initialize?: () => Promise<void>; // Loads dataproperties etc, important to do BEFORE using a data source to get a component's default settings (they get default dataProps)
  destroy?: () => void; // Cancel all timers etc

  dataProperties: IDataProperty[];
  groupProperties: IDataProperty[];

  getDataProps: <T>(
    f: DataPropsGetter<T>,
    componentType?: DashboardComponentType
  ) => T; // Get all required dataProps at once (so the dataSourceStore can know which ones are actually used, for example)

  getUsedAggregates?: (
    propsSetting: DataPropSetting[],
    componentType?: DashboardComponentType
  ) => void;

  getData: (opts?: {
    lowResolution?: boolean;
    cancelToken?: CancelToken;
    componentType?: DashboardComponentType;
  }) => Promise<IDataSourceResponse>;
  lastReceivedData: Date | undefined;

  depString: string; // All filters used by the data source, stringified. Use to trigger data-refreshes by effects.

  tableState?: TableWidgetState;
  defaultSort?: SortingRule;
  defaultGroupBy?: IDataGroupBy;
  extraColumns?: Column[];
}
