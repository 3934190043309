import i18n from 'i18next';
import { Column } from 'react-table';
import { SMSStatus } from 'shared/types/sms-status';
import { stylingNames } from '../Columns.styles';
import { CustomCell } from '../Helpers/CustomCell';
import { ICustomCellProps } from '../Helpers/CustomCellProps';
import { getOptionFilter } from '../Helpers/getOptionFilter';

export function smsStatus<T>(options: {
  id: string;
  accessor: (row: T) => SMSStatus;
  header?: string;
  show?: boolean;
}): Column<T> {
  const { id, header = 'Status', accessor, show } = options;
  const smsStatusCellProps: Record<SMSStatus, ICustomCellProps> = {
    success: {
      iconType: 'Status',
      variant: 'success',
      title: '',
    },
    failed: {
      iconType: 'Status',
      variant: 'error',
      title: i18n.t('sms:title_status.failed'),
    },
    pending: {
      iconType: 'Alert',
      variant: 'default',
      title: i18n.t('sms:title_status.pending'),
    },
  };
  return {
    id,
    Header: header,
    accessor,
    className: stylingNames.textCenter,
    resizable: false,
    maxWidth: 80,
    show,
    Filter: getOptionFilter([
      { label: i18n.t('sms:label.all'), value: '' },
      { label: i18n.t('sms:label.success'), value: 'success' },
      { label: i18n.t('sms:label.failed'), value: 'failed' },
      { label: i18n.t('sms:label.pending'), value: 'pending' },
    ]),
    Cell: row => {
      const cellProps = smsStatusCellProps[row.value as SMSStatus] || {};
      return <CustomCell {...cellProps} />;
    },
  };
}
