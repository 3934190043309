import { ConnectivityOrderStatus } from 'generated';
import i18n from 'i18next';
import { Column } from 'react-table';
import { CustomCell } from '../Helpers/CustomCell';
import { ICustomCellProps } from '../Helpers/CustomCellProps';
import { getOptionFilter } from '../Helpers/getOptionFilter';

export function orderStatus<T>(options: {
  id: string;
  accessor: (row: T) => ConnectivityOrderStatus;
  header?: string;
  defaultShow?: boolean;
  filterable?: boolean;
}): Column<T> {
  const {
    id,
    header = 'Status',
    accessor,
    defaultShow: show,
    filterable,
  } = options;

  const cellByStatus: Record<ConnectivityOrderStatus, ICustomCellProps> = {
    [ConnectivityOrderStatus.Unknown]: {
      iconType: 'StatusUnknown',
      variant: 'unconfigured',
      text: i18n.t(`order:order_status.${ConnectivityOrderStatus.Unknown}`),
    },
    [ConnectivityOrderStatus.New]: {
      iconType: 'StatusPending',
      variant: 'warning',
      text: i18n.t(`order:order_status.${ConnectivityOrderStatus.New}`),
    },
    [ConnectivityOrderStatus.InProgress]: {
      iconType: 'StatusPending',
      variant: 'warning',
      text: i18n.t(`order:order_status.${ConnectivityOrderStatus.InProgress}`),
    },
    [ConnectivityOrderStatus.Completed]: {
      iconType: 'StatusOk',
      variant: 'success',
      text: i18n.t(`order:order_status.${ConnectivityOrderStatus.Completed}`),
    },
    [ConnectivityOrderStatus.Canceled]: {
      iconType: 'StatusError',
      variant: 'error',
      text: i18n.t(`order:order_status.${ConnectivityOrderStatus.Canceled}`),
    },
    [ConnectivityOrderStatus.Failed]: {
      iconType: 'StatusError',
      variant: 'error',
      text: i18n.t(`order:order_status.${ConnectivityOrderStatus.Failed}`),
    },
  };

  return {
    id,
    Header: header,
    accessor,
    resizable: false,
    maxWidth: 140,
    show,
    filterable,
    Cell: row => {
      const iconProps =
        cellByStatus[row.value as ConnectivityOrderStatus] || {};
      return <CustomCell {...iconProps} index={row.index} />;
    },
    Filter: getOptionFilter([
      {
        label: i18n.t('all'),
        value: '',
      },
      {
        label: i18n.t(`order:order_status.${ConnectivityOrderStatus.Unknown}`),
        value: ConnectivityOrderStatus.Unknown,
      },
      {
        label: i18n.t(`order:order_status.${ConnectivityOrderStatus.New}`),
        value: ConnectivityOrderStatus.New,
      },
      {
        label: i18n.t(
          `order:order_status.${ConnectivityOrderStatus.InProgress}`
        ),
        value: ConnectivityOrderStatus.InProgress,
      },
      {
        label: i18n.t(
          `order:order_status.${ConnectivityOrderStatus.Completed}`
        ),
        value: ConnectivityOrderStatus.Completed,
      },
      {
        label: i18n.t(`order:order_status.${ConnectivityOrderStatus.Canceled}`),
        value: ConnectivityOrderStatus.Canceled,
      },
      {
        label: i18n.t(`order:order_status.${ConnectivityOrderStatus.Failed}`),
        value: ConnectivityOrderStatus.Failed,
      },
    ]),
  };
}
