import { TableData } from 'components/Table';
import { subDays } from 'date-fns';
import {
  IntActiveSessionTableDto,
  IntCdrSessionHistoryTableDto,
  IntConnectivityUnitAccessDetailsDto,
  IntHistoricalSessionTableDto,
  IntSearchDto,
} from 'generated';
import { IResponse } from 'shared/interfaces/api';
import { paginate, rnd, rndInt, tableDemoData } from 'utils';
import { apnHubDemoData } from './apnHubDemoData';
import { connectivityUnitDemoData } from './connectivityUnitDemoData';

class CdrSessionDemoData {
  private _sessionsTable: IntActiveSessionTableDto[] | undefined;
  private demoDataTraffic = false;
  get getHistoricalSessions() {
    const unit = connectivityUnitDemoData.units[0];
    return tableDemoData<IntCdrSessionHistoryTableDto>(i => {
      return {
        connectivityUnitLabel: rnd.label(),
        connectivityUnitId: unit.connectivityUnitId,
        connectivityUnitGroupId: rnd.resourceGroup(i + 1).resourceGroupId,
        connectivityUnitIdentifier: `Connectivity identifier ${rndInt(1, 50)}`,
        connectivityUnitGroupName: rnd.resourceGroup(i + 1).resourceGroupName,
        imsi: rnd.imsi(),
        startDate: subDays(new Date(), 2),
        endDate: subDays(new Date(), 1),
        sessionTime: rnd.int(9999, 99999),
        downloadedData: rnd.kb(),
        uploadedData: rnd.b(),
        totalData: rnd.int(1000, 999999),
        visitedMccMncs: rnd.mccmnc(),
      };
    });
  }
  get activeSessions() {
    this._sessionsTable = rnd.array<IntActiveSessionTableDto>(
      rnd.int(10, connectivityUnitDemoData.units.length),
      i => {
        const unit = connectivityUnitDemoData.units[i];
        return {
          connectivityUnitId: unit.connectivityUnitId,
          identifier: unit.identifier,
          label: unit.label,
          session: {
            general: {
              started: subDays(new Date(), 2),
              lastUpdated: subDays(new Date(), 1),
              ipAddress: rnd.ip(),
              imsi: rnd.imsi(),
              msisdn: rnd.msisdn(),
              apn: rnd.domainName(),
              primaryDns: rnd.ip(),
              secondaryDns: rnd.ip(),
              iPv6DNSNameServers: rnd.ip(),
              ratType: rnd.ratType(),
              blocked: rnd.bool(),
              terminating: rnd.bool(),
              homeMCCMNC: rnd.mccmnc(),
              visitedMCCMNC: rnd.mccmnc(),
            },
            usage: {
              timestamp: subDays(new Date(), 1),
              totalBytes: rnd.int(1000, 999999),
              inputBytes: rnd.kb(),
              outputBytes: rnd.b(),
              inputPackets: rnd.int(1, 99999),
              outputPackets: rnd.int(1, 99999),
              sessionRuntime: rnd.int(9999, 99999),
            },
            radius: {
              acctSessionId: rnd.charSequence(16).toUpperCase(),
              sessionTimeout: 0,
              idleTimeout: 3600,
              nasIpAddress: rnd.ip(),
              nasIdentifier: rnd.charSequence(9).toUpperCase(),
            },
            uuid: {
              apnHubSessionUUID: rnd.guid(),
              apnHubDeviceUUID: rnd.guid(),
            },
            mnogw: {
              mnoGatewayUuid: rnd.guid(),
              mnoFilterId: rnd.guid(),
            },
            enterpriseGW: {
              enterpriseGatewayUuid: rnd.guid(),
              enterpriseFilterId: rnd.guid(),
            },
          },
        };
      }
    );

    return this._sessionsTable;
  }

  filterSession = (
    session: IntActiveSessionTableDto | IntHistoricalSessionTableDto,
    search: string | null
  ) => {
    if (search) {
      return session.label.includes(search.toLowerCase());
    }
    return true;
  };

  getActiveSessions = (
    params: IntSearchDto
  ): IResponse<TableData<IntActiveSessionTableDto>> => {
    const filteredSessions = this.activeSessions.filter(session =>
      this.filterSession(session, params.search)
    );

    return {
      status: 200,
      data: paginate(filteredSessions, params),
    };
  };
  getActiveSession = (
    connectivityUnitId: string
  ): IResponse<IntConnectivityUnitAccessDetailsDto> => {
    const activeSession = this.activeSessions?.find(
      session => session.connectivityUnitId === connectivityUnitId
    );

    if (activeSession) {
      const dataProfile = apnHubDemoData.demoApnProfiles[0];

      return {
        status: 200,
        data: {
          apnHubDeviceUuid: activeSession.session.uuid.apnHubDeviceUUID,
          staticIP: activeSession.session.general.ipAddress,
          lastSessionStarted: rnd.date.past(),
          firstSessionStarted: activeSession.session.general.started,
          activeSession: activeSession.session,
          dataProfile: {
            profileId: dataProfile.profileId,
            name: dataProfile.name,
            global: dataProfile.global,
            default: dataProfile.default,
            profileDescriptor: dataProfile.profileDescriptor,
          },
          blockDataTraffic: this.demoDataTraffic,
        },
      };
    } else {
      return { status: 204 };
    }
  };
}

export const cdrSessionDemoData = new CdrSessionDemoData();
