import { Grid, Switch as MUISwitch } from '@mui/material';
import { useField } from 'formik';
import React from 'react';

interface IProps {
  name: string;
  value?: string;
  label?: string;
  color?: 'primary' | 'secondary' | 'default';
  className?: string;
  disabled?: boolean;
  disableRipple?: boolean;
}

const FormikSwitch: React.FC<IProps> = ({
  name,
  value,
  label,
  color = 'primary',
  className,
  disabled,
  disableRipple = false,
}) => {
  const [field] = useField({ name, type: 'checkbox', value });

  return (
    <Grid component="label" container alignItems="center" spacing={1}>
      <Grid item>{label}</Grid>
      <Grid item>
        <MUISwitch
          color={color}
          disabled={disabled}
          className={className}
          disableRipple={disableRipple}
          data-cy={name}
          {...field}
        />
      </Grid>
    </Grid>
  );
};

export default FormikSwitch;
