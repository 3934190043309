import {
  IntCreateNavigationDto,
  IntEditNavigationDto,
  IntNavigationDto,
  IntSearchDto,
} from 'generated';
import {
  DeleteEndpoint,
  GetEndpoint,
  PostEndpoint,
  PutEndpoint,
} from 'shared/interfaces/api';
import { navigationDemoData } from './demoData/navigationDemoData';

interface INavigationAPI {
  getActiveNavigation: GetEndpoint<undefined, IntNavigationDto>;
  updateNavigation: PutEndpoint<
    { navigationId: string },
    IntEditNavigationDto,
    IntNavigationDto
  >;
  getNavigationsForCustomer: GetEndpoint<
    { customerId: string; searchDto: IntSearchDto },
    IntNavigationDto[]
  >;
  createNavigation: PostEndpoint<
    undefined,
    IntCreateNavigationDto,
    IntNavigationDto
  >;
  deleteNavigation: DeleteEndpoint<string>;
}

export const navigationAPI: INavigationAPI = {
  getActiveNavigation: {
    url: '/api/navigation/active',
    mockData: navigationDemoData.getActiveNavigation,
  },
  updateNavigation: {
    url: params => `/api/navigation/${params.navigationId}`,
    mockData: navigationDemoData.updateNavigation,
  },
  getNavigationsForCustomer: {
    url: '/api/navigation',
    mockData: navigationDemoData.getNavigationsForCustomer,
  },
  createNavigation: {
    url: '/api/navigation',
    mockData: navigationDemoData.createNavigation,
  },
  deleteNavigation: {
    url: navigationId => `/api/navigation/${navigationId}`,
    mockData: () => ({
      status: 200,
    }),
  },
};
