import { snackbar } from 'components/Snackbar';
import {
  IntConfigurationType,
  IntConnectivityConfigurationDto,
} from 'generated';
import i18n from 'i18n';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { connectivityConfigurationAPI } from 'services/connectivityConfiguration.service';
import { RootStore } from './rootStore';
import { StoreBase } from './storeBase';

export class ConnectivityConfigStore extends StoreBase {
  @observable configs: IntConnectivityConfigurationDto[] | undefined;
  @observable config: IntConnectivityConfigurationDto | undefined;
  @observable loading = false;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this);
  }

  @action.bound setLoading(loading: boolean) {
    this.loading = loading;
  }

  @action.bound setConnectivityConfig(
    config: IntConnectivityConfigurationDto | undefined
  ) {
    this.config = config;
  }

  @action.bound async getConnectivityConfigs(customerId: string) {
    const resp = await this.httpGet(
      connectivityConfigurationAPI.getConnectivityConfigs,
      customerId,
      loading => {
        this.setLoading(loading);
      }
    );

    if ([200, 204].includes(resp.status)) {
      runInAction(() => {
        this.configs = resp.data;
      });
    } else {
      snackbar(i18n.t('profile:message.config_update.error.get'), {
        variant: 'error',
      });
    }
  }

  @action.bound async updateConnectivityConfigs(
    customerId: string,
    data: IntConnectivityConfigurationDto[],
    intConfigurationType: IntConfigurationType
  ) {
    const resp = await this.httpPut(
      connectivityConfigurationAPI.updateConnectivityConfigs,
      {
        params: {
          customerId,
          intConfigurationType,
        },
        data,
      },
      loading => {
        this.setLoading(loading);
      }
    );

    if (resp.status === 200) {
      snackbar(i18n.t('profile:validation.config_update'), {
        variant: 'success',
      });
      runInAction(() => {
        this.configs = [
          ...(this.configs?.filter(
            item => item.type !== intConfigurationType
          ) || []),
          ...(resp.data || []),
        ];
      });
    } else {
      snackbar(i18n.t('profile:message.config_update.error.update'), {
        variant: 'error',
      });
    }
  }
}
