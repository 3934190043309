import { Box, Button, List } from '@mui/material';
import BigListItem from 'components/BigListItem';
import LoadingOverlay from 'components/LoadingOverlay';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AssetFilterSettingsState } from './AssetFilterSettingsState';

interface IProps {
  state: AssetFilterSettingsState;
}

const SelectedAssetsList: React.FC<IProps> = ({ state }) => {
  const { t } = useTranslation('dashboard');
  const { allFilteredAssets, filterSpecDtos } = state;

  useEffect(() => {
    state.loadAllFilteredAssets(filterSpecDtos);

    return state.clearAllFilteredAssets;
  }, [state, filterSpecDtos]);

  return (
    <Box gridArea="2 / 2 / 4 / 3" overflow="auto">
      <LoadingOverlay
        isLoading={state.isLoadingAllFilteredAssets}
        style={{ height: '100%' }}
      >
        <List>
          {state.allFilteredAssets?.assets.map(asset => (
            <BigListItem key={asset.id} label={asset.name} />
          ))}
        </List>

        {allFilteredAssets?.hasMore && (
          <Button
            onClick={() =>
              state.loadAllFilteredAssets(
                filterSpecDtos,
                (allFilteredAssets?.page ?? 0) + 1
              )
            }
          >
            {t('common:action.show_more')}
          </Button>
        )}
      </LoadingOverlay>
    </Box>
  );
};

export default observer(SelectedAssetsList);
