export const isLocalDevelopment = process.env.NODE_ENV === 'development';
export const isPreview = process.env.REACT_APP_ENV === 'preview';
export const getEnvSuffix = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'prod':
      return '';
    case 'test':
      return '-test';
    case 'dev':
    default:
      return '-dev';
  }
};

// This means dev as in localhost dev....
export const isDevelopment = process.env.NODE_ENV === 'development';

export const isStagingTest =
  window.location.hostname.startsWith('atlas-staging');

// Whereas this means is localhost dev or the actual environment dev.teliaiot.com. Include the preview slot for simplicity.
export const isDevOrLocal =
  isDevelopment ||
  getEnvironmentVariable('REACT_APP_ENV') === 'dev' ||
  isPreview;
/**
 * This byte threshold SHOULD NOT be the binary threshold 1024!
 * The conversion threshold of 1000 is used because this is how data usage
 * is calculated and presented in the billing process.
 */
export const byteThreshold = 1000;

export function getEnvironmentVariable(name: string): string {
  const value = process.env[`${name}`] || process.env[`REACT_APP_${name}`];
  if (!value) {
    throw new Error(`Environment variable ${name} is not defined.`);
  }
  return value;
}
