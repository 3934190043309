import { TableData } from 'components/Table';
import { subSeconds } from 'date-fns';
import {
  ConfigurationType,
  IntConfigurationDto,
  IntExtensionLogEntry,
  IntExtensionTableDto,
  IntExtensionTableRequestDto,
  IntSearchDto,
} from 'generated';
import { IResponse } from 'shared/interfaces/api';
import { paginate, rnd, tableDemoData } from 'utils';

class ExtensionDemoData {
  getExtensionsTable(params?: IntExtensionTableRequestDto) {
    const number = rnd.int(1, 999);
    const resData = rnd.array<IntExtensionTableDto>(70, _ => ({
      id: rnd.guid(),
      name: `Extension #${number}`,
      displayName: `#${number}`,
      extensionType: rnd.item('Type 1', 'Type 2', 'Type 3', 'Type 4'),
      active: rnd.bool(),
      createdDate: rnd.date.past({ years: 1 }),
      customer: rnd.customerName(),
    }));
    return {
      status: 200,
      data: paginate(resData, params),
    };
  }

  getExtensionDetails = () => {
    const number = rnd.int(1, 999);

    return {
      status: 200,
      data: {
        id: rnd.guid(),
        name: `Extension #${number}`,
        displayName: `#${number}`,
        extensionType: rnd.item('Type 1', 'Type 2', 'Type 3', 'Type 4'),
        active: rnd.bool(),
        createdDate: rnd.date.past({ years: 1 }),
        namespaces: rnd.array(rnd.int(1, 3), i => ({
          id: `${rnd.guid()}`,
          name: `Namespace ${rnd.int(i, 99)}`,
        })),
      },
    };
  };

  create = () => ({ status: 201, data: rnd.guid() });

  delete = () => ({ status: 200 });

  getConfigs = (
    params: IntSearchDto
  ): IResponse<TableData<IntConfigurationDto>> => {
    return {
      status: 200,
      data: paginate(configs, params),
    };
  };

  deleteConfig = () => ({ status: 200 });

  createConfig = () => ({
    status: 200,
    data: generateConfigMapOrSecret(),
  });

  updateConfig = () => ({
    status: 200,
    data: generateConfigMapOrSecret(),
  });

  getConfig = () => ({ status: 200, data: generateConfigMapOrSecret() });

  get getLogs() {
    const startDate = new Date();
    let seconds = 0;

    return tableDemoData<IntExtensionLogEntry>(i => {
      seconds += rnd.int(60, 60 * 60 * 24);

      return {
        logEntry: `Eventtype ${rnd.int(0, 100)}`,
        logEntrySource: `Source ${rnd.int(0, 100)}`,
        timeGenerated: subSeconds(startDate, seconds),
      };
    });
  }
}

const generateConfigMapOrSecret = (index?: number): IntConfigurationDto => {
  const keys: { [key: string]: string } = {
    secret1: 'secret value 1',
    secret2: 'secret value 2',
  };

  return {
    id: rnd.guid(),
    type: rnd.enum(ConfigurationType),
    name: `config-${index ?? rnd.int(10, 999)}`,
    data: keys,
  };
};

export const configs: IntConfigurationDto[] = rnd.array(75, i =>
  generateConfigMapOrSecret(i)
);

export const secrets: IntConfigurationDto[] = rnd.array(75, i =>
  generateConfigMapOrSecret(i)
);

export const extensionDemoData = new ExtensionDemoData();
