import { Box, Typography } from '@mui/material';
import classNames from 'classnames';
import { TFunction } from 'i18next';
import { ComponentType } from 'react';
import { renderToString } from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { dataProp } from 'views/Dashboard/DataSources';
import { IDataProperty } from 'views/Dashboard/DataSources/dataPropTypes';
import { IPopupDataProps, IProps } from '.';
import { IMapPopupSettings } from '../../AzureMapsComponent/azureMapsConfig';
import {
  formatValue,
  PopupGetter,
} from '../../AzureMapsComponent/getPopupContent';
import { IMapFeatureProperties } from '../../ImageComponent/ImagePinLayer';
import { usePopupStyles } from './PopupContent.styles';

export function withPopupContent<T>(Component: ComponentType<T>): React.FC<T> {
  return props => <Component {...props} />;
}

export function getPopupContent(args: IPopupDataProps): string {
  const componentName = args.type === 'header' ? PopupHeader : PopupDetail;
  const PopupComponent = withPopupContent(componentName);
  return renderToString(<PopupComponent {...args} />);
}

export function makePopupGetter(
  settings: IMapPopupSettings,
  getProp: (id: string) => IDataProperty
): PopupGetter {
  const dataProps = settings?.rows.map(r => getProp(r.prop));

  return (featureProperties: IMapFeatureProperties) => {
    return renderToString(
      <PopupContent
        featureProperties={featureProperties}
        dataProps={dataProps}
      />
    );
  };
}

const valueFormatter = (
  valueRaw: any,
  unit: string | undefined,
  t: TFunction,
  decimals = 1
) => {
  const unitFormatted =
    unit && valueRaw !== null && valueRaw !== undefined ? unit : '';
  const formattedValue = Array.isArray(valueRaw)
    ? valueRaw.map(v => formatValue(v, decimals, false)).join(', ')
    : formatValue(valueRaw, decimals, false);
  return (
    <>
      {formattedValue}
      {unitFormatted}
    </>
  );
};

export const PopupHeader: React.FC<IPopupDataProps> = props => {
  const classes = usePopupStyles();
  const { t } = useTranslation('dashboard');
  const {
    title,
    description,
    yValue,
    unit,
    decimals,
    isParentClassRequired = false,
  } = props;

  return (
    <Box
      className={classNames({
        [classes.root]: isParentClassRequired,
      })}
    >
      <Box className={classes.popupHeader}>
        <Typography component="span" className={classes.popupHeaderTitle}>
          {valueFormatter(yValue, unit, t, decimals)}
        </Typography>
        <Box className={classes.popupHeaderDesc}>
          <Typography component="div" className={classes.textOverflowEllipsis}>
            {title}
          </Typography>
          <Typography component="span">{description}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export const PopupDetail: React.FC<IPopupDataProps> = props => {
  const classes = usePopupStyles();
  const { t } = useTranslation('dashboard');
  const {
    title,
    description,
    yValue,
    unit,
    decimals,
    isParentClassRequired = false,
  } = props;
  const valueFormatted = valueFormatter(yValue, unit, t, decimals);

  return (
    <Box
      className={classNames({
        [classes.popupDetail]: isParentClassRequired,
        [classes.popupProperty]: isParentClassRequired,
      })}
    >
      <Box mr={1}>
        <Typography component="span" className={classes.popupPropertyLabel}>
          {title}
        </Typography>
        <Typography component="span">{valueFormatted}</Typography>
        {description && (
          <Typography component="span" ml={1}>
            ({description})
          </Typography>
        )}
      </Box>
    </Box>
  );
};

const PopupContent: React.FC<IProps> = ({ featureProperties, dataProps }) => {
  const classes = usePopupStyles();
  const { displayName, data } = featureProperties;

  const getPopupPropertyData = (rowProp: IDataProperty, dataPoint: any) => {
    const { name, unit = '', decimals } = rowProp;
    const valueRaw = dataProp.get(rowProp, dataPoint);

    if (data?.headerTitle === name) {
      return '';
    }

    return (
      <Box key={dataPoint.id}>
        <PopupDetail
          title={name}
          description={''}
          yValue={valueRaw}
          unit={unit}
          decimals={decimals}
        />
      </Box>
    );
  };

  return (
    <Box className={classes.root}>
      <PopupHeader
        title={data?.headerTitle}
        description={data?.date}
        yValue={data?.headerValue}
        unit={data?.unit}
        decimals={dataProps?.length > 0 ? dataProps[0].decimals : undefined}
      />
      <Box mt={0.5} p={1.5}>
        <Typography component="span" className={classes.textOverflowEllipsis}>
          {displayName}
        </Typography>
        <Box className={classes.popupProperty}>
          {dataProps?.map(dp => getPopupPropertyData(dp, data))}
        </Box>
      </Box>
    </Box>
  );
};

export default PopupContent;
