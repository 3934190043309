import { isValidAtlasIconType } from 'components/AtlasIcon/iconUtils';
import { Role } from 'components/Auth/Role';
import { IntDashboardDto, IntMenuItemDto } from 'generated';
import i18n from 'i18n';
import { action, computed, makeObservable, observable, toJS } from 'mobx';
import routes from 'Routes';
import { IRouteItem } from 'shared/interfaces/route-item';
import { Dashboard } from 'store/domains/dashboard';
import { rnd } from 'utils';
import { IMenuItem } from './NavigationEditor';

export type MenuItemType = 'dashboard' | 'routes';

function stringifyRoles(roles: Role | Role[]): string {
  return typeof roles === 'object'
    ? Object.values(roles).join()
    : roles.toString();
}

export function menuItemDtoToForm(x: IntMenuItemDto): IMenuItem {
  return {
    id: x.menuItemId,
    isSelected: false,
    route: x.route,
    roles: x.roles,
    children: x.children.map(menuItemDtoToForm),
    title: x.displayName,
    icon: isValidAtlasIconType(x.icon) ? x.icon : undefined,
    productTemplateMenuItemId: x.productTemplateMenuItemId,
    dashboardId: x.dashboardId,
    isExpanded: false,
  };
}

export function menuItemFormToDto(params: {
  item: IMenuItem;
  index: number;
  navigationId?: string;
  productTemplateId?: string;
  parentId?: string;
}): IntMenuItemDto {
  const { item, index, parentId, navigationId, productTemplateId } = params;

  return {
    menuItemId: item.id,
    roles: item.roles,
    displayName: item.title,
    externalRoute: false,
    icon: item.icon || '',
    navigationId,
    productTemplateMenuItemId: item.productTemplateMenuItemId,
    order: index,
    route: item.route,
    parentId,
    dashboardId: item.dashboardId,
    children: item.children.map((child, i) =>
      menuItemFormToDto({
        item: child,
        index: i,
        navigationId,
        productTemplateId,
        parentId: item.id,
      })
    ),
  };
}

export function routesToItems(routeItems: IRouteItem[]): IMenuItem[] {
  const routeToMenuItem = (route: IRouteItem): IMenuItem => ({
    id: rnd.guid(),
    isSelected: false,
    route: route.component ? route.path : '',
    roles: route.roles ? stringifyRoles(route.roles) : '',
    title: i18n.t(`route:${route.text}`),
    isExpanded: false,
    children: routesToItems(route.subLinks || []),
    expanded: false,
    icon: route.icon,
  });

  return routeItems.filter(r => r.showInMenu).map(routeToMenuItem);
}

export function dashboardsToItems(
  dashboards: (IntDashboardDto | Dashboard)[]
): IMenuItem[] {
  if (dashboards.length === 0) {
    return [];
  }

  const dashboardItems: IMenuItem[] = dashboards.map(d => ({
    id: d.dashboardId,
    children: [],
    route: `/dashboard/${d.dashboardId}`,
    roles: Role.RoleNameViewDashboard,
    isExpanded: false,
    isSelected: false,
    title: d.displayName,
    icon: 'Graph',
    dashboardId: d.dashboardId,
  }));

  return [
    {
      id: 'dashboards',
      children: dashboardItems,
      route: '',
      roles: '',
      isExpanded: false,
      isSelected: false,
      title: 'Dashboards',
      icon: 'PieChart',
    },
  ];
}

export class AddMenuItemsState {
  @observable menuItems: IMenuItem[];

  constructor(dashboards: IntDashboardDto[], items: MenuItemType = 'routes') {
    this.menuItems =
      items === 'routes'
        ? [...routesToItems(routes)]
        : [...dashboardsToItems(dashboards)];

    makeObservable(this);
  }

  @action toggleSelect = (i: IMenuItem) => {
    const enable = !i.isSelected;

    this.forItemAndChildren(i, item => (item.isSelected = enable));
  };

  forItemAndChildren = (i: IMenuItem, drill: (child: IMenuItem) => void) => {
    drill(i);
    i.children.forEach(child => this.forItemAndChildren(child, drill));
  };

  @action.bound reset() {
    this.menuItems.forEach(item =>
      this.forItemAndChildren(item, x => (x.isSelected = false))
    );
  }

  @computed get selectedItems() {
    const result: IMenuItem[] = [];

    const addItemToArrayIfChecked = (
      arrToAddTo: IMenuItem[],
      item: IMenuItem
    ) => {
      if (!item.isSelected) {
        // For unchecked parents with checked children, add the kids to the closest ancestor array
        item.children.forEach(child =>
          addItemToArrayIfChecked(arrToAddTo, child)
        );
      } else {
        const children: IMenuItem[] = [];

        item.children.forEach(child =>
          addItemToArrayIfChecked(children, child)
        );

        arrToAddTo.push({
          ...toJS(item),
          id: rnd.guid(), // Generates IDs a bit often, but it's fine
          children,
        });
      }
    };

    this.menuItems.forEach(menuItem =>
      addItemToArrayIfChecked(result, menuItem)
    );

    return result;
  }
}
