import { EFeature } from 'components/Auth/EFeature';
import { Role } from 'components/Auth/Role';
import CreateDashboardModal from 'components/Dashboard/CreateDashboardModal';
import OptionalTooltip from 'components/OptionalTooltip';
import { IntMenuItemDto } from 'generated';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IRouteItem } from 'shared/interfaces/route-item';
import { useStores } from 'store';
import MenuItemContainer from './MenuItem/MenuItemContainer';
import MenuItemIcon from './MenuItem/MenuItemIcon';
import MenuItemText from './MenuItem/MenuItemText';

interface IProps {
  routeItem: IRouteItem | IntMenuItemDto;
}

const AddDashboardButton: React.FC<IProps> = ({ routeItem }) => {
  const { authStore, layoutStore } = useStores();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const { t } = useTranslation('dashboard');

  // Will probably require a solution-specific check?
  if (
    !authStore.hasFeature(EFeature.EnableDashboards) ||
    !authStore.hasRole(Role.RoleNameEditDashboard)
  ) {
    return null;
  }

  return (
    <>
      <OptionalTooltip
        text={t('action.dashboard.add')}
        enabled={!layoutStore.sidenavOpen}
      >
        <MenuItemContainer
          color={showCreateModal ? 'active' : 'default'}
          onClick={() => setShowCreateModal(true)}
          dataCy="addDashboardButton"
        >
          <MenuItemIcon iconType="Add" />
          {layoutStore.sidenavOpen && (
            <MenuItemText>{t('action.dashboard.add')}</MenuItemText>
          )}
        </MenuItemContainer>
      </OptionalTooltip>

      <CreateDashboardModal
        show={showCreateModal}
        closeModal={() => setShowCreateModal(false)}
        parentRouteItem={routeItem}
      />
    </>
  );
};

export default observer(AddDashboardButton);
