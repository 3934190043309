import { Box, Typography } from '@mui/material';
import React from 'react';
import { useStyles } from './MessageOverlay.styles';

// Could be nice to have: icon, color, variant, description, animations
interface IProps {
  message: string;
}

const MessageOverlay: React.FC<IProps> = ({ message }) => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      alignItems="center"
      textAlign="center"
      justifyContent="center"
      width="100%"
      height="100%"
      position="absolute"
      top={0}
      left={0}
      fontStyle="italic"
      style={{ pointerEvents: 'none' }}
      className="message-overlay"
    >
      <Typography
        variant="h6"
        color="textSecondary"
        className={classes.message}
      >
        {message}
      </Typography>
    </Box>
  );
};

export default MessageOverlay;
