import { AutocompleteInputChangeReason } from '@mui/material/Autocomplete';
import { snackbar } from 'components/Snackbar';
import {
  IntServiceDataFilterOptionDto,
  IntServiceDataFilterOptionResponseDto,
  IntServiceDataSpecificationFilterDto,
  ServiceDataFilterType,
} from 'generated';
import i18n from 'i18next';
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
  toJS,
} from 'mobx';
import { serviceApi } from 'services/service.service';
import { IResponse } from 'shared/interfaces/api';
import { rootStore } from 'store/RootStoreContext';
import { StoreBase } from 'store/storeBase';
import { IDashboardFilterSettings } from '../filtersConfig';
import { DashboardFilterState } from './DashboardFilterState';
import { isFilterTypeEnabled } from './isFilterTypeEnabled';

const pageSize = 100;

export class FilterControlState extends StoreBase {
  filterState: DashboardFilterState;
  settings: IDashboardFilterSettings;
  key: string; // The key used for saving selected values
  index: number;

  constructor(
    filterState: DashboardFilterState,
    settings: IDashboardFilterSettings,
    index: number
  ) {
    super(rootStore);

    this.filterState = filterState;
    this.settings = settings;
    this.index = index;

    this.key = `${settings.entity}_${settings.type}`;
    if (settings.entity === 'asset' && settings.definitionId) {
      this.key += `_${settings.definitionId}`;
    }

    makeObservable(this);
  }

  /*
  Since options are paginated and asynchronous, we save the selected ones. (rather than just their IDs)
  And since MUI doesn't like having selected options missing from the array, we need to insert the selected ones in case they're missing from the loaded options.
  */
  @observable.shallow _options: IntServiceDataFilterOptionDto[] = [];

  @computed get options(): IntServiceDataFilterOptionDto[] {
    const options: IntServiceDataFilterOptionDto[] = [...this._options];

    // For asset groups, only check the first saved option (it contains the others under children[])
    if (
      this.settings.entity === 'asset' &&
      this.settings.type === ServiceDataFilterType.ResourceGroupId
    ) {
      if (
        this.selectedOptions.length &&
        !options.find(opt => opt.id === this.selectedOptions[0].id)
      ) {
        // It's not loaded, insert it so it immediately shows up in the dropdown
        options.splice(0, 0, this.selectedOptions[0]);
      }
    } else {
      this.selectedOptions.forEach(selectedOption => {
        if (!options.find(opt => opt.id === selectedOption.id)) {
          // It's not loaded, insert it so it immediately shows up in the dropdown
          options.splice(0, 0, selectedOption);
        }
      });
    }

    return options;
  }

  @observable hasMore = false;
  page = 0;

  @computed get selectedOptions(): IntServiceDataFilterOptionDto[] {
    const selectedOptions = this.filterState.selectedOptions[this.key] || [];

    // This toJS fixes a MOBX "out of bounds" warning when Autocomplete attempts to read value[0], even when value is an empty array
    // Maybe they'll fix it, try to remove this when updating MUI
    return toJS(selectedOptions);
  }

  @action.bound clear() {
    this.search = '';
    this._options = [];
    window.clearTimeout(this.searchTimer);
  }

  @action.bound async loadOptions() {
    if (this.isDisabled) {
      return;
    }

    const optionsRequest = getOptionRequest(this);

    if (!optionsRequest) {
      return;
    }

    const resp = await optionsRequest;

    if (resp.status === 200 || resp.status === 204) {
      runInAction(() => {
        const newOptions = resp.data?.filterOptions || [];
        this._options =
          this.page > 0 ? [...this._options, ...newOptions] : newOptions;
        this.hasMore = resp.data?.hasMore || false;
      });
    } else {
      snackbar(i18n.t('error.loading.options'), {
        variant: 'error',
      });
    }
  }

  // Service datasources listen to this and sends it with the requests
  @computed get specificationFilterDto():
    | IntServiceDataSpecificationFilterDto
    | undefined {
    if (this.settings.entity !== 'asset') {
      return undefined;
    }

    if (
      this.settings.type === ServiceDataFilterType.ResourceGroupId &&
      this.selectedOptions.length
    ) {
      return {
        type: this.settings.type,
        values: [this.selectedOptions[this.selectedOptions.length - 1].id], // Only grab the last value, the deepest group selected
      };
    }

    return {
      type: this.settings.type,
      values: this.selectedOptions.map(option => option.id),
      definitionId: this.settings.definitionId,
    };
  }

  @computed get previousAssetFilters() {
    const previousFilters: IntServiceDataSpecificationFilterDto[] = [];
    for (let i = 0; i < this.index; i++) {
      const { specificationFilterDto } = this.filterState.controls[i];

      if (specificationFilterDto && specificationFilterDto.values?.length > 0) {
        previousFilters.push(specificationFilterDto);
      }
    }
    return previousFilters;
  }

  @action.bound resetAndLoadOptions() {
    this.page = 0;
    this.search = '';
    this._options = [];
    this.loadOptions();
  }

  @action.bound updateSearch() {
    this.page = 0; // Start over when search changes
    this.loadOptions();
  }

  searchTimer = 0;
  @observable search = '';

  @action.bound setSearch(
    newSearch: string,
    reason: AutocompleteInputChangeReason
  ) {
    // This happens when an option is selected, but we want to avoid clearing search
    // Strange MUI behavior? Arguably yes
    if (reason === 'reset' && !newSearch) {
      return;
    }

    if (newSearch === this.search) {
      return; // No need to update options
    }

    this.search = newSearch;

    window.clearTimeout(this.searchTimer);

    if (reason === 'clear') {
      // Immediately update
      this.updateSearch();
    } else if (reason === 'input') {
      // Wait a bit for further input, then update
      this.searchTimer = window.setTimeout(this.updateSearch, 250);
    }
  }

  @action.bound setValue(newValue: IntServiceDataFilterOptionDto[]) {
    const newValues: Record<string, IntServiceDataFilterOptionDto[]> = {
      [this.key]: newValue,
    };

    // Reset all later filters
    for (let i = this.index + 1; i < this.filterState.controls.length; i++) {
      newValues[this.filterState.controls[i].key] = [];
    }

    this.filterState.updateValues(newValues);
  }

  @action.bound fetchMore() {
    this.page++;
    this.loadOptions();
  }

  @computed get isDisabled() {
    const { authStore } = this.filterState.dashboard.rootStore;

    if (this.settings.entity === 'asset') {
      return !isFilterTypeEnabled(authStore, this.settings.type);
    }

    return false; // For now, skip this check for connectivity units
  }
}

function getOptionRequest(
  controlState: FilterControlState
): Promise<IResponse<IntServiceDataFilterOptionResponseDto>> | undefined {
  const { page, search, settings } = controlState;

  if (settings.entity === 'asset') {
    return controlState.httpPost(serviceApi.getFilterOptions, {
      params: undefined,
      data: {
        filters: controlState.previousAssetFilters,
        page,
        pageSize,
        search,
        type: settings.type,
        definitionId: settings.definitionId,
        includeEntityCount: false,
      },
    });
  }

  return undefined;
}
