import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => {
  return {
    root: {
      zIndex: theme.zIndex.appBar, // 1099,
      justifyContent: 'center',
      minHeight: theme.settings.components.topMenu.height,
      ...(theme.settings.components.topMenu.root as {}),
    },
    toolbar: {
      minHeight: theme.settings.components.topMenu.height - 2,
      paddingLeft: 0,
    },
  };
});
