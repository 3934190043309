import {
  getEnvironmentVariable,
  isDevelopment,
  isStagingTest,
} from 'utils/constants';

const envType = getEnvironmentVariable('REACT_APP_ENV');
const url = new URL(window.location.href);
const splitHostname = url.hostname.split('.');

function getCurrentSubPortalDomain() {
  switch (envType) {
    case 'prod':
      return splitHostname.length > 2 ? splitHostname[0] : undefined;
    case 'dev':
    case 'test':
      return splitHostname.length > 3 ? splitHostname[0] : undefined;
    default:
      return undefined;
  }
}

function getPortalRedirectURL(activeSubPortalDomainAlias: string | undefined) {
  const currentSubPortalDomain = getCurrentSubPortalDomain();
  const subPortalDomainAlias = Boolean(activeSubPortalDomainAlias)
    ? `${activeSubPortalDomainAlias}.`
    : '';
  const hostName = currentSubPortalDomain
    ? splitHostname.slice(1).join('.')
    : url.hostname;

  return `${url.protocol}//${subPortalDomainAlias}${hostName}${url.pathname}${url.search}`;
}

function isMainPortalDomainMatch(
  activeSubPortalDomainAlias: string | undefined
) {
  const redirectURL = getPortalRedirectURL(activeSubPortalDomainAlias);
  const mainURL = new URL(redirectURL);

  return url.hostname === mainURL.hostname;
}

export function isPortalRedirectRequired(
  isLoggedIn: boolean,
  activeSubPortalDomainAlias: string | undefined
) {
  const currentSubPortalDomain = getCurrentSubPortalDomain();
  const isSubPortalDomainMatch =
    activeSubPortalDomainAlias === currentSubPortalDomain;
  const isMainPortalURLMatch = isMainPortalDomainMatch(
    activeSubPortalDomainAlias
  );
  const isDomainMatch = Boolean(activeSubPortalDomainAlias)
    ? isSubPortalDomainMatch
    : isMainPortalURLMatch;

  return isLoggedIn && !isDomainMatch && !isDevelopment && !isStagingTest;
}

export function redirectToSubPortal(
  isLoggedIn: boolean,
  activeSubPortalDomainAlias: string | undefined,
  isFeatureFlagEnabled: boolean
) {
  const isRedirectRequired = isPortalRedirectRequired(
    isLoggedIn,
    activeSubPortalDomainAlias
  );

  if (isRedirectRequired && isFeatureFlagEnabled) {
    window.location.href = getPortalRedirectURL(activeSubPortalDomainAlias);
  }
}
