import { Box } from '@mui/material';
import AtlasIcon from 'components/AtlasIcon';
import { DropdownMenu, DropdownMenuItem } from 'components/DropdownMenu';
import i18n from 'i18n';
import { Column } from 'react-table';
import { FeatureAction } from 'store/featureStore';

export function featureAction<T>(options: {
  id: string;
  accessor: (row: T) => T;
  onClick: (row: T, action: FeatureAction) => void;
  actions: { [K in keyof typeof FeatureAction]: boolean };
  disabled: (row: T) => boolean;
}): Column<T> {
  const { id, accessor, onClick, actions, disabled } = options;

  return {
    id,
    filterable: false,
    sortable: false,
    resizable: false,
    width: 30,
    accessor,
    Cell: row => {
      const isDisabled = disabled(row.original);
      return (
        <Box color="primary.main">
          {Object.values(actions).some(action => action) && (
            <DropdownMenu
              onItemClick={(action: FeatureAction) => {
                onClick(row.original, action);
              }}
              label=""
              hideChevron
              icon={<AtlasIcon type={'MoreHoriz'} color="primary" />}
            >
              <DropdownMenuItem
                icon="PlusMinus"
                label={i18n.t('feature.toggle.base')}
                disabled={!actions.TOGGLE}
                value={FeatureAction.TOGGLE}
              />
              <DropdownMenuItem
                icon="Pencil"
                label={i18n.t('feature.edit.base')}
                disabled={!actions.EDIT}
                value={FeatureAction.EDIT}
              />
              <DropdownMenuItem
                icon="Pencil"
                label={i18n.t('feature.edit.advanced')}
                disabled={!actions.ADVANCEDEDIT}
                value={FeatureAction.ADVANCEDEDIT}
              />
              <DropdownMenuItem
                icon="TrashEmpty"
                label={i18n.t('delete')}
                disabled={!actions.DELETE || isDisabled}
                value={FeatureAction.DELETE}
              />
            </DropdownMenu>
          )}
        </Box>
      );
    },
  };
}
