import MessageOverlay from 'components/MessageOverlay';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { fontSettings } from 'styles/themes/fontSettings';
import { IDashboardComponent } from 'views/Dashboard/dashboardTypes';
import { useDataSource, useDataSourceData } from 'views/Dashboard/DataSources';
import Widget from 'views/Dashboard/Widget';
import { dataProp } from '../../DataSources';
import { IPieChartSettings } from './pieChartConfig';

interface IPieChartDataPoint {
  y: number;
  name: string;
  color?: string;
}

const PieChartComponent: IDashboardComponent<IPieChartSettings> = ({
  component,
}) => {
  const { t } = useTranslation('dashboard');
  const { settings } = component;

  const dataSource = useDataSource(settings.dataSource);

  const dataProps = dataSource.getDataProps(getProp => ({
    slices: (settings.slices || []).map(slice => getProp(slice.prop)),
  }));

  const { data, noContent, error } = useDataSourceData(dataSource);

  const chartData: IPieChartDataPoint[] = [];
  let total = 0;

  if (data?.type === 'object') {
    dataProps.slices.forEach(sliceDataProp => {
      const sliceValue = dataProp.getNumber(sliceDataProp, data.item);

      if (sliceValue === null || sliceValue === 0) {
        return; // Don't include empty slices (could be a future setting?)
      }

      total += sliceValue;

      chartData.push({
        name: sliceDataProp.name,
        y: sliceValue,
        color: sliceDataProp.getColor?.(data.item),
      });
    });
  }

  const chartOptions: Highcharts.Options = {
    title: {
      text: undefined,
    },
    legend: {
      enabled: !!data,
      align: 'right',
      verticalAlign: 'middle',
      symbolRadius: 0,
      itemMarginBottom: 4,
      itemMarginTop: 4,
      title: { text: `${t('common:total')}: ${total}` },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        type: 'pie',
        tooltip: {
          pointFormatter(this) {
            return `<b>${this.percentage?.toFixed(2)}%</b><br/>${t(
              'common:count'
            )}: <b>${this.y}</b>`;
          },
        },
        dataLabels: {
          enabled: true,
          distance: -22,
          formatter() {
            return this.percentage > 3
              ? `${this.percentage.toFixed(2) || 0}%`
              : '';
          },
          style: {
            textOutline: undefined,
            fontFamily: fontSettings.Pebble.fontStack,
            fontSize: '0.7rem',
            fontWeight: 'light',
          },
        },

        borderWidth: 1,
        borderColor: 'none',
        shadow: false,
        innerSize: '50%',
        showInLegend: true,
        data: chartData,
      },
    ],
  };

  return (
    <Widget isLoading={!dataSource.isInitialized || dataSource.isLoading}>
      <Widget.Header>{settings.title}</Widget.Header>
      <Widget.Content skeleton={!dataSource.isInitialized || !chartOptions}>
        {chartOptions && (
          <HighchartsReact
            options={chartOptions}
            highcharts={Highcharts}
            containerProps={{
              style: {
                height: '100%',
              },
            }}
          />
        )}

        {noContent && <MessageOverlay message={t('no_data')} />}
        {error && <MessageOverlay message={error} />}
      </Widget.Content>
    </Widget>
  );
};

export default observer(PieChartComponent);
