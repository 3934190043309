import { Box } from '@mui/material';
import i18n from 'i18n';
import React from 'react';
import { CellInfo, Column } from 'react-table';
import { formatNumber } from 'utils';
import { getTextFilter } from '../Helpers/getTextFilter';
import { textRight } from '../Helpers/textRight';

type RowValueType = number | number[] | string | string[] | undefined | null;

export function mixedType<T>(options: {
  id: string;
  header: string;
  accessor: (row: T) => RowValueType;
  defaultShow?: boolean;
  defaultSortDesc?: boolean;
  filterable?: boolean;
  sortable?: boolean;
  resizable?: boolean;
  width?: number;
  maxWidth?: number;
  ellipsis?: boolean;
  cellRender?: (row: CellInfo) => React.ReactElement;
  getUnit?: (row: T) => string;
  unit?: string;
  aggregate?: 'min-max';
  decimals?: number;
  conversion?: number;
  getConversion?: (row: T) => number | undefined;
  getDecimals?: (row: T) => number | undefined;
}): Column<T> {
  const {
    id,
    header,
    accessor,
    defaultShow: show = true,
    defaultSortDesc = false,
    filterable,
    sortable,
    resizable,
    width = 100,
    maxWidth = 150,
    getUnit,
    getDecimals,
    getConversion,
  } = options;

  const filterRender = getTextFilter('number');

  return {
    id,
    Header: <Box textAlign="right">{header}</Box>,
    accessor,
    width,
    maxWidth,
    show,
    filterable,
    sortable,
    resizable,
    Filter: filterRender,
    defaultSortDesc,
    Cell: ({ value, original }) => {
      if (value === null) {
        return (
          <div className={`${textRight} text-muted`}>
            {i18n.t('not_applicable_abbreviation')}
          </div>
        );
      }

      if (typeof value === 'number') {
        const unit = options.unit ?? getUnit?.(original) ?? '';
        const decimals = options.decimals ?? getDecimals?.(original);
        const conversion =
          options.conversion ?? getConversion?.(original) ?? null;
        const convertedValue = conversion ? value * conversion : value;

        return (
          <div className={textRight}>
            {formatNumber(convertedValue, { decimals })}
            {unit && <span className="text-unit">{unit}</span>}
          </div>
        );
      }

      return value;
    },
  };
}
