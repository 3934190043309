export enum Role {
  AccessAccountSettings = 'Access Account Settings',
  AccessSubscriptionsSettings = 'Access Subscriptions Settings',
  AccessSystemJobsDashboard = 'Access System Jobs Dashboard',
  AccessServiceData = 'Access Service Data',
  AccessPreviewFeatures = 'Access Preview Features',
  AccessManagementPortal = 'Access Management Portal',
  AccessCustomerPortal = 'Access Customer Portal',
  RoleNameEditVpnServer = 'Edit VPN Server',
  RoleNameDeleteVpnServer = 'Delete VPN Server',
  RoleNameViewVpnServer = 'View VPN Server',
  RoleNameEditAnalyticsCube = 'Edit Analytics Cube',
  RoleNameDeleteAnalyticsCube = 'Delete Analytics Cube',
  RoleNameViewAnalyticsCube = 'View Analytics Cube',
  RoleNameEditTerminalType = 'Edit Terminal Type',
  RoleNameDeleteTerminalType = 'Delete Terminal Type',
  RoleNameViewTerminalType = 'View Terminal Type',
  RoleNameEditAssetType = 'Edit Asset Type',
  RoleNameDeleteAssetType = 'Delete Asset Type',
  RoleNameViewAssetType = 'View Asset Type',
  RoleNameEditSimLinkConfiguration = 'Edit Sim Link Configuration',
  RoleNameDeleteSimLinkConfiguration = 'Delete Sim Link Configuration',
  RoleNameViewSimLinkConfiguration = 'View Sim Link Configuration',
  RoleNameEditTerminalLinkConfiguration = 'Edit Terminal Link Configuration',
  RoleNameDeleteTerminalLinkConfiguration = 'Delete Terminal Link Configuration',
  RoleNameViewTerminalLinkConfiguration = 'View Terminal Link Configuration',
  RoleNameEditAutomationRule = 'Edit Automation Rule',
  RoleNameDeleteAutomationRule = 'Delete Automation Rule',
  RoleNameViewAutomationRule = 'View Automation Rule',
  RoleNameForceExecuteAutomationRule = 'Force Execute Automation Rule',
  RoleNameViewAutomationRuleEntityChanged = 'View Automation Rule Entity Changed',
  RoleNameViewAutomationRuleServiceData = 'View Automation Rule Service Data',
  RoleNameEditIntelligenceEventDefinition = 'Edit Intelligence Event Definition',
  RoleNameDeleteIntelligenceEventDefinition = 'Delete Intelligence Event Definition',
  RoleNameViewIntelligenceEventDefinition = 'View Intelligence Event Definition',
  RoleNameEditReportTemplate = 'Edit Report Template',
  RoleNameDeleteReportTemplate = 'Delete Report Template',
  RoleNameViewReportTemplate = 'View Report Template',
  RoleNameEditIntelligenceEvent = 'Edit Intelligence Event',
  RoleNameDeleteIntelligenceEvent = 'Delete Intelligence Event',
  RoleNameViewIntelligenceEvent = 'View Intelligence Event',
  RoleNameEditDealer = 'Edit Dealer',
  RoleNameDeleteDealer = 'Delete Dealer',
  RoleNameViewDealer = 'View Dealer',
  RoleNameEditCustomer = 'Edit Customer',
  RoleNameDeleteCustomer = 'Delete Customer',
  RoleNameViewCustomer = 'View Customer',
  RoleNameEditCustomerLinks = 'Edit Customer Links',
  RoleNameViewCustomerLinks = 'View Customer Links',
  RoleNameEditUser = 'Edit User',
  RoleNameDeleteUser = 'Delete User',
  RoleNameViewUser = 'View User',
  RoleNameEditGroup = 'Edit Group',
  RoleNameDeleteGroup = 'Delete Group',
  RoleNameViewGroup = 'View Group',
  RoleNameEditRole = 'Edit Role',
  RoleNameDeleteRole = 'Delete Role',
  RoleNameViewRole = 'View Role',
  RoleNameEditAsset = 'Edit Asset',
  RoleNameDeleteAsset = 'Delete Asset',
  RoleNameViewAsset = 'View Asset',
  RoleNameSendServiceCommandToAsset = 'Send Service Command To Asset',
  RoleNameEditTerminal = 'Edit Terminal',
  RoleNameDeleteTerminal = 'Delete Terminal',
  RoleNameViewTerminal = 'View Terminal',
  RoleNameSendForceSendUpdatesCommandToTerminal = 'Send Force Send Updates Command To Terminal',
  RoleNameSendRestartCommandToTerminal = 'Send Restart Command To Terminal',
  RoleNameSendVerifyConnectivityCommandToTerminal = 'Send Verify Connectivity Command To Terminal',
  RoleNameReconnectSim = 'Reconnect Sim',
  RoleNameSendUpdateAgentCommandToTerminal = 'Send Update Agent Command To Terminal',
  RoleNameSendUpdateFirmwareCommandToTerminal = 'Send Update Firmware Command To Terminal',
  RoleNameSendGetSystemLogCommandToTerminal = 'Send Get System Log Command To Terminal',
  RoleNameSendImportFirmwareConfigurationCommandToTerminal = 'Send Import Firmware Configuration Command To Terminal',
  RoleNameSendExportFirmwareConfigurationCommandToTerminal = 'Send Export Firmware Configuration Command To Terminal',
  RoleNameSendBulkCommandsToTerminals = 'Send Bulk Commands To Terminals',
  RoleNameSendAddFirewallFilterCommandToTerminal = 'Send Add Firewall Filter Command To Terminal',
  RoleNameSendRemoveFirewallFilterCommandToTerminal = 'Send Remove Firewall Filter Command To Terminal',
  RoleNameSendUpdateFirewallFilterCommandToTerminal = 'Send Update Firewall Filter Command To Terminal',
  RoleNameSendEnableWifiCommandToTerminal = 'Send Enable Wifi Command To Terminal',
  RoleNameSendEnablePortPoeCommandToTerminal = 'Send Enable Port Poe Command To Terminal',
  RoleNameSendEnableFirewallFilteringCommandToTerminal = 'Send Enable Firewall Filtering Command To Terminal',
  RoleNameSendSetDefaultFirewallFilteringPolicyCommandToTerminal = 'Send Set Default Firewall Filtering Policy Command To Terminal',
  RoleNameEditTerminalAgent = 'Edit Terminal Agent',
  RoleNameDeleteTerminalAgent = 'Delete Terminal Agent',
  RoleNameViewTerminalAgent = 'View Terminal Agent',
  RoleNameEditSim = 'Edit Sim',
  RoleNameDeleteSim = 'Delete Sim',
  RoleNameViewSim = 'View Sim',
  RoleNameEditService = 'Edit Service',
  RoleNameDeleteService = 'Delete Service',
  RoleNameViewService = 'View Service',
  RoleNameEditSolution = 'Edit Solution',
  RoleNameDeleteSolution = 'Delete Solution',
  RoleNameViewSolution = 'View Solution',
  RoleNameEditSubscriptionPlan = 'Edit Subscription Plan',
  RoleNameDeleteSubscriptionPlan = 'Delete Subscription Plan',
  RoleNameViewSubscriptionPlan = 'View Subscription Plan',
  RoleNameEditAspect = 'Edit Aspect',
  RoleNameDeleteAspect = 'Delete Aspect',
  RoleNameViewAspect = 'View Aspect',
  RoleNameEditWidget = 'Edit Widget',
  RoleNameDeleteWidget = 'Delete Widget',
  RoleNameViewWidget = 'View Widget',
  RoleNameEditCdr = 'Edit CDR',
  RoleNameDeleteCdr = 'Delete CDR',
  RoleNameViewCdr = 'View CDR',
  RoleNameViewSimCdrDataUsage = 'View CDR data usage for SIM',
  RoleNameViewAutomationRuleTriggerGeofence = 'View AutomationRuleTriggerGeofence',
  RoleNameDeleteAutomationRuleTriggerGeofence = 'Delete AutomationRuleTriggerGeofence',
  RoleNameEditAutomationRuleTriggerGeofence = 'Edit AutomationRuleTriggerGeofence',
  RoleNameViewSubscription = 'View Subscription',
  RoleNameDeleteSubscription = 'Delete Subscription',
  RoleNameEditSubscription = 'Edit Subscription',
  RoleNameImpersonateUser = 'Impersonate users',
  RoleNameSimulateSubPortal = 'Simulate Subportal',
  RoleNameViewNewUI = 'View new UI',
  RoleNameOnlyViewAtlas = 'Only View Atlas',
  RoleNameViewConnectivityConfigurations = 'View Connectivity Configurations',
  RoleNameEditConnectivityConfigurations = 'Edit Connectivity Configurations',
  RoleNameViewConnectivityUnit = 'View Connectivity Units',
  RoleNameEditConnectivityUnit = 'Edit Connectivity Units',
  RoleNameViewConnectivityUnitEvents = 'View Connectivity Units Events',
  RoleNameViewConnectivityGroupEvents = 'View Connectivity Group Events',
  RoleNameViewCustomerEvents = 'View Customer Events',
  RoleNameViewAudit = 'View Audit',
  RoleNameViewConnectivityOrder = 'View Connectivity Orders',
  RoleNameDeleteConnectivityOrder = 'Delete Connectivity Orders',
  RoleNameEditConnectivityOrder = 'Edit Connectivity Orders',
  RoleNameViewIpAccess = 'View IP Access',
  RoleNameDeleteIpAccess = 'Delete IP Access',
  RoleNameEditIpAccess = 'Edit IP Access',
  RoleNameViewProduct = 'View Product',
  RoleNameViewInvoice = 'View Invoice',
  RoleNameViewConnectivityGroup = 'View Connectivity Group',
  RoleNameEditConnectivityGroup = 'Edit Connectivity Group',
  RoleNameDeleteConnectivityGroup = 'Delete Connectivity Group',
  RoleNameViewDashboard = 'View Dashboard',
  RoleNameEditDashboard = 'Edit Dashboard',
  RoleNameDeleteDashboard = 'Delete Dashboard',
  RoleNameViewDashboardDataStatus = 'View Dashboard Data Status',
  RoleNameEditDashboardDataStatus = 'Edit Dashboard Data Status',
  RoleNameDeleteDashboardDataStatus = 'Delete Dashboard Data Status',
  RoleNameViewExtension = 'View Extension',
  RoleNameEditExtension = 'Edit Extension',
  RoleNameDeleteExtension = 'Delete Extension',
  RoleNameStaticDemo = 'Static Demo',
  RoleNameViewNavigation = 'View Navigation',
  RoleNameEditNavigation = 'Edit Navigation',
  RoleNameDeleteNavigation = 'Delete Navigation',
  RoleNameViewUiConfiguration = 'View UI Configuration',
  RoleNameEditUiConfiguration = 'Edit UI Configuration',
  RoleNameDeleteUiConfiguration = 'Delete UI Configuration',
  RoleNameViewProductTemplate = 'View Product Template',
  RoleNameEditProductTemplate = 'Edit Product Template',
  RoleNameDeleteProductTemplate = 'Delete Product Template',
  RoleNameViewSmsHub = 'View SMS Hub Data',
  RoleNameEditSmsHub = 'Edit SMS Hub Data',
  ViewAdminPages = 'View Admin Pages',
  RoleNameEditDynamicAttributeDefinition = 'Edit Attribute Definition',
  RoleNameDeleteDynamicAttributeDefinition = 'Delete Attribute Definition',
  RoleNameViewDynamicAttributeDefinition = 'View Attribute Definition',
  RoleNameDeleteRealEstateItAsset = 'Delete Real Estate It Asset',
  RoleNameEditRealEstateItAsset = 'Edit Real Estate It Asset',
  RoleNameViewRealEstateItAsset = 'View Real Estate It Asset',
  RoleNameViewSupport = 'View Support',
  RoleNameMoveConnectivityGroupOrUnitsToCustomer = 'Move to Customer',
  RoleNameViewConnectivityUnitAttributeDefinitions = 'View Connectivity Unit Attribute Definitions',
  RoleNameEditConnectivityUnitAttributeDefinitions = 'Edit Connectivity Unit Attribute Definitions',
  RoleNameEditGlobalConnectivityUnitAttributeDefinitions = 'Edit Global Connectivity Unit Attribute Definitions',
  RoleNameEditReadOnlyConnectivityUnitAttributeDefinitions = 'Edit ReadOnly Connectivity Unit Attribute Definitions',
  RoleNameDeleteConnectivityUnitAttributeDefinitions = 'Delete Connectivity Unit Attribute Definitions',
  RoleNameViewConnectivityUnitAttributes = 'View Connectivity Unit Attributes',
  RoleNameEditConnectivityUnitAttributes = 'Edit Connectivity Unit Attributes',
  RoleNameEditGlobalConnectivityUnitAttributes = 'Edit Global Connectivity Unit Attributes',
  RoleNameEditReadOnlyConnectivityUnitAttributes = 'Edit ReadOnly Connectivity Unit Attributes',
  ViewFeatureManagement = 'View Feature Management',
  EditFeatureManagement = 'Edit Feature Management',
  RoleNameViewReport = 'View Report',
  RoleNameEditReport = 'Edit Report',
  RoleNameDeleteReport = 'Delete Report',
  Placeholder = 'Not a real role', // for dev testing
}
