import { AtlasIconType } from 'shared/types/atlas-icon';
import ButtonGroup from './ButtonGroup';
import Checkbox from './Checkbox';
import CheckboxGroup from './CheckboxGroup';
import ColorPicker from './ColorPicker';
import DatePicker from './DatePicker';
import DateTimePicker from './DateTimePicker';
import DocumentUpload from './DocumentUpload';
import FileUpload from './FileUpload';
import Autocomplete from './FormikAutocomplete';
import FormikSwitch from './FormikSwitch';
import LogErrors from './LogErrors';
import MultiSelectDropdown from './MultiSelectDropdown';
import NumberField from './NumberField';
import PasswordField from './PasswordField';
import PhoneField from './PhoneField';
import PhoneList from './PhoneList';
import Radio from './Radio';
import RadioGroup from './RadioGroup';
import SimpleSelect from './SimpleSelect';
import SingleFileUpload from './SingleFileUpload';
import StringList from './StringList';
import TextField from './TextField';
import ValueChange from './ValueChange';

export interface Option<TValue = string> {
  label: string;
  value: TValue;
  disabled?: boolean;
  icon?: AtlasIconType;
}

export interface IntAttachmentDto {
  id: string;
  fileName: string;
  size: number;
}

export const FormField = {
  Radio,
  Checkbox,
  CheckboxGroup,
  StringList,
  FormikSwitch,
  DatePicker,
  TextField,
  SimpleSelect,
  ButtonGroup,
  RadioGroup,
  NumberField,
  FileUpload,
  DocumentUpload,
  PasswordField,
  Autocomplete,
  PhoneField,
  PhoneList,
  ColorPicker,
  SingleFileUpload,
  MultiSelectDropdown,
  ValueChange,
  DateTimePicker,
  LogErrors,
};
