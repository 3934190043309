import { highchartsColors } from './ThemedHighcharts';

let nextColorIndex = 0;
const colorsBySeriesId: Record<string, number> = {};

export function getSeriesColorIndex(seriesId: string) {
  if (!seriesId) {
    return undefined;
  }

  if (colorsBySeriesId[seriesId] === undefined) {
    colorsBySeriesId[seriesId] = nextColorIndex;
    nextColorIndex = (nextColorIndex + 1) % highchartsColors.length;
  }

  return colorsBySeriesId[seriesId];
}
