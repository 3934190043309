import { Column } from 'react-table';
import { formatNumber } from 'utils';
import { CustomCell } from '../Helpers/CustomCell';
import { ICustomCellProps } from '../Helpers/CustomCellProps';
import { numberMaxAggregationProps } from '../PivotCells';

export function airQualityIndex<T>(options: {
  id: string;
  accessor: (row: T) => number | null;
  header?: string;
  defaultShow?: boolean;
  filterable?: boolean;
}): Column<T> {
  const {
    id,
    header = 'Status',
    accessor,
    defaultShow: show = true,
    filterable,
  } = options;

  const getCellProps = (value: number): ICustomCellProps => {
    if (value === null || value === undefined || value < 0) {
      return { text: '-' };
    }
    const text = `${formatNumber(value, { decimals: 0 })}`;

    if (value <= 50) {
      return {
        iconType: 'Status',
        iconColor: '#009505',
        text,
        title: 'Good',
      };
    } else if (value <= 100) {
      return { iconType: 'Status', iconColor: '#00e512', text, title: 'Fair' };
    } else if (value <= 150) {
      return {
        iconType: 'Status',
        iconColor: '#fdea00',
        text,
        title: 'Moderate',
      };
    } else if (value <= 200) {
      return { iconType: 'Status', iconColor: '#ff8100', text, title: 'Poor' };
    } else if (value <= 300) {
      return {
        iconType: 'Status',
        iconColor: '#dd0000',
        text,
        title: 'Very poor',
      };
    } else if (value <= 500) {
      return {
        iconType: 'Status',
        iconColor: '#f00',
        text,
        title: 'Unhealthy',
      };
    }
    return {
      iconType: 'Status',
      iconColor: '#000',
      text,
      title: 'Very unhealthy',
    };
  };

  return {
    id,
    Header: header,
    accessor,
    resizable: false,
    maxWidth: 130,
    filterable,
    show,
    className: 'text-right',
    Cell: row => <CustomCell {...getCellProps(row.value)} index={row.index} />,
    sortMethod: (a, b) => (a ?? -1) - (b ?? -1), // Fix sorting for null values (last)
    ...numberMaxAggregationProps, // Show max values, with the same logic as normal cells, in pivoted table "parent" rows
    Aggregated: row => (
      <CustomCell {...getCellProps(row.value)} index={row.index} />
    ),
  };
}
