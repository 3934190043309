import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
} from '@mui/material';
import classNames from 'classnames';
import { useField } from 'formik';
import React from 'react';
import { Option } from '..';
import { useStyles } from './CheckboxGroup.styles';

interface IProps<TValue = any> {
  label: string;
  name: string;
  options: Option<TValue>[];
  fullWidth?: boolean;
}
const CheckboxGroup: React.FC<IProps> = ({
  name,
  label,
  options,
  fullWidth = false,
}) => {
  const classes = useStyles();
  const [field, meta] = useField({ name, type: 'checkbox' });
  return (
    <>
      {options.length > 0 && (
        <FormControl error={!!meta.error}>
          <FormLabel>{label}</FormLabel>
          <FormGroup row>
            <Box mb={3}>
              {options.map(opt => (
                <FormControlLabel
                  className={classNames({
                    [classes.fullWidth]: fullWidth,
                  })}
                  control={
                    <Checkbox
                      color={'primary'}
                      data-cy={opt.value}
                      key={opt.value}
                      {...field}
                      value={opt.value}
                      checked={field.value.includes(opt.value)}
                    />
                  }
                  label={<span>{opt.label}</span>}
                />
              ))}
              {meta.error && <FormHelperText>{meta.error}</FormHelperText>}
            </Box>
          </FormGroup>
        </FormControl>
      )}
    </>
  );
};

export default CheckboxGroup;
