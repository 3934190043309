import { Tooltip, TooltipProps } from '@mui/material';
import React from 'react';
import { useStyles } from './AtlasTooltip.styles';

export interface IAtlasTooltipProps extends TooltipProps {
  children: JSX.Element;
  className?: string;
}

const AtlasTooltip: React.FC<IAtlasTooltipProps> = ({
  className,
  placement = 'top',
  children,
  ...tooltipProps
}) => {
  const classes = useStyles();

  return (
    <Tooltip
      className={classes.root}
      classes={{ popper: classes.tooltip }}
      arrow
      {...tooltipProps}
      placement={placement}
    >
      <div>{children}</div>
    </Tooltip>
  );
};

export default AtlasTooltip;
