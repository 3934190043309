import { Box, Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import ComponentContainer from 'views/Dashboard/ComponentContainer';
import { IDashboardComponent } from 'views/Dashboard/dashboardTypes';
import { Control } from 'views/Dashboard/SettingControls';
import { IFiltersSettings } from './FiltersComponent/filtersConfig';
import { IWidgetSettings } from './IWidgetSettings';

const PreviewStep: IDashboardComponent<
  Exclude<IWidgetSettings, IFiltersSettings>
> = ({ component }) => {
  const { t } = useTranslation('dashboard');
  const { settings } = component;

  return (
    <>
      <Control.Group label={t('general_settings')}>
        <Grid container>
          <Grid item sm={6}>
            <Control.Text
              object={settings}
              name="title"
              autoFocus
              label={t('title')}
            />
          </Grid>
        </Grid>
      </Control.Group>

      <Control.Group label={t('final_preview')}>
        <Box height={360}>
          <ComponentContainer component={component} />
        </Box>
      </Control.Group>
    </>
  );
};

export default observer(PreviewStep);
