import i18n from 'i18n';
import { Column } from 'react-table';
import { FilterOption } from '../Helpers/filterOption';
import { getOptionFilter } from '../Helpers/getOptionFilter';

export function connectivityUnitProperties<T>(options: {
  id: string;
  accessor: (row: T) => string | undefined;
  header?: string;
  defaultShow?: boolean;
  filterable?: boolean;
  width?: number;
  isNoOptionValueRequired?: boolean;
  extraFilterOptions: string[];
}): Column<T> {
  const {
    id,
    header,
    accessor,
    defaultShow: show = true,
    filterable,
    width = 80,
    isNoOptionValueRequired = false,
    extraFilterOptions,
  } = options;

  const filterOptions: FilterOption[] = [
    { label: i18n.t('common:all'), value: '' },
  ];

  isNoOptionValueRequired &&
    filterOptions.push({ label: i18n.t('common:action.no'), value: null });

  extraFilterOptions &&
    extraFilterOptions.forEach(item => {
      filterOptions.push({ label: item, value: item });
    });

  return {
    id,
    Header: header,
    accessor,
    resizable: true,
    width,
    show,
    filterable,
    Filter: getOptionFilter(filterOptions),
    Cell: row => {
      if (!row.value)
        return (
          <span className="text-muted">
            {i18n.t('not_applicable_abbreviation')}
          </span>
        );

      return row.value;
    },
  };
}
