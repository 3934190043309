import gPhoneNumber from 'google-libphonenumber';

// Check if start of number is a valid country code
export const isValidCountryCode = (
  code: string,
  fieldValue: string,
  phoneUtil: gPhoneNumber.PhoneNumberUtil
) => {
  return fieldValue
    ?.replace(/^(00|\+)/, '')
    .startsWith(`${phoneUtil.getCountryCodeForRegion(code)}`);
};

// Check if a number consists exclusively of a valid country code
export const isValidStandaloneCountryCode = (
  code: string,
  fieldValue: string,
  phoneUtil: gPhoneNumber.PhoneNumberUtil
) => {
  return (
    fieldValue?.replace(/^(00|\+)/, '') ===
    `${phoneUtil.getCountryCodeForRegion(code)}`
  );
};

// Extract country code from possible parameters
export const findCountryCode = (
  countryCode: string,
  fieldValue: string,
  phoneUtil: gPhoneNumber.PhoneNumberUtil,
  regionCode?: string
) => {
  if (regionCode) {
    return regionCode;
  } else if (isValidCountryCode(countryCode, fieldValue, phoneUtil)) {
    return countryCode;
  } else if (
    phoneUtil
      .getSupportedRegions()
      .filter(code => isValidCountryCode(code, fieldValue, phoneUtil))
      .length === 1
  ) {
    return phoneUtil
      .getSupportedRegions()
      .find(code => isValidCountryCode(code, fieldValue, phoneUtil));
  }
  return 'ZZ';
};
