import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    day: {
      margin: 0,
      width: '38px',
      borderRadius: '0',
    },
    selected: {
      '&::before': {
        content: '""',
        backgroundColor: '#990ae321',
        position: 'absolute',
        height: '100%',
        left: 0,
        right: 0,
      },
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        color: '#FFF',
      },
    },
    beginCap: {
      borderRadius: '50%',
      backgroundColor: theme.palette.primary.main,
      color: '#FFF',

      '&::before': {
        left: '50%',
      },
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    endCap: {
      borderRadius: '50%',
      backgroundColor: theme.palette.primary.main,
      color: '#FFF',
      '&::before': {
        right: '50%',
      },
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  })
);
