import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { appInsights } from 'appInsights';
import Axios from 'axios';
import qs from 'qs';
import { useCallback, useState } from 'react';
import {
  GetEndpoint,
  IResponse,
  PostPatchPutOrDeleteEndpoint,
  PostRequest,
} from 'shared/interfaces/api';
import { useStores } from 'store';
import { AuthStore } from 'store/authStore';
import { delay } from 'utils';

export type RequestType = 'post' | 'patch' | 'put' | 'delete';

export async function httpGet<TParams, TData>(
  endpoint: GetEndpoint<TParams, TData>,
  method: 'get',
  params: TParams,
  setIsLoading?: (value: React.SetStateAction<boolean>) => void
) {
  let axiosResp: IResponse<TData>;
  let url;
  const { headers } = endpoint;

  if (typeof endpoint.url === 'function') {
    url = endpoint.url(params);
    axiosResp = await Axios({ method, url, headers });
  } else {
    url = endpoint.url;
    axiosResp = await Axios({
      method,
      url,
      params,
      headers,
      paramsSerializer: () => qs.stringify(params, { allowDots: true }),
    });
  }

  setIsLoading?.(false);

  return {
    data: axiosResp.data,
    status: axiosResp.status,
    statusText: axiosResp.statusText,
    headers: axiosResp.headers,
  };
}

export async function getDataAndStatus<TParams, TData, TResponseData>(
  endpoint: PostPatchPutOrDeleteEndpoint<TParams, TData, TResponseData>,
  request: PostRequest<TParams, TData>,
  type: RequestType,
  setIsSaving?: (value: React.SetStateAction<boolean>) => void
) {
  const { params, data, headers, responseType } = request;
  const url =
    typeof endpoint.url === 'function' ? endpoint.url(params) : endpoint.url;

  let axiosResp;

  if (type === 'patch') {
    axiosResp = await Axios.patch<TResponseData>(url, data, {
      headers,
      responseType,
    });
  } else if (type === 'put') {
    axiosResp = await Axios.put<TResponseData>(url, data, {
      headers,
      responseType,
    });
  } else if (type === 'delete') {
    axiosResp = await Axios.delete<TResponseData>(url, {
      data: data,
      headers,
      responseType,
    });
  } else {
    axiosResp = await Axios.post<TResponseData>(url, data, {
      headers,
      responseType,
      params: typeof endpoint.url === 'string' ? params : undefined,
    });
  }

  setIsSaving?.(false);

  return {
    data: axiosResp.data,
    status: axiosResp.status,
    statusText: axiosResp.statusText,
  };
}

export function getHttpErrorException(error: any, authStore: AuthStore) {
  appInsights.trackException({
    error: new Error(
      error?.response?.data?.ExceptionMessage ||
        error?.response?.data?.Message ||
        error?.response?.statusText
    ),
    severityLevel: SeverityLevel.Error,
  });

  if (error?.response?.status === 401) {
    authStore.clearUserContext();
  }

  return {
    status: error?.response?.status,
    statusText:
      error?.response?.data?.ExceptionMessage ||
      error?.response?.data?.Message ||
      error?.response?.statusText,
    data: error?.response?.data,
  };
}

export function useGetEndpoint<TParams, TData>(
  endpoint: GetEndpoint<TParams, TData>,
  method: 'get',
  defaultIsLoading: boolean
): [(params: TParams) => Promise<IResponse<TData>>, boolean] {
  const [isLoading, setIsLoading] = useState(defaultIsLoading);
  const { demoMode, isStaticDemo, authStore } = useStores();

  const get = useCallback(
    async (params: TParams) => {
      setIsLoading(true);
      if (demoMode || isStaticDemo || endpoint.alwaysMockData) {
        await delay(500, 2000);
        setIsLoading(false);

        return endpoint.mockData?.(params) || { status: 200 };
      } else {
        try {
          return await httpGet(endpoint, method, params, setIsLoading);
        } catch (error) {
          setIsLoading(false);
          return getHttpErrorException(error, authStore);
        }
      }
    },
    [demoMode, isStaticDemo, endpoint, method, authStore]
  );

  return [get, isLoading];
}

export function usePostPatchPutOrDelete<TParams, TData, TResponseData>(
  endpoint: PostPatchPutOrDeleteEndpoint<TParams, TData, TResponseData>,
  type: RequestType
): [
  (response: PostRequest<TParams, TData>) => Promise<IResponse<TResponseData>>,
  boolean,
] {
  const [isSaving, setIsSaving] = useState(false);
  const { demoMode, isStaticDemo, authStore } = useStores();

  const save = useCallback(
    async (request: PostRequest<TParams, TData>) => {
      const { params, data } = request;

      setIsSaving(true);

      if (demoMode || isStaticDemo || endpoint.alwaysMockData) {
        await delay(500, 2000);

        setIsSaving(false);

        return endpoint.mockData?.(params, data) || { status: 200 };
      } else {
        try {
          return await getDataAndStatus<TParams, TData, TResponseData>(
            endpoint,
            request,
            type,
            setIsSaving
          );
        } catch (error) {
          setIsSaving(false);
          return getHttpErrorException(error, authStore);
        }
      }
    },
    [endpoint, demoMode, isStaticDemo, authStore, type]
  );

  return [save, isSaving];
}
