import { Box, Button, Grid } from '@mui/material';
import AtlasIcon from 'components/AtlasIcon';
import { AutoComplete } from 'components/Inputs';
import { observer } from 'mobx-react-lite';
import React, { Dispatch, RefObject, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'store';

interface IProps {
  topRightRef: RefObject<HTMLDivElement> | null;
  policy: string | null;
  handleChange: Dispatch<SetStateAction<string | null>>;
  isCreating: boolean;
  setIsCreating: (value: boolean) => void;
}

const PolicyList: React.FC<IProps> = ({
  topRightRef,
  policy,
  handleChange,
  isCreating,
  setIsCreating,
}) => {
  const { t } = useTranslation('policy');
  const {
    policyStore: { policies = [] },
  } = useStores();

  return (
    <>
      {policies.length === 0 && (
        <Box textAlign="center">{t('no_policies')}</Box>
      )}
      <Grid
        container
        mb={2}
        paddingY={4}
        paddingX={9}
        style={{ backgroundColor: '#f4f4f9' }}
      >
        <Grid container display="flex" alignItems={'center'}>
          <Grid item sm={4}>
            <AutoComplete
              label={t('select_policy')}
              options={policies}
              getOptionValue={opt => opt.policyId}
              getOptionLabel={opt => opt.name}
              value={policy}
              onChange={handleChange}
            />
          </Grid>
          {isCreating ? undefined : (
            <Button
              onClick={() => setIsCreating(true)}
              variant={'text'}
              color="primary"
              size="small"
            >
              {t('action.add_policy')}
              <AtlasIcon type="Plus" size={16} />
            </Button>
          )}
        </Grid>
        <Grid
          item
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          sm={8}
          ref={topRightRef}
        />
      </Grid>
    </>
  );
};

export default observer(PolicyList);
