import { Box, Button, Chip, FormControl } from '@mui/material';
import Axios from 'axios';
import AtlasIcon from 'components/AtlasIcon';
import LoadingOverlay from 'components/LoadingOverlay';
import { snackbar } from 'components/Snackbar';
import { useField, useFormikContext } from 'formik';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { IntAttachmentDto } from '..';
import { useStyles } from './FileUpload.styles';

interface IProps {
  name: string;
  label: string;
  maxSize?: number; // per file
  onUploadStart?: () => void;
  onUploadEnd?: () => void;
}

interface FileUploadState {
  isUploading: boolean;
  error?: string;
}

const FileUpload: React.FC<IProps> = ({
  name,
  label,
  maxSize,
  children,
  onUploadStart,
  onUploadEnd,
}) => {
  const { t } = useTranslation();
  const [state, setState] = useState<FileUploadState>({ isUploading: false });

  const [field, meta] = useField<IntAttachmentDto[]>(name);
  const { setFieldValue } = useFormikContext<any>();

  const classes = useStyles();

  const { getRootProps, getInputProps, open } = useDropzone({
    multiple: true,
    maxSize,
    noClick: true,
    onDropRejected: () => {
      snackbar(t('validation:file_too_large'), { variant: 'error' });
    },
    onDrop: async acceptedFiles => {
      if (acceptedFiles.length === 0) {
        return;
      }
      setState({ isUploading: true });
      onUploadStart && onUploadStart();
      const formData = new FormData();
      acceptedFiles.forEach((file, i) => {
        formData.append(`file[${i}]`, file);
      });
      const uploadResponse = await Axios.post<IntAttachmentDto[]>(
        '/api/incidentReports/UploadAttachments',
        formData
      );
      setState({ isUploading: false });
      onUploadEnd && onUploadEnd();
      setFieldValue(name, [...field.value, ...uploadResponse.data]);
    },
  });

  const removeFile = (index: number) => {
    const newValue = [...field.value];
    newValue.splice(index, 1);
    setFieldValue(name, newValue);
  };

  return (
    <FormControl error={!!meta.error} fullWidth>
      <section {...getRootProps()}>
        <LoadingOverlay isLoading={state.isUploading}>
          <input {...getInputProps()} />
          {field.value &&
            field.value.map((file, i) => (
              <Chip
                key={file.id}
                label={file.fileName}
                onDelete={() => removeFile(i)}
                variant="filled"
                className={classes.chip}
              />
            ))}
          <Box pt={0.5}>
            <Button
              startIcon={<AtlasIcon type="Attachment" fontSize="small" />}
              color="primary"
              variant="text"
              onClick={open}
              sx={{ textDecoration: 'underline' }}
            >
              {label}
            </Button>
          </Box>
          {children}
        </LoadingOverlay>
      </section>
    </FormControl>
  );
};

export default FileUpload;
