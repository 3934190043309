import { Box } from '@mui/material';
import { DropdownMenu, DropdownMenuItem } from 'components/DropdownMenu';
import i18n from 'i18next';
import { Column } from 'react-table';
import { ProfileAction } from 'store/apnStore';
import { textRight } from '../Helpers/textRight';

export function profileAction<T>(options: {
  id: string;
  accessor: (row: T) => string;
  onClick: (row: T, action: ProfileAction) => void;
  actions: { update: boolean; delete: boolean; create: boolean; copy: boolean };
  disabled: (row: T) => boolean;
}): Column<T> {
  const { id = 'modify', accessor, onClick, actions, disabled } = options;

  return {
    id,
    accessor,
    filterable: false,
    sortable: false,
    resizable: false,
    className: textRight,
    width: 300,
    Cell: row => {
      const isDisabled = disabled(row.original);
      return (
        <Box color="primary.main">
          {Object.values(actions).some(action => action === true) && (
            <DropdownMenu
              onItemClick={(action: ProfileAction) =>
                onClick(row.original, action)
              }
              label={i18n.t('common:label.action')}
              cyId="profileAction"
            >
              <DropdownMenuItem
                icon="Pencil"
                label={i18n.t('common:modify')}
                value={ProfileAction.UPDATE}
                disabled={!actions.update || isDisabled}
              />
              <DropdownMenuItem
                icon="TrashEmpty"
                label={i18n.t('common:delete')}
                value={ProfileAction.DELETE}
                disabled={!actions.delete || isDisabled}
              />
              <DropdownMenuItem
                icon="Add"
                label={i18n.t('common:copy')}
                value={ProfileAction.COPY}
                disabled={!actions.copy}
              />
            </DropdownMenu>
          )}
        </Box>
      );
    },
  };
}
