import { MenuItem, TextField } from '@mui/material';
import { Option } from 'components/FormFields';
import { observer } from 'mobx-react-lite';
import { useDashboardContext } from '../contexts/dashboardContext';
import { IControlProps } from './controlProps';

interface IProps<TValue> {
  placeholder?: string;
  options?: Option<TValue>[];
  children?: JSX.Element | JSX.Element[];
}

function SelectControl<TObj, TValue extends string>(
  props: IControlProps<TObj, TValue> & IProps<TValue>
) {
  const { name, object, label, options, placeholder, children } = props;

  const { updateSetting } = useDashboardContext();

  const value: string = (object[name] as any) || '';

  return (
    <TextField
      select
      label={label || name || ''}
      value={value}
      fullWidth
      variant="outlined"
      placeholder={placeholder}
      onChange={e => updateSetting(object, name, e.target.value)}
    >
      {options?.map(opt => (
        <MenuItem key={opt.value} value={opt.value}>
          {opt.label}
        </MenuItem>
      ))}
      {children}
    </TextField>
  );
}

export default observer(SelectControl);
