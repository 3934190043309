import {
  IntDealerDetailsDto,
  IntDealerTableDto,
  IntSearchDto,
  SubPortalType,
} from 'generated';
import { IResponse } from 'shared/interfaces/api';
import { paginate, rnd } from 'utils';

class DealerDemoData {
  getDetailedTable(params?: IntSearchDto) {
    const resData = rnd.array<IntDealerTableDto>(70, _ => ({
      address: rnd.address(),
      city: 'Stockholm',
      countryIso3166: 'SE',
      countryName: rnd.country(),
      dealerId: rnd.guid(),
      dealerName: rnd.company(),
      displayName: rnd.company(),
      email: rnd.email(),
      fullCompanyName: rnd.company(),
      managingDealerName: rnd.company(),
      phone: rnd.phoneNumber(),
      registerDate: new Date(2016, 2, 3).getDate(),
      zipCode: '11253',
    }));

    return {
      status: 200,
      data: paginate(resData, params),
    };
  }

  getDealer = (): IResponse<IntDealerDetailsDto> => ({
    status: 200,
    data: {
      dealerId: rnd.guid(),
      dealerName: rnd.company(),
      fullCompanyName: rnd.company(),
      contactFirstName: rnd.firstName(),
      contactLastName: rnd.lastName(),
      contactDisplayName: rnd.fullName(),
      contactTitle: 'Dr',
      contactEmail: rnd.email(),
      registerDate: new Date(2016, 2, 3).getDate(),
      country: rnd.countryCode(),
      countryName: rnd.country(),
      city: 'Stockholm',
      zipCode: '11253',
      address: rnd.address(),
      email: rnd.email(),
      phone: rnd.phoneNumber(),
      managingDealerId: rnd.guid(),
      managingDealerName: rnd.company(),
      customers: [
        {
          id: rnd.guid(),
          name: rnd.company(),
        },
        {
          id: rnd.guid(),
          name: rnd.company(),
        },
        {
          id: rnd.guid(),
          name: rnd.company(),
        },
      ],
      subDealers: [
        {
          dealerId: rnd.guid(),
          dealerName: rnd.company(),
        },
        {
          dealerId: rnd.guid(),
          dealerName: rnd.company(),
        },
      ],
      subPortals: [
        {
          subPortalId: rnd.guid(),
          subPortalName: 'subportal',
          domainAlias: 'domainalias',
          type: SubPortalType.Atlas,
        },
      ],
    },
  });
}

export const dealerDemoData = new DealerDemoData();
