import { darken } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => {
  const {
    settings: {
      components: { navMenu },
    },
  } = theme;

  return {
    navFooter: {
      backgroundColor: darken(navMenu.background || '', 0.2),
      padding: theme.spacing(4),
      width: '100%',
    },
    navFooterCollapsed: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
      flexDirection: 'column',
    },
    logoWrapper: {
      width: '100%',
    },
    teliaLogo: {
      height: 65,
      margin: theme.spacing(-2),
    },
    pebble: {
      height: 35,
    },
    footerText: {
      color: theme.palette.primary.contrastText,
      textDecoration: 'none',
    },
    infoText: {
      fontSize: '.75rem',
      whiteSpace: 'pre-wrap',
    },
    list: {
      width: '100%',
      listStyle: 'none',
      paddingInlineStart: 0,
      fontSize: '0.7rem',
      lineHeight: 1.5,
      marginTop: 0,
      marginBottom: 0,
      alignSelf: 'center',
      padding: 0,
    },
    listItem: {
      display: 'flex',
      alignItems: 'center',
      paddingBottom: 0,
      paddingTop: 0,
      marginBottom: theme.spacing(0.5),
    },
    listItemCollapsed: {
      '& div': {
        margin: '4px auto',
      },
    },
    link: {
      color: theme.palette.primary.contrastText,
      '&:hover': {
        textDecoration: 'none',
      },
    },
    icon: {
      display: 'flex',
      width: 23,
      height: 23,
      fontSize: 23,
      marginRight: theme.spacing(1),
      color: theme.palette.primary.contrastText,
    },
    iconColumn: {
      alignItems: 'center',
      flexDirection: 'column',
      marginTop: 0,
      marginBottom: theme.spacing(2),
    },
    iconList: {
      marginTop: theme.spacing(2),
      display: 'flex',
    },
    languageSelector: {
      width: '100%',
    },
  };
});
