import { Box } from '@mui/material';
import MessageOverlay from 'components/MessageOverlay';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { IDashboardComponent } from 'views/Dashboard/dashboardTypes';
import { useDataSource, useDataSourceData } from 'views/Dashboard/DataSources';
import Widget from 'views/Dashboard/Widget';
import { INumberGridSettings } from './numberGridConfig';
import PropertyCard from './PropertyCard';
import { usePolicy } from './usePolicy';

// Get the number of rows and columns required to show all properties. Might need responsive logic.
const getGridSize = (
  settings: INumberGridSettings
): { width: number; height: number } => {
  const largeCount = settings.cards.filter(x => x.size === 'large').length;
  const mediumCount = settings.cards.filter(x => x.size === 'medium').length;

  const height = largeCount > 0 || mediumCount >= 6 ? 2 : 1;
  const width =
    largeCount + (height === 1 ? mediumCount : Math.ceil(mediumCount / 2));

  return { width, height };
};

const NumberGridWidget: IDashboardComponent<INumberGridSettings> = ({
  component,
}) => {
  const { t } = useTranslation('dashboard');
  const { settings } = component;

  const dataSource = useDataSource(settings.dataSource);

  const { cards, title } = settings;

  dataSource.getUsedAggregates?.(
    cards.map(card => card.prop),
    component.componentType
  );

  const { data, noContent, error } = useDataSourceData(
    dataSource,
    component.componentType
  );

  const gridSize = getGridSize(settings);

  const policy = usePolicy(settings.policyId);

  const isLoading = !dataSource.isInitialized || dataSource.isLoading;

  return (
    <Widget isLoading={isLoading}>
      <Widget.Header>{title}</Widget.Header>
      <Widget.Content skeleton={!dataSource.isInitialized}>
        <Box
          display="grid"
          gridTemplateColumns={`repeat(${gridSize.width}, 1fr)`}
          gridTemplateRows={`repeat(${gridSize.height}, 1fr)`}
          gap={4}
          padding={4}
          pt={0}
          height="100%"
          overflow="hidden"
        >
          {cards.map((card, i) => (
            <Box
              key={i}
              style={{
                placeSelf: 'stretch',
                gridRowEnd: card.size === 'large' ? 'span 2' : undefined,
                background: '#F4F4F9',
                padding: 12,
              }}
            >
              <PropertyCard
                key={i}
                data={data}
                propId={
                  typeof card.prop === 'string'
                    ? card.prop
                    : card.prop?.id || ''
                }
                policy={policy}
                settings={settings}
              />
            </Box>
          ))}
        </Box>

        {noContent && cards.length <= 0 && (
          <MessageOverlay message={t('no_data')} />
        )}
        {error && <MessageOverlay message={error} />}
      </Widget.Content>
    </Widget>
  );
};

export default observer(NumberGridWidget);
