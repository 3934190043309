import { Role } from 'components/Auth/Role';
import { CrudResult } from 'enums/crud-result.enum';
import {
  InsertUserResult,
  IntCreateUserDto,
  IntEditUserPasswordDto,
  IntSelectedUsersBulkUpdateDto,
  IntSelectedUsersDto,
  IntUserCreateInfoDto,
  IntUserModelDto,
  IntUserTableDto,
} from 'generated';
import {
  DeleteEndpoint,
  GetEndpoint,
  PatchEndpoint,
  PostEndpoint,
  PutEndpoint,
  TableEndpoint,
} from 'shared/interfaces/api';
import { userDemoData } from './demoData/userDemoData';

interface IUserAPI {
  getAll: TableEndpoint<IntUserTableDto>;
  getUserCreateInfo: GetEndpoint<string | null, IntUserCreateInfoDto>;
  getUserFormInfo: GetEndpoint<string, IntUserModelDto>;
  getUserRoles: GetEndpoint<string, Role[]>;
  createUser: PostEndpoint<undefined, IntCreateUserDto, InsertUserResult>;
  editUser: PutEndpoint<string, IntUserModelDto, InsertUserResult>;
  changePassword: PatchEndpoint<
    undefined,
    IntEditUserPasswordDto,
    InsertUserResult
  >;
  deleteUser: DeleteEndpoint<string>;
  inviteUser: PostEndpoint<
    undefined,
    IntSelectedUsersDto,
    IntSelectedUsersBulkUpdateDto
  >;
  selectedLanguage: PutEndpoint<
    {
      userId: string;
      languageCulture: string;
    },
    undefined,
    CrudResult
  >;
  lockout: PutEndpoint<string>;
  unlock: PutEndpoint<string>;
}

const baseUrl = '/api/users';

export const userAPI: IUserAPI = {
  getAll: {
    url: `${baseUrl}/table`,
    mockData: userDemoData.getUserTable,
  },
  getUserCreateInfo: {
    url: customerId =>
      `${baseUrl}/GetUserCreateInfo?${
        customerId && `customerId=${customerId}`
      }`,
    mockData: userDemoData.getUserCreateInfo,
  },
  getUserFormInfo: {
    url: id => `${baseUrl}/${id}/formInfo`,
    mockData: userDemoData.getUserFormInfo,
  },
  getUserRoles: {
    url: id => `${baseUrl}/${id}/roles`,
    mockData: () => ({
      status: 200,
      data: Object.values(Role).filter(
        role => role !== Role.RoleNameStaticDemo
      ),
    }),
  },
  createUser: {
    url: `${baseUrl}`,
    mockData: () => ({
      status: 200,
    }),
  },
  editUser: {
    url: userId => `${baseUrl}/${userId}`,
    mockData: () => ({
      status: 200,
    }),
  },
  changePassword: {
    url: `${baseUrl}/editPassword`,
    mockData: () => ({
      status: 200,
    }),
  },
  deleteUser: {
    url: userId => `${baseUrl}/${userId}`,
    mockData: () => ({
      status: 200,
    }),
  },
  inviteUser: {
    url: `${baseUrl}/invite/AzureAD`,
    mockData: () => ({
      status: 200,
    }),
  },
  selectedLanguage: {
    url: params =>
      `${baseUrl}/${params.userId}/LanguageCode/${params.languageCulture}`,
    mockData: ({ languageCulture }) => {
      localStorage.setItem('languageCulture', languageCulture);
      return {
        status: 200,
        data: CrudResult.Success,
      };
    },
  },
  lockout: {
    url: userId => `${baseUrl}/${userId}/lockout`,
  },
  unlock: {
    url: userId => `${baseUrl}/${userId}/unlock`,
  },
};
