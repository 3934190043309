import { Box, Drawer, useTheme } from '@mui/material';
import { aiTrackEvent } from 'appInsights';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React from 'react';
import NavMenuContent from './NavMenuContent';
import { useStyles } from './SideNav.styles';
import SideNavCloser, { closerWidth } from './SideNavCloser/SideNavCloser';
import SideNavFooter from './SideNavFooter/SideNavFooter';

interface IProps {
  isOpen: boolean;
  toggle: () => void;
}

const SideNav: React.FC<IProps> = ({ isOpen, toggle }) => {
  const theme = useTheme();
  const classes = useStyles();
  const { topMenu, navMenu } = theme.settings.components;
  const topMenuHeight = topMenu.height;
  const padding = `${closerWidth}px`;
  const alignSideNavToScreenRight = navMenu.position === 'right';
  const paddingLeft = !alignSideNavToScreenRight ? undefined : padding;
  const paddingRight = alignSideNavToScreenRight ? undefined : padding;

  const aiTrackClicksToCollapseMenu = () => {
    aiTrackEvent('Side Navigation Collapse');
  };

  return (
    <Box
      position="relative"
      paddingLeft={paddingLeft}
      paddingRight={paddingRight}
      height={`calc(100vh - ${topMenuHeight}px)`}
      marginTop={`${topMenuHeight}px`}
      zIndex={1100}
    >
      <Drawer
        variant="permanent"
        className={classNames(classes.drawer, {
          [classes.drawerOpen]: isOpen,
        })}
        classes={{
          paper: classNames(classes.paper, {
            [classes.drawerOpen]: isOpen,
          }),
        }}
        open={isOpen}
        anchor={alignSideNavToScreenRight ? 'right' : 'left'}
      >
        <Box
          className={classes.contentBox}
          display="flex"
          flexDirection="column"
          flex="1 1 auto"
          justifyContent="space-between"
          height="100%"
          bgcolor={navMenu.background}
        >
          <NavMenuContent />

          {theme.settings.components.navMenu.showFooter && <SideNavFooter />}
        </Box>
        <SideNavCloser
          onClick={() => {
            toggle();
            aiTrackClicksToCollapseMenu();
          }}
          isOpen={isOpen}
          alignLeft={alignSideNavToScreenRight}
        />
      </Drawer>
    </Box>
  );
};

export default observer(SideNav);
