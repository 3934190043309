import * as azureMaps from 'azure-maps-control';
import { IMapFeatureGrouped, IMapFeatureProperties } from '../../ImagePinLayer';

export function useUpdateMapDataSourceFeatures(
  source: azureMaps.source.DataSource,
  features: IMapFeatureGrouped[],
  selectedFeatureId?: string
) {
  // Add/update shapes from features
  features.forEach(point => {
    const existingFeature = source.getShapeById(point.id);

    const properties: IMapFeatureProperties<any> = {
      ...point.properties,
      isSelected: selectedFeatureId === point.id,
    };

    if (!existingFeature) {
      const feature = new azureMaps.data.Feature(
        new azureMaps.data.Point(point.position),
        properties,
        point.id
      );
      source.add(feature);
    } else {
      existingFeature.setProperties(properties);
      existingFeature.setCoordinates(point.position);
    }
  });

  // Delete removed features
  source.getShapes().forEach((shape: any) => {
    const shapeId = shape.getId();
    if (!features.find(feature => feature.id === shapeId)) {
      source.remove(shape);
    }
  });
}
