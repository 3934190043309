import { ICustomPolicyCellProps } from 'components/Table/Columns/Helpers/CustomCellProps';
import { IntDashboardDataStatusDto } from 'generated';
import { Column } from 'react-table';
import { IDataProperty } from 'views/Dashboard/DataSources/dataPropTypes';
import {
  sortArrayByStatusRuleColor,
  sortByStatusRuleColor,
} from 'views/Dashboard/DataStatus/sortByStatusRuleColor';
import {
  getActiveNumberStatusRule,
  getActiveStringStatusRule,
} from 'views/Dashboard/DataStatus/useActiveStatusRule';
import { CustomPolicyCell } from '../Helpers/CustomPolicyCell';

export function policyColumn<T>(
  options: {
    id: string;
    accessor: (row: T) => number | null;
    header?: string;
    defaultShow?: boolean;
    filterable?: boolean;
    dataProp: IDataProperty;
  },
  policyStatus: IntDashboardDataStatusDto
): Column<T> {
  const {
    id,
    header = 'Status',
    dataProp,
    accessor,
    defaultShow: show = true,
    filterable,
  } = options;

  const getCellProps = (value: number | string): ICustomPolicyCellProps => {
    const rule =
      typeof value === 'string'
        ? getActiveStringStatusRule(policyStatus, value)
        : getActiveNumberStatusRule(policyStatus, value);

    return {
      rule,
      dataProp: dataProp,
      value: value,
    };
  };

  const getWorstValue = (values: (number | null)[]) => {
    const sortvaluesByColor = sortArrayByStatusRuleColor(
      values,
      value => getActiveNumberStatusRule(policyStatus, value)?.color,
      value => value
    );

    return sortvaluesByColor[0];
  };

  return {
    id,
    Header: header,
    accessor,
    resizable: true,
    maxWidth: 130,
    filterable,
    show,
    className: 'text-right',
    Cell: row => (
      <CustomPolicyCell {...getCellProps(row.value)} index={row.index} />
    ),
    sortMethod: sortByStatusRuleColor(
      value => getActiveNumberStatusRule(policyStatus, value)?.color,
      value => value
    ),
    aggregate: values => getWorstValue(values),
    Aggregated: row => (
      <CustomPolicyCell {...getCellProps(row.value)} index={row.index} />
    ),
  };
}
