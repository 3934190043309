import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    uploadedImage: {
      maxWidth: '100%',
      maxHeight: 30,
    },
  })
);
