import { PointOptionsObject } from 'highcharts';
import { ILineChartGroup } from './dataForAverageChartSeries';
import { ILineChartSettings, IPlotBand } from './lineChartConfig';

export function getChartWarnings(
  groups: ILineChartGroup[],
  settings: ILineChartSettings
): PointOptionsObject[] {
  const warnings: PointOptionsObject[] = [];
  const warningsData: IPlotBand | undefined = settings.yAxis?.plotBands?.find(
    plotBand => plotBand.color === 'red'
  );

  groups.forEach(group => {
    const groupName = group.name;
    group.data.forEach(y => {
      if (y.y && y.x) {
        if (warningsData?.from !== undefined && warningsData.to !== undefined) {
          if (y.y > warningsData.from && y.y < warningsData.to) {
            warnings.push({
              name: groupName,
              x: y.x,
              y: y.y,
            });
          }
        }
      }
    });
  });

  return warnings;
}
