import { Radio } from '@mui/material';
import { Field, FieldProps } from 'formik';
import { Column } from 'react-table';

export function radio<T>(name: string, valueKey: string & keyof T): Column<T> {
  return {
    id: 'selectRadio',
    className: 'p-0',
    accessor: () => '',
    resizable: false,
    sortable: false,
    Cell: row => (
      <Field name={name} type="radio" value={row.original}>
        {({ field, meta }: FieldProps) => {
          return (
            <Radio
              color="primary"
              size="small"
              style={{
                padding: 5,
              }}
              value={row.original}
              readOnly
              checked={
                meta.value
                  ? meta.value[valueKey] === row.original[valueKey]
                  : false
              }
            />
          );
        }}
      </Field>
    ),
    filterable: false,
    Filter: undefined,
    width: 34,
  };
}
