import { IntServiceDataSpecificationFilterDto } from 'generated';
import { useEffect, useState } from 'react';
import { useStores } from 'store';
import { useDashboardContext } from 'views/Dashboard/contexts/dashboardContext';
import { getDataSource } from 'views/Dashboard/DataSources';
import { IDataSourceStore } from 'views/Dashboard/DataSources/dataSourceTypes';

export interface ILocationDataSource {
  latitudeId: string;
  longitudeId: string;
  dataSource: IDataSourceStore;
}

// Finds the "location" service, loads its serviceProperties, and initializes a serviceLatest data source
export function useCurrentLocationDataSource(
  serviceDataFilters?: IntServiceDataSpecificationFilterDto[]
): ILocationDataSource | undefined {
  const [data, setData] = useState<ILocationDataSource>();
  const { dashboardStore } = useStores();
  const dashboard = useDashboardContext();

  useEffect(() => {
    let isMounted = true;

    const loadLocationService = async () => {
      await dashboardStore.loadServiceOptions();

      const locationService = dashboardStore.serviceOptions?.find(
        service => service.displayName?.toLowerCase() === 'location'
      );

      const latitudeId = locationService?.properties.find(
        prop => prop.displayName?.toLowerCase() === 'latitude'
      )?.servicePropertyId;

      const longitudeId = locationService?.properties.find(
        prop => prop.displayName?.toLowerCase() === 'longitude'
      )?.servicePropertyId;

      if (!locationService || !latitudeId || !longitudeId) {
        throw new Error('Location service not found');
      }

      const dataSource = getDataSource(dashboard, {
        type: 'serviceLatest',
        serviceDataFilters: serviceDataFilters || [],
      });

      if (dataSource.initialize) {
        await dataSource.initialize();
      }

      isMounted && setData({ dataSource, latitudeId, longitudeId });
    };
    loadLocationService();

    return () => {
      isMounted = false;
    };
  }, [dashboardStore, dashboard, serviceDataFilters]);

  return data;
}
