import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {},
    reactTable: {
      '& .rt-tr-group': {
        flexGrow: 0,
      },
      '& .rt-tbody': {
        backgroundColor: '#f9f9f9',
      },
      '& .rt-thead.-filters input': {
        border: 'none',
      },
    },
  })
);
