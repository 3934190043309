import { ServiceDataFilterType } from 'generated';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'store';
import { IDashboardFilterSettings } from './filtersConfig';

export function useGetFilterDisplayName(): (
  filter: IDashboardFilterSettings
) => string {
  const { t } = useTranslation('dashboard');
  const {
    dynamicAttributeStore: { definitions },
  } = useStores();

  return useCallback(
    filter => {
      if (filter.entity === 'asset') {
        if (
          filter.type === ServiceDataFilterType.DynamicAttribute &&
          filter.definitionId
        ) {
          return (
            definitions.find(def => def.id === filter.definitionId)?.name ||
            filter.definitionId
          );
        }

        return t(`filter.service_data_filter_type.${filter.type}`);
      }
      return ''; // Won't happen
    },
    [t, definitions]
  );
}
