import { useTheme } from '@mui/material';
import classNames from 'classnames';
import Highcharts from 'components/Highcharts';
import MessageOverlay from 'components/MessageOverlay';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { IDashboardComponent } from 'views/Dashboard/dashboardTypes';
import { useDataSource, useDataSourceData } from 'views/Dashboard/DataSources';
import { getDataPropId } from 'views/Dashboard/DataSources/getDataProp';
import { getActiveNumberStatusRule } from 'views/Dashboard/DataStatus/useActiveStatusRule';
import Widget from 'views/Dashboard/Widget';
import { usePolicy } from '../NumberGridComponent/usePolicy';
import { IGaugeSettings } from './gaugeConfig';
import { useStyles } from './GaugeWidget.styles';

const innerRadius = '65%';

const gaugeColors: Record<string, string> = {
  green: '#00d066',
  yellow: '#ffd06b',
  red: '#e4175c',
};

function useGaugeColor(
  settings: IGaugeSettings,
  value: number | undefined
): string {
  const defaultColor = useTheme().palette.primary.main;
  const policy = usePolicy(settings.policyId);

  if (!value) {
    return defaultColor;
  }

  // No policy selected, fallback to old range logic
  if (!settings.policyId) {
    return getLegacyGaugeColor(settings, value) || defaultColor;
  }

  const status = policy?.statuses?.find(
    s => s.servicePropertyId === getDataPropId(settings.valueProp)
  );

  if (!status) {
    return defaultColor;
  }

  const activeRule = getActiveNumberStatusRule(status, value);

  if (activeRule?.color) {
    return gaugeColors[activeRule.color] || defaultColor;
  }

  return defaultColor;
}

function getLegacyGaugeColor(settings: IGaugeSettings, value: number) {
  const getColorIfWithinBounds = (
    color: string,
    { min, max }: { min?: number; max?: number }
  ) => {
    if (
      typeof min !== 'number' ||
      typeof max !== 'number' ||
      min >= max ||
      value < min ||
      value > max
    ) {
      return '';
    }
    return color;
  };

  return (
    getColorIfWithinBounds('#00d066', settings.colors.green) ||
    getColorIfWithinBounds('#ffd06b', settings.colors.yellow) ||
    getColorIfWithinBounds('#ff9b00', settings.colors.orange) ||
    getColorIfWithinBounds('#e4175c', settings.colors.red)
  );
}

function getGaugeLabelSize(
  settings: IGaugeSettings
): 'small' | 'medium' | 'large' {
  const { position } = settings;

  if (!position) {
    return 'medium';
  }

  const minDim = Math.min(position.w, position.h);

  if (minDim <= 3) {
    return 'small';
  }
  if (minDim <= 4) {
    return 'medium';
  }
  return 'large';
}

const GaugeWidget: IDashboardComponent<IGaugeSettings> = ({ component }) => {
  const { t } = useTranslation('dashboard');
  const classes = useStyles();
  const { settings } = component;

  const dataSource = useDataSource(settings.dataSource);

  if (dataSource.getUsedAggregates) {
    dataSource.getUsedAggregates([settings.valueProp]);
  }

  const { data, noContent, error } = useDataSourceData(
    dataSource,
    component.componentType
  );

  const aggregateData: number[] =
    data?.type === 'aggregatedData' ? Object.values(data?.result) : [];

  const color = useGaugeColor(settings, aggregateData[0]);

  const labelSize = getGaugeLabelSize(settings);

  const getDecimals =
    dataSource.dataProperties.find(
      p => p.id === getDataPropId(settings.valueProp)
    )?.decimals ?? 1;

  const chartOptions: Highcharts.Options = {
    chart: {
      spacingTop: 0,
      spacingLeft: 0,
      spacingRight: 0,
      spacingBottom: 0,
      type: 'solidgauge',
    },
    colors: [color],
    title: {
      text: undefined,
      align: 'center',
    },
    credits: { enabled: false },
    tooltip: { enabled: false },
    pane: {
      center: ['50%', '80%'],
      size: '140%',
      startAngle: -90,
      endAngle: 90,
      background: [],
    },
    plotOptions: {
      solidgauge: {
        dataLabels: {
          y: 10,
          allowOverlap: true,
          borderWidth: 0,
          useHTML: true,
          enabled: true,
        },
      },
    },

    yAxis: {
      min: settings.valueMin,
      max: settings.valueMax,
      startOnTick: false,
      endOnTick: false,
      labels: {
        formatter() {
          return `${this.value}${settings.valueUnit}`;
        },
        enabled: true,
        y: 18,
        style: {
          color: '#9b9b9b',
          fontSize: { small: '12px', medium: '14px', large: '16px' }[labelSize],
        },
      },

      tickAmount: 0,
      minorTickInterval: undefined,
      tickInterval: undefined,
      tickColor: '#454545',
      minorTickColor: '#3c3c3c78',
      lineWidth: 0,
      tickPositions: [settings.valueMin, settings.valueMax],

      plotBands: [
        // This is the gray gauge background (a slightly hacky solution to an issue with flickering on updates)
        {
          from: settings.valueMin,
          to: settings.valueMax,
          innerRadius,
          outerRadius: '100%',
          borderColor: '#dcdcdc',
          borderWidth: 0,
          color: '#cccccc',
        },
        // This is a second, slightly transparent, outline to the gauge value
        {
          from: settings.valueMin,
          to: aggregateData[0] ?? settings.valueMin,
          innerRadius,
          outerRadius: '108%',
          color,
          className: classes.outerGaugeTrend,
        },
      ],
    },
    series: [
      {
        type: 'solidgauge',
        data: aggregateData || [],
        innerRadius,
        color: '#990ae3',
        dataLabels: {
          enabled: true,
          useHTML: true,
          y: { small: -38, medium: -40, large: -52 }[labelSize],
          format: `<div class="${classNames(classes.label, {
            [classes.smallLabel]: labelSize === 'small',
            [classes.mediumLabel]: labelSize === 'medium',
            [classes.largeLabel]: labelSize === 'large',
          })}">{y:.${getDecimals}f}<span class="${classes.unit}">${
            settings.valueUnit
          }</span></div>`,
        },
      },
    ],
  };

  return (
    <Widget
      isLoading={!dataSource.isInitialized || dataSource.isLoading}
      widgetType="GaugeWidget"
    >
      <Widget.Header>{settings.title}</Widget.Header>

      <Widget.Content skeleton={!dataSource.isInitialized || !chartOptions}>
        {chartOptions && (
          <Highcharts
            options={chartOptions}
            containerProps={{
              style: {
                height: '100%',
              },
            }}
          />
        )}
        {noContent && <MessageOverlay message={t('no_data')} />}
        {error && <MessageOverlay message={error} />}
      </Widget.Content>
    </Widget>
  );
};

export default observer(GaugeWidget);
