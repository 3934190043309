import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { Option } from 'components/FormFields';
import { observer } from 'mobx-react-lite';
import { useDashboardContext } from '../contexts/dashboardContext';
import { IControlProps } from './controlProps';

interface IProps<TValue> {
  options: Option<TValue>[];
  defaultValue?: TValue;
}

function RadioGroupControl<TObj, TValue = unknown>(
  props: IControlProps<TObj, TValue> & IProps<TValue>
) {
  const { name, object, label, options, defaultValue } = props;

  const { updateSetting } = useDashboardContext();

  const value = object[name] ?? defaultValue;

  const selectedIndex = options.findIndex(o => o.value === value);

  return (
    <FormControl fullWidth component="fieldset">
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <RadioGroup
        color="primary"
        row
        name={name}
        value={selectedIndex}
        onChange={(_e, newIndex) =>
          updateSetting(object, name, options[parseInt(newIndex)].value)
        }
      >
        {options?.map((opt, i) => (
          <FormControlLabel
            key={`${name}_${opt.value}`}
            value={i}
            control={<Radio color="primary" />}
            label={opt.label}
            disabled={opt.disabled}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

export default observer(RadioGroupControl);
