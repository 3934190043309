import { Box, Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import ComponentContainer from 'views/Dashboard/ComponentContainer';
import { IDashboardComponent } from 'views/Dashboard/dashboardTypes';
import { useDataSource } from 'views/Dashboard/DataSources';
import { Control } from 'views/Dashboard/SettingControls';
import PolicyControl from '../../NumberGridComponent/SettingsSteps/PolicyControl';
import PopupDetailsSettings from '../../PopupDetailsSettings';
import { ILineChartSettings } from '../lineChartConfig';

function shouldShowLegacyPlotbands(settings: ILineChartSettings) {
  return (
    (!settings.policyId &&
      !!settings.yAxis.plotBands?.find(
        pb => pb.from !== undefined || pb.to !== undefined
      )) ||
    settings.dataSource.type === 'analyticsCube'
  );
}

const GeneralSettingsStep: IDashboardComponent<ILineChartSettings> = ({
  component,
}) => {
  const { t } = useTranslation('dashboard');
  const { settings } = component;
  const dataSource = useDataSource(settings.dataSource);

  return (
    <Grid container spacing={4}>
      <Grid item sm={6}>
        {settings.dataSource.type !== 'analyticsCube' && (
          <Control.Group label={t('general_settings')}>
            <Control.DataProperty
              object={settings.yAxis}
              name="prop"
              label={t('line_chart.yaxis.property')}
              options={dataSource.dataProperties || []}
              allowDate={false}
              allowString={false}
            />
          </Control.Group>
        )}

        <Control.Group label={t('line_chart.yaxis.type')}>
          <Control.RadioGroup
            object={settings.yAxis}
            name="step"
            options={[
              { value: false, label: t('line_chart.yaxis.step_false') },
              { value: true, label: t('line_chart.yaxis.step_true') },
            ]}
            defaultValue={false}
          />
        </Control.Group>

        <Control.Group label={t('popup_settings.label.add_info')}>
          <PopupDetailsSettings
            rows={settings.popup.rows}
            dataSource={dataSource}
          />
        </Control.Group>

        {shouldShowLegacyPlotbands(settings) && (
          <Control.Group label={t('line_chart.plot_bands')}>
            <Control.PlotBands object={settings.yAxis} name="plotBands" />
          </Control.Group>
        )}

        <Control.Group label={t('line_chart.yaxis.static_range')}>
          <Grid container spacing={4}>
            <Grid item sm={6}>
              <Control.Number
                object={settings.yAxis}
                name="min"
                label={t('value.min')}
              />
            </Grid>
            <Grid item sm={6}>
              <Control.Number
                object={settings.yAxis}
                name="max"
                label={t('value.max')}
              />
            </Grid>
          </Grid>
        </Control.Group>
      </Grid>

      <Grid item sm={6}>
        <Control.Group label={t('preview')}>
          <Box height={300}>
            <ComponentContainer component={component} />
          </Box>
        </Control.Group>

        <PolicyControl settings={settings} />
      </Grid>
    </Grid>
  );
};

export default observer(GeneralSettingsStep);
