import { DialogTitle, IconButton, Typography } from '@mui/material';
import { aiTrackEvent } from 'appInsights';
import AtlasIcon from 'components/AtlasIcon';
import React, { ReactElement, useEffect } from 'react';
import { useStyles } from './DialogTitleWrapper.styles';

interface IProps {
  id?: string;
  title: string;
  rightButton?: ReactElement;
  onClose?: () => void;
  centerContent?: React.ReactNode;
}

const DialogTitleWrapper: React.FC<IProps> = ({
  id,
  title,
  onClose,
  rightButton: button,
  centerContent,
}) => {
  const classes = useStyles();

  useEffect(() => {
    aiTrackEvent('Open Wizard/Modal', { title });
  }, [title]);

  return (
    <DialogTitle id={id} className={classes.dialogTitle}>
      <Typography variant="h3" component="div" data-cy={id ?? 'title'}>
        {title}
      </Typography>
      {centerContent}
      <div>
        {button}
        {onClose && (
          <IconButton
            className={classes.button}
            color="inherit"
            onClick={onClose}
            size="large"
            data-cy="closeModal"
          >
            <AtlasIcon type="Close" />
          </IconButton>
        )}
      </div>
    </DialogTitle>
  );
};

export default DialogTitleWrapper;
