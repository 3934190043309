import { Button, ButtonGroup, FormControl, FormLabel } from '@mui/material';
import { Option } from 'components/FormFields';
import { observer } from 'mobx-react-lite';
import { useDashboardContext } from '../contexts/dashboardContext';
import { IControlProps } from './controlProps';

interface IProps<TValue> {
  options: Option<TValue>[];
}

function ButtonGroupControl<TObj, TValue extends string>(
  props: IControlProps<TObj, TValue> & IProps<TValue>
) {
  const { name, object, label, options } = props;

  const { updateSetting } = useDashboardContext();

  const value: string = (object[name] as any) || '';

  return (
    <FormControl fullWidth component="fieldset">
      <FormLabel component="legend">{label}</FormLabel>
      <ButtonGroup color="primary">
        {options?.map(opt => (
          <Button
            key={opt.value}
            value={opt.value}
            variant={value === opt.value ? 'contained' : 'outlined'}
            onClick={() => updateSetting(object, name, opt.value)}
          >
            {opt.label}
          </Button>
        ))}
      </ButtonGroup>
    </FormControl>
  );
}

export default observer(ButtonGroupControl);
