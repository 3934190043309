import { action, makeObservable, observable, runInAction } from 'mobx';
import { smsService } from 'services/sms.service';
import { SmsCustomer } from './domains/smsCustomer';
import { RootStore } from './rootStore';
import { StoreBase } from './storeBase';

export class SmsStore extends StoreBase {
  @observable defaultProfile?: SmsCustomer;
  @observable error: string | undefined;

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }

  @action.bound async getDefaultProfile() {
    const customerId = this.rootStore.authStore.user?.customerId;
    if (customerId) {
      const resp = await this.httpGet(smsService.getDefaultProfile, customerId);

      runInAction(() => {
        if (resp.status === 200 && resp.data) {
          this.defaultProfile = new SmsCustomer(this.rootStore, resp.data);
        } else {
          runInAction(() => {
            this.error = resp?.statusText;
          });
        }
      });
    }
  }
}
