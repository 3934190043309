import { createStyles, makeStyles } from '@mui/styles';
import { closerWidth } from './SideNavCloser';

export const useStyles = makeStyles(theme => {
  const { navMenu } = theme.settings.components;
  return createStyles({
    root: {
      position: 'relative',
      zIndex: 1,
      width: navMenu.closerWidth,
      background: 'transparent',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    button: {
      background: 'transparent',
      height: 78,
      width: '100%',
      cursor: 'pointer',
      transform: 'none',
      '&::before': {
        position: 'absolute',
        content: '""',
        height: '100%',
        left: 0,
        border: `${closerWidth}px solid transparent`,
        borderLeftColor: navMenu.background,
        transform: 'none',
        transition: theme.transitions.create(['transform'], {
          easing: theme.transitions.easing.sharp,
          duration: 80,
        }),
      },
      '&:hover::before': {
        transform: 'none',
        transition: theme.transitions.create(['transform'], {
          easing: theme.transitions.easing.sharp,
          duration: 320,
          delay: 0,
        }),
      },
      '& svg': {
        transform: 'rotateY(180deg)',
        color: '#FFF',
        transition: theme.transitions.create(['transform', 'color'], {
          easing: theme.transitions.easing.sharp,
          duration: 80,
          delay: 0,
        }),
      },
      '&:hover svg': {
        color: '#FFF',
        transform: 'rotateY(180deg)',
        transition: theme.transitions.create(['transform', 'color'], {
          easing: theme.transitions.easing.sharp,
          duration: 80,
        }),
      },
    },
    buttonOpen: {
      '&::before': {},
      '&:hover::before': {},
      '& svg': {
        color: '#FFF',
        transform: 'translateX(0) scaleY(1)',
        transition: theme.transitions.create(['transform', 'opacity'], {
          easing: theme.transitions.easing.sharp,
          duration: 80,
        }),
      },
      '&:hover svg': {
        color: '#FFF',
        opacity: 1,
        transition: theme.transitions.create(['transform', 'opacity'], {
          easing: theme.transitions.easing.sharp,
          duration: 80,
        }),
        transform: 'translateX(-0px) scaleY(1.05)',
      },
    },
    buttonAlignLeft: {
      transform: 'rotate(180deg)',
    },
  });
});
