import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    '& button': {
      marginLeft: theme.spacing(2),
    },
  },
}));
