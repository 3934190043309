import flatten, { unflatten } from 'flat';
import { IntDashboardComponentSettingDto } from 'generated';
import { IWidgetSettings } from './ComponentTypes/IWidgetSettings';

// Parse a list of flattened key-value strings to a JS object
export function parseDashboardComponentSettings<T = IWidgetSettings>(
  settings: IntDashboardComponentSettingDto[]
): T {
  const obj: any = {};
  settings.forEach(({ key, value }) => {
    obj[key] = JSON.parse(value);
  });

  return unflatten(obj) as T;
}

// Convert from nested object to list with key-value strings
// Example: { columns: ['loremIpsum'] } => [{ key: 'columns.1', value: '"loremIpsum"' }]
export function flattenDashboardComponentSettings<T>(
  settings: T
): IntDashboardComponentSettingDto[] {
  const flattenedSettings = flatten<T, object>(settings);

  return Object.entries(flattenedSettings)
    .map(
      ([key, value]): IntDashboardComponentSettingDto => ({
        key,
        value: JSON.stringify(value),
      })
    )
    .filter(setting => setting.value !== undefined);
}
