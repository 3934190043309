import { Box, Portal } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
interface IProps {
  isModal: boolean;
  centerButtonsRef?: HTMLElement | null;
}

const AssetCountButtonContainer: React.FC<IProps> = ({
  isModal = true,
  centerButtonsRef,
  children,
}) => {
  return isModal ? (
    <Portal container={centerButtonsRef}>{children} </Portal>
  ) : (
    <Box maxWidth={250}>{children}</Box>
  );
};

export default observer(AssetCountButtonContainer);
