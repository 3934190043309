import { Option } from 'components/FormFields';
import { AutoComplete } from 'components/Inputs';
import i18n from 'i18n';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useDashboardContext } from '../contexts/dashboardContext';
import {
  DataPropSetting,
  IDataProperty,
  ServiceAggregationType,
} from '../DataSources/dataPropTypes';
import { getDataPropId } from '../DataSources/getDataProp';
import { IControlProps } from './controlProps';

interface IProps {
  options: IDataProperty[];
  clearable?: boolean;

  allowString?: boolean;
  allowDate?: boolean;
  allowNumber?: boolean;
  allowBoolean?: boolean;
}

const aggOptions: Option<ServiceAggregationType>[] = [
  {
    label: i18n.t('dashboard:aggregation.min'),
    value: 'min',
  },
  {
    label: i18n.t('dashboard:aggregation.max'),
    value: 'max',
  },
  {
    label: i18n.t('dashboard:aggregation.avg'),
    value: 'avg',
  },
];

function DataPropertyControl<TObj>(
  props: IControlProps<TObj, DataPropSetting> & IProps
) {
  const {
    name,
    label,
    object,
    options,
    clearable,
    allowString = true,
    allowDate = true,
    allowNumber = true,
    allowBoolean = true,
  } = props;
  const { t } = useTranslation('dashboard');
  const { updateSetting } = useDashboardContext();

  if (!object) {
    return null;
  }

  const value = object[name] as any as DataPropSetting;

  const id = getDataPropId(value);
  const agg: ServiceAggregationType =
    (typeof value === 'object' && value.agg) || 'avg';

  const selectedDataProp = options.find(dp => dp.id === id);

  const handleChangePropId = (newId: string | null) => {
    updateSetting<DataPropSetting>(object, name, {
      id: newId || '',
      agg,
    });
  };

  const handleAggType = (newAgg: ServiceAggregationType | null) => {
    updateSetting<DataPropSetting>(object, name, {
      id,
      agg: newAgg || 'avg',
    });
  };

  return (
    <>
      <AutoComplete
        dataCy={`generalSettings-${name}`}
        label={label || ''}
        options={options}
        getOptionLabel={dp => dp.name}
        getOptionValue={dp => dp.id}
        value={id}
        groupBy={dp => dp.category?.displayName || ''}
        onChange={handleChangePropId}
        clearable={clearable}
        getOptionDisabled={opt =>
          (!allowString && opt.type === 'string') ||
          (!allowDate && opt.type === 'dateTime') ||
          (!allowNumber && opt.type === 'number') ||
          (!allowBoolean && opt.type === 'boolean')
        }
      />
      {selectedDataProp?.aggregations && (
        <AutoComplete
          dataCy={`generalSettings-${name}`}
          label={t('aggregation.base')}
          options={aggOptions}
          getOptionLabel={dp => dp.label}
          getOptionValue={dp => dp.value}
          value={agg}
          onChange={handleAggType}
        />
      )}
    </>
  );
}

export default observer(DataPropertyControl);
