import { Box, Typography } from '@mui/material';
import React from 'react';
import DropdownLink from './../DropdownLink/DropdownLink';
import { useStyles } from './DropdownMessage.styles';

const DropdownMessage: React.FC<{
  icon: JSX.Element;
  label: string;
  to: string;
  exact?: boolean;
  compact?: boolean;
  headerText: string;
}> = ({ label, to, icon, exact, compact, headerText }) => {
  const classes = useStyles();

  return (
    <DropdownLink to={to} exact={exact} compact={compact}>
      <Typography gutterBottom variant="button">
        <strong>{headerText}</strong>
      </Typography>

      <Box width={1} display="flex">
        <Box className={classes.iconWrapper}>{icon}</Box>
        <Box width={1} pl={2} display="flex" alignItems="center">
          <Typography variant="body2">
            <strong>{label}</strong>
          </Typography>
        </Box>
      </Box>
    </DropdownLink>
  );
};

export default DropdownMessage;
