import { IntMenuItemDto } from 'generated';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { INavigationMenuState } from '../useNavMenuState';
import MenuItem from './MenuItem';

interface IProps {
  navMenuState: INavigationMenuState;
  parent: IntMenuItemDto;
}

const SubCategory: React.FC<IProps> = ({ parent, navMenuState }) => (
  <>
    <MenuItem
      item={parent}
      variant="categoryHeader"
      navMenuState={navMenuState}
      isRoot
    />

    {parent.children.map(item => (
      <MenuItem key={item.menuItemId} item={item} navMenuState={navMenuState} />
    ))}
  </>
);

export default observer(SubCategory);
