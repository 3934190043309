import { AutocompleteInputChangeReason } from '@mui/material/Autocomplete';
import { snackbar } from 'components/Snackbar';
import {
  IntServiceDataFilterOptionDto,
  ServiceDataFilterType,
} from 'generated';
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { serviceApi } from 'services/service.service';
import { RootStore } from 'store/rootStore';
import { StoreBase } from 'store/storeBase';

const pageSize = 100;

export class AssetSelectState<
  TObject extends { assetId: string },
> extends StoreBase {
  _settingsObject: TObject;

  constructor(rootStore: RootStore, settingsObject: TObject) {
    super(rootStore);
    this._settingsObject = settingsObject;

    makeObservable(this);

    this.loadOptions();

    if (this.assetId) {
      this._selectedOption = {
        id: this.assetId,
        name: '',
        hierarchyName: '',
        children: [],
      };
      this.loadSelectedOption();
    } else {
      this.hasLoadedInitialValue = true; // Nothing to load
    }
  }

  @observable hasLoadedInitialValue = false;
  @observable _selectedOption: IntServiceDataFilterOptionDto | undefined;

  @computed get assetId() {
    return this._settingsObject.assetId;
  }

  @observable.shallow _options: IntServiceDataFilterOptionDto[] = [];

  @computed get options(): IntServiceDataFilterOptionDto[] {
    const options: IntServiceDataFilterOptionDto[] = [...this._options];

    if (
      this.assetId &&
      this._selectedOption &&
      !options.find(opt => opt.id === this.assetId)
    ) {
      // It's not loaded, insert it so it immediately shows up in the dropdown
      options.splice(0, 0, this._selectedOption);
    }

    return options;
  }

  @observable hasMore = false;
  page = 0;

  @computed get selectedOption(): IntServiceDataFilterOptionDto | null {
    if (!this.assetId) {
      return null;
    }

    return this.options.find(o => o.id === this.assetId) || null;
  }

  @action.bound async loadSelectedOption() {
    if (this.assetId) {
      const resp = await this.httpPost(serviceApi.getFilterOptions, {
        params: undefined,
        data: {
          filters: [
            { type: ServiceDataFilterType.AssetId, values: [this.assetId] },
          ],
          page: 0,
          pageSize: 1,
          search: '',
          type: ServiceDataFilterType.AssetId,
          includeEntityCount: false,
        },
      });

      if (resp.status === 200 && resp.data?.filterOptions.length) {
        runInAction(() => {
          this.hasLoadedInitialValue = true;
          this._selectedOption = resp.data?.filterOptions[0];
        });
      } else {
        snackbar('errr', { variant: 'error' });
      }
    }
  }

  requestId = 0;
  @action.bound async loadOptions(page = 0) {
    this.requestId++;
    const currentRequestId = this.requestId;

    const resp = await this.httpPost(serviceApi.getFilterOptions, {
      params: undefined,
      data: {
        filters: [],
        page,
        pageSize,
        search: this.search,
        type: ServiceDataFilterType.AssetId,
        includeEntityCount: false,
      },
    });

    if (this.requestId !== currentRequestId) {
      return; // Crude cancel logic - consider improvements using cancelation tokens
    }

    if (resp.status === 200 || resp.status === 204) {
      runInAction(() => {
        this.page = page;
        const newOptions =
          resp.data?.filterOptions.filter(x => x.id !== this.assetId) || [];

        this._options =
          page > 0 ? [...this._options, ...newOptions] : newOptions;
        this.hasMore = resp.data?.hasMore || false;
      });
    } else {
      snackbar('Error loading options', { variant: 'error' });
    }
  }

  @action.bound updateSearch() {
    this.page = 0; // Start over when search changes
    this.loadOptions();
  }

  searchTimer = 0;
  @observable search = '';
  @observable searchChangeReason: AutocompleteInputChangeReason | undefined;

  @action.bound handleSearchChanged(
    newSearch: string,
    reason: AutocompleteInputChangeReason
  ) {
    this.search = newSearch;
    this.searchChangeReason = reason;

    if (reason === 'input') {
      window.clearTimeout(this.searchTimer);
      this.searchTimer = window.setTimeout(this.updateSearch, 250);
    }
  }

  @action.bound handleChange(newValue: IntServiceDataFilterOptionDto | null) {
    this._selectedOption = newValue || undefined;
    this._settingsObject.assetId = newValue?.id || '';
  }

  @action.bound fetchMore() {
    this.loadOptions(this.page + 1);
  }
}
