import { IntSmsCustomerDto } from 'generated';
import { IResponse } from 'shared/interfaces/api';
import { rnd } from 'utils';

const demoMoAtUrl = 'http://52.157.104.151/sms_receive';
const demoDlrUrl = 'http://52.157.104.151/dlr_receive';

class SmsDemoData {
  getDefaultProfile = (customerId: string): IResponse<IntSmsCustomerDto> => {
    return {
      status: 200,
      data: {
        customerId: rnd.guid(),
        customerName: rnd.customerName(),
        moUrl: demoMoAtUrl,
        dlrUrl: demoDlrUrl,
        dlrEnabled: false,
      },
    };
  };
}

export const smsDemoData = new SmsDemoData();
