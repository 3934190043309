import { Box } from '@mui/material';
import AtlasIcon from 'components/AtlasIcon';
import { DropdownMenu, DropdownMenuItem } from 'components/DropdownMenu';
import i18n from 'i18n';
import { Column } from 'react-table';

export function serviceAction<T>(options: {
  id: string;
  accessor: (row: T) => T;
  onClick: (row: T, action: 'edit' | 'delete') => void;
  header: string;
  defaultShow?: boolean;
  showEdit?: (row: T) => boolean;
  showDelete?: (row: T) => boolean;
}): Column<T> {
  const {
    id,
    accessor,
    onClick,
    header,
    defaultShow: show = true,
    showEdit,
    showDelete,
  } = options;

  return {
    id,
    filterable: false,
    sortable: false,
    resizable: false,
    width: 70,
    Header: <Box textAlign="right">{header}</Box>,
    className: 'text-right',
    show,
    accessor,
    Cell: row => {
      const isEditable = showEdit?.(row.original) ?? true;
      const isDeletable = showDelete?.(row.original) ?? true;

      return (
        <Box>
          {
            <DropdownMenu
              onItemClick={(action: 'edit' | 'delete') => {
                onClick(row.original, action);
              }}
              label=""
              hideChevron
              icon={<AtlasIcon type={'MoreHoriz'} size={16} color="primary" />}
            >
              {isEditable && (
                <DropdownMenuItem
                  label={i18n.t('common:label.edit.base')}
                  value="edit"
                />
              )}
              {isDeletable && (
                <DropdownMenuItem label={i18n.t('delete')} value="delete" />
              )}
            </DropdownMenu>
          }
        </Box>
      );
    },
  };
}
