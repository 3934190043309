import { createStyles, makeStyles } from '@mui/styles';
import classNames from 'classnames';
import AtlasIcon from 'components/AtlasIcon';
import { IntDashboardDataStatusRuleDto } from 'generated';
import React from 'react';
import { AtlasIconType } from 'shared/types/atlas-icon';

interface IStatusCircleProps {
  defaultIcon: AtlasIconType;
  rule?: IntDashboardDataStatusRuleDto;
}

const useStatusCircleStyles = makeStyles(theme =>
  createStyles({
    statusCircle: {
      borderRadius: '50%',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#707070', // Fallback in case there's no status
    },
    md: {
      width: 24,
      height: 24,
    },
    green: {
      background: theme.palette.success.main,
    },
    yellow: {
      background: theme.palette.warning.main,
    },
    red: {
      background: theme.palette.error.main,
    },
  })
);

const StatusCircle: React.FC<IStatusCircleProps> = ({ rule, defaultIcon }) => {
  const classes = useStatusCircleStyles();

  const icon = rule?.icon || defaultIcon;

  return (
    <div
      className={classNames(classes.statusCircle, classes.md, {
        [classes.green]: rule?.color === 'green',
        [classes.yellow]: rule?.color === 'yellow',
        [classes.red]: rule?.color === 'red',
      })}
    >
      {icon && (
        <AtlasIcon type={icon as AtlasIconType} htmlColor="#FFF" size={16} />
      )}
    </div>
  );
};

export default StatusCircle;
