import { Table } from 'components/Table';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { ComponentPropsGetterR } from 'react-table';
import { IWidgetData } from 'views/Dashboard/DataSources/dataSourceTypes';
import { ITableViewModel } from '../TableComponent/makeTableViewModel';
import { MapWidgetState } from './MapWidgetState';

interface IProps {
  state?: MapWidgetState;
  tableViewModel: ITableViewModel;
  data: IWidgetData;
}

const MapTable: React.FC<IProps> = ({ state, data, tableViewModel }) => {
  const parsedData = useMemo(
    () => tableViewModel.getRows(data),
    [tableViewModel, data]
  );

  const getTrProps: ComponentPropsGetterR = (_finalState, rowInfo) => {
    if (rowInfo?.original) {
      return {
        onClick: () => {
          state?.selectFeature(rowInfo.original.__id, true);
        },
      };
    }

    return {};
  };

  const getTrGroupProps: ComponentPropsGetterR = (_finalState, rowInfo) => {
    const pivotValue = rowInfo?.row.__pivot as string;
    if (pivotValue) {
      return {
        onClick: () => {
          state?.selectFeature(pivotValue, true);
        },
      };
    }

    return {};
  };

  return (
    <Table
      columns={tableViewModel.columns}
      data={parsedData.rows}
      style={{ height: '100%' }}
      showPagination={false}
      getTrProps={getTrProps}
      getTrGroupProps={getTrGroupProps}
      pivotBy={tableViewModel.pivotBy.length ? ['__pivot'] : []}
      minRows={0}
      collapseOnDataChange={false}
    />
  );
};

export default observer(MapTable);
