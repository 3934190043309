import { Box, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';

interface IProps {
  label: string;
  variant?: 'default' | 'small';
}

const useStyles = makeStyles(theme =>
  createStyles({
    small: {
      fontSize: 14,
    },
  })
);

const ControlGroup: React.FC<IProps> = ({
  label,
  variant = 'default',
  children,
}) => {
  const classes = useStyles();

  return (
    <Box mb={6} width="100%">
      <Typography
        variant="h6"
        className={classNames({
          [classes.small]: variant === 'small',
        })}
        gutterBottom
      >
        {label}
      </Typography>
      {children}
    </Box>
  );
};

export default ControlGroup;
