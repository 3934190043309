import { FormField } from 'components/FormFields';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'store';

interface IProps {
  name?: string;
}

const PolicyCustomerField: React.FC<IProps> = ({ name = 'customerId' }) => {
  const {
    customerStore: { availableCustomers, isLoading },
  } = useStores();
  const { t } = useTranslation();

  return (
    <FormField.Autocomplete
      name={name}
      label={t('customer')}
      isLoading={isLoading}
      options={availableCustomers}
      getOptionLabel={customer => customer.customerName}
      getOptionValue={customer => customer.customerId}
      required
    />
  );
};

export default observer(PolicyCustomerField);
