import { aiTrackEvent } from 'appInsights';
import { snackbar } from 'components/Snackbar';
import { IntAssetTableDto, IntCreateAssetDto, IntSearchDto } from 'generated';
import i18n from 'i18n';
import { action, makeObservable, observable } from 'mobx';
import { Column } from 'react-table';
import { assetAPI } from 'services/asset.service';
import { TableEndpoint } from 'shared/interfaces/api';
import { RootStore } from 'store/rootStore';
import { StoreBase } from 'store/storeBase';
import { validateResponse } from 'utils';
import { getTableColumnForProperty } from './dynamicAttributeStore';

type State = {
  create: boolean;
};

export class AssetStore extends StoreBase {
  @observable assetEventsDetected: boolean;

  @observable error: State = {
    create: false,
  };

  @observable loading: State = {
    create: false,
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
    this.assetEventsDetected = false;
  }

  @action.bound dynamicAttributeTableColumns =
    (): Column<IntAssetTableDto>[] => {
      return this.rootStore.dynamicAttributeStore.dynamicAttributeProperties
        .filter(prop => prop.type !== 'notFound')
        .map(prop => {
          return getTableColumnForProperty(prop);
        });
    };

  @action.bound createAsset = async (
    data: IntCreateAssetDto,
    successCallbackFn: () => void
  ) => {
    const resp = await this.httpPost(
      assetAPI.createAsset,
      {
        params: undefined,
        data,
      },
      loading => this.setLoading('create', loading)
    );

    if (resp.status === 200 || resp.status === 201) {
      snackbar(i18n.t('asset:success.create'), {
        variant: 'success',
      });

      aiTrackEvent('Create', { ttile: 'Assets' });

      this.indicateAssetEventDetected();
      successCallbackFn();
    } else {
      snackbar(i18n.t('asset:identifiers.error.create'), { variant: 'error' });
    }
  };

  @action.bound async downloadTableAsCsv<T>(
    endpoint: TableEndpoint<T>,
    search: IntSearchDto,
    fileNamePrefix: string = 'asset_inventory'
  ) {
    snackbar(i18n.t('asset:action.download.csv'), {
      variant: 'info',
    });

    const response = await this.httpGet(endpoint, search, undefined, {
      Accept: 'text/csv',
    });

    if (response.status === 200) {
      snackbar(i18n.t('sim:content.success.download_modal.primary'), {
        variant: 'success',
      });
    } else if (response.status !== 200) {
      snackbar(i18n.t('common:error.download_rows'), {
        variant: 'error',
      });
      return;
    }

    let csv = response.data as any;
    const attributes =
      this.rootStore.dynamicAttributeStore.dynamicAttributeProperties;
    // Replace dynamic attribute guids to the readable given name
    for (const attribute of attributes) {
      csv = csv.replaceAll(attribute.id, attribute.name);
    }

    validateResponse(
      { status: response.status, data: csv } as any,
      fileNamePrefix,
      'csv'
    );
  }

  @action.bound setError = (type: keyof State, error: boolean) => {
    this.error[type] = error;
  };

  @action.bound setLoading = (type: keyof State, loading: boolean) => {
    this.loading[type] = loading;
  };

  @action.bound indicateAssetEventDetected() {
    this.assetEventsDetected = !this.assetEventsDetected;
  }
}
