import { observer } from 'mobx-react-lite';
import React from 'react';
import { Route, RouteComponentProps, RouteProps } from 'react-router-dom';
import DocumentTitle from '../DocumentTitle';

interface IProps extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any>>;
  title?: string;
}

// Remember to delete me in v2
const UnprotectedRoute: React.FC<IProps> = ({
  component: Component,
  title,
  ...routeProps
}) => {
  return (
    <Route
      {...routeProps}
      render={props => (
        <>
          <DocumentTitle title={title} />
          <Component {...props} />
        </>
      )}
    />
  );
};

export default observer(UnprotectedRoute);
