import * as azureMaps from 'azure-maps-control';
import React, { useEffect } from 'react';
import { IImageSettings } from './imageConfig';
import { mapLayerIds } from './mapLayerIds';

// The image is always placed with this width (in the map unit, longitude)
// DO NOT CHANGE, or all points placed on existing image widgets will need updates
const targetWidth = 10;

export const ImageLayer: React.FC<{
  map: azureMaps.Map;
  settings: IImageSettings;
}> = ({ map, settings }) => {
  useEffect(() => {
    if (!settings.imageWidth || !settings.imageHeight) {
      console.warn('Missing image dimensions.');
      return () => {
        /* Comment added to satisfy SonarCloud */
      };
    }

    const scale = targetWidth / settings.imageWidth; // The ratio of pixels to lat/longs

    const w = targetWidth;
    const h = scale * settings.imageHeight;

    const x1 = 0;
    const x2 = x1 + w;
    const y1 = 0;
    const y2 = y1 + h;

    // This order is odd (coordinates are unintuitive sometimes), but works and doesn't really matter
    const imageCoordinates: azureMaps.data.Position[] = [
      [x1, y2],
      [x2, y2],
      [x2, y1],
      [x1, y1],
    ];

    const imageBounds =
      azureMaps.data.BoundingBox.fromLatLngs(imageCoordinates);

    // Padding for the max bounds. Too small and the widget can't show an entire awkward-sized image, too big and you can get lost panning around in empty space.
    const paddingX = w * 1.3;
    const paddingY = h * 1.3;

    const maxBounds = [
      [x1 - paddingX, y1 - paddingY],
      [w + paddingX, y1 + paddingY],
      [x1 - paddingX, h + paddingY],
      [x1 - paddingX, h + paddingY],
    ];

    const maxBoundsBox = azureMaps.data.BoundingBox.fromLatLngs(maxBounds);

    const layer = new azureMaps.layer.ImageLayer(
      {
        url: settings.imageUrl,
        coordinates: imageCoordinates,
      },
      mapLayerIds.background
    );

    map.layers.add(layer, mapLayerIds.features);
    map.setCamera({
      center: azureMaps.data.BoundingBox.getCenter(imageBounds),
      bounds: imageBounds,
      padding: 10, // Give the image some space to breathe
      maxBounds: maxBoundsBox,
    });

    return () => {
      try {
        map.layers.remove(layer);
      } catch (error) {}
    };
  }, [map, settings.imageUrl, settings.imageWidth, settings.imageHeight]);

  return null;
};
