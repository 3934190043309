import { Box } from '@mui/material';
import LoadingSpinner from 'components/LoadingSpinner';
import React from 'react';

const FullscreenLoadingOverlay: React.FC = () => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    height="100%"
    width="100%"
    position="fixed"
    bgcolor="rgba(255, 255, 255, 0.8)"
  >
    <LoadingSpinner size={60} />
  </Box>
);

export default FullscreenLoadingOverlay;
