import {
  isValidCountryCode,
  isValidStandaloneCountryCode,
} from 'components/FormFields/PhoneField/PhoneFieldUtils';
import gPhoneNumber from 'google-libphonenumber';
import i18n from 'i18next';
import { isASCII } from 'utils/validation';
import validator from 'validator';
import * as Yup from 'yup';

const phoneUtil = gPhoneNumber.PhoneNumberUtil.getInstance();

const validCountryCode = (value: string) =>
  phoneUtil
    .getSupportedRegions()
    .filter(code => isValidCountryCode(code, value, phoneUtil)).length === 1;

export const validStandaloneCountryCode = (value: string) =>
  phoneUtil
    .getSupportedRegions()
    .filter(code => isValidStandaloneCountryCode(code, value, phoneUtil))
    .length >= 1;

export enum PhoneValidationStrategy {
  Default,
  AllowStandaloneCountryCode,
}

function checkPhoneNumber(
  this: Yup.StringSchema,
  strategy: PhoneValidationStrategy = PhoneValidationStrategy.Default,
  message: string = i18n.t('validation:invalid.phone_number.country_code')
) {
  return this.test('phone', message, function (value = '') {
    try {
      if (value.length === 0) {
        return true;
      }
      if (
        strategy === PhoneValidationStrategy.AllowStandaloneCountryCode &&
        validStandaloneCountryCode(value)
      ) {
        return true;
      }
      const phoneNumber = phoneUtil.parseAndKeepRawInput(
        value.replace(/^00/, '+'),
        'ZZ'
      );
      if (!phoneUtil.isPossibleNumber(phoneNumber)) {
        return this.createError({
          message: i18n.t(
            `validation:invalid.phone_number.${
              validCountryCode(value) ? 'region' : 'base'
            }`
          ),
        });
      }

      const regionCodeFromPhoneNumber =
        phoneUtil.getRegionCodeForNumber(phoneNumber);

      return (
        phoneUtil.isValidNumberForRegion(
          phoneNumber,
          regionCodeFromPhoneNumber
        ) ||
        this.createError({
          message: i18n.t('validation:invalid.phone_number.region'),
        })
      );
    } catch {
      return validCountryCode(value)
        ? this.createError({
            message: i18n.t('validation:invalid.phone_number.region'),
          })
        : false;
    }
  });
}

const yupMethods = () => {
  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    'isAscii',
    function (message: string = i18n.t('validation:invalid.character_format')) {
      return this.test('is valid ascii character', message, value =>
        isASCII(value!)
      );
    }
  );

  Yup.addMethod<Yup.StringSchema>(Yup.string, 'isPhone', checkPhoneNumber);

  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    'isMobilePhone',
    function (
      message: string = i18n.t('validation:invalid.phone_number.country_code')
    ) {
      return this.test('phone number', message, value => {
        if (value && value.substring(0, 2) === '00') {
          value = `+${value.slice(2, value.length)}​`;
        }

        return validator.isMobilePhone(
          value || '',
          ['sv-SE', 'fi-FI', 'nn-NO', 'da-DK'],
          { strictMode: true }
        );
      });
    }
  );

  Yup.addMethod<Yup.ArraySchema<Yup.AnySchema<string>>>(
    Yup.array,
    'isUnique',
    function (
      mapper = (a: string) => a,
      message: string = i18n.t('validation:invalid.duplicate')
    ) {
      return this.test('unique', message, function (list) {
        const uniqueValues = [...new Set(list?.map(mapper))];
        if (list?.length === uniqueValues.length) {
          return true;
        }
        const idx = list?.findIndex(
          (l: string, i: number) => mapper(l) !== uniqueValues[i]
        );
        return this.createError({
          path: `${this.path}.${idx}`,
          message,
        });
      });
    }
  );
};

export default yupMethods;
