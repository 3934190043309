import {
  IntServiceDataFilterOptionDto,
  IntServiceDataSpecificationFilterDto,
  ServiceDataFilterType,
} from 'generated';
import {
  action,
  autorun,
  computed,
  makeObservable,
  observable,
  runInAction,
  toJS,
} from 'mobx';
import { assetAPI } from 'services/asset.service';
import { serviceApi } from 'services/service.service';
import { RootStore } from 'store/rootStore';
import { StoreBase } from 'store/storeBase';
import {
  IAssetFilterSettings,
  IDashboardFilterSettings,
} from 'views/Dashboard/ComponentTypes/FiltersComponent/filtersConfig';
import { FilterOptionState } from './FilterOptionState';

export class AssetFilterSettingsState extends StoreBase {
  @observable filters: IntServiceDataSpecificationFilterDto[];

  constructor(
    rootStore: RootStore,
    filters: IntServiceDataSpecificationFilterDto[],
    filterOptions: IAssetFilterSettings[],
    getFilterDisplayName: (filter: IDashboardFilterSettings) => string
  ) {
    super(rootStore);
    makeObservable(this);
    this.filters = filters;
    this.optionStates = filterOptions
      .map(
        option =>
          new FilterOptionState(this, option, getFilterDisplayName(option))
      )
      // Only sort on init, so options don't jump around and confuse the poor user
      .sort((a, b) => {
        // Show options with selected values first
        const aHasValue = a.valueCount > 0;
        const bHasValue = b.valueCount > 0;
        if (aHasValue !== bHasValue) {
          return Number(bHasValue) - Number(aHasValue);
        }
        return a.displayName.localeCompare(b.displayName);
      });
    this.selectedKey = this.optionStates[0]?.key || '';

    autorun(() => this.getAssetCount(toJS(this.filterSpecDtos)));
  }

  optionStates: FilterOptionState[];

  @computed get filterSpecDtos(): IntServiceDataSpecificationFilterDto[] {
    return this.filters.filter(f => f.values.length);
  }

  requestId = 0; // Until we implement this more beautifully (react-query?) we need to keep track of the latest request, as these can fire quickly and finish in the wrong order
  @observable assetCount: number | undefined;
  @observable isLoadingAssetCount = false;
  @action.bound async getAssetCount(
    filters: IntServiceDataSpecificationFilterDto[]
  ) {
    const requestId = ++this.requestId;

    this.assetCount = undefined;
    const resp = await this.httpPost(
      assetAPI.getFilteredAssetCount,
      {
        params: undefined,
        data: filters,
      },
      isLoading => runInAction(() => (this.isLoadingAssetCount = isLoading))
    );

    if (this.requestId === requestId) {
      runInAction(() => (this.assetCount = resp.data));
    }
  }

  @observable selectedKey = '';
  @action.bound setSelectedKey(key: string) {
    this.selectedKey = key;
  }
  @computed get selectedOption() {
    return this.optionStates.find(opt => opt.key === this.selectedKey);
  }

  @action.bound clearAllValues() {
    this.filters.splice(0, this.filters.length);
  }

  @observable.shallow allFilteredAssets:
    | {
        page: number;
        assets: IntServiceDataFilterOptionDto[];
        hasMore: boolean;
      }
    | undefined;
  @observable isLoadingAllFilteredAssets = false;
  @action.bound async loadAllFilteredAssets(
    filters: IntServiceDataSpecificationFilterDto[],
    page = 0
  ) {
    const resp = await this.httpPost(
      serviceApi.getFilterOptions,
      {
        params: undefined,
        data: {
          type: ServiceDataFilterType.AssetId,
          filters,
          page,
          pageSize: 300,
          search: '',
          includeEntityCount: false,
        },
      },
      isLoading =>
        runInAction(() => (this.isLoadingAllFilteredAssets = isLoading))
    );

    runInAction(() => {
      if (resp.status === 200 && resp.data) {
        this.allFilteredAssets = {
          page,
          assets:
            page > 0
              ? [
                  ...(this.allFilteredAssets?.assets || []),
                  ...resp.data.filterOptions,
                ]
              : resp.data.filterOptions,
          hasMore: resp.data.hasMore,
        };
      }
    });
  }

  @action.bound clearAllFilteredAssets() {
    this.allFilteredAssets = undefined;
  }
}
