import { ProductStatus } from 'generated';
import i18n from 'i18next';
import { Column } from 'react-table';
import { CustomCell } from '../Helpers/CustomCell';
import { ICustomCellProps } from '../Helpers/CustomCellProps';
import { getOptionFilter } from '../Helpers/getOptionFilter';

export function productStatus<T>(options: {
  id: string;
  accessor: (row: T) => ProductStatus;
  header?: string;
  width?: number;
  maxWidth?: number;
  resizable?: boolean;
  defaultShow?: boolean;
}): Column<T> {
  const {
    id,
    header = 'Status',
    accessor,
    defaultShow: show,
    width,
    maxWidth = 150,
    resizable = true,
  } = options;
  const productStatusTranslationKeyPrefix = 'product:status.';
  const productStatusCellProps: Record<ProductStatus, ICustomCellProps> = {
    [ProductStatus.Pending]: {
      iconType: 'Status',
      variant: 'warning',
      title: i18n.t(`${productStatusTranslationKeyPrefix}pending`),
      text: i18n.t(`${productStatusTranslationKeyPrefix}pending`),
    },
    [ProductStatus.Active]: {
      iconType: 'Status',
      variant: 'success',
      title: i18n.t(`${productStatusTranslationKeyPrefix}active`),
      text: i18n.t(`${productStatusTranslationKeyPrefix}active`),
    },
    [ProductStatus.Suspended]: {
      iconType: 'Status',
      variant: 'unconfigured',
      title: i18n.t(`${productStatusTranslationKeyPrefix}suspended`),
      text: i18n.t(`${productStatusTranslationKeyPrefix}suspended`),
    },
  };
  return {
    id,
    Header: header,
    accessor,
    resizable,
    width,
    maxWidth,
    show,
    Cell: row => {
      const cellProps =
        productStatusCellProps[row.value as ProductStatus] || {};
      return <CustomCell {...cellProps} />;
    },
    Filter: getOptionFilter([
      {
        label: i18n.t(`${productStatusTranslationKeyPrefix}active`),
        value: ProductStatus.Active,
      },
      {
        label: i18n.t(`${productStatusTranslationKeyPrefix}pending`),
        value: ProductStatus.Pending,
      },
      {
        label: i18n.t(`${productStatusTranslationKeyPrefix}suspended`),
        value: ProductStatus.Suspended,
      },
    ]),
  };
}
