import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    errorText: {
      fontSize: '14px',
      marginLeft: '5px',
      marginTop: '2px',
      fontWeight: 600,
      color: theme.palette.error.main,
    },
    table: {
      maxHeight: 270,
      marginTop: 10,
    },
    tableError: {
      borderColor: theme.palette.error.main,
      borderWidth: 3,
    },
    textDanger: {
      color: theme.palette.error.main,
      fontSize: 12,
      fontWeight: 600,
      marginTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  })
);
