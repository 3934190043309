import { Checkbox, FormControlLabel } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useDashboardContext } from '../contexts/dashboardContext';
import { IControlProps } from './controlProps';

function CheckboxControl<TObj>(props: IControlProps<TObj, boolean>) {
  const { name, object, label } = props;

  const { updateSetting } = useDashboardContext();

  if (!object) {
    return null;
  }

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    updateSetting(object, name, e.target.checked);
  };

  const checked: boolean = (object[name] as any) || false;

  return (
    <FormControlLabel
      control={
        <Checkbox checked={checked} onChange={handleChange} color="primary" />
      }
      label={label}
    />
  );
}
export default observer(CheckboxControl);
