import { CancelToken } from 'axios';
import { snackbar } from 'components/Snackbar';
import {
  DashboardComponentType,
  IntAggregateType,
  IntServiceDataAggregateRequestDto,
  IntServiceDataPointInterval,
  IntServiceDataSpecificationColumnDto,
  IntServiceDataSpecificationRequestDto,
  ServiceDataFilterType,
  ServiceDataSpecificationColumnType,
} from 'generated';
import i18n from 'i18n';
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { SortingRule } from 'react-table';
import {
  IAggregatedServiceDataResponse,
  IServiceDataResponse,
  serviceApi,
} from 'services/service.service';
import { IResponse } from 'shared/interfaces/api';
import { Dashboard } from 'store/domains/dashboard';
import { StoreBase } from 'store/storeBase';
import {
  DataPropSetting,
  IDataProperty,
  ServiceAggregationType,
} from '../dataPropTypes';
import {
  DataPropsGetter,
  IDataSourceResponse,
  IDataSourceStore,
} from '../dataSourceTypes';
import { getDataProp, getDataPropId, getDpSetting } from '../getDataProp';
import { Poller } from '../Poller';
import { getAssetDataProperties } from './assetDataProperties';
import { combineServiceDataFilters } from './combineServiceDataFilters';
import { IServiceDataSourceSettings } from './serviceDataConfig';
import { IServiceLatestDataSourceSettings } from './serviceLatestConfig';
import {
  registerDateDataProp,
  servicePropToDataProp,
} from './servicePropsToDataProps';

export class ServiceDataSource
  extends StoreBase
  implements
    IDataSourceStore<
      IServiceDataSourceSettings | IServiceLatestDataSourceSettings
    >
{
  @observable.ref settings:
    | IServiceDataSourceSettings
    | IServiceLatestDataSourceSettings;
  @observable isInitialized = false;
  @observable lastReceivedData: Date | undefined;

  dashboard: Dashboard;
  usedColumns: IntServiceDataSpecificationColumnDto[] = [];
  usedAggregateColumns: Record<string, IntAggregateType[]> = {};
  loadedAttributeProperties: IDataProperty[] = [];

  constructor(
    dashboard: Dashboard,
    settings: IServiceDataSourceSettings | IServiceLatestDataSourceSettings
  ) {
    super(dashboard.rootStore);
    makeObservable(this);

    this.dashboard = dashboard;
    this.settings = settings;
  }

  @computed get dataProperties() {
    const {
      dashboardStore: { serviceProperties },
      languageCulture,
    } = this.rootStore;

    const props = serviceProperties.map(sp =>
      servicePropToDataProp({
        sp,
        isLatest: this.settings.type === 'serviceLatest',
        languageCulture,
        category: sp.serviceName,
      })
    );

    props.push(registerDateDataProp);

    return props;
  }

  @computed get groupProperties(): IDataProperty[] {
    const { dynamicAttributeProperties } = this.rootStore.dynamicAttributeStore;

    const allGroupProps: IDataProperty[] = [
      ...getAssetDataProperties(dynamicAttributeProperties),
    ];

    allGroupProps.push(...dynamicAttributeProperties);

    return allGroupProps;
  }

  @action.bound async initialize() {
    if (this.isInitialized) {
      return;
    }

    const {
      dashboardStore: { loadServiceOptions },
    } = this.rootStore;

    await loadServiceOptions();

    runInAction(() => {
      this.isInitialized = true;
    });
  }

  destroy() {
    this.poller.stop();
  }

  pushStartEndDatesToBodyParameters = (
    bodyParameters:
      | IntServiceDataSpecificationRequestDto
      | IntServiceDataAggregateRequestDto,
    isLatest: boolean,
    registerDate: { from: Date | null; to: Date | null } | null
  ) => {
    if (!isLatest && registerDate) {
      if (registerDate.from) {
        bodyParameters.filters.push({
          type: ServiceDataFilterType.RegisterDateStart,
          values: [registerDate.from.toISOString()],
        });
      }
      if (registerDate.to) {
        bodyParameters.filters.push({
          type: ServiceDataFilterType.RegisterDateEnd,
          values: [registerDate.to.toISOString()],
        });
      }
    }
  };

  async getData(opts?: {
    lowResolution?: boolean;
    cancelToken?: CancelToken;
    componentType?: DashboardComponentType;
  }): Promise<IDataSourceResponse> {
    const { type } = this.settings;
    const { registerDate, assetFilters } = this.getParams();
    const isLatest = type === 'serviceLatest';
    let resp:
      | IResponse<IServiceDataResponse>
      | IResponse<IAggregatedServiceDataResponse>;
    const interval = isLatest
      ? IntServiceDataPointInterval.Latest
      : IntServiceDataPointInterval.None;
    const isAggregateComponent =
      opts?.componentType === DashboardComponentType.Gauge ||
      opts?.componentType === DashboardComponentType.Number ||
      opts?.componentType === DashboardComponentType.NumberGrid;

    if (isAggregateComponent) {
      if (Object.keys(this.usedAggregateColumns).length <= 0) {
        return { type: 'noContent' };
      }

      const aggregateBodyParameters: IntServiceDataAggregateRequestDto = {
        columnAggregates: this.usedAggregateColumns,
        filters: [],
        interval: interval,
      };

      aggregateBodyParameters.filters.push(...assetFilters);

      this.pushStartEndDatesToBodyParameters(
        aggregateBodyParameters,
        isLatest,
        registerDate
      );

      resp = await this.httpPost(serviceApi.getAggregatedServiceDataNew, {
        data: aggregateBodyParameters,
        params: undefined,
        cancelToken: opts?.cancelToken,
      });
    } else {
      // If we don't request any service properties, the backend will throw an error anyway. Abort.
      if (
        !this.usedColumns.some(
          col =>
            col.columnType ===
            ServiceDataSpecificationColumnType.ServiceProperty
        )
      ) {
        return { type: 'noContent' };
      }
      const bodyParameters: IntServiceDataSpecificationRequestDto = {
        columns: this.usedColumns,
        filters: [],
        interval: interval,
      };

      bodyParameters.filters.push(...assetFilters);

      this.pushStartEndDatesToBodyParameters(
        bodyParameters,
        isLatest,
        registerDate
      );
      resp = await this.httpPost(serviceApi.newGetServiceData, {
        data: bodyParameters,
        params: undefined,
        cancelToken: opts?.cancelToken,
      });
    }
    if (resp.status === 204) {
      return {
        type: 'noContent',
      };
    } else if (
      resp.status === 403 &&
      resp.statusText === 'Too many assets in filter'
    ) {
      return {
        type: 'error',
        message: i18n.t('dashboard:error.too_many_assets'),
      };
    } else if (resp.status === 499) {
      return { type: 'canceled' };
    } else if (resp.status !== 200) {
      snackbar(
        resp.statusText ||
          resp.exceptionMessage ||
          'Error loading service data',
        { variant: 'error' }
      );

      return {
        type: 'error',
      };
    }

    // Refresh latest data every two minutes, but historical data more rarely
    const pollingInterval = (isLatest ? 2 : 15) * 60 * 1000;

    this.poller.start(pollingInterval);

    runInAction(() => {
      this.lastReceivedData = new Date();
    });
    if (isAggregateComponent) {
      const data = resp?.data as IAggregatedServiceDataResponse;
      return {
        type: 'success',
        data: {
          type: 'aggregatedData',
          result: data.result ?? {},
        },
      };
    } else {
      const data = resp?.data as IServiceDataResponse;
      return {
        type: 'success',
        data: { type: 'groupedData', groups: data.groupedData || [] },
      };
    }
  }

  poller = new Poller();

  getDataProps<T>(propGetter: DataPropsGetter<T>): T {
    const columns: IntServiceDataSpecificationColumnDto[] = []; // Service data specific, sent with requests to narrow down the data sent back
    const propIds: string[] = []; // Keep track of all requested IDs for easy re-fetching of data when changing settings

    const dataProps = propGetter(dpSetting => {
      const prop = getDataProp(this, dpSetting);
      if (prop.column && !columns.includes(prop.column)) {
        columns.push(prop.column);
      }
      propIds.push(prop.id);
      return prop;
    });
    this.usedColumns = columns;

    runInAction(() => {
      propIds.sort();
      this.usedDataPropIds = propIds.join(',');
    });

    return dataProps;
  }

  getAggregateType(aggType: ServiceAggregationType | undefined) {
    switch (aggType) {
      case 'avg':
        return IntAggregateType.Average;
      case 'max':
        return IntAggregateType.Max;
      case 'min':
        return IntAggregateType.Min;
      default:
        return IntAggregateType.Average;
    }
  }

  getUsedAggregates(
    propsSetting: DataPropSetting[],
    componentType?: DashboardComponentType
  ) {
    if (componentType === DashboardComponentType.NumberGrid) {
      const numberGridAggregates: Record<string, IntAggregateType[]> = {};
      const propIds: string[] = []; // Keep track of all requested IDs for easy re-fetching of data when adding or removing a card
      propsSetting.forEach(setting => {
        const id = getDataPropId(setting ?? '');
        numberGridAggregates[id] = [
          IntAggregateType.Average,
          IntAggregateType.Max,
          IntAggregateType.Min,
        ];
        propIds.push(id);
      });

      this.usedAggregateColumns = numberGridAggregates;

      runInAction(() => {
        this.usedDataPropIds = propIds.join(',');
      });
    } else {
      const id = getDataPropId(propsSetting[0] ?? '');
      const setting = getDpSetting(propsSetting[0] ?? '');

      this.usedAggregateColumns = {
        [id]: [this.getAggregateType(setting.agg)],
      };

      // re-fetch data when new aggregate or setting is chosen
      runInAction(() => {
        this.aggregateString = setting.agg ?? '';
        this.usedDataPropIds = id;
      });
    }
  }

  getParams() {
    const { filterState } = this.dashboard;
    const { assetFilterSpecDtos } = filterState;
    const { serviceDataFilters = [], type } = this.settings;

    const assetFilters = combineServiceDataFilters(
      assetFilterSpecDtos,
      serviceDataFilters
    );

    const dateFilters =
      type === 'service' ? filterState.getDateFilters() : null;

    return {
      assetFilters,
      registerDate: dateFilters,
    };
  }

  // The IDs of all used data properties
  @observable usedDataPropIds = '';

  @computed get depString(): string {
    const { manualRefreshTrigger } = this.dashboard.rootStore.dashboardStore;
    const params = this.getParams();

    const depObject = {
      ...params,
      manualRefreshTrigger,
      pollerRefreshTrigger: this.poller.currentTick,
      usedDataPropIds: this.usedDataPropIds,
      aggregateString: this.aggregateString,
    };

    return JSON.stringify(depObject);
  }

  @observable aggregateString = '';

  @computed get defaultSort(): SortingRule {
    if (this.settings.type === 'service') {
      return {
        id: 'AssetDetailsAssetName',
        desc: true,
      };
    }
    return {
      id: 'registerDate',
      desc: true,
    };
  }
}
