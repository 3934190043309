import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  List,
  ListItem,
} from '@mui/material';
import AtlasIcon from 'components/AtlasIcon';
import DialogTitleWrapper from 'components/DialogTitleWrapper';
import ModalFooterWithButtons from 'components/ModalFooterWithButtons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  SortEndHandler,
} from 'react-sortable-hoc';
import { Column } from 'react-table';
import { useStyles } from './EditColumnsModal.styles';

const SortableList = SortableContainer(List);

const DragHandle = SortableHandle(() => {
  const classes = useStyles();
  return <AtlasIcon type="DragHandle" className={classes.icon} />;
});

const SortableItemContainer: React.FC = ({ children }) => {
  const classes = useStyles();
  return (
    <Box className={classes.sortableItemContainer}>
      <DragHandle />
      {children}
    </Box>
  );
};
const SortableItem = SortableElement(SortableItemContainer);

interface IProps {
  show: boolean;
  close: () => void;
  columns: Column[];
  onSortEnd: SortEndHandler;
  toggleShowColumn: (colId: string, show: boolean) => void;
}

const EditColumnsModal: React.FC<IProps> = props => {
  const { show, close, columns, onSortEnd, toggleShowColumn } = props;
  const { t } = useTranslation('common');
  const showAllColumns = () => {
    columns.forEach(column => {
      toggleShowColumn(column.id || '_', true);
    });
  };
  const visibleColumns = columns.filter(column => column.show);
  const allColumnsVisible = visibleColumns.length === columns.length;
  return (
    <Dialog open={show} onClose={close} maxWidth="sm" fullWidth>
      <DialogTitleWrapper onClose={close} title={t('label.edit.columns')} />
      <DialogContent>
        <SortableList
          useDragHandle
          onSortEnd={onSortEnd}
          distance={10}
          helperClass="dragging"
        >
          <Box display="flex">
            <Box width={25} />
            <ListItem
              button
              disabled={allColumnsVisible}
              onClick={showAllColumns}
            >
              <Checkbox checked={allColumnsVisible} color="primary" />
              &nbsp;{t('action.show_all')}
            </ListItem>
          </Box>
          {columns.map((column, index) => (
            <SortableItem key={`${column.id}_${index}`} index={index}>
              <ListItem
                button
                disabled={visibleColumns.length === 1 && column.show}
                onClick={() =>
                  toggleShowColumn(column.id || '_', column.show === false)
                }
              >
                <Checkbox checked={column.show !== false} color="primary" />
                &nbsp;
                {column.Header ?? column.id}
              </ListItem>
            </SortableItem>
          ))}
        </SortableList>
      </DialogContent>
      <ModalFooterWithButtons
        rightButtons={
          <Button variant="contained" color="primary" onClick={close} fullWidth>
            {t('label.done')}
          </Button>
        }
      />
    </Dialog>
  );
};

export default EditColumnsModal;
