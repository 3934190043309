import i18n from 'i18next';
import { Column } from 'react-table';
import { SMSType } from 'shared/types/sms-type';
import { getOptionFilter } from '../Helpers/getOptionFilter';

export function smsType<T>(options: {
  id: string;
  accessor: (row: T) => SMSType;
  header?: string;
}): Column<T> {
  const { id, header, accessor } = options;

  return {
    id,
    Header: header,
    accessor,
    resizable: false,
    width: 70,
    Filter: getOptionFilter([
      {
        label: i18n.t('sms:binary'),
        value: 'binary',
      },
      {
        label: i18n.t('sms:text'),
        value: 'text',
      },
      {
        label: i18n.t('sms:all'),
        value: '',
      },
    ]),
    Cell: row => i18n.t(`sms:${row.value}`),
  };
}
