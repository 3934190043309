import { Grid, Link, Typography } from '@mui/material';
import { Role } from 'components/Auth/Role';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'store';
import { IDataSourceSettingsMenuProps } from '../dataSourceTypes';
import { IAnalyticsCubeDataSourceSettings } from './analyticsCubeConfig';

const AnalyticsCubeSettingsMenu: React.FC<
  IDataSourceSettingsMenuProps<IAnalyticsCubeDataSourceSettings>
> = ({ settings }) => {
  const {
    dashboardStore,
    authStore: { activeSubPortalDomainAlias, hasRole },
  } = useStores();

  const { t } = useTranslation('dashboard');

  const cube = dashboardStore.analyticsCubes.find(
    analyticsCube => analyticsCube.analyticsCubeId === settings.analyticsCubeId
  );

  const mgmtUrl = new URL(process.env.REACT_APP_API_URL || '');

  const manageAnalyticsCubeUrl =
    activeSubPortalDomainAlias && process.env.REACT_APP_API_URL
      ? `https://${activeSubPortalDomainAlias}-${mgmtUrl.hostname}`
      : mgmtUrl.href;

  return (
    <Grid container spacing={4}>
      <Grid item sm={6}>
        <Typography variant="h6">{t('analytics_cube.base')}</Typography>
        <Typography>{cube?.analyticsCubeName}</Typography>
        <Typography marginTop={2} variant={'h6'}>
          {t('analytics_cube.aggregates')}
        </Typography>
        {cube?.aggregates.map(agg => (
          <Typography key={agg.analyticsCubeAggregateId}>
            {agg.displayName}
          </Typography>
        ))}
        <Typography marginTop={2} variant={'h6'}>
          {t('analytics_cube.groupings')}
        </Typography>
        {cube?.groupings.map(g => (
          <Typography key={g.analyticsCubeGroupingId}>
            {g.displayName}
          </Typography>
        ))}
        {hasRole([
          Role.AccessManagementPortal,
          Role.RoleNameEditAnalyticsCube,
        ]) && (
          <Link
            display="inline-block"
            marginTop={5}
            href={`${manageAnalyticsCubeUrl}/Management/AnalyticsCube/Edit?analyticsCubeId=${cube?.analyticsCubeId}`}
          >
            {t('analytics_cube.edit')}
          </Link>
        )}
      </Grid>
    </Grid>
  );
};

export default observer(AnalyticsCubeSettingsMenu);
