import { DashboardComponentType } from 'generated';
import {
  IBaseComponentSettings,
  IComponentTypeConfig,
} from 'views/Dashboard/dashboardTypes';
import { IDataSourceSettings } from 'views/Dashboard/DataSources/dataSourceTypes';
import PreviewStep from '../PreviewStep';
import PieChartComponent from './PieChartComponent';

export interface IPieChartSettings extends IBaseComponentSettings {
  title: string;
  dataSource: IDataSourceSettings;

  slices: { prop: string }[];
}

export const pieChartComponentConfig: IComponentTypeConfig<
  DashboardComponentType.PieChart,
  IPieChartSettings
> = {
  widgetType: DashboardComponentType.PieChart,
  icon: 'Graph',
  Component: PieChartComponent,
  settingsSteps: [
    {
      title: 'final_preview',
      Component: PreviewStep,
    },
  ],
  getDefaultSettings: ({ dataSource }) => {
    return {
      position: { x: 0, y: 0, w: 4, h: 4 },
      title: '',
      dataSource: dataSource.settings,
      slices: [],
    };
  },
};
