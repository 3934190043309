import { Grid, Typography } from '@mui/material';
import classNames from 'classnames';
import { IntDashboardDataStatusRuleDto } from 'generated';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { sortArrayByStatusRuleColor } from '../sortByStatusRuleColor';
import { useStyles } from './SortedStatuses.styles';
import useFormatStringValue from './useFormatStringValue';
import useFormatRangeValue from './useValueRangeFormat';
interface IProps {
  statusRules: IntDashboardDataStatusRuleDto[];
  isStringProperty: boolean;
}

const SortedStatuses: React.FC<IProps> = ({
  statusRules,
  isStringProperty,
}) => {
  const classes = useStyles();

  const sortRules = (rules: IntDashboardDataStatusRuleDto[]) =>
    [...rules].sort((a, b) => {
      if (a.min === b.min) {
        return 0;
      } else if (a.min === null || a.min === undefined) {
        return -1;
      } else if (b.min === null || b.min === undefined) {
        return 1;
      }
      return a.min < b.min ? -1 : 1;
    });

  const valueRangeFormat = useFormatRangeValue();

  const formatStringValue = useFormatStringValue();

  const sortedRules = isStringProperty
    ? sortArrayByStatusRuleColor(statusRules, rule => rule.color)
    : sortRules(statusRules);

  return (
    <Grid container display="flex" justifyContent="center" paddingY={5}>
      {sortedRules.map(rule => (
        <Grid
          item
          paddingY={1}
          sm={isStringProperty ? 2 : 1}
          key={rule.id}
          className={classNames(classes.overview, {
            [classes.red]: rule.color === 'red',
            [classes.yellow]: rule.color === 'yellow',
            [classes.green]: rule.color === 'green',
          })}
        >
          <Typography className={classes.ruleValueLabel}>
            {isStringProperty
              ? formatStringValue(rule.textComparer, rule.textValue)
              : valueRangeFormat(rule.min, rule.max)}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};

export default observer(SortedStatuses);
