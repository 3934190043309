import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    inputRoot: {
      padding: '0 !important',
      backgroundColor: theme.palette.common.white,
    },
    input: {
      margin: '0 !important',
      padding: dense => (dense ? '0' : '24px 12px 10px 12px !important'),
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: '100%',
    },
    iconInputAdornment: {
      marginLeft: 12,
      marginTop: 14,
      marginRight: -6,
    },
    listItemIcon: {
      minWidth: 34,
    },
    endAdornment: {
      margin: -4,
      marginTop: 1,
    },
  })
);
