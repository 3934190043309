import { useTheme } from '@mui/material';
import * as azureMaps from 'azure-maps-control';
import { useMemo } from 'react';

export type MapSpriteType =
  | 'circle-red'
  | 'circle-blue'
  | 'circle-yellow'
  | 'circle-green'
  | 'circle-selected'
  | 'pin-red'
  | 'pin-green';

interface ICustomMapSprite {
  id: MapSpriteType;
  templateName: string;
  fillColor: string;
  borderColor?: string;
  scale?: number;
}

export function addCustomSprites(
  map: azureMaps.Map,
  sprites: ICustomMapSprite[]
): Promise<void[]> {
  return Promise.all(
    sprites.map(
      ({ id, templateName, fillColor, borderColor = '#FFF', scale = 1 }) =>
        map.imageSprite.createFromTemplate(
          id,
          templateName,
          fillColor,
          borderColor,
          scale
        )
    )
  );
}

const iconScale = 1.4;

export function useCustomSprites() {
  const theme = useTheme();

  return useMemo(
    (): ICustomMapSprite[] => [
      {
        id: 'circle-red',
        templateName: 'pin-round',
        fillColor: theme.palette.error.main,
        scale: iconScale,
      },
      {
        id: 'circle-yellow',
        templateName: 'pin-round',
        fillColor: theme.palette.warning.main,
        scale: iconScale,
      },
      {
        id: 'circle-green',
        templateName: 'pin-round',
        fillColor: theme.palette.success.main,
        scale: iconScale,
      },
      {
        id: 'circle-blue',
        templateName: 'pin-round',
        fillColor: theme.palette.info.main,
        scale: iconScale,
      },
      {
        id: 'circle-selected',
        templateName: 'pin-round',
        fillColor: theme.palette.primary.main,
        scale: iconScale * 1.2,
      },
      {
        id: 'pin-green',
        templateName: 'pin',
        fillColor: theme.palette.success.main,
        scale: 1,
      },
    ],
    [theme]
  );
}
