import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Paper,
  Popover,
  TextField,
} from '@mui/material';
import { StaticDatePicker } from '@mui/x-date-pickers';
import AtlasIcon from 'components/AtlasIcon';
import DateTextInput from 'components/DateFilterDropdown/DateRangePicker/DateTextInput';
import { format, isValid } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getDateFormatString, usePopover } from 'utils';
import { parseDateIfValid } from 'utils/parsing';
import { useStyles } from './DateFilter.styles';
import { FilterProps } from './FilterProps';

const DateFilter: React.FC<FilterProps<string>> = ({ filter, onChange }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openPopover, popoverProps] = usePopover('dateFilter');

  const [value, setValue] = useState(parseDateIfValid(filter?.value));
  const [dateString, setDateString] = useState('');
  const [isValidDate, setValidDate] = useState(true);

  useEffect(() => {
    if (value) {
      setDateString(format(value, getDateFormatString()));
    } else {
      setDateString('');
    }
  }, [value, t]);

  useEffect(() => {
    setValue(parseDateIfValid(filter?.value));
  }, [filter?.value]);

  const handleBlur = () => {
    if (parseDateIfValid(dateString)) {
      onChange(dateString);
    } else {
      setValue(undefined);
      onChange('');
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleBlur();
    }
  };

  const handleDateChange = (date: Date | null) => {
    date && setValue(date);
    setValidDate(isValid(date));
  };

  return (
    <>
      <TextField
        classes={{
          root: classes.root,
        }}
        variant="outlined"
        value={dateString}
        placeholder={t('common:date.pick')}
        onChange={e => setDateString(e.target.value)}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        InputProps={{
          classes: {
            root: classes.root,
            input: classes.input,
            adornedEnd: classes.adornment,
          },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton size="small" onClick={openPopover}>
                <AtlasIcon type="InputCalendar" size={14} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Popover {...popoverProps}>
        <Paper>
          <Box>
            <Box padding={4}>
              <DateTextInput
                value={value || new Date()}
                onChange={handleDateChange}
                label={t('common:date.pick')}
                isDateRangeValid={true}
                errorMessage={t('validation:invalid.format')}
              />
            </Box>
            <StaticDatePicker
              value={value}
              displayStaticWrapperAs="desktop"
              orientation="landscape"
              renderInput={params => <TextField {...params} />}
              openTo="day"
              onChange={date => {
                setValue((date as Date) || new Date());
                setValidDate(isValid(date));
              }}
              views={['day']}
            />
            <Box
              display="flex"
              justifyContent="space-between"
              paddingX={4}
              paddingBottom={4}
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={popoverProps.onClose}
              >
                {t('common:action.cancel')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  onChange(format(value || new Date(), getDateFormatString()));
                  if (!value) {
                    setDateString(format(new Date(), getDateFormatString()));
                  }
                  popoverProps.onClose();
                }}
                disabled={!isValidDate}
              >
                {t('common:action.apply')}
              </Button>
            </Box>
          </Box>
        </Paper>
      </Popover>
    </>
  );
};

export default DateFilter;
