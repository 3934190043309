import {
  DashboardComponentType,
  IntServiceDataSpecificationFilterDto,
} from 'generated';
import { IDataSourceConfig } from '../dataSourceTypes';
import { fixServiceDataSourceSettings } from './fixServiceDataSourceSettings';
import ServiceDataSettingsMenu from './ServiceDataSettingsMenu';

export interface IServiceDataSourceSettings {
  type: 'service';
  serviceDataFilters: IntServiceDataSpecificationFilterDto[];
}

export const serviceDataSourceConfig: IDataSourceConfig<IServiceDataSourceSettings> =
  {
    icon: 'Basketball',
    settingsSteps: [
      { title: 'service_settings', Component: ServiceDataSettingsMenu },
    ],
    getDefaultSettings: async dashboard => {
      return {
        type: 'service',
        serviceDataFilters: [],
      };
    },
    fixSettings: fixServiceDataSourceSettings,
    enabledComponentTypes: {
      [DashboardComponentType.AzureMaps]: false,
      [DashboardComponentType.ColumnChart]: true,
      [DashboardComponentType.Gauge]: true,
      [DashboardComponentType.Image]: false,
      [DashboardComponentType.LineChart]: true,
      [DashboardComponentType.Number]: true,
      [DashboardComponentType.Table]: true,
      [DashboardComponentType.NumberGrid]: false,
      [DashboardComponentType.GroupedBarChart]: true,
    },
    shouldShowDateFilter: true,
  };
