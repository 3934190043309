import { Button } from '@mui/material';
import Highcharts from 'components/Highcharts';
import MessageOverlay from 'components/MessageOverlay';
import { format } from 'date-fns';
import { Chart, TooltipOptions } from 'highcharts';
import { observer } from 'mobx-react-lite';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IDashboardComponent } from 'views/Dashboard/dashboardTypes';
import { useDataSource, useDataSourceData } from 'views/Dashboard/DataSources';
import Widget from 'views/Dashboard/Widget';
import DownloadHighchartDataMenu from '../../DownloadHighchartDataMenu';
import { averageLineChartSeries } from './averageLineChartSeries';
import { dataForAverageChartSeries } from './dataForAverageChartSeries';
import { ILineChartSettings } from './lineChartConfig';
import { lineChartSeries } from './lineChartSeries';
import { makePopupGetter } from './PopupContent';
import TwoTextSwitch from './TwoTextSwitch';
import { useLineChartSharedOptions } from './useLineChartSharedOptions';

const noOfAssetsToEnableShowAverageChart = 15;

const LineChartWidget: IDashboardComponent<ILineChartSettings> = ({
  component,
}) => {
  const { t } = useTranslation('dashboard');
  const { settings, dashboard } = component;
  const chartRef = useRef<Chart>();
  const dataSource = useDataSource(settings.dataSource);

  const [showAverageChart, setShowAverageChart] = useState(false);

  const { xValue, yValue, name, getPopup } = dataSource.getDataProps(
    getProp => ({
      name: getProp(settings.nameProp),
      yValue: getProp(settings.yAxis.prop),
      xValue: getProp(settings.xAxis.prop),
      getPopup: makePopupGetter(settings.popup, getProp),
    })
  );

  const { data, noContent, error } = useDataSourceData(dataSource);

  const fileName = `${settings.title}-${format(
    new Date(),
    'yyyy-MM-dd HH:mm'
  )}`;

  const [chartOptions, isZoomed, updateZoom] = useLineChartSharedOptions(
    fileName,
    xValue,
    yValue,
    settings,
    dashboard
  );

  const customTooltipOptions: TooltipOptions = {
    useHTML: true,
    shadow: true,
    borderRadius: 20,
    borderColor: '#DCDCE1',
    backgroundColor: 'rgba(225,225,225,.7)',
    borderWidth: 1,
    padding: 0.5,
    outside: false,
    style: {
      zIndex: 9999,
    },
  };

  if (data?.type === 'groupedData') {
    if (showAverageChart) {
      const { averages, ranges, minValues, maxValues, warnings } =
        dataForAverageChartSeries(data, name, xValue, yValue, settings);

      chartOptions.tooltip = {
        ...customTooltipOptions,
        enabled: showAverageChart,
        shared: true,
      };

      chartOptions.plotOptions = {
        scatter: {
          tooltip: {
            clusterFormat: `
            <div style="background-color:rgba(244,244,249, .7); color: #222222; padding:12px; display:flex; border-radius:8px;">
              <span style="font-size:24px; font-weight: 700; padding-right:24px">
                {point.clusterPointsAmount}
              </span>
              <div style="display:flex; flex-direction:column; justify-content:center; text-align: right; font-size:12px; border-color:#DCDCE1;">
                <span>${t('dashboard:line_chart.warnings')}</span>
                <span>${t('dashboard:line_chart.clustered_warnings')}</span>
              </div>` as Object,
          },
        },
      };

      chartOptions.chart = {
        resetZoomButton: {
          theme: {
            style: {
              display: 'none',
            },
          },
        },
      };

      averageLineChartSeries(
        chartOptions,
        averages,
        maxValues,
        minValues,
        ranges,
        yValue,
        warnings
      );
    } else {
      chartOptions.tooltip = {
        ...customTooltipOptions,
        shared: false,
        positioner: function (a, b, point) {
          const hitTheRoof = point.plotY < this.chart.plotTop + 50;
          const hitTheBottom = this.chart.plotHeight - point.plotY < 50;
          const checkBoundaries = hitTheBottom
            ? point.plotY - 100
            : point.plotY - 50;

          if (point.plotX - 100 > this.chart.plotLeft) {
            return {
              x: point.plotX - 200,
              y: hitTheRoof ? point.plotY : checkBoundaries,
            };
          } else {
            return {
              x: point.plotX + 100,
              y: hitTheRoof ? point.plotY : checkBoundaries,
            };
          }
        },
        headerFormat: '',
        pointFormatter() {
          return getPopup({
            displayName: this.options?.custom?.name,
            icon: 'circle-blue',
            iconAnchor: 'center',
            data: this.options?.custom,
          });
        },
      };
      lineChartSeries(
        chartOptions,
        data,
        xValue,
        yValue,
        name,
        settings.yAxis.step ?? false
      );
    }
  }

  const defaultToAverageChart =
    data?.type === 'groupedData' &&
    data.groups.length <= noOfAssetsToEnableShowAverageChart;

  useMemo(() => {
    if (defaultToAverageChart) {
      setShowAverageChart(false);
      updateZoom(false);
    } else {
      setShowAverageChart(true);
    }
  }, [defaultToAverageChart, updateZoom]);

  const resetZoom = () => {
    if (chartRef && chartRef.current) {
      chartRef.current.zoomOut();
      updateZoom(false);
    }
  };

  return (
    <Widget
      isLoading={!dataSource.isInitialized || dataSource.isLoading}
      widgetType="LineChartWidget"
    >
      <Widget.Header
        centerContent={
          <>
            {data?.type === 'groupedData' && data.groups.length >= 4 && (
              <TwoTextSwitch
                leftText={t('line_chart.show_aggregate_assets')}
                rightText={t('line_chart.show_individual_assets')}
                value={showAverageChart}
                onChange={setShowAverageChart}
              />
            )}
          </>
        }
        buttons={
          <DownloadHighchartDataMenu
            chartRef={chartRef}
            id={component.componentId}
            onBeforeDownload={() => {
              if (showAverageChart) {
                setShowAverageChart(false);
              }
            }}
          />
        }
      >
        {settings.title}
      </Widget.Header>

      <Widget.Content skeleton={!dataSource.isInitialized || !chartOptions}>
        {chartOptions && chartOptions.tooltip && (
          <>
            {showAverageChart && (
              <Highcharts
                callback={(chart: Chart) => (chartRef.current = chart)}
                options={chartOptions}
                containerProps={{
                  style: {
                    height: '100%',
                  },
                }}
              />
            )}
            {!showAverageChart && (
              <Highcharts
                callback={(chart: Chart) => (chartRef.current = chart)}
                options={chartOptions}
                containerProps={{
                  style: {
                    height: '100%',
                  },
                }}
              />
            )}

            {isZoomed && (
              <Button
                style={{ position: 'absolute', top: 24, right: 24 }}
                onClick={resetZoom}
                color="primary"
                variant="contained"
              >
                {t('line_chart.reset_zoom')}
              </Button>
            )}
          </>
        )}
        {(noContent || chartOptions.series?.length === 0) && (
          <MessageOverlay message={t('no_data')} />
        )}
        {error && <MessageOverlay message={error} />}
      </Widget.Content>
    </Widget>
  );
};

export default observer(LineChartWidget);
