import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'store';

const DatepickerLocaleProvider: React.FC = ({ children }) => {
  const {
    authStore: { user },
  } = useStores();

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={user?.locale}
    >
      {children}
    </LocalizationProvider>
  );
};

export default observer(DatepickerLocaleProvider);
