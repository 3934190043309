import { Autocomplete, TextField } from '@mui/material';
import { useStyles } from 'components/Inputs/AutoComplete/AutoComplete.styles';
import { addMinutes, format, set, startOfDay } from 'date-fns';
import { useEffect, useRef, useState } from 'react';

interface IProps {
  label: string;
  disabled?: boolean;
  value: Date;
  onChange: (newValue: Date) => void;
}

const formatTime = (d: Date) => format(d, 'HH:mm');

function getTimeOptions(optionsPerHour: number): string[] {
  const options: string[] = [];
  const startTime = startOfDay(new Date());

  for (let i = 0; i < 24 * optionsPerHour; i++) {
    options.push(formatTime(addMinutes(startTime, i * (60 / optionsPerHour))));
  }

  options.push('23:59');
  return options;
}

const timeOptions = getTimeOptions(2);

const isValidTimeString = (input: string) =>
  !!input.match(/^([0-1][0-9]|[2][0-3]):([0-5][0-9])$/);

const TimeSelect: React.FC<IProps> = ({ label, value, onChange, disabled }) => {
  const classes = useStyles(false);
  const [inputValue, setInputValue] = useState('');
  const openedOnValueRef = useRef('');

  useEffect(() => {
    // Don't update the value while the input is open, or things feels awkward
    if (!openedOnValueRef.current) {
      setInputValue(formatTime(value));
    }
  }, [value]);

  const handleInputChange = (newValue: string) => {
    setInputValue(newValue);

    if (!isValidTimeString(newValue) || newValue === formatTime(value)) {
      return;
    }

    openedOnValueRef.current = '';
    const parts = newValue.split(':').map(x => parseInt(x));

    onChange(
      set(value, {
        hours: parts[0],
        minutes: parts[1],
        seconds: 0,
      })
    );
  };

  const handleBlur = () => {
    if (!isValidTimeString(inputValue) && openedOnValueRef.current) {
      setInputValue(openedOnValueRef.current);
    }
    openedOnValueRef.current = '';
  };

  const invalidFormatError =
    inputValue.length === 5 && !isValidTimeString(inputValue);

  return (
    <Autocomplete
      classes={classes}
      value={inputValue}
      disabled={disabled}
      onFocus={() => (openedOnValueRef.current = inputValue)}
      onClose={handleBlur}
      onInputChange={(_, newValue) => {
        handleInputChange(newValue);
      }}
      onChange={(_, newValue) => {
        setInputValue(newValue);
      }}
      filterOptions={
        (options, params) =>
          options.filter(opt => opt.startsWith(params.inputValue)) // Only check the beginning of options
      }
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={timeOptions}
      renderOption={(attr, option) => (
        <li {...attr} key={option}>
          {option}
        </li>
      )}
      disableClearable
      freeSolo
      renderInput={params => (
        <TextField
          {...params}
          key={params.id}
          error={invalidFormatError}
          label={label}
          variant="outlined"
        />
      )}
    />
  );
};

export default TimeSelect;
