import { createStyles, makeStyles } from '@mui/styles';
import { widgetHeaderHeight } from 'views/Dashboard/Widget/WidgetHeader.styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    message: {
      background: 'rgba(255, 255, 255, 0.9)',
      padding: theme.spacing(2, 4),
      transform: `translate(-${widgetHeaderHeight / 2}px)`,
    },
  })
);
