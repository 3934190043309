import { FilterEntityType } from 'automationcenter-api';

import i18n from 'i18next';
import { Column } from 'react-table';
import { CustomCell } from '../Helpers/CustomCell';
import { ICustomCellProps } from '../Helpers/CustomCellProps';
import { getOptionFilter } from '../Helpers/getOptionFilter';

export function automationRuleFilterType<T>(options: {
  id: string;
  accessor: (row: T) => FilterEntityType;
  header?: string;
  defaultShow?: boolean;
  filterable?: boolean;
}): Column<T> {
  const {
    id,
    header = 'Status',
    accessor,
    defaultShow: show,
    filterable,
  } = options;

  const cellByStatus: Record<FilterEntityType, ICustomCellProps> = {
    // Support for automation rule filter SimGroup will be implemented in PBI #17598
    // [IntAutomationRuleFilterType.SimGroup]: {
    //   text: i18n.t(
    //     `automation:filterType.${IntAutomationRuleFilterType.SimGroup}`
    //   ),
    // },
    [FilterEntityType.BaseEntity]: {
      text: i18n.t(`automation:filter_type.${FilterEntityType.BaseEntity}`),
    },
    [FilterEntityType.ConnectivityUnit]: {
      text: i18n.t(
        `automation:filter_type.${FilterEntityType.ConnectivityUnit}`
      ),
    },
    [FilterEntityType.Asset]: {
      text: i18n.t(`automation:filter_type.${FilterEntityType.Asset}`),
    },
    [FilterEntityType.Customer]: {
      text: i18n.t(`automation:filter_type.${FilterEntityType.Customer}`),
    },
    [FilterEntityType.Terminal]: {
      text: i18n.t(`automation:filter_type.${FilterEntityType.Terminal}`),
    },
  };

  return {
    id,
    Header: header,
    accessor,
    resizable: false,
    maxWidth: 130,
    filterable,
    show,
    Cell: row => {
      const iconProps = cellByStatus[row.value as FilterEntityType] || {};
      return <CustomCell {...iconProps} index={row.index} />;
    },
    Filter: getOptionFilter([
      {
        label: i18n.t('all'),
        value: '',
      },
      // Support for automation rule filter SimGroup will be implemented in PBI #17598
      // {
      //   label: i18n.t(
      //     `automation:filterType.${IntAutomationRuleFilterType.SimGroup}`
      //   ),
      //   value: IntAutomationRuleFilterType.SimGroup,
      // },
      {
        label: i18n.t(
          `automation:filter_type.${FilterEntityType.ConnectivityUnit}`
        ),
        value: FilterEntityType.ConnectivityUnit,
      },
      {
        label: i18n.t(`automation:filter_type.${FilterEntityType.Asset}`),
        value: FilterEntityType.Asset,
      },
      {
        label: i18n.t(`automation:filter_type.${FilterEntityType.Customer}`),
        value: FilterEntityType.Customer,
      },
    ]),
  };
}
