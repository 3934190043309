import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IGaugeSettings } from 'views/Dashboard/ComponentTypes/GaugeComponent/gaugeConfig';
import { INumberSettings } from 'views/Dashboard/ComponentTypes/NumberComponent/numberConfig';
import { Control } from 'views/Dashboard/SettingControls';

interface IProps {
  settings: IGaugeSettings | INumberSettings;
}

const SettingsForShowLatestAssetUpdate: React.FC<IProps> = ({ settings }) => {
  const { t } = useTranslation('dashboard');
  const fieldName = 'timeInterval';
  const min = 1;

  const getValidationMsg = (value: string) => {
    if (value === '') {
      return undefined;
    }

    const parsedValue = parseFloat(value);

    if (isNaN(parsedValue)) {
      return t('validation:invalid.numerical');
    } else {
      return parsedValue < min
        ? t('asset_latest_update.interval_too_low')
        : undefined;
    }
  };

  return (
    <>
      <Control.Checkbox
        object={settings}
        name="showTimestamp"
        autoFocus
        label={t('asset_latest_update.checkbox_title')}
      />
      {settings.showTimestamp && (
        <Control.Number
          object={settings}
          name={fieldName}
          label={t('asset_latest_update.field_title')}
          suffix="min"
          helperText={t('asset_latest_update.helper_text')}
          min={min}
          getValidationMsg={getValidationMsg}
        />
      )}
    </>
  );
};

export default observer(SettingsForShowLatestAssetUpdate);
