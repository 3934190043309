import { aiTrackEvent } from 'appInsights';
import { snackbar } from 'components/Snackbar';
import {
  IntAnalyticsCubeDetailsDto,
  IntAnalyticsCubeDto,
  IntAtlasServiceDto,
  IntAtlasServicePropertyDto,
  IntCreateDashboardRequestDto,
  IntDashboardDto,
  IntServiceDataFilterOptionDto,
} from 'generated';
import i18n from 'i18next';
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { persist } from 'mobx-persist';
import { analyticsCubeApi } from 'services/analyticsCube.service';
import { dashboardApi } from 'services/dashboard.service';
import { serviceApi } from 'services/service.service';
import { addHardCodedServiceProperties } from 'views/Dashboard/DataSources/ServiceData/servicePropsToDataProps';
import { Dashboard } from './domains/dashboard';
import { RootStore } from './rootStore';
import { StoreBase } from './storeBase';

type ExtendedServiceProperty = IntAtlasServicePropertyDto & {
  serviceName: string;
};

export class DashboardStore extends StoreBase {
  @observable dashboards: Dashboard[] = [];
  @observable customerDashboards: IntDashboardDto[] = [];
  @observable dashboardTemplates: IntDashboardDto[] = [];

  @observable serviceOptions: IntAtlasServiceDto[] | undefined;
  @observable services: IntAtlasServiceDto[] = []; // Full details, including service properties TO BE REMOVED, DONT USE!
  @observable serviceProperties: ExtendedServiceProperty[] = [];

  @observable analyticsCubeOptions: IntAnalyticsCubeDto[] | undefined;
  @observable analyticsCubes: IntAnalyticsCubeDetailsDto[] = []; // Full details, including aggregates etc
  @observable isDeletingDashboard = false;

  @observable manualRefreshTrigger = 0; // Datasources listen to this, so we can easily trigger a refresh of all of them
  @observable disableManualRefresh = false; // Disable the refresh button for a few seconds after clicking it to prevent spamming

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this);
  }

  @action.bound async loadDashboard(dashboardId: string): Promise<void> {
    if (this.dashboards.find(dash => dash.dashboardId === dashboardId)) {
      return;
    }

    const dashboard = new Dashboard({
      rootStore: this.rootStore,
      dashboardId,
    });

    await dashboard.loadDetails();

    runInAction(() => {
      this.dashboards.push(dashboard);
    });
  }

  @action.bound async loadAllDashboards(): Promise<IntDashboardDto[]> {
    const resp = await this.httpGet(dashboardApi.getAllDashboards, undefined);

    if (resp.status !== 200 && resp.status !== 204) {
      snackbar(i18n.t('dashboard:error.loading'), {
        variant: 'error',
      });
    }

    return resp.data || [];
  }

  getDashboardTemplatesInitiated = false;
  @computed get templateDashboards() {
    if (!this.getDashboardTemplatesInitiated) {
      this.loadDashboardTemplates();
    }
    return this.dashboardTemplates;
  }

  @action.bound getDashboard(dashboardId: string) {
    return this.dashboards.find(dash => dash.dashboardId === dashboardId);
  }
  @action.bound async loadDashboardTemplates() {
    this.getDashboardTemplatesInitiated = true;
    this.dashboardTemplates = [];
    const resp = await this.httpGet(
      dashboardApi.getDashboardTemplates,
      undefined
    );

    if (resp.status === 200 || resp.status === 204) {
      runInAction(() => {
        this.dashboardTemplates = resp.data || [];
      });
    } else {
      snackbar(i18n.t('dashboard:error.loading'), {
        variant: 'error',
      });
    }
  }

  getCustomerDashboardsInitiated = false;
  @computed get dashboardsByCustomer() {
    if (!this.getCustomerDashboardsInitiated) {
      this.loadDashboardsByCustomer();
    }
    return this.customerDashboards;
  }

  @action.bound async loadDashboardsByCustomer() {
    this.getCustomerDashboardsInitiated = true;
    this.customerDashboards = [];
    const customerId = this.rootStore.authStore.user?.customerId;
    if (customerId) {
      const resp = await this.httpGet(
        dashboardApi.getDashboardsByCustomer,
        customerId
      );
      if (resp.status === 200 || resp.status === 204) {
        runInAction(() => {
          this.customerDashboards = resp.data || [];
        });
      } else {
        snackbar(i18n.t('dashboard:error.loading'), {
          variant: 'error',
        });
      }
    }
  }

  @action.bound async createDashboard(data: IntCreateDashboardRequestDto) {
    const resp = await this.httpPost(dashboardApi.createDashboard, {
      params: undefined,
      data,
    });
    const createdDashboard = resp.data;

    if (resp.status === 201 && createdDashboard) {
      runInAction(() => {
        snackbar(i18n.t('dashboard:success.created'), {
          variant: 'success',
        });

        aiTrackEvent('Create', { title: 'Dashboard' });

        this.dashboards.push(
          new Dashboard({
            rootStore: this.rootStore,
            dashboardId: createdDashboard.dashboardId,
          })
        );

        if (createdDashboard.menuItem) {
          this.rootStore.layoutStore.updateNavigation(
            createdDashboard.menuItem,
            'add'
          );
        }
      });
    } else {
      snackbar(i18n.t('dashboard:error.creation'), {
        variant: 'error',
      });
    }

    return createdDashboard;
  }

  @action.bound async deleteDashboard(
    dashboardToDelete: Dashboard,
    onDeleteComplete: () => void
  ) {
    const resp = await this.httpDelete(
      dashboardApi.deleteDashboard,
      {
        params: dashboardToDelete.dashboardId,
      },
      isDeleting => runInAction(() => (this.isDeletingDashboard = isDeleting))
    );

    if (resp.status === 204) {
      snackbar(i18n.t('dashboard:success.deleted'), { variant: 'success' });

      aiTrackEvent('Delete', { title: 'Dashboard' });

      runInAction(() => {
        this.dashboards = this.dashboards.filter(
          dash => dash !== dashboardToDelete
        );
        if (dashboardToDelete.menuItem) {
          this.rootStore.layoutStore.updateNavigation(
            dashboardToDelete.menuItem,
            'delete'
          );
        }
      });
      onDeleteComplete();
    } else {
      snackbar(i18n.t('dashboard:dashboard_delete_error'), {
        variant: 'error',
      });
    }
  }

  @observable hasLoadedServices = false;
  @observable servicePromise: Promise<void> | undefined;

  @action.bound async loadServiceOptions(): Promise<void> {
    if (!this.servicePromise) {
      this.servicePromise = new Promise(async res => {
        const resp = await this.httpGet(serviceApi.getAll, {
          filters: '',
          orderBy: '',
          orderDesc: false,
          page: 0,
          pageSize: 99999,
          search: '',
        });

        if (resp.status === 200 || resp.status === 204) {
          runInAction(() => {
            resp.data?.rows.forEach(service => {
              service.properties.forEach(prop =>
                this.serviceProperties.push({
                  ...prop,
                  serviceName: service.displayName,
                })
              );
            });
            this.serviceOptions = resp.data?.rows || [];
          });
        } else {
          snackbar(i18n.t('dashboard:error.services'), { variant: 'error' });
        }
        res();
      });
    }

    return this.servicePromise;
  }

  @action.bound async getService(serviceId: string) {
    const existingService = this.services.find(
      svc => svc.serviceId === serviceId
    );
    if (existingService) {
      return;
    }
    // Ideally we'd keep track of isLoading for each service here - because many widgets can try to load it at once.
    // Not a huge deal, but optimize later?
    // This boldly assumes you have the role "View Service" - unclear what would happen otherwise
    const resp = await this.httpGet(serviceApi.getService, serviceId);
    const service = resp.data;
    if (resp.status === 200 && service) {
      addHardCodedServiceProperties(service);
      runInAction(() => {
        this.services.push(service);
      });
    } else {
      snackbar(i18n.t('dashboard:error.service'), { variant: 'error' });
    }
  }

  @action.bound async loadAnalyticsCubeOptions() {
    if (this.analyticsCubeOptions) {
      return;
    }
    const resp = await this.httpGet(analyticsCubeApi.getAll, {
      filters: '',
      orderBy: '',
      orderDesc: false,
      page: 0,
      pageSize: 9999,
      search: '',
    });

    if (resp.status === 200 || resp.status === 204) {
      runInAction(() => {
        this.analyticsCubeOptions = resp.data?.rows || [];
      });
    } else {
      snackbar(i18n.t('dashboard:error.analytics_cube_plural'), {
        variant: 'error',
      });
    }
  }

  @action.bound async getAnalyticsCube(analyticsCubeId: string) {
    const existingCube = this.analyticsCubes.find(
      cube => cube.analyticsCubeId === analyticsCubeId
    );

    if (existingCube) {
      return;
    }

    const resp = await this.httpGet(
      analyticsCubeApi.getDetails,
      analyticsCubeId
    );
    const analyticsCube = resp.data;
    if (resp.status === 200 && analyticsCube) {
      runInAction(() => {
        this.analyticsCubes.push(analyticsCube);
      });
    } else {
      snackbar(i18n.t('dashboard:error.analytics_cube'), { variant: 'error' });
    }
  }

  // The selected options for all filters on all dashboards
  @observable @persist('object') filterValuesByDashboardId: Record<
    string, // dashboardId
    Record<string, IntServiceDataFilterOptionDto[]> // filter key - options
  > = {};

  @action.bound refreshWidgetData() {
    this.manualRefreshTrigger++;
    this.disableManualRefresh = true;

    window.setTimeout(
      () =>
        runInAction(() => {
          this.disableManualRefresh = false;
        }),
      5000
    );
  }
}
