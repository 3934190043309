import Unauthorized from 'components/Unauthorized';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { Route } from 'react-router-dom';
import { flattenedRoutes } from 'Routes';
import { AtlasLayoutType } from 'shared/types/atlas-layout-type';
import { useStores } from 'store';
import DocumentTitle from '../DocumentTitle';
import { EFeature } from '../EFeature';
import { Role } from '../Role';
import { useRedirectToLogin } from '../useRedirectToLogin';

interface IProps {
  layout?: AtlasLayoutType;
}

const RouteRenderer: React.FC<IProps> = ({ layout }) => {
  return (
    <>
      {flattenedRoutes
        .filter(r => r.component && r.layout === layout && r.path)
        .map(r => (
          <Route
            key={r.text + '-' + r.path.length} // Not very nice, but its being removed in part 2/2
            path={r.path}
            exact={r.exact}
          >
            <DocumentTitle title={r.text} />
            <RequireAuth roles={r.roles} feature={r.feature}>
              {r.component}
            </RequireAuth>
          </Route>
        ))}
    </>
  );
};

export default RouteRenderer;

const RequireAuth: FC<{
  feature?: EFeature;
  roles?: Role | Role[];
}> = observer(({ feature, roles, children }) => {
  const {
    authStore: { isLoggedIn, hasRole, hasFeature },
  } = useStores();

  /**
   * Make sure this covers all cases with some tests
   * Its supposed to render children IF
   * - User is logged in
   * - User has any roles or feature associated with the route
   * - There are no roles at all (confusing, but thats how it used to work. This will be removed in 2/2 anyhow)
   */

  const authorized =
    (isLoggedIn && hasRole(roles) && hasFeature(feature)) ||
    roles === undefined;

  if (roles && !isLoggedIn) {
    return useRedirectToLogin();
  }

  return authorized ? <>{children}</> : <Unauthorized />;
});
