import {
  dataSourceConfigs,
  IDataSourceSettings,
} from './DataSources/dataSourceTypes';

export function fixOldDataSourceSettings(settings: IDataSourceSettings) {
  try {
    const { fixSettings } = dataSourceConfigs[settings.type];
    if (fixSettings) {
      fixSettings(settings);
    }
  } catch (error) {
    console.warn(error);
  }
}
