import { rnd } from 'utils';

export const GeofenceService = {
  getGeofences: {
    url: '/api/geofences',
    mockData: () => {
      const randomPolygonPoints = getPolygonPoints();
      return {
        status: 200,
        data: {
          rows: [
            {
              displayName: 'Polygon 1',
              geofenceRadius: {
                longitude: 0,
                latitude: 0,
                radius: 0,
                automationRuleTriggerGeofenceId: 'f4ke-fds23-dss43-fds43',
              },
              geofenceType: 0,
              id: 'f4ke-f236-43e9-b539-1681bde984a4',
              polygonPoints: [
                ...randomPolygonPoints,
                {
                  ...randomPolygonPoints[0],
                  order: 4,
                },
              ],
            },
            {
              displayName: 'Radius 1',
              geofenceRadius: getCirclePoints(),
              geofenceType: 1,
              id: 'f4ke-f236-43e9-b539-1691bde984a4',
              polygonPoints: [
                ...randomPolygonPoints,
                {
                  ...randomPolygonPoints[0],
                  order: 4,
                },
              ],
            },
          ],
          total: 0,
        },
      };
    },
  },
};

const getPolygonPoints = () =>
  Array.from({ length: 4 }).map((x, i) => ({
    longitude:
      18.071667 +
      ((i === 0 || i === 3 ? rnd.int(-2, -1) : rnd.int(0, 1)) / 1000) *
        18.071667,
    latitude:
      59.326944 +
      ((i === 0 || i === 1 ? rnd.int(-2, -1) : rnd.int(0, 1)) / 1000) *
        59.326944,
    order: i,
    polygonPointId: `fake_${i}_polygon_point_id`,
    automationRuleTriggerGeofenceId: `fake_${i}_polygon_automation_rule_trigger_id`,
  }));

const getCirclePoints = () => ({
  longitude: 18.071667 + (rnd.int(-100, 0) / 1000) * 18.071667,
  latitude: 59.326944 + (rnd.int(-50, 50) / 1000) * 59.326944,
  radius: 10000,
  automationRuleTriggerGeofenceId: 'f4ke-fds23-dss43-fds43',
});
