import { DashboardComponentType } from 'generated';
import {
  IBaseComponentSettings,
  IComponentTypeConfig,
} from 'views/Dashboard/dashboardTypes';
import { DataPropSetting } from 'views/Dashboard/DataSources/dataPropTypes';
import { IDataSourceSettings } from 'views/Dashboard/DataSources/dataSourceTypes';
import GaugeWidget from './GaugeWidget';
import GeneralSettingsStep from './SettingsSteps/GeneralSettingsStep';
import PreviewStep from './SettingsSteps/PreviewStep';

export interface IGaugeSettings extends IBaseComponentSettings {
  title: string;
  dataSource: IDataSourceSettings;

  valueProp: DataPropSetting;
  valueUnit: string;

  valueMin: number;
  valueMax: number;

  // Remove once all (important) widgets use policies instead
  // See PBI #68512
  colors: {
    green: { min?: number; max?: number };
    yellow: { min?: number; max?: number };
    orange: { min?: number; max?: number };
    red: { min?: number; max?: number };
  };

  showTimestamp?: boolean;
  timeInterval?: number;

  policyId: string;
}

export const gaugeComponentConfig: IComponentTypeConfig<
  DashboardComponentType.Gauge,
  IGaugeSettings
> = {
  widgetType: DashboardComponentType.Gauge,
  icon: 'Games',
  Component: GaugeWidget,
  settingsSteps: [
    {
      title: 'settings',
      Component: GeneralSettingsStep,
    },
    {
      title: 'final_preview',
      Component: PreviewStep,
    },
  ],
  getDefaultSettings: ({ dataSource }) => {
    const valueProp = dataSource.dataProperties.find(
      dp => dp.type === 'number'
    );

    return {
      position: { x: 0, y: 0, w: 4, h: 4 },
      title: '',
      dataSource: dataSource.settings,
      timeInterval: 60,

      valueProp: valueProp?.id || '',
      valueMin: valueProp?.min ?? 0,
      valueMax: valueProp?.max ?? 100,
      valueUnit: valueProp?.unit || '',
      policyId: '',

      colors: {
        green: {},
        yellow: {},
        orange: {},
        red: {},
      },
    };
  },
};
