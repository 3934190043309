import {
  IntAddSharedAssetsRequestDto,
  IntCreateCustomerRequest,
  IntCustomerDto,
  IntCustomerLinkDto,
  IntCustomerProfileDto,
  IntCustomerTableDto,
  IntEditCustomerProfileDto,
  IntSearchDto,
} from 'generated';
import {
  DeleteEndpoint,
  GetEndpoint,
  PostEndpoint,
  PostTableEndpoint,
  PutEndpoint,
  TableEndpoint,
} from 'shared/interfaces/api';
import { rnd } from 'utils';
import { customerDemoData } from './demoData/customerDemoData';

type CustomerAPI = {
  getAll: TableEndpoint<IntCustomerTableDto>;
  getLinks: GetEndpoint<string, IntCustomerLinkDto[]>;
  updateLinks: PostEndpoint<string, IntCustomerLinkDto[]>;
  getCustomerInfo: GetEndpoint<string, IntCustomerProfileDto>;
  updateCustomerInfo: PostEndpoint<string, IntEditCustomerProfileDto>;
  deleteCustomer: DeleteEndpoint<string>;
  createCustomer: PostEndpoint<
    undefined,
    IntCreateCustomerRequest,
    IntCustomerDto
  >;
  getCustomerFromAsset: (
    assetId: string
  ) => PostTableEndpoint<IntSearchDto, IntCustomerTableDto>;
  shareAssets: PutEndpoint<undefined, IntAddSharedAssetsRequestDto>;
};

export const matchableCustomerId = rnd.guid();

const baseUrl = '/api/customer';

export const customerAPI: CustomerAPI = {
  getAll: {
    url: `${baseUrl}/table`,
    mockData: customerDemoData.getAll,
  },
  getLinks: {
    url: customerId => `${baseUrl}/${customerId}/links`,
    mockData: customerId => customerDemoData.getLinks(customerId),
  },
  updateLinks: {
    url: customerId => `${baseUrl}/${customerId}/links`,
    mockData: customerDemoData.updateLinks,
  },
  getCustomerInfo: {
    url: customerId => `${baseUrl}/${customerId}/customerinfo`,
    mockData: customerDemoData.getCustomerInfo,
  },
  updateCustomerInfo: {
    url: customerId => `${baseUrl}/${customerId}/customerinfo`,
    mockData: (_, updatedInfo) =>
      customerDemoData.updateCustomerInfo(updatedInfo),
  },
  deleteCustomer: {
    url: customerId => `${baseUrl}/${customerId}`,
    mockData: () => ({
      status: 200,
    }),
  },
  createCustomer: {
    url: baseUrl,
    mockData: () => ({ status: 200 }),
  },
  getCustomerFromAsset: assetId => ({
    url: `${baseUrl}/${assetId}/table`,
  }),
  shareAssets: {
    url: `${baseUrl}/sharedAssets`,
    mockData: () => ({
      status: 200,
    }),
  },
};
