import { DashboardComponentType } from 'generated';
import { IComponentTypeConfig } from '../dashboardTypes';
import { azureMapsComponentConfig } from './AzureMapsComponent/azureMapsConfig';
import { columnChartComponentConfig } from './ColumnChartComponent/columnChartConfig';
import { filtersConfig } from './FiltersComponent/filtersConfig';
import { gaugeComponentConfig } from './GaugeComponent/gaugeConfig';
import { groupedBarChartComponentConfig } from './GroupedBarChartComponent/groupedBarChartConfig';
import { highchartsMapComponentConfig } from './HighchartsMapComponent/highchartsMapConfig';
import { imageComponentConfig } from './ImageComponent/imageConfig';
import { lineChartComponentConfig } from './LineChartComponent/lineChartConfig';
import { numberComponentConfig } from './NumberComponent/numberConfig';
import { numberGridComponentConfig } from './NumberGridComponent/numberGridConfig';
import { pieChartComponentConfig } from './PieChartComponent/pieChartConfig';
import { tableComponentConfig } from './TableComponent/tableConfig';

export const componentTypeConfigs: {
  [T in DashboardComponentType]: IComponentTypeConfig<T, any>;
} = {
  [DashboardComponentType.Unknown]: null as any, // This really shouldn't EVER happen, but we do as the types command us
  [DashboardComponentType.LineChart]: lineChartComponentConfig,
  [DashboardComponentType.AzureMaps]: azureMapsComponentConfig,
  [DashboardComponentType.ColumnChart]: columnChartComponentConfig,
  [DashboardComponentType.Table]: tableComponentConfig,
  [DashboardComponentType.Number]: numberComponentConfig,
  [DashboardComponentType.Gauge]: gaugeComponentConfig,
  [DashboardComponentType.Image]: imageComponentConfig,
  [DashboardComponentType.Filters]: filtersConfig,
  [DashboardComponentType.PieChart]: pieChartComponentConfig,
  [DashboardComponentType.HighchartsMap]: highchartsMapComponentConfig,
  [DashboardComponentType.NumberGrid]: numberGridComponentConfig,
  [DashboardComponentType.GroupedBarChart]: groupedBarChartComponentConfig,
};
