import { Chip, Tooltip } from '@mui/material';
import AtlasIcon from 'components/AtlasIcon';

const beEnv = process.env.REACT_APP_API_URL?.includes('localhost')
  ? 'local'
  : process.env.REACT_APP_API_URL?.includes('test')
    ? 'test'
    : process.env.REACT_APP_API_URL?.includes('dev')
      ? 'dev'
      : 'prod';

const DevelopmentChip: React.FC = () => (
  <Tooltip
    placement="bottom"
    title={`URL: ${process.env.REACT_APP_API_URL}`}
    arrow
  >
    <Chip
      size="medium"
      color="primary"
      label={`Using ${beEnv} backend`}
      icon={<AtlasIcon type="Alert" size="18" />}
      translate="yes"
    />
  </Tooltip>
);

export default DevelopmentChip;
