import { createStyles, makeStyles } from '@mui/styles';
import { pxToRem } from 'styles/themes/makeAtlasTheme';

export const useStyles = makeStyles(theme =>
  createStyles({
    overlay: {
      backgroundColor:
        theme.palette.mode === 'dark'
          ? 'rgba(0, 0, 0, .3)'
          : 'rgba(255, 255, 255, .6)',
      pointerEvents: 'none',
      transition: 'opacity 250ms ease-out',
      opacity: 0,
    },
    open: {
      opacity: 1,
      zIndex: 300,
      pointerEvents: 'all',
      transition: 'none',
    },
    interactiveOverlay: {
      pointerEvents: 'none',
    },
    nonTransparent: {
      backgroundColor:
        theme.palette.mode === 'dark'
          ? 'rgba(0, 0, 0, 1)'
          : 'rgba(255, 255, 255, 1)',
    },
    margin: {
      marginTop: theme.spacing(3),
    },
    bulkCounter: {
      fontWeight: 'bold',
      fontSize: pxToRem(14),
      marginTop: theme.spacing(3),
    },
    bulkError: {
      color: theme.palette.error.main,
      marginTop: 0,
    },
  })
);
