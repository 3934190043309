import { IconButton, List, ListItem } from '@mui/material';
import classNames from 'classnames';
import AtlasIcon from 'components/AtlasIcon';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import {
  SortableContainer,
  SortableElement,
  SortEndHandler,
} from 'react-sortable-hoc';
import { arrayMoveMutate } from 'utils';
import { useStyles } from './ListControl.styles';

interface IProps<TValue> {
  items: TValue[];
  children: (item: TValue) => React.ReactNode;
}

const SortableList = SortableContainer(List);

function SortableListControl<TValue>(props: IProps<TValue>) {
  const { items, children } = props;
  const classes = useStyles();

  const handleRemoveClick = (index: number) => {
    runInAction(() => {
      items.splice(index, 1);
    });
  };

  const handleSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
    runInAction(() => {
      arrayMoveMutate(items, oldIndex, newIndex);
    });
  };

  if (!items.length) {
    return null;
  }

  return (
    <SortableList
      className={classes.rowList}
      dense
      onSortEnd={handleSortEnd}
      helperClass="is-dragging"
      distance={10}
      lockAxis="y"
    >
      {items.map((item, i) => (
        <SortableListItem
          key={i}
          index={i}
          divider={i < items.length - 1}
          className={classNames(classes.row, classes.draggableRow)}
        >
          <AtlasIcon disabled type="DragHandle" className={classes.icon} />

          <div className={classes.rowContent}>{children(item)}</div>

          <IconButton onClick={() => handleRemoveClick(i)} size="large">
            <AtlasIcon type="Clear" size={14} />
          </IconButton>
        </SortableListItem>
      ))}
    </SortableList>
  );
}

interface IListItemProps {
  className: string;
  divider?: boolean;
}

const WrappedListItem: React.FC<IListItemProps> = ({
  className,
  divider,
  children,
}) => (
  // react-sortable-hoc doesn't play entirely nice with ListItem if this div isn't present
  <div className={className}>
    <ListItem divider={divider}>{children}</ListItem>
  </div>
);
const SortableListItem = SortableElement(WrappedListItem);

export default observer(SortableListControl);
