import {
  IntServiceDataSpecificationFilterDto,
  ServiceDataFilterType,
} from 'generated';

export function assetIdsToServiceDataFilters(
  assetIds: string[]
): IntServiceDataSpecificationFilterDto[] {
  return [
    {
      type: ServiceDataFilterType.AssetId,
      values: assetIds,
    },
  ];
}
