import {
  ComparisonType,
  IntDashboardDataStatusDto,
  IntDashboardDataStatusRuleDto,
} from 'generated';
import {
  sortArrayByStatusRuleColor,
  sortArrayByStatusRuleComparer,
} from './sortByStatusRuleColor';

export function getActiveNumberStatusRule(
  status: IntDashboardDataStatusDto,
  value: number | null
): IntDashboardDataStatusRuleDto | null {
  if (value === null) {
    return null;
  }

  // Sort by rule color, red first, to always pick the most urgent rule if there are overlaps
  for (const rule of sortArrayByStatusRuleColor(
    status.rules,
    rule => rule.color
  )) {
    const { min, max } = rule;

    if (
      (typeof min !== 'number' || value >= min) &&
      (typeof max !== 'number' || value <= max)
    ) {
      return rule;
    }
  }

  return null;
}

export function getActiveStringStatusRule(
  status: IntDashboardDataStatusDto,
  value: string | null
): IntDashboardDataStatusRuleDto | null {
  if (value === null) {
    return null;
  }

  for (const rule of sortArrayByStatusRuleComparer(status.rules)) {
    const { textValue, textComparer } = rule;

    //make policies that are equal to more important than not equal to if there are overlap
    if (
      (textComparer === ComparisonType.EqualTo &&
        value.toLowerCase() === textValue?.toLowerCase()) ||
      (textComparer === ComparisonType.NotEqualTo &&
        value.toLowerCase() !== textValue?.toLowerCase())
    ) {
      return rule;
    }
  }

  return null;
}

export function useActiveStatusRule(
  status: IntDashboardDataStatusDto | undefined,
  value: number | null
): IntDashboardDataStatusRuleDto | null {
  if (status) {
    return getActiveNumberStatusRule(status, value);
  }

  return null;
}
