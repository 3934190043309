import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => {
  const { settingsMenu, navMenu } = theme.settings.components;

  const navDrawerLeft = navMenu.position !== 'right';

  return createStyles({
    root: {
      position: 'relative',
      flexGrow: 1,
      overflow: 'auto',
      marginRight: navDrawerLeft ? -settingsMenu.width : 0,
      marginLeft: navDrawerLeft ? 0 : -settingsMenu.width,

      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    container: theme.settings.components.contentContainer,
    headerShown: {
      marginTop: theme.settings.components.topMenu.height,
    },
    footerShown: {
      marginBottom: 50,
    },
    settingDrawerOpen: {
      marginRight: 0,
      marginLeft: 0,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  });
});
