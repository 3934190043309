import {
  Box,
  Checkbox as MUICheckbox,
  FormControlLabel,
  FormHelperText,
} from '@mui/material';
import classNames from 'classnames';
import { ErrorMessage, useField } from 'formik';
import React from 'react';
import { useStyles } from './Checkbox.styles';

interface IProps {
  name: string;
  value?: string;
  label?: string;
  color?: 'primary' | 'secondary' | 'default';
  className?: string;
  disabled?: boolean;
  onClick?: (e: React.ChangeEvent<any>) => void;
  disableRipple?: boolean;
  fullWidth?: boolean;
  form?: string;
  labelFontSize?: string;
  noMargin?: boolean;
}

const Checkbox: React.FC<IProps> = ({
  name,
  value,
  label,
  color = 'primary',
  className,
  disabled,
  disableRipple = false,
  fullWidth = false,
  form,
  labelFontSize,
  noMargin = false,
  onClick,
}) => {
  const classes = useStyles();
  const [field, meta] = useField({ name, type: 'checkbox', value });
  const { error, touched } = meta;

  const chkbox = (
    <MUICheckbox
      color={color}
      disabled={disabled}
      className={className}
      disableRipple={disableRipple}
      data-cy={name}
      onClick={onClick}
      {...field}
    />
  );

  return label ? (
    <Box mb={3}>
      <FormControlLabel
        color="primary"
        control={chkbox}
        label={<span style={{ fontSize: labelFontSize }}>{label}</span>}
        className={classNames({
          [classes.fullWidth]: fullWidth,
          [classes.noMargin]: noMargin,
        })}
        form={form}
      />
      {error && touched && (
        <ErrorMessage name={name}>
          {err => <FormHelperText error>{err}</FormHelperText>}
        </ErrorMessage>
      )}
    </Box>
  ) : (
    chkbox
  );
};

export default Checkbox;
