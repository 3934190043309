import { applyHighchartLocalization } from 'components/Highcharts/ThemedHighcharts';
import { Locale } from 'date-fns';
import { enGB, sv } from 'date-fns/locale';
import { Country, IntUserDto } from 'generated';
import i18n from 'i18n';
import { action, autorun, computed, makeObservable, observable } from 'mobx';
import { RootStore } from 'store/rootStore';
import { StoreBase } from 'store/storeBase';

export class User extends StoreBase implements IntUserDto {
  id = '';
  userId = '';
  userName = '';
  firstName = '';
  lastName = '';
  birthDate?: number;
  registerDate?: number;
  customerId = '';
  customerName = '';
  displayName = '';
  dealerId = '';
  dealerName = '';
  lastActivityDate = 0;
  city = '';
  zipCode = '';
  address = '';
  middleName = '';
  fullName = '';
  groups = [];
  isVerified = false;
  countryISO3166 = '';
  countryName = '';
  lastActivityIP = '';
  registerIP = '';
  validFrom?: number;
  validUntil?: number;
  utcDiff = 0;
  email = '';
  phoneNumber = '';
  phoneNumberConfirmed = false;
  emailConfirmed = false;
  isEmailVerificationRequired = false;
  googleAuthenticatorSecretKey = '';
  site = '';
  lockoutEnabled = true;

  @observable profilePicturePath = '';
  @observable customerLogo = '';
  @observable isGoogleAuthenticatorEnabled?: boolean;
  @observable twoFactorEnabled = false;
  @observable languageCulture = '';
  @observable addressMasterCountryCode: Country = 0;

  constructor(rootStore: RootStore, obj?: IntUserDto) {
    super(rootStore);
    makeObservable(this);
    this.setLanguageCulture(obj?.languageCulture);
    Object.assign(this, obj);

    autorun(() => (window.__DATEFNS_LOCALE__ = this.locale));
  }

  @action.bound setTwoFaActive(isActive: boolean) {
    this.twoFactorEnabled = isActive;
  }

  @action.bound setIsGoogleAuthenticatorEnabled(isEnabled: boolean) {
    this.isGoogleAuthenticatorEnabled = isEnabled;
  }

  @action.bound updateProfilePicture(path: string) {
    this.profilePicturePath = path;
  }

  @action.bound updateCustomerLogo(path: string) {
    this.customerLogo = path;
  }

  @action.bound setLanguageCulture(languageCulture = 'en-US') {
    this.languageCulture = languageCulture;
    i18n.changeLanguage(languageCulture);
    this.setAddressMasterCountryCode(this.countryName);
    applyHighchartLocalization(this);
    this.rootStore.dashboardStore.services = []; // hacky solution to translate registerDate. Change this when dynamic data translation is implemented.
  }

  @computed get locale(): Locale {
    // Consider rewriting this to an async import() when adding support for more languages, to only import what's needed.
    switch (this.languageCulture) {
      case 'sv-SE':
      case 'sv': {
        return sv;
      }

      case 'en':
      case 'en-US':
      case 'en-GB':
      default: {
        return enGB;
      }
    }
  }

  @action.bound setAddressMasterCountryCode(country: string) {
    if (country === 'Finland') {
      this.addressMasterCountryCode = Country.Finland;
    } else if (country === 'Denmark') {
      this.addressMasterCountryCode = Country.Denmark;
    } else if (country === 'Norway') {
      this.addressMasterCountryCode = Country.Norway;
    } else {
      this.addressMasterCountryCode = Country.Sweden;
    }
  }
}
