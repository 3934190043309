import { Box, Grid } from '@mui/material';
import { Option } from 'components/FormFields';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ComponentContainer from 'views/Dashboard/ComponentContainer';
import { IDashboardComponent } from 'views/Dashboard/dashboardTypes';
import { useDataSource } from 'views/Dashboard/DataSources';
import { Control } from 'views/Dashboard/SettingControls';
import SettingsForShowLatestAssetUpdate from 'views/Dashboard/SettingsForShowLatestUpdateFromAsset';
import { INumberSettings, TextSize } from '../numberConfig';

const backgroundColorOptions: Option[] = [
  {
    label: 'White',
    value: '#FFFFFF',
  },
  {
    label: 'Black',
    value: '#000000',
  },
];

const textSizeOptions: Option<TextSize>[] = [
  {
    label: 'Small',
    value: '32px',
  },
  {
    label: 'Medium',
    value: '64px',
  },
  {
    label: 'Large',
    value: '96px',
  },
];

const GeneralSettingsStep: IDashboardComponent<INumberSettings> = ({
  component,
}) => {
  const { t } = useTranslation('dashboard');
  const { settings, dashboard } = component;
  const dataSource = useDataSource(settings.dataSource);

  const dataPropId =
    typeof settings.valueProp === 'object'
      ? settings.valueProp.id
      : settings.valueProp;

  useEffect(() => {
    const newDataProp = dataSource.dataProperties.find(
      dp => dp.id === dataPropId
    );
    if (newDataProp && newDataProp.unit !== settings.unit) {
      dashboard.updateSetting(settings, 'unit', newDataProp.unit || '');
    }
  }, [dashboard, dataSource, dataPropId, settings]);

  return (
    <Grid container spacing={4}>
      <Grid item sm={6}>
        <Control.Group label={t('general_settings')}>
          <Grid container spacing={4}>
            <Grid item sm={12}>
              <Control.Text object={settings} name="title" label={t('title')} />
              <SettingsForShowLatestAssetUpdate settings={settings} />
              <Control.DataProperty
                object={settings}
                name="valueProp"
                label={t('number.property')}
                options={dataSource.dataProperties || []}
                allowDate={false}
                allowString={false}
              />
            </Grid>

            <Grid item sm={6}>
              <Control.AutoComplete
                object={settings}
                options={textSizeOptions}
                name="textSize"
                label={t('number.text_size')}
              />
            </Grid>

            <Grid item sm={6}>
              <Control.AutoComplete
                object={settings}
                options={backgroundColorOptions}
                name="backgroundColor"
                label={t('number.background_color')}
              />
            </Grid>
            <Grid item sm={12}>
              <Control.Text
                object={settings}
                name="unit"
                label={t('number.enter_unit')}
              />
            </Grid>
          </Grid>
        </Control.Group>
      </Grid>

      <Grid item sm={6}>
        <Control.Group label={t('preview')}>
          <Box height={300}>
            <ComponentContainer component={component} />
          </Box>
        </Control.Group>
      </Grid>
    </Grid>
  );
};

export default observer(GeneralSettingsStep);
