import { Box } from '@mui/material';
import { Role } from 'components/Auth/Role';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'store';
import AddDashboardButton from './AddDashboardButton';
import BottomAdminMenuItem from './BottomAdminMenuItem';
import EditPolicyMenuItem from './EditPolicyMenuItem';
import MenuItem from './MenuItem';
import SubCategory from './MenuItem/SubCategory';
import SwipableViewsWrapper from './SwipableViewsWrapper/SwipableViewsWrapper';
import { useNavMenuState } from './useNavMenuState';

const NavMenuContent: React.FC = () => {
  const {
    layoutStore: { navigation, myDashboardMenuItem },
    authStore: { enablePreviewFeatures, hasRole },
  } = useStores();
  const { t } = useTranslation();
  const navMenuState = useNavMenuState();
  const { canCreateDashboard, activeRootItem } = navMenuState;

  const swipeIndex =
    activeRootItem?.children.length || (activeRootItem && !activeRootItem.route)
      ? 1
      : 0;

  return (
    <>
      <nav style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        {/* Static link to my dashboard */}
        {enablePreviewFeatures && (
          <MenuItem
            item={myDashboardMenuItem}
            isRoot
            navMenuState={navMenuState}
          />
        )}

        {/* Bottom, swipeable, part */}
        <Box flex={1} overflow="auto">
          <SwipableViewsWrapper index={swipeIndex}>
            <Box height="100%">
              {navigation?.menuItems.map(item => (
                <MenuItem
                  key={item.menuItemId}
                  item={item}
                  navMenuState={navMenuState}
                  isRoot
                />
              ))}
            </Box>

            <Box height="100%">
              {activeRootItem && (
                <SubCategory
                  parent={activeRootItem}
                  navMenuState={navMenuState}
                />
              )}
            </Box>
          </SwipableViewsWrapper>
        </Box>
      </nav>
      {canCreateDashboard && activeRootItem && (
        <AddDashboardButton routeItem={activeRootItem} />
      )}

      {hasRole(Role.RoleNameEditDashboardDataStatus) && (
        <EditPolicyMenuItem icon="Smiley" label={t('policy:dialog_title')} />
      )}

      {hasRole([
        Role.RoleNameViewProductTemplate,
        Role.RoleNameEditProductTemplate,
      ]) && (
        <BottomAdminMenuItem
          dataCy="manageProductTemplatesButton"
          icon="Application"
          to="/product-template/manage"
          label={t('product:template.link')}
        />
      )}
      {hasRole([
        Role.RoleNameViewUiConfiguration,
        Role.RoleNameEditUiConfiguration,
      ]) && (
        <BottomAdminMenuItem
          dataCy="manageUiConfigsButton"
          icon="DefaultImage"
          to="/uiconfig/manage"
          label={t('uiconfig:manage.link')}
        />
      )}
      {hasRole([Role.RoleNameViewNavigation, Role.RoleNameEditNavigation]) && (
        <BottomAdminMenuItem
          dataCy="editNavigationItems"
          icon="Pencil"
          to="/navigation/manage"
          label={t('navigation:manage.edit_navigation')}
        />
      )}
      {hasRole(Role.AccessSystemJobsDashboard) && (
        <BottomAdminMenuItem
          icon="Statistics"
          to={`${process.env.REACT_APP_API_URL}/jobs`}
          label={t('common:action.jobs_dashboard')}
          dataCy="navigateJobsDashboard"
        />
      )}
    </>
  );
};

export default observer(NavMenuContent);
