import { darken } from '@mui/material';
import { IUiConfiguration } from '../IUiConfiguration';
import { SupportColors } from '../supportColors';
import { ThemeColors } from '../themeColors';

export const colorsConfig = (uiConfig: IUiConfiguration) => {
  const primaryColor = {
    dark: darken(uiConfig.colors.primary, 0.165),
    darker: darken(uiConfig.colors.primary, 0.345),
  };

  const colors: ThemeColors = {
    blue: '#007bff',
    green: '#029899',
    lightgreen: '#CCEFDD',
    orange: '#ec8d00',
    purple: '#9b009a',
    darkgray: '#333',
    lightgray: '#6e6e6e',
    gray: '#555',
    pink: '#ffdbe8',
    lightblue: '#FF0000',
    yellow: '#ffd06b',
    lightyellow: '#ffe0ad',
    red: '#FF0000',
    lightred: '#FAD1DE',
    darkGreen: '#007e3f',
    darkPurple: '#570580',
    lightPink: '#f9eefe',
    primaryColorDark: primaryColor.dark,
    primaryColorDarker: primaryColor.darker,
  };

  const supportColors: SupportColors = {
    lightBlue: '#CAEAFF',
    lightOrange: '#FFE1B3',
    lightGreen: '#C5EFD9',
    lightGray: '#E5E5EB',
    lightPurple: '#F5E0FF',
    lightRed: '#FFDDE8',
    darkBlue: '#014D80',
    darkOrange: '#6A4102',
    darkGreen: '#02562B',
    darkPurple: '#4E0174',
    darkGray: 'rgba(0,0,0,0.71)',
    darkRed: '#980233',
  };

  return { colors, primaryColor, supportColors };
};
