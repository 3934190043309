import { SvgIcon, SvgIconProps, Theme } from '@mui/material';
import { TableData } from 'components/Table';
import { IntSearchDto } from 'generated';
import i18n from 'i18next';
import React, { FunctionComponent } from 'react';
import { AtlasIconType } from 'shared/types/atlas-icon';
import { StatusVariant } from 'shared/types/status-variant';
import { $enum } from 'ts-enum-util';
import { rndInt } from './random';

export const mapToSvgIcon = (
  component: React.FunctionComponent<React.SVGProps<SVGSVGElement>>,
  viewBox?: string
): FunctionComponent<SvgIconProps> => {
  return props => (
    <SvgIcon
      component={component}
      {...props}
      viewBox={viewBox ? viewBox : '0 0 64 64'}
    />
  );
};

export function paginate<T>(rows: T[], params?: IntSearchDto): TableData<T> {
  const page = params?.page ?? 0;
  const pageSize = params?.pageSize ?? 50;

  return {
    rows: rows.slice(page * pageSize, page * pageSize + pageSize),
    total: rows.length,
  };
}

export const getStatusIcon = (status: string): AtlasIconType => {
  switch (status) {
    case 'Ready':
    case 'Active':
    case 'New':
      return 'StatusOk';
    case 'Pending':
      return 'StatusPending';
    case 'Unknown':
      return 'StatusUnknown';
    case 'Blocked':
    default:
      return 'StatusError';
  }
};

export function delay(ms: number, msMax?: number): Promise<void> {
  return new Promise(res => {
    const delayMs = msMax ? rndInt(ms, msMax) : ms;
    window.setTimeout(res, delayMs);
  });
}

export function arrayMoveMutate<T>(array: T[], from: number, to: number) {
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
}

export function arrayMove<T>(array: T[], from: number, to: number) {
  array = array.slice();
  arrayMoveMutate(array, from, to);
  return array;
}

export const getColor = (variant: StatusVariant, theme: Theme) => {
  let color = undefined;
  switch (variant) {
    case 'success': {
      color = theme.palette.success.main;
      break;
    }
    case 'new': {
      color = theme.palette.info.main;
      break;
    }
    case 'error': {
      color = theme.palette.error.main;
      break;
    }
    case 'warning': {
      color = theme.palette.warning.main;
      break;
    }
    case 'unconfigured': {
      color = theme.palette.unconfigured.main;
      break;
    }
    default: {
      break;
    }
  }

  return color;
};

// Trigger a resize on the window to force updates of various calculated positions
export function triggerWindowResize() {
  window.dispatchEvent(new Event('resize'));
}

// Same as above, but after a brief delay (hacky solution, but it works...)
let resizeTimeout: number;

export function delayedTriggerWindowResize() {
  window.clearTimeout(resizeTimeout);
  resizeTimeout = window.setTimeout(triggerWindowResize, 500);
}

export function getId(prefix: string) {
  return `${prefix}${new Date().getTime()}`;
}

export function mapEnum<T>(
  enumerable: T,
  translation: string,
  convertEnumValueToString = false
) {
  return $enum(enumerable as any)
    .getValues()
    .map(v => ({
      label: i18n.t(`${translation}.${v}`),
      value: convertEnumValueToString ? v.toString() : v,
    }));
}

export function mapEnumToJSobjects(enumerable: { [x: string]: any }) {
  const enumMembers = Object.keys(enumerable).map(key => enumerable[key]);
  const half = Math.ceil(enumMembers.length / 2);
  const firstHalf = enumMembers.splice(0, half);
  const secondHalf = enumMembers.splice(-half);
  return firstHalf.map((key, index) => {
    return {
      name: key,
      value: secondHalf[index],
    };
  });
}

// Values = an array of objects
// Key = key value
export function hasDuplicates<T extends {}>(key: keyof T) {
  return (values: T[]) => {
    const uniqueValues = new Set(values.map(v => v[key]));

    return uniqueValues.size !== values.length;
  };
}

export function cloneJSON<T>(inputObject: T): T {
  return JSON.parse(JSON.stringify(inputObject)) as T;
}

export function findAndReplaceInArray(
  val: string[],
  item: string,
  replaceItem: string
): string[] {
  const value = [...val];
  value.includes(item) && value.splice(value.indexOf(item), 1, replaceItem);
  return value;
}

export function distinctFilter(value: any, index: number, self: Array<any>) {
  return self.indexOf(value) === index;
}

export function saveFile(data: BlobPart, type: string, fileName: string) {
  const blob = new Blob([data], {
    type,
  });

  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.click();

  window.URL.revokeObjectURL(link.href);
}

// Group and aggregate the array of data based upon id
export function aggregateArrayOfObjects(
  groupBy: { id: string; aggregateByColumn?: Array<string> },
  list: Array<any>
) {
  const { id, aggregateByColumn } = groupBy;
  const aggregatedData: { [key: string]: any } = {};

  list.forEach(value => {
    const index = value[id].replace(/\s+/g, '-');
    const data = aggregatedData[index];

    if (data && aggregateByColumn) {
      aggregateByColumn.forEach(column => {
        data[column] += value[column];
      });
    } else {
      aggregatedData[index] = value;
    }
  });
  return Object.values(aggregatedData);
}

export function sortByString<T>(
  getProp: (obj: T) => string
): (a: T, b: T) => number {
  return (a, b) => {
    const propA = getProp(a)?.toUpperCase();
    const propB = getProp(b)?.toUpperCase();
    if (propA < propB) {
      return -1;
    }
    if (propA > propB) {
      return 1;
    }
    return 0;
  };
}

export function getNumberRangeArray(
  start: number,
  end: number,
  interval: number
) {
  const size = end / interval;
  const result = [];
  let min = start;
  let max = 0;

  for (let i = 0; i < interval; i++) {
    min = i === 0 ? min : max;
    max = size * (i + 1);
    result.push({ min, max });
  }
  return result;
}

export const getValueOrEmptyString = (value?: string): string => value || '';
