import { aiTrackEvent } from 'appInsights';
import { Role } from 'components/Auth/Role';
import { emptyGuid } from 'components/Auth/utils';
import { snackbar } from 'components/Snackbar';
import {
  IntApnHubFirewallRuleDto,
  IntCopyProfileDto,
  IntCreateProfileDto,
  IntEnterpriseVpnDto,
  IntEnterpriseVpnRequestDto,
  IntProfileDto,
  VpnStatus,
} from 'generated';
import i18n from 'i18n/i18n';
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { apnHubAPI } from 'services/apnHub.service';
import { ipAccessAPI } from 'services/ipAccess.service';
import { IResponse } from 'shared/interfaces/api';
import { RootStore } from 'store/rootStore';
import { StoreBase } from 'store/storeBase';

export enum ProfileAction {
  COPY = 'copy',
  DELETE = 'delete',
  UPDATE = 'update',
  CREATE = 'create',
}
enum VpnModalType {
  CONFIGVPN = 'configvpn',
  MANAGEVPNRULES = 'managevpnrules',
  EXPORTVPNCONFIG = 'exportvpnconfig',
}

type ModalState = {
  [key in ProfileAction]: boolean;
} & {
  [key in VpnModalType]: boolean;
};

export class ApnStore extends StoreBase {
  @observable selectedProfileId = '';
  @observable flowchartOpen = false;

  @observable apnProfiles: IntProfileDto[] = [];
  @observable profilesLoading = false;
  @observable profilesError = false;
  profilesInitiated = false;
  @observable vpnErrorMessage: string | undefined;
  @observable vpnError = false;

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }

  @computed get profiles() {
    if (!this.profilesInitiated) {
      this.getProfiles();
    }
    return this.apnProfiles;
  }

  @computed get customerId() {
    return this.rootStore.authStore.user?.customerId || emptyGuid;
  }

  @computed get authCheck() {
    return {
      update: this.rootStore.authStore.hasRole(Role.RoleNameEditIpAccess),
      delete: this.rootStore.authStore.hasRole(Role.RoleNameDeleteIpAccess),
      create: this.rootStore.authStore.hasRole(Role.RoleNameEditIpAccess),
      copy: this.rootStore.authStore.hasRole(Role.RoleNameEditIpAccess),
    };
  }

  @computed get selectedProfile() {
    if (!this.selectedProfileId || this.apnProfiles.length < 1) {
      if (!this.profilesInitiated) {
        this.getProfiles();
      }
      return null;
    }
    return this.apnProfiles.find(
      profile => profile.profileId === this.selectedProfileId
    );
  }

  @action.bound setFlowchartOpen(open: boolean) {
    this.flowchartOpen = open;
  }

  @action.bound async getProfiles() {
    this.profilesInitiated = true;
    this.profilesError = false;
    const resp = await this.httpGet(
      apnHubAPI.getProfiles,
      this.customerId,
      loading => {
        runInAction(() => {
          this.profilesLoading = loading;
        });
      }
    );

    if (resp.status === 200 && resp.data) {
      this.setProfiles(resp.data);
    } else {
      this.setProfilesError(true);
    }
  }

  @action.bound setProfilesError(error: boolean) {
    this.profilesError = error;
  }

  @action.bound setProfiles(profiles: IntProfileDto[]) {
    this.apnProfiles = profiles;

    if (!this.selectedProfileId && profiles.length) {
      this.setSelectedProfileId(profiles[0].profileId);
    }
  }

  @observable enterpriseVpns: IntEnterpriseVpnDto[] = [];
  enterpriseVpnsInitiated = false;

  @computed get enterpriseVpnsList() {
    if (!this.enterpriseVpnsInitiated) {
      this.getEnterpriseVpns();
    }
    return this.enterpriseVpns;
  }

  @action.bound async getEnterpriseVpns() {
    this.enterpriseVpnsInitiated = true;
    const resp = await this.httpGet(
      ipAccessAPI.getEnterpriseVpns,
      this.customerId,
      this.setVpnsLoading
    );

    runInAction(() => {
      if (resp.status === 200 && resp.data) {
        this.enterpriseVpns = resp.data;
      }
    });
  }

  @action.bound setSelectedProfileId(id: string) {
    this.selectedProfileId = id;
  }

  @observable openModal: ModalState = {
    copy: false,
    create: false,
    delete: false,
    update: false,
    configvpn: false,
    managevpnrules: false,
    exportvpnconfig: false,
  };

  @action.bound setOpenModal(state: ModalState) {
    this.openModal = state;
  }

  @observable profileActionsLoading = false;

  @action.bound setProfileActionsLoading(loading: boolean) {
    this.profileActionsLoading = loading;
  }

  @action.bound async copyProfile(profile: IntCopyProfileDto) {
    const resp = await this.httpPost(
      apnHubAPI.copyProfile,
      {
        params: this.customerId,
        data: profile,
      },
      loading => this.setProfileActionsLoading(loading)
    );

    runInAction(() => {
      if (resp.status === 200) {
        snackbar(i18n.t('apnhub:success.profile.copy'), { variant: 'success' });
        this.getProfiles();
      } else {
        snackbar(i18n.t('apnhub:error.profile.copy'), {
          variant: 'error',
        });
      }

      this.closeModal();
    });
  }

  @action.bound async deleteProfile(profile: IntCopyProfileDto) {
    const resp = await this.httpDelete(
      apnHubAPI.deleteProfile,
      {
        params: {
          customerId: this.customerId,
          profileId: profile.profileId,
        },
      },
      loading => this.setProfileActionsLoading(loading)
    );

    runInAction(() => {
      if (resp.status === 200) {
        aiTrackEvent('Delete', { title: 'APN Profile' });
        snackbar(
          i18n.t('apnhub:success.profile.delete', {
            profileName: profile.name,
          }),
          { variant: 'success' }
        );

        this.getProfiles();
      } else {
        snackbar(i18n.t('apnhub:error.profile.delete'), {
          variant: 'error',
        });
      }

      this.closeModal();
    });
  }

  @action.bound async updateProfile(profile: IntCreateProfileDto) {
    const resp = await this.httpPut(
      apnHubAPI.updateProfile,
      {
        params: this.customerId,
        data: profile,
      },
      loading => this.setProfileActionsLoading(loading)
    );

    runInAction(() => {
      if (resp.status === 200) {
        snackbar(
          i18n.t('apnhub:success.profile.update', {
            profileName: profile.name,
          }),
          { variant: 'success' }
        );

        aiTrackEvent('Update', { title: 'APN Profile' });

        this.getProfiles();
      } else {
        snackbar(i18n.t('apnhub:error.profile.update'), {
          variant: 'error',
        });
      }

      this.closeModal();
    });
  }

  @action.bound async createProfile(profile: IntCreateProfileDto) {
    const resp = await this.httpPost(
      apnHubAPI.createProfile,
      {
        params: this.customerId,
        data: profile,
      },
      loading => this.setProfileActionsLoading(loading)
    );

    runInAction(() => {
      if (resp.status === 200) {
        snackbar(
          i18n.t('apnhub:success.profile.create', {
            profileName: profile.name,
          }),
          { variant: 'success' }
        );
        this.getProfiles();

        aiTrackEvent('Create', { title: 'APN Profile' });
        this.closeModal();
      } else {
        snackbar(i18n.t('apnhub:error.profile.create'), {
          variant: 'error',
        });
      }
    });
  }

  @action.bound closeModal() {
    this.setOpenModal({
      create: false,
      update: false,
      copy: false,
      delete: false,
      configvpn: false,
      managevpnrules: false,
      exportvpnconfig: false,
    });
    this.setModifyingProfile();
    this.setSelectedProfileId('');
    this.setSelectedVpnId('');
    this.setVpnActionsOk(false);
  }

  @observable modifyingProfile?: IntProfileDto;

  @action.bound setModifyingProfile(profile?: IntProfileDto) {
    this.modifyingProfile = profile;
  }

  @observable vpnsLoading = false;
  @action.bound setVpnsLoading(loading: boolean) {
    this.vpnsLoading = loading;
  }
  @observable vpnActionsLoading = false;
  @action.bound setVpnActionsLoading(loading: boolean) {
    this.vpnActionsLoading = loading;
  }

  @observable vpnActionsOk = false;
  @action.bound setVpnActionsOk(success: boolean) {
    this.vpnActionsOk = success;
  }

  @action.bound async configureVpn(values: IntEnterpriseVpnRequestDto) {
    if (this.selectedVpn?.status === VpnStatus.Unconfigured) {
      const newValues: IntEnterpriseVpnRequestDto = {
        ...values,
        vpn: {
          ...values.vpn,
          psk: values.vpn.psk || null,
        },
      };
      await this.httpPost(
        ipAccessAPI.saveVpn,
        { params: undefined, data: newValues },
        this.setVpnActionsLoading
      ).then(resp => {
        this.handleVpnResponse(resp.status === 200);
        resp.status !== 200 && this.handleVpnResponseMessage(false, resp);
      });
    } else {
      await this.httpPost(
        ipAccessAPI.updateVpn,
        { params: values.id, data: values },
        this.setVpnActionsLoading
      ).then(resp => {
        this.handleVpnResponse(resp.status === 200);
        resp.status !== 200 && this.handleVpnResponseMessage(false, resp);
      });
    }
  }

  @action.bound setSelectedVpnId(id: string) {
    this.selectedVpnId = id;
  }
  @observable selectedVpnId = '';

  @computed get selectedVpn() {
    if (!this.selectedVpnId || this.enterpriseVpnsList.length < 1) {
      return null;
    }
    return this.enterpriseVpnsList.find(vpn => vpn.id === this.selectedVpnId);
  }

  @action.bound async updateVpnFirewallRules(
    rules: IntApnHubFirewallRuleDto[]
  ) {
    const resp = await this.httpPatch(
      ipAccessAPI.updateVpnFireWallRules,
      {
        params: this.selectedVpn?.id || '',
        data: rules,
      },
      this.setVpnActionsLoading
    );

    this.handleVpnResponse(resp.status === 200);
    this.handleVpnResponseMessage(resp.status === 200);
  }

  @action.bound handleVpnResponse(ok: boolean) {
    this.setVpnActionsOk(ok);
    ok && this.getEnterpriseVpns();
    runInAction(() => {
      if (ok) {
        snackbar(
          i18n.t('apnhub:dialog_config_vpn.verification.success.minor.message'),
          { variant: 'success' }
        );

        this.closeModal();
      }
    });
  }

  @action.bound handleVpnResponseMessage(
    ok: boolean,
    resp?: IResponse<unknown> | undefined
  ) {
    runInAction(() => {
      if (ok) {
        snackbar(
          i18n.t('apnhub:dialog_config_vpn.verification.success.firewall'),
          { variant: 'success' }
        );
        this.closeModal();
      } else {
        if (resp && resp.status === 422) {
          const message =
            'apnhub:dialog_config_vpn.verification.error.minor.detail';

          this.vpnErrorMessage = resp.exceptionMessage;

          const { propertyName, cause } = this.parseMessage.exception;

          if (propertyName.length > 0 && cause.length > 0) {
            snackbar(
              i18n.t(message, {
                cause: i18n.t(`apnhub:vpn_failure_causes.${cause}`),
                propertyName: i18n.t(
                  `apnhub:vpn_property_names.${propertyName}`
                ),
              }),
              {
                variant: 'error',
              }
            );
          } else {
            snackbar(
              i18n.t(
                'apnhub:dialog_config_vpn.verification.error.minor.firewall'
              ),
              {
                variant: 'error',
              }
            );
          }
        } else {
          snackbar(
            i18n.t(
              'apnhub:dialog_config_vpn.verification.error.minor.firewall'
            ),
            {
              variant: 'error',
            }
          );
        }

        this.vpnError = true;
      }
    });
  }

  @computed get parseMessage() {
    const parsedMessage = this.vpnErrorMessage;
    const exception = { cause: '', propertyName: '' };
    let cause = parsedMessage?.split(': ')[1];
    const propertyName = parsedMessage?.split(': ')[0];
    cause = cause?.replace(' ', '_');

    exception.propertyName = propertyName || '';
    exception.cause = cause || '';

    return { exception };
  }
}
