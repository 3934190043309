import 'azure-maps-control/dist/atlas.min.css';
import classNames from 'classnames';
import { IntDashboardDataStatusDto } from 'generated';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useDashboardContext } from 'views/Dashboard/contexts/dashboardContext';
import { IDashboardComponent } from 'views/Dashboard/dashboardTypes';
import { IDataProperty } from 'views/Dashboard/DataSources/dataPropTypes';
import { useDataSource } from 'views/Dashboard/DataSources/useDataSource';
import Widget from 'views/Dashboard/Widget';
import { usePolicy } from '../NumberGridComponent/usePolicy';
import {
  ITableViewModel,
  makeTableViewModel,
} from '../TableComponent/makeTableViewModel';
import { useMapStyles } from './AzureMap.styles';
import { IAzureMapsSettings } from './azureMapsConfig';
import AzureMapsContent from './AzureMapsContent';
import { makePopupGetter, PopupGetter } from './getPopupContent';
import {
  useCurrentLocationDataSource,
  useMapInstance,
  useRandomId,
} from './hooks';

export interface IAzureMapsViewModel {
  getPopupContent: PopupGetter;
  pinValues: { status: IntDashboardDataStatusDto; prop: IDataProperty }[];
  table: ITableViewModel;
}

const AzureMapsWidgetContainer: IDashboardComponent<IAzureMapsSettings> = ({
  component,
}) => {
  const classes = useMapStyles();
  const { t } = useTranslation('dashboard');
  const mapId = useRandomId(); // Can't be componentId, as previews in the settings wizard steps need unique ids
  const { settings } = component;

  const map = useMapInstance(mapId, settings);

  const location = useCurrentLocationDataSource(
    settings.dataSource.type === 'service' ||
      settings.dataSource.type === 'serviceLatest'
      ? settings.dataSource.serviceDataFilters // Share filters between the two data sources
      : undefined
  );
  const policy = usePolicy(settings.policyId);
  const dataSource = useDataSource(settings.dataSource);
  const dashboard = useDashboardContext();

  const viewModel = dataSource.getDataProps<IAzureMapsViewModel>(getProp => ({
    getPopupContent: makePopupGetter(settings.popup, getProp, t),
    groupByProperties: settings.groupProperties.map(getProp),
    pinValues:
      policy?.statuses?.map(status => ({
        status,
        prop: getProp(status.servicePropertyId || ''),
      })) || [],
    table: makeTableViewModel({
      columns: settings.tableColumns,
      dashboard,
      dataSourceSettings: settings.dataSource,
      pivotBy: settings.groupProperties,
      policyId: settings.policyId,
      enablePolicySummaryColumn: true,
    })(getProp),
  }));

  return (
    <Widget
      isLoading={
        !dataSource.isInitialized ||
        dataSource.isLoading ||
        !location ||
        location.dataSource.isLoading
      }
    >
      <Widget.Header>{settings.title}</Widget.Header>
      <Widget.Content skeleton={!location || !dataSource.isInitialized}>
        <div
          id={mapId}
          className={classNames(
            classes.map,
            settings.showTable && classes.mapWithTable
          )}
        />

        {map && dataSource.isInitialized && location && (
          <AzureMapsContent
            map={map}
            settings={settings}
            detailsDataSource={dataSource}
            location={location}
            viewModel={viewModel}
          />
        )}
      </Widget.Content>
    </Widget>
  );
};

export default observer(AzureMapsWidgetContainer);
