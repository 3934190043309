import { Box } from '@mui/material';
import React from 'react';
import AutoHeightContainer from './AutoHeightContainer';

interface IProps {
  height?: string | number;
  className?: string;
  disableHeightCalculation?: boolean;
}

const HeightContainer: React.FC<IProps> = ({
  height,
  className,
  disableHeightCalculation,
  children,
}) => {
  if (!height || height === 'auto') {
    return (
      <AutoHeightContainer
        className={className}
        disableHeightCalculation={disableHeightCalculation}
      >
        {children}
      </AutoHeightContainer>
    );
  }
  return <Box height={height}>{children}</Box>;
};

export default HeightContainer;
