import { Box, useTheme } from '@mui/material';
import classNames from 'classnames';
import AtlasIcon from 'components/AtlasIcon';
import React from 'react';
import { getColor } from 'utils';
import { useStyles } from './BatteryCell.styles';
import { ICustomCellProps } from './CustomCellProps';

export const BatteryCell: React.FC<ICustomCellProps> = ({
  variant,
  text,
  iconType,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const mainContents = (
    <>
      <Box
        className={classNames(classes.icon, {
          [classes.high]: variant === 'success',
          [classes.average]: variant === 'default',
          [classes.low]: variant === 'warning',
        })}
      >
        {text && <Box mr={iconType ? 2 : undefined}>{text}</Box>}
        {iconType && (
          <Box>
            <AtlasIcon
              type={iconType}
              style={{ color: getColor(variant, theme) }}
              size="16px"
            />
          </Box>
        )}
      </Box>
    </>
  );

  const textContent = <Box display="flex">{mainContents}</Box>;

  return textContent;
};
