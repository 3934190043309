import flatten, { unflatten } from 'flat';
import { IntUiConfigurationSettingDto } from 'generated';
import { IUiConfiguration } from './IUiConfiguration';

// Parse a list of flattened key-value strings to a JS object
export function parseUiConfiguration(
  settings: IntUiConfigurationSettingDto[]
): IUiConfiguration {
  const obj: any = {};
  settings.forEach(({ key, value }) => {
    obj[key] = JSON.parse(value);
  });

  return unflatten(obj);
}

// Convert from nested object to list with key-value strings
// Example: { columns: ['loremIpsum'] } => [{ key: 'columns.1', value: '"loremIpsum"' }]
export function flattenUiConfiguration(
  settings: IUiConfiguration
): IntUiConfigurationSettingDto[] {
  const flattenedSettings = flatten<IUiConfiguration, object>(settings);

  return Object.entries(flattenedSettings)
    .map(
      ([key, value]): IntUiConfigurationSettingDto => ({
        key,
        value: JSON.stringify(value),
      })
    )
    .filter(setting => setting.value !== undefined);
}
