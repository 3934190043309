import { DashboardComponentType } from 'generated';
import { IDataSourceConfig } from '../dataSourceTypes';
import AnalyticsCubeSettingsMenu from './AnalyticsCubeSettingsMenu';

export interface IAnalyticsCubeDataSourceSettings {
  type: 'analyticsCube';
  analyticsCubeId: string;
}

export const analyticsCubeDataSourceConfig: IDataSourceConfig<IAnalyticsCubeDataSourceSettings> =
  {
    icon: 'Basketball',
    settingsSteps: [
      { title: 'analytics_cube.base', Component: AnalyticsCubeSettingsMenu },
    ],
    getDefaultSettings: async dashboard => {
      await dashboard.rootStore.dashboardStore.loadServiceOptions();

      const cubes =
        dashboard.rootStore.dashboardStore.analyticsCubeOptions || [];

      return {
        type: 'analyticsCube',
        analyticsCubeId: cubes.length ? cubes[0].analyticsCubeId : '',
      };
    },
    enabledComponentTypes: {
      [DashboardComponentType.AzureMaps]: false,
      [DashboardComponentType.ColumnChart]: true,
      [DashboardComponentType.Gauge]: true,
      [DashboardComponentType.Image]: false,
      [DashboardComponentType.LineChart]: true,
      [DashboardComponentType.Number]: true,
      [DashboardComponentType.Table]: false,
    },
    shouldShowDateFilter: true,
  };
