import { computed } from 'mobx';
import { observer } from 'mobx-react-lite';
import { FC, useMemo, useState } from 'react';
import { useStores } from 'store';
import { Features } from './Feature';

/**
 * @param flagKey The key of the feature flag to check, without the .appconfig.featureflag prefix
 * @param inverse if you want to inverse the functionality and render when the feature is disabled
 * @param children component to render if flag is enabled
 * @returns children if enabled, null if not
 * @example <Feature flagKey="Feature1"><h1>Render me if Feature1 is enabled</h1> </Feature>
 */
export const FeatureFlag: FC<{
  flagKey: Features;
  inverse?: boolean;
}> = observer(({ children, flagKey, inverse = false }) => {
  const {
    featureStore: { featureMap },
  } = useStores();

  const feature = computed(() => featureMap[flagKey] ?? false).get();

  const [enabled, setEnabled] = useState(() => feature);

  useMemo(() => {
    if (feature) {
      setEnabled(true);
    } else {
      setEnabled(false);
    }
  }, [feature]);

  if ((enabled && !inverse) || (!enabled && inverse)) {
    return <>{children}</>;
  }

  return null;
});

FeatureFlag.displayName = 'FeatureFlag';
