import { createContext } from 'react';

export interface IDropdownContext<TValue = any> {
  onOptionClick: (value: TValue) => void;
}

export const DropdownContext = createContext<IDropdownContext>({
  onOptionClick: () => {
    // Comment added to satisfy SonarCloud
  },
});
