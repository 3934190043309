import { faker } from '@faker-js/faker';
import { emptyGuid } from 'components/Auth/utils';
import {
  IntCreateDashboardRequestDto,
  IntDashboardDetailsDto,
  IntDashboardDto,
  IntEditDashboardDetailsRequestDto,
  IntEditDashboardRequestDto,
} from 'generated';

// A little fake localStorage database for testing while waiting for backend, will improve or remove when MVP is finished
class DashboardDemoData {
  dashboards: IntDashboardDetailsDto[];

  constructor() {
    this.dashboards = this.fromStorage('dashboards', []);
  }

  // Save full details for simplicity, use this to convert to the "lesser" dto
  detailsDtoToDashboardDto(dto: IntDashboardDetailsDto): IntDashboardDto {
    return {
      created: dto.created,
      customerId: dto.customerId,
      dashboardId: dto.dashboardId,
      displayName: dto.displayName,
      lastUpdated: dto.lastUpdated,
      menuItem: dto.menuItem,
    };
  }

  getDashboards(): IntDashboardDto[] {
    return this.dashboards.map(this.detailsDtoToDashboardDto);
  }

  // Full details, components and all
  getDetails(dashId: string): IntDashboardDetailsDto | null {
    return this.dashboards.find(d => d.dashboardId === dashId) || null;
  }

  addDash(dash: IntCreateDashboardRequestDto) {
    const newDash: IntDashboardDetailsDto = {
      navigation: {
        navigationId: faker.string.uuid(),
        customerId: faker.string.uuid(),
        displayName: faker.person.jobTitle(),
        active: faker.datatype.boolean(),
        menuItems: [],
      },
      customerId: emptyGuid,
      dashboardId: faker.string.uuid(),
      displayName: dash.displayName,
      created: new Date(),
      lastUpdated: new Date(),
      components: [],
      menuItem: {
        menuItemId: faker.string.uuid(),
        navigationId: faker.string.uuid(),
        route: '',
        externalRoute: false,
        parentId: '',
        order: 0,
        icon: 'Graph',
        roles: faker.string.uuid(),
        displayName: '',
        children: [],
      },
    };
    this.dashboards.push(newDash);
    this.saveValues();
    return newDash;
  }

  deleteDash(dashboardId: string) {
    this.dashboards = this.dashboards.filter(
      dash => dash.dashboardId !== dashboardId
    );
    this.saveValues();
  }

  updateDashboard(dashboardId: string, dto: IntEditDashboardDetailsRequestDto) {
    const index = this.dashboards.findIndex(
      dash => dash.dashboardId === dashboardId
    );
    const { components } = dto;
    this.dashboards[index].components = components;
    this.saveValues();
    return this.dashboards[index];
  }

  updateDashboardName(
    dashboardId: string,
    dto: IntEditDashboardRequestDto
  ): IntDashboardDto {
    const index = this.dashboards.findIndex(
      dash => dash.dashboardId === dashboardId
    );
    this.dashboards[index].displayName = dto.displayName;
    this.saveValues();
    return this.detailsDtoToDashboardDto(this.dashboards[index]);
  }

  saveValues() {
    this.toStorage('dashboards', this.dashboards);
  }

  fromStorage<T>(key: string, defaultValue: T) {
    const rawValue = localStorage.getItem(key);
    if (rawValue) {
      return JSON.parse(rawValue) as T;
    }
    return defaultValue;
  }

  toStorage<T>(key: string, value: T) {
    const stringValue = JSON.stringify(value);
    localStorage.setItem(key, stringValue);
  }
}

let dashDemoData: DashboardDemoData | undefined;
export function dashboardDemoData() {
  if (!dashDemoData) {
    dashDemoData = new DashboardDemoData();
  }
  return dashDemoData;
}
