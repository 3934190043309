import { AutoComplete } from 'components/Inputs';
import { useField } from 'formik';
import { AtlasIconType } from 'shared/types/atlas-icon';

interface IProps<TOption, TValue> {
  name: string;
  label: string;
  disabled?: boolean;
  placeholder?: string;
  required?: boolean;
  options: TOption[];
  getOptionLabel: (option: TOption) => string;
  getOptionValue: (option: TOption) => TValue;
  getOptionIcon?: (option: TOption) => AtlasIconType;
  onChange?: (event: TValue) => void;
  isLoading?: boolean;
  clearable?: boolean;
  helperText?: string;
}

function FormikAutocomplete<
  TOption extends object,
  TValue extends string | number,
>(props: IProps<TOption, TValue>) {
  const {
    name,
    label,
    options,
    required = false,
    placeholder,
    disabled = false,
    getOptionLabel,
    getOptionValue,
    getOptionIcon,
    onChange,
    isLoading = false,
    clearable = false,
    helperText,
  } = props;
  const [field, meta, helpers] = useField<TValue>(name);

  return (
    <AutoComplete
      dataCy={name}
      options={options || []}
      onChange={newValue => {
        helpers.setValue(newValue as TValue);
        onChange?.(newValue as TValue);
      }}
      value={meta.value}
      required={required}
      placeholder={placeholder}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      getOptionIcon={getOptionIcon}
      label={label}
      disabled={disabled}
      onBlur={field.onBlur}
      error={meta.touched && !!meta.error}
      helperText={(meta.touched && meta.error) || helperText}
      isLoading={isLoading}
      clearable={clearable}
      customWidth
    />
  );
}

export default FormikAutocomplete;
