import {
  IntCreateNavigationDto,
  IntDashboardDto,
  IntEditNavigationDto,
  IntMenuItemDto,
  IntNavigationDto,
  IntSearchDto,
} from 'generated';
import routes from 'Routes';
import { IResponse } from 'shared/interfaces/api';
import { Dashboard } from 'store/domains/dashboard';
import { rnd } from 'utils';
import { dashboardDemoData } from 'views/Dashboard/dashboardDemoData';
import {
  dashboardsToItems,
  menuItemFormToDto,
  routesToItems,
} from 'views/Navigation/Manage/AddMenuItemsState';

export function getDemoDataMenuItems(
  dashboards: (IntDashboardDto | Dashboard)[]
): IntMenuItemDto[] {
  return [...routesToItems(routes), ...dashboardsToItems(dashboards)].map(
    (item, index) => menuItemFormToDto({ item, navigationId: '', index })
  );
}

class NavigationDemoData {
  getActiveNavigation = (): IResponse<IntNavigationDto> => {
    return {
      status: 200,
      data: {
        navigationId: rnd.guid(),
        active: true,
        customerId: rnd.guid(),
        displayName: 'Example navigation',
        menuItems: getDemoDataMenuItems(dashboardDemoData().getDashboards()),
      },
    };
  };

  updateNavigation = (
    params: {
      navigationId: string;
    },
    dto: IntEditNavigationDto | undefined
  ): IResponse<IntNavigationDto> => {
    if (!dto) {
      return {
        status: 500,
      };
    }
    return {
      status: 200,
      data: {
        navigationId: params.navigationId,
        ...dto,
      },
    };
  };

  getNavigationsForCustomer = (params: {
    customerId: string;
    searchDto: IntSearchDto;
  }): IResponse<IntNavigationDto[]> => {
    return {
      status: 200,
      data: [
        {
          displayName: 'Example navigation',
          navigationId: rnd.guid(),
          active: true,
          customerId: params.customerId,
          menuItems: [],
        },
      ],
    };
  };

  createNavigation = (
    params: undefined,
    body: IntCreateNavigationDto | undefined
  ): IResponse<IntNavigationDto> => {
    if (!body) {
      return { status: 500 };
    }
    return {
      status: 201,
      data: {
        customerId: body.customerId,
        active: body.active,
        displayName: body.displayName,
        menuItems: [],
        navigationId: rnd.guid(),
      },
    };
  };
}

export const navigationDemoData = new NavigationDemoData();
