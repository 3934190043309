import { createStyles, makeStyles } from '@mui/styles';
import * as atlas from 'azure-maps-control';
import { useEffect } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { AtlasIconType } from 'shared/types/atlas-icon';
import { SvgIcon } from '../ImageComponent/IconMarkerLayer/ClusteredMarkerLayer/HtmlMapPin/HtmlMapPin';

const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      color: '#83888d',
      width: 18,
      height: 18,
      margin: 7,
    },
  })
);

class IconButtonControl implements atlas.Control {
  private _container: HTMLDivElement;
  private _button: HTMLButtonElement;
  private _onClick: () => void;

  constructor(icon: AtlasIconType, className: string, onClick: () => void) {
    this._onClick = onClick;

    this._container = document.createElement('div');
    this._container.classList.add('azure-maps-control-container');

    this._button = document.createElement('button');
    this._button.classList.add('azure-maps-control-button');
    this._button.addEventListener('click', () => {
      this._onClick();
    });

    const svgIcon = renderToStaticMarkup(
      <SvgIcon iconType={icon} className={className} />
    );
    this._button.innerHTML = svgIcon;
    this._container.appendChild(this._button);
  }

  public onAdd(_map: atlas.Map): HTMLElement {
    return this._container;
  }

  public onRemove(): void {
    this._container.parentNode?.removeChild(this._container);
  }
}

export function useCustomMapControl(
  map: atlas.Map,
  icon: AtlasIconType,
  onClick: () => void
) {
  const classes = useStyles();

  useEffect(() => {
    const iconButtonControl = new IconButtonControl(
      icon,
      classes.icon,
      onClick
    );

    map.controls.add(iconButtonControl, {
      position: atlas.ControlPosition.BottomLeft,
    });

    return () => map.controls.remove(iconButtonControl);
  }, [map, icon, onClick, classes.icon]);
}
