import { ServiceDataFilterType } from 'generated';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import AssetGroupFilterControl from './AssetGroupFilterControl';
import DropdownFilterControl from './DropdownFilterControl';
import { FilterControlState } from './FilterControlState';

interface IProps {
  control: FilterControlState;
}

const FilterControlContainer: React.FC<IProps> = ({ control }) => {
  // When this changes, we need to reset value and load options again
  const depString = JSON.stringify(control.previousAssetFilters);

  useEffect(() => {
    control.resetAndLoadOptions();
  }, [control, depString]);

  if (
    control.settings.entity === 'asset' &&
    control.settings.type === ServiceDataFilterType.ResourceGroupId
  ) {
    return <AssetGroupFilterControl control={control} />;
  }
  return <DropdownFilterControl control={control} />;
};

export default observer(FilterControlContainer);
