import { Autocomplete, Checkbox, TextField, Typography } from '@mui/material';
import LoadingSpinner from 'components/LoadingSpinner';
import { useField } from 'formik';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { useStyles } from './MultiSelectDropdown.styles';

interface IProps<TOption> {
  name: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  options?: TOption[];
  limitTags?: number;
  renderTags?: boolean;
  isLoading?: boolean;
  getOptionLabelAndValue: (option: TOption) => { label: string; value: string };
  groupBy?: (option: TOption) => string;
  disabled?: boolean;
}

function MultiSelectDropdown<TOption>({
  name,
  label,
  placeholder,
  required = false,
  options,
  limitTags = 10,
  renderTags = true,
  isLoading,
  getOptionLabelAndValue,
  groupBy,
  disabled = false,
}: IProps<TOption>) {
  const [field, meta, helpers] = useField<TOption[]>(name);
  const [inputValue, setInputValue] = useState<string>('');
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  return (
    <Autocomplete
      multiple
      id={name}
      options={options || []}
      classes={{
        root: classes.root,
        inputRoot: classes.inputRoot,
        input: classes.input,
      }}
      limitTags={limitTags}
      groupBy={groupBy}
      disabled={disabled}
      disableCloseOnSelect
      inputValue={inputValue}
      value={field.value}
      loading={isLoading}
      onChange={(e, newValue) => helpers.setValue(newValue)}
      isOptionEqualToValue={(option, value) =>
        getOptionLabelAndValue(option).value ===
        getOptionLabelAndValue(value).value
      }
      getOptionLabel={option => getOptionLabelAndValue(option).label}
      renderTags={
        renderTags
          ? undefined
          : (value, _) => (
              <Typography ml={3} variant="body1">
                <Trans
                  i18nKey="selected"
                  ns="common"
                  values={{ count: value.length }}
                />
              </Typography>
            )
      }
      renderInput={params => (
        <TextField
          {...params}
          {...field}
          value={field.value}
          InputProps={{
            ...params.InputProps,
            onChange: handleChange,
            endAdornment: (
              <>
                {isLoading ? (
                  <LoadingSpinner
                    style={{ position: 'absolute', right: 40 }}
                    color="inherit"
                    size={18}
                  />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          label={label}
          required={required}
          variant="outlined"
          placeholder={placeholder}
          data-cy={name}
          error={meta.touched && !!meta.error}
          helperText={meta.touched && meta.error}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <li {...props} key={getOptionLabelAndValue(option).value}>
          <Checkbox
            data-cy="autoCompleteOption"
            color="primary"
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {getOptionLabelAndValue(option).label}
        </li>
      )}
    />
  );
}

export default MultiSelectDropdown;
