import { createStyles, makeStyles } from '@mui/styles';
import { CSSProperties } from 'react';

export const useStyles = makeStyles(theme =>
  createStyles({
    fileDownloads_Button: {
      color: theme.settings.colors.purple,
      padding: '2px 0',
      background: 'none',
      border: '0 none',
      textDecoration: 'underline',
      margin: theme.spacing(0, 1),
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'none',
      },
    },
  })
);

export const reverseOverflowHidden: CSSProperties = {
  direction: 'rtl',
  textAlign: 'left',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
};

export const textOverflowEllipsis: CSSProperties = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
};

export const stylingNames = {
  textCenter: 'text-center',
};
