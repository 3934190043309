import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => {
  return createStyles({
    outer: {
      justifyContent: 'space-between',
    },
    group: {
      '& > * ~ *': {
        marginLeft: theme.spacing(4.5),
      },
    },
  });
});
