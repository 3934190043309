import {
  compareAsc,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  formatDuration,
  sub,
  toDate,
} from 'date-fns';
import i18n from 'i18next';
import { CellInfo, Column } from 'react-table';
import Columns from '..';
import { FilterOption } from '../Helpers/filterOption';

type TokenType = 'xDays' | 'xSeconds' | 'xMinutes' | 'xHours';

interface IInterval {
  start: Date | number;
  end: Date | number;
}

interface IDuration {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}
// Basically the same functionality as date-fns built in function but neglects years and months from return object
function intervalToDuration({ start, end }: IInterval): IDuration {
  const dateLeft = toDate(start);
  const dateRight = toDate(end);

  const duration = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };

  const sign = compareAsc(dateLeft, dateRight);

  duration.days = Math.abs(differenceInDays(dateLeft, dateRight));
  const remainingHours = sub(dateLeft, { days: sign * duration.days });
  duration.hours = Math.abs(differenceInHours(remainingHours, dateRight));
  const remainingMinutes = sub(remainingHours, {
    hours: sign * duration.hours,
  });
  duration.minutes = Math.abs(differenceInMinutes(remainingMinutes, dateRight));
  const remainingSeconds = sub(remainingMinutes, {
    minutes: sign * duration.minutes,
  });
  duration.seconds = Math.abs(differenceInSeconds(remainingSeconds, dateRight));

  return duration;
}

export function timeDuration<T>(options: {
  id: string;
  header: string;
  accessor: (row: T) => number;
  width?: number;
  maxWidth?: number;
  resizable?: boolean;
  filter?: 'minMax' | 'options' | false;
  align?: 'left' | 'right';
  filterOptions?: FilterOption[];
  filterable?: boolean;
  defaultShow?: boolean;
  defaultSortDesc?: boolean;
  sortable?: boolean;
  ellipsis?: boolean;
}): Column<T> {
  const { ellipsis = false } = options;

  const renderCell = (row: CellInfo) => {
    const duration = intervalToDuration({ start: 0, end: row.value * 1000 });

    const templates = {
      xDays: i18n.t('unit:duration.days', {
        interpolation: { skipOnVariables: true },
      }),
      xHours: i18n.t('unit:duration.hours', {
        interpolation: { skipOnVariables: true },
      }),
      xMinutes: i18n.t('unit:duration.minutes', {
        interpolation: { skipOnVariables: true },
      }),
      xSeconds: i18n.t('unit:duration.seconds', {
        interpolation: { skipOnVariables: true },
      }),
    };

    const formattedDuration = formatDuration(duration, {
      format: ['days', 'hours', 'minutes', 'seconds'],
      zero: shouldIncludeZero(duration),
      locale: {
        formatDistance: (token: TokenType, count: string) =>
          templates[token].replace('{{count}}', count),
      },
    });

    function shouldIncludeZero(duration: IDuration): boolean {
      return Object.values(duration).some(durValue => durValue > 0);
    }

    return (
      <div title={ellipsis ? formattedDuration : undefined}>
        {formattedDuration}
      </div>
    );
  };

  return Columns.text({ ...options, ...{ cellRender: renderCell } });
}
