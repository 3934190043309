import { Box, SvgIconProps, useTheme } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import MaterialIcons from 'shared/icons/material-icons';
import TeliaIcons from 'shared/icons/telia-icons';
import { AtlasIconType } from 'shared/types/atlas-icon';
import { useStyles } from './AtlasIcon.styles';

interface IProps extends SvgIconProps {
  type: AtlasIconType;
  size?: number | string;
  className?: string;
  title?: string;
  disabled?: boolean;
  gutterBottom?: boolean;
  inline?: boolean;
}

const AtlasIcon: React.FC<IProps> = ({
  type,
  size,
  className,
  title,
  disabled,
  gutterBottom = false,
  inline,
  ...svgIconProps
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const library = theme.settings.icons.library;

  const getIconFromLib = (atlasIconType: AtlasIconType) => {
    switch (library) {
      case 'material': {
        return MaterialIcons[atlasIconType];
      }
      case 'telia': {
        return TeliaIcons[atlasIconType];
      }
      default: {
        return MaterialIcons[atlasIconType];
      }
    }
  };

  const Icon = getIconFromLib(type);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      className={classNames(className, {
        [classes.disabled]: disabled,
        [classes.gutterBottom]: gutterBottom,
        [classes.inline]: inline,
      })}
      style={{ height: size, width: size }}
    >
      <Icon
        {...svgIconProps}
        style={{ fontSize: size, ...svgIconProps.style }}
        viewBox={svgIconProps.viewBox || '0 0 24 24'}
      />
    </Box>
  );
};

export default AtlasIcon;
