import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    border: '1px solid red',
    '& button': {
      marginLeft: theme.spacing(2),
    },
  },
}));
