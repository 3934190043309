import React, { useContext } from 'react';

export type MutateFn<T = any> = (changes: T) => void;

export const MutationContext = React.createContext<MutateFn>(() => {
  /* Comment added to satisfy SonarCloud */
});

export function useMutate<T = any>() {
  return useContext<MutateFn<T>>(MutationContext);
}
