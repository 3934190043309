import { DashboardComponentType } from 'generated';
import { IDataSourceConfig } from '../../dataSourceTypes';

export interface ISimActivityDataSourceSettings {
  type: 'simActivity';
}

export const simActivityDataSourceConfig: IDataSourceConfig<ISimActivityDataSourceSettings> =
  {
    icon: 'Basketball',
    settingsSteps: [],
    getDefaultSettings: async () => {
      return {
        type: 'simActivity',
      };
    },
    enabledComponentTypes: {
      [DashboardComponentType.Table]: true,
    },
    shouldShowDateFilter: true,
  };
