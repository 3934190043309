import { Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import ComponentContainer from 'views/Dashboard/ComponentContainer';
import { IDashboardComponent } from 'views/Dashboard/dashboardTypes';
import { useDataSource } from 'views/Dashboard/DataSources';
import { Control } from 'views/Dashboard/SettingControls';
import { IHighthchartsMapSettings } from '../highchartsMapConfig';

const GeneralSettingsStep: IDashboardComponent<IHighthchartsMapSettings> = ({
  component,
}) => {
  const { t } = useTranslation('dashboard');
  const { settings } = component;
  const dataSource = useDataSource(settings.dataSource);

  return (
    <>
      <Control.Group label={t('general_settings')}>
        <Control.DataProperty
          object={settings}
          name="valueProp"
          label={t('highcharts_map.value')}
          options={dataSource.dataProperties}
          allowDate={false}
          allowString={false}
          allowBoolean={false}
        />
      </Control.Group>

      <Control.Group label={t('preview')}>
        <Box height={300}>
          <ComponentContainer component={component} />
        </Box>
      </Control.Group>
    </>
  );
};

export default observer(GeneralSettingsStep);
