import { ButtonBase } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { useStyles } from './RangeOptionButton.styles';

interface IProps {
  isActive: boolean;
  onClick: () => void;
  dataCy?: string;
}

const RangeOptionButton: React.FC<IProps> = ({
  isActive,
  onClick,
  children,
  dataCy,
}) => {
  const classes = useStyles();

  return (
    <ButtonBase
      onClick={onClick}
      data-cy={dataCy}
      className={classNames(classes.root, {
        [classes.active]: isActive,
      })}
    >
      {children}
    </ButtonBase>
  );
};

export default RangeOptionButton;
