import { createStyles, makeStyles } from '@mui/styles';

export const iconSize = 36;
export const markerSize = 36;
const clusterMarkerSize = 42;

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      color: '#FFF',

      width: markerSize,
      height: markerSize,
      lineHeight: `${markerSize}px`,
      textAlign: 'center',
      borderRadius: '50%',
      fontSize: 18,
      transition: theme.transitions.create('background', {
        duration: 120,
        easing: 'ease-out',
      }),
    },
    cluster: {
      width: clusterMarkerSize,
      height: clusterMarkerSize,
      lineHeight: `${clusterMarkerSize}px`,
      fontSize: 20,
      background: '#333',
      boxShadow: theme.shadows[1],
    },
    selected: {
      stroke: theme.palette.primary.main,
      strokeWidht: 2,
    },
    icon: {
      width: iconSize,
      height: iconSize,
      margin: (markerSize - iconSize) / 2,
    },
    notification: {
      position: 'absolute',
      top: -6,
      right: -6,
      fontSize: 14,
      background: theme.palette.error.main,
      color: '#FFF',
      borderRadius: '50%',
      height: 20,
      minWidth: 20,
      padding: 3,
      lineHeight: '14px',
      boxShadow: '1px 1px 3px rgba(1, 1, 1, .1)',
      textAlign: 'center',
    },
  })
);
