import { Box } from '@mui/material';
import classNames from 'classnames';
import ContentCard from 'components/ContentCard';
import ErrorIcon from 'components/ErrorIcon';
import {
  IResourceTableProps,
  TableLoadingOverlay,
  tableParamsToSearchDto,
} from 'components/Table';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Column,
  ComponentPropsGetterR,
  Filter,
  RowInfo,
  SubComponentFunction,
} from 'react-table';
import { TableSubComponentProps } from '..';
import { IntSearchDto } from '../../../generated';
import { checkBox } from '../Columns/Form/checkBox';
import HeightContainer from '../HeightContainer';
import {
  useContainerWidth,
  useGetOrPost,
  usePostTableFetch,
  useTableColumnSettings,
  useTableFetch,
  useTableQueryParams,
} from '../hooks';
import Table from '../Table';
import TableTopContent from '../TableTopContent/TableTopContent';
import { useStyles } from './ResourceTable.styles';

function ResourceTable<TRow, TRequestBody = IntSearchDto>(
  props: Readonly<IResourceTableProps<TRequestBody, TRow>>
) {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    subComponent,
    selectState,
    toolbar,
    height,
    disableHeightCalculation = false,
    tableId,
    tableTitle,
    disableSearch = false,
    defaultSortCol,
    defaultSortDesc,
    onDataChanged,
    mapRows,
    apiEndpoint,
    endpointType = 'get',
    searchPlaceholder,
    topMoreButtonItems,
    topAction,
    getPostBody,
    customError,
    searchColumns,
    hideFilterButton = false,
  } = props;
  const searchCols = useMemo(() => searchColumns || [], [searchColumns]);
  const tableDataCy = `table-id-${tableId}`;
  const { params, onSearchChange, tableParamProps, onResetFilter } =
    useTableQueryParams({
      defaultPageSize: 50,
      defaultSortCol,
      defaultSortDesc,
      tableId,
      searchColumns: searchCols,
    });

  const getBody = useCallback(() => {
    if (endpointType === 'get') {
      return undefined;
    }

    const searchDtoModel = tableParamsToSearchDto(params);

    if (getPostBody) {
      return getPostBody(searchDtoModel);
    }

    return searchDtoModel as TRequestBody;
  }, [params, getPostBody, endpointType]);

  const [tableRef, width] = useContainerWidth();
  const [data, errors, isLoading] = useGetOrPost(
    apiEndpoint,
    endpointType,
    params,
    useTableFetch,
    usePostTableFetch,
    getBody
  );

  const rows = useMemo(() => {
    const rawRows = data?.rows || [];
    return mapRows ? mapRows(rawRows) : rawRows;
  }, [mapRows, data]);

  useEffect(() => {
    let isMounted = true;

    if (onDataChanged && data && isMounted) {
      onDataChanged(data);
    }

    return () => {
      isMounted = false;
    };
  }, [onDataChanged, data, tableTitle]);

  const totalRows = data ? data.total : '-';
  const currentlyShown = data && data.rows ? data.rows.length : '-';
  const pages =
    data && data.total ? Math.ceil(data.total / params.pageSize) : 0;
  const initialShowFilterState = !!params.filters;
  const [showFilters, setShowFilters] = useState(initialShowFilterState);
  const subComp =
    subComponent && wrapSubComponent(subComponent, tableTitle || '');

  const isRowSelected = selectState && selectState.isRowSelected;
  const getTrProps = useCallback<ComponentPropsGetterR>(
    (finalState, row) => {
      if (isRowSelected && row && row.original && isRowSelected(row.original)) {
        return {
          className: 'selected',
          'data-cy': `row-${row?.index}`,
        };
      }

      return {
        'data-cy': `row-${row?.index}`,
      };
    },
    [isRowSelected]
  );

  const getTdProps = (
    _finalState: any,
    _rowInfo?: RowInfo,
    column?: Column,
    _instance?: any
  ) => {
    // A bit risky perhaps but that was the only cell that didnt have id. If not, then we can just change it
    return { 'data-cy': `cell-${column?.id ? column.id : 'expand'}` };
  };

  const getTheadThProps = (
    _finalState: any,
    _rowInfo?: RowInfo,
    column?: Column,
    _instance?: any
  ) => {
    return { 'data-cy': `columnHeader-${column?.id}` };
  };

  const getTbodyProps = () => ({ 'data-cy': tableDataCy });

  const { columns, toggleShowColumn, handleSortEnd } = useTableColumnSettings(
    props.columns,
    tableId
  );

  // Todo: dumb memo in useSelectState, constant updates on check/uncheck
  const finalColumns = useMemo(() => {
    return selectState
      ? [checkBox(selectState, `checkBox-${tableId}`), ...columns]
      : columns;
  }, [columns, tableId, selectState]);
  const noDataText = !isLoading ? t('label.no_rows_found') : '';
  const minRows = data && data.rows ? data.rows.length : 0;
  const dataCyTag = `ResourceTable-${tableId}`;

  return (
    <Box className="ResourceTable" ref={tableRef}>
      <ContentCard dataCy={dataCyTag}>
        <TableTopContent
          columns={columns}
          handleSortEnd={handleSortEnd}
          tableTitle={tableTitle}
          toggleShowColumn={toggleShowColumn}
          totalRows={totalRows}
          currentlyShown={currentlyShown}
          enableSearch={!disableSearch}
          onSearchChange={searchTerm => {
            onSearchChange(searchTerm);
          }}
          params={params}
          toolbar={toolbar}
          selectState={selectState}
          onFilterChange={() => {
            setShowFilters(!showFilters);
          }}
          showFilters={showFilters}
          hideFilterButton={hideFilterButton}
          searchPlaceholder={searchPlaceholder}
          handleResetFilter={() => {
            if (showFilters) {
              onResetFilter();
            }
          }}
          parentTableWidth={width}
          topMoreButtonItems={topMoreButtonItems}
          topAction={topAction}
        />
        <HeightContainer
          className="resource-table-wrapper"
          height={height}
          disableHeightCalculation={disableHeightCalculation}
        >
          {errors ? (
            customError ?? <ErrorIcon />
          ) : (
            <Table
              manual
              columns={finalColumns}
              SubComponent={subComp}
              noDataText={noDataText}
              className={classNames(classes.reactTable, 'resource-table')}
              style={{ height: '100%' }}
              filterable
              filtered={params.filters || ([] as Filter[])}
              getTheadThProps={getTheadThProps}
              getTdProps={getTdProps}
              getTrProps={getTrProps}
              getTbodyProps={getTbodyProps}
              getTheadFilterProps={(_state, _rowInfo, _column, _instance) => {
                const base = {
                  transition: 'height .15s linear',
                  minHeight: 0,
                };
                const close = { ...base, height: 0, display: 'none' };
                const open = { ...base, height: '38px', display: 'flex' };

                return {
                  style: showFilters ? open : close,
                };
              }}
              LoadingComponent={TableLoadingOverlay}
              data={rows}
              loading={isLoading}
              pages={pages}
              page={params.page}
              pageSize={params.pageSize}
              minRows={minRows}
              sorted={params.sortRules}
              {...tableParamProps}
              onFilteredChange={(newFiltering, column, value) => {
                tableParamProps.onFilteredChange(newFiltering, column, value);
              }}
              collapseOnDataChange={false}
            />
          )}
        </HeightContainer>
      </ContentCard>
    </Box>
  );
}

export default observer(ResourceTable);

interface ITrackOnMountProps {
  tableTitle: string;
  index: number;
}

const SubComponentWrapper: React.FC<ITrackOnMountProps> = ({
  children,
  index,
}) => {
  return (
    <div
      className="resource-table__sub-component"
      data-cy={`table-sub-component-index-${index}`}
    >
      {children}
    </div>
  );
};

// A SubComponentFunction can't itself contain hooks - not 100% sure why - so render it this way
export function wrapSubComponent<T>(
  Comp: React.ComponentType<TableSubComponentProps<T>>,
  tableTitle: string
): SubComponentFunction {
  return rowInfo => (
    <SubComponentWrapper tableTitle={tableTitle} index={rowInfo.index}>
      <Comp {...rowInfo} />
    </SubComponentWrapper>
  );
}
