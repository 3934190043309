import { InfiniteScrollAutoComplete } from 'components/Inputs';
import { observer, useLocalStore } from 'mobx-react-lite';
import { useStores } from 'store';
import { DynamicFilterStore } from 'store/dynamicFilterStore';
import { FilterProps } from './FilterProps';
import { useStyles } from './MultiSelectOptionsFilter.styles';
import { useDynamicFilterControl } from './useDynamicFilterControl';

interface IOptionFilterProps extends FilterProps<string[]> {
  definitionId: string;
}

const MultiSelectOptionsFilter: React.FC<IOptionFilterProps> = ({
  definitionId,
  filter,
  onChange,
}) => {
  const rootStore = useStores();
  const store = useLocalStore(() => new DynamicFilterStore(rootStore));

  const {
    options,
    fetchMore,
    total,
    searchString,
    handleSearch,
    loadingFilters,
    hasLoadedInitialFilters,
  } = useDynamicFilterControl(definitionId, store);

  const classes = useStyles();

  return (
    <InfiniteScrollAutoComplete
      id="options_id"
      multiple
      label=""
      options={options?.filter(opt => opt !== '') ?? []}
      getOptionLabel={opt => opt}
      getOptionValue={opt => opt ?? ''}
      value={filter?.value ?? []}
      clearable
      isLoading={loadingFilters}
      hasMore={options ? options.length < total : false}
      search={searchString}
      onSearchChange={handleSearch}
      fetchMore={fetchMore}
      styles={{ inputRoot: classes.inputRoot }}
      textFieldStyles={{ root: classes.textFieldRoot }}
      tagStyles={{ root: classes.tagRoot, label: classes.tagLabel }}
      onChange={onChange}
      disabled={!hasLoadedInitialFilters}
    />
  );
};

export default observer(MultiSelectOptionsFilter);
