import * as yup from 'yup';
import { OptionalObjectSchema } from 'yup/lib/object';

function object<T extends object>(
  spec?: yup.Shape<T>
): OptionalObjectSchema<yup.Shape<T>> {
  return yup.object<yup.Shape<T>>(spec);
}

function form<T extends object>(
  additions: yup.Shape<T>
): yup.ObjectSchema<yup.Shape<T>> {
  return yup.object().shape<yup.Shape<T>>(additions);
}

const yupMethods = {
  ...yup,
  form,
  object,
};

export default yupMethods;
