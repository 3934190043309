import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    rowList: {
      border: '1px solid #cccccc',
      borderRadius: 4,
      overflow: 'hidden',
      padding: 0,
      margin: theme.spacing(2, 0),
      width: '100%',
    },
    row: {
      display: 'flex',
    },
    draggableRow: {
      cursor: 'move',
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
      '&.is-dragging': {
        backgroundColor: '#FFF',
        boxShadow: theme.shadows[10],
        zIndex: 9999,
      },
    },
    clickableRow: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    icon: {
      width: '35px',
      height: '53px',
      padding: '5px',
      marginRight: '10px',
    },
    rowContent: {
      flex: 1,
    },
  })
);
