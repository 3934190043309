import { Box } from '@mui/material';
import { Column } from 'react-table';
import { ParsableDate } from 'utils';
import { TimeAgoCellComponent } from '../../Cells/Cells';
import { textRight } from '../Helpers/textRight';

export function timeAgo<T>(options: {
  id: string;
  header: string;
  defaultShow?: boolean;
  defaultSortDesc?: boolean;
  filterable?: boolean;
  sortable?: boolean;
  width?: number;
  maxWidth?: number;
  accessor: (row: T) => ParsableDate | null | undefined;
  adjustFromUtcToLocal?: boolean;
}): Column<T> {
  const {
    id,
    header,
    defaultShow: show = true,
    defaultSortDesc = false,
    filterable = false,
    sortable,
    accessor,
    width,
    maxWidth = 130,
    adjustFromUtcToLocal = false,
  } = options;
  return {
    id,
    Header: <Box textAlign="right">{header}</Box>,
    accessor,
    Cell: row => (
      <TimeAgoCellComponent
        value={row.value}
        adjustFromUtcToLocal={adjustFromUtcToLocal}
      />
    ),
    className: textRight,
    defaultSortDesc,
    maxWidth,
    filterable,
    sortable,
    width,
    show,
  };
}
