import { VpnStatus } from 'generated';
import i18n from 'i18next';
import { Column } from 'react-table';
import { CustomCell } from '../Helpers/CustomCell';
import { ICustomCellProps } from '../Helpers/CustomCellProps';
import { getOptionFilter } from '../Helpers/getOptionFilter';

export function vpnStatus<T>(options: {
  id: string;
  accessor: (row: T) => VpnStatus;
  header?: string;
  defaultShow?: boolean;
  filterable?: boolean;
}): Column<T> {
  const {
    id,
    header = 'Status',
    accessor,
    defaultShow: show,
    filterable,
  } = options;

  const cellByStatus: Record<VpnStatus, ICustomCellProps> = {
    [VpnStatus.Unknown]: {
      iconType: 'StatusUnknown',
      title: i18n.t(`apnhub:vpn_status_tooltip.${VpnStatus.Unknown}`),
      text: i18n.t(`apnhub:vpn_status.${VpnStatus.Unknown}`),
    },
    [VpnStatus.Unconfigured]: {
      iconType: 'StatusUnknown',
      title: i18n.t(`apnhub:vpn_status_tooltip.${VpnStatus.Unconfigured}`),
      text: i18n.t(`apnhub:vpn_status.${VpnStatus.Unconfigured}`),
    },
    [VpnStatus.Pending]: {
      iconType: 'StatusPending',
      title: i18n.t(`apnhub:vpn_status_tooltip.${VpnStatus.Pending}`),
      text: i18n.t(`apnhub:vpn_status.${VpnStatus.Pending}`),
    },
    [VpnStatus.Ready]: {
      iconType: 'StatusOk',
      title: i18n.t(`apnhub:vpn_status_tooltip.${VpnStatus.Ready}`),
      text: i18n.t(`apnhub:vpn_status.${VpnStatus.Ready}`),
    },
    [VpnStatus.Up]: {
      iconType: 'StatusOk',
      title: i18n.t(`apnhub:vpn_status_tooltip.${VpnStatus.Up}`),
      text: i18n.t(`apnhub:vpn_status.${VpnStatus.Up}`),
    },
    [VpnStatus.Down]: {
      iconType: 'StatusWarning',
      title: i18n.t(`apnhub:vpn_status_tooltip.${VpnStatus.Down}`),
      text: i18n.t(`apnhub:vpn_status.${VpnStatus.Down}`),
    },
    [VpnStatus.Error]: {
      iconType: 'StatusError',
      title: i18n.t(`apnhub:vpn_status_tooltip.${VpnStatus.Error}`),
      text: i18n.t(`apnhub:vpn_status.${VpnStatus.Error}`),
    },
    [VpnStatus.NotFound]: {
      iconType: 'StatusWarning',
      title: i18n.t(`apnhub:vpn_status_tooltip.${VpnStatus.NotFound}`),
      text: i18n.t(`apnhub:vpn_status.${VpnStatus.NotFound}`),
    },
  };

  return {
    id,
    Header: header,
    accessor,
    resizable: false,
    maxWidth: 130,
    filterable,
    show,
    Cell: row => {
      const iconProps = cellByStatus[row.value as VpnStatus] || {};
      return <CustomCell {...iconProps} index={row.index} />;
    },
    Filter: getOptionFilter([
      {
        label: i18n.t('all'),
        value: '',
      },
      {
        label: i18n.t(`apnhub:vpn_status.${VpnStatus.Unknown}`),
        value: VpnStatus.Unknown,
      },
      {
        label: i18n.t(`apnhub:vpn_status.${VpnStatus.Unconfigured}`),
        value: VpnStatus.Unconfigured,
      },
      {
        label: i18n.t(`apnhub:vpn_status.${VpnStatus.Pending}`),
        value: VpnStatus.Pending,
      },
      {
        label: i18n.t(`apnhub:vpn_status.${VpnStatus.Ready}`),
        value: VpnStatus.Ready,
      },
      {
        label: i18n.t(`apnhub:vpn_status.${VpnStatus.Down}`),
        value: VpnStatus.Down,
      },
      {
        label: i18n.t(`apnhub:vpn_status.${VpnStatus.Error}`),
        value: VpnStatus.Error,
      },
      {
        label: i18n.t(`apnhub:vpn_status.${VpnStatus.NotFound}`),
        value: VpnStatus.NotFound,
      },
    ]),
  };
}
