import {
  IconButton,
  InputAdornment,
  Paper,
  Popover,
  TextField,
} from '@mui/material';
import AtlasIcon from 'components/AtlasIcon';
import { IDateFilterValue } from 'components/DateFilterDropdown/dateFilterTypes';
import { getDateRangeFromFilterValue } from 'components/DateFilterDropdown/dateRangeOptions';
import DateRangePicker from 'components/DateFilterDropdown/DateRangePicker';
import { format } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getDateFormatString, usePopover } from 'utils';
import { IDateOptions } from 'utils/formatting';
import { parseDateIfValid } from 'utils/parsing';
import { useStyles } from './DateFilter.styles';
import { FilterProps } from './FilterProps';

interface IFilterProps extends FilterProps<string> {
  timeInterval: boolean;
}

const filterToInterval = (
  filter: string | undefined,
  dateOptions: IDateOptions
): IDateFilterValue | undefined => {
  if (filter) {
    const split = filter.split('>');
    if (split && split.length === 2) {
      const startDate = parseDateIfValid(split[0], dateOptions);
      const endDate = parseDateIfValid(split[1], dateOptions);
      if (startDate && endDate) {
        return {
          type: 'custom',
          startDate,
          endDate,
        };
      }
    }
  }

  return undefined;
};

const intervalToFilter = (
  interval: IDateFilterValue | undefined,
  dateOptions: IDateOptions
): string => {
  if (interval) {
    const range = getDateRangeFromFilterValue(interval);
    return (
      format(range.startDate, getDateFormatString(dateOptions)) +
      '>' +
      format(range.endDate, getDateFormatString(dateOptions))
    );
  }

  return '';
};

const getDefaultInterval = (): IDateFilterValue => {
  const startDate = new Date();
  startDate.setDate(startDate.getDate() - 7);

  return {
    type: 'custom',
    startDate,
    endDate: new Date(),
  };
};

const DateIntervalFilter: React.FC<IFilterProps> = ({
  timeInterval,
  filter,
  onChange,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const dateOptions = useMemo((): IDateOptions => {
    return {
      time: timeInterval,
      seconds: timeInterval,
    };
  }, [timeInterval]);

  const [openPopover, popoverProps] = usePopover('dateFilters');
  const [value, setValue] = useState(
    filterToInterval(filter?.value, dateOptions)
  );
  const [dateString, setDateString] = useState('');

  useEffect(() => {
    if (value) {
      const range = getDateRangeFromFilterValue(value);
      setDateString(
        format(range.startDate, getDateFormatString(dateOptions)) +
          ' - ' +
          format(range.endDate, getDateFormatString(dateOptions))
      );
    } else {
      setDateString('');
    }
  }, [dateOptions, value]);

  useEffect(() => {
    setValue(filterToInterval(filter?.value, dateOptions));
  }, [dateOptions, filter?.value]);

  // Every 10 seconds, re-render to update the date range, so the endDate time input remains accurate
  const [, setTick] = useState(0);
  useEffect(() => {
    const intervalId = window.setInterval(() => setTick(x => x + 1), 10000);
    return () => window.clearInterval(intervalId);
  }, []);

  const handleChange = (newValue: IDateFilterValue | undefined) => {
    setValue(newValue);
    onChange(intervalToFilter(newValue, dateOptions));
    popoverProps.onClose();
  };

  return (
    <>
      <TextField
        classes={{
          root: classes.root,
        }}
        variant="outlined"
        value={dateString}
        placeholder={t('common:date.pick_interval')}
        onClick={openPopover}
        InputProps={{
          readOnly: true,
          title: dateString,
          classes: {
            root: classes.root,
            input: classes.input,
            adornedEnd: classes.adornment,
          },
          endAdornment: (
            <InputAdornment position="end" onClick={openPopover}>
              <IconButton size="small">
                <AtlasIcon type="InputCalendar" size={14} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Popover {...popoverProps}>
        <Paper>
          <DateRangePicker
            header={t('common:date.pick_interval')}
            enableTime={timeInterval}
            enableRangeOptions={false}
            value={value ?? getDefaultInterval()}
            onChange={handleChange}
            onCancel={popoverProps.onClose}
            onClear={() => handleChange(undefined)}
            allowSingleDate={true}
            disableFuture={false}
          />
        </Paper>
      </Popover>
    </>
  );
};

export default DateIntervalFilter;
