import { Box, FormControl, Typography } from '@mui/material';
import React from 'react';
import { IMessage } from 'shared/interfaces/message';

interface IProps {
  messages: IMessage[];
  minHeight?: number | string;
}

const ModalSimpleMessage: React.FC<IProps> = ({
  messages,
  minHeight = 200,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight={minHeight}
      textAlign="center"
    >
      {messages.map(currentMessage => (
        <FormControl key={currentMessage.text.toString()}>
          <Typography
            variant={currentMessage.isMinor ? 'h6' : 'h5'}
            data-cy="messageContainer"
          >
            {currentMessage.text}
          </Typography>
        </FormControl>
      ))}
    </Box>
  );
};

export default ModalSimpleMessage;
