import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { IntPolicyUsageDto } from 'generated';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  usages: IntPolicyUsageDto[];
}

const PolicyUsage: React.FC<IProps> = ({ usages }) => {
  const { t } = useTranslation('policy');

  return (
    <>
      {usages.length > 0 ? (
        <>
          <Typography marginBottom={3}>{`${t(
            'policy_is_used_warning'
          )}:`}</Typography>
          <Box maxHeight={450} overflow="scroll">
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography fontWeight="bold" variant="body1">
                      {t('common:customer')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontWeight="bold">
                      {t('dashboard:base')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontWeight="bold">
                      {t('dashboard:action.widget.base')}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {usages.map(u => (
                  <TableRow key={u.dashboardComponentId}>
                    <TableCell>{u.customerName}</TableCell>
                    <TableCell>{u.dashboardName}</TableCell>
                    <TableCell>{u.dashboardComponentTitle}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </>
      ) : (
        <Typography marginBottom={3}>{`${t('policy_is_not_used')}`}</Typography>
      )}
    </>
  );
};

export default PolicyUsage;
