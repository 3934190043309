import { Box, Button, Popover, Typography } from '@mui/material';
import AtlasIcon from 'components/AtlasIcon';
import AtlasIconButton from 'components/AtlasIconButton';
import ModalSimpleMessage from 'components/ModalSimpleMessage';
import MultiDialog from 'components/MultiDialog';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'store';
import { usePopover } from 'utils';
import ProfilePreview from '../Profile';
import { iconSize } from '../SiteTopBarContent';
import { useStyles } from '../SiteTopBarContent.styles';

const ProfilePopover: React.FC = () => {
  const { t } = useTranslation();
  const [openPopover, popoverProps] = usePopover('profile');
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const {
    authStore: { user, logout },
  } = useStores();
  const classes = useStyles();

  const handleLogout = async () => {
    await logout();
  };

  const confirmLogout = () => {
    handleLogout();
    setShowConfirmModal(false);
  };

  if (!user) {
    return null;
  }

  return (
    <>
      <Popover
        data-cy="siteTopBarContentAccountPopover"
        {...popoverProps}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box className={classes.popoverContent}>
          <ProfilePreview
            name={user.fullName}
            picturePath={user.profilePicturePath}
            headerText={t('enterprise:personal_profile').toUpperCase()}
            url="/profile"
            dataCy="profilePicturePreview"
          />
          <ProfilePreview
            name={user.customerName || t('not_applicable_abbreviation')}
            picturePath={user.customerLogo || ''}
            headerText={t('enterprise:enterprise.profile').toUpperCase()}
            url={`/management/enterprises/${user.customerId}`}
          />
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            padding={'1rem'}
            height={'4.125rem'}
          >
            <Button
              data-cy="siteTopBarContentOpenSignOutDialog"
              variant="contained"
              color="primary"
              onClick={() => setShowConfirmModal(true)}
            >
              {t('auth:action.log_out')}
            </Button>
          </Box>
        </Box>
      </Popover>
      <AtlasIconButton
        onClick={openPopover}
        className={classes.iconButton}
        data-cy="profileIconButton"
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <AtlasIcon type="EndUser" size={iconSize} />
          <Typography className={classes.iconWidth} variant="body2">
            {t('profile')}
          </Typography>
        </Box>
      </AtlasIconButton>
      <MultiDialog
        data-cy="siteTopBarContentSignOutButton"
        type="confirm"
        show={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={confirmLogout}
        title={t('auth:modal_title.confirm_logout')}
        acceptButtonText={t('action.yes')}
        cancelButtonText={t('action.cancel')}
      >
        <ModalSimpleMessage
          messages={[
            {
              text: t('auth:confirm_logout'),
            },
          ]}
        />
      </MultiDialog>
    </>
  );
};

export default observer(ProfilePopover);
