import { useFormikContext } from 'formik';
import React from 'react';

interface IProps {
  errors?: boolean;
  values?: boolean;
}

const LogErrors: React.FC<IProps> = ({ errors = true, values }) => {
  const formikContext = useFormikContext();

  if (errors && !formikContext.isValid) {
    console.log('FormikErrors', formikContext.errors);
  }

  if (values) {
    console.log('FormikValues', formikContext.values);
  }

  return null;
};

export default LogErrors;
