import { createStyles, makeStyles } from '@mui/styles';

export const iconSize = 24;
export const markerSize = 40;

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      width: markerSize,
      height: markerSize,
      padding: (markerSize - iconSize) / 2,
      borderRadius: '50%',
      background: theme.palette.success.main,
      color: '#FFF',
      boxShadow: theme.shadows[1],
      transition: theme.transitions.create('background', {
        duration: 120,
        easing: 'ease-out',
      }),
    },
    green: {
      background: theme.palette.success.main,
      '&:hover': {
        background: theme.palette.success.dark,
      },
    },
    yellow: {
      color: '#402700',
      background: theme.palette.warning.main,
      '&:hover': {
        background: theme.palette.warning.dark,
      },
    },
    red: {
      background: theme.palette.error.main,
      '&:hover': {
        background: theme.palette.error.dark,
      },
    },
    blue: {
      background: theme.palette.info.main,
      '&:hover': {
        background: theme.palette.info.dark,
      },
    },
    selected: {
      background: theme.palette.primary.main,
      '&:hover': {
        background: theme.palette.primary.dark,
      },
    },
    icon: {
      width: iconSize,
      height: iconSize,
    },
    label: {
      padding: '0px 5px',
      borderRadius: 6,
      background: '#FFF',
      color: '#000',
      fontSize: 10,
      position: 'absolute',
      top: markerSize - 2,
      left: markerSize / 2,
      transform: 'translateX(-50%)',
      border: '1px solid #e7e7e7',
      fontFamily: theme.typography.fontFamily,
      boxShadow: theme.shadows[1],
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      maxWidth: 100,
      '$selected &, $root:hover &': {
        color: theme.palette.primary.main,
      },
    },
  })
);
