import {
  IntAtlasServiceDto,
  IntCreateServiceDto,
  IntSearchDto,
  IntServiceDataAggregateRequestDto,
  IntServiceDataFilterOptionRequestDto,
  IntServiceDataFilterOptionResponseDto,
  IntServiceDataPointInterval,
  IntServiceDataSpecificationRequestDto,
  IntServiceDetailDto,
  IntServiceFilterDto,
  IntServiceFilterRequestDto,
  IntServiceTableDto,
  IntUpdateServiceRequestDto,
} from 'generated';
import {
  DeleteEndpoint,
  GetEndpoint,
  PostEndpoint,
  PutEndpoint,
  TableEndpoint,
} from 'shared/interfaces/api';
import { serviceDemoData } from './demoData/serviceDemoData';

interface IServiceAPI {
  getAll: GetEndpoint<
    IntSearchDto,
    { total: number; rows: IntAtlasServiceDto[] }
  >;
  getService: GetEndpoint<string, IntAtlasServiceDto>;
  getServiceDetail: GetEndpoint<string, IntServiceDetailDto>;
  // The old endpoint that requires serviceId and can only grab data from a single service.
  // To be removed when the newer system is fully tested, see PBI #84183
  getServiceData: PostEndpoint<
    string,
    IntServiceDataSpecificationRequestDto,
    IServiceDataResponse
  >;
  // The newer endpoint without serviceId
  newGetServiceData: PostEndpoint<
    undefined,
    IntServiceDataSpecificationRequestDto,
    IServiceDataResponse
  >;
  getFilterOptions: PostEndpoint<
    undefined,
    IntServiceDataFilterOptionRequestDto,
    IntServiceDataFilterOptionResponseDto
  >;
  getServicesTable: TableEndpoint<IntServiceTableDto>;
  getVendorApiDataKeys: GetEndpoint<number, { [key: string]: string }>;
  createService: PostEndpoint<
    undefined,
    IntCreateServiceDto,
    IntServiceDetailDto
  >;
  editService: PutEndpoint<string, IntUpdateServiceRequestDto>;
  testDeleteService: DeleteEndpoint<string, any>;
  deleteService: DeleteEndpoint<string>;
  getServiceFilters: GetEndpoint<string, IntServiceFilterDto>;
  createOrUpdateServiceFilters: PutEndpoint<
    undefined,
    IntServiceFilterRequestDto
  >;
  deleteAllServiceFilters: DeleteEndpoint<string>;
  getAggregatedServiceData: PostEndpoint<
    string,
    IntServiceDataAggregateRequestDto,
    IAggregatedServiceDataResponse
  >;
  // The old endpoint that requires serviceId and can only grab data from a single service.
  // To be removed when the newer system is fully tested, see PBI #84183
  getAggregatedServiceDataNew: PostEndpoint<
    undefined,
    IntServiceDataAggregateRequestDto,
    IAggregatedServiceDataResponse
  >;
}

export interface IntConnectivityDataResponseDto {
  interval: IntServiceDataPointInterval;
  groupedData: IGroupedData[];
}

export interface IServiceDataResponse {
  interval: IntServiceDataPointInterval;
  groupedData: IGroupedData[];
}

export interface IAggregatedServiceDataResponse {
  interval: IntServiceDataPointInterval;
  result: Record<string, number>;
}

export interface IGroupedData<TData = any> {
  id: string;
  owner: Record<string, string>;
  dataPoints: TData[];
}

const baseUrl = '/api/service';

export const serviceApi: IServiceAPI = {
  getAll: {
    url: baseUrl,
    mockData: serviceDemoData.getAllServices,
  },
  getService: {
    url: serviceId => `${baseUrl}/${serviceId}`,
    mockData: serviceDemoData.getService,
  },
  getServiceDetail: {
    url: serviceId => `${baseUrl}/${serviceId}/detail`,
    mockData: serviceDemoData.getServiceDetails,
  },
  getServiceData: {
    url: serviceId => `${baseUrl}/${serviceId}/data`,
    mockData: serviceDemoData.getServiceData,
  },
  newGetServiceData: {
    url: () => `${baseUrl}/data`,
    mockData: serviceDemoData.getServiceDataNoServiceId,
  },
  getFilterOptions: {
    url: `${baseUrl}/getfilteroptions`,
    mockData: serviceDemoData.getFilterOptions,
  },
  getServicesTable: {
    url: `${baseUrl}/table`,
    mockData: serviceDemoData.getServiceTable,
  },
  getVendorApiDataKeys: {
    url: vendorApitype => `${baseUrl}/vendorapidatakeys/${vendorApitype}`,
    mockData: () => ({ status: 200 }),
  },
  createService: {
    url: baseUrl,
    mockData: () => ({ status: 201 }),
  },
  editService: {
    url: serviceId => `${baseUrl}/${serviceId}`,
    mockData: () => ({ status: 200 }),
  },

  testDeleteService: {
    url: serviceId => `${baseUrl}/${serviceId}/test`,
    mockData: () => ({ status: 200 }),
  },
  deleteService: {
    url: serviceId => `${baseUrl}/${serviceId}`,
    mockData: () => ({ status: 200 }),
  },
  getServiceFilters: {
    url: customerId => `${baseUrl}/filter/${customerId}`,
  },
  createOrUpdateServiceFilters: {
    url: `${baseUrl}/filter`,
  },
  deleteAllServiceFilters: {
    url: customerId => `${baseUrl}/filter/${customerId}`,
  },
  getAggregatedServiceData: {
    url: serviceId => `${baseUrl}/${serviceId}/data/aggregate`,
    mockData: serviceDemoData.getAggregatedServiceData,
  },
  getAggregatedServiceDataNew: {
    url: `${baseUrl}/data/aggregate`,
    mockData: serviceDemoData.getAggregatedServiceDataNew,
  },
};
