import { Button, Dialog, DialogContent } from '@mui/material';
import { withAppInsightsTrack } from 'appInsights';
import DialogTitleWrapper from 'components/DialogTitleWrapper';
import Form from 'components/Form';
import { FormField } from 'components/FormFields';
import LoadingOverlay from 'components/LoadingOverlay';
import ModalFooterWithButtons from 'components/ModalFooterWithButtons';
import { Formik } from 'formik';
import { IntCreateDashboardRequestDto, IntMenuItemDto } from 'generated';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { IRouteItem } from 'shared/interfaces/route-item';
import { useStores } from 'store';
import { Yup } from 'utils';

interface IProps {
  show: boolean;
  closeModal: () => void;
  parentRouteItem: IRouteItem | IntMenuItemDto; // Remove double type when new menu is in order.
}

type RouteProps = IRouteItem | IntMenuItemDto;

const CreateDashboardModal: React.FC<IProps> = ({
  show,
  closeModal,
  parentRouteItem,
}) => {
  const { t } = useTranslation('dashboard');
  const { dashboardStore, authStore } = useStores();
  const history = useHistory();

  function isNewNavigation(props: RouteProps): props is IntMenuItemDto {
    return 'navigationId' in props;
  }

  const createDashboardValidationSchema =
    Yup.form<IntCreateDashboardRequestDto>({
      displayName: Yup.string().required(),
      customerId: Yup.string().required(),
      navigationId: Yup.string().notRequired(),
      parentMenuItemId: Yup.string().notRequired(),
      icon: Yup.string().required(),
    });

  const initialValues: IntCreateDashboardRequestDto = {
    displayName: '',
    customerId: authStore.user?.customerId || '',
    navigationId: isNewNavigation(parentRouteItem)
      ? parentRouteItem.navigationId
      : '',
    parentMenuItemId: isNewNavigation(parentRouteItem)
      ? parentRouteItem.menuItemId
      : '',
    icon: 'Graph',
  };

  const path = isNewNavigation(parentRouteItem)
    ? `/dashboard`
    : `/building-climate-monitoring`;

  const handleSubmit = async (values: IntCreateDashboardRequestDto) => {
    const createdDashboard = await dashboardStore.createDashboard(values);

    if (createdDashboard) {
      closeModal();
      history.push(`${path}/${createdDashboard.dashboardId}`);
    }
  };

  return (
    <Dialog open={show} onClose={closeModal} maxWidth="sm" fullWidth>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={createDashboardValidationSchema}
      >
        {({ isValid, dirty, isSubmitting }) => (
          <Form>
            <LoadingOverlay isLoading={isSubmitting}>
              <DialogTitleWrapper
                id="create-user"
                title={t('action.dashboard.add')}
                onClose={closeModal}
              />

              <DialogContent>
                <FormField.TextField
                  name="displayName"
                  label={t('name')}
                  required
                  autoFocus
                  maxLength={50}
                />
              </DialogContent>

              <ModalFooterWithButtons
                leftButtons={
                  <Button
                    type="button"
                    color="primary"
                    variant="outlined"
                    onClick={closeModal}
                  >
                    {t('common:action.cancel')}
                  </Button>
                }
                rightButtons={
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={!isValid || !dirty || isSubmitting}
                    data-cy="createDashboardSubmitButton"
                  >
                    {t('action.dashboard.create')}
                  </Button>
                }
              />
            </LoadingOverlay>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default withAppInsightsTrack(observer(CreateDashboardModal));
