import { Box, Button, Chip, FormControl } from '@mui/material';
import AtlasIcon from 'components/AtlasIcon';
import LoadingOverlay from 'components/LoadingOverlay';
import { snackbar } from 'components/Snackbar';
import { useField, useFormikContext } from 'formik';
import { IntCreateDocumentRequestDto } from 'generated';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { convertFileToBase64String } from 'utils';
import { useStyles } from './DocumentUpload.styles';

interface IProps {
  name: string;
  label: string;
  maxSize?: number; // per file
  onUploadStart?: () => void;
  onUploadEnd?: () => void;
}

interface FileUploadState {
  isUploading: boolean;
  error?: string;
}

const DocumentUpload: React.FC<IProps> = ({
  name,
  label,
  maxSize,
  children,
  onUploadStart,
  onUploadEnd,
}) => {
  const { t } = useTranslation();
  const [state, setState] = useState<FileUploadState>({ isUploading: false });

  const [field, meta] = useField<Partial<IntCreateDocumentRequestDto>>(name);
  const { setFieldValue } = useFormikContext<any>();

  const classes = useStyles();

  const { getRootProps, getInputProps, open } = useDropzone({
    multiple: false,
    maxSize,
    noClick: true,
    onDropRejected: () => {
      snackbar(t('validation:file_too_large'), { variant: 'error' });
    },
    onDrop: async acceptedFiles => {
      if (acceptedFiles.length === 0) {
        return;
      }
      convertFileToBase64String(acceptedFiles[0], base64 => {
        setState({ isUploading: true });
        onUploadStart && onUploadStart();
        const data: Partial<IntCreateDocumentRequestDto> = {
          fileName: acceptedFiles[0].name,
          fileData: (base64 as string).split(',')[1],
        };
        setState({ isUploading: false });
        onUploadEnd && onUploadEnd();
        setFieldValue(name, data);
      });
    },
  });

  const removeFile = () => {
    setFieldValue(name, { fileName: '', fileData: '' });
  };

  return (
    <FormControl error={!!meta.error} fullWidth>
      <section {...getRootProps()}>
        <LoadingOverlay isLoading={state.isUploading}>
          <input {...getInputProps()} />
          {field.value?.fileName && (
            <Chip
              key={field.value?.fileName}
              label={field.value?.fileName}
              onDelete={() => removeFile()}
              variant="filled"
              className={classes.chip}
            />
          )}
          <Box pt={0.5}>
            <Button
              startIcon={<AtlasIcon type="Attachment" fontSize="small" />}
              color="primary"
              variant="text"
              onClick={open}
              disabled={!!field.value?.fileName}
            >
              {label}
            </Button>
          </Box>
          {children}
        </LoadingOverlay>
      </section>
    </FormControl>
  );
};

export default DocumentUpload;
