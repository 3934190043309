import MessageOverlay from 'components/MessageOverlay';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { IDashboardComponent } from 'views/Dashboard/dashboardTypes';
import { useDataSource, useDataSourceData } from 'views/Dashboard/DataSources';
import { IDataProperty } from 'views/Dashboard/DataSources/dataPropTypes';
import Widget from 'views/Dashboard/Widget';
import HighchartsMap from './HighchartsMap';
import { IHighthchartsMapSettings } from './highchartsMapConfig';

export interface IHighchartsMapViewModel {
  value: IDataProperty;
}

const HighchartsMapContainer: IDashboardComponent<IHighthchartsMapSettings> = ({
  component,
}) => {
  const { t } = useTranslation('dashboard');
  const { settings } = component;

  const dataSource = useDataSource(settings.dataSource);

  const dataProps = dataSource.getDataProps<IHighchartsMapViewModel>(
    getProp => ({
      value: getProp(settings.valueProp),
    })
  );

  const { data, noContent, error } = useDataSourceData(dataSource);

  return (
    <Widget isLoading={!dataSource.isInitialized || dataSource.isLoading}>
      <Widget.Header>{settings.title}</Widget.Header>
      <Widget.Content
        skeleton={
          !dataSource.isInitialized ||
          (!dataSource.lastReceivedData && dataSource.isLoading)
        }
      >
        {data && (
          <HighchartsMap
            data={data}
            settings={settings}
            viewModel={dataProps}
          />
        )}
        {noContent && <MessageOverlay message={t('no_data')} />}
        {error && <MessageOverlay message={error} />}
      </Widget.Content>
    </Widget>
  );
};

export default observer(HighchartsMapContainer);
