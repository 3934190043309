import { Box } from '@mui/material';
import * as azureMaps from 'azure-maps-control';
import React from 'react';
import { IWidgetData } from 'views/Dashboard/DataSources/dataSourceTypes';
import ClusteredMarkerLayer from '../ImageComponent/IconMarkerLayer/ClusteredMarkerLayer';
import { IMapFeature } from '../ImageComponent/ImagePinLayer';
import { IAzureMapsSettings } from './azureMapsConfig';
import { IAzureMapsViewModel } from './AzureMapsWidgetContainer';
import GroupedMapTooltip from './GroupedMapTooltip';
import { useGroupedFeatures } from './hooks/useGroupedFeatures';
import MapTable from './MapTable';
import { MapWidgetState } from './MapWidgetState';

interface IProps {
  settings: IAzureMapsSettings;
  viewModel: IAzureMapsViewModel;
  features: IMapFeature[];
  data: IWidgetData;
  map: azureMaps.Map;
  state: MapWidgetState;
}

const GroupedMapContent: React.FC<IProps> = ({
  settings,
  features,
  data,
  map,
  state,
  viewModel,
}) => {
  const groupedFeatures = useGroupedFeatures(features);

  return (
    <>
      {settings.showTable && (
        <Box height="50%">
          <MapTable
            state={state}
            tableViewModel={viewModel.table}
            data={data}
          />
        </Box>
      )}

      <ClusteredMarkerLayer
        features={groupedFeatures}
        map={map}
        state={state}
      />

      <GroupedMapTooltip state={state} features={groupedFeatures} />
    </>
  );
};

export default GroupedMapContent;
