import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { pxToRem } from 'styles/themes/makeAtlasTheme';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      width: '100%',
      borderCollapse: 'collapse',
    },
    tr: {
      '&:nth-child(odd)': {
        background: '#d4d4d4',
      },
    },
    td: {
      fontSize: pxToRem(18),
      lineHeight: 1.33,
      padding: theme.spacing(1),
    },
    checkRadio: {
      padding: 0,
      marginTop: -2,
    },
  })
);
