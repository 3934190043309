import {
  IntCreateDashboardRequestDto,
  IntDashboardDetailsDto,
  IntDashboardDto,
  IntEditDashboardDetailsRequestDto,
  IntEditDashboardRequestDto,
} from 'generated';
import {
  DeleteEndpoint,
  GetEndpoint,
  PatchEndpoint,
  PostEndpoint,
} from 'shared/interfaces/api';
import { dashboardDemoData } from 'views/Dashboard/dashboardDemoData';

interface IDashboardAPI {
  createDashboard: PostEndpoint<
    undefined,
    IntCreateDashboardRequestDto,
    IntDashboardDto
  >;
  getAllDashboards: GetEndpoint<undefined, IntDashboardDto[]>; // Basic details for all dashboards, to show links in menu
  getDashboardsByCustomer: GetEndpoint<string, IntDashboardDto[]>; // Basic details for all dashboards, to show links in menu
  getDashboardTemplates: GetEndpoint<undefined, IntDashboardDto[]>; // Basic details for all dashboards, to show links in menu
  deleteDashboard: DeleteEndpoint<string>;
  getDashboardDetails: GetEndpoint<string, IntDashboardDetailsDto>;
  updateDashboardMetaData: PatchEndpoint<
    string,
    IntEditDashboardRequestDto,
    IntDashboardDto
  >;
  updateDashboardDetails: PatchEndpoint<
    string,
    IntEditDashboardDetailsRequestDto,
    IntDashboardDetailsDto
  >;
  uploadImage: PostEndpoint<
    { dashboardId: string; dashboardComponentId: string },
    FormData,
    string
  >;
}

export const dashboardApi: IDashboardAPI = {
  getAllDashboards: {
    url: '/api/dashboard',
    mockData: () => ({
      status: 200,
      data: dashboardDemoData().getDashboards(),
    }),
  },
  getDashboardTemplates: {
    url: '/api/dashboard/template',
    mockData: () => ({
      status: 200,
      data: dashboardDemoData().getDashboards(),
    }),
  },
  getDashboardsByCustomer: {
    url: customerId => `/api/dashboard/list/${customerId}`,
    mockData: () => ({
      status: 200,
      data: dashboardDemoData().getDashboards(),
    }),
  },
  createDashboard: {
    url: '/api/dashboard',
    mockData: (_, data) => {
      if (!data || data.displayName === 'error') {
        return { status: 500 };
      }

      return {
        status: 201,
        data: dashboardDemoData().addDash(data),
      };
    },
  },
  deleteDashboard: {
    url: dashboardId => `/api/dashboard/${dashboardId}`,
    mockData: dashboardId => {
      dashboardDemoData().deleteDash(dashboardId);
      return {
        status: 204,
      };
    },
  },
  getDashboardDetails: {
    url: dashboardId => `/api/dashboard/${dashboardId}`,
    mockData: dashboardId => {
      const dashDetails = dashboardDemoData().getDetails(dashboardId);
      if (!dashDetails) {
        return { status: 404 };
      }
      return {
        status: 200,
        data: dashDetails,
      };
    },
  },
  updateDashboardMetaData: {
    url: dashboardId => `/api/dashboard/${dashboardId}`,
    mockData: (dashboardId, data) => {
      if (!data) {
        return { status: 500 };
      }
      return {
        status: 200,
        data: dashboardDemoData().updateDashboardName(dashboardId, data),
      };
    },
  },
  updateDashboardDetails: {
    url: dashboardId => `/api/dashboard/${dashboardId}`,
    mockData: (dashboardId, data) => {
      if (!data) {
        return { status: 500 };
      }
      return {
        status: 200,
        data: dashboardDemoData().updateDashboard(dashboardId, data),
      };
    },
  },
  uploadImage: {
    url: ({ dashboardId, dashboardComponentId }) =>
      `/api/dashboard/${dashboardId}/${dashboardComponentId}/UploadImage`,
    mockData: () => {
      return {
        status: 200,
        data: '/telia-company-logo.png',
      };
    },
  },
};
