const missingByNamespace: Record<string, Record<string, string>> = {};
let announceTimer: number;

function announceMissingKeys() {
  window.clearTimeout(announceTimer);
  announceTimer = window.setTimeout(() => {
    Object.entries(missingByNamespace).forEach(([namespace, keys]) => {
      let outputJSONString = '';
      Object.entries(keys).forEach(
        ([key, fallback]) =>
          (outputJSONString += `  "${key}": "${fallback}",\n`)
      );
      console.log(
        `\nMissing i18n keys in ${namespace}.json:\n\n${outputJSONString}\n`
      );
    });
  }, 100);
}

export function missingKeyHandler(
  languages: string[],
  namespace: string,
  key: string,
  fallbackValue: string
) {
  if (!missingByNamespace[namespace]) {
    missingByNamespace[namespace] = {};
  }
  missingByNamespace[namespace][key] = fallbackValue;
  announceMissingKeys();
}
