import { createStyles, makeStyles } from '@mui/styles';
import { fontSettings } from 'styles/themes/fontSettings';

export const usePopupStyles = makeStyles(() =>
  createStyles({
    root: {
      borderRadius: 8,
      minWidth: 240,
      maxWidth: 340,
      fontSize: 12,
      fontFamily: fontSettings.TeliaSansMedium.fontStack,
      color: '#222222',
    },
    textOverflowEllipsis: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    popupHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: 'rgba(244,244,249,.7)',
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      padding: 8,
    },
    popupHeaderTitle: {
      fontSize: 24,
      fontFamily: fontSettings.TeliaSansBold.fontStack,
      fontWeight: 'bold',
      padding: '5px 24px 5px 5px',
    },
    popupHeaderDesc: {
      textAlign: 'right',
      overflow: 'hidden',
    },
    popupProperty: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: 2,
      flexDirection: 'column',
    },
    popupPropertyLabel: {
      color: '#8C8C90',
      whiteSpace: 'normal',
      '&::after': {
        content: '" • "',
      },
    },
    popupDetail: {
      padding: '6px 0px 8px 12px',
    },
  })
);
