import { IntDashboardDataStatusRuleDto } from 'generated';

const sortIndexByColor: Record<string, number> = {
  red: 0,
  yellow: 1,
  green: 2,
  null: 3,
};

export function sortByStatusRuleColor<T>(
  getColor: (obj: T) => string | undefined | null,
  getValue?: (obj: T) => number | null
) {
  return (a: T, b: T) => {
    const aColorIndex = sortIndexByColor[getColor(a) || 'null'];
    const bColorIndex = sortIndexByColor[getColor(b) || 'null'];

    if (aColorIndex === bColorIndex && getValue) {
      return (
        (getValue(a) ?? Number.MIN_VALUE) - (getValue(b) ?? Number.MIN_VALUE)
      );
    }
    return aColorIndex - bColorIndex;
  };
}

// Sort an array by status rule colors, in order red-yellow-green-null
export function sortArrayByStatusRuleColor<T>(
  arr: T[],
  getColor: (obj: T) => string | undefined | null,
  getValue?: (obj: T) => number | null
) {
  return [...arr].sort(sortByStatusRuleColor(getColor, getValue));
}

export function sortArrayByStatusRuleComparer(
  arr: IntDashboardDataStatusRuleDto[]
) {
  const sortNullValueLast = 3;
  return [...arr].sort((a, b) => {
    const aComparer = a.textComparer ?? sortNullValueLast;
    const bComparer = b.textComparer ?? sortNullValueLast;

    return aComparer - bComparer;
  });
}
