import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    container: {
      position: 'relative',
    },
    root: {
      width: 467,
      borderRadius: 5000,
      height: '100%',
      backgroundColor: '#f2f2f2',
      border: 'none',
      margin: '0 auto',
    },
    input: {
      paddingTop: 10,
      paddingBottom: 10,
      marginLeft: 20,
    },
  })
);
