import { emphasize } from '@mui/material';
import { IUiConfiguration } from 'styles/themes/IUiConfiguration';
import { defaultUiConfiguration } from './defaultUiConfiguration';
import { IAtlasThemeSettings } from './IAtlasThemeSettings';
import {
  buttonConfig,
  colorsConfig,
  fabConfig,
  palatteConfig,
  tableConfig,
  typographyConfig,
} from './UIConfiguration';

export function applyUIConfiguration(
  uiConfig: IUiConfiguration
): IAtlasThemeSettings {
  const { typography, assets, bodyFontStack } = typographyConfig(uiConfig);
  const { primaryColor, colors, supportColors } = colorsConfig(uiConfig);
  const button = buttonConfig(uiConfig, primaryColor, bodyFontStack);
  const palette = palatteConfig(uiConfig);

  // Consider improvements to this, very basic, logic

  const themeSettings: IAtlasThemeSettings = {
    palette,
    colors,
    supportColors,
    spacingFactor: 4,
    assets,
    typography,
    spinner: { type: 'telia' },
    icons: { library: 'telia' },
    components: {
      button,
      iconButton: {
        root: {
          padding: '12px',
        },
        hoverBackgroundColor: {
          backgroundColor: uiConfig.colors.primary + '0C', //  0C = 0.05 alpha
        },
      },
      contentContainer: {
        maxWidth: 1700,
        margin: '0 auto',
        padding: '10px 12px',
      },
      body: {
        fontSize: '.75rem',
        lineHeight: '1.125rem',
        backgroundColor: '#f6f6f6',
      },
      fab: fabConfig,
      navigation: {
        menuItem: {
          height: 42,
          iconSize: 24,
          fontSize: 14,
          colors: {
            active: uiConfig.navigation.menuItem.colors.active,
            // This is ugly but the default telia purple doesn't look quite right brightened and I want to keep uiConfigs lean to start with. MVP.
            header:
              uiConfig.navigation.background ===
              defaultUiConfiguration.navigation.background
                ? '#570580'
                : emphasize(uiConfig.navigation.background, 0.2),
          },
        },
      },
      navMenu: {
        width: 240,
        collapsedWidth: 85,
        closerWidth: 10,
        showFooter: true,
        background: uiConfig.navigation.background,
      },
      topMenu: {
        height: 76,
        logoSrc: uiConfig.logoUrl,
        logoMiniSrc: uiConfig.logoUrl,
        root: {
          borderBottom: '1px solid rgb(241, 241, 241)',
          background: '#FFF',
          boxShadow: 'none',
        },
      },
      settingsMenu: {
        width: 400,
      },
      table: tableConfig,
      contentCard: {
        borderRadius: 0,
        boxShadow: '1px 1px 6px rgba(1, 1, 1, .05)',
        background: '#FFF',
        border: '1px solid #e4e4e4',
      },
      widget: {
        header: {},
        headerHeight: 32,
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
  };

  return themeSettings;
}
