import Highcharts from 'components/Highcharts';
import MessageOverlay from 'components/MessageOverlay';
import { Chart } from 'highcharts';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IWidgetData } from 'views/Dashboard/DataSources/dataSourceTypes';
import { IColumnChartSettings } from './columnChartConfig';
import { IColumnChartViewModel } from './ColumnChartContainer';
import { useColumnChartOptions } from './useColumnChartOptions';

interface IProps {
  viewModel: IColumnChartViewModel;
  data: IWidgetData;
  settings: IColumnChartSettings;
  chartRef?: (chart: Chart) => void;
}

const ColumnChart: React.FC<IProps> = ({
  data,
  settings,
  viewModel,
  chartRef,
}) => {
  const chartOptions = useColumnChartOptions({ data, settings, viewModel });
  const { t } = useTranslation('dashboard');

  const hasNoDataToShow = !chartOptions.series?.length;

  return (
    <>
      <Highcharts
        callback={chartRef}
        options={chartOptions}
        containerProps={{
          style: {
            height: '100%',
          },
        }}
      />
      {hasNoDataToShow && <MessageOverlay message={t('no_data')} />}
    </>
  );
};

export default observer(ColumnChart);
