import { DashboardComponentType } from 'generated';
import {
  IBaseComponentSettings,
  IComponentTypeConfig,
} from 'views/Dashboard/dashboardTypes';
import { DataPropSetting } from 'views/Dashboard/DataSources/dataPropTypes';
import { IDataSourceSettings } from 'views/Dashboard/DataSources/dataSourceTypes';
import NumberWidget from './NumberWidget';
import GeneralSettingsStep from './SettingsSteps/GeneralSettingsStep';
import PreviewStep from './SettingsSteps/PreviewStep';

export interface INumberSettings extends IBaseComponentSettings {
  title: string;
  dataSource: IDataSourceSettings;
  valueProp: DataPropSetting;
  textSize: TextSize;
  backgroundColor: string;
  unit: string;
  showTimestamp?: boolean;
  timeInterval?: number;
}

export type TextSize = '32px' | '64px' | '96px';

export const numberComponentConfig: IComponentTypeConfig<
  DashboardComponentType.Number,
  INumberSettings
> = {
  widgetType: DashboardComponentType.Number,
  icon: 'Graph',
  Component: NumberWidget,
  settingsSteps: [
    {
      title: 'settings',
      Component: GeneralSettingsStep,
    },
    {
      title: 'final_preview',
      Component: PreviewStep,
    },
  ],
  getDefaultSettings: ({ dataSource }) => {
    const valueProp = dataSource.dataProperties.find(
      dp => dp.type === 'number'
    );
    return {
      position: { x: 0, y: 0, w: 4, h: 4 },
      title: '',
      dataSource: dataSource.settings,
      valueProp: valueProp?.id || '',
      textSize: '32px',
      backgroundColor: '#FFFFFF',
      unit: valueProp?.unit || '',
      timeInterval: 60,
    };
  },
};
