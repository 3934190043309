import { Box, Typography } from '@mui/material';
import AtlasIcon from 'components/AtlasIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  text?: string;
  dataCy?: string;
}

const ErrorIcon: React.FC<IProps> = ({ text, dataCy }) => {
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
      sx={{ flexGrow: 1 }}
      data-cy={dataCy}
    >
      <AtlasIcon type="StatusWarning" size={32} gutterBottom />
      <Typography color="textPrimary" variant="h6">
        {text || t('error.base')}
      </Typography>
    </Box>
  );
};

export default ErrorIcon;
