import { Grid } from '@mui/material';
import { IntDashboardDataStatusDto } from 'generated';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'store';
import RulesForm from './RulesForm';

interface IProps {
  status: IntDashboardDataStatusDto;
  index: number;
}

const DataStatusForm: React.FC<IProps> = ({ status, index }) => {
  const {
    dashboardStore: { serviceProperties = [] },
  } = useStores();
  const { t } = useTranslation('policy');

  const namePrefix = `statuses[${index}]`;

  const serviceProperty = serviceProperties.find(
    sp => sp.servicePropertyId === status.servicePropertyId
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <RulesForm
            title={t('bad_status')}
            statusColor="red"
            rules={status.rules}
            namePrefix={namePrefix}
            defaultIcon="SmileySad"
            addStatusLabel={t('action.add_bad_status')}
            unit={serviceProperty?.unit || ''}
            propertyType={serviceProperty?.dataType}
          />
          <RulesForm
            title={t('moderate_status')}
            statusColor="yellow"
            rules={status.rules}
            namePrefix={namePrefix}
            defaultIcon="Info"
            addStatusLabel={t('action.add_moderate_status')}
            unit={serviceProperty?.unit || ''}
            propertyType={serviceProperty?.dataType}
          />
          <RulesForm
            title={t('good_status')}
            statusColor="green"
            rules={status.rules}
            namePrefix={namePrefix}
            defaultIcon="Smiley"
            addStatusLabel={t('action.add_good_status')}
            unit={serviceProperty?.unit || ''}
            propertyType={serviceProperty?.dataType}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default observer(DataStatusForm);
