import Skeleton from '@mui/material/Skeleton';
import classNames from 'classnames';
import React from 'react';
import { useStyles } from './WidgetContent.styles';

interface IProps {
  skeleton?: boolean;
  backgroundColor?: string;
  color?: string;
}

const WidgetContent: React.FC<IProps> = ({
  children,
  skeleton,
  backgroundColor,
  color,
}) => {
  const classes = useStyles();

  return (
    <div
      className={classNames('WidgetContent', classes.root)}
      style={{ backgroundColor, color }}
      data-cy="WidgetContent"
    >
      {children}
      {skeleton && (
        <Skeleton
          variant="rectangular"
          animation="wave"
          className={classes.skeleton}
        />
      )}
    </div>
  );
};

export default WidgetContent;
