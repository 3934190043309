import { Box, ButtonBase, Popover } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import AtlasIcon from 'components/AtlasIcon';
import React from 'react';
import { pxToRem } from 'styles/themes/makeAtlasTheme';
import { usePopover } from 'utils';
import { DropdownContext } from './DropdownContext';

interface IProps<TValue = any> {
  label: string;
  onItemClick: (value: TValue) => void;
  cyId?: string;
  icon?: JSX.Element;
  disabled?: boolean;
  children?: React.ReactNode;
  hideChevron?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      padding: 0,
      fontSize: pxToRem(14),
    },
  })
);

function DropdownMenu<TValue extends string | number>({
  label,
  icon,
  onItemClick,
  disabled,
  cyId,
  children,
  hideChevron = false,
}: IProps<TValue>) {
  const classes = useStyles();
  const [openPopover, popoverProps] = usePopover(label);

  const handleItemClick = (value: TValue) => {
    onItemClick(value);
    popoverProps.onClose();
  };

  return (
    <DropdownContext.Provider value={{ onOptionClick: handleItemClick }}>
      <ButtonBase
        onClick={openPopover}
        disabled={disabled}
        className={classes.button}
        data-cy={cyId}
      >
        {icon && (
          <Box mr={1} data-cy={`${cyId}-icon`}>
            {icon}
          </Box>
        )}
        <Box data-cy={`${cyId}-label`}>{label}</Box>
        {!hideChevron && !disabled && (
          <Box ml={1}>
            <AtlasIcon
              type={popoverProps.open ? 'ChevronUp' : 'ChevronDown'}
              color="primary"
              size={14}
            />
          </Box>
        )}
      </ButtonBase>
      <Popover
        {...popoverProps}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        {children}
      </Popover>
    </DropdownContext.Provider>
  );
}

export default DropdownMenu;
