import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    padding: '20px 16px 4px 0',
  },
  select: {
    zIndex: 1,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  input: {
    width: '0px',
    minWidth: '30px',
    flexGrow: 1,
  },
  flag: {
    width: 24,
    height: 24,
  },
  disabled: {
    backgroundColor: theme.palette.grey[100],
  },
}));
