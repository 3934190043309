import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    rootGrid: {
      display: 'grid',
      gridTemplateColumns: '320px 1fr',
      gridTemplateRows: '50px 1fr',
      gridColumnGap: theme.spacing(6),
      gridRowGap: 16,
      height: '60vh',
    },
    // Label
    topLeft: {
      gridArea: '1 / 1 / 2 / 2',
      alignSelf: 'flex-end',
      marginBottom: theme.spacing(2),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'end',
    },
    // Filter type list
    middleLeft: {
      gridArea: '2 / 1 / 2 / 2',
      overflow: 'auto',
      paddingRight: theme.spacing(6),
      borderRight: '1px solid #eaeaed',
    },
    assetCountButton: {
      fontSize: '1rem',
      color: '#5a5a5a',
      fontWeight: 'bold',
      display: 'flex',
      justifyContent: 'space-between',
      cursor: 'pointer',
      padding: 8,
      borderRadius: 8,
      minWidth: 250,
    },
    assetCountNumber: {
      marginLeft: theme.spacing(3),
      color: theme.palette.primary.main,
      '$assetCountSelected &': {
        color: '#FFF',
      },
    },
    assetCountSelected: {
      color: '#FFF',
      background: theme.palette.primary.main,
    },
    firstSelectedFilterValue: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      maxWidth: 175,
    },
  })
);
