import { faker } from '@faker-js/faker';
import { TableData } from 'components/Table';
import {
  IntAddGroupMembersRequestDto,
  IntConnectivityUnitGroupDetailsDto,
  IntConnectivityUnitGroupDto,
  IntConnectivityUnitStatusCountDto,
  IntConnectivityUnitTableDto,
  IntGroupMinimalConsumptionDto,
  IntSearchDto,
  IntUnitMinimalConsumptionDto,
} from 'generated';
import { IResponse } from 'shared/interfaces/api';
import { paginate, rnd } from 'utils';
import { apnHubDemoData } from './apnHubDemoData';
import { connectivityUnitDemoData } from './connectivityUnitDemoData';

type IDemoGroup = IntConnectivityUnitGroupDto &
  IntConnectivityUnitGroupDetailsDto;

class ConnectivityGroupDemoData {
  private _groups: IDemoGroup[] | undefined;
  get groups() {
    if (!this._groups) {
      this._groups = rnd.array<IDemoGroup>(19, i => {
        const { resourceGroupName, resourceGroupId } = rnd.resourceGroup(i + 1);
        const createDate = faker.date.recent();

        const unitsInGroup = connectivityUnitDemoData.units.filter(
          unit => unit.resourceGroupName === resourceGroupName
        );
        const connectivityUnitStatuses: IntConnectivityUnitStatusCountDto[] =
          [];
        unitsInGroup.forEach(unit => {
          let statusDto = connectivityUnitStatuses.find(
            x => x.connectivityUnitStatus === (unit.activationStatus as any)
          );
          if (!statusDto) {
            statusDto = {
              // "any" type below will be replaced with specific type in work item #29457
              connectivityUnitStatus: unit.activationStatus as any,
              count: 0,
            };
            connectivityUnitStatuses.push(statusDto);
          }
          statusDto.count++;
        });

        return {
          id: resourceGroupId,
          customerId: rnd.guid(),
          customerName: rnd.customerName(),
          label: resourceGroupName,
          lastActive: rnd.date.recent(),
          totalData: rnd.mb(),
          totalSms: rnd.int(1, 1000),
          identifier: rnd.int(1, 1000),
          memberCount: unitsInGroup.length,
          created: createDate,
          description: '',
          createDate,
          topDataConsumingConnectivityUnits:
            rnd.array<IntUnitMinimalConsumptionDto>(5, () => ({
              connectivityUnitId: rnd.guid(),
              identifier: rnd.euiccId(),
              label: rnd.label(),
              totalConsumption: rnd.data(),
            })),
          topSmsConsumingConnectivityUnits:
            rnd.array<IntUnitMinimalConsumptionDto>(5, () => ({
              connectivityUnitId: rnd.guid(),
              identifier: rnd.euiccId(),
              label: rnd.label(),
              totalConsumption: rnd.int(0, 500),
            })),
          connectivityUnitStatuses,
          creator: {
            userId: rnd.guid(),
            firstName: rnd.firstName(),
            lastName: rnd.lastName(),
          },
          profileName: rnd.item(...apnHubDemoData.demoApnProfiles).name,
          name: resourceGroupName,
          localizedProgress: 0.7,
          rules: [],
          triggers: [],
          totalSims: unitsInGroup.length,
        };
      });
    }
    return this._groups;
  }

  getGroup = (connectivityGroupId: string): IDemoGroup => {
    const group = this.groups.find(x => x.id === connectivityGroupId);

    if (!group) {
      throw new Error('ConnectivityGroup missing');
    }

    return group;
  };

  getConnectivityGroupTable = (
    params: IntSearchDto
  ): IResponse<TableData<IntConnectivityUnitGroupDto>> => {
    const filteredGroups = this.groups.filter(unit => {
      if (params.search) {
        return unit.label.toLowerCase().includes(params.search.toLowerCase());
      }
      return true;
    });

    return {
      status: 200,
      data: paginate(filteredGroups, params),
    };
  };

  getGroupDetails = (params: {
    id: string;
    startDate?: Date;
    endDate?: Date;
  }): IResponse<IntConnectivityUnitGroupDetailsDto> => {
    return {
      status: 200,
      data: this.getGroup(params.id),
    };
  };

  getMostActiveGroups = (): IResponse<IntGroupMinimalConsumptionDto[]> => {
    return {
      status: 200,
      data: rnd.array<IntGroupMinimalConsumptionDto>(10, i => ({
        id: this.groups[i].id,
        identifier: this.groups[i].identifier,
        label: this.groups[i].label,
        memberCount: this.groups[i].memberCount,
        totalData: this.groups[i].totalData,
      })),
    };
  };

  addMembersToGroup = (
    groupId: string,
    membersToAdd: IntAddGroupMembersRequestDto | undefined
  ): IResponse<IntConnectivityUnitTableDto[]> => {
    const group = this.getGroup(groupId);
    const members = membersToAdd?.connectivityUnitIds.map(
      connectivityUnitDemoData.getUnit
    );
    members?.forEach(unit => {
      unit.resourceGroupName = group.label;
      unit.resourceGroupId = group.id;
    });

    group.memberCount = connectivityUnitDemoData.units.filter(
      unit => unit.resourceGroupName === group.label
    ).length;

    return {
      status: 200,
    };
  };

  removeMemberFromGroup = (params: {
    groupId: string;
    id: string;
    forceImmediateChange: boolean;
  }) => {
    const group = this.getGroup(params.groupId);
    const member = connectivityUnitDemoData.getUnit(params.id);
    member.resourceGroupName = '';
    member.resourceGroupId = '';

    group.memberCount = connectivityUnitDemoData.units.filter(
      unit => unit.resourceGroupName === group.label
    ).length;

    return {
      status: 200,
    };
  };
}

export const connectivityGroupDemoData = new ConnectivityGroupDemoData();
