import { Box } from '@mui/material';
import AtlasIcon from 'components/AtlasIcon';
import AtlasTooltip from 'components/AtlasTooltip';
import i18n from 'i18next';
import { Column } from 'react-table';
import ColArrayValue from '../Helpers/ColArrayValue';
import { FilterOption } from '../Helpers/filterOption';
import { getOptionFilter } from '../Helpers/getOptionFilter';
import { textRight } from '../Helpers/textRight';

export function boolean<T>(options: {
  id: string;
  header: string;
  accessor: (row: T) => boolean | boolean[] | undefined | null;
  defaultShow?: boolean;
  defaultSortDesc?: boolean;
  filterable?: boolean;
  sortable?: boolean;
  resizable?: boolean;
  align?: 'left' | 'right';
  tooltip?: boolean;
  tooltipText?: string;
  width?: number;
  nullValue?: string;
}): Column<T> {
  const {
    id,
    header,
    accessor,
    defaultShow: show = true,
    defaultSortDesc = false,
    filterable = false,
    sortable,
    resizable,
    nullValue,
    tooltip = false,
    tooltipText = '',
    align = 'left',
    width = 100,
  } = options;

  const filterOptions: FilterOption[] = [
    { label: 'All', value: '' },
    { label: i18n.t('common:action.yes'), value: 'True' },
    { label: i18n.t('common:action.no'), value: 'False' },
  ];

  let filterRender = getOptionFilter(filterOptions);

  return {
    id,
    Header: tooltip ? (
      <AtlasTooltip placement="top" title={tooltipText} arrow>
        <Box>
          {header}
          <AtlasIcon size={14} type="Info" />
        </Box>
      </AtlasTooltip>
    ) : (
      header
    ),
    accessor,
    defaultSortDesc,
    width,
    show,
    filterable,
    sortable,
    resizable,
    Filter: filterRender,
    Cell: row => {
      if (row.value === null || row.value === undefined) {
        return (
          <span className="text-muted">
            {nullValue ?? i18n.t('not_applicable_abbreviation')}
          </span>
        );
      }
      if (Array.isArray(row.value)) {
        return <ColArrayValue value={row.value} type="boolean" />;
      } else {
        return row.value ? i18n.t('yes') : i18n.t('no');
      }
    },
    className: align === 'left' ? undefined : textRight,
  };
}
