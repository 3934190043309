import { Autocomplete, Chip, TextField } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { uniqueId } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getYupSchema } from 'views/Management/Automations/EditAutomationWizard/FormValidation';
import { InputType } from '../TextField/TextField';
import { useStyles } from './StringList.styles';
interface IProps {
  name: string;
  label: string;
  type?: InputType;
  required?: boolean;
  placeholder?: string;
  limitTags?: number;
}

const StringList: React.FC<IProps> = ({
  name,
  label,
  type = 'text',
  required = false,
  placeholder,
  limitTags = 3,
}) => {
  const classes = useStyles();
  const [field, { error, touched }, helpers] = useField(name);
  const { setFieldTouched } = useFormikContext();

  const isErrorArray = Array.isArray(error);
  const fieldError = isErrorArray ? error[error.length - 1] : error;
  const listItems = field.value && !field.value.join('') ? [] : field.value;
  const { t } = useTranslation();

  const validateInputAccordingToYup = (input: string) => {
    try {
      getYupSchema(name, field.value, t, type).validateSync(input);
      return true;
    } catch (error) {
      return false;
    }
  };

  return (
    <Autocomplete
      multiple
      freeSolo
      classes={{
        root: classes.root,
        inputRoot: classes.inputRoot,
        input: classes.input,
      }}
      id={name}
      options={[]}
      value={listItems}
      onChange={(_, newValue, reason) => {
        const rejectedReason = ['createOption', 'blur'];
        if (rejectedReason.includes(reason)) {
          setFieldTouched(name, true);
        }
        helpers.setValue(newValue);
      }}
      isOptionEqualToValue={() => {
        // we want to validate duplicate with yup to be able to show error message
        return false;
      }}
      autoSelect
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          const key = uniqueId();
          return (
            <Chip
              color={validateInputAccordingToYup(option) ? 'default' : 'error'}
              {...getTagProps({ index })}
              key={key}
              label={option}
            />
          );
        })
      }
      ChipProps={{ size: 'small', variant: 'filled' }}
      limitTags={limitTags}
      renderInput={params => {
        return (
          <TextField
            {...params}
            variant="outlined"
            placeholder={placeholder}
            data-cy={name}
            label={label}
            type={type}
            required={required}
            onBeforeInput={() => {
              field.value &&
                !field.value.join('') &&
                helpers.setError(undefined);
            }}
            onBlur={field.onBlur}
            error={touched && !!fieldError}
            helperText={touched && fieldError}
          />
        );
      }}
    />
  );
};

export default StringList;
