import { InputBase } from '@mui/material';
import AtlasIcon from 'components/AtlasIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './GlobalSearch.styles';

const GlobalSearch: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <InputBase
      className={classes.root}
      endAdornment={
        <AtlasIcon type="Search" style={{ marginRight: 30 }} size={24} />
      }
      placeholder={t('common:search_placeholder')}
      inputProps={{ className: classes.input }}
    />
  );
};

export default GlobalSearch;
