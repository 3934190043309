import {
  Collapse,
  Drawer,
  Link as MUILink,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  Switch,
  Tab,
  Tabs,
  useTheme,
} from '@mui/material';
import AtlasIcon from 'components/AtlasIcon';
import { EFeature } from 'components/Auth/EFeature';
import { Role } from 'components/Auth/Role';
import { observer } from 'mobx-react-lite';
import { FC, lazy, Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import { useStores } from 'store';
import { isDevelopment } from 'utils';
import TabPanel from '../TabPanel';
import { useStyles } from './SettingsDrawer.styles';
import SimulateSubPortalSetting from './SimulateSubPortalSetting';

interface IProps {
  isOpen: boolean;
  toggle: () => void;
}

const RoleViewer = lazy(() => import('./RoleViewer'));

export const httpStatusCodes = [
  { label: '200', value: 200 },
  { label: '201', value: 201 },
  { label: '204', value: 204 },
  { label: '400', value: 400 },
  { label: '401', value: 401 },
  { label: '403', value: 403 },
  { label: '404', value: 404 },
  { label: '500', value: 500 },
];

const SettingsDrawer: FC<IProps> = ({ isOpen }) => {
  const classes = useStyles();
  const theme = useTheme();
  const {
    authStore,
    toggleDemoData,
    demoMode,
    toggleErrorMode,
    errorMode,
    httpStatusCode,
    setHttpStatusCode,
    layoutStore: { disableNewNavigation, toggleDisableNewNavigation },
  } = useStores();
  const [tab, setTab] = useState(0);
  const { navMenu } = theme.settings.components;
  const { t, i18n } = useTranslation('profile');

  const canSimulateSubportals = authStore.hasRole(
    Role.RoleNameSimulateSubPortal
  );

  return (
    <Drawer
      variant="persistent"
      anchor={navMenu.position === 'right' ? 'left' : 'right'}
      open={isOpen}
      className={classes.drawer}
      classes={{ paper: classes.drawer }}
    >
      <Tabs
        value={tab}
        variant="fullWidth"
        indicatorColor="primary"
        onChange={(_, newVal) => setTab(newVal)}
      >
        <Tab value={0} label={t('user.base')} className={classes.tab} />
        <Tab value={1} label={t('common:roles')} className={classes.tab} />
      </Tabs>
      <div className={classes.tabContent}>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={tab}
          onChangeIndex={setTab}
        >
          {/* Fragment to make Swipeable package happy */}
          <>
            {(authStore.hasFeature(EFeature.EnablePreviewFeatures) ||
              isDevelopment) && (
              <TabPanel value={tab} index={0} dir={theme.direction}>
                <List
                  dense
                  subheader={
                    <ListSubheader>{t('demo_settings')}</ListSubheader>
                  }
                >
                  <ListItem>
                    <ListItemIcon>
                      <AtlasIcon type="AiRobot" />
                    </ListItemIcon>
                    <ListItemText
                      primary={t('demo_data.base')}
                      secondary={t('demo_data.secondary')}
                    />
                    <ListItemSecondaryAction>
                      <Switch
                        color="primary"
                        onChange={toggleDemoData}
                        checked={demoMode}
                        edge="end"
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem disabled={!demoMode}>
                    <ListItemIcon>
                      <AtlasIcon type="SmileySad" />
                    </ListItemIcon>
                    <ListItemText
                      primary={t('demo_data.errors')}
                      secondary={t('demo_data.errors_secondary')}
                    />
                    <ListItemSecondaryAction>
                      <Switch
                        color="primary"
                        checked={errorMode && demoMode}
                        onChange={toggleErrorMode}
                        edge="end"
                        disabled={!demoMode}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Collapse
                    in={demoMode && errorMode}
                    timeout="auto"
                    unmountOnExit
                  >
                    <ListItem disabled={!demoMode}>
                      <ListItemIcon>
                        <AtlasIcon type="NetworkLost" />
                      </ListItemIcon>
                      <ListItemText
                        primary={t('demo_data.http_status.base')}
                        secondary={t('demo_data.http_status.secondary')}
                      />
                      <ListItemSecondaryAction>
                        <Select
                          value={httpStatusCode}
                          onChange={e =>
                            setHttpStatusCode(e.target.value as string)
                          }
                        >
                          {httpStatusCodes.map(code => (
                            <MenuItem key={code.value} value={code.value}>
                              {code.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </Collapse>
                  <ListItem>
                    <ListItemIcon>
                      <AtlasIcon type="Management" />
                    </ListItemIcon>
                    <ListItemText
                      primary="Show static navigation"
                      secondary="Instead of loading a navigation, use demo data (all routes) in the menu."
                    />
                    <ListItemSecondaryAction>
                      <Switch
                        color="primary"
                        checked={disableNewNavigation}
                        onChange={toggleDisableNewNavigation}
                        edge="end"
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>

                <List
                  dense
                  subheader={
                    <ListSubheader>Development settings</ListSubheader>
                  }
                >
                  {canSimulateSubportals && (
                    <SimulateSubPortalSetting navDrawerOpen={isOpen} />
                  )}

                  <ListItem>
                    <ListItemIcon>
                      <AtlasIcon type="DefaultImage" />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <MUILink component={Link} to="/theme">
                          Component overview
                        </MUILink>
                      }
                      secondary="Contains a list of all available icons"
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <AtlasIcon type="Silent" />
                    </ListItemIcon>
                    <ListItemText
                      primary="Show translation keys"
                      secondary="Displays the key instead of the translation value"
                    />
                    <ListItemSecondaryAction>
                      <Switch
                        color="primary"
                        checked={i18n.language === 'cimode'}
                        onChange={() => {
                          if (i18n.language === 'cimode') {
                            i18n.changeLanguage('en-US');
                          } else {
                            i18n.changeLanguage('cimode');
                          }
                        }}
                        edge="end"
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </TabPanel>
            )}
          </>
          <TabPanel value={tab} index={1} dir={theme.direction}>
            <Suspense fallback={<>Loading roles...</>}>
              <RoleViewer />
            </Suspense>
          </TabPanel>
        </SwipeableViews>
      </div>
    </Drawer>
  );
};

export default observer(SettingsDrawer);
