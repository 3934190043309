import MessageOverlay from 'components/MessageOverlay';
import { Chart } from 'highcharts';
import { observer } from 'mobx-react-lite';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IDashboardComponent } from 'views/Dashboard/dashboardTypes';
import { useDataSource, useDataSourceData } from 'views/Dashboard/DataSources';
import { IDataProperty } from 'views/Dashboard/DataSources/dataPropTypes';
import Widget from 'views/Dashboard/Widget';
import DownloadHighchartDataMenu from '../../DownloadHighchartDataMenu';
import GroupedBarChart from './GroupedBarChart';
import { IGroupedBarChartSettings } from './groupedBarChartConfig';

export interface IGroupedBarChartViewModel {
  name: IDataProperty;
  yValue: IDataProperty;
  xValue: IDataProperty;
}

const GroupedBarChartWidget: IDashboardComponent<IGroupedBarChartSettings> = ({
  component,
}) => {
  const { t } = useTranslation('dashboard');
  const { settings } = component;

  const dataSource = useDataSource(settings.dataSource);

  const dataProps = dataSource.getDataProps<IGroupedBarChartViewModel>(
    getProp => ({
      name: getProp(settings.nameProp),
      yValue: getProp(settings.yAxis.prop),
      xValue: getProp(settings.xAxis.prop),
    })
  );

  const chartRef = useRef<Chart>();
  const { data, noContent, error } = useDataSourceData(dataSource);

  return (
    <Widget isLoading={!dataSource.isInitialized || dataSource.isLoading}>
      <Widget.Header
        buttons={
          <DownloadHighchartDataMenu
            chartRef={chartRef}
            id={component.componentId}
          />
        }
      >
        {settings.title}
      </Widget.Header>

      <Widget.Content
        skeleton={
          !dataSource.isInitialized ||
          (!dataSource.lastReceivedData && dataSource.isLoading)
        }
      >
        {data && (
          <GroupedBarChart
            data={data}
            settings={settings}
            viewModel={dataProps}
            chartRef={chart => (chartRef.current = chart)}
          />
        )}

        {noContent && <MessageOverlay message={t('no_data')} />}
        {error && <MessageOverlay message={error} />}
      </Widget.Content>
    </Widget>
  );
};

export default observer(GroupedBarChartWidget);
