import { IntPolicyDto } from 'generated';
import { useStores } from 'store';

export function usePolicy(policyId: string): IntPolicyDto | undefined {
  const {
    policyStore: { policies = [] },
  } = useStores();

  return policies.find(p => p.policyId === policyId);
}
