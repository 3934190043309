import { snackbar } from 'components/Snackbar';
import { Chart } from 'highcharts';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { downLoadWidgetData } from 'utils';

export function useDownloadChartExcel(
  chartRef: React.MutableRefObject<Chart | undefined>
): {
  downloadXLSX: () => void;
  downloadCSV: () => void;
} {
  const { t } = useTranslation('dashboard');

  const downloadXLSX = () => {
    if (!chartRef.current) {
      snackbar(t('error.export.xlsx'), { variant: 'error' });
    } else {
      const rows: Array<any> = chartRef.current.getDataRows(),
        fileName: string = chartRef.current.getFilename() + '.xlsx',
        XLSXData: string[] = [],
        headers: string = rows ? rows[0] : [],
        cellWidth: { wch: number }[] = [];

      for (let i = 1; i < rows.length; i++) {
        // Removing useless data
        const array = rows[i].splice(0, rows[i].length);
        XLSXData.push({ ...array });
      }

      if (Array.isArray(headers)) {
        headers.forEach(() => cellWidth.push({ wch: 22 }));
      }

      XLSXData.unshift(headers);

      // Creating xlsx file and fills it with data
      downLoadWidgetData(XLSXData, cellWidth, fileName);
    }
  };

  return {
    downloadXLSX,
    downloadCSV: () => chartRef.current?.downloadCSV(),
  };
}
