import { addMinutes, isMatch, isValid, parse, parseISO } from 'date-fns';
import { isNil } from 'lodash';
import { getDateFormatString, IDateOptions } from './formatting';

// If updating this also update the clone in cypress\support\utils.ts
export type ParsableDate = string | number | Date;

export function tryParseDate(
  input: ParsableDate | undefined | null,
  adjustFromUtcToLocal = false
): Date | null {
  try {
    if (isNil(input)) {
      return null;
    }
    const res = parseDate(input, adjustFromUtcToLocal);
    if (isNaN(res.getTime())) {
      return null;
    }
    return res;
  } catch (ex) {
    return null;
  }
}

export function parseDate(
  input: ParsableDate,
  adjustFromUtcToLocal = false
): Date {
  let parsedDate;

  switch (typeof input) {
    case 'string':
      parsedDate = parseISO(input);
      break;
    case 'number':
      parsedDate = new Date(input);
      break;
    default:
      parsedDate = input;
  }

  return adjustFromUtcToLocal
    ? addMinutes(parsedDate, -parsedDate.getTimezoneOffset())
    : parsedDate;
}

export function parseDateIfValid(
  value: string | undefined,
  options?: IDateOptions
): Date | undefined {
  if (!value) {
    return undefined;
  }
  const hasCorrectFormat = isMatch(value, getDateFormatString(options)); // Could this be more forgiving? Maybe, but fine for now
  const parsedValue = parse(value, getDateFormatString(options), new Date());
  return hasCorrectFormat && isValid(parsedValue) ? parsedValue : undefined;
}
