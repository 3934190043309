import { SubPortalType } from 'generated';
import i18n from 'i18next';
import { Column } from 'react-table';
import { FilterOption } from '../Helpers/filterOption';
import { getOptionFilter } from '../Helpers/getOptionFilter';

export function subPortalTypes<T>(options: {
  id: string;
  accessor: (row: T) => SubPortalType;
  header?: string;
  defaultShow?: boolean;
  filterable?: boolean;
  sortable?: boolean;
}): Column<T> {
  const {
    id,
    header,
    accessor,
    defaultShow: show = true,
    filterable,
    sortable,
  } = options;

  const filterOptions: FilterOption[] = [
    { label: i18n.t('common:all'), value: '' },
    {
      label: i18n.t('subPortal:enum.type.0'),
      value: SubPortalType[0],
    },
    {
      label: i18n.t('subPortal:enum.type.1'),
      value: SubPortalType[1],
    },
    {
      label: i18n.t('subPortal:enum.type.2'),
      value: SubPortalType[2],
    },
  ];

  return {
    id,
    Header: header,
    accessor,
    resizable: false,
    maxWidth: 200,
    filterable,
    sortable,
    show,
    Cell: row => {
      return row.value;
    },
    Filter: getOptionFilter(filterOptions),
  };
}
