import { IntConfigurationType } from 'generated';
import { rnd } from 'utils';

class ConnecitivityConfigDemoData {
  getConnectivityConfigs = (customerId: string) => ({
    status: 200,
    data: [
      {
        id: rnd.guid(),
        customerId,
        type: IntConfigurationType.GIC,
        configurationIdentifier: rnd.item('0600057', '0600058', '0600059'),
        keyVaultCredentials: {
          username: rnd.userName(),
          password: rnd.password(),
          url: rnd.item(
            'https://www.teliacompany.com',
            'https://www.teliacompany.se',
            'https://www.teliacompany.fi'
          ),
        },
      },
    ],
  });

  updateConnectivityConfigs = () => ({ status: 200 });
}

export const connecitivityConfigDemoData = new ConnecitivityConfigDemoData();
