import { Warning } from '@mui/icons-material';
import { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';
import { AtlasFlagIconType } from 'shared/types/atlas-flag-icon';
import { mapToSvgIcon } from 'utils';
import { ReactComponent as FlagAC } from './svg/flags/AC.svg';
import { ReactComponent as FlagAD } from './svg/flags/AD.svg';
import { ReactComponent as FlagAE } from './svg/flags/AE.svg';
import { ReactComponent as FlagAF } from './svg/flags/AF.svg';
import { ReactComponent as FlagAG } from './svg/flags/AG.svg';
import { ReactComponent as FlagAI } from './svg/flags/AI.svg';
import { ReactComponent as FlagAL } from './svg/flags/AL.svg';
import { ReactComponent as FlagAM } from './svg/flags/AM.svg';
import { ReactComponent as FlagAO } from './svg/flags/AO.svg';
import { ReactComponent as FlagAQ } from './svg/flags/AQ.svg';
import { ReactComponent as FlagAR } from './svg/flags/AR.svg';
import { ReactComponent as FlagAS } from './svg/flags/AS.svg';
import { ReactComponent as FlagAT } from './svg/flags/AT.svg';
import { ReactComponent as FlagAU } from './svg/flags/AU.svg';
import { ReactComponent as FlagAW } from './svg/flags/AW.svg';
import { ReactComponent as FlagAX } from './svg/flags/AX.svg';
import { ReactComponent as FlagAZ } from './svg/flags/AZ.svg';
import { ReactComponent as FlagBA } from './svg/flags/BA.svg';
import { ReactComponent as FlagBB } from './svg/flags/BB.svg';
import { ReactComponent as FlagBD } from './svg/flags/BD.svg';
import { ReactComponent as FlagBE } from './svg/flags/BE.svg';
import { ReactComponent as FlagBF } from './svg/flags/BF.svg';
import { ReactComponent as FlagBG } from './svg/flags/BG.svg';
import { ReactComponent as FlagBH } from './svg/flags/BH.svg';
import { ReactComponent as FlagBI } from './svg/flags/BI.svg';
import { ReactComponent as FlagBJ } from './svg/flags/BJ.svg';
import { ReactComponent as FlagBL } from './svg/flags/BL.svg';
import { ReactComponent as FlagBM } from './svg/flags/BM.svg';
import { ReactComponent as FlagBN } from './svg/flags/BN.svg';
import { ReactComponent as FlagBO } from './svg/flags/BO.svg';
import { ReactComponent as FlagBQ } from './svg/flags/BQ.svg';
import { ReactComponent as FlagBR } from './svg/flags/BR.svg';
import { ReactComponent as FlagBS } from './svg/flags/BS.svg';
import { ReactComponent as FlagBT } from './svg/flags/BT.svg';
import { ReactComponent as FlagBV } from './svg/flags/BV.svg';
import { ReactComponent as FlagBW } from './svg/flags/BW.svg';
import { ReactComponent as FlagBY } from './svg/flags/BY.svg';
import { ReactComponent as FlagBZ } from './svg/flags/BZ.svg';
import { ReactComponent as FlagCA } from './svg/flags/CA.svg';
import { ReactComponent as FlagCC } from './svg/flags/CC.svg';
import { ReactComponent as FlagCD } from './svg/flags/CD.svg';
import { ReactComponent as FlagCF } from './svg/flags/CF.svg';
import { ReactComponent as FlagCG } from './svg/flags/CG.svg';
import { ReactComponent as FlagCH } from './svg/flags/CH.svg';
import { ReactComponent as FlagCI } from './svg/flags/CI.svg';
import { ReactComponent as FlagCK } from './svg/flags/CK.svg';
import { ReactComponent as FlagCL } from './svg/flags/CL.svg';
import { ReactComponent as FlagCM } from './svg/flags/CM.svg';
import { ReactComponent as FlagCN } from './svg/flags/CN.svg';
import { ReactComponent as FlagCO } from './svg/flags/CO.svg';
import { ReactComponent as FlagCR } from './svg/flags/CR.svg';
import { ReactComponent as FlagCU } from './svg/flags/CU.svg';
import { ReactComponent as FlagCV } from './svg/flags/CV.svg';
import { ReactComponent as FlagCW } from './svg/flags/CW.svg';
import { ReactComponent as FlagCX } from './svg/flags/CX.svg';
import { ReactComponent as FlagCY } from './svg/flags/CY.svg';
import { ReactComponent as FlagCZ } from './svg/flags/CZ.svg';
import { ReactComponent as FlagDE } from './svg/flags/DE.svg';
import { ReactComponent as FlagDJ } from './svg/flags/DJ.svg';
import { ReactComponent as FlagDK } from './svg/flags/DK.svg';
import { ReactComponent as FlagDM } from './svg/flags/DM.svg';
import { ReactComponent as FlagDO } from './svg/flags/DO.svg';
import { ReactComponent as FlagDZ } from './svg/flags/DZ.svg';
import { ReactComponent as FlagEC } from './svg/flags/EC.svg';
import { ReactComponent as FlagEE } from './svg/flags/EE.svg';
import { ReactComponent as FlagEG } from './svg/flags/EG.svg';
import { ReactComponent as FlagEH } from './svg/flags/EH.svg';
import { ReactComponent as FlagER } from './svg/flags/ER.svg';
import { ReactComponent as FlagES } from './svg/flags/ES.svg';
import { ReactComponent as FlagET } from './svg/flags/ET.svg';
import { ReactComponent as FlagEU } from './svg/flags/EU.svg';
import { ReactComponent as FlagFI } from './svg/flags/FI.svg';
import { ReactComponent as FlagFJ } from './svg/flags/FJ.svg';
import { ReactComponent as FlagFK } from './svg/flags/FK.svg';
import { ReactComponent as FlagFM } from './svg/flags/FM.svg';
import { ReactComponent as FlagFO } from './svg/flags/FO.svg';
import { ReactComponent as FlagFR } from './svg/flags/FR.svg';
import { ReactComponent as FlagGA } from './svg/flags/GA.svg';
import { ReactComponent as FlagGB } from './svg/flags/GB.svg';
import { ReactComponent as FlagGD } from './svg/flags/GD.svg';
import { ReactComponent as FlagGE } from './svg/flags/GE.svg';
import { ReactComponent as FlagGF } from './svg/flags/GF.svg';
import { ReactComponent as FlagGG } from './svg/flags/GG.svg';
import { ReactComponent as FlagGH } from './svg/flags/GH.svg';
import { ReactComponent as FlagGI } from './svg/flags/GI.svg';
import { ReactComponent as FlagGL } from './svg/flags/GL.svg';
import { ReactComponent as FlagGM } from './svg/flags/GM.svg';
import { ReactComponent as FlagGN } from './svg/flags/GN.svg';
import { ReactComponent as FlagGP } from './svg/flags/GP.svg';
import { ReactComponent as FlagGQ } from './svg/flags/GQ.svg';
import { ReactComponent as FlagGR } from './svg/flags/GR.svg';
import { ReactComponent as FlagGS } from './svg/flags/GS.svg';
import { ReactComponent as FlagGT } from './svg/flags/GT.svg';
import { ReactComponent as FlagGU } from './svg/flags/GU.svg';
import { ReactComponent as FlagGW } from './svg/flags/GW.svg';
import { ReactComponent as FlagGY } from './svg/flags/GY.svg';
import { ReactComponent as FlagHK } from './svg/flags/HK.svg';
import { ReactComponent as FlagHM } from './svg/flags/HM.svg';
import { ReactComponent as FlagHN } from './svg/flags/HN.svg';
import { ReactComponent as FlagHR } from './svg/flags/HR.svg';
import { ReactComponent as FlagHT } from './svg/flags/HT.svg';
import { ReactComponent as FlagHU } from './svg/flags/HU.svg';
import { ReactComponent as FlagIC } from './svg/flags/IC.svg';
import { ReactComponent as FlagID } from './svg/flags/ID.svg';
import { ReactComponent as FlagIE } from './svg/flags/IE.svg';
import { ReactComponent as FlagIL } from './svg/flags/IL.svg';
import { ReactComponent as FlagIM } from './svg/flags/IM.svg';
import { ReactComponent as FlagIN } from './svg/flags/IN.svg';
import { ReactComponent as FlagIO } from './svg/flags/IO.svg';
import { ReactComponent as FlagIQ } from './svg/flags/IQ.svg';
import { ReactComponent as FlagIR } from './svg/flags/IR.svg';
import { ReactComponent as FlagIS } from './svg/flags/IS.svg';
import { ReactComponent as FlagIT } from './svg/flags/IT.svg';
import { ReactComponent as FlagJE } from './svg/flags/JE.svg';
import { ReactComponent as FlagJM } from './svg/flags/JM.svg';
import { ReactComponent as FlagJO } from './svg/flags/JO.svg';
import { ReactComponent as FlagJP } from './svg/flags/JP.svg';
import { ReactComponent as FlagKE } from './svg/flags/KE.svg';
import { ReactComponent as FlagKG } from './svg/flags/KG.svg';
import { ReactComponent as FlagKH } from './svg/flags/KH.svg';
import { ReactComponent as FlagKI } from './svg/flags/KI.svg';
import { ReactComponent as FlagKM } from './svg/flags/KM.svg';
import { ReactComponent as FlagKN } from './svg/flags/KN.svg';
import { ReactComponent as FlagKP } from './svg/flags/KP.svg';
import { ReactComponent as FlagKR } from './svg/flags/KR.svg';
import { ReactComponent as FlagKW } from './svg/flags/KW.svg';
import { ReactComponent as FlagKY } from './svg/flags/KY.svg';
import { ReactComponent as FlagKZ } from './svg/flags/KZ.svg';
import { ReactComponent as FlagLA } from './svg/flags/LA.svg';
import { ReactComponent as FlagLB } from './svg/flags/LB.svg';
import { ReactComponent as FlagLC } from './svg/flags/LC.svg';
import { ReactComponent as FlagLI } from './svg/flags/LI.svg';
import { ReactComponent as FlagLK } from './svg/flags/LK.svg';
import { ReactComponent as FlagLR } from './svg/flags/LR.svg';
import { ReactComponent as FlagLS } from './svg/flags/LS.svg';
import { ReactComponent as FlagLT } from './svg/flags/LT.svg';
import { ReactComponent as FlagLU } from './svg/flags/LU.svg';
import { ReactComponent as FlagLV } from './svg/flags/LV.svg';
import { ReactComponent as FlagLY } from './svg/flags/LY.svg';
import { ReactComponent as FlagMA } from './svg/flags/MA.svg';
import { ReactComponent as FlagMC } from './svg/flags/MC.svg';
import { ReactComponent as FlagMD } from './svg/flags/MD.svg';
import { ReactComponent as FlagME } from './svg/flags/ME.svg';
import { ReactComponent as FlagMF } from './svg/flags/MF.svg';
import { ReactComponent as FlagMG } from './svg/flags/MG.svg';
import { ReactComponent as FlagMH } from './svg/flags/MH.svg';
import { ReactComponent as FlagMK } from './svg/flags/MK.svg';
import { ReactComponent as FlagML } from './svg/flags/ML.svg';
import { ReactComponent as FlagMM } from './svg/flags/MM.svg';
import { ReactComponent as FlagMN } from './svg/flags/MN.svg';
import { ReactComponent as FlagMO } from './svg/flags/MO.svg';
import { ReactComponent as FlagMP } from './svg/flags/MP.svg';
import { ReactComponent as FlagMQ } from './svg/flags/MQ.svg';
import { ReactComponent as FlagMR } from './svg/flags/MR.svg';
import { ReactComponent as FlagMS } from './svg/flags/MS.svg';
import { ReactComponent as FlagMT } from './svg/flags/MT.svg';
import { ReactComponent as FlagMU } from './svg/flags/MU.svg';
import { ReactComponent as FlagMV } from './svg/flags/MV.svg';
import { ReactComponent as FlagMW } from './svg/flags/MW.svg';
import { ReactComponent as FlagMX } from './svg/flags/MX.svg';
import { ReactComponent as FlagMY } from './svg/flags/MY.svg';
import { ReactComponent as FlagMZ } from './svg/flags/MZ.svg';
import { ReactComponent as FlagNA } from './svg/flags/NA.svg';
import { ReactComponent as FlagNC } from './svg/flags/NC.svg';
import { ReactComponent as FlagNE } from './svg/flags/NE.svg';
import { ReactComponent as FlagNF } from './svg/flags/NF.svg';
import { ReactComponent as FlagNG } from './svg/flags/NG.svg';
import { ReactComponent as FlagNI } from './svg/flags/NI.svg';
import { ReactComponent as FlagNL } from './svg/flags/NL.svg';
import { ReactComponent as FlagNO } from './svg/flags/NO.svg';
import { ReactComponent as FlagNP } from './svg/flags/NP.svg';
import { ReactComponent as FlagNR } from './svg/flags/NR.svg';
import { ReactComponent as FlagNU } from './svg/flags/NU.svg';
import { ReactComponent as FlagNZ } from './svg/flags/NZ.svg';
import { ReactComponent as FlagOM } from './svg/flags/OM.svg';
import { ReactComponent as FlagPA } from './svg/flags/PA.svg';
import { ReactComponent as FlagPE } from './svg/flags/PE.svg';
import { ReactComponent as FlagPF } from './svg/flags/PF.svg';
import { ReactComponent as FlagPG } from './svg/flags/PG.svg';
import { ReactComponent as FlagPH } from './svg/flags/PH.svg';
import { ReactComponent as FlagPK } from './svg/flags/PK.svg';
import { ReactComponent as FlagPL } from './svg/flags/PL.svg';
import { ReactComponent as FlagPM } from './svg/flags/PM.svg';
import { ReactComponent as FlagPN } from './svg/flags/PN.svg';
import { ReactComponent as FlagPR } from './svg/flags/PR.svg';
import { ReactComponent as FlagPS } from './svg/flags/PS.svg';
import { ReactComponent as FlagPT } from './svg/flags/PT.svg';
import { ReactComponent as FlagPW } from './svg/flags/PW.svg';
import { ReactComponent as FlagPY } from './svg/flags/PY.svg';
import { ReactComponent as FlagQA } from './svg/flags/QA.svg';
import { ReactComponent as FlagRE } from './svg/flags/RE.svg';
import { ReactComponent as FlagRO } from './svg/flags/RO.svg';
import { ReactComponent as FlagRS } from './svg/flags/RS.svg';
import { ReactComponent as FlagRU } from './svg/flags/RU.svg';
import { ReactComponent as FlagRW } from './svg/flags/RW.svg';
import { ReactComponent as FlagSA } from './svg/flags/SA.svg';
import { ReactComponent as FlagSB } from './svg/flags/SB.svg';
import { ReactComponent as FlagSC } from './svg/flags/SC.svg';
import { ReactComponent as FlagSD } from './svg/flags/SD.svg';
import { ReactComponent as FlagSE } from './svg/flags/SE.svg';
import { ReactComponent as FlagSG } from './svg/flags/SG.svg';
import { ReactComponent as FlagSH } from './svg/flags/SH.svg';
import { ReactComponent as FlagSI } from './svg/flags/SI.svg';
import { ReactComponent as FlagSJ } from './svg/flags/SJ.svg';
import { ReactComponent as FlagSK } from './svg/flags/SK.svg';
import { ReactComponent as FlagSL } from './svg/flags/SL.svg';
import { ReactComponent as FlagSM } from './svg/flags/SM.svg';
import { ReactComponent as FlagSN } from './svg/flags/SN.svg';
import { ReactComponent as FlagSO } from './svg/flags/SO.svg';
import { ReactComponent as FlagSR } from './svg/flags/SR.svg';
import { ReactComponent as FlagSS } from './svg/flags/SS.svg';
import { ReactComponent as FlagST } from './svg/flags/ST.svg';
import { ReactComponent as FlagSV } from './svg/flags/SV.svg';
import { ReactComponent as FlagSX } from './svg/flags/SX.svg';
import { ReactComponent as FlagSY } from './svg/flags/SY.svg';
import { ReactComponent as FlagSZ } from './svg/flags/SZ.svg';
import { ReactComponent as FlagTA } from './svg/flags/TA.svg';
import { ReactComponent as FlagTC } from './svg/flags/TC.svg';
import { ReactComponent as FlagTD } from './svg/flags/TD.svg';
import { ReactComponent as FlagTF } from './svg/flags/TF.svg';
import { ReactComponent as FlagTG } from './svg/flags/TG.svg';
import { ReactComponent as FlagTH } from './svg/flags/TH.svg';
import { ReactComponent as FlagTJ } from './svg/flags/TJ.svg';
import { ReactComponent as FlagTK } from './svg/flags/TK.svg';
import { ReactComponent as FlagTL } from './svg/flags/TL.svg';
import { ReactComponent as FlagTM } from './svg/flags/TM.svg';
import { ReactComponent as FlagTN } from './svg/flags/TN.svg';
import { ReactComponent as FlagTO } from './svg/flags/TO.svg';
import { ReactComponent as FlagTR } from './svg/flags/TR.svg';
import { ReactComponent as FlagTT } from './svg/flags/TT.svg';
import { ReactComponent as FlagTV } from './svg/flags/TV.svg';
import { ReactComponent as FlagTW } from './svg/flags/TW.svg';
import { ReactComponent as FlagTZ } from './svg/flags/TZ.svg';
import { ReactComponent as FlagUA } from './svg/flags/UA.svg';
import { ReactComponent as FlagUG } from './svg/flags/UG.svg';
import { ReactComponent as FlagUM } from './svg/flags/UM.svg';
import { ReactComponent as FlagUS } from './svg/flags/US.svg';
import { ReactComponent as FlagUY } from './svg/flags/UY.svg';
import { ReactComponent as FlagUZ } from './svg/flags/UZ.svg';
import { ReactComponent as FlagVA } from './svg/flags/VA.svg';
import { ReactComponent as FlagVC } from './svg/flags/VC.svg';
import { ReactComponent as FlagVE } from './svg/flags/VE.svg';
import { ReactComponent as FlagVG } from './svg/flags/VG.svg';
import { ReactComponent as FlagVI } from './svg/flags/VI.svg';
import { ReactComponent as FlagVN } from './svg/flags/VN.svg';
import { ReactComponent as FlagVU } from './svg/flags/VU.svg';
import { ReactComponent as FlagWF } from './svg/flags/WF.svg';
import { ReactComponent as FlagWS } from './svg/flags/WS.svg';
import { ReactComponent as FlagXK } from './svg/flags/XK.svg';
import { ReactComponent as FlagYE } from './svg/flags/YE.svg';
import { ReactComponent as FlagYT } from './svg/flags/YT.svg';
import { ReactComponent as FlagZA } from './svg/flags/ZA.svg';
import { ReactComponent as FlagZM } from './svg/flags/ZM.svg';
import { ReactComponent as FlagZW } from './svg/flags/ZW.svg';
import {
  applicationViewbox,
  customFlagViewbox,
  flagViewbox,
  hubViewbox,
  statusWarningViewbox,
} from './variables';

const Placeholder = Warning;

const TeliaFlagIcons: Record<
  AtlasFlagIconType,
  React.ComponentType<SvgIconProps>
> = {
  FlagAC: mapToSvgIcon(FlagAC, customFlagViewbox),
  FlagAD: mapToSvgIcon(FlagAD, customFlagViewbox),
  FlagAE: mapToSvgIcon(FlagAE, customFlagViewbox),
  FlagAF: mapToSvgIcon(FlagAF, customFlagViewbox),
  FlagAG: mapToSvgIcon(FlagAG, customFlagViewbox),
  FlagAI: mapToSvgIcon(FlagAI, customFlagViewbox),
  FlagAL: mapToSvgIcon(FlagAL, hubViewbox),
  FlagAM: mapToSvgIcon(FlagAM, customFlagViewbox),
  FlagAO: mapToSvgIcon(FlagAO, customFlagViewbox),
  FlagAQ: mapToSvgIcon(FlagAQ, customFlagViewbox),
  FlagAR: mapToSvgIcon(FlagAR, customFlagViewbox),
  FlagAS: mapToSvgIcon(FlagAS, customFlagViewbox),
  FlagAT: mapToSvgIcon(FlagAT, customFlagViewbox),
  FlagAU: mapToSvgIcon(FlagAU, customFlagViewbox),
  FlagAW: mapToSvgIcon(FlagAW, '0 0 350 250'),
  FlagAX: mapToSvgIcon(FlagAX, customFlagViewbox),
  FlagAZ: mapToSvgIcon(FlagAZ, customFlagViewbox),
  FlagBA: mapToSvgIcon(FlagBA, customFlagViewbox),
  FlagBB: mapToSvgIcon(FlagBB, customFlagViewbox),
  FlagBD: mapToSvgIcon(FlagBD, flagViewbox),
  FlagBE: mapToSvgIcon(FlagBE, customFlagViewbox),
  FlagBF: mapToSvgIcon(FlagBF, customFlagViewbox),
  FlagBG: mapToSvgIcon(FlagBG, flagViewbox),
  FlagBH: mapToSvgIcon(FlagBH, customFlagViewbox),
  FlagBI: mapToSvgIcon(FlagBI, customFlagViewbox),
  FlagBJ: mapToSvgIcon(FlagBJ, flagViewbox),
  FlagBL: mapToSvgIcon(FlagBL, customFlagViewbox),
  FlagBM: mapToSvgIcon(FlagBM, customFlagViewbox),
  FlagBN: mapToSvgIcon(FlagBN, customFlagViewbox),
  FlagBO: mapToSvgIcon(FlagBO, customFlagViewbox),
  FlagBQ: mapToSvgIcon(FlagBQ, customFlagViewbox),
  FlagBR: mapToSvgIcon(FlagBR, customFlagViewbox),
  FlagBS: mapToSvgIcon(FlagBS, flagViewbox),
  FlagBT: mapToSvgIcon(FlagBT, customFlagViewbox),
  FlagBV: mapToSvgIcon(FlagBV, customFlagViewbox),
  FlagBW: mapToSvgIcon(FlagBW, customFlagViewbox),
  FlagBY: mapToSvgIcon(FlagBY, customFlagViewbox),
  FlagBZ: mapToSvgIcon(FlagBZ, customFlagViewbox),
  FlagCA: mapToSvgIcon(FlagCA, customFlagViewbox),
  FlagCC: mapToSvgIcon(FlagCC, customFlagViewbox),
  FlagCD: mapToSvgIcon(FlagCD, customFlagViewbox),
  FlagCF: mapToSvgIcon(FlagCF, customFlagViewbox),
  FlagCG: mapToSvgIcon(FlagCG, flagViewbox),
  FlagCH: mapToSvgIcon(FlagCH, flagViewbox),
  FlagCI: mapToSvgIcon(FlagCI, flagViewbox),
  FlagCK: mapToSvgIcon(FlagCK, flagViewbox),
  FlagCL: mapToSvgIcon(FlagCL, customFlagViewbox),
  FlagCM: mapToSvgIcon(FlagCM, customFlagViewbox),
  FlagCN: mapToSvgIcon(FlagCN, customFlagViewbox),
  FlagCO: mapToSvgIcon(FlagCO, flagViewbox),
  FlagCR: mapToSvgIcon(FlagCR, flagViewbox),
  FlagCU: mapToSvgIcon(FlagCU, customFlagViewbox),
  FlagCV: mapToSvgIcon(FlagCV, customFlagViewbox),
  FlagCW: mapToSvgIcon(FlagCW, customFlagViewbox),
  FlagCX: mapToSvgIcon(FlagCX, customFlagViewbox),
  FlagCY: mapToSvgIcon(FlagCY, customFlagViewbox),
  FlagCZ: mapToSvgIcon(FlagCZ, flagViewbox),
  FlagDE: mapToSvgIcon(FlagDE, flagViewbox),
  FlagDJ: mapToSvgIcon(FlagDJ, customFlagViewbox),
  FlagDK: mapToSvgIcon(FlagDK, customFlagViewbox),
  FlagDM: mapToSvgIcon(FlagDM, customFlagViewbox),
  FlagDO: mapToSvgIcon(FlagDO, customFlagViewbox),
  FlagDZ: mapToSvgIcon(FlagDZ, customFlagViewbox),
  FlagEC: mapToSvgIcon(FlagEC, customFlagViewbox),
  FlagEE: mapToSvgIcon(FlagEE, customFlagViewbox),
  FlagEG: mapToSvgIcon(FlagEG, customFlagViewbox),
  FlagEH: mapToSvgIcon(FlagEH, customFlagViewbox),
  FlagER: mapToSvgIcon(FlagER, customFlagViewbox),
  FlagES: mapToSvgIcon(FlagES, '0 0 24 15'),
  FlagET: mapToSvgIcon(FlagET, statusWarningViewbox),
  FlagEU: mapToSvgIcon(FlagEU, customFlagViewbox),
  FlagFI: mapToSvgIcon(FlagFI, customFlagViewbox),
  FlagFJ: mapToSvgIcon(FlagFJ, customFlagViewbox),
  FlagFK: mapToSvgIcon(FlagFK, customFlagViewbox),
  FlagFM: mapToSvgIcon(FlagFM, customFlagViewbox),
  FlagFO: mapToSvgIcon(FlagFO, customFlagViewbox),
  FlagFR: mapToSvgIcon(FlagFR, flagViewbox),
  FlagGA: mapToSvgIcon(FlagGA, flagViewbox),
  FlagGB: mapToSvgIcon(FlagGB, flagViewbox),
  FlagGD: mapToSvgIcon(FlagGD, customFlagViewbox),
  FlagGE: mapToSvgIcon(FlagGE, '0 0 24 15'),
  FlagGF: mapToSvgIcon(FlagGF, applicationViewbox),
  FlagGG: mapToSvgIcon(FlagGG, customFlagViewbox),
  FlagGH: mapToSvgIcon(FlagGH, flagViewbox),
  FlagGI: mapToSvgIcon(FlagGI, customFlagViewbox),
  FlagGL: mapToSvgIcon(FlagGL, flagViewbox),
  FlagGM: mapToSvgIcon(FlagGM, flagViewbox),
  FlagGN: mapToSvgIcon(FlagGN, customFlagViewbox),
  FlagGP: mapToSvgIcon(FlagGP, flagViewbox),
  FlagGQ: mapToSvgIcon(FlagGQ, customFlagViewbox),
  FlagGR: mapToSvgIcon(FlagGR, customFlagViewbox),
  FlagGS: mapToSvgIcon(FlagGS, flagViewbox),
  FlagGT: mapToSvgIcon(FlagGT, customFlagViewbox),
  FlagGU: mapToSvgIcon(FlagGU, customFlagViewbox),
  FlagGW: mapToSvgIcon(FlagGW, flagViewbox),
  FlagGY: mapToSvgIcon(FlagGY, flagViewbox),
  FlagHK: mapToSvgIcon(FlagHK, statusWarningViewbox),
  FlagHM: mapToSvgIcon(FlagHM, customFlagViewbox),
  FlagHN: mapToSvgIcon(FlagHN, customFlagViewbox),
  FlagHR: mapToSvgIcon(FlagHR, customFlagViewbox),
  FlagHT: mapToSvgIcon(FlagHT, customFlagViewbox),
  FlagHU: mapToSvgIcon(FlagHU, flagViewbox),
  FlagIC: mapToSvgIcon(FlagIC, customFlagViewbox),
  FlagID: mapToSvgIcon(FlagID, customFlagViewbox),
  FlagIE: mapToSvgIcon(FlagIE, flagViewbox),
  FlagIL: mapToSvgIcon(FlagIL, customFlagViewbox),
  FlagIM: mapToSvgIcon(FlagIM, customFlagViewbox),
  FlagIN: mapToSvgIcon(FlagIN, customFlagViewbox),
  FlagIO: mapToSvgIcon(FlagIO, customFlagViewbox),
  FlagIQ: mapToSvgIcon(FlagIQ, customFlagViewbox),
  FlagIR: mapToSvgIcon(FlagIR, customFlagViewbox),
  FlagIS: mapToSvgIcon(FlagIS, flagViewbox),
  FlagIT: mapToSvgIcon(FlagIT, flagViewbox),
  FlagJE: mapToSvgIcon(FlagJE, customFlagViewbox),
  FlagJM: mapToSvgIcon(FlagJM, flagViewbox),
  FlagJO: mapToSvgIcon(FlagJO, flagViewbox),
  FlagJP: mapToSvgIcon(FlagJP, flagViewbox),
  FlagKE: mapToSvgIcon(FlagKE, flagViewbox),
  FlagKG: mapToSvgIcon(FlagKG, customFlagViewbox),
  FlagKH: mapToSvgIcon(FlagKH, flagViewbox),
  FlagKI: mapToSvgIcon(FlagKI, customFlagViewbox),
  FlagKM: mapToSvgIcon(FlagKM, flagViewbox),
  FlagKN: mapToSvgIcon(FlagKN, customFlagViewbox),
  FlagKP: mapToSvgIcon(FlagKP, flagViewbox),
  FlagKR: mapToSvgIcon(FlagKR, flagViewbox),
  FlagKW: mapToSvgIcon(FlagKW, flagViewbox),
  FlagKY: mapToSvgIcon(FlagKY, flagViewbox),
  FlagKZ: mapToSvgIcon(FlagKZ, customFlagViewbox),
  FlagLA: mapToSvgIcon(FlagLA, customFlagViewbox),
  FlagLB: mapToSvgIcon(FlagLB, customFlagViewbox),
  FlagLC: mapToSvgIcon(FlagLC, customFlagViewbox),
  FlagLI: mapToSvgIcon(FlagLI, customFlagViewbox),
  FlagLK: mapToSvgIcon(FlagLK, customFlagViewbox),
  FlagLR: mapToSvgIcon(FlagLR, customFlagViewbox),
  FlagLS: mapToSvgIcon(FlagLS, customFlagViewbox),
  FlagLT: mapToSvgIcon(FlagLT, flagViewbox),
  FlagLU: mapToSvgIcon(FlagLU, flagViewbox),
  FlagLV: mapToSvgIcon(FlagLV, customFlagViewbox),
  FlagLY: mapToSvgIcon(FlagLY, flagViewbox),
  FlagMA: mapToSvgIcon(FlagMA, flagViewbox),
  FlagMC: mapToSvgIcon(FlagMC, customFlagViewbox),
  FlagMD: mapToSvgIcon(FlagMD, customFlagViewbox),
  FlagME: mapToSvgIcon(FlagME, customFlagViewbox),
  FlagMF: mapToSvgIcon(FlagMF, applicationViewbox),
  FlagMG: mapToSvgIcon(FlagMG, flagViewbox),
  FlagMH: mapToSvgIcon(FlagMH, customFlagViewbox),
  FlagMK: mapToSvgIcon(FlagMK, flagViewbox),
  FlagML: mapToSvgIcon(FlagML, flagViewbox),
  FlagMM: mapToSvgIcon(FlagMM, flagViewbox),
  FlagMN: mapToSvgIcon(FlagMN, customFlagViewbox),
  FlagMO: mapToSvgIcon(FlagMO, flagViewbox),
  FlagMP: mapToSvgIcon(FlagMP, customFlagViewbox),
  FlagMQ: mapToSvgIcon(FlagMQ, customFlagViewbox),
  FlagMR: mapToSvgIcon(FlagMR, flagViewbox),
  FlagMS: mapToSvgIcon(FlagMS, customFlagViewbox),
  FlagMT: mapToSvgIcon(FlagMT, customFlagViewbox),
  FlagMU: mapToSvgIcon(FlagMU, flagViewbox),
  FlagMV: mapToSvgIcon(FlagMV, flagViewbox),
  FlagMW: mapToSvgIcon(FlagMW, customFlagViewbox),
  FlagMX: mapToSvgIcon(FlagMX, customFlagViewbox),
  FlagMY: mapToSvgIcon(FlagMY, customFlagViewbox),
  FlagMZ: mapToSvgIcon(FlagMZ, flagViewbox),
  FlagNA: mapToSvgIcon(FlagNA, flagViewbox),
  FlagNC: mapToSvgIcon(FlagNC, customFlagViewbox),
  FlagNE: mapToSvgIcon(FlagNE, flagViewbox),
  FlagNF: mapToSvgIcon(FlagNF, flagViewbox),
  FlagNG: mapToSvgIcon(FlagNG, flagViewbox),
  FlagNI: mapToSvgIcon(FlagNI, flagViewbox),
  FlagNL: mapToSvgIcon(FlagNL, flagViewbox),
  FlagNO: mapToSvgIcon(FlagNO, flagViewbox),
  FlagNP: mapToSvgIcon(FlagNP, flagViewbox),
  FlagNR: mapToSvgIcon(FlagNR, flagViewbox),
  FlagNU: mapToSvgIcon(FlagNU, flagViewbox),
  FlagNZ: mapToSvgIcon(FlagNZ, flagViewbox),
  FlagOM: mapToSvgIcon(FlagOM, customFlagViewbox),
  FlagPA: mapToSvgIcon(FlagPA, flagViewbox),
  FlagPE: mapToSvgIcon(FlagPE, flagViewbox),
  FlagPF: mapToSvgIcon(FlagPF, flagViewbox),
  FlagPG: mapToSvgIcon(FlagPG, flagViewbox),
  FlagPH: mapToSvgIcon(FlagPH, flagViewbox),
  FlagPK: mapToSvgIcon(FlagPK, flagViewbox),
  FlagPL: mapToSvgIcon(FlagPL, flagViewbox),
  FlagPM: mapToSvgIcon(FlagPM, flagViewbox),
  FlagPN: mapToSvgIcon(FlagPN, flagViewbox),
  FlagPR: mapToSvgIcon(FlagPR, flagViewbox),
  FlagPS: mapToSvgIcon(FlagPS, flagViewbox),
  FlagPT: mapToSvgIcon(FlagPT, flagViewbox),
  FlagPW: mapToSvgIcon(FlagPW, flagViewbox),
  FlagPY: mapToSvgIcon(FlagPY, flagViewbox),
  FlagQA: mapToSvgIcon(FlagQA, customFlagViewbox),
  FlagRE: mapToSvgIcon(FlagRE, flagViewbox),
  FlagRO: mapToSvgIcon(FlagRO, flagViewbox),
  FlagRS: mapToSvgIcon(FlagRS, flagViewbox),
  FlagRU: mapToSvgIcon(FlagRU, flagViewbox),
  FlagRW: mapToSvgIcon(FlagRW, flagViewbox),
  FlagSA: mapToSvgIcon(FlagSA, flagViewbox),
  FlagSB: mapToSvgIcon(FlagSB, flagViewbox),
  FlagSC: mapToSvgIcon(FlagSC, flagViewbox),
  FlagSD: mapToSvgIcon(FlagSD, flagViewbox),
  FlagSE: mapToSvgIcon(FlagSE, flagViewbox),
  FlagSG: mapToSvgIcon(FlagSG, flagViewbox),
  FlagSH: mapToSvgIcon(FlagSH, customFlagViewbox),
  FlagSI: mapToSvgIcon(FlagSI, customFlagViewbox),
  FlagSJ: mapToSvgIcon(FlagSJ, flagViewbox),
  FlagSK: mapToSvgIcon(FlagSK, customFlagViewbox),
  FlagSL: mapToSvgIcon(FlagSL, flagViewbox),
  FlagSM: mapToSvgIcon(FlagSM, flagViewbox),
  FlagSN: mapToSvgIcon(FlagSN, flagViewbox),
  FlagSO: mapToSvgIcon(FlagSO, flagViewbox),
  FlagSR: mapToSvgIcon(FlagSR, flagViewbox),
  FlagSS: mapToSvgIcon(FlagSS, flagViewbox),
  FlagST: mapToSvgIcon(FlagST, flagViewbox),
  FlagSV: mapToSvgIcon(FlagSV, flagViewbox),
  FlagSX: mapToSvgIcon(FlagSX, flagViewbox),
  FlagSY: mapToSvgIcon(FlagSY, flagViewbox),
  FlagSZ: mapToSvgIcon(FlagSZ, flagViewbox),
  FlagTA: mapToSvgIcon(FlagTA, customFlagViewbox),
  FlagTC: mapToSvgIcon(FlagTC, flagViewbox),
  FlagTD: mapToSvgIcon(FlagTD, flagViewbox),
  FlagTF: mapToSvgIcon(FlagTF, flagViewbox),
  FlagTG: mapToSvgIcon(FlagTG, flagViewbox),
  FlagTH: mapToSvgIcon(FlagTH, flagViewbox),
  FlagTJ: mapToSvgIcon(FlagTJ, flagViewbox),
  FlagTK: mapToSvgIcon(FlagTK, flagViewbox),
  FlagTL: mapToSvgIcon(FlagTL, flagViewbox),
  FlagTM: mapToSvgIcon(FlagTM, flagViewbox),
  FlagTN: mapToSvgIcon(FlagTN, flagViewbox),
  FlagTO: mapToSvgIcon(FlagTO, flagViewbox),
  FlagTR: mapToSvgIcon(FlagTR, customFlagViewbox),
  FlagTT: mapToSvgIcon(FlagTT, flagViewbox),
  FlagTV: mapToSvgIcon(FlagTV, customFlagViewbox),
  FlagTW: mapToSvgIcon(FlagTW, flagViewbox),
  FlagTZ: mapToSvgIcon(FlagTZ, flagViewbox),
  FlagUA: mapToSvgIcon(FlagUA, flagViewbox),
  FlagUG: mapToSvgIcon(FlagUG, customFlagViewbox),
  FlagUM: mapToSvgIcon(FlagUM, customFlagViewbox),
  FlagUS: mapToSvgIcon(FlagUS, customFlagViewbox),
  FlagUY: mapToSvgIcon(FlagUY, customFlagViewbox),
  FlagUZ: mapToSvgIcon(FlagUZ, flagViewbox),
  FlagVA: mapToSvgIcon(FlagVA, flagViewbox),
  FlagVC: mapToSvgIcon(FlagVC, flagViewbox),
  FlagVE: mapToSvgIcon(FlagVE, '0 0 24 15'),
  FlagVG: mapToSvgIcon(FlagVG, flagViewbox),
  FlagVI: mapToSvgIcon(FlagVI, customFlagViewbox),
  FlagVN: mapToSvgIcon(FlagVN, flagViewbox),
  FlagVU: mapToSvgIcon(FlagVU, flagViewbox),
  FlagWF: mapToSvgIcon(FlagWF, applicationViewbox),
  FlagWS: mapToSvgIcon(FlagWS, flagViewbox),
  FlagXK: mapToSvgIcon(FlagXK, customFlagViewbox),
  FlagYE: mapToSvgIcon(FlagYE, flagViewbox),
  FlagYT: mapToSvgIcon(FlagYT, applicationViewbox),
  FlagZA: mapToSvgIcon(FlagZA, flagViewbox),
  FlagZM: mapToSvgIcon(FlagZM, flagViewbox),
  FlagZW: mapToSvgIcon(FlagZW, flagViewbox),
  FlagZZ: Placeholder,
};

export default TeliaFlagIcons;
