import { FormikContextType, useFormikContext } from 'formik';
import { useEffect, useRef } from 'react';

export function usePreviousFormikValues<T>(): IPreviewFormikValues<T> {
  const context = useFormikContext<T>();
  const { values } = context;
  const prevValueRef = useRef<T>(values);

  useEffect(() => {
    prevValueRef.current = values;
  }, [values]);

  return {
    currentValues: values,
    prevValues: prevValueRef.current,
    context,
  };
}

export interface IPreviewFormikValues<T> {
  currentValues: T;
  prevValues: T;
  context: FormikContextType<T>;
}
export type FormikValueChangedHandler<T> = (
  values: IPreviewFormikValues<T>
) => void;

function ValueChange<T>({
  onValueChange,
}: {
  onValueChange: FormikValueChangedHandler<T>;
}) {
  const values = usePreviousFormikValues<T>();
  useEffect(() => onValueChange(values), [onValueChange, values]);
  return null;
}

export default ValueChange;
