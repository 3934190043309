import { Box, Skeleton } from '@mui/material';
import i18n from 'i18next';
import { Column } from 'react-table';
import { convertByteToUnitNumber, getByteConversionUnitAndValue } from 'utils';
import { FilterOption } from '../Helpers/filterOption';
import { getOptionFilter } from '../Helpers/getOptionFilter';
import { getTextFilter } from '../Helpers/getTextFilter';
import { textRight } from '../Helpers/textRight';

export function dataUsage<T>(options: {
  id: string;
  header: JSX.Element | string;
  accessor: (row: T) => number | null | undefined;
  filterable?: boolean;
  filter?: boolean | 'options';
  filterOptions?: FilterOption[];
  decimals?: number;
  defaultShow?: boolean;
  defaultSortDesc?: boolean;
  minWidth?: number;
  sortable?: boolean;
  resizable?: boolean;
  width?: number;
}): Column<T> {
  const {
    id,
    header,
    accessor,
    filterable,
    filter,
    filterOptions = [],
    defaultShow: show = true,
    defaultSortDesc = false,
    width = 80,
    sortable = true,
    resizable = true,
  } = options;
  const textFilter = filter === false ? undefined : getTextFilter();

  return {
    id,
    Header: <Box textAlign="right">{header}</Box>,
    className: textRight,
    accessor,
    width,
    show,
    resizable,
    sortable,
    defaultSortDesc,
    Cell: row => {
      switch (row.value) {
        case -1:
          return <Skeleton animation="wave" variant="text" />;
        case -2:
          return i18n.t('not_applicable_abbreviation');
        case null:
        case undefined:
          return <span className="text-muted">-</span>;
        default:
          const { value, unit } = getByteConversionUnitAndValue(row.value);
          const rawValue = convertByteToUnitNumber(row.value);
          return (
            <div title={`${rawValue} ${unit}`}>
              {value}
              <span className="text-unit">{unit}</span>
            </div>
          );
      }
    },
    filterable,
    Filter: filter === 'options' ? getOptionFilter(filterOptions) : textFilter,
  };
}
