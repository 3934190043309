import { aiTrackEvent } from 'appInsights';
import { snackbar } from 'components/Snackbar';
import {
  IntAtlasServiceDto,
  IntAutomationRuleDetailsDto,
  IntAutomationRuleDto,
  IntAutomationRuleFilterType,
  IntAutomationRuleType,
  IntConnectivityUnitTableRequestDto,
  IntCreateAutomationRuleDto,
  IntCreateLegacyAutomationRuleDto,
  IntFilterIdDto,
  IntLegacyAutomationRuleDto,
} from 'generated';
import i18n from 'i18n';
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { Filter } from 'react-table';
import { automationApi } from 'services/automation.service';
import { connectivityUnitAPI } from 'services/connectivityUnit.service';
import { customerAPI } from 'services/customer.service';
import { serviceApi } from 'services/service.service';
import { IResponse } from 'shared/interfaces/api';
import { RootStore } from 'store/rootStore';
import { StoreBase } from 'store/storeBase';
import {
  IEntityObjectPost,
  IntEntityType,
} from 'views/Management/Automations/EditAutomationWizard/interfaces';

export class AutomationStore extends StoreBase {
  @observable openModal = false;
  @observable showDeletionModal = false;
  @observable detailsLoading = false;
  @observable detailsError = false;
  @observable loading = false;
  @observable cloningLoading = false;
  @observable automationRuleDetails: IntAutomationRuleDetailsDto | undefined;
  @observable automationRule: IntAutomationRuleDto | undefined;
  @observable legacyAutomationRule: IntLegacyAutomationRuleDto | undefined;
  @observable automationRuleError = false;
  @observable serviceOptions: IntAtlasServiceDto[] | undefined;
  @observable services: IntAtlasServiceDto[] = [];
  @observable initialEntities: IntEntityType[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }

  @action.bound setShowDeletionModal(toggle: boolean) {
    this.showDeletionModal = toggle;
  }

  @action.bound setOpenModal(toggle: boolean) {
    this.openModal = toggle;
  }

  @action.bound closeModal() {
    this.setOpenModal(false);
    this.rootStore.refreshPageData();
  }

  @action.bound setDetailsLoading(loading: boolean) {
    this.detailsLoading = loading;
  }

  @action.bound setLoading(loading: boolean) {
    this.loading = loading;
  }

  @action.bound setCloningLoading(loading: boolean) {
    this.cloningLoading = loading;
  }

  @action.bound async setServiceOptions(
    serviceOptions: IntAtlasServiceDto[] | undefined
  ) {
    this.serviceOptions = serviceOptions;
  }

  @action.bound async getServiceOptions() {
    if (this.serviceOptions) {
      return;
    }
    const resp = await this.httpGet(
      serviceApi.getAll,
      {
        filters: '',
        orderBy: 'serviceName',
        orderDesc: false,
        page: 0,
        pageSize: 9999,
        search: '',
      },
      loading => {
        this.setLoading(loading);
      }
    );
    if (resp.status === 200 || resp.status === 204) {
      runInAction(() => {
        this.serviceOptions = resp.data?.rows ?? [];
      });
    } else {
      snackbar(i18n.t('dashboard:error.services'), { variant: 'error' });
    }
  }

  @action.bound async getService(serviceId: string) {
    const existingService = this.services.find(s => s.serviceId === serviceId);
    if (existingService) {
      return;
    }
    const resp = await this.httpGet(serviceApi.getService, serviceId);
    const service = resp.data;
    if (resp.status === 200 && service) {
      runInAction(() => {
        this.services.push(service);
      });
    } else {
      snackbar(i18n.t('dashboard:error.service'), { variant: 'error' });
    }
  }

  async modifyAutomationRuleResponse(
    status: number,
    automationRuleId: string,
    setAutomationRuleState: boolean,
    useLegacy: boolean
  ) {
    runInAction(() => {
      if (status === 200) {
        snackbar(i18n.t('automation:success.modify'), { variant: 'success' });

        aiTrackEvent('Update', { title: 'Automation rule' });

        if (setAutomationRuleState) {
          this.setOpenModal(false);
          if (useLegacy) {
            this.getAutomationRuleLegacy(automationRuleId);
          } else {
            this.getAutomationRuleDetails(automationRuleId);
            this.getAutomationRule(automationRuleId);
          }
        }
      } else {
        snackbar(i18n.t('automation:error.modify'), {
          variant: 'error',
        });
      }
    });
  }

  @action.bound async modifyAutomationRule(
    automationRuleId: string,
    automationRule: IntCreateAutomationRuleDto,
    setAutomationRuleState = false
  ) {
    const resp = await this.httpPut(
      automationApi.modifyAutomationRule,
      {
        params: automationRuleId,
        data: automationRule,
      },
      this.setLoading
    );

    await this.modifyAutomationRuleResponse(
      resp.status,
      automationRuleId,
      setAutomationRuleState,
      false
    );
  }

  @action.bound async modifyAutomationRuleLegacy(
    automationRuleId: string,
    automationRule: IntCreateLegacyAutomationRuleDto,
    setAutomationRuleState = false
  ) {
    const resp = await this.httpPut(
      automationApi.modifyAutomationRuleLegacy,
      {
        params: automationRuleId,
        data: automationRule,
      },
      this.setLoading
    );

    await this.modifyAutomationRuleResponse(
      resp.status,
      automationRuleId,
      setAutomationRuleState,
      true
    );
  }

  createAutomationRuleResponse(resp: IResponse<string | IntAutomationRuleDto>) {
    runInAction(() => {
      if (resp.status === 201) {
        snackbar(i18n.t('automation:success.create'), { variant: 'success' });

        aiTrackEvent('Create', { title: 'Automation rule' });
      } else if (resp.status === 208) {
        snackbar(i18n.t('automation:error.create.duplicate'), {
          variant: 'error',
        });
      } else {
        snackbar(i18n.t('automation:error.create.base'), {
          variant: 'error',
        });
      }
    });
  }

  @action.bound async getAutomationRuleLegacy(automationRuleId: string) {
    this.httpGet(
      automationApi.getSingleLegacy,
      automationRuleId,
      this.setLoading
    )
      .then(response => {
        if (response.status === 200 && response.data) {
          this.setLegacyAutomationRule(response.data);
        } else {
          this.automationRuleError = true;
          snackbar(i18n.t('automation:error.get_rule'), {
            variant: 'error',
          });
        }
      })
      .finally(() => {
        this.automationRuleError = false;
        this.setLoading(false);
      });
  }

  @action.bound async createAutomationRuleLegacy(
    automationRule: IntCreateLegacyAutomationRuleDto
  ) {
    const resp = await this.httpPost(automationApi.createAutomationRuleLegacy, {
      params: undefined,
      data: automationRule,
    });

    this.createAutomationRuleResponse(resp);
  }

  @action.bound async createAutomationRule(
    automationRule: IntCreateAutomationRuleDto
  ) {
    const resp = await this.httpPost(automationApi.createAutomationRule, {
      params: undefined,
      data: automationRule,
    });

    this.createAutomationRuleResponse(resp);
  }

  checkName = async (
    name: string,
    automationRule:
      | IntCreateAutomationRuleDto
      | IntCreateLegacyAutomationRuleDto,
    index: number = 1
  ): Promise<string> => {
    const newName = `${name}-${index}`;
    const resp = await this.httpGet(
      automationApi.validateAutomationRuleName,
      {
        automationRuleName: newName,
        customerId: automationRule.customerId,
      },
      this.setCloningLoading
    );
    if (!resp.data) {
      return await this.checkName(name, automationRule, index + 1);
    } else return newName;
  };

  cloneAutomationRuleResponse(status: number) {
    runInAction(() => {
      if (status === 201) {
        snackbar(`${i18n.t('automation:success.clone')}`, {
          variant: 'success',
        });

        aiTrackEvent('Create', { title: 'Automation rule clone' });
      } else if (status === 208) {
        snackbar(i18n.t('automation:error.create.duplicate'), {
          variant: 'error',
        });
      } else {
        snackbar(i18n.t('automation:error.create.clone'), {
          variant: 'error',
        });
      }
    });
  }

  @action.bound async cloneLegacyAutomationRule(
    automationRule: IntCreateLegacyAutomationRuleDto
  ): Promise<string> {
    const automationRuleName = await this.checkName(
      automationRule.displayName,
      automationRule
    );

    const clonedAutomationRule: IntCreateLegacyAutomationRuleDto = {
      ...automationRule,
      displayName: automationRuleName,
    };

    const resp = await this.httpPost(
      automationApi.createAutomationRuleLegacy,
      {
        params: undefined,
        data: clonedAutomationRule,
      },
      this.setCloningLoading
    );
    this.cloneAutomationRuleResponse(resp.status);
    return resp.data ?? '';
  }

  @action.bound async cloneAutomationRule(
    automationRule: IntCreateAutomationRuleDto
  ): Promise<string> {
    const automationRuleName = await this.checkName(
      automationRule.displayName,
      automationRule
    );

    const clonedAutomationRule: IntCreateAutomationRuleDto = {
      ...automationRule,
      displayName: automationRuleName,
    };

    const resp = await this.httpPost(
      automationApi.createAutomationRule,
      {
        params: undefined,
        data: clonedAutomationRule,
      },
      this.setCloningLoading
    );

    this.cloneAutomationRuleResponse(resp.status);

    return resp.data?.id ?? '';
  }

  @action.bound setAutomationRule(
    automationRule: IntAutomationRuleDto | undefined
  ) {
    this.automationRule = automationRule;
  }

  @action.bound setLegacyAutomationRule(
    automationRule: IntLegacyAutomationRuleDto | undefined
  ) {
    this.legacyAutomationRule = automationRule;
  }

  @action.bound setAutomationRuleDetails(
    automationRuleDetails: IntAutomationRuleDetailsDto | undefined
  ) {
    this.automationRuleDetails = automationRuleDetails;
  }

  @action.bound setInitialEntities(entities: IntEntityType[]) {
    this.initialEntities = entities;
  }

  @action.bound async populateEntities(
    automationRule: IntAutomationRuleDto | undefined
  ) {
    const getEntityObject = (
      ruleType: IntAutomationRuleType,
      filter: IntFilterIdDto
    ): IEntityObjectPost<IntConnectivityUnitTableRequestDto> => {
      const value = filter.entityId;
      if (ruleType === IntAutomationRuleType.ServiceDataChanged) {
        throw new Error('Not implemented yet');
      } else
        return {
          apiEndpoint: connectivityUnitAPI.getAllPost({
            customerId: this.rootStore.authStore.user?.customerId,
          }),
          filters: [{ id: 'id', value }],
        };
    };

    let entityObject: IEntityObjectPost<IntConnectivityUnitTableRequestDto> = {
      apiEndpoint: connectivityUnitAPI.getAllPost({
        customerId: this.rootStore.authStore.user?.customerId,
      }),
      filters: [],
    };
    const entityFilters: Filter[] = [];

    let response;

    if (
      automationRule?.automationRuleFilterType ===
      IntAutomationRuleFilterType.Customer
    ) {
      const customers: string[] = [];
      automationRule.filterIds.forEach(filter => {
        customers.push(filter.entityId);
      });
      response = await this.httpGet(customerAPI.getAll, {
        pageSize: 500,
        page: 0,
        orderBy: '',
        orderDesc: false,
        search: '',
        filters: '',
        customerIds: customers,
      });
    } else {
      automationRule?.filterIds.forEach(filter => {
        entityObject = getEntityObject(automationRule.ruleType, filter);
        entityObject.filters.forEach((entity: Filter) =>
          entityFilters.push(entity)
        );
      });

      response = await this.httpPost(entityObject.apiEndpoint, {
        data: {
          pageSize: 500,
          page: 0,
          orderBy: '',
          orderDesc: false,
          search: '',
          filters: JSON.stringify(entityFilters) as any,
        },
        params: undefined,
      });
    }

    if (response.status === 200 && response.data?.rows) {
      this.setInitialEntities(response.data?.rows);
    } else {
      snackbar(i18n.t('automation:error.get_filtered_properties'), {
        variant: 'error',
      });
    }
  }

  @action.bound async getAutomationRule(automationRuleId: string) {
    await this.httpGet(
      automationApi.getSingle,
      automationRuleId,
      this.setLoading
    )
      .then(response => {
        if (response.status === 200 && response.data) {
          this.populateEntities(response.data);
          this.setAutomationRule(response.data);
        } else {
          this.automationRuleError = true;
          snackbar(i18n.t('automation:error.get_rule'), {
            variant: 'error',
          });
        }
      })
      .finally(() => {
        this.automationRuleError = false;
        this.setLoading(false);
      });
  }

  @action.bound async getAutomationRuleDetails(automationRuleId: string) {
    this.detailsError = false;
    await this.httpGet(
      automationApi.getAutomationRuleDetails,
      automationRuleId,
      this.setDetailsLoading
    ).then(response => {
      if (response.status === 200 && response.data) {
        this.setAutomationRuleDetails(response.data);
      } else {
        runInAction(() => {
          this.detailsError = true;
        });
      }
    });
  }

  @action.bound async deleteAutomationRule(
    automationRuleId: string,
    onDeleteComplete: () => void
  ) {
    const resp = await this.httpDelete(automationApi.deleteAutomationRule, {
      params: automationRuleId,
    });

    if (resp.status === 200) {
      snackbar(i18n.t('automation:success.delete'), { variant: 'success' });
      aiTrackEvent('Delete', { title: 'Automation rule' });
      this.setShowDeletionModal(false);
      onDeleteComplete();

      //communicate with dashboard store that a rule was deleted and force a reload on the dashboard
      this.rootStore.dashboardStore.refreshWidgetData();
    } else {
      snackbar(resp.statusText ?? i18n.t('automation:error.delete'), {
        variant: 'error',
      });
    }
  }

  @action.bound getSelectedService(triggerServiceId = '') {
    return this.services.find(
      service => service.serviceId === triggerServiceId
    );
  }

  @action.bound getSelectedServiceProperty(
    triggerServiceId = '',
    triggerServicePropertyId = ''
  ) {
    return this.getSelectedService(triggerServiceId)?.properties.find(
      property => property.servicePropertyId === triggerServicePropertyId
    );
  }

  @action.bound getServicePropertyOptions(
    servicesLength: number,
    triggerServiceId: string | undefined
  ) {
    if (servicesLength > 0) {
      return (
        this.getSelectedService(triggerServiceId)?.properties.map(property => ({
          value: property.servicePropertyId,
          label: property.servicePropertyName,
        })) ?? []
      );
    }
    return [];
  }

  @computed get isDetailLoading() {
    return this.loading || this.detailsLoading;
  }

  @computed get hasDetailError() {
    return this.detailsError || this.automationRuleError;
  }

  @action.bound async forceExecuteAutomationRule(guid: string) {
    const resp = await this.httpPost(automationApi.forceExecuteAutomationRule, {
      params: { guid },
    });

    if (resp.status === 200) {
      snackbar(i18n.t('automation:success.force_execute'), {
        variant: 'success',
      });
      aiTrackEvent('ForceExecute', { title: 'Automation rule' });
    } else {
      snackbar(resp.statusText ?? i18n.t('automation:error.force_execute'), {
        variant: 'error',
      });
    }
  }
}
