import { CSSInterpolation } from '@mui/material';

export interface IFab {
  root: CSSInterpolation;
  primary: CSSInterpolation;
}

export const fabConfig: IFab = {
  root: {
    background: '#990ae3',
    textTransform: 'capitalize',
    fontWeight: 'bold',
  },
  primary: {
    '&:hover, &:active': {
      backgroundColor: '#AC1FF5',
    },
    '&:focused, .MuiTouchRipple-child': {
      backgroundColor: '#8007BD',
    },
    '&:disabled': {
      backgroundColor: '#F2F2F2',
      color: '#6E6E6E',
    },
  },
};
