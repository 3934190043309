import { createStyles, makeStyles } from '@mui/styles';
import { fontSettings } from 'styles/themes/fontSettings';

export const useStyles = makeStyles(theme =>
  createStyles({
    rowList: {
      border: '1px solid #cccccc',
      borderRadius: 4,
      overflow: 'hidden',
      padding: 0,
      margin: theme.spacing(2, 0),
    },
    row: {
      cursor: 'move',
      '&.is-dragging': {
        backgroundColor: '#FFF',
        boxShadow: theme.shadows[10],
        zIndex: 9999,
      },
    },
    iconButton: {
      padding: 0,
      margin: theme.spacing(2, 0),
    },
    icon: {
      height: '20px',
      width: '20px',
      marginRight: '2px',
      color: theme.palette.primary.main,
    },
    label: {
      fontFamily: fontSettings.TeliaSansMedium.fontStack,
      fontSize: '16px',
      lineHeight: '16px',
      color: theme.palette.primary.main,
    },
  })
);
