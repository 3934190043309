import { useField } from 'formik';
import { isEqual } from 'lodash';
import qs, { ParsedQs } from 'qs';
import { ParsedUrlQueryInput } from 'querystring';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SortEndHandler } from 'react-sortable-hoc';
import {
  Column,
  Filter,
  FilteredChangeFunction,
  PageChangeFunction,
  PageSizeChangeFunction,
  SortedChangeFunction,
  SortingRule,
} from 'react-table';
import { useGet, usePost } from 'shared/api/hooks';
import { PostTableEndpoint, TableEndpoint } from 'shared/interfaces/api';
import {
  arrayMove,
  delayedTriggerWindowResize,
  useRegisterComponentRefresh,
} from 'utils';
import {
  TableData,
  tableParamsToSearchDto,
  TableQueryParams,
  TableSelectState,
} from '.';

interface UseTableParamsReturn {
  params: TableQueryParams;
  onSearchChange: (searchTerm: string) => void;
  onResetFilter: (initialFilter?: Filter[]) => void;
  tableParamProps: {
    onPageChange: PageChangeFunction;
    onPageSizeChange: PageSizeChangeFunction;
    onSortedChange: SortedChangeFunction;
    onFilteredChange: FilteredChangeFunction;
  };
}

export interface ExtendedParsedQs extends ParsedQs {
  table?: {
    [key: string]: ParsedQs;
  };
}

export function useTableStateMemory({
  pageSize = 50,
  initialFilters = [],
}: {
  pageSize?: number;
  initialFilters?: Filter[];
} = {}): UseTableParamsReturn {
  const [params, setParams] = useState<TableQueryParams>({
    page: 0,
    pageSize,
    search: '',
    sortRules: [],
    filters: initialFilters,
  });

  return {
    params,
    onResetFilter: () => {
      setParams(prevState => ({ ...prevState, filters: initialFilters }));
    },
    onSearchChange: searchTerm =>
      setParams(prevState => ({ ...prevState, search: searchTerm })),
    tableParamProps: {
      onFilteredChange: newFilters => {
        if (!isEqual(params.filters, newFilters)) {
          setParams(prevState => ({
            ...prevState,
            filters: newFilters,
          }));
        }
      },
      onPageChange: page =>
        setParams(prevState => ({ ...prevState, page: page })),
      onPageSizeChange: (newSize, page) =>
        setParams(prevState => ({
          ...prevState,
          page,
          pageSize: newSize,
        })),
      onSortedChange: newSorted => {
        const newSortRule: SortingRule = newSorted.length
          ? newSorted[0]
          : { id: '', desc: false };
        setParams(prevState => ({
          ...prevState,
          sortRules: newSorted,
          orderBy: newSortRule.id,
          orderDesc: newSortRule.desc,
        }));
      },
    },
  };
}

export function useTableQueryParams<TRow>(
  options: {
    defaultPageSize?: number;
    defaultSortCol?: string;
    defaultSortDesc?: boolean;
    tableId?: string;
    searchColumns?: (keyof TRow)[];
  } = {}
): UseTableParamsReturn {
  const {
    defaultPageSize = 50,
    defaultSortCol = '',
    defaultSortDesc = false,
    tableId,
    searchColumns,
  } = options;
  const { pathname, search } = useLocation();
  const query = search.slice(1);
  const history = useHistory();
  const { push: historyPush, replace: historyReplace } = history;

  const params: TableQueryParams = useMemo(() => {
    let searchParams: ExtendedParsedQs = qs.parse(query, {
      ignoreQueryPrefix: true,
    });
    if (tableId && searchParams.table) {
      searchParams = searchParams.table[tableId] || '';
    }

    const filters: Filter[] =
      typeof searchParams.filter === 'string'
        ? JSON.parse(decodeURIComponent(searchParams.filter))
        : [];

    const pageNo =
      typeof searchParams.page === 'string' ? parseInt(searchParams.page) : 1;

    const page = pageNo - 1;

    const pageSize =
      typeof searchParams.pageSize === 'string'
        ? parseInt(searchParams.pageSize)
        : defaultPageSize;

    const orderBy =
      typeof searchParams.sort === 'string'
        ? searchParams.sort
        : defaultSortCol;

    const orderDesc = searchParams.sortDesc
      ? searchParams.sortDesc === 'true'
      : defaultSortDesc;

    // Important to memo sortRules or the rable infinitely re-renders.
    // This is not used correctly in back-end but it is by decision...
    const sortRules: SortingRule[] = orderBy
      ? [{ id: orderBy, desc: orderDesc }]
      : [];

    const searchTerm =
      typeof searchParams.search === 'string' ? searchParams.search : '';

    const queryParams: TableQueryParams = {
      page,
      pageSize,
      sortRules,
    };
    if (searchColumns && searchColumns.length > 0) {
      queryParams.searchColumns = JSON.stringify(searchColumns);
    }

    if (filters?.length > 0) {
      queryParams.filters = [...filters];
    }
    if (searchTerm?.length > 0) {
      queryParams.search = searchTerm;
    }

    return queryParams;
  }, [
    query,
    tableId,
    defaultPageSize,
    defaultSortCol,
    defaultSortDesc,
    searchColumns,
  ]);

  const pushOrReplaceHistory = (
    scopeUpdates: Partial<TableQueryParams>,
    scopeNextParams: ParsedUrlQueryInput
  ) => {
    // Back-button-functionality is overkill for changing filters, only push if page index and/or size changes.
    const push = scopeUpdates.page != null || scopeUpdates.pageSize != null;
    if (tableId) {
      const prevParams = qs.parse(query, { ignoreQueryPrefix: true });
      if (typeof prevParams.table === 'object') {
        scopeNextParams = {
          ...prevParams,
          table: {
            ...prevParams.table,
            [tableId]: { ...scopeNextParams },
          },
        };
      } else {
        scopeNextParams = {
          ...prevParams,
          table: {
            [tableId]: { ...scopeNextParams },
          },
        };
      }
    }

    const fullPath = `${pathname}?${qs.stringify(scopeNextParams)}`;

    if (push) {
      historyPush(fullPath);
    } else {
      historyReplace(fullPath);
    }
  };

  const setNextParamsFilter = (
    scopeUpdates: Partial<TableQueryParams>,
    scopeFilters: Filter[] | undefined,
    scopeNextParams: ParsedUrlQueryInput
  ) => {
    const nextParamsFilter = scopeUpdates.filters || scopeFilters;

    if (nextParamsFilter) {
      scopeNextParams.filter = encodeURIComponent(
        JSON.stringify(nextParamsFilter)
      );
    }
  };

  const setNextParamsSorting = (
    updates: Partial<TableQueryParams>,
    sortRules: SortingRule[],
    nextParams: ParsedUrlQueryInput
  ) => {
    if (
      (updates.sortRules && updates.sortRules.length > 0) ||
      (sortRules && sortRules.length > 0)
    ) {
      const sortDesc = updates?.sortRules?.[0].desc ?? sortRules?.[0].desc;

      nextParams.sort = updates?.sortRules?.[0].id ?? sortRules?.[0].id;

      if (sortDesc) {
        nextParams.sortDesc = true;
      }
    }
  };

  const updateQuery = (updates: Partial<TableQueryParams>) => {
    const { page, pageSize, filters, sortRules } = params;
    const nextParams: ParsedUrlQueryInput = {};
    const nextIndex = updates.page ?? page;

    if (nextIndex > 0) {
      nextParams.page = (updates.page || page) + 1;
    }

    const nextPageSize = updates.pageSize != null ? updates.pageSize : pageSize;

    if (nextPageSize !== defaultPageSize) {
      nextParams.pageSize = updates.pageSize || pageSize;
    }

    setNextParamsSorting(updates, sortRules, nextParams);

    const nextSearch =
      typeof updates.search === 'string' ? updates.search : params.search;

    if (nextSearch) {
      nextParams.search = nextSearch; // EncodeURI?
    }

    setNextParamsFilter(updates, filters, nextParams);
    pushOrReplaceHistory(updates, nextParams);
  };

  const onSortedChange: SortedChangeFunction = newSorted => {
    if (!newSorted || !newSorted.length) {
      return;
    }

    updateQuery({ sortRules: newSorted });
  };

  const onPageChange: PageChangeFunction = nextPage => {
    updateQuery({ page: nextPage });
  };

  const onPageSizeChange: PageSizeChangeFunction = (nextPageSize, nextPage) => {
    updateQuery({
      page: nextPage,
      pageSize: nextPageSize,
    });
  };

  const onFilteredChange: FilteredChangeFunction = nextFilters => {
    updateQuery({
      filters: nextFilters.filter(
        filter => !Array.isArray(filter.value) || filter.value.length > 0
      ),
      page: 0,
    });
  };

  return {
    params,
    onSearchChange: searchTerm => updateQuery({ search: searchTerm, page: 0 }),
    onResetFilter: () => updateQuery({ filters: [], page: 0 }),
    tableParamProps: {
      onSortedChange,
      onPageChange,
      onPageSizeChange,
      onFilteredChange,
    },
  };
}

export interface SavedColumn {
  id: string; // Really, all we need?
  show: boolean; // But might as well prepare for saving everything
  width?: number; // Not implemented, but wouldn't it be neat?
}

export function useTableColumnSettings<T>(
  columns: Column<T>[],
  tableId: string
) {
  const columnsById = useMemo(() => {
    const value: Record<string, Column<T>> = {};
    columns.forEach(col => {
      if (col.id) {
        value[col.id] = col;
      }
    });
    return value;
  }, [columns]);

  const storedColumnsAreCorrect = (a: SavedColumn[], b: Column<T>[]) => {
    // If the available column IDs have changed since last save, revert column settings to default to avoid bugs. Could probably be improved.
    if (!a || !b || a.length !== b.length) {
      return false;
    }
    const firstIds = a
      .map(col => col.id)
      .sort()
      .join(',');
    const secondIds = b
      .map(col => col.id)
      .sort()
      .join(',');
    return firstIds === secondIds;
  };

  const [order, setOrder] = useState<SavedColumn[]>(() => {
    const storedValue = localStorage.getItem(tableId);
    if (storedValue) {
      const parsedStoredValue = JSON.parse(storedValue);
      if (storedColumnsAreCorrect(parsedStoredValue, columns)) {
        return parsedStoredValue;
      }
    }
    return columns.map(col => {
      const item: SavedColumn = {
        id: col.id || '_',
        show: col.show !== false,
      };
      return item;
    });
  });

  useEffect(() => {
    localStorage.setItem(tableId, JSON.stringify(order));
  }, [tableId, order]);

  const betterColumns: Column<T>[] = order.map(item => {
    const col = columnsById[item.id];
    return {
      ...col,
      show: item.show,
    };
  });

  const toggleShowColumn = (colId: string, show: boolean) => {
    setOrder(cols => cols.map(c => (c.id === colId ? { ...c, show } : c)));
  };

  const handleSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
    setOrder(cols => arrayMove(cols, oldIndex, newIndex));
  };

  return {
    columns: betterColumns,
    toggleShowColumn,
    handleSortEnd,
  };
}

export function useTableSelect<T extends object>(
  key: keyof T & string,
  opts: { isRowDisabled?: (row: T) => boolean } = {}
): [T[], TableSelectState<T>, () => void] {
  const { isRowDisabled } = opts;
  const [state, setState] = useState<Record<string, T>>({});

  const returnVal: TableSelectState<T> = useMemo(
    () => ({
      isRowSelected: row => row && state[row[key] as any] != null,
      selectRow: (row, select) => {
        if (!key) {
          return;
        }
        setState(prevState => {
          const newState = { ...prevState };
          const rowKey = row[key] as any;
          if (select) {
            newState[rowKey] = row;
          } else {
            delete newState[rowKey];
          }
          return newState;
        });
      },
      isRowDisabled,
      selectedRowsCount: Object.keys(state).length,
      isAllSelected: rows => {
        const rowKeys = rows.map(row => `${row[key]}`);
        return rowKeys.every(rowKey => Object.keys(state).includes(rowKey));
      },
      toggleAll: (rows, checked) => {
        setState(prevState => {
          const newState = { ...prevState };
          if (checked) {
            rows.forEach(row => {
              delete newState[`${row[key]}`];
            });
          } else {
            rows.forEach(row => {
              newState[`${row[key]}`] = row;
            });
          }
          return newState;
        });
      },
    }),
    [state, key, isRowDisabled]
  );

  const resetState = () => setState({});

  return [Object.values(state), returnVal, resetState];
}

export function useFormTableSelect<T extends object>(
  name: string,
  key: keyof T & string
): [T[], TableSelectState<T>] {
  const [field, , helpers] = useField<T[]>(name);
  return [
    field.value,
    {
      isRowSelected: row =>
        field?.value?.findIndex(valueRow => valueRow[key] === row[key]) > -1,
      selectRow: (row, select) => {
        if (!field.value) {
          helpers.setValue([row]);
        } else if (select) {
          helpers.setValue([...field.value, row]);
        } else {
          helpers.setValue(field.value.filter(r => r[key] !== row[key]));
        }
      },
      isAllSelected: rows =>
        rows.every(row =>
          field.value.map(value => value[key]).includes(row[key])
        ),
      toggleAll: (rows, checked) => {
        helpers.setValue(
          checked
            ? field.value.filter(
                value => !rows.map(row => row[key]).includes(value[key])
              )
            : [
                ...field.value,
                ...rows.filter(
                  row =>
                    !field.value.map(value => value[key]).includes(row[key])
                ),
              ]
        );
      },
    },
  ];
}

export const useTableFetch = <TRow>(
  endpoint: TableEndpoint<TRow>,
  params: TableQueryParams
) => {
  const [data, setData] = useState<TableData<TRow>>();
  const [errors, setErrors] = useState<string>();
  const [get, isLoading] = useGet(endpoint);

  const fetchData = useCallback(async () => {
    try {
      const searchParams = tableParamsToSearchDto(params);
      const response = await get(searchParams);
      setErrors(undefined);

      if (response.status === 200 && response.data) {
        setData({ rows: response.data.rows, total: response.data.total });
      } else if (response.status === 204) {
        setData({ rows: [], total: 0 });
      } else {
        setErrors(response.statusText ?? 'error');
      }
    } catch (error) {
      if (error.message) {
        setErrors(error.message);
      } else {
        setErrors(error);
      }
    }
    delayedTriggerWindowResize();
  }, [get, params]);

  useRegisterComponentRefresh(fetchData);

  useEffect(() => {
    fetchData();
  }, [params, fetchData]);

  return [data, errors, isLoading] as const;
};

export const usePostTableFetch = <TRequestBody, TRow>(
  endpoint: PostTableEndpoint<TRequestBody, TRow>,
  getPostBody?: () => TRequestBody | undefined
) => {
  const [data, setData] = useState<TableData<TRow>>();
  const [errors, setErrors] = useState<string>();
  const [post, isLoading] = usePost<undefined, TRequestBody, TableData<TRow>>(
    endpoint
  );

  const fetchData = useCallback(async () => {
    try {
      const response = await post({
        params: undefined,
        data: getPostBody?.(),
      });
      setErrors(undefined);

      if (response.status === 200 && response.data) {
        setData(response.data);
      } else if (response.status === 204) {
        setData({ rows: [], total: 0 });
      } else {
        setErrors(response.statusText);
      }
    } catch (error) {
      if (error.message) {
        setErrors(error.message);
      } else {
        setErrors(error);
      }
    }
    delayedTriggerWindowResize();
  }, [post, getPostBody]);

  useRegisterComponentRefresh(fetchData);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return [data, errors, isLoading] as const;
};

// This function will be removed when we move away from get endpoints
export const useGetOrPost = <TRequestBody, TRow>(
  apiEndpoint: TableEndpoint<TRow> | PostTableEndpoint<TRequestBody, TRow>,
  endpointType: 'get' | 'post',
  params: TableQueryParams,
  getEndpoint: <TRow>(
    endpoint: TableEndpoint<TRow>,
    params: TableQueryParams
  ) => readonly [TableData<TRow> | undefined, string | undefined, boolean],
  postEndpoint: <TRequestBody, TRow>(
    endpoint: PostTableEndpoint<TRequestBody, TRow>,
    getPostBody?: () => TRequestBody | undefined
  ) => readonly [TableData<TRow> | undefined, string | undefined, boolean],
  getPostBody?: () => TRequestBody | undefined
): readonly [TableData<TRow> | undefined, string | undefined, boolean] => {
  if (endpointType === 'get') {
    return getEndpoint(apiEndpoint as TableEndpoint<TRow>, params);
  }

  return postEndpoint(
    apiEndpoint as PostTableEndpoint<TRequestBody, TRow>,
    getPostBody
  );
};

export function useContainerWidth(): [
  React.MutableRefObject<HTMLElement | null>,
  number,
] {
  const [width, setWidth] = useState<number>(900);

  const tableRef = useRef<HTMLElement | null>(null);

  useLayoutEffect(() => {
    const setContainerWidth = () => {
      tableRef.current && setWidth(tableRef.current.offsetWidth);
    };
    setContainerWidth();
    window.addEventListener('resize', setContainerWidth);
    return () => {
      window.removeEventListener('resize', setContainerWidth);
    };
  }, [tableRef]);

  return [tableRef, width];
}
