import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => {
  const { navMenu, topMenu } = theme.settings.components;

  const width =
    navMenu.width + navMenu.closerWidth || 240 + navMenu.closerWidth;
  const transition = theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: 80,
  });

  return createStyles({
    brand: {
      textDecoration: 'none',
      width: navMenu.width,
      '&:hover': {
        textDecoration: 'none',
      },
      transition,
    },
    icon: {
      maxHeight: 34,
      maxWidth: '100%',
    },
    logoWrapper: {
      minHeight: topMenu.height,
      width: width,
      transition,
      backgroundColor: navMenu.background,
    },
    minimized: {
      width: navMenu.collapsedWidth,
      transition,
    },
  });
});
