import { column } from 'components/Table';
import { Column } from 'react-table';
import { rootStore } from 'store/RootStoreContext';
import { IDataProperty } from 'views/Dashboard/DataSources/dataPropTypes';

export function getPolicyColumnOverride(
  params: {
    id: string;
    header: string;
    accessor: (row: any) => any;
    dataProp: IDataProperty;
  },
  policyId: string | undefined
): Column | undefined {
  const { id, header, accessor, dataProp } = params;

  const policy = rootStore.policyStore.policies?.find(
    p => p.policyId === policyId
  );
  const policyStatus = policy?.statuses?.find(s => s.servicePropertyId === id);

  if (policyStatus) {
    return column.policyColumn(
      {
        id,
        header,
        accessor,
        dataProp,
      },
      policyStatus
    );
  }
  return undefined;
}
