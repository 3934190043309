import classNames from 'classnames';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useStyles } from './DropdownLink.styles';

interface IProps {
  to: string;
  exact?: boolean;
  compact?: boolean;
}

// Todo: should probably have a ripple effect but using ButtonBase with Link is more trouble than it's worth right now
const DropdownLink: React.FC<IProps> = ({ to, exact, children, compact }) => {
  const classes = useStyles();

  return (
    <NavLink
      to={to}
      exact={exact}
      className={classNames(classes.root, { [classes.compact]: compact })}
    >
      {children}
    </NavLink>
  );
};

export default DropdownLink;
