import { emptyGuid } from 'components/Auth/utils';
import { Option } from 'components/FormFields';
import { snackbar } from 'components/Snackbar';
import {
  IntAttributeDto,
  IntConnectivityType,
  IntConnectivityUnitTableDto,
  IntSearchDto,
} from 'generated';
import i18n from 'i18n';
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { persist } from 'mobx-persist';
import { Column } from 'react-table';
import {
  connectivityUnitAPI,
  TPropertyName,
} from 'services/connectivityUnit.service';
import { productAPI } from 'services/product.service';
import { TableEndpoint } from 'shared/interfaces/api';
import { validateResponse } from 'utils';
import { ConnectivityUnit } from './domains/connectivityUnit';
import { getTableColumnForProperty } from './dynamicAttributeStore';
import { RootStore } from './rootStore';
import { StoreBase } from './storeBase';

type CustomAttributesModalsState = {
  createCustomAttribute: boolean;
  deleteCustomAttribute: boolean;
  updateCustomAttribute: boolean;
};

type UpsertCustomAttribute = 'update' | 'create';

export class ConnectivityUnitStore extends StoreBase {
  @observable currentUnit?: ConnectivityUnit;
  @observable currentUnitId: string = emptyGuid;
  @observable locales: string[] = [];
  @observable customAttributesLoading = false;
  @observable customAttributesEventDetected = false;
  @observable connectivityTypes: Option<IntConnectivityType>[] = [];
  @observable connectivityTypesLoading = false;
  @observable @persist currentConnectivityType = IntConnectivityType.Unknown;

  @observable customAttributesModalsState: CustomAttributesModalsState = {
    createCustomAttribute: false,
    deleteCustomAttribute: false,
    updateCustomAttribute: false,
  };

  @observable propertyData: Record<TPropertyName, string[]> = {
    homeNetwork: [],
    production: [],
    localized: [],
  };

  getProductsInitiated = false;

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }

  @action.bound customAttributeTableColumns =
    (): Column<IntConnectivityUnitTableDto>[] => {
      return this.rootStore.dynamicAttributeStore.customAttributeProperties
        .filter(prop => prop.type !== 'notFound')
        .map(prop => getTableColumnForProperty(prop, 'connectivityUnit'));
    };

  getPropertiesInitiated = {
    homeNetwork: false,
    production: false,
    localized: false,
  };

  @computed get properties() {
    return (propertyName: TPropertyName) => {
      if (!this.getPropertiesInitiated[propertyName]) {
        this.getProperties(propertyName);
      }

      return this.propertyData[propertyName];
    };
  }

  @action.bound changeConnectivityType(newValue: IntConnectivityType | null) {
    this.currentConnectivityType = newValue || IntConnectivityType.Unknown;
  }

  @action.bound indicateCustomAttributesEventDetected() {
    this.customAttributesEventDetected = !this.customAttributesEventDetected;
  }

  @action.bound openModal(modal: keyof CustomAttributesModalsState) {
    this.customAttributesModalsState[modal] = true;
  }

  @action.bound closeModal(modal: keyof CustomAttributesModalsState) {
    this.customAttributesModalsState[modal] = false;
  }

  @action.bound setLoading(loading: boolean) {
    this.customAttributesLoading = loading;
  }

  @action.bound setConnectivityTypesLoading(loading: boolean) {
    this.connectivityTypesLoading = loading;
  }

  @action.bound async getProperties(propertyName: TPropertyName) {
    this.getPropertiesInitiated[propertyName] = true;
    const response = await this.httpGet(
      connectivityUnitAPI.getProperties,
      propertyName
    );

    runInAction(() => {
      if (response.status === 200 && response.data) {
        this.propertyData[propertyName] = response.data;
      }
    });
  }

  @action.bound async getProducts() {
    if (!this.getProductsInitiated) {
      const response = await this.httpGet(
        productAPI.getProducts,
        undefined,
        this.setConnectivityTypesLoading
      );

      runInAction(() => {
        if (response.status === 200 && response.data) {
          this.getProductsInitiated = true;
          this.connectivityTypes = response.data?.map(connectivityType => ({
            label: i18n.t(`${'sim:connectivity_types'}.${connectivityType}`),
            value: connectivityType,
          }));

          if (
            this.connectivityTypes.length === 1 ||
            !this.connectivityTypes.some(
              c => c.value === this.currentConnectivityType
            )
          ) {
            this.changeConnectivityType(this.connectivityTypes[0].value);
          }
        }
      });
    }
  }

  @action.bound async upsertCustomAttribute(
    customAttribute: IntAttributeDto,
    type: UpsertCustomAttribute
  ) {
    const resp = await this.httpPut(
      connectivityUnitAPI.upsertConnectivityUnitAttributeValue,
      {
        params: { connectivityUnitId: this.currentUnitId, customAttribute },
        data: customAttribute,
      },
      this.setLoading
    );
    if (resp.status === 201 || resp.status === 200) {
      this.indicateCustomAttributesEventDetected();
      this.closeModal(`${type}CustomAttribute`);
      snackbar(i18n.t('asset:attributes.success.update'), {
        variant: 'success',
      });
      return true;
    } else {
      snackbar(i18n.t('asset:attributes.error.update'), {
        variant: 'error',
      });
      return false;
    }
  }
  @action.bound async downloadTableAsCsv<T>(
    endpoint: TableEndpoint<T>,
    search: IntSearchDto,
    fileNamePrefix: string = 'sim_inventory'
  ) {
    const response = await this.httpGet(endpoint, search, undefined, {
      Accept: 'text/csv',
    });
    if (response.status !== 200) {
      snackbar(i18n.t('common:error.download_rows'), {
        variant: 'error',
      });
    }

    validateResponse(response as any, fileNamePrefix, 'csv');
  }

  @action.bound
  initUnit(
    connectivityUnitId: string,
    startDateString: string | undefined | null,
    endDateString: string | undefined | null
  ) {
    this.currentUnitId = connectivityUnitId;
    this.currentUnit = new ConnectivityUnit(
      connectivityUnitId,
      startDateString,
      endDateString,
      this.rootStore
    );
  }

  @action.bound
  removeCurrentUnit() {
    this.currentUnit?.dispose();
    this.currentUnitId = emptyGuid;
    this.currentUnit = undefined;
  }
}
