import { DashboardComponentType } from 'generated';
import {
  IBaseComponentSettings,
  IComponentTypeConfig,
} from 'views/Dashboard/dashboardTypes';
import { IDataSourceSettings } from 'views/Dashboard/DataSources/dataSourceTypes';
import PreviewStep from '../PreviewStep';
import { ITableColumnSettings } from '../TableComponent/tableConfig';
import AzureMapsWidgetContainer from './AzureMapsWidgetContainer';
import GeneralSettingsStep from './SettingsSteps/GeneralSettingsStep';
import MapTableSettingsStep from './SettingsSteps/MapTableSettingsStep';

export interface IAzureMapsSettings extends IBaseComponentSettings {
  title: string;

  dataSource: IDataSourceSettings;

  style: AzureMapsStyle;
  controls: {
    geofence?: boolean;
    stylePicker?: boolean;
    zoomButtons?: boolean;
  };

  popup: IMapPopupSettings;

  policyId: string;
  groupProperties: string[];

  showTable: boolean;
  tableColumns: ITableColumnSettings[];
}

export type AzureMapsStyle =
  | 'roads'
  | 'night'
  | 'satellite'
  | 'grayscale_light'
  | 'grayscale_dark'
  | 'high_contrast_dark'
  | 'blank';

export interface IMapPopupSettings {
  rows: IMapPopupRow[];
}

export interface IMapPopupRow {
  prop: string;
}

export const azureMapsComponentConfig: IComponentTypeConfig<
  DashboardComponentType.AzureMaps,
  IAzureMapsSettings
> = {
  widgetType: DashboardComponentType.AzureMaps,
  icon: 'Maps',
  Component: AzureMapsWidgetContainer,
  settingsSteps: [
    {
      title: 'settings',
      Component: GeneralSettingsStep,
    },
    {
      title: 'azure_maps.table_settings',
      Component: MapTableSettingsStep,
    },
    {
      title: 'final_preview',
      Component: PreviewStep,
    },
  ],
  fixSettings: settings => {
    if (!settings.groupProperties) {
      settings.groupProperties = [];
    }
    if (!settings.tableColumns) {
      settings.tableColumns = [];
    }
  },
  getDefaultSettings: ({ dataSource }) => {
    return {
      position: { x: 0, y: 0, w: 12, h: 6 },
      title: '',
      dataSource: dataSource.settings,
      style: 'roads',
      controls: {},
      popup: {
        rows: [],
      },
      policyId: '',
      groupProperties: [],
      showTable: false,
      tableColumns: [],
    };
  },
};
