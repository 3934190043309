import {
  Button,
  ButtonGroup as MUIButtonGroup,
  FormControl,
  FormHelperText,
  FormLabel,
} from '@mui/material';
import { useField } from 'formik';
import React from 'react';
import { Option } from '..';

interface IProps {
  name: string;
  options: Option<any>[];
  label?: string;
}

const ButtonGroup: React.FC<IProps> = ({ name, label, options }) => {
  const [field, meta, { setValue }] = useField(name);

  return (
    <FormControl error={!!meta.error}>
      <FormLabel>{label}</FormLabel>
      <MUIButtonGroup>
        {options.map(opt => (
          <Button
            key={`${name}_${opt.value}`}
            color="primary"
            variant={field.value === opt.value ? 'contained' : 'outlined'}
            onClick={() => setValue(opt.value)}
          >
            {opt.label}
          </Button>
        ))}
      </MUIButtonGroup>
      {meta.error && <FormHelperText>{meta.error}</FormHelperText>}
    </FormControl>
  );
};

export default ButtonGroup;
