import { AutoComplete } from 'components/Inputs';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { IDataProperty } from 'views/Dashboard/DataSources/dataPropTypes';
import { IDataSourceStore } from 'views/Dashboard/DataSources/dataSourceTypes';

export function sortDataPropsByNameAndCategory(
  a: IDataProperty,
  b: IDataProperty
): number {
  const aCat = a.category?.displayName || '';
  const bCat = b.category?.displayName || '';
  if (aCat !== bCat) {
    return aCat.localeCompare(bCat);
  }
  return a.name.localeCompare(b.name);
}

interface IProps {
  dataPropertiesTitle: string;
  groupPropertiesTitle: string;
  dataSource: IDataSourceStore;
  isOptionDisabled?: (dp: IDataProperty) => boolean;
  onDataPropSelect: (dpID: string) => void;
  showGroupProps?: boolean;
  showDataProps?: boolean;
  label: string;
}

const DataPropertyList: React.FC<IProps> = ({
  dataSource,
  isOptionDisabled,
  onDataPropSelect,
  showGroupProps = true,
  showDataProps = true,
  label,
}) => {
  const options: IDataProperty[] = [];
  if (showDataProps) {
    options.push(
      ...dataSource.dataProperties.sort(sortDataPropsByNameAndCategory)
    );
  }
  if (showGroupProps) {
    options.push(
      ...dataSource.groupProperties.sort(sortDataPropsByNameAndCategory)
    );
  }

  return (
    <AutoComplete
      value=""
      blurOnSelect
      label={label}
      options={options}
      getOptionLabel={dp => dp.name}
      getOptionValue={dp => dp.id}
      groupBy={dp => dp.category?.displayName || ''}
      getOptionDisabled={dp => !!isOptionDisabled?.(dp)}
      onChange={(selectedValue: string | null) =>
        selectedValue && onDataPropSelect(selectedValue)
      }
    />
  );
};

export default observer(DataPropertyList);
