import { Column } from 'react-table';
import { CustomCell } from '../Helpers/CustomCell';
import { ICustomCellProps } from '../Helpers/CustomCellProps';

enum SensorCalibrationStatus {
  CalibrationRequired = 0,
  CalibrationOngoing = 1,
  CalibrationDone = 2,
}

const cells: Record<SensorCalibrationStatus, ICustomCellProps> = {
  [SensorCalibrationStatus.CalibrationRequired]: {
    text: 'Calibration required',
  },
  [SensorCalibrationStatus.CalibrationOngoing]: {
    text: 'Calibration ongoing',
  },
  [SensorCalibrationStatus.CalibrationDone]: {
    text: 'Calibration done',
  },
};

export function iaqSensorCalibrationStatus<T>(options: {
  id: string;
  accessor: (row: T) => number | null;
  header?: string;
  defaultShow?: boolean;
  filterable?: boolean;
}): Column<T> {
  const {
    id,
    header = 'Status',
    accessor,
    defaultShow: show = true,
    filterable,
  } = options;

  const getCellProps = (value: SensorCalibrationStatus): ICustomCellProps => {
    if (value === null || value === undefined || value < 0) {
      return { text: '', variant: 'unconfigured' };
    }

    return cells[value] || { text: value };
  };

  return {
    id,
    Header: header,
    accessor,
    resizable: false,
    maxWidth: 140,
    filterable,
    show,
    className: 'text-right',
    Cell: row => <CustomCell {...getCellProps(row.value)} index={row.index} />,
    sortMethod: (a, b) => (a ?? -1) - (b ?? -1), // Fix sorting for null values (last)
    aggregate: () => null,
  };
}
