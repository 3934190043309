import { ConfigurationSetting } from '@azure/app-configuration';
import { GetEndpoint, PutEndpoint } from 'shared/interfaces/api';
import { featureDemoData } from './demoData/featureDemoData';

interface FeatureAPI {
  updateFeature: PutEndpoint<
    string,
    ConfigurationSetting,
    ConfigurationSetting
  >;
  getFeatures: GetEndpoint<undefined, ConfigurationSetting[]>;
  getFeature: GetEndpoint<
    { key: string; onlyIfChanged: boolean },
    ConfigurationSetting
  >;
}

const baseUrl = '/api/features';

export const featureService: FeatureAPI = {
  updateFeature: {
    url: baseUrl,
    mockData: featureDemoData.updateFeature,
  },
  getFeatures: {
    url: baseUrl,
    mockData: featureDemoData.getFeatures,
  },
  getFeature: {
    url: ({ key, onlyIfChanged }) =>
      `${baseUrl}/${encodeURIComponent(key)}${
        onlyIfChanged ? '?onlyIfChanged=' + onlyIfChanged : ''
      }`,
    mockData: featureDemoData.getFeature,
  },
};
