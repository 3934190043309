import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    icon: {
      borderRadius: '100%',
      width: 20,
      minWidth: 20,
      height: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    green: {
      background: theme.palette.success.main,
      color: 'white',
    },
    yellow: {
      background: theme.palette.warning.main,
    },
    red: {
      background: theme.palette.error.main,
      color: 'white',
    },
  })
);
