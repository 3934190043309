import { Box, Button, Typography } from '@mui/material';
import LoadingOverlay from 'components/LoadingOverlay';
import { snackbar } from 'components/Snackbar';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Trans, useTranslation } from 'react-i18next';
import { dashboardApi } from 'services/dashboard.service';
import { usePost } from 'shared/api/hooks';
import { IResponse } from 'shared/interfaces/api';
import { useComponentContext } from '../contexts/componentContext';
import { useDashboardContext } from '../contexts/dashboardContext';
import ControlGroup from './ControlGroup';
import { IControlProps } from './controlProps';
import { useStyles } from './ImageUploadControl.styles';

interface IProps {
  dashboardId: string;
  dashboardComponentId: string;
  onPreviewLoaded?: (img: HTMLImageElement) => void;
}

function ImageUploadControl<TObj, TValue extends string>(
  props: IControlProps<TObj, TValue> & IProps
) {
  const { name, object, dashboardId, dashboardComponentId, onPreviewLoaded } =
    props;

  const { updateSetting } = useDashboardContext();

  const currentValue: string = (object[name] as any) || '';

  const { t } = useTranslation('dashboard');
  const [response, setResponse] = useState<IResponse<string>>();

  const classes = useStyles();

  const [uploadImage, isUploading] = usePost(dashboardApi.uploadImage);

  const { setSettingsError } = useComponentContext();
  useEffect(() => {
    setSettingsError(name, !currentValue ? 'REQUIRED' : '');
    return () => {
      setSettingsError(name, '');
    };
  }, [name, currentValue, setSettingsError]);

  const accept = ['.jpeg', '.png', '.bmp', '.gif', '.jpg'];
  const maxSize = 15 * 1024 * 1024;
  const { getRootProps, getInputProps, open } = useDropzone({
    multiple: false,
    accept,
    noClick: true,
    maxSize,
    onDropRejected: rejectedFiles => {
      rejectedFiles.forEach(file => {
        if (file.size > maxSize) {
          snackbar(t('validation:file_too_large', { maxValue: '(max 15MB)' }), {
            variant: 'error',
          });
        } else if (!accept.includes(file.type)) {
          snackbar(
            t('validation:invalid.file_format', {
              exampleFormat: '(.jpg, .png, .bmp, .gif)',
            }),
            {
              variant: 'error',
            }
          );
        } else {
          snackbar(t('validation:file_rejected'), { variant: 'error' });
        }
      });
    },
    onDrop: async acceptedFiles => {
      if (acceptedFiles.length === 0) {
        return;
      }

      const formData = new FormData();
      acceptedFiles.forEach((file, i) => {
        formData.append(`file[${i}]`, file);
      });
      const uploadResponse = await uploadImage({
        params: { dashboardId, dashboardComponentId },
        data: formData,
      });

      setResponse(uploadResponse);

      if (uploadResponse.status === 200) {
        updateSetting(object, name, uploadResponse.data);
      }
    },
  });

  return (
    <ControlGroup label={t('image.uploader.label')}>
      <section {...getRootProps()} className={classes.dropzone}>
        <LoadingOverlay isLoading={isUploading}>
          <input {...getInputProps()} />

          {response && response.status !== 200 && (
            <Typography variant="h6" color="error">
              {response.statusText || response.exceptionMessage}
            </Typography>
          )}

          {currentValue ? (
            <div>
              <img
                src={currentValue}
                className={classes.imagePreview}
                alt={t('image.uploader.label')}
                onLoad={
                  onPreviewLoaded && (e => onPreviewLoaded(e.currentTarget))
                }
              />
            </div>
          ) : (
            <>
              <Typography variant="h6" color="textSecondary">
                <Trans i18nKey="image.uploader.description" ns="dashboard" />
              </Typography>
            </>
          )}

          <Box pt={0.5}>
            <Button color="primary" variant="outlined" onClick={open}>
              {t(`image.uploader.${currentValue ? 'change' : 'choose'}`)}
            </Button>
          </Box>
        </LoadingOverlay>
      </section>
    </ControlGroup>
  );
}

export default observer(ImageUploadControl);
