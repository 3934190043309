import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './Unauthorized.styles';

const Unauthorized: React.FC<{ title?: string; message?: string }> = ({
  title,
  message,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('route');
  return (
    <>
      <Typography
        gutterBottom
        variant="h3"
        color="primary"
        className={classes.unauthorizedHeader}
      >
        {title ?? t('unauthorized.header')}
      </Typography>
      <Typography gutterBottom className={classes.unauthorizedText}>
        {message ?? t('unauthorized.text')}
      </Typography>
      <Grid container justifyContent="center" alignItems="center">
        <Grid md={12} item className={classes.unauthorizedBackground} />
      </Grid>
    </>
  );
};

export default Unauthorized;
