import { Box, IconButton } from '@mui/material';
import AtlasIcon from 'components/AtlasIcon';
import { Role } from 'components/Auth/Role';
import { useFormikContext } from 'formik';
import { IntDashboardDataStatusDto, IntPolicyDto } from 'generated';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'store';

interface IProps {
  status: IntDashboardDataStatusDto;
  isActive: boolean;
  isError: boolean;
  setTabIndex: (index: number) => void;
}

const StatusTabLabel: React.FC<IProps> = ({
  status,
  isActive,
  isError,
  setTabIndex,
}) => {
  const {
    authStore: { hasRole },
    dashboardStore: { serviceProperties },
  } = useStores();
  const { t } = useTranslation('policy');
  const { setFieldValue, values } = useFormikContext<IntPolicyDto>();

  const servicePropName =
    serviceProperties.find(
      sp => sp.servicePropertyId === status.servicePropertyId
    )?.displayName || status.servicePropertyId;

  const handleDeleteClick = () => {
    if (
      window.confirm(
        t('confirm_delete', {
          name: servicePropName,
        })
      )
    ) {
      setFieldValue(
        'statuses',
        values.statuses?.filter(s => s !== status)
      );
      setTabIndex(0);
    }
  };

  return (
    <Box display="flex" alignItems="center">
      {isError && <AtlasIcon type="Alert" color="error" size={18} />}
      {servicePropName}
      {hasRole(Role.RoleNameDeleteDashboardDataStatus) && isActive && (
        <IconButton
          size="small"
          onClick={handleDeleteClick}
          component="div"
          style={{ marginLeft: 4 }}
        >
          <AtlasIcon type="Close" size={17} />
        </IconButton>
      )}
    </Box>
  );
};

export default observer(StatusTabLabel);
