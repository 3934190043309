import { Column } from 'react-table';

export function fileDownloads<T>(options: {
  id: string;
  header: string;
  accessor: (row: T) => string[];
  handlers: (row: T) => (() => void)[];
  width?: number;
  buttonClassname?: string;
}): Column<T> {
  const { id, header, accessor, handlers, width, buttonClassname } = options;
  return {
    id,
    Header: header,
    accessor,
    Cell: row => {
      let buttons;
      const clickHandlers = handlers(row.original);

      if (Array.isArray(clickHandlers)) {
        buttons = clickHandlers.map((handler, index) => (
          <button className={buttonClassname} onClick={handler} key={index}>
            {row.value[index]}
          </button>
        ));
      }

      return buttons;
    },
    width,
  };
}
