import { Box, Link } from '@mui/material';
import i18n from 'i18next';
import React from 'react';
import { Link as RouteLink } from 'react-router-dom';
import { CellInfo, Column } from 'react-table';
import { getMinMaxFilter } from '../Helpers/getMinMaxFilter';
import { getTextFilter } from '../Helpers/getTextFilter';
import { textRight } from '../Helpers/textRight';

type AccessorType = string | string[] | number | number[];

export function link<T>(options: {
  id: string;
  header: string;
  accessor: (row: T) => AccessorType;
  to: (row: T) => string | string[];
  filter?: 'minMax' | false;
  defaultShow?: boolean;
  defaultSortDesc?: boolean;
  align?: 'left' | 'right';
  filterable?: boolean;
  sortable?: boolean;
  maxWidth?: number;
  width?: number;
  resizable?: boolean;
  styles?: React.CSSProperties;
  relative?: boolean;
}): Column<T> {
  const {
    id,
    header,
    accessor,
    to,
    filter,
    defaultShow: show = true,
    defaultSortDesc = false,
    filterable,
    sortable,
    maxWidth,
    width,
    align = 'left',
    resizable = true,
    styles,
    relative = true,
  } = options;
  const refinedFilter =
    filter === 'minMax' ? getMinMaxFilter() : getTextFilter();

  return {
    id,
    Header: <Box textAlign={align}>{header}</Box>,
    accessor,
    className: align === 'left' ? undefined : textRight,
    show,
    Cell: row => {
      if (!row.value) {
        return (
          <span className="text-muted">
            {i18n.t('not_applicable_abbreviation')}
          </span>
        );
      }

      const urls = to(row.original);
      let links;

      if (Array.isArray(urls)) {
        links = mapLinkArray(urls, relative, row);
      } else {
        links = mapLink(urls, relative, row, styles);
      }

      return links;
    },
    filterable,
    defaultSortDesc,
    sortable,
    maxWidth,
    resizable,
    Filter: filter === false ? undefined : refinedFilter,
    width,
  };
}

const mapLink = (
  url: string,
  relative: boolean,
  row: CellInfo,
  styles?: React.CSSProperties
) => {
  return (
    <Link
      component={relative ? RouteLink : 'a'}
      href={relative ? undefined : url}
      to={relative ? url : undefined}
      underline="always"
    >
      <div style={styles}>{row.value}</div>
    </Link>
  );
};

const mapLinkArray = (urls: string[], relative: boolean, row: CellInfo) => {
  return urls.map((url, index) => (
    <Link
      key={url}
      component={relative ? RouteLink : 'a'}
      href={relative ? undefined : url}
      to={relative ? url : undefined}
      underline="always"
      style={{
        marginRight: 10,
        display: 'inline-block',
      }}
      download
    >
      {row.value[index]}
    </Link>
  ));
};
