export enum InsertResult {
  UnknownError = 0,
  InsertedSuccessfully = 1,
  InvalidProperty = 2,
  UnknownProperty = 3,
}

export enum DeleteResult {
  UnknownError = 0,
  DeletedSuccessfully = 1,
  EntityNotFound = 2,
}

export enum UpdateResult {
  UnknownError = 0,
  UpdatedSuccessfully = 1,
  UpdateFailed = 2,
  NoUpdatesNeeded = 3,
  EntityNotFound = 4,
  NullValuesInUpdate = 5,
}

export enum ActionType {
  Insert = 0,
  Update = 1,
  Delete = 2,
  Read = 3,
}

export enum InsertAutomationRuleTriggerGeofenceResult {
  AutomationRuleTriggerGeofenceInsertedSuccessfully = 0,
  InvalidUnknownProperty = 1,
  MissingUnknownProperty = 2,
  UnknownError = 3,
  AutomationRuleTriggerMissing = 4,
  ValidationSuccessful = 5,
}

export enum UpdateAutomationRuleTriggerGeofenceResult {
  AutomationRuleTriggerGeofenceUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  AutomationRuleTriggerGeofenceNotFound = 2,
  NullValuesInUpdates = 3,
  UnknownError = 4,
  DatabaseSaveChangesError = 5,
}

export enum InsertPolygonResult {
  PolygonInsertedSuccessfully = 0,
  InvalidUnknownProperty = 1,
  MissingUnknownProperty = 2,
  UnknownError = 3,
  AutomationRuleTriggerGeofenceIdMissing = 4,
  ValidationSuccessful = 5,
}

export enum DeletePolygonResult {
  PolygonDeletedSuccessfully = 0,
  PolygonNotFound = 1,
  UnknownError = 2,
}

export enum UpdatePolygonResult {
  PolygonUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  PolygonNotFound = 2,
  NullValuesInUpdates = 3,
  UnknownError = 4,
}

export enum InsertAutomationRuleActionAwsIotCoreResult {
  InsertedSuccessfully = 0,
  MissingAutomationRuleActionId = 1,
  UnknownError = 2,
}

export enum UpdateAutomationRuleActionAwsIotCoreResult {
  UpdatedSuccessfully = 0,
  NotFound = 1,
  UnknownError = 2,
}

export enum DeleteAutomationRuleActionAwsIotCoreResult {
  DeletedSuccessfully = 0,
  NotFound = 1,
  UnknownError = 2,
}

export enum InsertAutomationRuleActionIotHubResult {
  InsertedSuccessfully = 0,
  MissingAutomationRuleActionId = 1,
  UnknownError = 2,
}

export enum UpdateAutomationRuleActionIotHubResult {
  UpdatedSuccessfully = 0,
  NotFound = 1,
  UnknownError = 2,
}

export enum DeleteAutomationRuleActionIotHubResult {
  DeletedSuccessfully = 0,
  NotFound = 1,
  UnknownError = 2,
}

export enum InsertDashboardResult {
  DashboardWithDisplayNameAlreadyExists = 0,
  ValidationError = 1,
  UnknownError = 2,
  InsertedSuccessfully = 3,
}

export enum UpdateDashboardResult {
  UnknownError = 0,
  UpdatedSuccessfully = 1,
  EntityNotFound = 2,
  ValidationError = 3,
}

export enum InsertAutomationRuleActionEventHubResult {
  InsertedSuccessfully = 0,
  MissingAutomationRuleActionId = 1,
  UnknownError = 2,
}

export enum UpdateAutomationRuleActionEventHubResult {
  UpdatedSuccessfully = 0,
  NotFound = 1,
  UnknownError = 2,
}

export enum DeleteAutomationRuleActionEventHubResult {
  DeletedSuccessfully = 0,
  NotFound = 1,
  UnknownError = 2,
}

export enum InsertAutomationRuleActionWebHookResult {
  AutomationRuleActionWebHookInsertedSuccessfully = 0,
  InvalidUnknownProperty = 1,
  MissingUnknownProperty = 2,
  UnknownError = 3,
  DatabaseSaveChangesError = 4,
  MissingAutomationRuleActionId = 5,
}

export enum UpdateAutomationRuleActionWebHookResult {
  AutomationRuleActionWebHookUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  AutomationRuleActionWebHookNotFound = 2,
  NullValuesInUpdates = 3,
  DatabaseUpdateError = 4,
  UnknownError = 5,
}

export enum DeleteAutomationRuleActionWebHookResult {
  AutomationRuleActionWebHookDeletedSuccessfully = 0,
  AutomationRuleActionWebHookNotFound = 1,
  UnknownError = 2,
}

export enum InsertVpnServerResult {
  VpnServerInsertedSuccessfully = 0,
  InvalidVpnServerName = 1,
  MissingVpnServerName = 2,
  InvalidUnknownProperty = 3,
  MissingUnknownProperty = 4,
  ValidationSuccessful = 5,
  DatabaseSaveChangesError = 6,
  MissingCustomer = 7,
  AccessDenied = 8,
  UnknownError = 9,
}

export enum DeleteVpnServerResult {
  VpnServerDeletedSuccessfully = 0,
  VpnServerNotFound = 1,
  UnknownError = 2,
}

export enum UpdateVpnServerResult {
  VpnServerUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  VpnServerNotFound = 2,
  NullValuesInUpdates = 3,
  UnknownError = 4,
}

export enum InsertAspectResult {
  AspectInsertedSuccessfully = 0,
  InvalidAspectName = 1,
  MissingAspectName = 2,
  InvalidUnknownProperty = 3,
  MissingUnknownProperty = 4,
  UnknownError = 5,
}

export enum UpdateAspectResult {
  AspectUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  AspectNotFound = 2,
  NullValuesInUpdates = 3,
  UnknownError = 4,
}

export enum InsertWidgetResult {
  WidgetInsertedSuccessfully = 0,
  InvalidWidgetName = 1,
  MissingWidgetName = 2,
  InvalidUnknownProperty = 3,
  MissingUnknownProperty = 4,
  UnknownError = 5,
}

export enum UpdateWidgetResult {
  WidgetUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  WidgetNotFound = 2,
  NullValuesInUpdates = 3,
  UnknownError = 4,
}

export enum InsertSubPortalResult {
  SubPortalInsertedSuccessfully = 0,
  InvalidSubPortalName = 1,
  MissingSubPortalName = 2,
  InvalidUnknownProperty = 3,
  MissingUnknownProperty = 4,
  SubPortalWithDomainAliasAlreadyExists = 5,
  UnknownError = 6,
}

export enum UpdateSubPortalResult {
  SubPortalUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  SubPortalNotFound = 2,
  NullValuesInUpdates = 3,
  SubPortalWithDomainAliasAlreadyExists = 4,
  UnknownError = 5,
}

export enum InsertInterfaceThemeResult {
  InterfaceThemeInsertedSuccessfully = 0,
  InvalidInterfaceThemeName = 1,
  MissingInterfaceThemeName = 2,
  InvalidUnknownProperty = 3,
  MissingUnknownProperty = 4,
  InterfaceThemeWithInterfaceThemeNameAlreadyExists = 5,
  UnknownError = 6,
}

export enum UpdateInterfaceThemeResult {
  InterfaceThemeUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  InterfaceThemeNotFound = 2,
  NullValuesInUpdates = 3,
  InterfaceThemeWithInterfaceThemeNameAlreadyExsist = 4,
  UnknownError = 5,
}

export enum InsertSimResult {
  SimInsertedSuccessfully = 0,
  InvalidUnknownProperty = 1,
  MissingUnknownProperty = 2,
  ImeiMissing = 3,
  SimWithIccidAlreadyExists = 4,
  ValidationSuccessful = 5,
  DatabaseSaveChangesError = 6,
  CustomerDoesNotExist = 7,
  DealerDoesNotExist = 8,
  UnknownError = 9,
}

export enum InsertUserEventSettingResult {
  UserEventSettingInsertedSuccessfully = 0,
  InvalidUnknownProperty = 1,
  MissingUnknownProperty = 2,
  ValidationSuccessful = 3,
  DatabaseSaveChangesError = 4,
  UserDoesNotExist = 5,
  UnknownError = 6,
}

export enum UpdateUserEventSettingResult {
  UserEventSettingUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  UserEventSettingNotFound = 2,
  NullValuesInUpdates = 3,
  UserDoesNotExist = 4,
  DatabaseSaveChangesError = 5,
  UnknownError = 6,
}

export enum InsertFeatureResult {
  FeatureInsertedSuccessfully = 0,
  InvalidUnknownProperty = 1,
  MissingUnknownProperty = 2,
  ValidationSuccessful = 3,
  DatabaseSaveChangesError = 4,
  UnknownError = 5,
}

export enum UpdateFeatureResult {
  FeatureUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  FeatureNotFound = 2,
  NullValuesInUpdates = 3,
  DatabaseSaveChangesError = 4,
  UnknownError = 5,
}

export enum EnableDisableUserEventSettingNotificationResult {
  UserEventSettingUpdatedSuccessfully = 0,
  UserEventSettingNotFound = 1,
  DatabaseSaveChangesError = 2,
  UnknownError = 3,
}

export enum UpdateSimResult {
  UnknownError = 0,
  SimUpdatedSuccessfully = 1,
  NoUpdatesAreNeeded = 2,
  SimNotFound = 3,
  NullValuesInUpdates = 4,
  SimWithIccidAlreadyExsist = 5,
  DatabaseSaveChangesError = 6,
  CustomerDoesNotExist = 7,
  DealerDoesNotExist = 8,
}

export enum InsertSimDataCdrResult {
  SimDataCdrInsertedSuccessfully = 0,
  InvalidUnknownProperty = 1,
  MissingUnknownProperty = 2,
  ValidationSuccessful = 3,
  DatabaseSaveChangesError = 4,
  CustomerDoesNotExist = 5,
  DealerDoesNotExist = 6,
  UnknownError = 7,
}

export enum DeleteSimDataCdrResult {
  SimDataCdrDeletedSuccessfully = 0,
  SimDataCdrNotFound = 1,
  TerminalsExist = 2,
  UnknownError = 3,
}

export enum UpdateSimDataCdrResult {
  SimDataCdrUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  SimDataCdrNotFound = 2,
  NullValuesInUpdates = 3,
  SimWithIccidAlreadyExsist = 4,
  UnknownError = 5,
  CustomerDoesNotExist = 6,
  DealerDoesNotExist = 7,
}

export enum InsertTerminalLinkResult {
  TerminalLinkInsertedSuccessfully = 0,
  MissingTerminalLinkProviderId = 1,
  InvalidUnknownProperty = 2,
  MissingUnknownProperty = 3,
  TerminalLinkAlreadyExists = 4,
  UnknownError = 5,
}

export enum UpdateTerminalLinkResult {
  TerminalLinkUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  TerminalLinkNotFound = 2,
  NullValuesInUpdates = 3,
  TerminalLinkAlreadyExists = 4,
  UnknownError = 5,
}

export enum InsertSimLinkResult {
  SimLinkInsertedSuccessfully = 0,
  MissingSimLinkProviderId = 1,
  InvalidUnknownProperty = 2,
  MissingUnknownProperty = 3,
  SimLinkAlreadyExists = 4,
  UnknownError = 5,
}

export enum UpdateSimLinkResult {
  SimLinkUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  SimLinkNotFound = 2,
  NullValuesInUpdates = 3,
  SimLinkAlreadyExists = 4,
  UnknownError = 5,
}

export enum InsertIntelligenceEventResult {
  IntelligenceEventInsertedSuccessfully = 0,
  MissingIntelligenceEventName = 1,
  InvalidUnknownProperty = 2,
  MissingUnknownProperty = 3,
  EventDefinitionMissing = 4,
  ValidationSuccessful = 5,
  DatabaseSaveChangesError = 6,
  UnknownError = 7,
}

export enum UpdateIntelligenceEventResult {
  IntelligenceEventUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  IntelligenceEventNotFound = 2,
  NullValuesInUpdates = 3,
  IntelligenceEventWithIntelligenceEventNameAlreadyExsist = 4,
  UnknownError = 5,
}

export enum InsertReportTemplateResult {
  ReportTemplateInsertedSuccessfully = 0,
  InvalidReportTemplateName = 1,
  MissingReportTemplateName = 2,
  InvalidUnknownProperty = 3,
  MissingUnknownProperty = 4,
  ReportTemplateWithReportTemplateNameAlreadyExists = 5,
  UnknownError = 6,
}

export enum UpdateReportTemplateResult {
  ReportTemplateUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  ReportTemplateNotFound = 2,
  NullValuesInUpdates = 3,
  ReportTemplateWithReportTemplateNameAlreadyExsist = 4,
  UnknownError = 5,
}

export enum InsertReportTemplateColumnResult {
  ReportTemplateColumnInsertedSuccessfully = 0,
  InvalidReportTemplateColumnName = 1,
  MissingReportTemplateColumnName = 2,
  InvalidUnknownProperty = 3,
  MissingUnknownProperty = 4,
  ReportTemplateColumnWithReportTemplateColumnNameAlreadyExists = 5,
  UnknownError = 6,
}

export enum DeleteReportTemplateColumnResult {
  ReportTemplateColumnDeletedSuccessfully = 0,
  ReportTemplateColumnNotFound = 1,
  DealersExist = 2,
  UnknownError = 3,
}

export enum UpdateReportTemplateColumnResult {
  ReportTemplateColumnUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  ReportTemplateColumnNotFound = 2,
  NullValuesInUpdates = 3,
  ReportTemplateColumnWithReportTemplateColumnNameAlreadyExsist = 4,
  UnknownError = 5,
}

export enum InsertIntelligenceEventDefinitionResult {
  IntelligenceEventDefinitionInsertedSuccessfully = 0,
  InvalidIntelligenceEventDefinitionName = 1,
  MissingIntelligenceEventDefinitionName = 2,
  InvalidUnknownProperty = 3,
  MissingUnknownProperty = 4,
  IntelligenceEventDefinitionWithIntelligenceEventDefinitionNameAlreadyExists = 5,
  UnknownError = 6,
}

export enum UpdateIntelligenceEventDefinitionResult {
  IntelligenceEventDefinitionUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  IntelligenceEventDefinitionNotFound = 2,
  NullValuesInUpdates = 3,
  IntelligenceEventDefinitionWithIntelligenceEventDefinitionNameAlreadyExsist = 4,
  UnknownError = 5,
}

export enum InsertDealerResult {
  DealerInsertedSuccessfully = 0,
  InvalidDealerName = 1,
  InvalidContactEmail = 2,
  MissingDealerName = 3,
  MissingDisplayName = 4,
  MissingContactFirstName = 5,
  MissingContactLastName = 6,
  MissingContactEmail = 7,
  InvalidUnknownProperty = 8,
  MissingUnknownProperty = 9,
  DealerWithDealerNameAlreadyExists = 10,
  ValidationSuccessful = 11,
  DatabaseSaveChangesError = 12,
  UnknownError = 13,
}

export enum UpdateDealerResult {
  DealerUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  DealerNotFound = 2,
  NullValuesInUpdates = 3,
  DealerWithDealerNameAlreadyExsist = 4,
  UnknownError = 5,
}

export enum InsertGroupResult {
  AccessDenied = 0,
  GroupInsertedSuccessfully = 1,
  MissingGroupName = 2,
  GroupWithGroupNameAlreadyExists = 3,
  ValidationSuccessful = 4,
  UnknownError = 5,
}

export enum UpdateGroupResult {
  GroupUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  GroupNotFound = 2,
  NullValuesInUpdates = 3,
  GroupWithGroupNameAlreadyExists = 4,
  UnknownError = 5,
}

export enum InsertRoleResult {
  RoleInsertedSuccessfully = 0,
  InvalidName = 1,
  InvalidDescription = 2,
  MissingName = 3,
  InvalidUnknownProperty = 4,
  MissingUnknownProperty = 5,
  RoleWithNameAlreadyExists = 6,
  AccessDenied = 7,
  ValidationSuccessful = 8,
  UnknownError = 9,
}

export enum UpdateRoleResult {
  RoleUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  RoleNotFound = 2,
  NullValuesInUpdates = 3,
  RoleWithNameAlreadyExsist = 4,
  DatabaseSaveChangesError = 5,
  UnknownError = 6,
}

export enum InsertUserResult {
  UserInsertedSuccessfully = 0,
  InvalidUsername = 1,
  InvalidPassword = 2,
  InvalidEmail = 3,
  MissingUsername = 4,
  MissingPassword = 5,
  MissingEmail = 6,
  UserWithUsernameAlreadyExists = 7,
  UserWithEmailAlreadyExsists = 8,
  MissingPhoneNumber = 9,
  MissingFirstName = 10,
  MissingLastName = 11,
  ValidationSuccessful = 12,
  DatabaseSaveChangesError = 13,
  CustomerMissing = 14,
  UnknownError = 15,
}

export enum UpdateUserResult {
  UserUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  UserNotFound = 2,
  NullValuesInUpdates = 3,
  UserWithUsernameAlreadyExsist = 4,
  UserWithEmailAlreadyExists = 5,
  UnknownError = 6,
  DatabaseSaveChangesError = 7,
  ProfilePictureError = 8,
}

export enum AddUserToGroupResult {
  UserAddedToGroups = 0,
  UserNeedsToExist = 1,
  DatabaseSaveChangesError = 2,
  GroupNotFound = 3,
}

export enum DeleteUserFromGroupResult {
  UserRemovedFromGroupSuccessfully = 0,
  UserNotFound = 1,
  DatabaseSaveChangesError = 2,
  GroupNotFound = 3,
  UserDosentExistsWithThisGroup = 4,
}

export enum InsertCustomerResult {
  CustomerInsertedSuccessfully = 0,
  InvalidCustomerName = 1,
  InvalidContactEmail = 2,
  MissingCustomerName = 3,
  MissingContactFirstName = 4,
  MissingContactLastName = 5,
  MissingContactEmail = 6,
  InvalidUnknownProperty = 7,
  MissingUnknownProperty = 8,
  CustomerWithCustomerNameAlreadyExists = 9,
  ValidationSuccessful = 10,
  DatabaseSaveChangesError = 11,
  UnknownError = 12,
  MissingDealerId = 13,
  InvalidDealerId = 14,
}

export enum UpdateCustomerResult {
  CustomerUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  CustomerNotFound = 2,
  NullValuesInUpdates = 3,
  CustomerWithCustomerNameAlreadyExsist = 4,
  DatabaseSaveChangesError = 5,
  UnknownError = 6,
  CustomerLogoError = 7,
}

export enum InsertCustomerLinkResult {
  CustomerLinkInsertedSuccessfully = 0,
  MissingCustomerLinkProviderId = 1,
  InvalidUnknownProperty = 2,
  MissingUnknownProperty = 3,
  CustomerLinkAlreadyExists = 4,
  UnknownError = 5,
  CustomerNotFound = 6,
}

export enum DeleteCustomerLinkResult {
  CustomerLinkDeletedSuccessfully = 0,
  CustomerLinkNotFound = 1,
  UnknownError = 2,
}

export enum UpdateCustomerLinkResult {
  CustomerLinkUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  CustomerLinkNotFound = 2,
  NullValuesInUpdates = 3,
  CustomerLinkAlreadyExists = 4,
  UnknownError = 5,
}

export enum InsertLinkConfigurationResult {
  LinkConfigurationInsertedSuccessfully = 0,
  MissingLinkConfigurationProviderId = 1,
  InvalidUnknownProperty = 2,
  MissingUnknownProperty = 3,
  LinkConfigurationAlreadyExists = 4,
  UnknownError = 5,
}

export enum UpdateLinkConfigurationResult {
  LinkConfigurationUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  LinkConfigurationNotFound = 2,
  NullValuesInUpdates = 3,
  LinkConfigurationAlreadyExists = 4,
  UnknownError = 5,
}

export enum InsertServiceResult {
  AccessDenied = 0,
  ServiceInsertedSuccessfully = 1,
  InvalidServiceName = 2,
  MissingServiceName = 3,
  InvalidUnknownProperty = 4,
  MissingUnknownProperty = 5,
  ServiceWithServiceNameAlreadyExists = 6,
  UnknownError = 7,
  ValidationSuccessful = 8,
  DatabaseSaveChangesError = 9,
}

export enum UpdateServiceResult {
  ServiceUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  ServiceNotFound = 2,
  NullValuesInUpdates = 3,
  ServiceWithServiceNameAlreadyExsist = 4,
  DatabaseSaveChangesError = 5,
  UnknownError = 6,
  ServicePropertyDataTypeCollision = 7,
}

export enum CreateServiceDataResult {
  ServiceDataCreatedSuccessfully = 0,
  UnknownError = 1,
}

export enum InsertSolutionResult {
  SolutionInsertedSuccessfully = 0,
  InvalidSolutionName = 1,
  InvalidUnknownProperty = 2,
  MissingMinimumServices = 3,
  MissingSolutionName = 4,
  MissingUnknownProperty = 5,
  SolutionWithSolutionNameAlreadyExists = 6,
  UnknownError = 7,
}

export enum UpdateSolutionResult {
  SolutionUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  SolutionNotFound = 2,
  NullValuesInUpdates = 3,
  SolutionWithSolutionNameAlreadyExists = 4,
  UnknownError = 5,
}

export enum BulkCommandResult {
  Successful = 0,
  Failed = 1,
}

export enum InsertSubscriptionResult {
  SubscriptionInsertedSuccessfully = 0,
  MissingSubscriptionPlanId = 1,
  InvalidUnknownProperty = 2,
  MissingUnknownProperty = 3,
  SubscriptionAlreadyExists = 4,
  UnknownError = 5,
}

export enum UpdateSubscriptionResult {
  SubscriptionUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  SubscriptionNotFound = 2,
  NullValuesInUpdates = 3,
  SubscriptionAlreadyExists = 4,
  CustomerNotExists = 5,
  UnknownError = 6,
}

export enum InsertSubscriptionPlanResult {
  SubscriptionPlanInsertedSuccessfully = 0,
  InvalidSubscriptionPlanName = 1,
  InvalidUnknownProperty = 2,
  MissingMinimumPlanItems = 3,
  MissingSubscriptionPlanName = 4,
  MissingUnknownProperty = 5,
  SubscriptionPlanWithSubscriptionPlanNameAlreadyExists = 6,
  UnknownError = 7,
}

export enum UpdateSubscriptionPlanResult {
  SubscriptionPlanUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  SubscriptionPlanNotFound = 2,
  NullValuesInUpdates = 3,
  SubscriptionPlanWithSubscriptionPlanNameAlreadyExists = 4,
  UnknownError = 5,
}

export enum InsertSubscriptionPlanItemResult {
  SubscriptionPlanItemInsertedSuccessfully = 0,
  MissingSubscriptionPlanId = 1,
  InvalidUnknownProperty = 2,
  MissingUnknownProperty = 3,
  SubscriptionPlanItemAlreadyExists = 4,
  UnknownError = 5,
}

export enum UpdateSubscriptionPlanItemResult {
  SubscriptionPlanItemUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  SubscriptionPlanItemNotFound = 2,
  NullValuesInUpdates = 3,
  SubscriptionPlanItemAlreadyExists = 4,
  UnknownError = 5,
}

export enum InsertAnalyticsCubeResult {
  AnalyticsCubeInsertedSuccessfully = 0,
  InvalidUnknownProperty = 1,
  MissingUnknownProperty = 2,
  UnknownError = 3,
}

export enum DeleteAnalyticsCubeResult {
  AnalyticsCubeDeletedSuccessfully = 0,
  AnalyticsCubeNotFound = 1,
  UnknownError = 2,
}

export enum UpdateAnalyticsCubeResult {
  AnalyticsCubeUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  AnalyticsCubeNotFound = 2,
  NullValuesInUpdates = 3,
  UnknownError = 4,
}

export enum InsertAnalyticsCubeAggregateResult {
  AnalyticsCubeAggregateInsertedSuccessfully = 0,
  InvalidUnknownProperty = 1,
  MissingUnknownProperty = 2,
  UnknownError = 3,
}

export enum UpdateAnalyticsCubeAggregateResult {
  AnalyticsCubeAggregateUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  AnalyticsCubeAggregateNotFound = 2,
  NullValuesInUpdates = 3,
  UnknownError = 4,
}

export enum InsertAnalyticsCubeGroupingResult {
  AnalyticsCubeGroupingInsertedSuccessfully = 0,
  InvalidUnknownProperty = 1,
  MissingUnknownProperty = 2,
  UnknownError = 3,
}

export enum UpdateAnalyticsCubeGroupingResult {
  AnalyticsCubeGroupingUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  AnalyticsCubeGroupingNotFound = 2,
  NullValuesInUpdates = 3,
  UnknownError = 4,
}

export enum InsertAnalyticsCubeParameterResult {
  AnalyticsCubeParameterInsertedSuccessfully = 0,
  InvalidUnknownProperty = 1,
  MissingUnknownProperty = 2,
  UnknownError = 3,
}

export enum UpdateAnalyticsCubeParameterResult {
  AnalyticsCubeParameterUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  AnalyticsCubeParameterNotFound = 2,
  NullValuesInUpdates = 3,
  UnknownError = 4,
}

export enum InsertAnalyticsCubeFilterResult {
  AnalyticsCubeFilterInsertedSuccessfully = 0,
  InvalidUnknownProperty = 1,
  MissingUnknownProperty = 2,
  UnknownError = 3,
}

export enum DeleteAnalyticsCubeFilterResult {
  AnalyticsCubeFilterDeletedSuccessfully = 0,
  AnalyticsCubeFilterNotFound = 1,
  UnknownError = 2,
}

export enum UpdateAnalyticsCubeFilterResult {
  AnalyticsCubeFilterUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  AnalyticsCubeFilterNotFound = 2,
  NullValuesInUpdates = 3,
  UnknownError = 4,
}

export enum InsertServiceVendorApiResult {
  ServiceVendorApiInsertedSuccessfully = 0,
  InvalidUnknownProperty = 1,
  MissingUnknownProperty = 2,
  UnknownError = 3,
}

export enum UpdateServiceVendorApiResult {
  ServiceVendorApiUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  ServiceVendorApiNotFound = 2,
  NullValuesInUpdates = 3,
  UnknownError = 4,
}

export enum InsertTerminalTypeResult {
  TerminalTypeInsertedSuccessfully = 0,
  InvalidUnknownProperty = 1,
  MissingUnknownProperty = 2,
  UnknownError = 3,
}

export enum UpdateTerminalTypeResult {
  TerminalTypeUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  TerminalTypeNotFound = 2,
  NullValuesInUpdates = 3,
  UnknownError = 4,
}

export enum InsertAssetTypeResult {
  AssetTypeInsertedSuccessfully = 0,
  InvalidUnknownProperty = 1,
  MissingUnknownProperty = 2,
  DisplayNameMissing = 3,
  AssetTypeNameMissing = 4,
  PatternMissing = 5,
  DatabaseSaveChangesError = 6,
  PermissionDenied = 7,
  UnknownError = 8,
  ValidationSuccessful = 9,
}

export enum DeleteAssetTypeResult {
  AssetTypeDeletedSuccessfully = 0,
  AssetTypeNotFound = 1,
  DatabaseSaveChangesError = 2,
  UnknownError = 3,
}

export enum UpdateAssetTypeResult {
  AssetTypeUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  AssetTypeNotFound = 2,
  NullValuesInUpdates = 3,
  DatabaseSaveChangesError = 4,
  UnknownError = 5,
}

export enum InsertAssetTypeConfigurationParameterResult {
  AssetTypeConfigurationParameterInsertedSuccessfully = 0,
  InvalidUnknownProperty = 1,
  MissingUnknownProperty = 2,
  UnknownError = 3,
}

export enum UpdateAssetTypeConfigurationParameterResult {
  AssetTypeConfigurationParameterUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  AssetTypeConfigurationParameterNotFound = 2,
  NullValuesInUpdates = 3,
  UnknownError = 4,
}

export enum InsertTerminalTypeSynchronizableParameterResult {
  TerminalTypeSynchronizableParameterInsertedSuccessfully = 0,
  InvalidUnknownProperty = 1,
  MissingUnknownProperty = 2,
  UnknownError = 3,
}

export enum UpdateTerminalTypeSynchronizableParameterResult {
  TerminalTypeSynchronizableParameterUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  TerminalTypeSynchronizableParameterNotFound = 2,
  NullValuesInUpdates = 3,
  UnknownError = 4,
}

export enum InsertTerminalTypeCapabilityResult {
  TerminalTypeCapabilityInsertedSuccessfully = 0,
  InvalidUnknownProperty = 1,
  MissingUnknownProperty = 2,
  UnknownError = 3,
}

export enum UpdateTerminalTypeCapabilityResult {
  TerminalTypeCapabilityUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  TerminalTypeCapabilityNotFound = 2,
  NullValuesInUpdates = 3,
  UnknownError = 4,
}

export enum InsertTerminalTypeSoftwareResult {
  TerminalTypeSoftwareInsertedSuccessfully = 0,
  InvalidUnknownProperty = 1,
  MissingUnknownProperty = 2,
  UnknownError = 3,
}

export enum UpdateTerminalTypeSoftwareResult {
  TerminalTypeSoftwareUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  TerminalTypeSoftwareNotFound = 2,
  NullValuesInUpdates = 3,
  UnknownError = 4,
}

export enum InsertAutomationRuleResult {
  AutomationRuleInsertedSuccessfully = 0,
  MissingAutomationRuleIdentifier = 1,
  InvalidUnknownProperty = 2,
  MissingUnknownProperty = 3,
  DatabaseSaveError = 4,
  UnknownError = 5,
}

export enum DeleteAutomationRuleResult {
  AutomationRuleDeletedSuccessfully = 0,
  AutomationRuleNotFound = 1,
  AutomationRuleIdMissing = 2,
  DatabaseSaveError = 3,
  UnknownError = 4,
}

export enum UpdateAutomationRuleResult {
  AutomationRuleUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  AutomationRuleNotFound = 2,
  NullValuesInUpdates = 3,
  UnknownError = 4,
}

export enum InsertAutomationRuleTriggerFilterStatusResult {
  AutomationRuleTriggerFilterStatusInsertedSuccessfully = 0,
  InvalidUnknownProperty = 1,
  MissingUnknownProperty = 2,
  UnknownError = 3,
}

export enum DeleteAutomationRuleTriggerFilterStatusResult {
  AutomationRuleTriggerFilterStatusDeletedSuccessfully = 0,
  AutomationRuleTriggerFilterStatusNotFound = 1,
  UnknownError = 2,
}

export enum UpdateAutomationRuleTriggerFilterStatusResult {
  AutomationRuleTriggerFilterStatusUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  AutomationRuleTriggerFilterStatusNotFound = 2,
  NullValuesInUpdates = 3,
  UnknownError = 4,
}

export enum InsertAutomationRuleTriggerResult {
  AutomationRuleTriggerInsertedSuccessfully = 0,
  InvalidUnknownProperty = 1,
  MissingUnknownProperty = 2,
  UnknownError = 3,
}

export enum UpdateAutomationRuleTriggerResult {
  AutomationRuleTriggerUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  AutomationRuleTriggerNotFound = 2,
  NullValuesInUpdates = 3,
  UnknownError = 4,
  FailedToUpdateGeoFence = 5,
}

export enum InsertAutomationRuleActionResult {
  AutomationRuleActionInsertedSuccessfully = 0,
  InvalidUnknownProperty = 1,
  MissingUnknownProperty = 2,
  UnknownError = 3,
  DatabaseSaveChangesError = 4,
  JsonValidationFailed = 5,
}

export enum UpdateAutomationRuleActionResult {
  AutomationRuleActionUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  AutomationRuleActionNotFound = 2,
  NullValuesInRequest = 3,
  UnknownError = 4,
  UpdateNotSuccessful = 5,
  DatabaseSaveChangesError = 6,
  JsonValidationFailed = 7,
}

export enum InsertAssetResult {
  AssetInsertedSuccessfully = 0,
  MissingAssetIdentifier = 1,
  AssetIdentifierAlreadyExists = 2,
  ValidationSuccessful = 3,
  CustomerIdMissing = 4,
  AccessDenied = 5,
  DatabaseSaveChangesError = 6,
}

export enum UpdateAssetResult {
  AssetUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  AssetNotFound = 2,
  NullValuesInUpdates = 3,
  AssetWithIdentifierAlreadyExists = 4,
  UnknownError = 5,
  CustomerDoesNotExist = 6,
  DealerDoesNotExist = 7,
  DatabaseSaveChangesError = 8,
}

export enum InsertResourceGroupResult {
  ResourceGroupInsertedSuccessfully = 0,
  InvalidUnknownProperty = 1,
  MissingUnknownProperty = 2,
  UnknownError = 3,
  AssetIdentifierAlreadyExists = 4,
  UnableToLinkConnectivityUnits = 5,
}

export enum UpdateResourceGroupResult {
  ResourceGroupUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  ResourceGroupNotFound = 2,
  NullValuesInUpdates = 3,
  UnknownError = 4,
}

export enum PatchResourceGroupResult {
  UnknownError = 0,
  ResourceGroupUpdatedSuccessfully = 1,
  UnableToLinkConnectivityUnits = 2,
  ConnectivityUnitsAlreadyLinked = 3,
}

export enum InsertAssetIdentifierResult {
  AssetIdentifierInsertedSuccessfully = 0,
  MissingAssetIdentifier = 1,
  InvalidUnknownProperty = 2,
  MissingUnknownProperty = 3,
  AssetIdentifierAlreadyExists = 4,
  UnknownError = 5,
}

export enum DeleteAssetIdentifierResult {
  AssetIdentifierDeletedSuccessfully = 0,
  AssetIdentifierNotFound = 1,
  UnknownError = 2,
}

export enum UpdateAssetIdentifierResult {
  AssetIdentifierUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  AssetIdentifierNotFound = 2,
  NullValuesInUpdates = 3,
  AssetIdentifierAlreadyExists = 4,
  UnknownError = 5,
}

export enum InsertAssetConfigurationParameterResult {
  AssetConfigurationParameterInsertedSuccessfully = 0,
  InvalidUnknownProperty = 1,
  MissingUnknownProperty = 2,
  AssetConfigurationParameterAlreadyExists = 3,
  UnknownError = 4,
}

export enum UpdateAssetConfigurationParameterResult {
  AssetConfigurationParameterUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  AssetConfigurationParameterNotFound = 2,
  NullValuesInUpdates = 3,
  AssetConfigurationParameterAlreadyExists = 4,
  UnknownError = 5,
}

export enum InsertTerminalResult {
  TerminalInsertedSuccessfully = 0,
  TerminalsInsertedSuccessfully = 1,
  MissingTerminalIdentifier = 2,
  InvalidUnknownProperty = 3,
  MissingUnknownProperty = 4,
  TerminalWithIdentifierAlreadyExists = 5,
  ValidationSuccessful = 6,
  UnknownError = 7,
  TerminalIdentifierExist = 8,
  AssetIdentifierExist = 9,
  DisplayNameMissing = 10,
  AccessDenied = 11,
}

export enum DeleteTerminalResult {
  TerminalDeletedSuccessfully = 0,
  TerminalNotFound = 1,
  TerminalHasDependendentRelations = 2,
  UnknownError = 3,
  TerminalDeleteFailed = 4,
}

export enum UpdateTerminalResult {
  TerminalUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  TerminalNotFound = 2,
  NullValuesInUpdates = 3,
  TerminalWithIdentifierAlreadyExists = 4,
  UnknownError = 5,
}

export enum InsertTerminalConfigResult {
  UnknownError = 0,
  InsertedSuccessfully = 1,
  AlreadyExist = 2,
}

export enum UpdateTerminalConfigResult {
  UnknownError = 0,
  UpdatedSuccessfully = 1,
  NotFound = 2,
}

export enum InsertTerminalIdentifierResult {
  TerminalIdentifierInsertedSuccessfully = 0,
  MissingTerminalIdentifier = 1,
  InvalidUnknownProperty = 2,
  MissingUnknownProperty = 3,
  TerminalIdentifierAlreadyExists = 4,
  UnknownError = 5,
}

export enum UpdateTerminalIdentifierResult {
  TerminalIdentifierUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  TerminalIdentifierNotFound = 2,
  NullValuesInUpdates = 3,
  TerminalIdentifierAlreadyExists = 4,
  UnknownError = 5,
}

export enum InsertTerminalFingerprintResult {
  TerminalFingerprintInsertedSuccessfully = 0,
  MissingTerminalFingerprintHash = 1,
  MissingTerminalId = 2,
  InvalidUnknownProperty = 3,
  MissingUnknownProperty = 4,
  UnknownError = 5,
}

export enum UpdateTerminalFingerprintResult {
  TerminalFingerprintUpdatedSuccessfully = 0,
  MissingTerminalId = 1,
  NoUpdatesAreNeeded = 2,
  TerminalFingerprintNotFound = 3,
  NullValuesInUpdates = 4,
  UnknownError = 5,
}

export enum UnlinkTerminalAssetResult {
  TerminalAssetUnlinkSuccessfully = 0,
  TerminalNotFound = 1,
  UnknownError = 2,
}

export enum InsertAspectToolbarActionResult {
  AspectToolbarActionInsertedSuccessfully = 0,
  MissingAspectId = 1,
  InvalidUnknownProperty = 2,
  MissingUnknownProperty = 3,
  UnknownError = 4,
}

export enum UpdateAspectToolbarActionResult {
  AspectToolbarActionUpdatedSuccessfully = 0,
  MissingAspectId = 1,
  NoUpdatesAreNeeded = 2,
  AspectToolbarActionNotFound = 3,
  NullValuesInUpdates = 4,
  UnknownError = 5,
}

export enum InsertVpnServerUserResult {
  VpnServerUserInsertedSuccessfully = 0,
  MissingVpnServerId = 1,
  InvalidUnknownProperty = 2,
  MissingUnknownProperty = 3,
  UnknownError = 4,
}

export enum UpdateVpnServerUserResult {
  VpnServerUserUpdatedSuccessfully = 0,
  MissingVpnServerId = 1,
  NoUpdatesAreNeeded = 2,
  VpnServerUserNotFound = 3,
  NullValuesInUpdates = 4,
  UnknownError = 5,
}

export enum InsertServicePropertyEnumConstantResult {
  ServicePropertyEnumConstantInsertedSuccessfully = 0,
  InvalidUnknownProperty = 1,
  MissingUnknownProperty = 2,
  UnknownError = 3,
}

export enum UpdateServicePropertyEnumConstantResult {
  ServicePropertyEnumConstantUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  ServicePropertyEnumConstantNotFound = 2,
  NullValuesInUpdates = 3,
  UnknownError = 4,
}

export enum InsertServicePropertyResult {
  ServicePropertyInsertedSuccessfully = 0,
  InvalidUnknownProperty = 1,
  MissingUnknownProperty = 2,
  UnknownError = 3,
  NameAlreadyExists = 4,
  DataTypeCollision = 5,
  ReservedName = 6,
}

export enum UpdateServicePropertyResult {
  ServicePropertyUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  ServicePropertyNotFound = 2,
  NullValuesInUpdates = 3,
  UnknownError = 4,
}

export enum InsertServiceVendorApiMappingResult {
  ServiceVendorApiMappingInsertedSuccessfully = 0,
  InvalidUnknownProperty = 1,
  MissingUnknownProperty = 2,
  UnknownError = 3,
}

export enum UpdateServiceVendorApiMappingResult {
  ServiceVendorApiMappingUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  ServiceVendorApiMappingNotFound = 2,
  NullValuesInUpdates = 3,
  UnknownError = 4,
}

export enum InsertLinkConfigurationEventDataMappingResult {
  LinkConfigurationEventDataMappingInsertedSuccessfully = 0,
  InvalidUnknownProperty = 1,
  MissingUnknownProperty = 2,
  UnknownError = 3,
}

export enum UpdateLinkConfigurationEventDataMappingResult {
  LinkConfigurationEventDataMappingUpdatedSuccessfully = 0,
  NoUpdatesAreNeeded = 1,
  LinkConfigurationEventDataMappingNotFound = 2,
  NullValuesInUpdates = 3,
  UnknownError = 4,
}

export enum InsertTerminalFirewallFilterResult {
  TerminalFirewallFilterInsertedSuccessfully = 0,
  MissingTerminalId = 1,
  InvalidUnknownProperty = 2,
  MissingUnknownProperty = 3,
  UnknownError = 4,
}

export enum UpdateTerminalFirewallFilterResult {
  TerminalFirewallFilterUpdatedSuccessfully = 0,
  MissingTerminalId = 1,
  NoUpdatesAreNeeded = 2,
  TerminalFirewallFilterNotFound = 3,
  NullValuesInUpdates = 4,
  UnknownError = 5,
}

export enum InsertConnectivityOrderResult {
  UnknownError = 0,
  MissingUnknownProperty = 1,
  ConnectivityOrderInsertedSuccessfully = 2,
}

export enum UpdateConnectivityOrderResult {
  UnknownError = 0,
  MissingUnknownProperty = 1,
  ConnectivityOrderUpdatedSuccessfully = 2,
  InvalidOrderId = 3,
}

export enum DeleteConnectivityOrderResult {
  UnknownError = 0,
  MissingUnknownProperty = 1,
  ConnectivityOrderDeletedSuccessfully = 2,
}

export enum SaveOperation {
  Create = 0,
  Edit = 1,
}

export enum ActivationMode {
  None = 0,
  FirstDataReceived = 1,
  Manual = 2,
}

export enum Country {
  Sweden = 0,
  Finland = 1,
  Norway = 2,
  Denmark = 3,
}

export enum AreaType {
  Connectivity = 0,
  BCM = 1,
}

export type AttributeValueType = boolean | number | string | null;

export enum AvailabilityType {
  None = 0,
  Environment = 1,
  File = 2,
}

export enum BulkUpdateResult {
  Unknown = 0,
  Updated = 1,
  NoChange = 2,
  Error = 3,
}

export enum ConfigurationType {
  None = 0,
  ConfigMap = 1,
  Secret = 2,
}

export enum EndpointAccessibility {
  None = 0,
  Extension = 1,
  Cloud = 2,
  Internet = 3,
}

export enum EventResourceType {
  Customer = 0,
  ConnectivityUnit = 1,
  ConnectivityUnitGroup = 2,
}

export enum GeofenceFilterType {
  CustomerId = 0,
  AssetId = 3,
}

export interface IntAccessEndpointDto {
  id: string;
  name: string;
  displayName: string;
  ipAddress: string;
  accessibility: EndpointAccessibility;
  ports: IntPortDto[];
  createdDate: Date;
}

export interface IntAclDto {
  line: string;
  source: string;
}

export enum IntActionReasonValueType {
  Using = 0,
  Expired = 1,
}

export interface IntActiveDataSessionEnterpriseGWDto {
  enterpriseGatewayUuid: string;
  enterpriseFilterId: string;
}

export interface IntActiveDataSessionGeneralDto {
  started?: Date;
  lastUpdated?: Date;
  ipAddress: string;
  imsi: string;
  msisdn: string;
  apn: string;
  primaryDns: string;
  secondaryDns: string;
  iPv6DNSNameServers: string;
  ratType: string;
  blocked?: boolean;
  terminating?: boolean;
  homeMCCMNC: string;
  visitedMCCMNC: string;
}

export interface IntActiveDataSessionMNOGWDto {
  mnoGatewayUuid: string;
  mnoFilterId: string;
}

export interface IntActiveDataSessionRadiusDto {
  acctSessionId: string;
  sessionTimeout: number;
  idleTimeout: number;
  nasIpAddress: string;
  nasIdentifier: string;
}

export interface IntActiveDataSessionUsageDto {
  totalBytes: number;
  inputBytes: number;
  inputPackets: number;
  outputBytes: number;
  outputPackets: number;
  sessionRuntime: number;
  timestamp?: Date;
}

export interface IntActiveDataSessionUUIDDto {
  apnHubSessionUUID: string;
  apnHubDeviceUUID: string;
}

export interface IntActiveSessionsTableRequestDto {
  customerId?: string;
  fromDate?: Date;
  toDate?: Date;
  search: string | null;
  orderBy: string | null;
  page: number | null;
  pageSize: number | null;
  orderDesc: boolean | null;
  filters: string | null;
}

export interface IntActiveSessionTableDto {
  connectivityUnitId: string;
  identifier: string;
  label: string;
  session: IntDataSessionDetailsDto;
}

export interface IntAddGroupMembersRequestDto {
  forceTransferToNewGroup: boolean;
  connectivityUnitIds: string[];
  forceImmediateChange: boolean;
}

export interface IntAddPhoneNumberDto {
  phoneNumber: string;
}

export interface IntAddSharedAssetsRequestDto {
  customerIds: string[];
  assetIds: string[];
}

export interface IntAnalyticsCubeAggregateDto {
  analyticsCubeAggregateId: string;
  displayName: string;
  unit: string;
  computionPropertyId?: string;
  computionPairPropertyId?: string;
  valueMin?: number;
  valueMax?: number;
  valueType: AnalyticsCubeAggregateValueType;
  servicePropertyComputionType?: ServicePropertyComputionType;
  servicePropertyPairComputionType?: ServicePropertyPairComputionType;
  servicePropertyRepresentationType?: ServicePropertyRepresentationType;
  servicePropertyPairRepresentationType?: ServicePropertyRepresentationType;
  computionService: IntAtlasServiceDto;
  computionServiceProperty: IntAtlasServicePropertyDto;
  computionPairServiceProperty: IntAtlasServicePropertyDto;
}

export interface IntAnalyticsCubeDataSpecificationRequestDto {
  parameters: { [key: string]: string };
  timeZone: string;
  filters: IntServiceDataSpecificationFilterDto[];
  columns: IntServiceDataSpecificationColumnDto[];
}

export interface IntAnalyticsCubeDetailsDto {
  aggregates: IntAnalyticsCubeAggregateDto[];
  parameters: IntAnalyticsCubeParameterDto[];
  groupings: IntAnalyticsCubeGroupingDto[];
  filters: IntAnalyticsCubeFilterDto[];
  analyticsCubeId: string;
  analyticsCubeName: string;
}

export interface IntAnalyticsCubeDto {
  analyticsCubeId: string;
  analyticsCubeName: string;
}

export interface IntAnalyticsCubeFilterDto {
  analyticsCubeFilterId: string;
  displayName: string;
  type: AnalyticsCubeFilterType;
  servicePropertyFilterType: AnalyticsCubeServicePropertyFilterType;
  filterValue: string;
  filterIntervalValue: string;
  filterServiceId?: string;
  filterServicePropertyId?: string;
  filterService: IntAtlasServiceDto;
  filterServiceProperty: IntAtlasServicePropertyDto;
}

export interface IntAnalyticsCubeGroupingDto {
  analyticsCubeGroupingId: string;
  displayName: string;
  type: AnalyticsCubeGroupingType;
  groupingRegisterDateResolution?: AnalyticsCubeRegisterDateGroupingResolution;
  fixedDataPointInterval?: boolean;
}

export interface IntAnalyticsCubeOutputResult {
  aggregateId?: string;
  groupedData: { [key: string]: any };
}

export interface IntAnalyticsCubeParameterDto {
  analyticsCubeParameterId: string;
  displayName: string;
  defaultValue: string;
  type: AnalyticsCubeParameterType;
  filterId?: string;
  groupingId?: string;
}

export interface IntApiTicketDto {
  api: string;
  subject: string;
  description: string;
  tscId: string;
  contact: IntContactDto;
  assetId: string;
  contactPersonIsNotMe: boolean;
  attachments: IntAttachmentDto[];
  severity: SeverityType;
  additionalEmails: string[];
  additionalContacts: IntContactDto[];
}

export interface IntApnHubFirewallRuleDto {
  destination: string[];
  source: string[];
  protocol: string;
  port: number[];
  action: FirewallRuleAction;
  name: string;
}

export interface IntApnHubProfileFirewallRules {
  outbound: IntApnHubFirewallRuleDto[];
  inbound: IntApnHubFirewallRuleDto[];
  breakout: IntApnHubFirewallRuleDto[];
}

export interface IntAreaDto {
  areaId: string;
}

export interface IntAssetIdentifierDto {
  assetIdentifierId: string;
  assetId?: string;
  identifier: string;
  type?: AssetIdentifierType;
}

export interface IntAssetPropertiesDto {
  assetId: string;
  displayName: string;
  connected: ConnectedStatus;
  assetTypeId?: string;
  dealerId?: string;
  customerId?: string;
  customerName: string;
  make: string;
  model: string;
  type: string;
  lastConnected?: Date;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface IntAssetSearchDto {
  assetId: string;
  search: string | null;
  orderBy: string | null;
  page: number | null;
  pageSize: number | null;
  orderDesc: boolean | null;
  filters: string | null;
}

export interface IntAssetTableDto {
  assetId: string;
  displayName: string;
  serialNumber?: string | null;
  imei?: string | null;
  connected: ConnectedStatus;
  assetTypeId?: string;
  dealerId?: string;
  customerId?: string;
  customerName: string;
  make: string;
  model: string;
  type: string;
  createdAt?: Date;
  updatedAt?: Date;
  lastConnected?: Date;
  installationDate?: Date;
  address: string;
  serviceMode?: boolean;
  referenceLabel: string;
  state: AssetState;
  powerInfo?: IntTerminalPowerInfo | null;
  assetAttributes?: { [key: string]: any } | null;
}

export interface IntAtlasServiceDto {
  serviceId: string;
  displayName: string;
  icon: string;
  properties: IntAtlasServicePropertyDto[];
}

export interface IntAtlasServicePropertyDto {
  servicePropertyId: string;
  servicePropertyName: string;
  displayName: string;
  displayNames?: { [key: string]: string };
  storageColumnName: string;
  displayFormat?: ServicePropertyDisplayFormat;
  dataType: ServicePropertyDataType;
  unit: string;
  dataRangeMin?: number;
  dataRangeMax?: number;
  conversion?: number;
  imperialConversion?: number;
}

export interface IntAttachmentDto {
  id: string;
  fileName: string;
  size: number;
  content: string;
  mimeType: string;
}

export interface IntAttributeDefinitionDto {
  id?: string | null;
  name: string;
  description: string;
  dataType: DynamicAttributeDataType;
  isReadOnly: boolean;
  isGlobal: boolean;
  customerId?: string | null;
  minValue?: number | null;
  maxValue?: number | null;
  resourceType: IntAttributeResourceType;
}

export interface IntAttributeDto {
  id?: string | null;
  definitionId?: string | null;
  resourceId?: string | null;
  resourceType: IntAttributeResourceType;
  key: string;
  values: AttributeValueType[] | null;
}

export enum IntAttributeResourceType {
  ConnectivityUnit = 1,
  Asset = 2,
}

export enum IntAutomationActionConnectivityUnitEventType {
  Unknown = 0,
  Deactivate = 1,
  BlockDataAccess = 4,
}

export enum IntAutomationActionType {
  NoAction = 0,
  SendSms = 1,
  SendEmail = 2,
  SendConnectivtyUnitChangeEvent = 4,
  CreateEvent = 5,
  Webhook = 6,
  EventHub = 7,
  AwsIoTCore = 8,
  IoTHub = 9,
  GoogleCloudPubSub = 10,
}

export interface IntAutomationAssetUnitEntityDto {
  entityId: string;
  label: string;
  triggerStatus: IntAutomationTriggerStatus;
  filterEntityType: IntFilterEntityType;
  lastEvaluated?: Date;
  lastTriggered?: Date;
  lastExecuted?: Date;
}

export interface IntAutomationConnectivityUnitEntityDto {
  identifier: string;
  group: string;
  groupId?: string;
  entityId: string;
  label: string;
  triggerStatus: IntAutomationTriggerStatus;
  filterEntityType: IntFilterEntityType;
  lastEvaluated?: Date;
  lastTriggered?: Date;
  lastExecuted?: Date;
}

export interface IntAutomationEntityBase {
  entityId: string;
  label: string;
  triggerStatus: IntAutomationTriggerStatus;
  filterEntityType: IntFilterEntityType;
  lastEvaluated?: Date;
  lastTriggered?: Date;
  lastExecuted?: Date;
}

export interface IntAutomationRuleActionDto {
  id?: string | null;
  actionType?: IntAutomationActionType | null;
  sendTo: string[];
  subjectTemplate: string;
  bodyTemplate: string;
  useDefaultBody: boolean;
  eventBody: string;
  eventBodyTranslations?: { [key: string]: string };
  connectivityUnitEventType?: IntAutomationActionConnectivityUnitEventType | null;
  notifyConnectivityUnitActionByEmail: boolean;
  webhook?: IntWebhookDto;
  eventHub?: IntEventHubDto;
  awsIoTCore?: IntAwsIoTCoreDto;
  ioTHub?: IntIoTHubDto;
  googleCloudPubSub?: IntGoogleCloudPubSubDto;
}

export interface IntAutomationRuleDetailsDto {
  created?: Date;
  createdBy: string;
  lastEvaluated?: Date;
  lastTriggered?: Date;
  lastExecuted?: Date;
  activeSince?: Date;
  numberOfEntities: number;
  trigger: IntAutomationTriggerType;
  condition: IntTriggerConditionType;
  level: string;
  actions: IntAutomationRuleActionDto[];
  evaluationPeriod: IntTriggerConditionTimeInterval;
  serviceName: string;
  propertyName: string;
  triggerUnitValue: string;
  triggerTypeValue?: number;
  triggerListValue: string[];
  propertyUnitType: string;
  disableOnExecution: boolean;
}

export interface IntAutomationRuleDto {
  id: string;
  status: IntAutomationRuleStatus;
  displayName: string;
  customerId: string;
  ruleType: IntAutomationRuleType;
  filterEntityType: IntFilterEntityType;
  automationRuleFilterType: IntAutomationRuleFilterType;
  filterIds: IntFilterIdDto[];
  trigger: IntAutomationTriggerDto;
  action: IntAutomationRuleActionDto;
  lastEvaluated?: Date;
  lastTriggered?: Date;
  disableOnExecution: boolean;
}

export interface IntAutomationRuleEntityDto {
  entityId: string;
}

export enum IntAutomationRuleEntityType {
  Assets = 0,
  Devices = 1,
  SIM = 2,
}

export interface IntAutomationRuleFilterDto {
  automationFilterType: IntAutomationRuleFilterType;
  entites: IntAutomationRuleEntityDto[];
}

export enum IntAutomationRuleFilterType {
  Customer = 0,
  Group = 1,
  Entity = 2,
}

export enum IntAutomationRuleStatus {
  Enabled = 0,
  Disabled = 1,
  Provisioning = 2,
  Error = 3,
}

export interface IntAutomationRuleStatusCountDto {
  status: IntAutomationRuleStatus;
  count?: number;
}

export interface IntAutomationRuleSubscriptionTableDto {
  automationRuleId: string;
  displayName: string;
  activated: boolean;
  lastRun?: Date;
  availableSubscriptions: IntAutomationActionType[];
  activeSubscriptions: IntAutomationActionType[];
}

export interface IntAutomationRuleTableDto {
  id: string;
  status: IntAutomationRuleStatus;
  displayName: string;
  filterEntityType: IntFilterEntityType;
  activeSince?: Date;
  lastEvaluated?: Date;
  lastTriggered?: Date;
  lastExecuted?: Date;
}

export enum IntAutomationRuleType {
  EntityChanged = 0,
  ServiceDataChanged = 1,
}

export interface IntAutomationTriggerDto {
  id?: string | null;
  triggerType: IntAutomationTriggerType | null;
  triggerCondition: IntTriggerConditionDto;
}

export enum IntAutomationTriggerStatus {
  NotTriggered = 0,
  Triggered = 1,
}

export enum IntAutomationTriggerType {
  Threshold = 0,
  PropertyChanged = 1,
  TimeSinceLastServiceData = 2,
  Service = 3,
}

export interface IntAwsIoTCoreDto {
  payloadType: AutomationRuleActionAwsIotCorePayloadType;
  payload: string;
  topic: string;
  certificateIdentifier: string;
  endpoint: string;
  thingId: string;
}

export interface IntBadResponseDto {
  requestObject?: string;
  errorMessage: string;
  errorCode?: number;
}

export interface IntBaseConnectivityConfigurationDto {
  customerId: string;
  type: IntConfigurationType;
  configurationIdentifier: string;
  keyVaultCredentials: IntKeyVaultCredentialsDto | null;
}

export interface IntBaseTicketDto {
  subject: string;
  description: string;
  tscId: string;
  contact: IntContactDto;
  assetId: string;
  contactPersonIsNotMe: boolean;
  attachments: IntAttachmentDto[];
  severity: SeverityType;
  additionalEmails: string[];
  additionalContacts: IntContactDto[];
}

export interface IntBulkDeleteCustomAttributeRequestDto {
  ids: string[];
  customAttributeTypeIds: string[];
}

export interface IntBulkSubscriptionStatusResultDto {
  success: string[];
  errors: IntBadResponseDto[];
}

export interface IntBulkUpdateCustomAttributeRequestDto {
  ids: string[];
  customAttributeKeyValuePairs: { [key: string]: string[] };
}

export interface IntCdrDataDto {
  connectivityUnitId?: string;
  customerId?: string;
  source: string;
  date: Date;
  imsi: string;
  msisdn: string;
  iccId: string;
  identifier: string;
  label: string;
  groupLabel: string;
  connectivityUnitGroupId?: string;
  country: string;
  mobileCountryCode: string;
  downloadedData: number;
  uploadedData: number;
  totalData: number;
}

export interface IntCdrSessionHistoryTableDto {
  connectivityUnitLabel: string;
  connectivityUnitIdentifier: string;
  connectivityUnitGroupName: string;
  imsi: string;
  startDate?: Date;
  endDate?: Date;
  sessionTime: number;
  downloadedData: number;
  uploadedData: number;
  totalData: number;
  visitedMccMncs: string;
  connectivityUnitId: string;
  connectivityUnitGroupId: string;
}

export interface IntCdrSessionHistoryTableRequestDto {
  customerId?: string;
  startDate?: Date;
  endDate?: Date;
  search: string | null;
  orderBy: string | null;
  page: number | null;
  pageSize: number | null;
  orderDesc: boolean | null;
  filters: string | null;
}

export interface IntCdrSmsDto {
  incomingMessages: number;
  outgoingMessages: number;
  source: string;
  date: Date;
  imsi: string;
}

export interface IntChangeLabelDto {
  label: string;
}

export interface IntConfigurationDto {
  id: string;
  name: string;
  type: ConfigurationType;
  data: { [key: string]: string };
}

export interface IntConfigurationReferenceDto {
  configuration: IntConfigurationDto;
  availability: AvailabilityType;
  type: ConfigurationType;
  mountPath: string;
}

export enum IntConfigurationType {
  Unknown = 0,
  GIC = 1,
  MIC = 2,
}

export interface IntConfirmEmailDto {
  userId: string;
  code: string;
  passwordCreateTypeIsEmail: boolean;
}

export enum IntConfirmEmailResult {
  Failure = 0,
  Success = 1,
  InvalidToken = 2,
  TokenExpired = 3,
}

export interface IntConnectivityConfigurationDto {
  id: string;
  customerId: string;
  type: IntConfigurationType;
  configurationIdentifier: string;
  keyVaultCredentials: IntKeyVaultCredentialsDto | null;
}

export interface IntConnectivityGroupBaseRequestDto {
  connectivityUnitIds: string[];
  forceImmediateChange: boolean;
}

export interface IntConnectivityOrderContactDto {
  contactId?: string;
  name: string;
  email: string;
  phone: string;
}

export interface IntConnectivityOrderDetailsDto {
  contactEmail: string;
  contactPhone: string;
  deliveryAddress: string;
  initialState: string;
  orderDate: Date;
  serviceRequestId: string;
  packageMarkings: string;
  formSpecification: string;
  subscriptionPackage: string;
  statusUpdated?: Date;
}

export interface IntConnectivityOrderDto {
  id: string;
  orderNumber: number;
  status: ConnectivityOrderStatus;
  quantity: number;
  preferredDeliveryDate?: Date;
  formSpecification: string;
  packageMarkings: string;
  customerId: string;
  customer: string;
}

export interface IntConnectivityOrderLinkLandingResponseDto {
  customerName: string;
  customerLinkProviderId: string;
}

export interface IntConnectivityOrderLinkRequestDto {
  customerId: string;
  connectivityOrderLinkId?: string;
  linkType: IntConnectivityOrderType;
  identifier: string;
  dcpCompanyId: string;
}

export interface IntConnectivityOrderRequestDto {
  customerId?: string;
  customerName: string;
  quantity?: number;
  agreementId?: string;
  rateplanId?: string;
  agreementProfileId?: string;
  initialState: string;
  deliveryAddress: string;
  packageMarkings: string;
  contact: IntConnectivityOrderContactDto;
  preferredDeliveryDate?: Date;
}

export enum IntConnectivityOrderType {
  WaitingForIdentifier = 0,
  DcpServicerequestId = 1,
}

export interface IntConnectivityResourceGroupDto {
  label: string;
  description: string;
  connectivityUnitIds: string[];
}

export interface IntConnectivitySessionStartedReportDto {
  customerId?: string;
  imsi: string;
  imei: string;
  createdSessionsCount?: number;
  currentLocation: string;
  date?: Date;
}

export interface IntConnectivityTicketDto {
  connectivity: string;
  data: boolean;
  sms: boolean;
  affectedSims: number;
  lastWorked: string;
  lastWorkingDate?: Date;
  location: string;
  deviceType: string;
  affectedId: string;
  objectType: ConnectivityTicketObjectType;
  subject: string;
  description: string;
  tscId: string;
  contact: IntContactDto;
  assetId: string;
  contactPersonIsNotMe: boolean;
  attachments: IntAttachmentDto[];
  severity: SeverityType;
  additionalEmails: string[];
  additionalContacts: IntContactDto[];
}

export enum IntConnectivityType {
  Unknown = 0,
  GIC = 1,
  MIC = 2,
  LIC = 3,
}

export interface IntConnectivityUnitAccessDetailsDto {
  apnHubDeviceUuid: string;
  staticIP: string;
  blockDataTraffic?: boolean;
  lastSessionStarted?: Date;
  firstSessionStarted?: Date;
  activeSession: IntDataSessionDetailsDto;
  dataProfile: IntProfileDto;
}

export interface IntConnectivityUnitAgreementDto {
  connectivityUnitAgreementId?: string;
  customerId?: string;
  dcpId: string;
  displayName: string;
  leadOperatorName: string;
  formSpecification: IntConnectivityUnitAgreementFormSpecificationDto;
  profileSpecifications: IntConnectivityUnitAgreementProfileSpecificationDto[];
  rateplans: IntRateplanDto[];
}

export interface IntConnectivityUnitAgreementFormSpecificationDto {
  description: string;
  earliestDeliveryDate?: number;
  lowOrderThreshold?: number;
  minimumOrderVolume?: number;
  orderIncrement?: number;
}

export interface IntConnectivityUnitAgreementProfileSpecificationDto {
  connectivityUnitAgreementProfileSpecificationId?: string;
  identifier: string;
  description: string;
}

export interface IntConnectivityUnitCountByStatusRequestDto {
  requestedStatuses: IntStatus[];
  connectivityType?: IntConnectivityType | null;
}

export interface IntConnectivityUnitDataProfileDto {
  uuid?: string;
  name: string;
  globalProperty?: boolean;
  enterpriseUuid?: string;
}

export interface IntConnectivityUnitDto {
  connectivityUnitId: string;
  type: ConnectivityUnitType;
  identifier: string;
  label: string;
  lastActive: Date;
  imei: string;
  localizedStatus: ConnectivityUnitLocalizedStatus;
  activationStatus: ConnectivityUnitProfileActivationStatus;
  roaming: boolean;
  formFactor: string;
  simCardDescription: string;
  customer: IntCustomerDto;
  rateplanDisplayName: string;
  resourceGroupId: string;
  resourceGroupName: string;
  enabledProfile: IntConnectivityUnitProfileDto;
  profiles: IntConnectivityUnitProfileDto[];
  testModeStatus: TestModeStatus;
  testModeExpiredDate?: Date;
  connectivityProductType: IntConnectivityType;
  customAttributes?: IntAttributeDto[] | null;
}

export interface IntConnectivityUnitEventDetailsDto {
  triggerType: IntEventTriggerType;
  triggeredById: string;
  triggeredByLabel: string;
  properties: { [key: string]: string };
}

export interface IntConnectivityUnitEventDto {
  id: string;
  connectivityUnitId: string;
  category: IntEventCategory;
  code: IntSimEventCode;
  state: IntEventState;
  timestamp?: Date;
}

export interface IntConnectivityUnitGetGroupTableDto {
  startDate?: Date;
  endDate?: Date;
  search: string | null;
  orderBy: string | null;
  page: number | null;
  pageSize: number | null;
  orderDesc: boolean | null;
  filters: string | null;
}

export interface IntConnectivityUnitGroupCreator {
  userId?: string;
  firstName: string;
  lastName: string;
}

export interface IntConnectivityUnitGroupDetailsDto {
  topDataConsumingConnectivityUnits: IntUnitMinimalConsumptionDto[];
  topSmsConsumingConnectivityUnits: IntUnitMinimalConsumptionDto[];
  connectivityUnitStatuses: IntConnectivityUnitStatusCountDto[];
  id: string;
  label: string;
  identifier?: number;
  memberCount?: number;
  totalData?: number;
  totalSms?: number;
  lastActive?: Date;
  createDate: Date;
  creator: IntConnectivityUnitGroupCreator;
  profileName: string;
  customerId: string;
}

export interface IntConnectivityUnitGroupDto {
  totalData?: number;
  totalSms?: number;
  lastActive?: Date;
  id: string;
  label: string;
  identifier: number;
  memberCount: number;
  customerId: string;
  customerName: string;
}

export enum IntConnectivityUnitGroupLinkAction {
  Link = 0,
  Unlink = 1,
}

export interface IntConnectivityUnitGroupTableDto {
  id: string;
  label: string;
  identifier?: number;
  memberCount?: number;
  totalData?: number;
  totalSms?: number;
  lastActive?: Date;
  createDate: Date;
  creator: IntConnectivityUnitGroupCreator;
  profileName: string;
  customerId: string;
}

export interface IntConnectivityUnitProfileDto {
  connectivityUnitProfileId: string;
  msisdn: string;
  imsi: string;
  iccId: string;
  pin1: string;
  pin2: string;
  puk1: string;
  puk2: string;
  enabled: boolean;
  bootstrap: boolean;
  operatorName: string;
  currentLocation: string;
  currentOperator: string;
  voice: boolean;
  subscriptionPackageName: string;
  localeName: string;
  localeId: string;
  downloadStatus: ConnectivityUnitProfileDownloadStatus;
  activationStatus: ConnectivityUnitProfileActivationStatus;
  countryName: string;
  apns: string;
}

export interface IntConnectivityUnitStatusCountDto {
  connectivityUnitStatus: IntStatus;
  count: number;
}

export enum IntConnectivityUnitStatusValueType {
  Unknown = 0,
  Activated = 1,
  Deactivated = 2,
  Paused = 3,
  Terminated = 4,
  InProgress = 5,
}

export interface IntConnectivityUnitTableBulkSelectFilterDto {
  startDate?: Date;
  endDate?: Date;
  orderBy: string | null;
  orderDesc: boolean | null;
}

export interface IntConnectivityUnitTableDto {
  connectivityUnitId: string;
  status: ConnectivityUnitStatus;
  label: string;
  identifier: string;
  type: ConnectivityUnitType;
  imsi: string;
  imei: string;
  msisdn: string;
  iccId: string;
  currentLocation: string;
  localizedTo: string;
  lastActive?: Date;
  resourceGroupId: string;
  resourceGroupName: string;
  uploadedData: number;
  downloadedData: number;
  totalData: number;
  incomingMessages: number;
  outgoingMessages: number;
  totalMessages: number;
  customer: IntCustomerDto;
  testModeStatus: TestModeStatus;
  testModeExpiredDate?: Date;
  connectivityProductType: IntConnectivityType;
  customAttributes?: { [key: string]: any } | null;
  homeNetwork: string;
  production: string;
}

export interface IntConnectivityUnitTableRequestDto {
  resourceGroupId?: string;
  customerId?: string;
  startDate?: Date;
  endDate?: Date;
  search: string | null;
  orderBy: string | null;
  page: number | null;
  pageSize: number | null;
  orderDesc: boolean | null;
  filters: string | null;
}

export interface IntContactDto {
  contactId: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

export interface IntCopyProfileDto {
  profileId: string;
  name: string;
}

export interface IntCountryDataConsumptionDto {
  customerId?: string;
  country: string;
  countryISO3166: string;
  downloaded: number;
  uploaded: number;
  total: number;
}

export interface IntCreateAccessEndpointRequestDto {
  displayName: string;
  workloadId: string;
  accessibility: EndpointAccessibility;
  ports: IntPortDto[];
}

export interface IntCreateAssetDto {
  displayName: string;
}

export interface IntCreateAssetIdentifier {
  assetId: string;
  identifier: string;
  type: AssetIdentifierType;
}

export interface IntCreateAutomationRuleDto {
  id?: string | null;
  active: boolean;
  displayName: string;
  customerId: string;
  ruleType: IntAutomationRuleType;
  automationRuleFilterType: IntAutomationRuleFilterType;
  filterEntityType: IntFilterEntityType;
  filterIds: IntFilterIdDto[];
  trigger: IntAutomationTriggerDto;
  action: IntAutomationRuleActionDto;
  actions: IntAutomationRuleActionDto[];
  disableOnExecution: boolean;
}

export interface IntCreateConfigurationRequestDto {
  name: string;
  type: ConfigurationType;
  data: { [key: string]: string };
}

export interface IntCreateCustomerRequest {
  customerId: string;
  customerName: string;
  fullCompanyName: string;
  dealerName: string;
  dealerId: string;
  contactFirstName: string;
  contactLastName: string;
  contactTitle: string;
  contactEmail: string;
  country: string;
  currency: string;
  storageTenant: StorageTenant;
  customerLanguages: string[];
}

export interface IntCreateDashboardRequestDto {
  customerId: string;
  displayName: string;
  navigationId?: string;
  parentMenuItemId?: string;
  icon: string;
}

export interface IntCreateDealerRequest {
  dealerId: string;
  dealerName: string;
  fullCompanyName: string;
  country: string;
  city: string;
  zipCode: string;
  address: string;
  email: string;
  phone: string;
  managingDealerId?: string;
}

export interface IntCreateDeviceRequestDto {
  name: string;
  profileId: number;
  supplierId: number;
  placeId: number;
  siteId: number;
  closestSwitchId: number;
  ipAddress: string;
  mac: string;
  address: string;
  building: string;
  floor: string;
  room: string;
  rack: string;
}

export interface IntCreateDocumentRequestDto {
  name: string;
  fileName: string;
  fileData: string;
  locationId?: number;
  siteId?: number;
  assetId?: number;
  clientDeviceId?: number;
}

export interface IntCreateExtensionRequestDto {
  displayName: string;
}

export interface IntCreateGroupDto {
  label: string;
  description: string;
  connectivityUnitIds: string[];
}

export interface IntCreateGroupRequestDto {
  groupId: string;
  groupName: string;
  globallyAvailable?: boolean;
  dealerId?: string;
  description: string;
}

export interface IntCreateIdentifierDto {
  identifier: string;
  type: TerminalIdentifierType;
}

export interface IntCreateIntelligenceEventRequestDto {
  automationRuleId: string;
  automationRuleActionId: string;
  customerId: string;
  assetId?: string;
  terminalId?: string;
}

export interface IntCreateLegacyAutomationRuleDto {
  id?: string | null;
  displayName: string;
  ruleType: IntAutomationRuleType;
  assetFilters: IntServiceDataSpecificationFilterDto[];
  triggerServiceData: IntTriggerDto;
  active: boolean;
  customerId: string;
  actions: IntAutomationRuleActionDto[];
  filterEntityType: IntFilterEntityType;
}

export interface IntCreateNavigationDto {
  customerId: string;
  displayName: string;
  active: boolean;
}

export interface IntCreatePlaceRequestDto {
  address: string;
  area: string;
  city: string;
  propertyNumber: string;
  name: string;
  siteId: number;
  longitude: number;
  latitude: number;
}

export interface IntCreateProductTemplateDto {
  displayName: string;
  linkServiceName: string;
  description: string;
}

export interface IntCreateProfileDto {
  profileId?: string;
  name: string;
  nameServers: string[];
  defaultVpn?: string;
  stickyIpAllocation: boolean;
  outboundFirewallRules: IntCreateProfileFirewallJsonPropertiesDto[];
  breakoutFirewallRules: IntCreateProfileFirewallJsonPropertiesDto[];
}

export interface IntCreateProfileFirewallJsonPropertiesDto {
  name: string;
  properties: string;
}

export interface IntCreateProfileRequestDto {
  name: string;
  category: string;
  networkId: number;
}

export interface IntCreateReportDefinitionDto {
  name: string;
  description: string;
  author: string;
  createdAt?: Date;
  areaType: AreaType;
  customerId: string;
  inputTypeFilter: IntInputTypeFilter[];
  outputTypeFilter: IntOutputTypeFilter[];
}

export interface IntCreateServiceDto {
  serviceName: string;
  globallyAvailable: boolean;
  customerId?: string;
}

export interface IntCreateSubPortalRequestDto {
  subPortalId: string;
  subPortalName: string;
  domainAlias: string;
  dealerId: string;
}

export interface IntCreateSupplierRequestDto {
  name: string;
  city: string;
  contactInfo: string;
}

export interface IntCreateTerminalDto {
  customerId: string;
  displayName: string;
  identifiers: IntCreateIdentifierDto[];
}

export interface IntCreateTerminalFingerprintDto {
  fingerprint: string;
  validFrom: Date;
  validUntil: Date;
}

export interface IntCreateTerminalIdentifier {
  terminalId: string;
  identifier: string;
  type: TerminalIdentifierType;
}

export interface IntCreateUiConfigurationRequestDto {
  displayName: string;
  subPortalId?: string;
  active: boolean;
  settings: IntUiConfigurationSettingDto[];
}

export interface IntCreateUserDto {
  password: string;
  passwordOption: PasswordOption;
  userId?: string;
  userName: string;
  email: string;
  firstName: string;
  lastName: string;
  customerId: string;
  phoneNumber: string;
  isEmailVerificationRequired: boolean;
  groups: IntGroupDto[];
  languageCulture: string;
  profilePicturePath: string;
  zipCode: string;
  city: string;
  address: string;
  countryISO3166: string;
  countryName: string;
  imageData: string;
  imageFileName: string;
  deleteImage: boolean;
}

export interface IntCreateVpnServerDto {
  vpnServerName: string;
  customerId: string;
  protocol: VpnServerProtocol;
  port?: number | null;
  cipher: VpnServerCipher;
  hash: VpnServerHash;
  dnsServers: string;
  network: string;
  ipv6?: boolean | null;
}

export interface IntCreateWorkloadRequestDto {
  name: string;
  extensionId: string;
  workloadType: WorkloadType;
  image: string;
  persistentStorage: boolean;
  schedule: string;
  category: WorkloadCategory;
  configurations: IntConfigurationReferenceDto[];
}

export interface IntCronJobStatusDto {
  name: string;
  latestRun: Date;
  jobStatuses: IntJobStatusDto[];
}

export interface IntCustomAttributesForResourcesDto {
  customAttributeResourceType: IntAttributeResourceType;
  resourceId: string;
  search: string | null;
  orderBy: string | null;
  page: number | null;
  pageSize: number | null;
  orderDesc: boolean | null;
  filters: string | null;
}

export interface IntCustomerDto {
  id: string;
  name: string;
}

export interface IntCustomerLinkDto {
  customerLinkId?: string;
  customerId: string;
  linkProvider: LinkProvider;
  customerLinkProviderId: string;
}

export interface IntCustomerLookUpDto {
  tscId: string;
  assets: IntServiceHubAssetDto[];
}

export interface IntCustomerProfileDto {
  customerId: string;
  customerName: string;
  dealerId: string;
  dealerName: string;
  customerLogo: string;
  email: string;
  phoneNumber: string;
  orgNumber: string;
  city: string;
  zipCode: string;
  address: string;
  countryName: string;
  created: Date;
  serviceDataTTL?: number;
  sharedAssets: IntSharedAssetDto[];
  customerLanguages: string[];
}

export interface IntCustomerTableDto {
  customerId: string;
  customerName: string;
  created: Date;
}

export interface IntCustomerTableRequestDto {
  customerIds: string[];
  search: string | null;
  orderBy: string | null;
  page: number | null;
  pageSize: number | null;
  orderDesc: boolean | null;
  filters: string | null;
}

export interface IntDashboardComponentDto {
  dashboardComponentId: string;
  parentComponentId?: string;
  componentType: DashboardComponentType;
  parentDashboardComponent: IntDashboardComponentDto | null;
  components: IntDashboardComponentDto[] | null;
  settings: IntDashboardComponentSettingDto[];
}

export interface IntDashboardComponentSettingDto {
  key: string;
  value: string;
}

export interface IntDashboardDataStatusDto {
  id: string;
  name: string;
  dealerId?: string | null;
  customerId?: string | null;
  rules: IntDashboardDataStatusRuleDto[];
  serviceId?: string | null;
  servicePropertyId?: string | null;
}

export interface IntDashboardDataStatusRuleDto {
  id: string;
  label: string | null;
  color: string | null;
  icon: string | null;
  min?: number | null;
  max?: number | null;
  textValue: string | null;
  textComparer?: ComparisonType | null;
  description: string | null;
}

export interface IntDashboardDetailsDto {
  components: IntDashboardComponentDto[];
  navigation: IntNavigationDto;
  dashboardId: string;
  displayName: string;
  customerId: string;
  lastUpdated: Date;
  created: Date;
  isTemplate?: boolean;
  menuItem: IntMenuItemDto | null;
}

export interface IntDashboardDto {
  dashboardId: string;
  displayName: string;
  customerId: string;
  lastUpdated: Date;
  created: Date;
  isTemplate?: boolean;
  menuItem: IntMenuItemDto | null;
}

export interface IntDashboardUsageDto {
  customerId: string;
  customerName: string;
  dashboardId: string;
  dashboardName: string;
  dashboardComponentId: string;
  dashboardComponentTitle: string;
}

export interface IntDataConsumptionByDayRequestDto {
  startDate?: Date;
  endDate?: Date;
  search: string | null;
  orderBy: string | null;
  page: number | null;
  pageSize: number | null;
  orderDesc: boolean | null;
  filters: string | null;
}

export interface IntDataSessionDetailsDto {
  general: IntDataSessionGeneralDto;
  usage: IntDataSessionUsageDto;
  radius: IntDataSessionRadiusDto;
  uuid: IntDataSessionUUIDDto;
  mnogw: IntDataSessionMNOGWDto;
  enterpriseGW: IntDataSessionEnterpriseGWDto;
}

export interface IntDataSessionEnterpriseGWDto {
  enterpriseGatewayUuid: string;
  enterpriseFilterId: string;
}

export interface IntDataSessionGeneralDto {
  started?: Date;
  lastUpdated?: Date;
  ipAddress: string;
  imsi: string;
  msisdn: string;
  apn: string;
  primaryDns: string;
  secondaryDns: string;
  iPv6DNSNameServers: string;
  ratType: string;
  blocked?: boolean;
  terminating?: boolean;
  homeMCCMNC: string;
  visitedMCCMNC: string;
}

export interface IntDataSessionMNOGWDto {
  mnoGatewayUuid: string;
  mnoFilterId: string;
}

export interface IntDataSessionRadiusDto {
  acctSessionId: string;
  sessionTimeout: number;
  idleTimeout: number;
  nasIpAddress: string;
  nasIdentifier: string;
}

export interface IntDataSessionUsageDto {
  totalBytes: number;
  inputBytes: number;
  inputPackets: number;
  outputBytes: number;
  outputPackets: number;
  sessionRuntime: number;
  timestamp?: Date;
}

export interface IntDataSessionUUIDDto {
  apnHubSessionUUID: string;
  apnHubDeviceUUID: string;
}

export interface IntDealerDetailsDto {
  dealerId: string;
  dealerName: string;
  fullCompanyName: string;
  contactFirstName: string;
  contactLastName: string;
  contactDisplayName: string;
  contactTitle: string;
  contactEmail: string;
  registerDate: number;
  country: string;
  countryName: string;
  city: string;
  zipCode: string;
  address: string;
  email: string;
  phone: string;
  managingDealerId?: string;
  managingDealerName: string;
  customers: IntCustomerDto[];
  subDealers: IntDealerDto[];
  subPortals: IntSubPortalDto[];
}

export interface IntDealerDto {
  dealerId: string;
  dealerName: string;
}

export interface IntDealerTableDto {
  dealerId: string;
  dealerName: string;
  fullCompanyName: string;
  registerDate: number;
  city: string;
  zipCode: string;
  address: string;
  countryIso3166: string;
  countryName: string;
  email: string;
  phone: string;
  managingDealerId?: string;
  managingDealerName: string;
}

export interface IntDefaultProfileDto {
  agreedSince: Date;
  smsTraffic: SmsTraffic;
}

export interface IntDeliveryAddressDto {
  deliveryAddressId?: string;
  address: string;
}

export interface IntDeliveryAddressRequestDto {
  customerId?: string;
  address: string;
}

export interface IntDependencyDto {
  timeGenerated: Date;
  dependencyType: string;
  dataRaw: string;
}

export interface IntDeploymentStatusDto {
  name: string;
  replicas: number;
  updateReplicas: number;
  availableReplicas: number;
  podStatuses: IntPodStatusDto[];
}

export interface IntDetailsDto {
  apnName: string;
  regionName: string;
  profileName: string;
  gatewayName: string;
  gatewayEndpoints: string[];
  gatewayRegion: string;
}

export interface IntDocumentDto {
  id: number;
  name: string;
  fileName: string;
  contentType: string;
  createdAt?: Date;
  updatedAt?: Date;
  size: number;
  uploadedBy: string;
}

export interface IntDynamicAssetAttributeValueDto {
  id: string;
  definitionId: string;
  assetId: string;
  value: string;
  definition: IntAttributeDefinitionDto;
}

export interface IntDynamicAssetAttributeValueSlimDto {
  definitionId: string;
  assetId: string;
  dataType: DynamicAttributeDataType;
  value: string;
}

export interface IntEditCustomerProfileDto {
  customerId: string;
  customerName: string;
  dealerId: string;
  email: string;
  phoneNumber: string;
  orgNumber: string;
  city: string;
  zipCode: string;
  address: string;
  countryName: string;
  imageData: string;
  imageFileName: string;
  deleteImage: boolean;
  sharedAssets: IntSharedAssetDto[];
  customerLanguages: string[];
}

export interface IntEditDashboardDetailsRequestDto {
  components: IntDashboardComponentDto[];
  customerId: string;
  displayName: string;
  isTemplate?: boolean;
}

export interface IntEditDashboardRequestDto {
  customerId: string;
  displayName: string;
  isTemplate?: boolean;
}

export interface IntEditGroupRequestDto {
  groupId: string;
  dealerId?: string;
  description: string;
  groupName: string;
  roles: IntRoleGroupDto[];
}

export interface IntEditNavigationDto {
  customerId: string;
  displayName: string;
  active: boolean;
  menuItems: IntMenuItemDto[];
}

export interface IntEditProductTemplateDto {
  displayName: string;
  description: string;
  linkServiceName: string;
  menuItems: IntMenuItemDto[] | null;
  groups: IntProductTemplateGroupDto[] | null;
  attributeDefinitions: IntProductTemplateAttributeDefinitionDto[] | null;
}

export interface IntEditSubPortalRequestDto {
  subPortalId: string;
  subPortalName: string;
  domainAlias: string;
  dealerId: string;
  features: IntFeatureDto[];
}

export interface IntEditUserPasswordDto {
  userId: string;
  password: string;
  passwordOption: PasswordOption;
}

export interface IntEditUserProfileDto {
  userId: string;
  userName: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  city: string;
  zipCode: string;
  address: string;
  countryName: string;
  imageData: string;
  imageFileName: string;
  deleteImage: boolean;
  isEmailVerificationRequired: boolean;
  languageCulture: string;
}

export interface IntEditVpnServerDto {
  vpnServerName: string;
  customerId: string;
  protocol: VpnServerProtocol;
  cipher: VpnServerCipher;
  hash: VpnServerHash;
  dnsServers: string;
  ipv6: boolean;
  interClient: boolean;
  network: string;
  port?: number;
  maxClients?: number;
  pingInterval?: number;
  pingTimeout?: number;
  inactiveTimeout?: number;
}

export interface IntEnterpriseVpnDto {
  id: string;
  customerId: string;
  name: string;
  enterpriseUuid?: string;
  vpn: IntVpnDto;
  networks: string[];
  uuid?: string;
  status: VpnStatus;
  enterpriseGatewayIp: string;
  region: string;
  enterpriseGatewayUuid: string;
  firewallRules: IntApnHubProfileFirewallRules;
}

export interface IntEnterpriseVpnListDto {
  id: string;
  customerId: string;
  name: string;
  enterpriseUuid?: string;
  uuid?: string;
  status: VpnStatus;
}

export interface IntEnterpriseVpnRequestDto {
  id: string;
  customerId: string;
  uuid?: string;
  name: string;
  enterpriseUuid?: string;
  vpn: IntVpnDto;
  networks: string[];
}

export enum IntEntitiesFilteringType {
  Customer = 0,
  Group = 1,
  Entity = 2,
}

export enum IntEntityPropertyName {
  TotalDataUsage = 0,
  Imei = 1,
  Status = 2,
  ActionReason = 3,
  CountryIso = 4,
}

export interface IntEnvironmentVariable {
  name: string;
  configurationName: string;
  configurationType: ConfigurationType;
}

export interface IntEventAreaDto {
  appRoleName: string;
  category: string;
  event: string;
  message: string;
}

export interface IntEventByAreaIdDto {
  areaId: string;
  startDate?: Date;
  endDate?: Date;
  search: string | null;
  orderBy: string | null;
  page: number | null;
  pageSize: number | null;
  orderDesc: boolean | null;
  filters: string | null;
}

export interface IntEventByRequesterDto {
  id: string;
  startDate?: Date;
  endDate?: Date;
  search: string | null;
  orderBy: string | null;
  page: number | null;
  pageSize: number | null;
  orderDesc: boolean | null;
  filters: string | null;
}

export enum IntEventCategory {
  Localization = 2,
  NetworkStatus = 4,
  SimOrder = 5,
  SimStatus = 1,
  SimTraffic = 3,
  TestMode = 6,
  Other = 0,
}

export interface IntEventDto {
  timeGenerated?: Date;
  appRoleName: string;
  category: string;
  event: string;
  message: string;
  dynamicProperties: IntExtraPropertiesDto[];
}

export interface IntEventHubDto {
  eventHubName: string;
  eventHubConnectionString: string;
  payloadType: AutomationRuleActionEventHubPayloadType;
  payload: string;
}

export interface IntEventSearchDto {
  resourceType: EventResourceType;
  includeDependencies: boolean;
  includeTableReferences: string[];
  includeAllReferences?: boolean;
  startDate?: Date;
  endDate?: Date;
  search: string | null;
  orderBy: string | null;
  page: number | null;
  pageSize: number | null;
  orderDesc: boolean | null;
  filters: string | null;
}

export enum IntEventState {
  Undefined = 0,
  Initiated = 1,
  Success = 2,
  Fail = 3,
}

export enum IntEventTriggerType {
  NotApplicable = 0,
  Manual = 1,
  System = 2,
  AutomationRule = 3,
}

export interface IntExtensionDto {
  namespaces: IntNamespaceDto[];
  id: string;
  displayName: string;
  active: boolean;
  createdDate: Date;
}

export interface IntExtensionLogEntry {
  timeGenerated: Date;
  logEntrySource: string;
  logEntry: string;
}

export interface IntExtensionLogRequestDto {
  startTime?: Date;
  search: string | null;
  orderBy: string | null;
  page: number | null;
  pageSize: number | null;
  orderDesc: boolean | null;
  filters: string | null;
}

export interface IntExtensionTableDto {
  id: string;
  displayName: string;
  active: boolean;
  createdDate: Date;
}

export interface IntExtensionTableRequestDto {
  search: string | null;
  orderBy: string | null;
  page: number | null;
  pageSize: number | null;
  orderDesc: boolean | null;
  filters: string | null;
}

export interface IntExtraPropertiesDto {
  key: string;
  value: string;
}

export interface IntFeatureDto {
  featureId: string;
  featureKey: string;
  subPortalId?: string;
  enabled: boolean;
}

export interface IntFileDto {
  id: number;
  name: string;
  type: string;
  content: string;
}

export enum IntFilterEntityType {
  BaseEntity = 0,
  ConnectivityUnit = 1,
  Asset = 2,
  Customer = 3,
  Terminal = 4,
}

export interface IntFilterIdDto {
  id?: string | null;
  entityId: string;
  label: string;
}

export interface IntFilterOptionsForAssetAttributeValuesRequestDto {
  assetId?: string;
  definitionId?: string;
  search: string | null;
  orderBy: string | null;
  page: number | null;
  pageSize: number | null;
  orderDesc: boolean | null;
  filters: string | null;
}

export interface IntFilterOptionsResponseDto {
  value: string;
  count?: number | null;
}

export interface IntForgotPasswordDto {
  email: string;
}

export interface IntGeofenceDto {
  id: string;
  displayName: string;
  geofenceType: GeofenceType;
  polygonPoints: IntPolygonPointDto[];
  geofenceRadius: IntGeofenceRadiusDto;
}

export interface IntGeofenceRadiusDto {
  automationRuleTriggerGeofenceId: string;
  longitude: number;
  latitude: number;
  radius: number;
}

export interface IntGeographicAddressDto {
  id: string;
  formattedAddress: string;
  streetNr: string;
  streetNrSuffix: string;
  streetName: string;
  city: string;
  postcode: string;
  country: string;
  geographicLocation: IntGeographicLocationDto;
}

export interface IntGeographicLocationDto {
  spatialRef: string;
  geographicPoint: IntGeographicPointDto[];
}

export interface IntGeographicPointDto {
  latitude: string;
  longitude: string;
}

export interface IntGeographicSiteDto {
  id: string;
  formattedAddress: string;
  streetNr: string;
  streetNrSuffix: string;
  streetName: string;
  city: string;
  postcode: string;
  country: string;
}

export interface IntGoogleAuthenticatorDto {
  secretKey: string;
  barcodeUrl: string;
}

export interface IntGoogleAuthenticatorEnableDto {
  code: string;
  secretKey: string;
  barcodeUrl: string;
}

export interface IntGoogleCloudPubSubDto {
  projectId: string;
  topic: string;
  connectionIdentifier: string;
}

export interface IntGroupDto {
  groupId: string;
  groupName: string;
  fromTemplate: boolean;
  roles: IntRoleGroupDto[];
}

export interface IntGroupExtendedDto {
  dealerId?: string;
  description: string;
  usersInGroup: number;
  createdBy: string;
  updatedBy: string;
  lastUpdated?: Date;
  groupId: string;
  groupName: string;
  fromTemplate: boolean;
  roles: IntRoleGroupDto[];
}

export interface IntGroupMinimalConsumptionDto {
  id: string;
  identifier: number;
  label: string;
  memberCount: number;
  totalData?: number;
}

export interface IntGroupTableDto {
  id: string;
  label: string;
  identifier: number;
  memberCount: number;
  customerId: string;
  customerName: string;
}

export interface IntHardwareTicketDto {
  hardware: string;
  subject: string;
  description: string;
  tscId: string;
  contact: IntContactDto;
  assetId: string;
  contactPersonIsNotMe: boolean;
  attachments: IntAttachmentDto[];
  severity: SeverityType;
  additionalEmails: string[];
  additionalContacts: IntContactDto[];
}

export interface IntHistoricalDataSessionDetailsDto {
  general: IntHistoricalDataSessionGeneralDto;
  usage: IntHistoricalDataSessionUsageDto;
  uuid: IntHistoricalDataSessionUUIDDto;
}

export interface IntHistoricalDataSessionEnterpriseGWDto {
  enterpriseGatewayUuid: string;
  enterpriseFilterId: string;
}

export interface IntHistoricalDataSessionGeneralDto {
  ended?: Date;
  started?: Date;
  lastUpdated?: Date;
  ipAddress: string;
  imsi: string;
  msisdn: string;
  apn: string;
  primaryDns: string;
  secondaryDns: string;
  iPv6DNSNameServers: string;
  ratType: string;
  blocked?: boolean;
  terminating?: boolean;
  homeMCCMNC: string;
  visitedMCCMNC: string;
}

export interface IntHistoricalDataSessionMNOGWDto {
  mnoGatewayUuid: string;
  mnoFilterId: string;
}

export interface IntHistoricalDataSessionRadiusDto {
  acctSessionId: string;
  sessionTimeout: number;
  idleTimeout: number;
  nasIpAddress: string;
  nasIdentifier: string;
}

export interface IntHistoricalDataSessionUsageDto {
  totalBytes: number;
  inputBytes: number;
  inputPackets: number;
  outputBytes: number;
  outputPackets: number;
  sessionRuntime: number;
  timestamp?: Date;
}

export interface IntHistoricalDataSessionUUIDDto {
  apnHubSessionUUID: string;
  apnHubDeviceUUID: string;
}

export interface IntHistoricalSessionsTableRequestDto {
  customerId?: string;
  search: string | null;
  orderBy: string | null;
  page: number | null;
  pageSize: number | null;
  orderDesc: boolean | null;
  filters: string | null;
}

export interface IntHistoricalSessionTableDto {
  connectivityUnitId: string;
  identifier: string;
  label: string;
  session: IntHistoricalDataSessionDetailsDto;
}

export interface IntIKENegotiationDto {
  dhGroups: number[];
  enc: string[];
  auth: string[];
  lifetime?: number;
}

export interface IntImageDto {
  registryPath: string;
  repositoryName: string;
  fullImageName: string;
  tags: string[];
  lastUpdatedOn: Date;
}

export interface IntInputTypeFilter {
  inputId: string;
  inputName: string;
  inputValues: IntInputTypeFilterValue[];
}

export interface IntInputTypeFilterValue {
  id: string;
  name: string;
}

export interface IntIntelligenceEventTableDto {
  intelligenceEventId: string;
  automationRuleId?: string;
  automationRuleActionId?: string;
  automationRuleName: string;
  eventDefinitionDisplayName: string;
  eventDefinitionDisplayNameFr: string;
  eventDefinitionDisplayNameDe: string;
  eventDefinitionDisplayNameEs: string;
  eventDefinitionDisplayNameSv: string;
  displayNames: { [key: string]: string };
  assetDisplayName: string;
  assetTable: IntAssetTableDto;
  assetAttributes: { [key: string]: any } | null;
  customerName: string;
  eventDate: Date;
  actualValue: string;
  acknowledgedDate?: Date;
  triggeredServiceDataPoint: { [key: string]: string } | null;
  unit: string;
  conversion?: number;
  displayFormat: ServicePropertyDisplayFormat;
  triggerServiceId?: string;
  triggerServicePropertyId?: string;
}

export interface IntIntelligenceEventTableRequestDto {
  dashboardFilters: IntServiceDataSpecificationFilterDto[];
  search: string | null;
  orderBy: string | null;
  page: number | null;
  pageSize: number | null;
  orderDesc: boolean | null;
  filters: string | null;
}

export interface IntInvoiceDto {
  invoiceId: string;
  customerId?: string;
  customerName: string;
  createdDate: Date;
  dueDate: Date;
  invoiceNumber: string;
  specificationsUrl: string;
  invoiceUrl: string;
}

export interface IntInvoiceMetaDto {
  customerId: string;
  invoiceId: string;
}

export interface IntInvoiceSpecificationMetaDto {
  customerId: string;
  dueDate: Date;
  invoiceId: string;
  invoiceNumber: string;
}

export interface IntInvoiceTicketDto {
  invoiceReferenceId: string;
  subject: string;
  description: string;
  tscId: string;
  contact: IntContactDto;
  assetId: string;
  contactPersonIsNotMe: boolean;
  attachments: IntAttachmentDto[];
  severity: SeverityType;
  additionalEmails: string[];
  additionalContacts: IntContactDto[];
}

export interface IntIoTHubDto {
  connectionString: string;
  payloadType: AutomationRuleActionIotHubPayloadType;
  payload: string;
}

export enum IntIpAllocationType {
  Dynamic = 0,
  Static = 1,
}

export interface IntJobStatusDto {
  name: string;
  startTime: Date;
  stopTime: Date;
  succeeded: boolean;
  podStatuses: IntPodStatusDto[];
}

export interface IntKeyVaultCredentialsDto {
  username: string;
  password: string;
  url: string;
}

export interface IntLegacyAutomationRuleDto {
  id: string;
  displayName: string;
  ruleType: IntAutomationRuleType;
  assetFilters: IntServiceDataSpecificationFilterDto[];
  triggerServiceData: IntTriggerDto;
  active: boolean;
  customerId: string;
  action: IntAutomationRuleActionDto;
  actions: IntAutomationRuleActionDto[];
  filterEntityType: IntFilterEntityType;
}

export interface IntLegacyTriggerConditionDto {
  type: IntTriggerConditionType;
  triggerValue: number;
  serviceId?: string;
  serviceDisplayName: string;
  servicePropertyId?: string;
  servicePropertyDisplayName: string;
  triggerInterval?: IntTriggerConditionTimeInterval;
  cooldown: number;
  cooldownInterval?: IntTriggerConditionTimeInterval;
  maximumTriggerFrequency: number;
  maximumTriggerFrequencyInterval?: IntTriggerConditionTimeInterval;
  triggerTerminalAttribute?: TriggerTerminalAttribute;
}

export interface IntLocaleDto {
  id: string;
  name: string;
}

export enum IntLocalizationResult {
  Unknown = 0,
  Success = 1,
  Failed = 2,
  InvalidConnectivityUnitId = 3,
  InvalidConnectivityUnitProfileId = 4,
  MissingLocaleId = 5,
  MissingCustomerLinkForDcp = 6,
  MissingDcpCustomerId = 7,
  LocalizationAlreadyInProgress = 8,
  InvalidLocaleId = 9,
  NoLocalizationIdAccess = 10,
  DcpRequestNotSuccessful = 11,
}

export interface IntLocalizationResultDto {
  connectivityUnitId?: string;
  result: IntLocalizationResult;
  resultMessage: string;
  errorFlag: boolean;
}

export interface IntLocationDto {
  latitude: number;
  longitude: number;
  altitude?: number;
}

export interface IntLoginFormDto {
  username: string;
  password: string;
  rememberMe: boolean;
}

export enum IntLoginResult {
  Success = 0,
  LockedOut = 1,
  RequiresVerification = 2,
  EmailNotConfirmed = 3,
  Failure = 4,
}

export interface IntLoginResultDto {
  loginResult: IntLoginResult;
  twoFactorProviders: string[];
}

export interface IntManagedVpnTicketDto {
  subject: string;
  description: string;
  tscId: string;
  contact: IntContactDto;
  assetId: string;
  contactPersonIsNotMe: boolean;
  attachments: IntAttachmentDto[];
  severity: SeverityType;
  additionalEmails: string[];
  additionalContacts: IntContactDto[];
}

export interface IntMenuItemDto {
  menuItemId: string;
  navigationId?: string;
  productTemplateMenuItemId?: string;
  dashboardId?: string;
  route: string;
  externalRoute: boolean;
  parentId?: string;
  order: number;
  icon: string;
  roles: string;
  displayName: string;
  children: IntMenuItemDto[];
}

export interface IntModifyGroupMembersRequestDto {
  linkAction: IntConnectivityUnitGroupLinkAction;
  forceTransferToNewGroup: boolean;
  connectivityUnitIds: string[];
  forceImmediateChange: boolean;
}

export interface IntMoveConnectivityUnitGroupRequestDto {
  customerId: string;
  connectivityUnitIds: string[];
  forceImmediateChange: boolean;
}

export interface IntNamespaceDto {
  id: string;
  name: string;
}

export interface IntNavigationDto {
  navigationId: string;
  customerId: string;
  displayName: string;
  active: boolean;
  menuItems: IntMenuItemDto[];
}

export interface IntOtherTicketDto {
  subject: string;
  description: string;
  tscId: string;
  contact: IntContactDto;
  assetId: string;
  contactPersonIsNotMe: boolean;
  attachments: IntAttachmentDto[];
  severity: SeverityType;
  additionalEmails: string[];
  additionalContacts: IntContactDto[];
}

export interface IntOutputTypeFilter {
  outputId: string;
  outputName: string;
}

export interface IntPingResponseDto {
  lastPing?: Date;
  latency?: number;
  state: boolean;
}

export interface IntPlaceDto {
  id: number;
  siteId: number;
  name: string;
  city: string;
  address: string;
  propertyNumber: string;
  area: string;
  network: string;
  longitude: number;
  latitude: number;
}

export interface IntPodStatusDto {
  name: string;
  created: Date;
  started: Date;
  stopped: Date;
  restartCount: number;
  message: string;
  phase: PodPhase;
}

export interface IntPolicyDto {
  policyId: string;
  name: string;
  customerId: string;
  statuses: IntDashboardDataStatusDto[] | null;
}

export interface IntPolicyUsageDto {
  customerId: string;
  customerName: string;
  dashboardId: string;
  dashboardName: string;
  dashboardComponentId: string;
  dashboardComponentTitle: string;
}

export interface IntPolygonPointDto {
  polygonPointId: string;
  automationRuleTriggerGeofenceId: string;
  longitude: number;
  latitude: number;
  order: number;
}

export interface IntPortalTicketDto {
  portal: string;
  subject: string;
  description: string;
  tscId: string;
  contact: IntContactDto;
  assetId: string;
  contactPersonIsNotMe: boolean;
  attachments: IntAttachmentDto[];
  severity: SeverityType;
  additionalEmails: string[];
  additionalContacts: IntContactDto[];
}

export interface IntPortDto {
  port: number;
  targetPort: number;
}

export interface IntProductConnectivityDetailsDto {
  status: ProductStatus;
  pricePlan: string;
  homeNetwork: string;
  formFactor: string[];
}

export interface IntProductDto {
  productId: string;
  name: string;
  productType: ProductType;
  productStatus: ProductStatus;
  startDate: Date;
}

export interface IntProductIpAccessDetailsDto {
  status: ProductStatus;
  profile: IPAccessProfile;
  pricePlan: string;
  vpnList: IntProductVpnDto[];
  apnName: string;
  allocatedIps: number;
}

export interface IntProductLocalizationDetailsDto {
  status: ProductStatus;
  countryName: string;
  countryCode: string;
  priceProfileId: string;
}

export interface IntProductSmsAccessDetailsDto {
  status: ProductStatus;
  smsMoUrl: string;
  deliveryReportUrl: string;
}

export interface IntProductTemplateAttributeDefinitionDto {
  definitionId: string;
  definitionName: string;
  definitionDescription: string;
}

export interface IntProductTemplateDetailsDto {
  productTemplateId: string;
  displayName: string;
  linkServiceName: string;
  description: string;
  menuItems: IntMenuItemDto[];
  groups: IntProductTemplateGroupDto[];
  attributeDefinitions: IntProductTemplateAttributeDefinitionDto[];
}

export interface IntProductTemplateDto {
  productTemplateId: string;
  displayName: string;
  linkServiceName: string;
  description: string;
}

export interface IntProductTemplateGroupDto {
  groupId: string;
  groupName: string;
  isDefault: boolean;
}

export interface IntProductTemplateOptionDto {
  productTemplateId: string;
  displayName: string;
  active: boolean;
}

export interface IntProductVpnDto {
  status: VpnStatus;
  name: string;
}

export enum IntProfileActivationStatus {
  Unknown = 0,
  Active = 1,
  Deactivated = 2,
  Paused = 3,
  Terminated = 4,
  InProgress = 5,
}

export interface IntProfileDescriptorDto {
  nameservers: string[];
  defaultEnterpriseVpnUuid?: string;
  stickyIpAllocation?: boolean;
  status: ProfileDescriptorStatus;
  statusMessages: string[];
  statusTrace: string;
  firewallRules: IntApnHubProfileFirewallRules;
}

export interface IntProfileDto {
  profileId: string;
  name: string;
  global: boolean;
  default: boolean;
  profileDescriptor: IntProfileDescriptorDto;
}

export enum IntPropertyUnitType {
  B = 0,
  kB = 1,
  MB = 2,
  GB = 3,
  TB = 4,
}

export interface IntQrCodeDto {
  imei: string;
  imsi: string | null;
  euicc: string | null;
  macAddress: string | null;
  maker: string | null;
  customIdentifier1: string;
}

export interface IntRateplanDto {
  rateplanLinkId?: string;
  ratePlanType?: IntRatePlanLinkType;
  identifier: string;
  displayName: string;
}

export enum IntRatePlanLinkType {
  Unknown = 0,
  DCP = 1,
}

export interface IntRealEstateItConnectedDeviceDto {
  id: number;
  teliaAssetId: string;
  name: string;
  manufacture: string;
  model: string;
  serialNumber: string;
  networkName: string;
  ipv4: string;
  netMask: string;
  gateway: string;
  mac: string;
  locationName: string;
  locationId: number;
  area: string;
  city: string;
  address: string;
  building: string;
  floor: string;
  room: string;
  rack: string;
  longitude: number;
  latitude: number;
  profileName: string;
  category: string;
  siteId: number;
  closestSwitchId: number;
  notes: string;
  state: boolean;
  latency?: number;
  lastPing?: Date;
  documents: IntDocumentDto[];
  acl: IntAclDto[];
}

export interface IntRealEstateItConnectedDeviceTableDto {
  id: number;
  teliaAssetId: string;
  name: string;
  locationId: number;
  locationName: string;
  city: string;
  longitude: number;
  latitude: number;
  profileName: string;
  category: string;
  ipv4: string;
  netMask: string;
  mac: string;
  siteId: number;
  state: boolean;
  latency?: number;
  lastPing?: Date;
}

export interface IntRealEstateItNetworkDto {
  id: number;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  name: string;
  type: string;
  domain: string;
  zone: string;
  vlan: number;
  ipSubnet: string;
  ipSubnetType: string;
  internetType: string;
}

export interface IntRealEstateItProfileDto {
  id: number;
  category: string;
  name: string;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  allowInternet: boolean;
  allowInternal: boolean;
  allowLan: boolean;
  acl: IntAclDto[];
  customAcl: string;
  notes: string;
  network: IntRealEstateItNetworkDto;
  clientDevices: IntRealEstateItConnectedDeviceTableDto[];
  servers: IntRealEstateItServerDto[];
}

export interface IntRealEstateItServerDto {
  id: number;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  name: string;
  ipv4: string;
  supplierId: number;
  profileId: number;
  notes: string;
}

export interface IntRealEstateItTicketDto {
  type: RealEstateItTicketType;
  missingEquipment?: boolean;
  hasPowerSupply?: boolean;
  hasConstruction?: boolean;
  hasInternetDelivery?: boolean;
  propertyDesignation: string;
  visitingAddress: string;
  affectedEquipment: string;
  problemLocation: string;
  deviceStatus: string;
  caseDescription: string;
  impactDescription: string;
  errorDescription: string;
  requestDescription: string;
  comments: string;
  subject: string;
  description: string;
  tscId: string;
  contact: IntContactDto;
  assetId: string;
  contactPersonIsNotMe: boolean;
  attachments: IntAttachmentDto[];
  severity: SeverityType;
  additionalEmails: string[];
  additionalContacts: IntContactDto[];
}

export interface IntRelatedPartyDto {
  name: string;
  role: string;
}

export interface IntRemoveSharedAssetsRequestDto {
  assetIds: string[];
}

export interface IntRenameGroupDto {
  displayName: string;
}

export interface IntReplaceConfigurationRequestDto {
  name: string;
  type: ConfigurationType;
  data: { [key: string]: string };
}

export interface IntReplaceUiConfigurationRequestDto {
  uiConfigurationId: string;
  displayName: string;
  subPortalId?: string;
  active: boolean;
  settings: IntUiConfigurationSettingDto[];
}

export interface IntReportFilterDto {
  name: string;
  inputTypeFilter: IntInputTypeFilter[];
  outputTypeFilter: IntOutputTypeFilter[];
}

export interface IntReportingDefinitionDto {
  name: string;
  description: string;
  author: string;
  createdAt: Date;
  areaType?: AreaType;
  customerId?: string;
}

export interface IntReportInputTypeDto {
  reportInputTypeId: string;
  reportInputTypeName: string;
}

export interface IntReportMetaDataDto {
  customerId: string;
  name: string;
  areaType: AreaType;
}

export interface IntReportRequestDto {
  serviceId: string;
  filters: IntServiceDataSpecificationFilterDto[];
  columns: IntServiceDataSpecificationColumnDto[];
  timeZoneOffset: number;
}

export interface IntRequestConnectivityConfigurationDto {
  customerId: string;
  type: IntConfigurationType;
  configurationIdentifier: string;
  keyVaultCredentials: IntKeyVaultCredentialsDto | null;
}

export interface IntResetPasswordDto {
  email: string;
  password: string;
  code: string;
}

export enum IntResetPasswordResult {
  Success = 0,
  Failure = 1,
  InvalidToken = 2,
}

export interface IntRoleDto {
  roleId: string;
  roleName: string;
  description: string;
  isSystemRole: boolean;
}

export interface IntRoleEditDto {
  roleId: string;
  description: string;
}

export interface IntRoleGroupDto {
  roleId: string;
  roleName: string;
  accessLevel?: UserRoleAccessLevel;
}

export interface IntRuleTriggerHistoryRowDto {
  entityId: string;
  entityLabel: string;
  evaluationTime?: Date;
  executionTime?: Date;
  triggerType: IntEntityPropertyName;
  lastEvaluatedToValue: string;
  lastEvaluatedFromValue: string;
}

export interface IntScanDto {
  assetId?: string;
  identifier: string;
  customerId: string;
  customerName: string;
  displayName: string;
  imei: string;
  make: string;
  model: string;
  type: string | null;
  installationDate?: Date;
  address: string;
  referenceLabel: string;
  city?: string;
  lastConnected?: Date;
  connected: boolean;
  location: IntLocationDto | null;
  attributes: IntAttributeDto[] | null;
}

export interface IntSearchDto {
  search: string | null;
  orderBy: string | null;
  page: number | null;
  pageSize: number | null;
  orderDesc: boolean | null;
  filters: string | null;
}

export interface IntSelectedUserBulkUpdateDto {
  id: string;
  name: string;
  email: string;
  result: BulkUpdateResult;
}

export interface IntSelectedUserDto {
  name: string;
  id: string;
}

export interface IntSelectedUsersBulkUpdateDto {
  users: IntSelectedUserBulkUpdateDto[];
}

export interface IntSelectedUsersDto {
  userIds: string[];
}

export interface IntSendProviderCodeDto {
  selectedProvider: string;
}

export interface IntServiceDataAggregateRequestDto {
  interval: IntServiceDataPointInterval;
  filters: IntServiceDataSpecificationFilterDto[];
  columnAggregates: { [key: string]: IntAggregateType[] };
}

export interface IntServiceDataFilterOptionRequestDto {
  filters: IntServiceDataSpecificationFilterDto[];
  search: string;
  page: number;
  pageSize: number;
  type: ServiceDataFilterType;
  definitionId?: string;
  includeEntityCount: boolean;
}

export interface IntServiceDataFilterOptionResponseDto {
  filterOptions: IntServiceDataFilterOptionDto[];
  hasMore: boolean;
}

export interface IntServiceDataGroupByRequestDto {
  columnAggregates: { [key: string]: IntAggregateType[] };
  interval: IntServiceDataPointInterval;
  groupBy: IntGroupType;
  filters: IntServiceDataSpecificationFilterDto[];
  columns: IntServiceDataSpecificationColumnDto[];
}

export interface IntServiceDataSpecificationColumnDto {
  columnType: ServiceDataSpecificationColumnType;
  value: string;
}

export interface IntServiceDataSpecificationFilterDto {
  type: ServiceDataFilterType;
  values: string[];
  definitionId?: string;
}

export interface IntServiceDataSpecificationRequestDto {
  interval: IntServiceDataPointInterval;
  filters: IntServiceDataSpecificationFilterDto[];
  columns: IntServiceDataSpecificationColumnDto[];
}

export interface IntServiceDetailDto {
  serviceId: string;
  serviceName: string;
  displayName: string;
  safeServiceName: string;
  createDate: number;
  globallyAvailable: boolean;
  customerId?: string;
  customerName: string;
  latestServiceDataContextType: LatestServiceDataContextType;
  serviceDataTTL?: number;
  properties: IntServicePropertyDto[];
  vendorApis: IntServiceVendorApiDto[];
}

export interface IntServiceFilterDto {
  customerId: string;
  servicesFilters: IntAtlasServiceDto[];
  availableServices: IntAtlasServiceDto[];
}

export interface IntServiceFilterRequestDto {
  customerId: string;
  serviceIds: string[];
}

export interface IntServiceHubAssetDto {
  assetId: string;
  displayName: string;
  assetCode: string;
}

export interface IntServicePropertyDto {
  servicePropertyId: string;
  serviceId: string;
  servicePropertyName: string;
  displayName: string;
  displayNames: { [key: string]: string };
  storageColumnName: string;
  unit: string;
  imperialUnit: string;
  imperialConversion?: number;
  dataType: ServicePropertyDataType;
  numberDataRangeMin?: number;
  numberDataRangeMax?: number;
  valueType?: ServicePropertyValueType;
  allowZero?: boolean;
  conversion?: number;
  displayFormat?: ServicePropertyDisplayFormat;
  enumConstants: IntServicePropertyEnumConstantDto[];
}

export interface IntServicePropertyEnumConstantDto {
  servicePropertyEnumConstantId: string;
  servicePropertyId: string;
  value: number;
  displayName: string;
  displayNameFr: string;
  displayNameDe: string;
  displayNameEs: string;
  displayNameSv: string;
  meaning?: ServicePropertyEnumConstantMeaning;
}

export interface IntServiceTableDto {
  serviceId: string;
  serviceName: string;
  displayName: string;
  safeServiceName: string;
  createDate: number;
  globallyAvailable: boolean;
  customerId?: string;
  customerName: string;
  latestServiceDataContextType: LatestServiceDataContextType;
  serviceDataTTL?: number;
}

export interface IntServiceVendorApiDto {
  serviceVendorApiId: string;
  serviceId: string;
  type: VendorApiType;
  activated: boolean;
  mappings: IntServiceVendorApiMappingDto[];
}

export interface IntServiceVendorApiMappingDto {
  serviceVendorApiMappingId: string;
  serviceVendorApiId: string;
  servicePropertyId: string;
  dataKey: string;
}

export interface IntSharedAssetDto {
  assetId: string;
  customerId: string;
  asset: IntAssetTableDto;
}

export enum IntSimEventCode {
  EntityChange = 9999,
  ImeiChange = 4000,
  SimFirstTimeInNewDevice = 4001,
  SimMoveToNewDevice = 4002,
  SimActivation = 1001,
  SimDeactivation = 1002,
  SimTermination = 1004,
  SimProvisioning = 1003,
  SubscriptionActivation = 2001,
  SubscriptionDownload = 2002,
  SubscriptionDelete = 2003,
  TeliaBootstrapSubscriptionRollback = 2004,
}

export interface IntSiteDto {
  id: number;
  name: string;
}

export interface IntSmsCustomerDto {
  customerId: string;
  customerName: string;
  moUrl: string;
  dlrUrl: string;
  dlrEnabled: boolean;
}

export interface IntSmsUrlDto {
  url: string;
  type: IntSmsUrlType;
}

export enum IntSmsUrlType {
  Dlr = 0,
  Mo = 1,
}

export interface IntSolutionDto {
  solutionId: string;
  solutionName: string;
  urlSolutionName: string;
  displayName: string;
  type: SolutionType;
  priceCurrency: string;
  priceFixed?: number;
  pricePerUser?: number;
  pricePerAsset?: number;
  pricePerTerminal?: number;
  icon: string;
}

export interface IntSptTicketDto {
  serviceAffected: SptServiceAffected;
  subService: string;
  other: string;
  faultDescription: string;
  vehicleNumber: string;
  trafficArea: string;
  lastWorkingDate?: Date;
  incidentStart: Date;
  actionTaken: string;
  subject: string;
  description: string;
  tscId: string;
  contact: IntContactDto;
  assetId: string;
  contactPersonIsNotMe: boolean;
  attachments: IntAttachmentDto[];
  severity: SeverityType;
  additionalEmails: string[];
  additionalContacts: IntContactDto[];
}

export interface IntStateDto {
  provisioningStatus: ApnProvisioningStatus;
  operationalStatus: string;
  deviceCount: number;
}

export enum IntStatus {
  Error = 0,
  Active = 1,
  Deactivated = 2,
  Terminated = 3,
  InProgress = 4,
}

export interface IntStTicketDto {
  trafficAgreements: string;
  vehicleNumber: string;
  numberOfVehicle: IntNumberOfVehicle;
  circulation: string;
  ride: string;
  lastWorkingDate: Date;
  servicePackage?: string;
  serviceType: IntStServiceType;
  errorDetails: string[];
  errorDescription: { [key: string]: string }[];
  subject: string;
  description: string;
  tscId: string;
  contact: IntContactDto;
  assetId: string;
  contactPersonIsNotMe: boolean;
  attachments: IntAttachmentDto[];
  severity: SeverityType;
  additionalEmails: string[];
  additionalContacts: IntContactDto[];
}

export interface IntSubnetDto {
  address: string;
  prefixLength: number;
  activeDevices: number;
}

export interface IntSubPortalDetailsDto {
  subPortalId: string;
  subPortalName: string;
  domainAlias: string;
  type: SubPortalType;
  dealerId: string;
  dealerName: string;
  features: IntFeatureDto[];
}

export interface IntSubPortalDto {
  subPortalId: string;
  subPortalName: string;
  domainAlias: string;
  type: SubPortalType;
}

export interface IntSubPortalTableDto {
  subPortalId: string;
  subPortalName: string;
  domainAlias: string;
  type: SubPortalType;
  dealerId: string;
  dealerName: string;
}

export interface IntSubscribeToAutomationRuleDto {
  automationRuleId: string;
  actionType: IntAutomationActionType;
  subscribe: boolean;
}

export enum IntSubscriptionStatusRequest {
  Activate = 0,
  Deactivate = 1,
  Pause = 2,
  Terminate = 3,
  Reactivate = 4,
}

export interface IntSupplierDto {
  id: number;
  name: string;
  city: string;
  contactInfo: string;
  servers: IntRealEstateItServerDto[];
  connectedDevices: IntRealEstateItConnectedDeviceDto[];
}

export interface IntSupplierTableDto {
  id: number;
  name: string;
  city: string;
  contactInfo: string;
}

export interface IntSupportTableDto {
  id: string;
  shortDescription: string;
  lastUpdate: Date;
  creationDate: Date;
  createdBy: string;
  status: IntTicketStatus;
  category: string;
}

export interface IntSwapDto {
  oldTerminal: IntQrCodeDto;
  newTerminal: IntQrCodeDto;
}

export interface IntSwitchDto {
  id: number;
  locationId: number;
  siteId: number;
  name: string;
}

export interface IntTerminalActionResponseDto {
  success: boolean;
  message: string;
}

export interface IntTerminalAgentDto {
  terminalAgentId: string;
  packageName: string;
  packageVersion: string;
}

export interface IntTerminalConnectivityResponseDto {
  connected: boolean;
}

export interface IntTerminalDhcpLeaseDto {
  id: string;
  macAddress: string;
  ipAddress: string;
  clientHostname: string;
  start?: Date;
  end?: Date;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface IntTerminalFileContentDto {
  content: string;
}

export interface IntTerminalFileDto {
  fileId: string;
  terminalId: string;
  date: Date;
  fileName: string;
  size: number;
  contentBlobId: string;
  contentType: TerminalFileContentType;
}

export interface IntTerminalFingerprintDto {
  terminalFingerprintId: string;
  terminalId?: string;
  fingerprintHash: string;
  generateDate?: Date;
  validFrom?: Date;
  validUntil?: Date;
}

export interface IntTerminalIdentifierDto {
  terminalIdentifierId: string;
  terminalId?: string;
  identifier: string;
  type?: TerminalIdentifierType;
}

export interface IntTerminalInterfaceDto {
  id: string;
  interfaceType: TerminalInterfaceType;
  interfaceName: string;
  status: TerminalInterfaceStatus;
  iPv4Address: string;
  iPv4PrefixLength?: number;
  iPv6Address?: string;
  iPv6PrefixLength?: number;
  defaultGateway: string;
  dnsServers: string[];
  macAddress: string;
  ethLinkSpeed?: number;
  ethLinkMode: EthernetLinkMode;
  wlanSSID?: string;
  wlanMode?: string;
  wlanTxPower?: number;
  wlanChannelFrequency?: number;
  wlanChannelWidth?: number;
  linkUptime?: number;
  bytesSent?: number;
  bytesReceived?: number;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface IntTerminalLinkDto {
  status: string;
  iPv4Address: string;
  iPv4Gateway: string;
  iPv4Dns1: string;
  iPv4Dns2: string;
  mac: string;
  linkUptime?: number;
  interfaceName: string;
  bytesSent?: number;
  bytesReceived?: number;
}

export interface IntTerminalPowerInfo {
  terminalId: string;
  powerRemainingPercent?: number | null;
  powerVoltageStatus?: TerminalPowerVoltageStatus | null;
  powerStatus?: TerminalPowerStatus | null;
  powerSource?: TerminalPowerSource | null;
}

export interface IntTerminalPropertiesDto {
  terminalId: string;
  customerId: string;
  terminalTypeId?: string;
  terminalTypeName: string;
  connected: ConnectedStatus;
  issues: DeviceHealthIssue[];
  platformModel: string;
  active: boolean;
  imei: string;
  displayName: string;
  defaultAssetId?: string;
  simId?: string;
  sim: IntTerminalSimDto;
  serialNumber: string;
  platformOsVersion: string;
  platformBrand: string;
  terminalAgentVersion: string;
  signalStrength?: number;
  totalUptimeSeconds?: number;
  uptimeSeconds?: number;
  freeMemoryBytes?: number;
  totalMemoryBytes?: number;
  powerRemainingPercent?: number;
  powerVoltageStatus?: TerminalPowerVoltageStatus;
  powerStatus?: TerminalPowerStatus;
  powerSource?: TerminalPowerSource;
  latitude?: number;
  longitude?: number;
  interfaces: IntTerminalInterfaceDto[];
  lastConnected?: Date;
  systemTime?: Date;
}

export interface IntTerminalSimDto {
  simId: string;
  status?: SimStatus;
  iccid: string;
  imsi: string;
  msisdn: string;
}

export interface IntTerminalTableDto {
  terminalId: string;
  issues: DeviceHealthIssue[];
  displayName: string;
  connected?: ConnectedStatus;
  active?: boolean;
  customerId: string;
  customerName: string;
  totalUptimeSeconds?: number;
  uptimeSeconds?: number;
  latitude?: number;
  longitude?: number;
  powerStatus?: TerminalPowerStatus;
  powerSource?: TerminalPowerSource;
  powerVoltageStatus?: TerminalPowerVoltageStatus;
  powerRemainingPercent?: number;
  platformBrand: string;
  platformModel: string;
  platformOs: string;
  platformOsVersion: string;
  terminalAgentVersion: string;
  lastConnected?: Date;
}

export interface IntTerminalTableRequestDto {
  customerId?: string;
  search: string | null;
  orderBy: string | null;
  page: number | null;
  pageSize: number | null;
  orderDesc: boolean | null;
  filters: string | null;
}

export interface IntTerminalTypeDto {
  terminalTypeId: string;
  displayName: string;
  pattern: string;
  globallyAvailable?: boolean;
}

export interface IntTicketDetailsDto {
  id: string;
  description: string;
  shortDescription: string;
  status: IntTicketStatus;
  closedDate?: Date;
  creationDate: Date;
  createdBy: string;
  openedBy: string;
  product: string;
  category: string;
  severity: string;
  severityType: SeverityType;
  closingCode: string;
  tscId: string;
  relatedParty: IntRelatedPartyDto[];
  notes: IntTicketNoteDto[];
  attachments: IntAttachmentDto[];
  additionalContacts: IntContactDto[];
}

export interface IntTicketNoteDto {
  text: string;
  author: string;
  visibility: string;
  date: Date;
}

export enum IntTicketStatus {
  New = 0,
  Open = 1,
  AwaitingInfo = 2,
  Resolved = 3,
  Closed = 4,
  Cancelled = 5,
}

export interface IntTicketUpdateDto {
  id: string;
  tscId: string;
  notes: IntTicketNoteDto[];
  attachments: IntAttachmentDto[];
  additionalContacts: IntContactDto[];
  addAdditionalContacts: IntContactDto[];
  removeAdditionalContacts: IntContactDto[];
}

export interface IntTriggerConditionDto {
  id?: string | null;
  type: IntTriggerConditionType | null;
  propertyName?: IntEntityPropertyName;
  propertyUnit: string;
  triggerUnitValue: string;
  triggerTypeValue?: number;
  triggerListValue: string[];
  timeInterval?: IntTriggerConditionTimeInterval | null;
  triggerServiceId?: string;
  triggerServicePropertyId?: string;
  triggerConditionOptions: IntTriggerConditionOptionDto[];
}

export enum IntTriggerConditionOptionType {
  Unknown = 0,
  Timer = 1,
}

export enum IntTriggerConditionTimeInterval {
  Unknown = 0,
  CurrentDay = 1,
  CurrentMonth = 2,
  Seconds = 3,
  Minutes = 4,
  Hours = 5,
  Days = 6,
  Weeks = 7,
  NotApplicable = 999,
}

export enum IntTriggerConditionType {
  EqualTo = 0,
  NotEqualTo = 1,
  LessThan = 2,
  LessOrEqualThan = 3,
  GreaterThan = 4,
  GreaterOrEqualThan = 5,
  NotApplicable = 999,
}

export interface IntTriggerConditionOptionDto {
  type: IntTriggerConditionOptionType;
  timeValue: number;
  timeInterval: IntTriggerConditionTimeInterval;
}

export interface IntTriggerDto {
  triggerType: IntAutomationTriggerType;
  triggerCondition: IntLegacyTriggerConditionDto;
}

export enum IntTriggerValueControlType {
  Changed = 0,
  Included = 1,
  Excluded = 2,
}

export interface IntUiConfigurationDetailsDto {
  settings: IntUiConfigurationSettingDto[];
  uiConfigurationId: string;
  displayName: string;
  customerId?: string;
  subPortalId?: string;
  lastUpdated: Date;
  created: Date;
  active: boolean;
}

export interface IntUiConfigurationDto {
  uiConfigurationId: string;
  displayName: string;
  customerId?: string;
  subPortalId?: string;
  lastUpdated: Date;
  created: Date;
  active: boolean;
}

export interface IntUiConfigurationSettingDto {
  key: string;
  value: string;
}

export interface IntUnitConsumptionBaseDto {
  connectivityUnitId: string;
  identifier: string;
  label: string;
  totalConsumption: number;
}

export interface IntUnitConsumptionDataDto {
  totalUploadedData: number;
  totalDownloadedData: number;
  consumption: IntCdrDataDto[];
  connectivityUnitId: string;
  identifier: string;
  label: string;
  totalConsumption: number;
}

export interface IntUnitConsumptionSmsDto {
  totalIncomingMessages: number;
  totalOutgoingMessages: number;
  consumption: IntCdrSmsDto[];
  connectivityUnitId: string;
  identifier: string;
  label: string;
  totalConsumption: number;
}

export interface IntUnitMinimalConsumptionDto {
  connectivityUnitId?: string;
  identifier: string;
  label: string;
  totalConsumption: number;
  lastActive?: Date;
}

export interface IntUpdateCustomerDetailsDto {
  customerId: string;
  resourceGroupId?: string | null;
  forceImmediateChange: boolean;
}

export interface IntUpdateDealerRequestDto {
  dealerId: string;
  dealerName: string;
  fullCompanyName: string;
  contactFirstName: string;
  contactLastName: string;
  contactDisplayName: string;
  contactEmail: string;
  contactTitle: string;
  country: string;
  city: string;
  zipCode: string;
  address: string;
  email: string;
  phone: string;
  managingDealerId?: string;
  subDealers: IntDealerDto[];
}

export interface IntUpdateDeviceRequestDto {
  name: string;
  profileId?: number;
  supplierId?: number;
  placeId?: number;
  siteId?: number;
  closestSwitchId?: number;
  ipAddress: string;
  mac: string;
  serialNumber: string;
  manufacture: string;
  model: string;
  longitude?: number;
  latitude?: number;
  customAcl: string;
  tag: string;
  notes: string;
  ignoreAlarmState?: boolean;
  address: string;
  building: string;
  floor: string;
  room: string;
  rack: string;
}

export interface IntServiceDataFilterOptionDto {
  id: string;
  name: string;
  hierarchyName: string;
  entityCount?: number;
  children: IntServiceDataFilterOptionDto[];
}

export interface IntUpdatePlaceRequestDto {
  address: string;
  area: string;
  city: string;
  propertyNumber: string;
  name: string;
  siteId?: number;
  building: string;
  caretakerId: string;
  caretakerType: string;
  longitude?: number;
  latitude?: number;
  customerOrganizationNumber: string;
  notes: string;
}

export interface IntUpdateProfileDto {
  profileId?: string;
  forceImmediateChange: boolean;
}

export interface IntUpdateProfileRequestDto {
  name: string;
  category: string;
  networkId?: number;
  allowInternet?: boolean;
  allowInternal?: boolean;
  allowLan?: boolean;
  customAcl: string;
  notes: string;
}

export interface IntUpdateServiceRequestDto {
  serviceId: string;
  displayName: string;
  globallyAvailable: boolean;
  customerId?: string;
  latestServiceDataContextType: LatestServiceDataContextType;
  serviceDataTTL?: number;
  properties: IntServicePropertyDto[];
  vendorApis: IntServiceVendorApiDto[];
}

export interface IntUpdateSupplierRequestDto {
  name: string;
  city: string;
  contactInfo: string;
}

export interface IntUpdateTerminalDto {
  customerId: string;
  terminalTypeId?: string;
  defaultAssetId?: string;
  displayName: string;
  powerVoltageStatus?: TerminalPowerVoltageStatus;
  powerSource?: TerminalPowerSource;
  latitude?: number;
  longitude?: number;
}

export interface IntUpdateTerminalIdentifier {
  identifier: string;
  type: TerminalIdentifierType;
}

export interface IntUpdateWorkloadRequestDto {
  image: string;
  persistentStorage: boolean;
  schedule: string;
  configurations: IntConfigurationReferenceDto[];
}

export interface IntUserCreateInfoDto {
  groups: IntGroupDto[];
  customers: IntCustomerDto[];
}

export interface IntUserDto {
  id: string;
  userId: string;
  userName: string;
  firstName: string;
  lastName: string;
  birthDate?: number;
  registerDate?: number;
  customerId: string;
  customerName: string;
  customerLogo: string;
  displayName: string;
  dealerId: string;
  dealerName: string;
  lastActivityDate: number;
  city: string;
  zipCode: string;
  address: string;
  middleName: string;
  fullName: string;
  groups: IntGroupDto[];
  isVerified: boolean;
  countryISO3166: string;
  countryName: string;
  lastActivityIP: string;
  registerIP: string;
  validFrom?: number;
  validUntil?: number;
  utcDiff: number;
  email: string;
  phoneNumber: string;
  phoneNumberConfirmed: boolean;
  isGoogleAuthenticatorEnabled?: boolean;
  emailConfirmed: boolean;
  isEmailVerificationRequired: boolean;
  googleAuthenticatorSecretKey: string;
  profilePicturePath: string;
  languageCulture: string;
  site: string;
  twoFactorEnabled: boolean;
  lockoutEndDateUtc?: number;
  lockoutEnabled: boolean;
}

export interface IntUserFeaturesRolesDto {
  userModel: IntUserDto;
  roles: string[];
  roleAndAccessLevels: { [key: string]: UserRoleAccessLevel };
  features: IntFeatureDto[];
  activeSubPortalDomainAlias: string;
  isImpersonating: boolean;
}

export interface IntUserModelDto {
  userId?: string;
  userName: string;
  password: string;
  email: string;
  firstName: string;
  lastName: string;
  customerId: string;
  phoneNumber: string;
  isEmailVerificationRequired: boolean;
  groups: IntGroupDto[];
  languageCulture: string;
  profilePicturePath: string;
  zipCode: string;
  city: string;
  address: string;
  countryISO3166: string;
  countryName: string;
  imageData: string;
  imageFileName: string;
  deleteImage: boolean;
}

export interface IntUserTableDto {
  userId: string;
  username: string;
  displayName: string;
  customerName: string;
  groups: string[];
  createdDate?: Date;
  lastActive?: Date;
  emailConfirmed: boolean;
  isEmailVerificationRequired: boolean;
  phoneNumber: string;
  isLockedOut: boolean;
}

export interface IntValidateImageDto {
  valid: boolean;
  errorMessage: string;
  image: IntImageDto;
}

export interface IntVerifyCodeDto {
  provider: string;
  code: string;
  rememberBrowser: boolean;
  rememberMe: boolean;
}

export interface IntVerifyPhoneNumberDto {
  phoneNumber: string;
  code: string;
}

export interface IntVolumeMountDto {
  configurationName: string;
  path: string;
  configurationType: ConfigurationType;
}

export interface IntVpnDto {
  gatewayAddress: string;
  psk: string | null;
  ikeVersions: string[];
  phase1: IntIKENegotiationDto;
  phase2: IntIKENegotiationDto;
}

export interface IntVpnServerDetailsDto {
  vpnServerId: string;
  vpnServerName: string;
  customerId: string;
  online: boolean;
  protocol: VpnServerProtocol;
  port?: number;
  cipher: VpnServerCipher;
  hash: VpnServerHash;
  maxClients?: number;
  dnsServers: string;
  network: string;
  pingInterval?: number;
  pingTimeout?: number;
  inactiveTimeout?: number;
  ipv6: boolean;
  interClient: boolean;
  uptime: string;
  totalSentKb: number;
  totalReceivedKb: number;
  users: IntVpnServerUserDto[];
  terminals: IntVpnServerTerminalDto[];
}

export interface IntVpnServerDto {
  vpnServerId: string;
  vpnServerName: string;
  customerId: string;
  status: VpnServerStatus;
  lzoCompression?: boolean;
  dnsServers: string;
  protocol: VpnServerProtocol;
  pingInterval?: number;
  dnsMapping?: boolean;
  inactiveTimeout?: number;
  networkMode: VpnServerNetworkMode;
  debug?: boolean;
  restrictRoutes?: boolean;
  networkEnd: string;
  bindAddress: string;
  groups: string;
  linkPingInterval?: number;
  hash: VpnServerHash;
  blockOutsideDns?: boolean;
  ipv6Firewall?: boolean;
  interClient?: boolean;
  id: string;
  networkStart: string;
  network: string;
  policy: string;
  uptime?: number;
  userCount?: number;
  name: string;
  devicesOnline?: number;
  vpnServerUrl: string;
  maxClients?: number;
  port?: number;
  ipv6?: boolean;
  cipher: VpnServerCipher;
  pingTimeout?: number;
}

export interface IntVpnServerTerminalDto {
  vpnServerTerminalId: string;
  terminalName: string;
  online: boolean;
  imei: string;
  serialNumber: string;
  virtualIpAddress: string;
  realIpAddress: string;
  connectedSince?: Date;
}

export interface IntVpnServerUserDto {
  vpnServerUserId: string;
  userName: string;
  online: boolean;
  virtualIpAddress: string;
  realIpAddress: string;
  connectedSince?: Date;
}

export interface IntVpnsExcelDto {
  vpnIds: string[];
}

export interface IntWebhookCustomHeaderDto {
  webhookCustomHeaderId: string;
  header: string;
  value: string;
}

export interface IntWebhookDto {
  url: string;
  httpMethod: AutomationRuleActionWebHookMethod;
  payloadType: AutomationRuleActionWebHookPayloadType;
  authenticationType: AutomationRuleActionWebHookAuthType;
  payload: string;
  username: string;
  password: string;
  tokenEndpoint: string;
  grantType: string;
  accessToken: string;
  customHeaders: IntWebhookCustomHeaderDto[];
}

export interface IntWorkloadDto {
  id: string;
  name: string;
  image: string;
  extensionId: string;
  type: WorkloadType;
  persistentStorage: boolean;
  category: WorkloadCategory;
  schedule: string;
  configurations: IntConfigurationReferenceDto[];
  createdDate: Date;
}

export interface IntWorkloadTableDto {
  id: string;
  extensionId: string;
  extensionName: string;
  namespaceId: string;
  namespaceName: string;
  name: string;
  type: WorkloadType;
  createdDate: Date;
}

export interface IntWorkloadTableRequestDto {
  extensionId?: string;
  search: string | null;
  orderBy: string | null;
  page: number | null;
  pageSize: number | null;
  orderDesc: boolean | null;
  filters: string | null;
}

export enum IPAccessProfile {
  BLOCKED = 0,
  FULL_INTERNET = 1,
}

export enum LabelAction {
  NoAction = 0,
  Create = 1,
  Update = 2,
  Delete = 3,
}

export enum ServiceStatus {
  Running = 200,
  Stopped = 400,
  UnderMaintenance = 500,
  UnderDevelopment = 505,
}

export enum LinkConfigurationEventDataMappingType {
  ServiceProperty = 0,
  AssetServiceMode = 1,
  TerminalPowerSource = 2,
  TerminalPowerVoltageStatus = 3,
  AssetConfigurationParameter = 4,
  TerminalProperty = 5,
  AssetProperty = 6,
}

export enum AspectToolbarActionInputDefaultValueType {
  StaticValue = 0,
  AssetConfigurationParameter = 1,
}

export enum GeofenceTriggerType {
  Inside = 0,
  Outside = 1,
}

export enum GeofenceType {
  Polygon = 0,
  Radius = 1,
}

export enum TerminalPowerVoltageStatus {
  Normal = 0,
  Low = 1,
  CriticalLow = 2,
}

export enum AspectToolbarActionInputType {
  Stepper = 0,
  Textbox = 1,
}

export enum IntelligenceEventDefinitionContext {
  General = 0,
  Asset = 1,
  Terminal = 2,
}

export enum VpnServerNetworkMode {
  Tunnel = 0,
  Bridge = 1,
}

export enum VpnServerHash {
  None = 0,
  Md5 = 1,
  Sha1 = 2,
  Sha256 = 3,
  Sha512 = 4,
}

export enum TerminalTypeSetAssetConfigurationParameterType {
  SendAzureIotHubMessage = 0,
}

export enum VpnServerCipher {
  None = 0,
  Blowfish128 = 1,
  Blowfish256 = 2,
  Aes128 = 3,
  Aes192 = 4,
  Aes256 = 5,
}

export enum VpnServerProtocol {
  Tcp = 0,
  Udp = 1,
}

export enum VpnServerStatus {
  Offline = 0,
  Online = 1,
}

export enum EventDataRegisterDateType {
  MillisecondsSinceEpoch = 0,
  SecondsSinceEpoch = 1,
}

export enum SimStatus {
  TestReady = 0,
  Inventory = 1,
  ActivationReady = 2,
  Activated = 3,
  Deactivated = 4,
  Retired = 5,
  Unknown = 6,
}

export enum ServicePropertyEnumConstantMeaning {
  Neutral = 0,
  ALittlePositive = 1,
  Positive = 2,
  VeryPositive = 3,
  MostPositive = 4,
  ALittleNegative = 5,
  Negative = 6,
  VeryNegative = 7,
  MostNegative = 8,
}

export enum ServicePropertyDisplayFormat {
  Raw = 0,
  TimeSpanHhMm = 1,
  TimeSpanHhMmSs = 2,
  NumberZeroDecimals = 3,
  NumberOneDecimal = 4,
  NumberTwoDecimals = 5,
  NumberThreeDecimals = 6,
  NumberFourDecimals = 7,
  NumberFiveDecimals = 8,
  NumberSixDecimals = 9,
}

export enum ShowWhenILogin {
  Home = 0,
  SolutionAspect = 1,
}

export enum RatePlanLinkType {
  Unknown = 0,
  DCP = 1,
}

export enum MeasurementSystem {
  Metric = 0,
  Imperial = 1,
}

export enum ServicePropertyDataType {
  Text = 0,
  Number = 1,
  TrueOrFalse = 2,
  DateTime = 3,
  Enum = 4,
}

export enum SubPortalType {
  CustomerPortal = 0,
  ManagementPortal = 1,
  Atlas = 2,
}

export enum TerminalFileContentType {
  SystemLog = 0,
  FirmwareConfig = 1,
}

export enum AppStatus {
  PrepareForSubmission = 0,
  WaitingForReview = 1,
  InReview = 2,
  PendingRelease = 3,
  Processing = 4,
  Ready = 5,
  Rejected = 6,
  Removed = 7,
}

export enum TerminalIdentifierType {
  Unknown = 0,
  InternationalMobileEquipmentIdentity = 1,
  MediaAccessControlAddress = 2,
  SerialNumber = 3,
  VendorIdentifier = 4,
  MobileEquipmentIdentifier = 5,
  AzureIotHubDeviceId = 6,
}

export enum AssetIdentifierType {
  Unknown = 0,
  VehicleIdentificationNumber = 1,
  VehicleRegistrationNumber = 2,
  SerialNumber = 3,
  NationalIdentificationNumber = 4,
  Name = 5,
  AzureIotHubDeviceId = 12,
  Imei = 13,
  PanoramaSeriesId = 16,
}

export enum ConnectivityIdentifierType {
  Id = 0,
  Identifier = 1,
}

export enum TerminalPowerStatus {
  Charging = 0,
  Discharging = 1,
  Off = 2,
}

export enum TerminalPowerSource {
  Battery = 0,
  PowerCable = 1,
}

export enum TerminalInterfaceType {
  Unknown = 0,
  eth = 1,
  wlan = 2,
}

export enum TerminalInterfaceStatus {
  Unknown = 0,
  Enabled = 1,
  Disabled = 2,
  Connected = 3,
  Disconnected = 4,
}

export enum EthernetLinkMode {
  Unknown = 0,
  Simplex = 1,
  Half = 2,
  Full = 3,
}

export enum SubscriptionPlanItemType {
  Solution = 0,
  Service = 1,
  Aspect = 2,
  Hardware = 3,
  Other = 4,
}

export enum ConnectivityUnitGroupType {
  Unknown = 0,
  ConnectivityUnit = 1,
}

export enum SubscriptionPlanQuantityType {
  PerAsset = 0,
  PerTerminal = 1,
  PerUser = 2,
  Fixed = 3,
}

export enum SubscriptionPlanInterval {
  Day = 0,
  Week = 1,
  Month = 2,
  Year = 3,
}

export enum SubscriptionStatus {
  Trialing = 0,
  Active = 1,
  PastDue = 2,
  Canceled = 3,
  Unpaid = 4,
}

export enum LinkProvider {
  Stripe = 0,
  Salesforce = 1,
  EricssonDcp = 3,
  AzureIotHub = 4,
  CoAP = 8,
  R6 = 9,
  ServiceId = 11,
  AzureEventHub = 12,
  MandatorKey = 13,
  RealEstateItInstance = 14,
}

export enum SimLinkProvider {
  EricssonDcp = 3,
}

export enum TerminalLinkProvider {
  AzureIotHub = 4,
  AzureEventHub = 12,
}

export enum LinkConfigurationProtocol {
  Json = 0,
}

export enum LinkProviderType {
  Sim = 0,
  Terminal = 3,
}

export enum WidgetWidth {
  OneTwelfth = 0,
  TwoTwelfths = 1,
  ThreeTwelfths = 2,
  FourTwelfths = 3,
  FiveTwelfths = 4,
  SixTwelfths = 5,
  SevenTwelfths = 6,
  EightTwelfths = 7,
  NineTwelfths = 8,
  TenTwelfths = 9,
  ElevenTwelfths = 10,
  TwelveTwelfths = 11,
}

export enum WidgetControlWidth {
  OneTwelfth = 0,
  TwoTwelfths = 1,
  ThreeTwelfths = 2,
  FourTwelfths = 3,
  FiveTwelfths = 4,
  SixTwelfths = 5,
  SevenTwelfths = 6,
  EightTwelfths = 7,
  NineTwelfths = 8,
  TenTwelfths = 9,
  ElevenTwelfths = 10,
  TwelveTwelfths = 11,
}

export enum WidgetVisualizationControlType {
  Table = 0,
  Metric = 1,
  LineChart = 2,
  AreaChart = 3,
  ColumnChart = 4,
  BarChart = 5,
  PieChart = 6,
  ScatterChart = 7,
  Gauge = 8,
  OpenStreetMap = 9,
  GoogleMap = 10,
  BingMap = 11,
  AssetDetails = 13,
  Image = 14,
}

export enum WidgetInteractionControlType {
  Button = 0,
  SwitchButton = 1,
  Slider = 2,
}

export enum ReportTemplateType {
  Service = 0,
  Events = 1,
  AnalyticsCube = 2,
}

export enum ReportTemplateColumnAttribute {
  AssetName = 0,
  RegisterDate = 1,
  ServiceProperty = 2,
  StoredDate = 3,
  AnalyticsCubeAggregate = 4,
}

export enum InformationModelAttribute {
  NotSet = -1,
  AssetDetailsAssetId = 0,
  AssetDetailsAssetIdentifier = 1,
  AssetDetailsAssetName = 2,
  AssetDetailsCountryName = 3,
  AssetDetailsCustomerName = 5,
  AssetDetailsDealerName = 7,
  AnalyticsCubeAggregate = 8,
  ServiceProperty = 9,
  AssetDetailsAttribute = 10,
  AssetDetailsMake = 11,
  AssetDetailsModel = 12,
  AssetDetailsType = 13,
  AssetDetailsServiceMode = 14,
  AssetDetailsTerminalPowerSource = 15,
  AssetDetailsTerminalConnected = 16,
  AssetDetailsEvents = 17,
  AssetDetailsConfigurationParameter = 18,
  AssetDetailsCity = 20,
  AssetDetailsAddress = 21,
  AssetDetailsMaintenanceUnit = 22,
  AssetDetailsBuilding = 23,
  AssetDetailsFloor = 24,
  AssetDetailsSubSpace = 25,
  AssetDetailsReferenceLabel = 26,
}

export enum WidgetControlType {
  Visualization = 0,
  Interaction = 1,
}

export enum AspectType {
  Dashboard = 0,
  SpecificAsset = 1,
  Reports = 2,
}

export enum WidgetVisualizationControlSourceType {
  Service = 0,
  AnalyticsCube = 1,
  Events = 2,
}

export enum AutomationRuleFilterType {
  Asset = 0,
  Terminal = 1,
  CustomerAsset = 3,
  CustomerTerminal = 4,
  AssetGroup = 6,
  DealerAsset = 7,
  DealerTerminal = 8,
  ManagingDealerAsset = 10,
  ManagingDealerTerminal = 11,
}

export enum AutomationRuleTriggerType {
  ServiceProperty = 0,
  TerminalAttribute = 1,
  TimeSinceLastServiceData = 4,
  Geofence = 5,
  Service = 6,
}

export enum TriggerTerminalAttribute {
  Connected = 0,
  Active = 1,
  SignalStrength = 2,
  FreeMemory = 3,
  Uptime = 4,
  Link1Status = 5,
  Link2Status = 6,
  IpSecVpnStatus = 7,
  OpenVpnStatus = 8,
  PowerVoltageStatus = 9,
  LastConnected = 10,
}

export enum CooldownTerminalAttribute {
  Connected = 0,
  Active = 1,
  SignalStrength = 2,
  FreeMemory = 3,
  Uptime = 4,
  Link1Status = 5,
  Link2Status = 6,
  IpSecVpnStatus = 7,
  OpenVpnStatus = 8,
  PowerVoltageStatus = 9,
  LastConnected = 10,
}

export enum AutomationRuleServicePropertyTriggerType {
  Above = 0,
  Below = 1,
  Matches = 2,
}

export enum AnalyticsCubeServicePropertyFilterType {
  Above = 0,
  Below = 1,
  Matches = 2,
}

export enum AutomationRuleTriggerCause {
  Unknown = -1,
  TerminalActive = 0,
  TerminalNotActive = 1,
  TerminalConnected = 2,
  TerminalNotConnected = 3,
  ServiceProperty = 4,
  TerminalSignalStrengthAbove = 5,
  TerminalSignalStrengthBelow = 6,
  TerminalFreeMemoryAbove = 7,
  TerminalFreeMemoryBelow = 8,
  TerminalUptimeAbove = 9,
  TerminalUptimeBelow = 10,
  TerminalLink1StatusConnected = 11,
  TerminalLink1StatusDisconnected = 12,
  TerminalLink2StatusConnected = 13,
  TerminalLink2StatusDisconnected = 14,
  TerminalIpSecVpnStatusConnected = 15,
  TerminalIpSecVpnStatusDisconnected = 16,
  TerminalOpenVpnVpnStatusConnected = 17,
  TerminalOpenVpnVpnStatusDisconnected = 18,
  TerminalPowerVoltageStatusMatches = 31,
  TerminalPowerVoltageStatusDoesntMatch = 32,
  TooLongTimeSinceLastServiceData = 33,
  TerminalTooLongSinceLastConnected = 34,
}

export enum AutomationRuleTriggerState {
  InCooldown = 0,
  Triggerd = 1,
  ReverseTriggerd = 2,
}

export enum AutomationRuleTriggerCooldownType {
  ServiceProperty = 0,
  TerminalAttribute = 2,
  TimeSinceLastServiceData = 5,
  Geofence = 6,
}

export enum AutomationRuleAttributeTriggerType {
  Matches = 0,
  Above = 2,
  Below = 3,
}

export enum AutomationRuleAttributeCooldownType {
  Matches = 0,
  Above = 2,
  Below = 3,
}

export enum AutomationRuleTriggerServicePropertyCooldownType {
  Above = 0,
  Below = 1,
  Matches = 2,
}

export enum AutomationRuleTriggerFilterType {
  Matches = 0,
  DoesntMatch = 1,
  Above = 2,
  Below = 3,
}

export enum AutomationRuleActionType {
  NoAction = 0,
  SendEmail = 1,
  SendSms = 2,
  CreateIntelligenceEvent = 3,
  WebHook = 7,
  SendToEventHub = 8,
  SendToAwsIotCore = 10,
  SendToAzureIotHub = 11,
  SendToGoogleCloudPubSub = 14,
}

export enum AutomationRuleActionWebHookMethod {
  Get = 0,
  Post = 1,
  Put = 2,
  Delete = 3,
}

export enum AutomationRuleActionWebHookAuthType {
  BasicAuthentication = 0,
  BearerToken = 1,
  NoAuthentication = 2,
  OAuth2 = 3,
}

export enum AutomationRuleActionWebHookPayloadType {
  None = 0,
  Json = 1,
  Xml = 2,
}

export enum AutomationRuleActionAwsIotCorePayloadType {
  None = 0,
  Json = 1,
}

export enum AutomationRuleActionIotHubPayloadType {
  None = 0,
  Json = 1,
}

export enum AutomationRuleActionEventHubPayloadType {
  None = 0,
  Json = 1,
}

export enum UserRoleAccessLevel {
  Global = 0,
  Dealer = 1,
  Customer = 2,
  User = 3,
  ManagingDealer = 4,
  NoAccess = 5,
}

export enum SolutionType {
  RemoteMonitoring = 0,
  PredictiveMaintenance = 1,
  DeviceManagement = 2,
  Custom = 3,
}

export enum AssetSolutionProvisioningStatus {
  ProvisioningNotStarted = 0,
  OngoingProvisioning = 1,
  ProvisioningFailed = 2,
  ProvisioningSuccessful = 3,
}

export enum AspectToolbarActionType {
  SendServiceCommand = 0,
  InvokeAzureIotHubDeviceMethod = 1,
  SendAzureIotHubMessage = 2,
  CreateOrUpdateAutomationRule = 3,
}

export enum AssetSolutionProvisioningTask {
  None = 0,
  WaitingForTerminalToBeLinkedToAsset = 1,
  LinkingAppToTerminal = 2,
  WaitingForTerminalToBecomeConnected = 3,
  SleepingBeforeInstallingAppOnTerminal = 4,
  InstallingAppOnTerminal = 5,
  WaitingForReceivingFirstServiceData = 6,
  StartingAppOnTerminal = 7,
}

export enum ServicePropertyValueType {
  Average = 0,
  LatestValue = 1,
}

export enum VendorApiType {
  EfentoCoap = 2,
}

export enum ServiceDataPointInterval {
  Second = 0,
  Minute = 1,
  Hour = 2,
  Day = 3,
  Month = 4,
  Latest = 5,
  None = 6,
}

export enum ServiceDataAggregateType {
  Average = 0,
  Count = 1,
  Max = 2,
  Min = 3,
  Sum = 4,
}

export enum ServiceDataGroupType {
  RegisterDate = 0,
  Asset = 1,
}

export enum ServicePropertyComputionType {
  Average = 0,
  Sum = 2,
  Min = 3,
  Max = 4,
  Latest = 5,
}

export enum ServicePropertyRepresentationType {
  Absolute = 0,
  Average = 1,
  Sum = 2,
  Min = 3,
  Max = 4,
  Count = 5,
}

export enum AnalyticsCubeAggregateValueType {
  ServicePropertyCompution = 2,
  ServicePropertyPairCompution = 5,
  RegisterDate = 8,
}

export enum ServicePropertyPairComputionType {
  Multiplied = 0,
  Divided = 1,
  Summed = 2,
  Subtracted = 3,
}

export enum AnalyticsCubeFilterType {
  ServiceProperty = 0,
  AssetMake = 1,
  AssetModel = 2,
  AssetType = 3,
  Service = 4,
  RegisterDateStart = 5,
  RegisterDateEnd = 6,
  AssetCustomer = 8,
  AssetDealer = 9,
  Asset = 10,
  AssetGroup = 11,
  AssetCity = 13,
  AssetAddress = 14,
  AssetMaintenanceUnit = 15,
  AssetBuilding = 16,
  AssetFloor = 17,
  AssetSubSpace = 18,
  AssetReferenceLabel = 19,
}

export enum AnalyticsCubeRegisterDateGroupingResolution {
  Minute = 1,
  Hour = 2,
  Day = 3,
  Month = 4,
}

export enum AnalyticsCubeGroupingType {
  RegisterDate = 5,
  Asset = 6,
}

export enum AnalyticsCubeParameterType {
  Filter = 0,
  Grouping = 1,
}

export enum TerminalTypeSoftwareType {
  Agent = 0,
  Firmware = 1,
}

export enum TerminalTypeCapabilityType {
  AttributeSignalStrength = 0,
  AttributeUptime = 1,
  AttributeTotalUptime = 2,
  AttributePowerStatus = 3,
  AttributePowerSource = 4,
  AttributePowerRemaining = 5,
  AttributePlatformBrand = 6,
  AttributePlatformModel = 7,
  AttributePlatformOs = 8,
  AttributePlatformOsVersion = 9,
  AttributeDebug = 10,
  AttributeOperatingTemperature = 11,
  AttributeFreeMemory = 12,
  AttributeTotalMemory = 13,
  AttributeActiveThreads = 14,
  AttributeLink1Status = 15,
  AttributeLink1IPv4Gateway = 16,
  AttributeLink1IPv4Dns1 = 17,
  AttributeLink1IPv4Dns2 = 18,
  AttributeLink1Mac = 19,
  AttributeLink1LinkUptime = 20,
  AttributeLink1InterfaceName = 21,
  AttributeLink1BytesSent = 22,
  AttributeLink1BytesReceived = 23,
  AttributeLink2Status = 24,
  AttributeLink2IPv4Gateway = 25,
  AttributeLink2IPv4Dns1 = 26,
  AttributeLink2IPv4Dns2 = 27,
  AttributeLink2Mac = 28,
  AttributeLink2LinkUptime = 29,
  AttributeLink2InterfaceName = 30,
  AttributeLink2BytesSent = 31,
  AttributeLink2BytesReceived = 32,
  AttributeLink3Status = 33,
  AttributeLink3IPv4Gateway = 34,
  AttributeLink3IPv4Dns1 = 35,
  AttributeLink3IPv4Dns2 = 36,
  AttributeLink3Mac = 37,
  AttributeLink3LinkUptime = 38,
  AttributeLink3InterfaceName = 39,
  AttributeLink3BytesSent = 40,
  AttributeLink3BytesReceived = 41,
  AttributeVpnIpSec1Status = 42,
  AttributeVpnIpSec1Uptime = 43,
  AttributeVpnOpenVpn1Status = 44,
  AttributeVpnOpenVpn1Uptime = 45,
  AttributeSystemTime = 46,
  AttributePort1Poe = 47,
  AttributePort2Poe = 48,
  AttributePort3Poe = 49,
  AttributePort4Poe = 50,
  FunctionReceiveSms = 301,
  FunctionImportFirmwareConfig = 302,
  FunctionExportFirmwareConfig = 303,
  FunctionRestart = 304,
  FunctionUpdateAgent = 305,
  FunctionUpdateFirmware = 306,
  FunctionGetSystemLog = 307,
  FunctionFiles = 308,
  FunctionApps = 310,
  FunctionSettings = 309,
  FunctionWiFi = 311,
  FunctionFirewall = 312,
}

export enum FilterQueryType {
  Match = 0,
  Range = 1,
}

export enum CombinedFilterOperatorType {
  AND = 0,
  OR = 1,
}

export enum LatestServiceDataContextType {
  LatestPoint = 0,
  LatestPointByRegisterDate = 1,
  LatestCollection = 2,
}

export enum AssetMetadataType {
  InstallationDate = 1,
  Address = 2,
  City = 3,
  ApartmentId = 4,
  VehicleRef = 5,
  MaintenanceUnit = 6,
  Building = 7,
  Floor = 8,
  SubSpace = 9,
}

export enum AssetStaticAttribute {
  InstallationDate = 1,
  Address = 2,
  City = 3,
  ApartmentId = 4,
  VehicleRef = 5,
  MaintenanceUnit = 6,
  Building = 7,
  Floor = 8,
  SubSpace = 9,
  Make = 10,
  Model = 11,
  Type = 12,
}

export enum AttributeDefinitionResourceType {
  Unknown = 0,
  ConnectivityUnit = 1,
  Asset = 2,
}

export enum ConnectedStatus {
  Unknown = 0,
  Connected = 1,
  NotConnected = 2,
  PartlyConnected = 3,
}

export enum DeviceConnectionState {
  Disconnected = 0,
  Connected = 1,
}

export enum DeviceHealthIssue {
  None = 0,
  LowMemory = 1,
  BadSignal = 2,
  CriticalLowBattery = 3,
}

export enum TerminalTypeSynchronizableParameterType {
  AssetAttributeDoorType = 0,
}

export enum AssetState {
  Unknown = 0,
  Provisioned = 1,
  Active = 2,
  Terminated = 3,
}

export enum ConnectivityUnitType {
  Single = 0,
  Multi = 1,
}

export enum ConnectivityUnitProfileDownloadStatus {
  Unknown = 0,
  Downloaded = 1,
  InProgress = 2,
  DownloadFailed = 3,
}

export enum VpnStatus {
  Unknown = 0,
  Unconfigured = 1,
  Pending = 2,
  Ready = 3,
  Up = 4,
  Down = 5,
  Error = 6,
  NotFound = 7,
}

export enum ConnectivityUnitStatus {
  Unknown = 0,
  Working = 1,
  TemporaryBlock = 2,
  PermanentBlock = 3,
  Updating = 4,
}

export enum ConnectivityOrderStatus {
  Unknown = 0,
  New = 1,
  InProgress = 2,
  Completed = 3,
  Canceled = 4,
  Failed = 5,
}

export enum ConnectivityUnitProfileActivationStatus {
  Unknown = 0,
  Active = 1,
  Deactivated = 2,
  Paused = 3,
  Terminated = 4,
  InProgress = 5,
}

export enum ConnectivityUnitLocalizedStatus {
  Unknown = 0,
  NotLocalized = 1,
  Localized = 2,
  Updating = 3,
}

export enum TicketCategory {
  Invoice = 1,
  ConnectivityUnit = 2,
  API = 3,
  ManagedVPN = 4,
  Hardware = 5,
  Portal = 6,
  Other = 7,
  RealEstateIt = 8,
  SmartPublicTransport = 9,
  ST = 10,
}

export enum ProductType {
  Unknown = 0,
  GlobalConnectivity = 1,
  APNAccess = 2,
  SMSAccess = 3,
  Localization = 4,
  PortalAccessGlobalConnectivity = 5,
  PortalAccess = 6,
  AssetTracking = 7,
  BCMDeviceRegistration = 8,
  ManagedGateway = 9,
}

export enum ProductStatus {
  Pending = 0,
  Active = 1,
  Suspended = 2,
}

export enum StorageTenant {
  EuOnly = 0,
}

export enum PasswordOption {
  Manually = 0,
  Sms = 1,
  Email = 2,
}

export enum ProfileDescriptorStatus {
  Unknown = 0,
  Pending = 1,
  Ready = 2,
  Error = 3,
}

export enum FirewallRuleAction {
  Accept = 0,
  Drop = 1,
  Reject = 2,
}

export enum ApnProvisioningStatus {
  Unknown = 0,
  Pending = 1,
  Ready = 2,
  Error = 3,
}

export enum DashboardComponentType {
  Unknown = 0,
  LineChart = 1,
  AzureMaps = 2,
  ColumnChart = 3,
  Table = 4,
  Number = 5,
  Gauge = 6,
  Image = 7,
  Filters = 8,
  PieChart = 9,
  HighchartsMap = 10,
  NumberGrid = 11,
  GroupedBarChart = 12,
}

export enum TestModeStatus {
  NotApplicable = 0,
  Active = 1,
  Expired = 2,
  Consumed = 3,
}

export enum DynamicAttributeDataType {
  Unknown = 0,
  Text = 1,
  Number = 2,
  Boolean = 3,
  DateTime = 4,
  File = 5,
}

export enum DynamicAttributeDefinitionScope {
  None = 0,
  AssetType = 1,
  Customer = 2,
  Dealer = 3,
  Product = 4,
}

export enum SptServiceAffected {
  Hardware = 0,
  Software = 1,
  ElevatorEmergencyCommunication = 2,
}

export enum IntNumberOfVehicle {
  One = 0,
  MoreThanTen = 1,
}

export enum IntStServiceType {
  Bus = 0,
  Train = 1,
  Tram = 2,
  Miscellaneous = 3,
}

export enum RealEstateItTicketType {
  SingleBuilding = 0,
  MultipleBuilding = 1,
  Request = 2,
}

export enum ConnectivityTicketObjectType {
  FixedObject = 0,
  MovingObject = 1,
  UnknownObject = 2,
}

export enum ExportType {
  Zip = 0,
  Folder = 1,
}

export enum ExportStatus {
  Queued = 0,
  InProgress = 1,
  Done = 2,
  Failed = 3,
}

export enum ComparisonType {
  EqualTo = 0,
  NotEqualTo = 1,
  LessThan = 2,
  LessOrEqualThan = 3,
  GreaterThan = 4,
  GreaterOrEqualThan = 5,
}

export enum PodPhase {
  Unknown = 0,
  Pending = 1,
  Running = 2,
  Succeeded = 3,
  Failed = 4,
}

export enum QueryableAssetProperty {
  CustomerId = 0,
  AssetId = 3,
  Make = 7,
  Model = 8,
  Type = 9,
  Address = 10,
  City = 11,
  MaintenanceUnit = 12,
  Building = 13,
  SubSpace = 14,
  Floor = 15,
  ApartmentId = 16,
  DynamicAttribute = 17,
  DealerId = 18,
  TerminalId = 20,
}

export interface ReportingTableRequestDto {
  search: string;
  orderBy: string;
  page: number;
  pageSize: number;
  orderDesc: boolean;
  filters: string;
}

export enum ServiceDataSpecificationColumnType {
  ServiceProperty = 0,
  AssetMetaData = 1,
  AssetAttribute = 2,
}

export enum ServiceDataFilterType {
  CustomerId = 0,
  RegisterDateStart = 1,
  RegisterDateEnd = 2,
  AssetId = 3,
  Limit = 4,
  ResourceGroupId = 5,
  Make = 7,
  Model = 8,
  Type = 9,
  Address = 10,
  City = 11,
  MaintenanceUnit = 12,
  Building = 13,
  SubSpace = 14,
  Floor = 15,
  ApartmentId = 16,
  DynamicAttribute = 17,
  DealerId = 18,
  ReferenceLabel = 19,
  TerminalId = 20,
}

export enum ServiceDataSpecificationAttribute {
  AssetDetailsAssetId = 0,
  AssetDetailsAssetIdentifier = 1,
  AssetDetailsAssetName = 2,
  AssetDetailsCountryName = 3,
  AssetDetailsCustomerName = 5,
  AssetDetailsDealerName = 7,
  AssetDetailsMake = 8,
  AssetDetailsModel = 9,
  AssetDetailsType = 10,
  AssetDetailsServiceMode = 11,
  AssetDetailsTerminalPowerSource = 12,
  AssetDetailsTerminalConnected = 13,
  AssetDetailsInstallationDate = 16,
  AssetDetailsAddress = 18,
  AssetDetailsTerminalBatteryLevel = 26,
  AssetDetailsDynamicAttributes = 28,
}

export enum IntServiceDataPointInterval {
  Second = 0,
  Minute = 1,
  Hour = 2,
  Day = 3,
  Month = 4,
  Latest = 5,
  None = 6,
}

export enum IntAggregateType {
  Average = 0,
  Count = 1,
  Max = 2,
  Min = 3,
  Sum = 4,
}

export enum IntGroupType {
  RegisterDate = 0,
  Asset = 1,
}

export enum SmsTraffic {
  AllowAll = 0,
  BlockAll = 1,
}

export enum SeverityType {
  Low = 0,
  Medium = 1,
  High = 2,
  Critical = 3,
}

export enum Urgency {
  NonPenaltyErrors = 3,
  DegardedServices = 2,
  CompleteOutage = 1,
}

export enum Impact {
  SingleUsers = 3,
  SiteOrSeveralUsers = 2,
  Enterprise = 1,
}

export enum TicketStatus {
  New = 0,
  Open = 1,
  AwaitingInfo = 2,
  Resolved = 3,
  Closed = 4,
  Cancelled = 5,
}

export enum Unit {
  Millimetre = 0,
  Centimetre = 1,
  Metre = 2,
  Kilometre = 3,
}

export enum UserEpochDateColumn {
  registerDate = 0,
}

export enum InsertVpnServerRouteResult {
  InsertedSuccessfully = 0,
  InvalidUnknownProperty = 1,
  MissingUnknownProperty = 2,
  UnknownError = 3,
  DatabaseSaveChangesError = 4,
}

export enum WorkloadCategory {
  General = 0,
  DataIngestion = 1,
}

export enum WorkloadType {
  Unknown = 0,
  Job = 1,
  CronJob = 2,
  Deployment = 3,
}
