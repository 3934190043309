import { IntAutomationTriggerStatus } from 'generated';
import i18n from 'i18next';
import { Column } from 'react-table';
import { CustomCell } from '../Helpers/CustomCell';
import { ICustomCellProps } from '../Helpers/CustomCellProps';
import { getOptionFilter } from '../Helpers/getOptionFilter';

export function automationTriggerStatus<T>(options: {
  id: string;
  accessor: (row: T) => IntAutomationTriggerStatus;
  header?: string;
  defaultShow?: boolean;
  filterable?: boolean;
}): Column<T> {
  const {
    id,
    header = i18n.t('automation:table.label.status'),
    accessor,
    defaultShow: show,
    filterable,
  } = options;

  const cellByStatus: Record<IntAutomationTriggerStatus, ICustomCellProps> = {
    [IntAutomationTriggerStatus.NotTriggered]: {
      iconType: 'StatusNeutral',
      text: i18n.t(
        `automation:trigger_status.${IntAutomationTriggerStatus.NotTriggered}`
      ),
    },
    [IntAutomationTriggerStatus.Triggered]: {
      iconType: 'StatusOk',
      text: i18n.t(
        `automation:trigger_status.${IntAutomationTriggerStatus.Triggered}`
      ),
    },
  };

  return {
    id,
    Header: header,
    accessor,
    resizable: false,
    maxWidth: 130,
    filterable,
    show,
    Cell: row => {
      const iconProps =
        cellByStatus[row.value as IntAutomationTriggerStatus] || {};
      return <CustomCell {...iconProps} index={row.index} />;
    },
    Filter: getOptionFilter([
      {
        label: i18n.t('all'),
        value: '',
      },
      {
        label: i18n.t(
          `automation:trigger_status.${IntAutomationTriggerStatus.NotTriggered}`
        ),
        value: IntAutomationTriggerStatus.NotTriggered,
      },
      {
        label: i18n.t(
          `automation:trigger_status.${IntAutomationTriggerStatus.Triggered}`
        ),
        value: IntAutomationTriggerStatus.Triggered,
      },
    ]),
  };
}
