import { getSeriesColorIndex } from 'components/Highcharts';
import { format } from 'date-fns';
import { Options, SeriesOptionsType } from 'highcharts';
import { IDataProperty } from 'views/Dashboard/DataSources/dataPropTypes';
import { IGroupedWidgetData } from 'views/Dashboard/DataSources/dataSourceTypes';
import { dataProp } from '../../DataSources';

interface IDataPoint {
  x: number;
  y: number;
  custom?: { [key: string]: string };
  marker?: { enabled?: boolean };
}

export function lineChartSeries(
  chartOptions: Options,
  data: IGroupedWidgetData,
  xValue: IDataProperty<number>,
  yValue: IDataProperty<number>,
  name: IDataProperty<string>,
  step: boolean
) {
  chartOptions.series = data.groups
    .map(group => ({
      id: group.id,
      type: 'line',
      colorIndex: getSeriesColorIndex(group.id),
      name: dataProp.getString(name, group.owner) || group.id,
      step: step ? 'left' : undefined,
      data: (
        group.dataPoints
          .map(datapoint => {
            const x = dataProp.getNumber(xValue, datapoint);
            const y = dataProp.getNumber(yValue, datapoint);

            datapoint.name = dataProp.getString(name, group.owner) || group.id;
            datapoint.unit = yValue.unit;
            datapoint.headerTitle = yValue.name;
            datapoint.headerValue = y;
            datapoint.date =
              x !== null
                ? format(new Date(x), 'E. d MMM HH:mm', {
                    locale: window.__DATEFNS_LOCALE__,
                  })
                : '';

            return { x, y, custom: { ...datapoint, ...group.owner } };
          })
          .filter(point => point.x !== null && point.y !== null) as IDataPoint[]
      )
        .sort((firstEl, secondEl) => firstEl.x - secondEl.x)
        .map(enableMarkersForSinglePoints),
    }))
    .filter(s => s.data.length) as SeriesOptionsType[];
}

function enableMarkersForSinglePoints(
  point: IDataPoint,
  _: number,
  arr: IDataPoint[]
): IDataPoint {
  if (arr.length === 1) {
    return {
      ...point,
      marker: { enabled: true },
    };
  }

  return point;
}
