import { TextField } from '@mui/material';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers';
import { format } from 'date-fns';
import { useField, useFormikContext } from 'formik';
import React from 'react';

interface IProps {
  name: string;
  label: string;
  minDate?: Date;
  maxDate?: Date;
  required?: boolean;
  disabled?: boolean;
  submitOnlyDate?: boolean;
}

const DatePicker: React.FC<IProps> = ({
  name,
  label,
  minDate,
  maxDate,
  required,
  disabled,
  submitOnlyDate,
}) => {
  const [field] = useField<string>(name);
  const { setFieldValue } = useFormikContext<any>();

  return (
    <MuiDatePicker
      label={label}
      value={field.value}
      minDate={minDate}
      maxDate={maxDate}
      inputFormat="yyyy-MM-dd"
      onChange={newDate => {
        setFieldValue(
          name,
          submitOnlyDate && newDate ? format(newDate, 'yyyy-MM-dd') : newDate
        );
      }}
      renderInput={params => <TextField {...params} fullWidth />}
      disabled={disabled}

      // error={meta.error ? true : undefined} <-- todo: this is a mess and doesn't quite work (but not SUPER important right now)
    />
  );
};

export default DatePicker;
